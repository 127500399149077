import { Profissao } from './profissao';
import { ClasseProfissional } from './classe-profissional';
import { Empresa } from './empresa';
import { Endereco } from '../../../ficha-veiculo/ficha-cadastro/model/endereco-cliente';

export class DadosProfissionais {

  classeProfissional: ClasseProfissional;
  profissao: Profissao;
  empresa: Empresa = new Empresa();

  private _isSelectDados = false;

  public static of(
    idClasse: number,
    idProfissao: number,
    nomeEmpresa: string,
    telefoneEmpresa: string,
    endereco: Endereco) {

    const classeProfissional = new ClasseProfissional();
    classeProfissional.id = idClasse;

    const profissao = new Profissao();
    profissao.id = idProfissao;

    const empresa = new Empresa();
    empresa.nome = nomeEmpresa;
    empresa.telefone = telefoneEmpresa;
    empresa.endereco = endereco;

    const dados = new DadosProfissionais();
    dados.classeProfissional = classeProfissional;
    dados.profissao = profissao;
    dados.empresa = empresa;
    dados._isSelectDados = true;
    return dados;
  }

  get idClasseProfissional(): number {
    return this.classeProfissional ? this.classeProfissional.id : undefined;
  }

  get idProfissao(): number {
    return this.profissao ? this.profissao.id : undefined;
  }

  get nomeEmpresa(): string {
    return this.empresa ? this.empresa.nome : undefined;
  }

  get telefoneEmpresa(): string {
    return this.empresa ? this.empresa.telefone : undefined;
  }

  get isEmpresa() {
    return this.classeProfissional && this.classeProfissional.empresa;
  }

  get valid(): boolean {
    return (this.classeProfissional && this.profissao && !this.isEmpresa) ||
    (this.classeProfissional && this.profissao && this.empresa && this.empresa.valid);
  }

  get isSelectDados(): boolean {
    return this._isSelectDados;
  }

  dadosSelected() {
    this._isSelectDados = false;
  }
}
