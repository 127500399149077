import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';

@Component({
  selector: 'login-google',
  templateUrl: './login-google.component.html',
  styleUrls: ['./login-google.component.css']
})
export class LoginGoogleComponent {
  @Output() googleSocialUserChange: EventEmitter<SocialUser> = new EventEmitter();

  constructor(private googleAuthService: AuthService) { }

  signInWithGoogle(): void {
    this.googleAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);

    this.googleAuthService.authState
      .subscribe(user => {
        if (user && user.provider === 'GOOGLE') {
          this.googleSocialUserChange.emit(user);
        }
      });
  }
}
