export class Parcela {
  private $numeroParcelas: number;
  private $valor: number;
  private $percentual: number;
  private $valorRetido: number;
  private $valorLiquido: number;

  constructor(
    numeroParcelas?: number,
    valor?: number,
    percentual?: number,
    valorRetido?: number,
    valorLiquido?: number
  ) {
    this.$numeroParcelas = numeroParcelas;
    this.$valor = valor;
    this.$percentual = percentual;
    this.$valorRetido = valorRetido;
    this.$valorLiquido = valorLiquido;
  }

  get numeroParcelas(): number {
    return this.$numeroParcelas;
  }

  get valor(): number {
    return this.$valor;
  }

  get percentualRetencao() {
    return this.$percentual;
  }

  get valorRetido() {
    return this.$valorRetido;
  }

  get valorLiquido() {
    return this.$valorLiquido;
  }
}
