import { finalize, take } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FichaCadastroEvent } from '../ficha-cadastro.event';
import { LoginStorage } from './../../../login/login.storage';
import { BancoService } from './../../../services/banco/banco.service';
import { DadosFavorecido } from './../../../services/dados-favorecido/dados-favorecido';
import { DadosFavorecidoService } from './../../../services/dados-favorecido/dados-favorecido.service';
import { Item } from './../../../shared/custom-select/item';
import { ModalLoaderComponent } from './../../../shared/modais/modal-loader/modal-loader.component';
import { ModalMessageComponent } from './../../../shared/modais/modal-message/modal-message.component';
import { ModalComponent } from './../../../shared/modais/modal/modal.component';
import { FichaCadastro } from './../ficha-cadastro';

@Component({
  selector: 'app-ficha-cadastro-analise',
  templateUrl: './ficha-cadastro-analise.component.html',
  styleUrls: ['./ficha-cadastro-analise.component.css'],
})
export class FichaCadastroAnaliseComponent implements OnInit {
  @ViewChild('modalDadosFavorecido', { static: true })
  modalDadosFavorecido: ModalComponent;
  @ViewChild('modalLoader', { static: true }) modalLoader: ModalLoaderComponent;
  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;

  @Input() fichaCadastro: FichaCadastro;

  isSaving = false;

  dadosFavorecido: DadosFavorecido = new DadosFavorecido();
  dadosFavorecidoForm: FormGroup;

  idProposta: number;
  nomeCliente: string;
  cpfCliente: string;
  valorLiquido: number;
  qtdParcelas: number;
  valorParcela: number;
  anoModelo: number;
  descMarcaModelo: string;

  bancos: Item[];

  constructor(
    private _dadosFavorecidoService: DadosFavorecidoService,
    private _bancoService: BancoService,
    private loginStorage: LoginStorage,
    private fichaEvent: FichaCadastroEvent
  ) {}

  ngOnInit() {
    this.fichaEvent.onCLickNextStep.subscribe(() => {
      if (this.fichaCadastro.passo === this.fichaCadastro.PASSO_ANALISE) {
        const garantias =
          this.fichaCadastro.garantiasOriginais &&
          this.fichaCadastro.garantiasOriginais.length > 0
            ? this.fichaCadastro.garantiasOriginais
            : this.fichaCadastro.garantias;
        this.idProposta = this.fichaCadastro.proposta.proposta.id;
        this.nomeCliente = this.fichaCadastro.proposta.cliente.nome;
        this.cpfCliente = this.fichaCadastro.proposta.cliente.cpf;
        this.valorLiquido = this.fichaCadastro.proposta.proposta.valorLiquido;
        this.qtdParcelas = this.fichaCadastro.proposta.proposta.qtdParcelas;
        this.valorParcela = this.fichaCadastro.proposta.proposta.valorParcela;
        this.anoModelo = garantias[0].anoModelo;
        this.descMarcaModelo = garantias[0].descMarcaModelo;
      }
    });
  }

  abrirModalDadosFavorecido() {
    this._bancoService
      .bancos()
      .pipe(take(1))
      .subscribe(
        (res: any[]) =>
          (this.bancos = res.map((banco) => {
            const dadosBanco = new Item();
            dadosBanco.id = banco.codigo;
            dadosBanco.descricao = `${banco.codigo} - ${banco.descricao}`;
            return dadosBanco;
          }))
      );
    this.dadosFavorecido = new DadosFavorecido();
    this.modalDadosFavorecido.show(true);
    this.modalDadosFavorecido.ok
      .pipe(take(1))
      .subscribe(() => this.salvarDadosFavorecido());
  }

  isFormValid(): boolean {
    return this.dadosFavorecido.banco != null;
  }

  private salvarDadosFavorecido() {
    this.dadosFavorecido.idProposta = this.fichaCadastro.proposta.proposta.id;
    this.modalLoader.show('Salvando dados do favorecido');
    this._dadosFavorecidoService
      .salvarDadosFavorecido(this.dadosFavorecido)
      .pipe(
        take(1),
        finalize(() => this.modalLoader.hide())
      )
      .subscribe(
        (result) => {
          this.modalMessage.show('Dados do favorecido informados com sucesso.');
        },
        (error) => this.handleErrorDadosFavorecido(error.error)
      );
  }

  private handleErrorDadosFavorecido(error) {
    this.modalMessage.show(error.message);
  }

  isLojista(): boolean {
    return this.loginStorage.usuario.tipoUsuarioTO.id === 7;
  }

  isRefinanciamento(): boolean {
    if (
      this.fichaCadastro.tipoProduto &&
      this.fichaCadastro.tipoProduto !== undefined
    ) {
      return this.fichaCadastro.tipoProduto === 'REFINANCIAMENTO';
    }
    return this.fichaCadastro.grupo1 === 'REFINANCIAMENTO';
  }
}
