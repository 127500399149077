import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FichaCadastroEvent {

  private onclickNextSubject = new Subject<boolean>();
  public onCLickNextStep = this.onclickNextSubject.asObservable();

  public nextStep() {
    this.onclickNextSubject.next(true);
  }

}
