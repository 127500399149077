import { TelefonePartner } from '../../../../shared/partner/telefone-partner';

export class Telefone {
  idCategoria: number;
  idTipoTelefone: number;
  ddd: string;
  numero: string;

  constructor(idCategoria?: number, idTipoTelefone?: number, ddd?: string, numero?: string) {
    this.idCategoria = idCategoria;
    this.idTipoTelefone = idTipoTelefone;
    this.ddd = ddd;
    this.numero = numero;
  }

  set $dddNumero(value: string) {
    this.ddd = TelefonePartner.getDdd(value);
    this.numero = TelefonePartner.getNumero(value);
  }

  get $dddNumero() {
    if (this.ddd && this.numero) { return `${this.ddd}${this.numero}`; }
    if (this.ddd) { return this.ddd; }
    return undefined;
  }
}
