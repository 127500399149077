import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

@Component({
  selector: 'app-tipo-endereco',
  templateUrl: './tipo-endereco.component.html',
  styleUrls: ['./tipo-endereco.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TipoEnderecoComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TipoEnderecoComponent),
      multi: true,
    }
  ]
})
export class TipoEnderecoComponent implements Validator, ControlValueAccessor {

  @Input() required = false;
  @Input() showComercial = true;
  tipo: string;

  onChange() {
    this.propagateChange(this.tipo);
  }

  validate(): { [key: string]: any; } {
    return this.required && !this.tipo ? { required: true } : null;
  }

  public writeValue(obj: string) {
    if (obj) {
      this.tipo = obj;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // This is intentional
  }

  private propagateChange = (_: any) => {
    // This is intentional
  }

}
