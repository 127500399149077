import { Component, Input } from '@angular/core';
import { FichaListaFiltro } from './../model/ficha-lista-filtro';

@Component({
  selector: 'app-lista-ficha-em-fechamento',
  templateUrl: './lista-ficha-em-fechamento.component.html',
  styleUrls: ['./lista-ficha-em-fechamento.component.css']
})
export class ListaFichaEmFechamentoComponent {
  @Input() propostas = [];
  @Input() filtro: FichaListaFiltro;
}
