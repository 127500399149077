
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { finalize, take } from 'rxjs/operators';

import { LoginStorage } from '../../login/login.storage';
import { HttpStatus } from '../../omni-rest/http-status';
import { ModalMessageComponent } from '../../shared/modais/modal-message/modal-message.component';
import { StringReplace } from '../../shared/partner/string-replace';
import { FichaService } from '../ficha-veiculo.service';
import { FichaListaSharedService } from './../ficha-lista/ficha-lista.shared.service';

@Component({
  selector: 'app-ficha-card',
  templateUrl: './ficha-card.component.html',
  styleUrls: ['./ficha-card.component.scss']
})
export class FichaCardComponent implements OnInit {
  @Input()
  proposta: any;
  @Input()
  idFase: number;
  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;

  clickTituloCard = this.goToTimeline;
  clickBotaoAcao;

  isEmAnalise: boolean;
  isAprovada: boolean;
  isEmFechamento: boolean;
  isRecusada: boolean;
  isPagamentoAgendado: boolean;
  isSos: boolean;
  isCardEsquerdo: boolean;
  isUsuarioAcao: boolean;
  isSmallText: boolean;
  isStrongText: boolean;
  isBotaoAcao: boolean;
  isNovaTentativa: boolean;
  isNoAction: boolean;
  isCdclRecusada: boolean;

  smallText: string;
  strongText: string;
  botaoAcao: string;

  titleTituloCard = 'Ver ficha detalhada';
  dataLimiteAtendimento: number;

  private STATUS_PAGAMENTO_REALIZADO: number = 11;

  constructor(
    private router: Router,
    private fichaService: FichaService,
    private fichaListaSharedService: FichaListaSharedService,
    private loginStorage: LoginStorage,
  ) { }

  ngOnInit() {
    this.disableAll();
    this.clickBotaoAcao = this.goToTimeline;
    this.isCardEsquerdo = !(['CDC LOJA', 'CRÉDITO PESSOAL'].includes(this.proposta.produto)
      && this.proposta.statusTimeline.idStatus === this.STATUS_PAGAMENTO_REALIZADO)
      && this.proposta.statusTimeline.cardEsquerdo;

    if (this.isCardEsquerdo) {
      this.isSos = this.proposta.statusTimeline.cardEsquerdo[0].valor === 'SOS';
      if (this.isSos) {
        this.dataLimiteAtendimento = this.proposta.statusTimeline.dataLimiteAtendimento;
      }
      for (
        const value of this.proposta.statusTimeline.cardEsquerdo
      ) {
        if (
          value.tipo === 'TEXTO' ||
          value.tipo === 'VARIÁVEL'
        ) {
          if (!this.isSmallText) {
            this.isSmallText = true;
            if (['CDC LOJA', 'CRÉDITO PESSOAL'].includes(this.proposta.produto)
              && value.valor.toLowerCase().includes('máxim')
            ) {
              this.smallText = 'Proposta em Análise';
            } else {
              this.smallText = value.valor;
            }
          } else {
            this.isStrongText = !['CDC LOJA', 'CRÉDITO PESSOAL'].includes(this.proposta.produto);
            this.isCdclRecusada = (['CDC LOJA', 'CDC PREMIUM'].includes(this.proposta.produto) && this.proposta.statusTimeline.idStatus === 12);
            this.strongText = value.valor;
          }
        }
        if (value.tipo === 'BOTAO') {
          this.isBotaoAcao = true;
          this.botaoAcao = value.valor;
        }
      }
    }

    if (this.proposta.statusTimeline.idStatus) {
      this.isPagamentoAgendado = this.proposta.statusTimeline.idStatus === 10;
    }

    if (!this.isSos && !this.isPagamentoAgendado) {
      this.isEmAnalise = this.idFase === 1;
      this.isAprovada = this.idFase === 2;
      this.isEmFechamento = this.idFase === 3;
      this.isRecusada = this.idFase === 4;
    }

    if (
      this.proposta.statusTimeline.idStatus &&
      this.proposta.statusTimeline.idStatus === 15
    ) {
      this.handlePropostaResutalParcialPendenteOuIndisponivel();
    }
    this.isUsuarioAcao = this.proposta.statusTimeline.usuarioAcao;
  }

  private handlePropostaResutalParcialPendenteOuIndisponivel() {
    this.isRecusada = false;
    this.isCardEsquerdo = true;

    if (this.proposta.statusTimeline.acoes) {
      const acao = this.proposta.statusTimeline.acoes[0];
      this.isSmallText = true;
      this.isStrongText = true;
      this.isBotaoAcao = true;

      if (acao.acao === 'PLACA_INVALIDA') {
        this.clickTituloCard = this.goToNovaPlaca;
        this.clickBotaoAcao = this.goToNovaPlaca;
        this.strongText = 'Placa Inexistente';
        this.smallText = 'Informar nova placa';
        this.botaoAcao = 'Alterar';
      } else if (acao.acao === 'FIPE_NOT_FOUND') {
        this.clickTituloCard = this.goToInformarGarantia;
        this.clickBotaoAcao = this.goToInformarGarantia;
        this.strongText = 'Cotação Não Encontrada';
        this.smallText = 'Informar garantia correspondente';
        this.botaoAcao = 'Alterar';
      } else if (acao.acao === 'TAXA_CLIENTE_NOT_FOUND') {
        this.strongText = 'Motivo';
        this.smallText = 'Política interna';
        this.clickBotaoAcao = () => {
          return;
        };
        this.clickTituloCard = () => {
          return;
        };
        this.noActionTituloCard();
        this.isBotaoAcao = false;
      } else if (acao.acao === 'CRIVO_ERRO') {
        this.isSmallText = false;
        this.strongText = 'Disparo Não Realizado';
        this.botaoAcao = 'Nova tentativa';
        this.clickBotaoAcao = this.novaTentativa;
        this.clickTituloCard = this.novaTentativa;
      } else if (acao.acao === 'GARANTIA_RECUSADA') {
        this.clickTituloCard = this.goToNovaPlaca;
        this.clickBotaoAcao = this.goToNovaPlaca;
        this.strongText = 'Garantia Recusada';
        this.smallText = 'Informar nova garantia';
        this.botaoAcao = 'Alterar';
      } else {
        this.clickBotaoAcao = () => {
          return;
        };
        this.clickTituloCard = () => {
          return;
        };
        this.noActionTituloCard();
        this.isSmallText = false;
        this.isStrongText = false;
        this.isBotaoAcao = false;
        this.isCardEsquerdo = false;
      }
    }
  }

  disableAll(): void {
    this.isEmAnalise = false;
    this.isAprovada = false;
    this.isEmFechamento = false;
    this.isRecusada = false;
    this.isPagamentoAgendado = false;
    this.isSos = false;
    this.isCardEsquerdo = false;
    this.isUsuarioAcao = false;
    this.isSmallText = false;
    this.isStrongText = false;
    this.isBotaoAcao = false;
  }

  goToTimeline(): void {
    if (
      this.isSos &&
      this.dataLimiteAtendimento &&
      moment(this.dataLimiteAtendimento).isBefore(moment())
    ) {
      this.modalMessage
        .show(
          'Esta ficha foi recusada após expiração do prazo de atendimento da SOS!'
        )
        .modalClose.pipe(take(1))
        .subscribe(() => this.fichaListaSharedService.reloadFichaLista());
    } else if (['CDC LOJA', 'CDC PREMIUM'].includes(this.proposta.produto)) {
      this.goToTimelineCdc();
    } else if (['CRÉDITO PESSOAL', 'MICROCREDITO'].includes(this.proposta.produto)) {
      this.router.navigate(['/produtos/cp/timelines'], {
        queryParams: { id: this.proposta.id }
      });
    } else {
      const produto = StringReplace.removeSpaces(
        StringReplace.removeAccentuation(this.proposta.produto)
      ).toLowerCase();
      this.router.navigate(['/timeline'], {
        queryParams: { produto, idFicha: this.proposta.id }
      });
    }
  }

  goToNovaPlaca() {
    this.router.navigate(['/novaplaca', this.proposta.id]);
  }

  goToInformarGarantia() {
    this.router.navigate(['/informargarantia/', this.proposta.id]);
  }

  novaTentativa() {
    this.isNovaTentativa = true;
    this.isBotaoAcao = false;
    this.fichaService
      .analiseAutomatica(this.proposta.id).pipe(
        take(1),
        finalize(() => {
          this.isNovaTentativa = false;
          this.isBotaoAcao = true;
        }))
      .subscribe(
        result => {
          this.router.navigate(['/ficha']);
        },
        error => {
          let message: string;
          if (error.status === HttpStatus.FORBIDDEN) {
            message =
              'Somente o usuário que gerou a proposta pode disparar a análise automática.';
          } else if (error.status === HttpStatus.NOT_FOUND) {
            message = 'Proposta não encontrada.';
          } else {
            this.modalMessage.show(
              'Não foi possível disparar a análise automática,.'
            );
          }
          this.modalMessage.show(message);
        }
      );
  }

  private noActionTituloCard() {
    this.isNoAction = true;
    this.titleTituloCard = '';
  }

  get isParceiro() {
    return this.loginStorage.usuario.parcerias.length > 0;
  }

  private goToTimelineCdc() {
    this.router.navigate(['/produtos/cdcloja/timelines'], {
      queryParams: { id: this.proposta.id }
    });
  }
}
