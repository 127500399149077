import { OmniGeralRestService } from './../../omni-rest/omni-geral/omni-geral-rest.service';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';

@Injectable()
export class GrauInstrucaoService {

  constructor(private _restService: OmniGeralRestService) { }

  listar() {
    return this._restService
        .get('grau-instrucao')
        .pipe(timeout(20000))
        .toPromise();
  }
}
