import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CpNovaFichaComponent } from './cadastro/cadastro.component';
import { CreditoPessoalTimelineComponent } from './timeline/cp-timeline.component';
import { RelatorioCobrancaMcComponent } from './relatorio/cobranca/contrato/relatorio-cobranca-mc.component';
import { RelatorioCobrancaParcelaMcComponent } from './relatorio/cobranca/parcela/relatorio-cobranca-parcela-mc.component';
import { PropostaComponent } from './proposta/proposta.component';

const
  routes: Routes = [
    {
      path: 'fichas',
      component: CpNovaFichaComponent
    },
    {
      path: 'fichas/:id',
      component: CpNovaFichaComponent
    },
    {
      path: 'timelines',
      component: CreditoPessoalTimelineComponent
    },
    {
      path: 'proposta/:id',
      component: PropostaComponent
    },
    {
      path: 'external/relatorio/cobranca/:login/:token',
      component: RelatorioCobrancaMcComponent
    },
    {
      path: 'external/relatorio/cobranca/parcela/:login/:token',
      component: RelatorioCobrancaParcelaMcComponent
    },
  ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CpRoutingModule {}
