import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-referencia-base',
  templateUrl: './referencia-base.component.html',
  styleUrls: ['./referencia-base.component.css']
})
export class ReferenciaBaseComponent implements OnInit {
  @Input() isRemovable: boolean;
  @Input() index?: number = -1;
  @Input() detalhesClienteForm: FormGroup;
  @Output() referenciaDestroyEventEmitter: EventEmitter<number> = new EventEmitter();

  referenciaForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.referenciaForm = this.fb.group({
      tipoReferencia: ['1', Validators.required],
      nomeReferencia: ['', [Validators.required, Validators.maxLength(40)]],
      telefoneReferencia: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(15)]]
    });

    this.detalhesClienteForm.addControl(`referenciaForm_${this.index}`, this.referenciaForm);
  }

  selfDestruct(): void {
    this.detalhesClienteForm.removeControl(`referenciaForm_${this.index}`);
    this.referenciaDestroyEventEmitter.emit(this.index);
  }
}
