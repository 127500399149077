import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnDestroy {
  @Output() confirmValue = new EventEmitter<boolean>();

  titulo: string = 'Atenção';
  mensagem: string = 'Mensagem';
  labelFechar: string = 'Voltar e validar o celular';
  labelOk: string = 'Entendi e quero continuar';
  showFecharBtn: boolean = true;
  showOkBtn: boolean = true;
  isTituloVermelho: boolean = false;

  alive: boolean = true;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnDestroy() {
    this.alive = false;
  }

  showModal(
    options?: {
      titulo?: string,
      mensagem?: string,
      labelFechar?: string,
      labelOk?: string,
      showFecharBtn?: boolean,
      showOkBtn?: boolean,
      isTituloVermelho?: boolean
    }): void {

    if (options) {
      this.titulo = options.titulo || this.titulo;
      this.mensagem = options.mensagem || this.mensagem;
      this.labelFechar = options.labelFechar || this.labelFechar;
      this.labelOk = options.labelOk || this.labelOk;
      this.showFecharBtn = options.showFecharBtn || this.showFecharBtn;
      this.showOkBtn = options.showOkBtn || this.showOkBtn;
      this.isTituloVermelho = options.isTituloVermelho || this.isTituloVermelho;
    }

  }

  onClickOk() {
    this.confirmValue.emit(true);
    this.bsModalRef.hide();
  }

  onClickClose() {
    this.confirmValue.emit(false);
    this.bsModalRef.hide();
  }
}
