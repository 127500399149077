import { Injectable } from '@angular/core';
import { Produto } from './model/produto';

const TIPO_OPERACAO = 'tipo_operacao';

@Injectable()
export class ProdutoStorage {
  save(tipoOperacao: Produto) {
    this.setStorage(TIPO_OPERACAO, tipoOperacao);
  }

  clear(): void {
    sessionStorage.removeItem(TIPO_OPERACAO);
  }

  get tipoOperacao(): Produto[] {
    return this.getStorage(TIPO_OPERACAO);
  }

  private setStorage(key: string, value: Produto) {
    sessionStorage.setItem(key, btoa(JSON.stringify(value)));
  }

  private getStorage(key: string): any {
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(atob(value));
    }
    return {};
  }
}
