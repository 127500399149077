import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';
import { ModalLoaderComponent } from './modal-loader/modal-loader.component';
import { ModalMessageComponent } from './modal-message/modal-message.component';
import { ModalComponent } from './modal/modal.component';
import { NewModalMessageComponent } from './new-modal-message/new-modal-message.component';
import { ModalAlteracoesComponent } from './modal-alteracoes/modal-alteracoes.component';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
  ],
  declarations: [
    ModalComponent,
    ModalLoaderComponent,
    ModalMessageComponent,
    NewModalMessageComponent,
    ConfirmModalComponent,
    ModalAlteracoesComponent
  ],
  exports: [
    ModalComponent,
    ModalLoaderComponent,
    ModalMessageComponent,
    NewModalMessageComponent,
    ConfirmModalComponent
  ],
  entryComponents: [
    ConfirmModalComponent
  ]
})
export class ModaisModule { }
