import { RepiqueService } from './services/repique.service';
import { NgModule } from '@angular/core';
import { CpComponent } from './cp.component';
import { CpNovaFichaComponent } from './cadastro/cadastro.component';
import { CpRoutingModule } from './cp-routing.module';
import { ProdutosSharedModule } from '../shared/shared.module';
import { AnaliseComponent } from './cadastro/etapas/analise/analise.component';
import { DadosAnaliseComponent } from './cadastro/etapas/dados-analise/dados-analise.component';
import { ModalCarregarFichaComponent } from './cadastro/etapas/dados-analise/modal-carregar-ficha/modal-carregar-ficha.component';
import { DetalhesClienteComponent } from './cadastro/etapas/detalhes-cliente/detalhes-cliente.component';
import { ReferenciaBaseComponent } from './cadastro/etapas/referencia-base/referencia-base.component';
import { TelefoneBaseComponent } from './cadastro/etapas/telefone-base/telefone-base.component';
import { EnderecosClienteComponent } from './cadastro/etapas/enderecos-cliente/enderecos-cliente.component';
import { EnderecoBaseComponent } from './cadastro/etapas/endereco-base/endereco-base.component';
import { IdentificacaoComponent } from './cadastro/etapas/identificacao/identificacao.component';
import { ResultadoParcialComponent } from './cadastro/etapas/resultado-parcial/resultado-parcial.component';
import { AlteraTabelaComponent } from './cadastro/etapas/resultado-parcial/altera-tabela/altera-tabela.component';
import { AlterarValorComponent } from './cadastro/etapas/resultado-parcial/alterar-valor/alterar-valor.component';
import { ParcelaComponent } from './cadastro/etapas/resultado-parcial/parcela/parcela.component';
import { ParcelaPersonalizadaComponent } from './cadastro/etapas/resultado-parcial/parcela-personalizada/parcela-personalizada.component';
import { ProdutosComponent } from './cadastro/etapas/resultado-parcial/produtos/produtos.component';
import { RetencaoComponent } from './cadastro/etapas/resultado-parcial/retencao/retencao.component';
import { SelecionarLojistaVendedorComponent } from './cadastro/etapas/selecionar-lojista-vendedor/selecionar-lojista-vendedor.component';
import { DadosComplementaresComponent } from './cadastro/etapas/dados-complementares/dados-complementares.component';
import { SharedModule } from '../../shared/shared.module';
import { CreditoPessoalService } from './services/cp.service';
import { CreditoPessoalTimelineModule } from './timeline/cp-timeline.module';
import { DadosEmpreendimentoComponent } from './cadastro/etapas/faef/dados-empreendimento/dados-empreendimento.component';
import { InstituicaoBaseComponent } from './cadastro/etapas/faef/dados-empreendimento/instituicao-base/instituicao-base.component';
import { MicrocreditoService } from './services/microcredito.service';
import { VendasMargemComponent } from './cadastro/etapas/faef/vendas-margem/vendas-margem.component';
import { MercadoriaFaefComponent } from './cadastro/etapas/faef/vendas-margem/mercadoria-faef/mercadoria-faef.component';
import { MediaVendasComponent } from './cadastro/etapas/faef/media-vendas/media-vendas.component';
import { ComprasTrimestreComponent } from './cadastro/etapas/faef/compras-trimestre/compras-trimestre.component';
import { DadosFinanceirosComponent } from './cadastro/etapas/faef/dados-financeiros/dados-financeiros.component';
import { ResultadosContabeisComponent } from './cadastro/etapas/faef/resultados-contabeis/resultados-contabeis.component';
import { ModalBalancoPatrimonialComponent } from './cadastro/etapas/faef/resultados-contabeis/modal-balanco-patrimonial/modal-balanco-patrimonial.component';
import { ModalIndicadoresComponent } from './cadastro/etapas/faef/resultados-contabeis/modal-indicadores/modal-indicadores.component';
import { ModalDemonstrativoResultadoComponent } from './cadastro/etapas/faef/resultados-contabeis/modal-demonstrativo-resultado/modal-demonstrativo-resultado.component';
import { ParecerAgenteComponent } from './cadastro/etapas/faef/parecer-agente/parecer-agente.component';
import { AnaliseAvalistaComponent } from './cadastro/etapas/dados-analise/analise-avalista/analise-avalista.component';
import { RepiqueListagemComponent } from './cadastro/etapas/dados-analise/repique-listagem/repique-listagem.component';
import { ModalConfirmarTelefoneComponent } from './cadastro/etapas/identificacao/modal-confirmar-telefone/modal-confirmar-telefone.component';
import { RelatorioCobrancaMcComponent } from './relatorio/cobranca/contrato/relatorio-cobranca-mc.component';
import { RelatorioCobrancaParcelaMcComponent } from './relatorio/cobranca/parcela/relatorio-cobranca-parcela-mc.component';
import { DataTablesModule } from 'angular-datatables';
import { Select2Module } from 'ng2-select2';
import { PropostaComponent } from './proposta/proposta.component';

@NgModule({
  declarations: [
    CpComponent,
    CpNovaFichaComponent,
    RelatorioCobrancaMcComponent,
    RelatorioCobrancaParcelaMcComponent,
    AnaliseComponent,
    DadosAnaliseComponent,
    ModalCarregarFichaComponent,
    DetalhesClienteComponent,
    DadosComplementaresComponent,
    ReferenciaBaseComponent,
    TelefoneBaseComponent,
    EnderecosClienteComponent,
    EnderecoBaseComponent,
    IdentificacaoComponent,
    ResultadoParcialComponent,
    AlteraTabelaComponent,
    AlterarValorComponent,
    ParcelaComponent,
    ParcelaPersonalizadaComponent,
    ProdutosComponent,
    RetencaoComponent,
    SelecionarLojistaVendedorComponent,
    DadosEmpreendimentoComponent,
    InstituicaoBaseComponent,
    VendasMargemComponent,
    MercadoriaFaefComponent,
    MediaVendasComponent,
    ComprasTrimestreComponent,
    DadosFinanceirosComponent,
    ResultadosContabeisComponent,
    ModalBalancoPatrimonialComponent,
    ModalIndicadoresComponent,
    ModalDemonstrativoResultadoComponent,
    ParecerAgenteComponent,
    AnaliseAvalistaComponent,
    RepiqueListagemComponent,
    ModalConfirmarTelefoneComponent,
    PropostaComponent,
  ],
  imports: [
    ProdutosSharedModule,
    CpRoutingModule,
    SharedModule,
    CreditoPessoalTimelineModule,
    DataTablesModule,
    Select2Module
  ],
  entryComponents: [
    ModalCarregarFichaComponent,
    AlteraTabelaComponent,
    AlterarValorComponent,
    ParcelaPersonalizadaComponent,
    ProdutosComponent,
    RetencaoComponent,
    ModalBalancoPatrimonialComponent,
    ModalIndicadoresComponent,
    ModalDemonstrativoResultadoComponent,
    RepiqueListagemComponent,
    ModalConfirmarTelefoneComponent,
  ],
  providers: [CreditoPessoalService, MicrocreditoService, RepiqueService],
})
export class CpModule {}
