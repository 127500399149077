import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginStorage } from '../../login/login.storage';
import { PromotorService } from '../promotor/promotor.service';

@Injectable()
export class PromotorGuard implements CanActivate {

  constructor(
    public promotorService: PromotorService,
    private router: Router,
    private loginStorage: LoginStorage
  ) { }

  canActivate(): Observable<boolean> | boolean {
    if (this.promotorService.isOperadorLiberaCessao === undefined && !!this.loginStorage.usuario.promotor.id) {
      this.promotorService
        .getOperadorLiberaCessao(this.loginStorage.usuario.promotor.id)
        .then(() => {
          if (!this.promotorService.isOperadorLiberaCessao) {
            this.goToFichas();
          }
          return this.response;
        });
    } else {
      return this.response;
    }
  }

  get response() {
    if (this.promotorService.isOperadorLiberaCessao) {
      this.goToCessao();
      return false;
    }
    return true;
  }

  private goToFichas() {
    this.router.navigate(['/ficha']);
  }

  private goToCessao() {
    this.router.navigate(['/produtos/cdcloja/cessao']);
  }
}
