import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Proposta } from '../../shared/models/proposta';
import { Faef } from '../cadastro/etapas/faef/model/faef';
import { CreditoPessoalService } from '../services/cp.service';
import { ProfissaoService } from './../../../services/profissao/profissao.service';
import { BancoService } from '../../../services/banco/banco.service';
import { FaefStateService } from '../cadastro/etapas/faef/faef-state.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-cp-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.css'],
})

export class PropostaComponent implements OnInit, OnDestroy {
  propostaId: number = Number(this.activeRoute.snapshot.paramMap.get('id'));
  proposta: Proposta;
  faef: Faef;
  loading: Boolean = true;
  profissoes: any[] = [];
  clienteProfissaoDescricao: string = '';
  conjugeProfissaoDescricao: string = '';
  nomeBancoFavorecido: string = '';
  previousUrl = `/produtos/cp/timelines?id=${this.propostaId}`;

  tipoReferencias = {
    1: 'Amigo(a)',
    2: 'Parente',
    3: 'Vizinho(a)',
    4: 'Outros',
  };

  exibirDados = {
    proposta: false,
    cliente: false,
    referencia: false,
    conjuge: false,
    favorecido: false,
    enderecoComercial: false,
    enderecoResidencial: false,
    parecerAgente: false,
    financeiro: false,
    mercadoria: false,
    compra: false,
    venda: false,
    vendaPorMes: false,
    indicador: false,
  };

  generos = {
    M: 'Masculino',
    F: 'Feminino',
  };

  vendasPrazoCliente = {
    1: 'À VISTA',
    2: 'ATÉ 15 DIAS',
    3: 'ATÉ 30 DIAS',
    4: 'ATÉ 60 DIAS',
    5: 'ATÉ 90 DIAS',
    6: 'MAIS DE 90 DIAS',
  };

  meses = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  mediasMes = {
    1: 'Bom',
    2: 'Regular',
    3: 'Ruim',
  };

  constructor(
    private service : CreditoPessoalService,
    private profissaoService: ProfissaoService,
    private bancoService: BancoService,
    private faefState: FaefStateService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.buscarProposta();
  }

  ngOnDestroy(): void {}

  get avalistas(): any[] {
    if (this.proposta && this.proposta.avalistas) {
      return Array.isArray(this.proposta.avalistas) ? this.proposta.avalistas : Object.keys(this.proposta.avalistas).map((value) => this.proposta.avalistas[value]);
    }
    return [];
  }

  get clienteEAvalistasBoxes(): any[] {
    const cliente = this.proposta.cliente ? JSON.parse(JSON.stringify(this.proposta.cliente)) : {};
    const avalistas = JSON.parse(JSON.stringify(this.avalistas));
    cliente.title = 'Dados do Cliente';
    cliente.type = 'cliente';
    avalistas.forEach((avalista: any, i: number) => {
      avalista.title = `Dados do Avalista ${(avalistas.length === 1 ? '' : i + 1)}`.trim();
      avalista.type = `avalista${i}`;
      delete avalista.profissao;
    });
    return this.avalistas.length > 0 ? [cliente].concat(avalistas) : [cliente];
  }

  get mesesVenda(): any {
    if (!this.faef || !this.faef.faefVendasMedia || !this.faef.faefVendasMedia.faefVendasMediaMes) {
      return {};
    }

    const vendasPorMes = {};

    this.faef.faefVendasMedia.faefVendasMediaMes.forEach((mediaMes) => {
      if (!vendasPorMes[this.mediasMes[mediaMes.tipoMes]]) {
        vendasPorMes[this.mediasMes[mediaMes.tipoMes]] = [];
      }

      vendasPorMes[this.mediasMes[mediaMes.tipoMes]].push(this.meses[mediaMes.mes]);
    });

    return vendasPorMes;
  }

  get mediaMeses(): string[] {
    return Object.keys(this.mediasMes).map(key => this.mediasMes[key]);
  }

  public boolText(value: boolean): string {
    return value ? 'Ocultar' : 'Exibir';
  }

  public formatCurrency(value: number|string): string {
    return Number(value).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' });
  }

  private async buscarProposta(): Promise<void> {
    this.loading = true;
    try {
      this.buscarFaef();
      const ficha = await this.service.getTimeline(this.propostaId).toPromise();
      this.proposta = ficha.proposta;
      this.buscarProfissoes();
      this.buscarBancos();
    } catch (_erro) {
      toastr.error('Não foi possível carregar as informações da proposta, aguarde um minuto e atualize a página.');
    } finally {
      this.loading = false;
    }
  }

  private async buscarProfissoes(): Promise<void> {
    let clienteProfissoes;
    let clienteProfissao;
    let conjugeProfissoes;
    let conjugeProfissao;

    if (
        this.proposta &&
        this.proposta.cliente &&
        this.proposta.cliente.dadosProfissionais &&
        this.proposta.cliente.dadosProfissionais.classeProfissional &&
        this.proposta.cliente.dadosProfissionais.profissao
       ) {
      clienteProfissoes = (await this.profissaoService.profissoes(this.proposta.cliente.dadosProfissionais.classeProfissional.id).toPromise()).profissoes;
      clienteProfissao = clienteProfissoes.find(profissao => {
        return profissao.id === this.proposta.cliente.dadosProfissionais.profissao.id;
      });
      this.clienteProfissaoDescricao = clienteProfissao.descricao;
    }

    if (
          this.proposta &&
          this.proposta.conjuge &&
          this.proposta.conjuge.dadosProfissionais &&
          this.proposta.conjuge.dadosProfissionais.classeProfissional &&
          this.proposta.conjuge.dadosProfissionais.profissao
       ) {
      conjugeProfissao = clienteProfissoes.find(profissao => {
        return profissao.id === this.proposta.conjuge.dadosProfissionais.profissao.id;
      });

      if (!conjugeProfissao) {
        conjugeProfissoes = (await this.profissaoService.profissoes(this.proposta.conjuge.dadosProfissionais.classeProfissional.id).toPromise()).profissoes;
        conjugeProfissao = conjugeProfissoes.find(profissao => {
          return profissao.id === this.proposta.conjuge.dadosProfissionais.profissao.id;
        });
      }

      this.conjugeProfissaoDescricao = conjugeProfissao.descricao;
    }
  }

  private buscarBancos(): void {
    if (this.proposta.favorecido && this.proposta.favorecido.banco) {
      this.bancoService.bancos().pipe(take(1)).subscribe((res: any[]) => {
        const banco = res.find((banco) => Number(banco.codigo) === Number(this.proposta.favorecido.banco));
        this.nomeBancoFavorecido = (banco || {}).descricao;
      });
    }
  }

  private async buscarFaef(): Promise<void> {
    await this.faefState.init(this.propostaId);
    const faef = this.faefState.getLast();
    if (faef && Object.keys(faef).length > 1) {
      this.faef = faef;
    }
  }

  public formatDate(date: number): string {
    return new Date(date).toLocaleDateString();
  }

}
