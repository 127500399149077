import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
declare var $: any;

@Component({
  selector: 'slider-retorno',
  templateUrl: './slider-retorno.component.html',
  styleUrls: ['./slider-retorno.component.css']
})
export class SliderRetornoComponent implements OnInit, OnChanges {

  @Input() retornoMax: number;
  @Input() retorno: number;
  @Output() retornoChange: EventEmitter<number> = new EventEmitter();

  private retornoEmmited: number = 0;
  private retornoMaxInicializado: number = 0;

  ngOnInit() {
    this.initSlider();
    $('#slider-retorno').slider().on('change', (ev) => {
      this.retornoEmmited = $('#slider-retorno').data('slider').getValue();
      this.retornoChange.emit(this.retornoEmmited);
    });
  }

  ngOnChanges() {
    if ((this.retornoEmmited !== this.retorno) || (this.retornoMaxInicializado !== this.retornoMax)) {
      this.initSlider();
    }
  }

  initSlider() {
    $('#slider-retorno').slider({
      value: this.retorno,
      step: 1,
      min: 0,
      max: this.retornoMax,
      ticks: [0, this.retornoMax],
      tooltip: 'hide',
      ticks_tooltip: false
    });
    $('#slider-retorno').slider('refresh');
    this.retornoMaxInicializado = this.retornoMax;
  }
}
