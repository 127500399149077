import { appSettings } from './../../../../../environments/app.setings';

import { takeWhile, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { AlterarValorComponent } from './alterar-valor/alterar-valor.component';

@Component({
  selector: 'app-valor-financiado',
  templateUrl: './valor-financiado.component.html',
  styleUrls: ['./valor-financiado.component.css', './valor-financiado.component.scss']
})
export class ValorFinanciadoComponent implements OnInit, OnDestroy {

  @Input() cotacao: number;
  @Input() disabled: boolean;
  @Input() valorSimular: number;
  @Input() simulacao: boolean = false;
  @Output() valorSimularChange: EventEmitter<number> = new EventEmitter();

  private debouncer: Subject<number> = new Subject<number>();
  private isAlive = true;

  readonly valorMinimo = 2000;
  readonly invervaloCalculo = 50;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    this.debouncer.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeWhile(() => this.isAlive))
      .subscribe((val) => this.valorSimularChange.emit(val));
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  onChangeValorSimular() {
    this.debouncer.next(this.valorSimular);
  }

  onClickPlus() {
    if (this.isValorSimular()) {
      const calculado = this.round(this.valorSimular + this.invervaloCalculo);
      if (calculado > this.cotacao) {
        this.valorSimular = this.cotacao;
      } else {
        this.valorSimular = calculado;
      }
      this.onChangeValorSimular();
    }
  }

  onClickMinus() {
    if (this.isValorSimular()) {
      const calculado = this.round(this.valorSimular - this.invervaloCalculo);
      if (calculado < this.valorMinimo) {
        this.valorSimular = this.valorMinimo;
      } else {
        this.valorSimular = calculado;
      }
      this.onChangeValorSimular();
    }
  }

  openModalAlterarValor() {
    const initialState = { valorMaximo: this.cotacao, valorMinimo: this.valorMinimo };
    const modal = this.modalService.show(AlterarValorComponent, { ...appSettings.MODAL_PARAMS, initialState });

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe((valor) => {
        this.valorSimular = valor;
        this.onChangeValorSimular();
      });
  }

  private round(value: number): number {
    return Math.round((value || 0) * 100) / 100;
  }

  private isValorSimular(): boolean {
    return this.valorSimular && this.valorSimular > 0;
  }

}
