import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PropostaSimulacao } from './../ficha-cadastro-resultado-parcial/model/proposta/proposta-simulacao';
import { VeiculoSimulacao } from './../ficha-cadastro-resultado-parcial/model/proposta/veiculo-simulacao';

@Injectable()
export class FichaCadastroSharedService {

  private simularPropostaSubject = new Subject<boolean>();
  simularPropostaObservable = this.simularPropostaSubject.asObservable();

  private resultadoParcialSubject = new Subject<PropostaSimulacao>();
  resultadoParcialObservable = this.resultadoParcialSubject.asObservable();

  private alterarVeiculoSubject = new Subject<VeiculoSimulacao>();
  alterarVeiculoObservable = this.alterarVeiculoSubject.asObservable();

  private selecionarVeiculoManualSubject = new Subject<any>();
  selecionarVeiculoManualObservable = this.selecionarVeiculoManualSubject.asObservable();

  simularProposta() {
    this.simularPropostaSubject.next(true);
  }

  simulacaoResultadoParcial(proposta: PropostaSimulacao) {
    this.resultadoParcialSubject.next(proposta);
  }

  alterarVeiculo(veiculo: VeiculoSimulacao) {
    this.alterarVeiculoSubject.next(veiculo);
  }

  selecionarVeiculoManualmente() {
    this.selecionarVeiculoManualSubject.next();
  }
}
