import { ContratoRepique } from './../../../../shared/models/contrato-repique';
import { RepiqueListagemComponent } from './repique-listagem/repique-listagem.component';
import { RepiqueService } from './../../../services/repique.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import * as moment from 'moment';

import { appSettings } from '../../../../../../environments/app.setings';
import { ModalCarregarFichaComponent } from './modal-carregar-ficha/modal-carregar-ficha.component';
import { ClasseProfissionalService } from '../../../../../services/classe-profissional/classe-profissional.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TipoNegociacaoEnum } from '../../../../shared/enums/tipo-negociacao.enum';
import { Proposta } from '../../../../shared/models/proposta';
import { CreditoPessoalService } from '../../../services/cp.service';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { Cliente } from '../../../../shared/models/cliente';

@Component({
  selector: 'app-dados-analise',
  templateUrl: './dados-analise.component.html',
  styleUrls: ['./dados-analise.component.css']
})
export class DadosAnaliseComponent implements OnInit, OnDestroy {
  @Input() tipoNegociacaoEnum: TipoNegociacaoEnum;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  @ViewChild('dadosAnaliseModal', { static: true }) dadosAnaliseModal: NewModalMessageComponent;
  private isAlive: boolean = true;

  showLoader: boolean = false;

  principalForm: FormGroup;
  dadosClienteForm: FormGroup;
  avalistaForm: FormGroup;
  dadosConjugeForm: FormGroup;
  rendaForm: FormGroup;

  avalistas: Cliente[] = [];
  editing = -1;

  classesProfissionais: any[];

  showConjugeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isModalPoliticaInterna: boolean = false;

  consultaCpfObj: any;
  tabelas: any[] = [];
  proposta: Proposta;

  dataRange = {
    min: 18,
    max: 85,
    message: 'A idade deve ser entre 18 e 85 anos',
  };

  constructor(
    private fb: FormBuilder,
    private classeProfissionalService: ClasseProfissionalService,
    private router: Router,
    private state: PropostaStateService,
    private cpService: CreditoPessoalService,
    private repiqueService: RepiqueService,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    console.log('Iniciando componente Dados Análise');
    this.proposta = this.state.getLast();
    this.initForms();
    this.fillClassesProfissionais();
    this.fillTabelas();
    this.initObservers();
  }

  ngOnDestroy() {
    console.log('Destruindo componente Dados Análise');
    this.isAlive = false;
  }

  private initForms(): void {
    this.dadosClienteForm = this.fb.group({
      cpf: [],
      dataNascimento: [],
      classeProfissional: [],
    });

    this.dadosConjugeForm = this.fb.group({
      cpfConjuge: ['', Validators.required],
      dataNascimentoConjuge: ['', Validators.required]
    });

    this.rendaForm = this.fb.group({
      renda: ['', [Validators.maxLength(17)]],
      hasComprovanteRenda: [false],
      isComposicaoRenda: [false],
      isRendaAtribuida: [false],
      hasExperienciaCredito: [false],
      valorSolicitado: ['', [Validators.required, Validators.maxLength(17)]],
      entrada: ['', Validators.maxLength(17)],
      numeroParcelas: [''],
      valorDesejadoParcela: ['', Validators.maxLength(17)],
    });

    this.principalForm = this.fb.group({
      dadosCliente: this.dadosClienteForm,
      renda: this.rendaForm,
      tabela: ['', Validators.required]
    });

  }

  addAvalista() {
    this.avalistaForm = this.fb.group({
      cpf: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      classeProfissional: ['', Validators.required],
      grauParentesco: ['', Validators.required],
      renda: ['', Validators.required]
    });

    this.editing = this.avalistas.length;
  }

  onAvalistaEdit(i) {
    const avalista = this.avalistas[i];

    this.avalistaForm = this.fb.group({
      cpf: [`${avalista.cpf}`, Validators.required],
      dataNascimento: [avalista.dtNascimento, Validators.required],
      classeProfissional: [`${avalista.dadosProfissionais.classeProfissional.id}`, Validators.required],
      grauParentesco: [`${avalista.grauParentesco}`, Validators.required],
      renda: [avalista.dadosProfissionais.salario, Validators.required]
    });

    this.editing = i;
  }

  onAvalistaRemove(i) {
    this.avalistas.splice(i, 1);
  }

  cancelarAvalista() {
    this.editing = -1;
    this.avalistaForm.reset();
  }

  salvarAvalista() {
    const avalistaValue = this.avalistaForm.value;

    if (this.editing >= this.avalistas.length) {
      const avalista: Cliente = {
        cpf: avalistaValue.cpf,
        dtNascimento: Number(avalistaValue.dataNascimento),
        dadosProfissionais: {
          classeProfissional: { id: Number(avalistaValue.classeProfissional) },
          salario: avalistaValue.renda
        },
        grauParentesco: Number(avalistaValue.grauParentesco),
      };
      this.avalistas.push(avalista);
    } else {
      this.avalistas[this.editing].cpf = avalistaValue.cpf;
      this.avalistas[this.editing].dtNascimento = Number(avalistaValue.dataNascimento);
      this.avalistas[this.editing].dadosProfissionais.classeProfissional.id = Number(avalistaValue.classeProfissional);
      this.avalistas[this.editing].dadosProfissionais.salario = avalistaValue.renda;
      this.avalistas[this.editing].grauParentesco = Number(avalistaValue.grauParentesco);
    }
    this.cancelarAvalista();
  }

  async onCpfChangeAvalista() {
    this.showLoader = true;
    const avalistaValue = this.avalistaForm.value;
    try {
      const avalista = await this.cpService.consultarCPF(avalistaValue.cpf).toPromise();
      if (avalista) {
        if (this.editing >= this.avalistas.length) {
          this.avalistas.push(avalista);
        } else {
          this.avalistas[this.editing] = avalista;
        }
        this.populateAvalistaForm();
      }
    } finally {
      this.showLoader = false;
    }
  }

  private populateForm() {
    this.showConjugeSubject.next(false);
    if (this.proposta.cliente && this.proposta.cliente.dadosProfissionais) {
      this.dadosClienteForm.patchValue({
        cpf: this.proposta.cliente.cpf,
        classeProfissional: `${this.proposta.cliente.dadosProfissionais.classeProfissional.id}`,
        dataNascimento: moment(new Date(this.proposta.cliente.dtNascimento)).format('DDMMYYYY')
      });
    }

    if (this.proposta.conjuge) {
      this.dadosConjugeForm.patchValue({
        cpfConjuge: this.proposta.conjuge.cpf,
        dataNascimentoConjuge: moment(new Date(this.proposta.conjuge.dtNascimento)).format('DDMMYYYY')
      });
    }

    if (this.proposta.cliente) {
      this.rendaForm.patchValue({ renda: (this.proposta.cliente.rendas[0] || { valor: undefined }).valor }, { emitEvent: false });
    }

    this.rendaForm.patchValue({ valorSolicitado: this.proposta.valorLiquido }, { emitEvent: false });
  }

  private populateAvalistaForm() {
    this.showConjugeSubject.next(false);
    if (this.avalistas[this.editing] && this.avalistas[this.editing].dadosProfissionais) {
      this.avalistaForm.patchValue({
        cpf: this.avalistas[this.editing].cpf,
        classeProfissional: `${this.avalistas[this.editing].dadosProfissionais.classeProfissional.id}`,
        dataNascimento: moment(new Date(this.avalistas[this.editing].dtNascimento)).format('DDMMYYYY'),
        renda: this.avalistas[this.editing].rendas[0]
      });
    }
  }

  private initObservers(): void {
    this.dadosClienteForm
      .valueChanges.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => this.onDadosClienteFormChange());

    this.showConjugeSubject.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(res => this.handleShowConjugeChange(res));

    this.rendaForm.get('renda').valueChanges.pipe().distinctUntilChanged().subscribe(renda => {
      if (renda) {
        this.cpf.setValidators([Validators.required]);
      }else {
        this.cpf.clearValidators();
      }
      this.cpf.updateValueAndValidity();
    });

    this.dadosClienteForm.get('cpf').valueChanges.pipe().distinctUntilChanged().subscribe(cpf => {
      if (cpf) {
        this.dataNascimento.setValidators([Validators.required]);
      }else {
        this.dataNascimento.clearValidators();
      }
      this.dataNascimento.updateValueAndValidity();
    });

    this.dadosClienteForm.get('dataNascimento').valueChanges.pipe().distinctUntilChanged().subscribe(cpf => {
      if (cpf) {
        this.classeProfissional.setValidators([Validators.required]);
      }else {
        this.classeProfissional.clearValidators();
      }
      this.classeProfissional.updateValueAndValidity();
    });

    this.dadosClienteForm.get('classeProfissional').valueChanges.pipe().distinctUntilChanged().subscribe(cpf => {
      if (cpf) {
        this.renda.setValidators([Validators.required]);
      }else {
        this.renda.clearValidators();
      }
      this.renda.updateValueAndValidity();
    });

  }

  private onDadosClienteFormChange(): void {
    this.showConjugeSubject.next(this.isOtherClasseProfissional());
    this.checkComposicaoRenda();
  }

  private isOtherClasseProfissional(): boolean {
    return this.classeProfissional.value === '9';
  }

  private checkComposicaoRenda(): void {
    if (this.classeProfissional.value === '9') {
      this.rendaForm.get('isComposicaoRenda').setValue(true);
      this.rendaForm.get('isComposicaoRenda').disable();
    } else {
      this.rendaForm.get('isComposicaoRenda').setValue(false);
      this.rendaForm.get('isComposicaoRenda').enable();
    }
  }

  private handleShowConjugeChange(showConjuge: boolean): void {
    if (showConjuge) {
      this.principalForm.addControl('dadosConjuge', this.dadosConjugeForm);
    } else {
      this.principalForm.removeControl('dadosConjuge');
    }
  }

  onPrincipalFormChanges(): void {
    this.fillInformacoesClienteObj();
    this.fillPropostaSimulacao();
    this.fillInformacoesAvalista();
  }

  private fillInformacoesAvalista() {
    const avalistas = Object.assign(
      {},
      ...this.avalistas.map((value, index) => ({ [index + 1]: value }))
    );
    this.proposta.avalistas = avalistas;
  }

  private fillPropostaSimulacao(): void {
    this.proposta.valorLiquido = this.rendaForm.get('valorSolicitado').value * 1;
    this.proposta.valorEntrada = this.rendaForm.get('entrada').value * 1;

    const tabelaId = Number(this.principalForm.get('tabela').value);
    this.proposta.tabela = this.tabelas.filter(t => t.id === tabelaId)[0];
  }

  private fillInformacoesClienteObj(): void {
    const cliente = this.dadosClienteForm.value;

    if (this.proposta.cliente) {
      this.proposta.cliente.cpf = cliente.cpf;
    } else {
      this.proposta.cliente = { cpf: cliente.cpf };
    }

    if (this.proposta.cliente.dadosProfissionais) {
      this.proposta.cliente.dadosProfissionais.classeProfissional = { id: Number(cliente.classeProfissional) };
    } else {
      this.proposta.cliente.dadosProfissionais = { classeProfissional: { id: Number(cliente.classeProfissional) } };
    }

    const renda = this.rendaForm.value;
    this.proposta.cliente.dadosProfissionais.salario = renda.renda;

    if (cliente.dataNascimento) {
      this.proposta.cliente.dtNascimento = cliente.dataNascimento;
    }
    if (this.showConjugeSubject.value) {
      this.proposta.conjuge = {
        cpf: this.cpfConjuge.value,
        dtNascimento: this.dataNascimentoConjuge.value,
        dadosProfissionais: {},
        telefones: [{}]
      };
    }

    this.removeCaracteresEspeciaisFromInformacoesCliente();
  }

  private removeCaracteresEspeciaisFromInformacoesCliente(): void {
    this.proposta.cliente.cpf =
      this.removeEspeciais(this.proposta.cliente.cpf);

    if (this.proposta.conjuge) {
      this.proposta.conjuge.cpf =
        this.removeEspeciais(this.proposta.conjuge.cpf);
    }
  }

  private removeEspeciais(str: string) {
    if (str) {
      return str.replace(/[^\d]/g, '');
    }
    return null;
  }

  private fillClassesProfissionais(): void {
    this.classeProfissionalService
      .classes().pipe(
      take(1))
      .subscribe(res => {
        this.classesProfissionais = res;
      });
  }

  public fillTabelas(): void {
    let valorParam = this.rendaForm.get('valorSolicitado').value;
    if (!valorParam) valorParam = 0;

    this.cpService.tabelas(this.proposta.operacao.id, valorParam).pipe(take(1)).subscribe(res => {
      this.tabelas = res;
      this.proposta.cliente.telefones.forEach(obj => {
        obj.tipoTelefone = 'PROPRIO';
      });
      const idTabela = this.principalForm.get('tabela').value;
      if (!idTabela || idTabela !== undefined) {
        const valorFiltrado = this.tabelas.filter(t => t.id === idTabela);
        if (valorFiltrado === undefined || !valorFiltrado) {
          this.proposta.tabela = undefined;
          this.principalForm = this.fb.group({ dadosCliente: this.dadosClienteForm, tabela: '' });
        }
      }
    },                                                                                    error => { this.tabelas = []; console.log(error); }
      );
  }

  get cpf(): AbstractControl {
    return this.dadosClienteForm.get('cpf');
  }

  get classeProfissional(): AbstractControl {
    return this.dadosClienteForm.get('classeProfissional');
  }

  get cpfConjuge(): AbstractControl {
    return this.dadosConjugeForm.get('cpfConjuge');
  }

  get dataNascimentoConjuge(): AbstractControl {
    return this.dadosConjugeForm.get('dataNascimentoConjuge');
  }

  get dataNascimento(): AbstractControl {
    return this.dadosClienteForm.get('dataNascimento');
  }

  get renda(): AbstractControl {
    return this.rendaForm.get('renda');
  }

  get isRendaAtribuida(): AbstractControl {
    return this.rendaForm.get('isRendaAtribuida');
  }

  get hasExperienciaCredito(): AbstractControl {
    return this.rendaForm.get('hasExperienciaCredito');
  }

  handleModalHide($event: boolean): void {
    if (this.isModalPoliticaInterna && $event) {
      this.router.navigateByUrl('ficha');
    }
  }

  onClickNext() {
    this.onPrincipalFormChanges();
    this.proposta.qtdeParcelas = 12;
    this.state.setState(this.proposta);
    this.next.emit();
  }

  get cpfsIguais() {
    const cpfConjuge = this.dadosConjugeForm.get('cpfConjuge').value;
    return cpfConjuge && cpfConjuge === this.cpf.value;
  }

  onClickPrevious() {
    this.previous.emit();
  }

  get formValid() {
    return this.principalForm.valid && this.editing < 0;
  }

  get simulacao() {
    return this.cpf.value || this.renda.value || this.dataNascimento.value || this.classeProfissional.value;
  }

  async onCpfChange() {
    this.showLoader = true;
    try {
      const cliente = await this.cpService.consultarCPF(this.cpf.value).toPromise();
      if (cliente) {
        this.proposta.cliente = cliente;
        if (cliente.conjuge && cliente.conjuge.cpf) {
          this.proposta.conjuge = cliente.conjuge;
          delete cliente.conjuge;
        }
        this.populateForm();
        await this.abrirModalCarregarFicha();
      } else {
        await this.abrirModalRepique();
      }
    } finally {
      this.showLoader = false;
    }
  }

  private async abrirModalRepique() {
    const baseContratos = await this.repiqueService.buscarPorCpf(this.proposta.operacao.id, this.cpf.value);
    if (baseContratos.length > 0) {
      const modal = this.modalService.show(RepiqueListagemComponent, { ...appSettings.MODAL_PARAMS, initialState: { baseContratos }, class: 'modal-lg' });
      const repiqueListagem = modal.content as RepiqueListagemComponent;
      repiqueListagem.onSelect
        .pipe(
          takeWhile(() => repiqueListagem.alive)
        )
        .subscribe(item => this.carregarContratoRepique(item));
    }
  }

  async carregarContratoRepique(item: ContratoRepique) {
    const propostaRepique = await this.repiqueService.carregarContratoOrigem(item.contrato);
    this.proposta = { ...propostaRepique, lojista: this.proposta.lojista, promotor: this.proposta.promotor, contratoRepique: item };
    this.tabelas = [];
    this.fillTabelas();

    this.dadosClienteForm.patchValue({
      dataNascimento: this.proposta.cliente.dtNascimento,
      classeProfissional: this.proposta.cliente.dadosProfissionais.classeProfissional.id
    });

    if (this.proposta.conjuge) {
      this.dadosConjugeForm.patchValue({
        cpfConjuge: this.proposta.conjuge.cpf,
        dataNascimentoConjuge: this.proposta.conjuge.dtNascimento
      });
    }

    if (this.proposta.cliente.rendas[0]) {
      this.rendaForm.patchValue({
        renda: this.proposta.cliente.rendas[0].valor
      });
    }

    if (this.proposta.avalistas) {
      this.avalistas = Object.getOwnPropertyNames(this.proposta.avalistas).map(name => this.proposta.avalistas[name]);
    }
  }

  async abrirModalCarregarFicha() {
    if (this.proposta.cliente.ultimaFichaAberta) {
      const modal = this.modalService.show(ModalCarregarFichaComponent, appSettings.MODAL_PARAMS);
      modal.content.propostaId = this.proposta.cliente.ultimaFichaAberta;
      const component = modal.content as ModalCarregarFichaComponent;
      component.onHide
        .pipe(take(1))
        .subscribe(value => {
          if (value) this.abrirModalRepique();
        });
    } else {
      await this.abrirModalRepique();
    }
  }

  get valorSolicitado() {
    return this.rendaForm.get('valorSolicitado').value * 1;
  }

  get valorDivida() {
    if (!this.proposta.contratoRepique) {
      return 0;
    }

    return this.proposta.contratoRepique.valores.valorCorrigido;
  }

  get valorLiberado() {
    if (!this.valorSolicitado || !this.valorDivida || this.valorDivida > this.valorSolicitado) {
      return 0;
    }

    return this.valorSolicitado - this.valorDivida;
  }

  get valorMinimoParaSolicitar() {
    return this.valorDivida || 200;
  }

}
