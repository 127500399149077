
import { throwError as observableThrowError,  Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { OmniMaisRestService } from '../omni-rest/omni-mais/omni-mais-rest.service';
import { OmniWsRestService } from '../omni-rest/omni-ws/omni-ws-rest.service';
import { LoginCredencial } from './model/login-credencial';
import { LoginResult } from './model/login-result';
import { ChangePasswordRequest } from './model/change-password-request';
import { ChangePasswordResponse } from './model/change-password-response';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class LoginService {

  constructor(
    private omniWsRestService: OmniWsRestService,
    private omniMaisRestService: OmniMaisRestService
  ) { }

  login(credencial: LoginCredencial): Observable<LoginResult> {
    return this.omniWsRestService.post('user/login', { login: credencial.login, senha: credencial.senha });
  }

  recoveryPassword(credencial: LoginCredencial): Observable<any> {
    return this.omniWsRestService.post('user/forgot-password', { login: credencial.login });
  }

  changePassword(request : ChangePasswordRequest) : Observable<ChangePasswordResponse> {
    return this.omniWsRestService.put<ChangePasswordResponse>('user/password', request);
  }

  getAndAuthenticateUser(usuarioTO: any): Observable<any> {
    if (usuarioTO.tokenPush) {
      return this.omniWsRestService
        .post('user/change-token-push', { tokenPush: usuarioTO.tokenPush });
    }
    return from(new Promise((resolve, reject) => {resolve('SEM TOKEN PUSH')}));
  }

  selecionarAgente(idAgente: string) {
    return this.omniMaisRestService.post('usuario/selecionarAgentePorUsuario', { idAgente });
  }
}
