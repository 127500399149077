import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotCaracterDirective } from './not-caracter.directive';
import { OnlyLetterDirective } from './only-letter.directive';
import { OnlyNumberRestrictiveDirective } from './only-number-restrictive.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { TextUpperCaseDirective } from './text-upper-case.directive';
import { OnlyMoneyDirective } from './only-money.directive';
import { OnlyNumberOrCharacterDirective } from './only-number-or-character.directive';
import { OnlyNumberOrCharacterDirectiveWhiteSpace } from './only-number-or-character-whitespace.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    OnlyLetterDirective,
    OnlyMoneyDirective,
    OnlyNumberDirective,
    NotCaracterDirective,
    TextUpperCaseDirective,
    OnlyNumberRestrictiveDirective,
    OnlyNumberOrCharacterDirective,
    OnlyNumberOrCharacterDirectiveWhiteSpace
  ],
  exports: [
    OnlyLetterDirective,
    OnlyMoneyDirective,
    OnlyNumberDirective,
    TextUpperCaseDirective,
    NotCaracterDirective,
    OnlyNumberRestrictiveDirective,
    OnlyNumberOrCharacterDirective,
    OnlyNumberOrCharacterDirectiveWhiteSpace
  ]
})
export class TextDirectivesModule { }
