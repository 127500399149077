import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      const replacedValue = value.replace(/[^\d]/g, '');
      const newValue = replacedValue.length > 11 ? replacedValue.substring(0, 11) : replacedValue;

      if (newValue.length > 2) {
        const ddd = newValue.substring(0, 2);

        if (newValue.length > 7) {
          const middle = newValue.substring(2, newValue.length - 4);
          const end = newValue.substring(newValue.length - 4);

          return `(${ddd}) ${middle}-${end}`;
        }

        return `(${ddd}) ${newValue.substring(2)}`;
      }
    }
    return value;
  }

}
