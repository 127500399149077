
import { timer as observableTimer } from 'rxjs';

import { takeWhile } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-phases',
  templateUrl: './loader-phases.component.html',
  styleUrls: ['./loader-phases.component.scss']
})
export class LoaderPhasesComponent implements OnInit {

  @Input() time: number = 45000;
  @Input() phases: string[];

  timePerPhase: number;

  private currentPhase: number;

  ngOnInit() {
    this.initAnimation();
  }

  private initAnimation() {
    if (this.phases && this.phases.length) {
      this.timePerPhase = this.time / this.phases.length;
      this.currentPhase = -1;
      observableTimer(1, this.timePerPhase).pipe(
        takeWhile(() => this.currentPhase < this.phases.length - 1))
        .subscribe(() => this.currentPhase++);
    }
  }

  get currentPhaseText() {
    return this.phases[this.currentPhase];
  }

  get previousPhaseText() {
    return this.phases[this.currentPhase - 1] || '';
  }
}
