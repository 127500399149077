import { Filtro } from './filtro';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmniMaisRestService } from '../omni-rest/omni-mais/omni-mais-rest.service';

@Injectable()
export class DashboardService {

  constructor(private restService: OmniMaisRestService) { }

  public buscarDashboardLojistaOperador(filtro: Filtro): Observable<any> {
    return this.restService.post('proposta/buscarDashboardLojistaOperador', filtro);
  }

  ranking(filtro: Filtro): Observable<any> {
    return this.restService.post('proposta/buscarRankingLojistaOperador', filtro);
  }

}
