import { Proposta } from './proposta';

export interface TermsOfPayment {
  period?: number;
  amount?: number;
  interest?: number;
  allowed?: boolean;
  retention?: number;
  retentionFee?: number;
  net?: number;
  operation?: any;
}

export interface Simulacao {
  primeiraParcela?: number;
  paymentProtectionInsurances: any[];
  assistances: any[];
  termsOfPayment: TermsOfPayment[];
  cessionPaymentOptionsTerms?: any[];
  cessionPaymentOptions?: any[];
  periodRange?: any;
  gracePeriodRange: any;
  firstPaymentDate?: number;
  maxPeriod?: number;
  minPeriod?: number;
  operation?: any;
  diasValidosVencimento?: number[];
}
export class PropostaSimulacao {
  proposta?: Proposta;

  simulacao: Simulacao;

  constructor() {
    this.simulacao = {
      paymentProtectionInsurances: [],
      assistances: [],
      termsOfPayment: [],
      gracePeriodRange: {},
    };
  }
}
