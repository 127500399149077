import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notificacao-aba',
  templateUrl: './notificacao-aba.component.html',
  styleUrls: ['./notificacao-aba.component.css']
})
export class NotificacaoAbaComponent {
  @Input() quantidade = 0;
}
