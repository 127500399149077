import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { finalize, takeWhile } from 'rxjs/operators';
import * as toastr from 'toastr';

import { appSettings } from './../../../../../../../environments/app.setings';
import { Proposta } from './../../../../../shared/models/proposta';
import { PropostaStateService } from './../../../../../shared/states/proposta-state.service';
import { StatusProcessoUnico } from './../../../../models/captura-unico';
import { CdcLojaService } from './../../../../services/cdc-loja.service';
import { AutenticacaoClienteService } from './autenticacao-cliente.service';
import { ModalConfirmarProcessoAutenticacaoComponent } from './modal-confirmar-processo-autenticacao/modal-confirmar-processo-autenticacao.component';

export enum AutenticacaoStatus {
  INICIAR = 1,
  EM_ANDAMENTO = 2,
  REALIZADO = 3,
  AUTENTICACAO_MANUAL = 4
}
@Component({
  selector: 'app-autenticacao',
  templateUrl: './autenticacao.component.html',
  styleUrls: ['./autenticacao.component.scss']
})
export class AutenticacaoComponent implements OnInit, OnDestroy {
  readonly STATUS = AutenticacaoStatus;
  processoStatus: AutenticacaoStatus = AutenticacaoStatus.INICIAR;

  @Input() set isBiometriaAtiva(ativa: boolean) {
    this.processoStatus = ativa ? AutenticacaoStatus.INICIAR : AutenticacaoStatus.AUTENTICACAO_MANUAL;
  }

  proposta: Proposta;
  timeLeft;
  timer;
  desabilitarBotao = false;
  showLoader = false;

  constructor(
    private modal: BsModalService,
    private cdcLojaService: CdcLojaService,
    private state: PropostaStateService,
    private biometriaService: AutenticacaoClienteService,
  ) { }

  ngOnInit(): void {
    this.proposta = this.state.getLast();
    this.checkBiometriaRealizada();
  }

  abrirModalDeConfirmacao() {
    const modal = this.modal.show(ModalConfirmarProcessoAutenticacaoComponent, { ...appSettings.MODAL_PARAMS });
    const confirm = <ModalConfirmarProcessoAutenticacaoComponent>modal.content;

    confirm.confirmValue
      .pipe(
        takeWhile(() => confirm.alive)
      )
      .subscribe(value => {
        if (value) {
          this.showLoader = true;
          this.cancelarLinkAutenticacao();
        }
      });
  }

  private cancelarLinkAutenticacao() {
    this.cdcLojaService.cancelarLinkAutenticacao(this.proposta.id).pipe(finalize(() => this.showLoader = false)).subscribe(() => {
      this.iniciarProcessoManual();
    });
  }

  private iniciarProcessoManual() {
    this.processoStatus = this.STATUS.AUTENTICACAO_MANUAL;
  }

  verificarStatusBiometria() {
    this.showLoader = true;
    this.cdcLojaService.getStatusBiometriaProposta(this.proposta.id).pipe(finalize(() => this.showLoader = false)).subscribe(
      (response) => {
        if (response.status === StatusProcessoUnico.CONCLUIDO) {
          this.biometriaService.atualizarAutenticacaoManual({ isAutenticacaoManual: false });
          this.processoStatus = this.STATUS.REALIZADO;
          return;
        }

        if (response.status === StatusProcessoUnico.ERRO) {
          toastr.error('Não foi possível prosseguir com a captura da biometria.', 'Atenção');
          return;
        }

        this.initTimer();
        this.abrirModalDeConfirmacao();
      },
      () => {
        this.initTimer();
        this.abrirModalDeConfirmacao();
      });
  }

  private initTimer() {
    this.timeLeft = 60;
    this.timer = setInterval(
      () => {
        this.desabilitarBotao = true;
        if (this.timeLeft > 0) {
          this.timeLeft--;
          return;
        }
        this.desabilitarBotao = false;
        this.stop();
      },
      1000
    );
  }

  private stop() {
    clearInterval(this.timer);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  async checkBiometriaRealizada() {
    const realizada  = this.proposta.statusBiometria;
    if (realizada) {
      this.verificarStatusBiometria();
    }
  }
}
