import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EcommerceEtapas } from '../ecommerce-etapas';
import { BsModalService } from 'ngx-bootstrap';
import { AlterarValorComponent } from '../../../shared/components/cadastro-fichas-cdc/alterar-valor/alterar-valor.component';
import { appSettings } from '../../../../../environments/app.setings';
import { takeWhile } from 'rxjs/operators';
import { ParametroRendaProduto } from '../../../../produtos/shared/models/parametro-renda-produto';
import { ProdutosComponent } from '../../../../produtos/shared/components/cadastro-fichas-cdc/produtos/produtos.component';
import { PrazoPrimeiroPgtoComponent } from './prazo-primeiro-pgto/prazo-primeiro-pgto.component';
import { Proposta } from '../../../shared/models/proposta';
import { CdcLojaService } from '../../services/cdc-loja.service';
import {
  Simulacao,
  TermsOfPayment,
} from '../../../../produtos/shared/models/proposta-simulacao';
import { Tabela } from '../../../../produtos/shared/models/tabelas';

import * as _ from 'lodash';
import moment from 'moment';
import { Router } from '@angular/router';

interface OpcaoPagamento {
  tabela: Tabela;
  opcoesParcelamento: TermsOfPayment[];
}

@Component({
  selector: 'app-simulacao',
  templateUrl: './simulacao.component.html',
  styleUrls: ['./simulacao.component.scss'],
})
export class SimulacaoComponent implements OnInit {
  @Input() tabelas: Tabela[];
  @Input() parametroRendaProduto: ParametroRendaProduto;
  @Output() next = new EventEmitter<void>();

  showLoader = false;
  proposta: Proposta;
  index: number = 0;

  opcoesParcelamento: TermsOfPayment[];
  simulacao: Simulacao;
  showProductsButton = false;

  constructor(
    private modalService: BsModalService,
    private cdcLojaService: CdcLojaService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.showLoader = true;
    this.proposta = EcommerceEtapas.proposta;

    if (!this.proposta.tabela.carencia) {
      this.proposta.tabela.carencia = this.tabelas[0].carencia;
    }

    EcommerceEtapas.simulador = await this.cdcLojaService
      .newSimulacao(this.proposta)
      .toPromise();

    this.simulacao = EcommerceEtapas.simulador;
    this.opcoesParcelamento = this.simulacao.termsOfPayment;
    this.hasOmniProducts();

    const firstPaymentDate = this.simulacao.firstPaymentDate / 1000 + 3 * 3600;
    const diaMes = moment.unix(firstPaymentDate).format('YYYY-MM-DD');
    const carenciaLabel = this.diasAte(diaMes);
    this.proposta.tabela.carencia = carenciaLabel;

    this.manageProposta();
    this.showLoader = false;
  }

  manageProposta() {
    this.proposta.valorEntrada = this.proposta.valorEntrada || 0;
    this.proposta.assistencia = this.proposta.assistencia || 0;
    this.proposta.seguro = this.proposta.seguro || 0;
    this.index = this.opcoesParcelamento.findIndex(
      (opt) => opt.period === this.proposta.qtdeParcelas
    );
  }

  onSelect(index: number) {
    this.index = index;
    this.proposta.qtdeParcelas = this.opcoesParcelamento[index].period;
  }

  async onClickNext() {
    this.showLoader = true;
    await this.cdcLojaService.salvarProposta(this.proposta).toPromise();
    this.showLoader = false;
    this.next.emit();
  }

  async onClickPrevious() {
    this.showLoader = true;
    await this.cdcLojaService.salvarProposta(this.proposta).toPromise();
    this.showLoader = false;
    this.router.navigate(['ficha']);
  }

  async onChangePrimeiroPagamento(data: number) {
    this.simulacao.primeiraParcela = data;
    const diaMes = moment.unix(data).format('YYYY-MM-DD');
    const carencia = this.diasAte(diaMes);
    this.proposta.carencia = carencia - 30;
    this.proposta.tabela.carencia = carencia;
    EcommerceEtapas.proposta = this.proposta;
    EcommerceEtapas.simulador = await this.cdcLojaService
    .newSimulacao(this.proposta)
    .toPromise();

    this.simulacao = EcommerceEtapas.simulador;
    this.simulacao.firstPaymentDate = data * 1000;
    this.opcoesParcelamento = this.simulacao.termsOfPayment;
    this.hasOmniProducts();
  }

  private diasAte(diaMes): number {
    return Math.round(
      moment
        .duration(
          moment(`${diaMes} 03:00:00`)
            .startOf('day')
            .diff(moment({ h: 3, m: 0, s: 0, ms: 0 }))
        )
        .asDays()
    );
  }

  abrirModalAlterarValor() {
    const modal = this.modalService.show(AlterarValorComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState: { parametroRendaProduto: this.parametroRendaProduto },
    });

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe((valor: any) => this.onAlterarValor(valor));
  }

  async onAlterarValor(valorFinanciamento: any) {
    this.showLoader = true;
    this.proposta.valorLiquido = valorFinanciamento;
    await this.saveAndUpdate();
    this.showLoader = false;
  }

  abrirModalProdutos() {
    const modal = this.modalService.show(
      ProdutosComponent,
      appSettings.MODAL_PARAMS
    );
    modal.content.init({
      simulacao: this.simulacao,
      proposta: this.proposta,
    });

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe((produto) => this.onChangeProduto(produto));
  }

  private async onChangeProduto(produto: {
    seguro: number;
    assistencia: number;
  }) {
    this.showLoader = true;
    this.proposta.seguro = produto.seguro;
    this.proposta.assistencia = produto.assistencia;
    await this.saveAndUpdate();
    this.showLoader = false;
  }

  private saveAndUpdate() {
    return new Promise<void>(async (resolve) => {
      const promiseArray = await Promise.all([
        this.cdcLojaService.salvarProposta(this.proposta).toPromise(),
        this.cdcLojaService.newSimulacao(this.proposta).toPromise(),
      ]);
      this.opcoesParcelamento = promiseArray[1].termsOfPayment;
      resolve();
    });
  }

  private opcoesParcelamentoArray() {
    return new Promise<[OpcaoPagamento[], Simulacao[]]>(async (resolve) => {
      const promiseArray = [];

      this.tabelas.forEach(async (t, index) => {
        if (this.proposta.tabela.id !== t.id) {
          const novaProposta = _.cloneDeep(this.proposta);
          novaProposta.tabela = {
            id: t.id,
            descricao: t.descricao,
          };

          promiseArray.push(
            this.cdcLojaService.newSimulacao(novaProposta).toPromise()
          );
        } else {
          promiseArray.push(
            new Promise((resolve) => {
              resolve(this.simulacao);
            })
          );
        }
      });

      const simulacoes = await Promise.all(promiseArray);

      const opcoesParcelamentoArray = simulacoes.map((s, index) => {
        return {
          tabela: this.tabelas[index],
          opcoesParcelamento: s.termsOfPayment,
        };
      });

      resolve([opcoesParcelamentoArray, simulacoes]);
    });
  }

  async abrirModalPrimeiroPgto() {
    this.showLoader = true;

    const promiseRes = await this.opcoesParcelamentoArray();

    const opcoesPagamento = promiseRes[0];
    const simulacoes = promiseRes[1];

    const selectedTableIndex = this.tabelas.findIndex(
      (t) => t.id === this.proposta.tabela.id
    );

    const initialState = {
      opcoesPagamento,
      index: selectedTableIndex,
    };
    this.showLoader = false;

    const modal = this.modalService.show(PrazoPrimeiroPgtoComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe((index: number) =>
        this.onChangePrazo(opcoesPagamento[index], simulacoes[index])
      );
  }

  onChangePrazo(opcaoPagamento: OpcaoPagamento, simulacao: Simulacao) {
    this.opcoesParcelamento = opcaoPagamento.opcoesParcelamento;
    this.proposta.tabela = opcaoPagamento.tabela;
    EcommerceEtapas.simulador = simulacao;
    this.simulacao = simulacao;
  }

  get calendarFirstPaymentDate() {
    return moment(this.simulacao.firstPaymentDate).unix();
  }

  hasOmniProducts() {
    const { paymentProtectionInsurances, assistances } = this.simulacao;
    this.showProductsButton = paymentProtectionInsurances.length > 0 || assistances.length > 0;
  }
}
