import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bell-plus',
  templateUrl: './bell-plus.component.html',
  styleUrls: ['./bell-plus.component.css'],
})
export class BellPlusComponent {
  @Input() class = '';
  @Output() onClick = new EventEmitter();

  onClickComponent() {
    this.onClick.emit();
  }
}
