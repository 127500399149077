import {
  Component,
  Input,
  EventEmitter,
  Output,
  IterableDiffers,
} from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';
@Component({
  selector: 'app-custom-dual-list',
  templateUrl: './custom-dual-list.component.html',
  styleUrls: ['./custom-dual-list.component.css'],
})
export class CustomDualListComponent extends DualListComponent {
  @Input() sourceName = [];
  @Input() targetName = [];
  @Input() showPercentage: boolean = false;
  @Output() selectChange = new EventEmitter();
  @Output() moveItemEventEmitter = new EventEmitter();
  constructor(differs: IterableDiffers) {
    super(differs);
  }
  moveAll() {
    this.selectAll(this.available);
    this.moveItem(this.available, this.confirmed);
  }
  removeAll() {
    this.selectAll(this.confirmed);
    this.moveItem(this.confirmed, this.available);
  }
  // Override function in DualListComponent to add custom selectChange event.
  selectItem(list: any[], item: any) {
    let itemList;
    if (item.item) {
      itemList = item.item;
    } else {
      itemList = item;
    }
    const pk = list.filter((e: any) => {
      return Object.is(e, itemList);
    });
    if (pk.length > 0) {
      // Already in list, so deselect.
      for (let i = 0, len = pk.length; i < len; i += 1) {
        const idx = list.indexOf(pk[i]);
        if (idx !== -1) {
          list.splice(idx, 1);
          this.selectChange.emit({ key: itemList._id, selected: false });
        }
      }
    } else {
      if (
        this.showPercentage &&
        item.origin === 'available' &&
        this.available.pick.length + this.confirmed.sift.length === 100
      ) {
        return;
      }
      list.push(itemList);
      this.selectChange.emit({ key: itemList._id, selected: true });
    }
  }
}
