import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';
import { ParamsConfirmacaoCPFUserChave } from './confirma-dados-assinatura';

@Injectable()
export class FichaVerificacaoSegurancaService {
  constructor(private restService: OmniMaisRestService) {}

  getTokenByCpfValidacaoSegurancao(params: ParamsConfirmacaoCPFUserChave): Observable<any> {
    return this.restService.post('cliente/confirmar-cpf-chave', params);
  }
}
