import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-token-not-received-info-modal',
  templateUrl: './token-not-received-info-modal.component.html',
  styleUrls: ['./token-not-received-info-modal.component.scss']
})
export class TokenNotReceivedInfoModalComponent {
  constructor(public bsModalRef: BsModalRef) { }
}
