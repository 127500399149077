import { throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { LeadsOnlineRestService } from '../../../omni-rest/leads/leads-online-rest.service';
import { catchError } from 'rxjs/operators';
import { HttpStatus } from '../../../omni-rest/http-status';
import { OmniMaisRestService } from '../../../omni-rest/omni-mais/omni-mais-rest.service';
import { DadoSimulacao } from '../../../ficha-veiculo/ficha-cadastro/ficha-cadastro-resultado-parcial/model/dado-simulacao';

@Injectable({
  providedIn: 'root',
})
export class LeadsOnlineService {
  constructor(
    private restService: OmniMaisRestService,
    private leadsOnlineRestService: LeadsOnlineRestService
  ) {}

  getOriginadorLeads() {
    return this.intercept(() => this.leadsOnlineRestService.get('agent/originator').toPromise());
  }

  // Serviços da Tela Inicial --------------------------------------------------------------------------------------
  findAllEmAnalise(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllAprovada(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllEmFechamento(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllRecusada(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  analiseAutomatica(idProposta: string): Observable<any> {
    return this.restService.post(`veiculo/analise-automatica/${idProposta}`);
  }
  // Serviços da Tela Inicial --------------------------------------------------------------------------------------

  // Serviços do Cadastro de Nova Ficha ----------------------------------------------------------------------------
  getLojistaByOperador(): Observable<any> {
    return this.restService.get('lojista/listaLojistaOperador');
  }

  getVendedoresByLojista(idLojista): Observable<any> {
    return this.restService.get(`vendedor/listByLojista/${idLojista}`);
  }

  getTipoVeiculo(): Observable<any> {
    return this.restService.get('veiculo/buscarTipoVeiculo');
  }

  getMarcaPorTipo(idTipoVeiculo: string): Observable<any> {
    return this.restService.get(`veiculo/buscarMarcaPorTipo/${idTipoVeiculo}`);
  }

  getAnoModeloPorMarca(idTipoVeiculo: string, idMarca: string): Observable<any> {
    return this.restService.get(`veiculo/buscarAnoModeloPorMarca/${idTipoVeiculo}/${idMarca}`);
  }

  getModeloPorMarcaAno(idTipoVeiculo: string, idMarca: string, idAnoModelo: string): Observable<any> {
    return this.restService.get(`veiculo/buscarModeloPorMarcaAno/${idTipoVeiculo}/${idMarca}/${idAnoModelo}`);
  }

  getVersaoPorMarcaModeloAno(idTipoVeiculo: string, idMarca: string, idAnoModelo: string, idModelo: string): Observable<any> {
    return this.restService.get(`veiculo/buscarVersaoPorMarcaModeloAno/${idTipoVeiculo}/${idMarca}/${idModelo}/${idAnoModelo}`);
  }

  getCotacaoPorVersao(idTipoVeiculo: string, idMarca: string, idAnoModelo: string, idModelo: string, idVersao: string): Observable<any> {
    return this.restService.get(`veiculo/buscarCotacaoPorVersao/${idTipoVeiculo}/${idMarca}/${idModelo}/${idVersao}/${idAnoModelo}`);
  }

  getAllNacionalidade() {
    return this.restService.get('nacionalidade/list');
  }

  getCidadePorUf(uf: string) {
    return this.restService.get(`cidade/listCidadeByUf/${uf}`);
  }

  getAllProfissoes() {
    return this.restService.get('profissao/list');
  }

  getProfissoesByClasseProfissional(id: number) {
    return this.restService.get(`profissao/listarProfissoesPorClasse/${id}`);
  }

  getEnderecoByCep(cep: string) {
    return this.restService.get(`cep/buscarEnderecoPorCep/${cep}`);
  }

  simular(payload: any) {
    return this.leadsOnlineRestService.post('vehicle/simulate', payload);
  }

  simulacao(simulacao: DadoSimulacao, parcelas?: number) {
    let url = `proposta/${simulacao.getNumeroProposta()}/simulacao/detalhe` +
      `?ultimaDataTaxa=${simulacao.getUltimaDataTaxa()}` +
      `&retorno=${simulacao.getSeguro().getRetorno()}` +
      `&carencia=${simulacao.getCarencia()}` +
      `&entradaPorcentagem=${simulacao.getEntradaPorcentagem()}` +
      `&parcelas=${parcelas || 0}`;

    if (simulacao.getValor() && simulacao.getValor() > 0) {
      url = `${url}&valorLiberado=${simulacao.getValor()}`;
    } else {
      url = `${url}&valorLiberado=0`;
    }
    if (simulacao.getSeguro().getGrupoSeguroIds() != null && simulacao.getSeguro().getGrupoSeguroIds().length) {
      simulacao.getSeguro().getGrupoSeguroIds().map(elem => elem.seguroId).forEach((item) => {
        url = `${url}&seguroId=${item}`;
      });
    } else {
      url = `${url}&seguroId=0`;
    }

    if (simulacao.getSeguro().getGrupoAssistenciaIds() != null && simulacao.getSeguro().getGrupoAssistenciaIds().length) {
      simulacao.getSeguro().getGrupoAssistenciaIds().map(elem => elem.assistenciaId).forEach((item) => {
        url = `${url}&assistenciaId=${item}`;
      });
    } else {
      url = `${url}&assistenciaId=0`;
    }
    return this.restService.get(url);
  }

  getDadosProposta(idProposta: string): Observable<any> {
    return this.restService.get(`proposta/${idProposta}/dados`).pipe(catchError((error, source) => {
      if (error.status === HttpStatus.BAD_REQUEST) {
        return observableOf(error.error);
      }
      return observableThrowError(error);
    }));
  }

  getPropostaBySimulacao(idProposta: string) {
    return this.restService.get(`veiculo/simulacao/${idProposta}`).pipe(catchError((error, source) => {
      if (error.status === HttpStatus.BAD_REQUEST) {
        return observableOf(error.error);
      }
      return observableThrowError(error);
    }));
  }

  getAllSeguros() {
    return this.restService.get('tiposSeguro');
  }

  getAllAssistencias() {
    return this.restService.get('assistencias');
  }

  getListaNomeMae(idProposta: string, idCliente: string) {
    return this.restService.get(`cliente/listNomeMae/${idProposta}/${idCliente}`);
  }

  finalizarSimulacao(payload: any) {
    return this.leadsOnlineRestService.post('vehicle/finishSimulation', payload);
  }

  verificarCpf(cpf: string) {
    return this.restService.get(`cliente/verificar/${cpf}`);
  }

  recuperarDadosFicha(cpf: string, idlojista) {
    return this.restService.get(`veiculo/recuperar-dados/${cpf}?lojista=${idlojista}`);
  }
  // Serviços do Cadastro de Nova Ficha ---------------------------------------------------------------------------

  sendSosObservacao(body: any): Observable<any> {
    return this.restService.post('proposta/pushObservacaoSos/', body);
  }

  prosseguirNegociacao(body: any): Observable<any> {
    return this.restService.post('proposta/pushProssegueNegociacao', body);
  }

  sendMotivoDesistencia(body: any): Observable<any> {
    return this.restService.post('proposta/pushMotivoDesistencia', body);
  }

  pushDocumentacaoEnviada(body: any): Observable<any> {
    return this.restService.post('proposta/pushDocumentacaoEnviada', body);
  }

  pushRespostaSos(body: any): Observable<any> {
    return this.restService.post('proposta/pushRespostaSos', body);
  }

  pushAvalista(body: any): Observable<any> {
    return this.restService.post('proposta/pushAvalista', body);
  }

  async intercept(runnable: () => any) {
    try {
      return await runnable();
    } catch (response) {
      if (response.error && response.error.messages && !`${response.error.messages[0]}`.includes('500')) {
        throw new Error(response.error.messages[0]);
      }
      console.log(response);

      throw new Error('Falha ao acessar o serviço!');
    }
  }
}
