export class Push {
  habilitaPush: boolean;
  idTipoPush: number;

  private _descTipoPush: string;
  private _isCanChange: boolean;

  constructor(
    idTipoPush: number,
    pushLigado: boolean,
    descTipoPush: string,
    isCanChange?: boolean,
  ) {
    this.idTipoPush = idTipoPush;
    this.habilitaPush = pushLigado;
    this._descTipoPush = descTipoPush;
    this._isCanChange = isCanChange || false;
  }

  get $descTipoPush(): string {
    return this._descTipoPush;
  }

  set $descTipoPush(descTipoPush: string) {
    this._descTipoPush = descTipoPush;
  }

  get $isCanChange(): boolean {
    return this._isCanChange;
  }
}
