import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import * as moment from 'moment';

import { appSettings } from '../../../../../../environments/app.setings';
import { ModalCarregarFichaComponent } from './modal-carregar-ficha/modal-carregar-ficha.component';
import { ClasseProfissionalService } from '../../../../../services/classe-profissional/classe-profissional.service';
import { Proposta } from '../../../../shared/models/proposta';
import { Data } from '../../../../../shared/partner/data';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { ClienteEndereco } from '../../../../shared/models/cliente';
import { ParametroRendaProduto } from '../../../../shared/models/parametro-renda-produto';
import { CdcLojaRestService } from '../../../../../omni-rest/cdc/cdc-loja-rest.service';
import { CdcLojaService, Tabela } from '../../../services/cdc-loja.service';
import { IdadePermitida } from '../../../../../produtos/shared/models/idade-permitida';
import { ClasseProfissional } from '../../../../../shared/dados-profissionais/model/classe-profissional';

@Component({
  selector: 'app-dados-analise',
  templateUrl: './dados-analise.component.html',
  styleUrls: ['./dados-analise.component.scss']
})
export class DadosAnaliseComponent implements OnInit, OnDestroy {
  @Input() parametroRendaProduto: ParametroRendaProduto;
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  private isAlive: boolean = true;
  showLoader: boolean = false;

  principalForm: FormGroup;
  dadosClienteForm: FormGroup;
  dadosConjugeForm: FormGroup;
  rendaForm: FormGroup;
  enderecosForm: FormGroup;
  showConjugeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  classesProfissionais: ClasseProfissional[];
  tabelas: Tabela[] = [];
  proposta: Proposta;

  dataRange = {
    min: 22,
    max: 85,
    message: 'A idade deve ser entre 22 e 85 anos'
  };

  constructor(
    private fb: FormBuilder,
    private classeProfissionalService: ClasseProfissionalService,
    private state: PropostaStateService,
    private cdcLojaService: CdcLojaService,
    private cdcLojaRestService: CdcLojaRestService,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    this.proposta = this.state.getLast();
    this.initForms();
    this.fillClassesProfissionais();
    this.fillTabelas();
    this.initObservers();
    this.getDataRange();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initForms(): void {
    const cpf = new FormControl(null, { validators: Validators.required, updateOn: 'blur' });
    this.dadosClienteForm = this.fb.group({
      cpf,
      dataNascimento: ['', Validators.required],
      classeProfissional: ['', Validators.required],
    });

    this.dadosConjugeForm = this.fb.group({
      cpfConjuge: ['', [Validators.required, this.validaCpfConjuge()]],
      dataNascimentoConjuge: ['', Validators.required]
    });

    this.rendaForm = this.fb.group({
      renda: ['', [Validators.required, Validators.maxLength(17)]],
      hasComprovanteRenda: [false],
      isComposicaoRenda: [false],
      isRendaAtribuida: [false],
      hasExperienciaCredito: [false],
      valorSolicitado: ['', [Validators.required, Validators.maxLength(17)]],
      entrada: ['', Validators.maxLength(17)],
      numeroParcelas: [''],
      valorDesejadoParcela: ['', Validators.maxLength(17)],
      cep: [''],
      endereco: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      uf: [''],
      cidade: ['']
    });

    this.principalForm = this.fb.group({
      dadosCliente: this.dadosClienteForm,
      tabela: ['', Validators.required]
    });

    this.enderecosForm = this.fb.group({});
    this.principalForm.addControl('renda', this.rendaForm);
  }

  private validaCpfConjuge(): { [key: string]: any } | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === this.cpf.value) {
        return { cpfConjugeError: true };
      }
      return null;
    };
  }

  private populateEnderecos() {
    if (this.proposta.cliente.enderecos) {
      const enderecos = this.proposta.cliente.enderecos;
      const residencialForm = enderecos.filter(e => e.tipoEndereco === 'RESIDENCIAL')[0] || {};

      this.enderecosForm.patchValue(
        { residencialForm },
        { emitEvent: false }
      );
    }
  }

  private populateForm() {
    this.showConjugeSubject.next(false);
    if (this.proposta.cliente && this.proposta.cliente.dadosProfissionais) {
      this.dadosClienteForm.patchValue({
        cpf: this.proposta.cliente.cpf,
        classeProfissional: `${this.proposta.cliente.dadosProfissionais.classeProfissional.id}`,
        dataNascimento: moment(new Date(this.proposta.cliente.dtNascimento)).format('DDMMYYYY')
      });
    }

    if (this.proposta.conjuge) {
      this.dadosConjugeForm.patchValue({
        cpfConjuge: this.proposta.conjuge.cpf,
        dataNascimentoConjuge: moment(new Date(this.proposta.conjuge.dtNascimento)).format('DDMMYYYY')
      });
    }

    if (this.proposta.cliente) {
      this.rendaForm.patchValue({ renda: (this.proposta.cliente.rendas[0] || { valor: undefined }).valor }, { emitEvent: false });
      this.populateEnderecos();
    }

    this.checkTelefonesCliente();
    this.rendaForm.patchValue({ valorSolicitado: this.proposta.valorLiquido }, { emitEvent: false });
  }

  private initObservers(): void {
    this.dadosClienteForm
      .valueChanges.pipe(
        takeWhile(() => this.isAlive))
      .subscribe(() => this.onDadosClienteFormChange());

    this.showConjugeSubject.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(res => this.handleShowConjugeChange(res));

  }

  private onDadosClienteFormChange(): void {
    this.showConjugeSubject.next(this.isOtherClasseProfissional());
    this.checkComposicaoRenda();
  }

  private isOtherClasseProfissional(): boolean {
    return this.classeProfissional.value === '9';
  }

  private checkComposicaoRenda(): void {
    if (this.classeProfissional.value === '9') {
      this.rendaForm.get('isComposicaoRenda').setValue(true);
      this.rendaForm.get('isComposicaoRenda').disable();
    } else {
      this.rendaForm.get('isComposicaoRenda').setValue(false);
      this.rendaForm.get('isComposicaoRenda').enable();
    }
  }

  private handleShowConjugeChange(showConjuge: boolean): void {
    if (showConjuge) {
      this.principalForm.addControl('dadosConjuge', this.dadosConjugeForm);
    } else {
      this.principalForm.removeControl('dadosConjuge');
    }
  }

  onPrincipalFormChanges(): void {
    this.fillInformacoesClienteObj();
    this.fillPropostaSimulacao();
    this.fillEnderecos();
  }

  private fillPropostaSimulacao(): void {
    this.proposta.valorLiquido = this.rendaForm.get('valorSolicitado').value * 1;
    this.proposta.valorEntrada = this.rendaForm.get('entrada').value * 1;

    const tabelaId = Number(this.principalForm.get('tabela').value);
    this.proposta.tabela = this.tabelas.filter(t => t.id === tabelaId)[0];
  }

  private fillInformacoesClienteObj(): void {
    const cliente = this.dadosClienteForm.value;

    if (this.proposta.cliente) {
      this.proposta.cliente.cpf = cliente.cpf;
    } else {
      this.proposta.cliente = { cpf: cliente.cpf };
    }

    this.proposta.cliente.dadosProfissionais = { classeProfissional: { id: Number(cliente.classeProfissional) } };

    const renda = this.rendaForm.value;
    this.proposta.cliente.rendas = [{
      rendaAtribuida: renda.isRendaAtribuida,
      tipoRenda: 'SALARIO',
      valor: renda.renda
    }];

    if (cliente.dataNascimento) {
      if (!this.proposta.cliente) {
        this.proposta.cliente = {};
      }
      this.proposta.cliente.dtNascimento = this.formatDateMiliseconds(cliente.dataNascimento);
    }
    if (this.showConjugeSubject.value) {
      this.proposta.conjuge = {
        cpf: this.cpfConjuge.value,
        dtNascimento: this.formatDateMiliseconds(this.dataNascimentoConjuge.value),
        dadosProfissionais: {},
        telefones: [{}]
      };
    }

    this.removeCaracteresEspeciaisFromInformacoesCliente();
  }

  private fillEnderecos() {
    const enderecoResidencial: ClienteEndereco = (this.enderecosForm.get('residencialForm') as FormGroup).getRawValue();
    enderecoResidencial.cep = (enderecoResidencial.cep || '').replace('-', '');
    enderecoResidencial.correspondencia = true;

    const enderecos = [];
    if (this.proposta.cliente.enderecos) {
      const enderecoComercial = this.proposta.cliente.enderecos.filter(e => e.tipoEndereco !== 'RESIDENCIAL')[0];
      if (enderecoComercial) {
        enderecos.push(enderecoComercial);
      }
    }
    enderecos.push(enderecoResidencial);

    this.proposta.cliente.enderecos = enderecos;
  }

  private removeCaracteresEspeciaisFromInformacoesCliente(): void {
    this.proposta.cliente.cpf =
      this.removeEspeciais(this.proposta.cliente.cpf);

    if (this.proposta.conjuge) {
      this.proposta.conjuge.cpf =
        this.removeEspeciais(this.proposta.conjuge.cpf);
    }
  }

  private removeEspeciais(str: string) {
    if (str) {
      return str.replace(/[^\d]/g, '');
    }
    return null;
  }

  private fillClassesProfissionais(): void {
    this.classeProfissionalService
      .classes().pipe(
        take(1))
      .subscribe(res => {
        this.classesProfissionais = res;
      });
  }

  private fillTabelas(): void {
    this.cdcLojaService
      .tabelas(this.proposta.operacao.id)
      .pipe(take(1))
      .subscribe(
        res => this.tabelas = res,
        error => console.error(error)
      );
  }

  get cpf(): AbstractControl {
    return this.dadosClienteForm.get('cpf');
  }

  get classeProfissional(): AbstractControl {
    return this.dadosClienteForm.get('classeProfissional');
  }

  get cpfConjuge(): AbstractControl {
    return this.dadosConjugeForm.get('cpfConjuge');
  }

  get dataNascimentoConjuge(): AbstractControl {
    return this.dadosConjugeForm.get('dataNascimentoConjuge');
  }

  get dataNascimento(): AbstractControl {
    return this.dadosClienteForm.get('dataNascimento');
  }

  get isRendaAtribuida(): AbstractControl {
    return this.rendaForm.get('isRendaAtribuida');
  }

  get hasExperienciaCredito(): AbstractControl {
    return this.rendaForm.get('hasExperienciaCredito');
  }

  onClickNext() {
    this.onPrincipalFormChanges();
    this.proposta.qtdeParcelas = 10;
    this.state.setState(this.proposta);
    this.next.emit();
  }

  onClickPrevious() {
    this.previous.emit();
  }

  private formatDateMiliseconds(data) {
    return Data.toMilissegundos(data);
  }

  get formValid() {
    return this.principalForm.valid && this.enderecosForm.valid;
  }

  async onCpfChange() {
    this.showLoader = true;
    try {
      const cliente = await this.cdcLojaService.consultarCPF(this.cpf.value).toPromise();
      if (cliente) {
        this.proposta.cliente = cliente;
        if (cliente.conjuge && cliente.conjuge.cpf) {
          this.proposta.conjuge = cliente.conjuge;
          delete cliente.conjuge;
        }
        this.populateForm();
        this.abrirModalCarregarFicha();
      }
    } finally {
      this.showLoader = false;
    }
  }

  abrirModalCarregarFicha() {
    if (this.proposta.cliente.ultimaFichaAberta) {
      const modal = this.modalService.show(ModalCarregarFichaComponent, appSettings.MODAL_PARAMS);

      modal.content.propostaId = this.proposta.cliente.ultimaFichaAberta;
    }
  }

  getDataRange() {
    this.cdcLojaRestService.get(`lojistas/${this.proposta.lojista.id}/idade-permitida`)
      .subscribe((response: IdadePermitida) => {
        this.dataRange = {
          min: response.idadeMinima,
          max: response.idadeMaxima,
          message: `A idade deve ser entre ${response.idadeMinima} e ${response.idadeMaxima} anos`
        };
      });
  }

  private checkTelefonesCliente() {
    this.proposta.cliente.telefones.forEach((telefone) => {
      if (!telefone.ddd || this.isValidDddTelefoneLength(telefone.ddd)) {
        return;
      }
      telefone.ddd = telefone.ddd.substring(1, 3);
    });
  }

  private isValidDddTelefoneLength(ddd: string): boolean {
    return ddd.length === 2;
  }
}
