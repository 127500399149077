import { FichaEstapasCadastro } from '../../shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';

export class CreditoPessoalEtapas {
  public static INICIAL = { numero: 0, descricao: '' };
  public static VENDEDOR = { numero: 1, descricao: 'Operador' };
  public static DADOS_ANALISE = { numero: 2, descricao: 'Dados para análise' };
  public static RESULTADO_PARCIAL = { numero: 3, descricao: 'Resultado parcial' };

  public static CLIENTE_DETALHES = { numero: 4.1, descricao: 'Detalhes' };
  public static CLIENTE = { numero: 4, descricao: 'Cliente', subetapas: [
    CreditoPessoalEtapas.CLIENTE_DETALHES,
    { numero: 4.2, descricao: 'Dados complementares' },
    { numero: 4.3, descricao: 'Endereços' }
  ]};

  public static AVALISTA1 = { numero: 5, descricao: 'Avalista 1', hide: true, subetapas: [
    { numero: 5.1, descricao: 'Detalhes' },
    { numero: 5.2, descricao: 'Dados complementares' },
    { numero: 5.3, descricao: 'Endereços' }
  ]};

  public static AVALISTA2 = { numero: 6, descricao: 'Avalista 2', hide: true, subetapas: [
    { numero: 6.1, descricao: 'Detalhes' },
    { numero: 6.2, descricao: 'Dados complementares' },
    { numero: 6.3, descricao: 'Endereços' }
  ]};

  public static AVALISTA3 = { numero: 7, descricao: 'Avalista 3', hide: true, subetapas: [
    { numero: 7.1, descricao: 'Detalhes' },
    { numero: 7.2, descricao: 'Dados complementares' },
    { numero: 7.3, descricao: 'Endereços' }
  ]};

  public static DADOS_FAEF = { numero: 8, descricao: 'Dados FAEF', hide: true, subetapas: [
    { numero: 8.1, descricao: 'Dados do empreendimento' },
    { numero: 8.2, descricao: 'Vendas e margem' },
    { numero: 8.3, descricao: 'Média mensal de vendas' },
    { numero: 8.4, descricao: 'Compras' },
    { numero: 8.5, descricao: 'Dados financeiros' },
    { numero: 8.6, descricao: 'Resultados contábeis' },
    { numero: 8.7, descricao: 'Parecer' },
  ]};
  public static DEMAIS_DADOS = { numero: 9, descricao: 'Demais dados' };
  public static ANALISE = { numero: 10, descricao: 'Análise' };

  public static etapas(): FichaEstapasCadastro[] {
    return [
      this.VENDEDOR,
      this.DADOS_ANALISE,
      this.RESULTADO_PARCIAL,
      this.CLIENTE,
      this.AVALISTA1,
      this.AVALISTA2,
      this.AVALISTA3,
      this.DADOS_FAEF,
      this.DEMAIS_DADOS,
      this.ANALISE,
    ];
  }

  public static next(current: FichaEstapasCadastro): FichaEstapasCadastro {
    return this.getEtapaByNumero(current, current.numero, 1);
  }

  public static previous(current: FichaEstapasCadastro): FichaEstapasCadastro {
    return this.getEtapaByNumero(current, current.numero, -1);
  }

  private static getEtapaByNumero(current: FichaEstapasCadastro, numero: number, increment: number) {
    if ((current.subetapas || this.isFloat(numero)) && Math.abs(increment) === 1) {
      const subetapa = this.getEtapaByNumero(current, numero, increment * 0.1);
      if (subetapa) {
        return subetapa;
      }

      return this.getEtapaByNumero(current, Math.trunc(numero), increment);
    }

    // calculo para nao gerar problema do ponto flutuante <https://www.w3schools.com/js/js_numbers.asp>
    const numeroEtapa = (numero * 10 + increment * 10) / 10;
    const etapa = this.etapas().find(etapaF => etapaF.numero === Math.trunc(numeroEtapa));
    if (etapa) {
      if (etapa.hide) {
        return this.getEtapaByNumero(current, numeroEtapa, increment);
      }

      if (etapa.subetapas) {
        if (etapa.numero < numeroEtapa) {
          const subetapa = etapa.subetapas.filter(e => !e.hide).find(sub => sub.numero === numeroEtapa);
          if (subetapa) {
            return subetapa;
          }

          return this.getEtapaByNumero(current, Math.trunc(numero), increment * 10);
        }

        // se esta retornando da primeira subetapa
        if (increment === -0.1) {
          return this.getEtapaByNumero(current, numeroEtapa, increment * 10);
        }

        return etapa.subetapas.filter(e => !e.hide).sort((a, b) => (a.numero - b.numero) * Math.sign(increment))[0];
      }

      return etapa;
    }
    return current;
  }

  private static isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
}
