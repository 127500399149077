import { FaefComercio } from './../../model/faef';
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-mercadoria-faef',
  templateUrl: './mercadoria-faef.component.html',
  styleUrls: ['./mercadoria-faef.component.scss']
})
export class MercadoriaFaefComponent {

  @Input() mercadoria: FaefComercio;
  @Output() remove = new EventEmitter<FaefComercio>();
  @Output() edit = new EventEmitter<FaefComercio>();

}
