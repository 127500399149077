import { Component, OnInit, Injector } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { LeadsOnlineService } from '../produtos/leads/services/leads.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  leadsOnlineService: LeadsOnlineService;
  isleads: boolean = false;

  constructor(private service: DashboardService, private injector: Injector) { }

  ngOnInit() {
    this.initLeadsOnline();
  }

  async initLeadsOnline() {
    this.leadsOnlineService = this.injector.get(LeadsOnlineService);
    try {
      const response = await this.leadsOnlineService.getOriginadorLeads();
      this.isleads = response.originadorLeads;
    } catch (e) {
      toastr.warning('Erro ao consultar tipo de originador Leads Online. Caso seja um parceiro Leads, favor contatar o suporte', 'Atenção');
    }
  }

  get hasLeads() {
    return this.isleads;
  }
}
