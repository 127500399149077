import { FichaListaFiltro } from './../model/ficha-lista-filtro';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { fichasFilter, calcularQuantidadeSOS } from '../../helpers/filter';
@Component({
  selector: 'app-ficha-aprovada',
  templateUrl: './ficha-aprovada.component.html',
  styleUrls: ['./ficha-aprovada.component.css']
})
export class FichaAprovadaComponent {

  @Input() propostas = [];
  @Input() filtro: FichaListaFiltro;
  @Output() quantidadeSos =  new EventEmitter();
  public fichasFiltradas;

  filter() {
    this.fichasFiltradas = fichasFilter(this.filtro, this.propostas);
    this.quantidadeSos.emit(calcularQuantidadeSOS(this.fichasFiltradas));
    return this.fichasFiltradas;
  }
}
