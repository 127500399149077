import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { finalize, takeWhile } from 'rxjs/operators';
import { OmniFacilUploadRestService } from '../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { FichaService } from '../ficha-veiculo.service';
import { ModalAvisoAcessoCameraComponent } from './before-selfie/modal-aviso-acesso-camera/modal-aviso-acesso-camera.component';
import { ModalDicasFotoVerificacaoComponent } from './before-selfie/modal-dicas-foto/modal-dicas-foto-verificacao.component';
import {
  ConfirmaDadosAssinatura,
  ParamsConfirmacaoCPFUserChave,
  ConfirmacaoCPFUserChave,
  UrlParams
} from './confirma-dados-assinatura';
import { FichaVerificacaoSegurancaService } from './ficha-verificacao-seguranca.service';
import { ModalAvisoCpfInvalidoComponent } from './verificacao-seguranca-identificacao/modal-aviso-cpf-invalido/modal-aviso-cpf-invalido.component';
import { ModalErroComponent } from './verificar/modal-erro/modal-erro.component';
import { ModalTermosComponent } from './verificar/modal-termos/modal-termos.component';
import { ModalVerificacaoComponent } from './verificar/modal-verificacao/modal-verificacao.component';
import { ModalAvisoIosComponent } from './bem-vindo/modal-aviso-ios/modal-aviso-ios.component';

const BEMVINDO = 1;
const IDENTIFICACAO = 1.1;
const VERIFICAR = 2;
const CONCLUIDO = 3;
const BEFORE_SELFIE = 4;
const SELFIE = 5;
const AFTER_SELFIE = 6;

const COD_OMNI_DOC_SELFIE = 164;
@Component({
  selector: 'app-ficha-verificacao-seguranca',
  templateUrl: './ficha-verificacao-seguranca.component.html',
  styleUrls: ['./ficha-verificacao-seguranca.component.scss'],
})
export class FichaVerificacaoSegurancaComponent implements OnInit, OnDestroy {
  statusVerificacao = BEMVINDO;
  dadosAssinatura: ConfirmaDadosAssinatura;
  isLoading = false;
  isAlive = true;
  foto: File;
  loadingSaveSelf = false;
  loadingGetToken = false;
  dataToken: any;
  cpf: string;
  urlAssinaturaDigital: string;
  numeroPreContrato: string;

  constructor(
    private modal: BsModalService,
    private route: ActivatedRoute,
    private service: FichaService,
    private router: Router,
    private omniRestService: OmniFacilUploadRestService,
    private fichaVerificacaoSegurancaService: FichaVerificacaoSegurancaService
  ) {}

  ngOnInit() {
    this.initObservableParams();

    if (this.isIos() && !this.isSafari()) {
      this.modal.show(ModalAvisoIosComponent, {
        class: 'modal-sm',
        keyboard: false,
        ignoreBackdropClick: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private initObservableParams(): void {
    this.route.params
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((params: UrlParams) => {
        if (Object.keys(params).length === 0 || !params.chave) {
          this.router.navigate(['/login']);
          return;
        }
        this.dadosAssinatura = new ConfirmaDadosAssinatura();
        this.dadosAssinatura.idCliente = params.chave;
        this.dadosAssinatura.tipoComunicacao = params.tipoComunicacao;
      });
  }

  onClickNext(): void {
    switch (this.status) {
      case BEMVINDO:
        this.statusVerificacao = IDENTIFICACAO;
        break;
      case CONCLUIDO:
        this.statusVerificacao = BEFORE_SELFIE;
        break;
      case BEFORE_SELFIE:
        this.statusVerificacao = SELFIE;
        break;
      case SELFIE:
        this.statusVerificacao = AFTER_SELFIE;
        break;
    }
  }

  onClickTypeCode() {
    const verificarCodigoModal = <ModalVerificacaoComponent>this.modal.show(
      ModalVerificacaoComponent,
      {
        class: 'modal-sm',
        keyboard: false,
        ignoreBackdropClick: true,
      }
    ).content;
    verificarCodigoModal.onChangeCodigoSeguranca
      .pipe(takeWhile(() => verificarCodigoModal.alive))
      .subscribe((event: any) => {
        this.isLoading = true;
        this.service
          .confirmContactDetails({
            ...this.dadosAssinatura,
            token: event.codigoSeguranca,
          })
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            () => {
              this.statusVerificacao = CONCLUIDO;
            },
            () => {
              this.onErrorHandler();
            }
          );
      });
  }

  onErrorHandler(): void {
    const modalErro = <ModalErroComponent>this.modal.show(ModalErroComponent, {
      class: 'modal-sm',
      keyboard: false,
      ignoreBackdropClick: true,
    }).content;
    modalErro.onChangeDigitarNovamente
      .pipe(takeWhile(() => modalErro.alive))
      .subscribe(() => this.onClickTypeCode());
  }

  onClickTermos() {
    this.modal.show(ModalTermosComponent, {
      class: 'modal-lg',
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }

  onClickDicaFotoSelfie() {
    this.modal.show(ModalDicasFotoVerificacaoComponent, {
      class: 'modal-sm2',
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }

  onClickAvisoPermissaoCamera() {
    const modal = this.modal.show(ModalAvisoAcessoCameraComponent, {
      class: 'modal-sm2',
      keyboard: false,
      ignoreBackdropClick: true,
    }).content;

    modal.closeModal.subscribe(() => {
      this.onClickNext();
    });
  }

  onClick(): void {
    if (this.isDisabledButtonClick) {
      return;
    }

    switch (this.status) {
      case BEMVINDO:
      case CONCLUIDO:
      case SELFIE:
        return this.onClickNext();
      case BEFORE_SELFIE:
        return this.onClickAvisoPermissaoCamera();
      case VERIFICAR:
        return this.onClickTypeCode();
      case AFTER_SELFIE:
        this.saveOnOmniDocSelfie();
        break;
      case IDENTIFICACAO:
        this.onClickGetToken();
        break;
    }
  }

  getLabel(): string {
    switch (this.status) {
      case BEMVINDO:
      case BEFORE_SELFIE:
      case IDENTIFICACAO:
        return 'CONTINUAR';
      case VERIFICAR:
        return 'DIGITAR CÓDIGO';
      case CONCLUIDO:
        return 'INICIAR ASSINATURA';
      case AFTER_SELFIE:
        return 'CONFIRMAR';
    }
  }

  get status(): number {
    return this.statusVerificacao;
  }

  public capturePhoto(foto: File): void {
    this.foto = foto;
    this.onClick();
  }

  public onClickPickNewPhoto(): void {
    this.foto = null;
    this.statusVerificacao = SELFIE;
  }

  async saveOnOmniDocSelfie(): Promise<void> {
    this.loadingSaveSelf = true;
    try {
      if (!this.dataToken) {
        this.loadingSaveSelf = false;
        return;
      }

      const configToken = {
        login: this.dataToken.login,
        token: this.dataToken.token,
      };

      const proposta = {
        id: Number(this.dadosAssinatura.idProposta),
        origem: 1,
        cliente: { cpf: this.cpf },
        numeroPreContrato: this.numeroPreContrato
      };
      await this.omniRestService
        .post(this.foto, proposta, COD_OMNI_DOC_SELFIE, configToken)
        .toPromise();

      window.location.href = this.urlAssinaturaDigital;
    } finally {
      this.loadingSaveSelf = false;
    }
  }

  async onClickGetToken(): Promise<void> {
    this.loadingGetToken = true;
    this.isLoading = true;
    try {
      const params: ParamsConfirmacaoCPFUserChave = {
        cpf: this.cpf,
        chave: this.dadosAssinatura.idCliente,
        tipoComunicacao: this.dadosAssinatura.tipoComunicacao,
      };
      const dataToken: ConfirmacaoCPFUserChave = await this.fichaVerificacaoSegurancaService
        .getTokenByCpfValidacaoSegurancao(params)
        .toPromise();
      this.dataToken = dataToken.autenticacao;
      this.urlAssinaturaDigital = dataToken.urlAssinatura;
      this.numeroPreContrato = dataToken.numeroPreContrato;
      Object.assign(this.dadosAssinatura, dataToken.dadosProposta);
      this.statusVerificacao = VERIFICAR;
    } catch (err) {
      if (!err || !err.error) {
        return;
      }
      this.showModalInvalidCpf(err.error.message);
    } finally {
      this.loadingGetToken = false;
      this.isLoading = false;
    }
  }

  private showModalInvalidCpf(message: string) {
    this.modal.show(ModalAvisoCpfInvalidoComponent, {
      class: 'modal-sm2',
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: { message }
    });
  }

  public changeCpf(cpf): void {
    this.cpf = cpf;
  }

  private isSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  private isIos() {
    const iosVersions = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];

    const hasIos = iosVersions.filter((version) => version === navigator.platform);

    return hasIos.length > 0
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  public get isDisabledButtonClick(): boolean {
    return (
      (this.status === 1 && this.isIos() && !this.isSafari()) ||
      (this.status === 6 && this.loadingSaveSelf) ||
      (this.status === 1.1 &&
        (this.loadingGetToken || !this.cpf || this.cpf.length !== 11))
    );
  }

  public onClickErroWebcam() :void {
    this.statusVerificacao = BEFORE_SELFIE;
  }
}
