export class LoginCredencial {
  login?: string;
  senha?: string;
  plataforma = 'WEB';
  versaoAplicacao = '1';
  idCodAplicacao = '1';
  tokenPush: string;
  socialToken?: string;
  redeSocial?: string;
}
