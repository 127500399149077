import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';

import * as toastr from 'toastr';

import pt from '@angular/common/locales/pt';

if (environment.production) {
  const tmpConsoleLog = console.log;
  console.log = (message?: any, ...optionalParams: any[]) => {
    if (localStorage.enableConsole) {
      tmpConsoleLog(message, optionalParams);
    }
  };

  enableProdMode();
}

registerLocaleData(pt, 'pt');

platformBrowserDynamic().bootstrapModule(AppModule);

// configura o padrao do toastr
(toastr as any).options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 15000,
  extendedTimeOut: 3000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};
