import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-sos-respondido',
  templateUrl: './ficha-timeline-sos-respondido.component.html',
  styleUrls: ['./ficha-timeline-sos-respondido.component.css']
})
export class FichaTimelineSosRespondidoComponent {

  @Input() acoes: any;
  @Input() timelineActive: boolean;
}
