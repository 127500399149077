import { Agrupamento } from './agrupamento';
export class AgrupamentoLoja implements Agrupamento {

  descricao = 'loja';

  getKey(proposta: any): string {
    return proposta.nomeLoja;
  }

  compare(proposta1: any, proposta2: any): number {
    return proposta1.nomeLoja.localeCompare(proposta2.nomeLoja);
  }
}
