import { ButtonSelectorData } from '../button-selector/button-selector-data';
import { TipoVeiculoEnum } from '../../../../produtos/shared/enums/tipo-veiculo.enum';

export class TipoVeiculoHandler {

  public static handle(values: [{descricao: string}]): ButtonSelectorData[] {
    const operacoes = [];
    values
      .forEach(value => this.handleDescricao(value.descricao, operacoes));
    return operacoes.sort((a, b) => a.order - b.order);
  }

  private static handleDescricao(descricao, operacoes: ButtonSelectorData[]) {
    if (descricao === 'Automóveis') {
      operacoes.push(new ButtonSelectorData(TipoVeiculoEnum.AUTOMOVEIS, 'Automóveis', 'automovel', 1));
    } else if (descricao === 'Motocicletas') {
      operacoes.push(new ButtonSelectorData(TipoVeiculoEnum.MOTOCICLETAS, 'Motocicletas', 'motocicleta', 2));
    }
  }
}
