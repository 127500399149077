import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DadosGrafico } from './dados-grafico';

declare var Chart: any;

@Component({
  selector: 'app-painel-meu-desempenho',
  templateUrl: './painel-meu-desempenho.component.html',
  styleUrls: ['./painel-meu-desempenho.component.css']
})
export class PainelMeuDesempenhoComponent implements OnInit, OnChanges {

  @ViewChild('canvasHolder', { static: true }) canvasHolder: ElementRef;
  @Input() totaisStatus: any[];
  @Input() loading;

  dadosGrafico: DadosGrafico;

  ngOnInit() {
    this.initConfigGrafico();
  }

  ngOnChanges() {
    this.clearGrafico();
    if (this.totaisStatus && this.totaisStatus.length > 0) {
      this.dadosGrafico = new DadosGrafico(this.totaisStatus);
      this.graficoChangeDados();
    }
  }

  initConfigGrafico() {
    Chart.plugins.register({
      afterDatasetsDraw: (chartInstance, easing) => {
        const ctx = chartInstance.chart.ctx;
        const fontFamily = 'Montserrat, sans-serif';
        let total = 0;

        chartInstance.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.getDatasetMeta(i);
          if (!meta.hidden) {
            meta.data.forEach((element, index) => {
              ctx.fillStyle = '#ffffff';
              const fontSize = 14;
              ctx.font = Chart.helpers.fontString(fontSize, 'normal', fontFamily);
              const data = dataset.data[index];
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const padding = 0;
              const position = element.tooltipPosition();
              ctx.fillText(data, position.x, position.y - (fontSize / 2) - padding);
              total += data;
            });
          }
        });

        ctx.fillStyle = '#002c7c';
        ctx.font = Chart.helpers.fontString(35, 'bold', fontFamily);
        ctx.fillText(total, chartInstance.width / 2 - 80, chartInstance.height / 2 - 10, 200);

        ctx.fillStyle = '#474947';
        ctx.font = Chart.helpers.fontString(14, 'normal', fontFamily);
        ctx.fillText('fichas', chartInstance.width / 2 - 80, chartInstance.height / 2 + 20, 200);
      }
    });
  }

  private graficoChangeDados() {
    const config = {
      type: 'doughnut',
      data: {
        datasets: [{
          vlabel: '# of Votes',
          data: this.dadosGrafico.valores,
          backgroundColor: this.dadosGrafico.cores,
          borderColor: [
            'rgba(255, 255, 255, 1)'
          ],
          borderWidth: 1
        }],
        labels: this.dadosGrafico.status
      },
      options: {
        showAllTooltips: true,
        cutoutPercentage: 65,
        responsive: true,
        legend: {
          display: true,
          position: 'right',
          padding: 50,
          showTooltips: true
        },
        title: {
          display: false,
          text: 'Gráfico'
        },
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    };

    this.canvasHolder.nativeElement.innerHTML = '<canvas id="chart-area"></canvas>';
    const chart: any = document.getElementById('chart-area');
    const ctx = chart.getContext('2d');

    (window as any).myDoughnut = new Chart(ctx, config);
  }

  clearGrafico() {
    this.canvasHolder.nativeElement.innerHTML = '';
  }
}
