import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadersModule } from '../../../shared/loaders/loaders.module';
import { VerificacaoSegurancaClearsaleModule } from './verificacao-seguranca-clearsale/verificacao-seguranca-clearsale.module';
import { VerificacaoSegurancaRoutingModule } from './verificacao-seguranca-routing.module';
import { VerificacaoSegurancaUnicoModule } from './verificacao-seguranca-unico/verificacao-seguranca-unico.module';
import { VerificacaoSegurancaComponent } from './verificacao-seguranca.component';
import { VerificacaoSegurancaService } from './verificacao-seguranca.service';

@NgModule({
  declarations: [
    VerificacaoSegurancaComponent
  ],
  imports: [
    CommonModule,
    VerificacaoSegurancaClearsaleModule,
    VerificacaoSegurancaUnicoModule,
    VerificacaoSegurancaRoutingModule,
    LoadersModule
  ],
  providers: [VerificacaoSegurancaService],
})
export class VerificacaoSegurancaModule { }
