import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-dica-de-foto',
  templateUrl: './modal-dica-de-foto.component.html',
  styleUrls: ['./modal-dica-de-foto.component.scss']
})
export class ModalDicaDeFotoComponent {

  constructor(private bsModalRef: BsModalRef) { }

  closeOnClick() {
    this.bsModalRef.hide();
  }
}
