import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appFallbackImage]'
})
export class FallbackImageDirective {

  @Input('appFallbackImage') image: string;

  constructor(private eRef: ElementRef) { }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    if (element.src !== this.image && element.src !== 'https://www.bizfacil.com.br/bs/images/user.png') {
      element.src = this.image || 'https://www.bizfacil.com.br/bs/images/user.png';
    }
  }
}
