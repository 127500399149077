import { Injectable } from '@angular/core';
import { CreditoPessoalRestService } from '../../../omni-rest/cp/credito-pessoal-rest.service';

@Injectable()
export class RepiqueService {
  constructor(private restService: CreditoPessoalRestService) {}

  buscarPorCpf(operacao: number, cpf: string) {
    return this.restService
      .get(`repiques?operacao=${operacao}&cpf=${cpf}`)
      .toPromise();
  }

  carregarContratoOrigem(contratoId: string) {
    return this.restService
      .get(`repiques/contratos/${contratoId}`)
      .toPromise();
  }

}
