export function setFichaContent(data) {
  getFichaContent().then(item => {
    const content = { ...item, ...data };
    localStorage.setItem('ficha', JSON.stringify(content));
  });
}

export function getFichaContent() {
  return new Promise((resolve, reject) => {
    try {
      resolve(JSON.parse(localStorage.getItem('ficha')));
    } catch (err) {
      reject(err);
    }
  });
}

export function removeFichaContent() {
  localStorage.removeItem('ficha');
}
