import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { WebcamModule } from 'ngx-webcam';
import 'rxjs/Rx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { ConfiguracaoModule } from './configuracao/configuracao.module';
import { ContextSharedService } from './context-shared/context-shared.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { DirectivesModule } from './directives/directives.module';
import { FichaVeiculoModule } from './ficha-veiculo/ficha-veiculo.module';
import { FichaVerificacaoSegurancaModule } from './ficha-veiculo/ficha-verificacao-seguranca/ficha-verificacao-seguranca.module';
import { InicioModule } from './ficha/cadastro/inicio/inicio.module';
import { LoginModule } from './login/login.module';
import { MenuSharedService } from './menu/menu-shared.service';
import { MenuComponent } from './menu/menu.component';
import { OmniRestModule } from './omni-rest/omni-rest.module';
import { ProdutosModule } from './produtos/produtos.module';
import { ServicesModule } from './services/services.module';
import { DadosProfissionaisModule } from './shared/dados-profissionais/dados-profissionais.module';
import { SharedModule } from './shared/shared.module';
import { SimuladorVeiculoFichaService } from './simulador-veiculo/services/simulador-veiculo-ficha.service';
import { SimuladorModule } from './simulador/simulador.module';
import { DataTablesModule } from 'angular-datatables';
import { Select2Module } from 'ng2-select2';

@NgModule({
  declarations: [AppComponent, MenuComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ServicesModule.forRoot(),
    LoginModule,
    OmniRestModule,
    FichaVeiculoModule,
    DashboardModule,
    DashboardModule,
    DirectivesModule,
    DadosProfissionaisModule,
    ConfiguracaoModule,
    InicioModule,
    WebcamModule,
    SimuladorModule,
    ProdutosModule,
    AppRoutingModule,
    FichaVerificacaoSegurancaModule,
    DataTablesModule,
    Select2Module
  ],
  bootstrap: [AppComponent],
  providers: [
    MenuSharedService,
    ContextSharedService,
    SimuladorVeiculoFichaService
  ],
})
export class AppModule { }
