import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as toastr from 'toastr';
import { FichaService } from '../../../ficha-veiculo.service';
import { DadosAssinatura } from '../model/dados-assinatura';
import { DadosEnvio } from '../model/dados-envio';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-emissao-pre-contrato',
  templateUrl: './modal-emissao-pre-contrato.component.html',
  styleUrls: ['./modal-emissao-pre-contrato.component.scss']
})
export class ModalEmissaoPreContratoComponent implements OnInit, OnDestroy {

  listTipoAssinatura = [
    { id: 1, descricao: 'Manual' },
    { id: 2, descricao: 'Eletrônica' }
  ];

  listTipoEnvio = [
    {
      id: 1,
      descricao: 'Via Celular',
      detalhe1: 'O cliente irá receber uma mensagem no celular cadastrado',
      detalhe2: 'com as informações do contrato e como assina-lo.'
    },
    {
      id: 2,
      descricao: 'Via E-mail',
      detalhe1: 'O cliente irá receber um link no e-mail cadastrado',
      detalhe2: 'com as informações do contrato e como assina-lo.'
    }
  ];

  @Input() proposta: any;
  dadosAssinatura: DadosAssinatura;
  dadosEnvio: DadosEnvio;
  isLoading = false;
  alive = true;
  closeModal: Subject<DadosEnvio> = new Subject();

  formTipoAssinatura: FormGroup;

  constructor(
    private service: FichaService,
    private modalInstance: BsModalRef,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    this.getDadosAssinatura();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  initForm() {
    this.formTipoAssinatura = this.fb.group({
      tipoAssinatura: ['', Validators.required],
      tipoEnvio: [0, Validators.required]
    });
  }

  getDadosAssinatura(): void {
    this.isLoading = true;
    this.service.getDataSign(this.proposta.id)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        (response) => {
          this.dadosAssinatura = response;
          this.setDadosEnvio();
        },
        (error) => toastr.error('Não conseguimos preencher as informações da proposta')
      );
  }

  onClickEmitirPreContrato() {
    this.dadosEnvio.tipoComunicacao = this.tipoEnvio.toString();
    this.close(this.dadosEnvio);
  }

  close(dadosEnvio?: DadosEnvio) {
    this.closeModal.next(dadosEnvio);
    this.modalInstance.hide();
  }

  setDadosEnvio(): void {
    const cliente = this.dadosAssinatura.clientes[0];
    this.dadosEnvio = {
      idCliente: cliente.id,
      idProposta: this.dadosAssinatura.idProposta,
      tipoComunicacao: '0',
    };
  }

  onChangeTipoAssinatura(tipoAssinatura) {
    if (tipoAssinatura.id === 1) {
      this.formTipoAssinatura.patchValue({ tipoEnvio: 0 });
    }
  }

  onChangeTipoEnvio(tipoEnvio) {
    this.formTipoAssinatura.patchValue({ tipoEnvio: tipoEnvio.id });
  }

  get formValid() {
    return this.tipoAssinatura === 1 || (this.tipoAssinatura === 2 && this.tipoEnvio);
  }

  get tipoAssinatura() {
    return this.formTipoAssinatura.get('tipoAssinatura').value;
  }

  get tipoEnvio() {
    return this.formTipoAssinatura.get('tipoEnvio').value;
  }

  get celular() {
    if (!this.dadosAssinatura || !this.dadosAssinatura.clientes) {
      return '(XX XXXXX-XXXX)';
    }

    const digitosFinais = this.dadosAssinatura.clientes[0].nrTelefone.substr(-2);
    return `(XX XXXX-XX${digitosFinais})`;
  }

  get email() {
    if (!this.dadosAssinatura || !this.dadosAssinatura.clientes) {
      return '';
    }

    const { email } = this.dadosAssinatura.clientes[0];
    const user = email.substr(0, email.indexOf('@'));
    const firstDigit = user.substr(0, 1);
    const userEmail = user.substr(1);
    const finalEmail = email.substr(email.indexOf('@'));
    const hiddenEmail = userEmail.replace(/([^0-9a-zA-Z]|\w)/g, '*');

    return `(${firstDigit}${hiddenEmail}${finalEmail})`;
  }
}
