import { Component, OnInit, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-alterar-valor',
  templateUrl: './alterar-valor.component.html',
  styleUrls: ['./alterar-valor.component.scss']
})
export class AlterarValorComponent implements OnInit {

  @Input() valorMaximo: number;
  @Input() valorMinimo: number;
  @ViewChild('valorFinanciamentoInput', { static: true }) valorFinanciamentoInput: ElementRef;

  alive: boolean = true;
  alterarValorForm: FormGroup;
  parcelasPersonalizada: number[];
  onSave: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.alterarValorForm = this.fb.group({
      valorFinanciamento: ['', [Validators.required, Validators.min(this.valorMinimo), Validators.max(this.valorMaximo)]]
    });

    setTimeout(() => {
      this.valorFinanciamentoInput.nativeElement.focus();
    });
  }

  get valorFinanciamento() {
    return this.alterarValorForm.get('valorFinanciamento');
  }

  save() {
    this.onSave.emit(this.valorFinanciamento.value);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }
}
