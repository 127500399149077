import { Directive, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appDataValidator],[appDataValidator][ngModel]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DataNascimentoValidatorDirective)
    }
  ]
})
export class DataNascimentoValidatorDirective {

  @Input() dateRange;
  @Output() valid = new EventEmitter<boolean>();

  validate(c: AbstractControl): { [key: string]: any } {
    if (this.validateData(c.value)) {
      this.valid.emit(true);
      return null;
    }

    this.valid.emit(false);
    return { message: this.dateRange.message };
  }

  validateData(dateString) {
    if (dateString && dateString.length === 8 && this.checkYear(dateString)) {
      const idade = moment().diff(moment(dateString, 'DDMMYYYY'), 'years');
      return idade >= this.dateRange.min && idade <= this.dateRange.max;
    }
  }

  checkYear(value) {
    const lDay = parseInt(value.substring(0, 2));
    const lMon = parseInt(value.substring(2, 4));
    const lYear = parseInt(value.substring(4));
    const BiY = (lYear % 4 === 0 && lYear % 100 !== 0) || lYear % 400 === 0;
    const MT = [1, BiY ? -1 : -2, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1];
    return lMon <= 12 && lMon > 0 && lDay <= MT[lMon - 1] + 30 && lDay > 0;
  }
}
