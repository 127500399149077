import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-reprovada',
  templateUrl: './ficha-timeline-reprovada.component.html',
  styleUrls: ['./ficha-timeline-reprovada.component.css']
})
export class FichaTimelineReprovadaComponent {
  @Input() timeline: any;
  @Input() timelineActive: boolean;
}
