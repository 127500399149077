import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, finalize, map, take, takeWhile, timeout } from 'rxjs/operators';
import * as toastr from 'toastr';

import { appSettings } from '../../../../../environments/app.setings';
import { ClienteEndereco } from '../../../../produtos/shared/models/cliente';
import { Proposta } from '../../../../produtos/shared/models/proposta';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { EcommerceEtapas } from '../ecommerce-etapas';
import { PropostaEnviadaComponent } from './proposta-enviada/proposta-enviada.component';

@Component({
  selector: 'app-dados-contato',
  templateUrl: './dados-contato.component.html',
  styleUrls: ['./dados-contato.component.scss'],
})
export class DadosContatoComponent implements OnInit, OnDestroy {
  @Output() previous = new EventEmitter();

  proposta: Proposta;
  form: FormGroup;
  enderecoForm: FormGroup;
  showLoader = false;
  nomeClienteSubscriptions: Subscription[] = [];
  showMensagemNomeCliente: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private cdcLojaService: CdcLojaService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initForm();
    this.initObservers();
    this.proposta = EcommerceEtapas.proposta;
  }

  private initForm(): void {
    this.form = this.fb.group({
      nomeCompleto: ['', Validators.required],
      celular: ['', Validators.required],
      email: ['', Validators.required],
    });

    this.enderecoForm = this.fb.group({});
    this.form.addControl('endereco', this.enderecoForm);
  }

  onClickPrevious() {
    this.previous.emit();
  }

  async onClickNext() {
    this.showLoader = true;

    const controls = this.form.controls;
    const celular: string = controls.celular.value.replace(/\W/g, '');

    this.proposta.cliente.email = controls.email.value;

    this.proposta.cliente.enderecos = [];
    this.proposta.cliente.telefones = [];
    const enderecoResidencial: ClienteEndereco = (this.enderecoResidencialForm as FormGroup).getRawValue();
    this.proposta.cliente.enderecos.push(enderecoResidencial);
    this.proposta.cliente.telefones.push({
      ddd: celular.substring(0, 2),
      nrTelefone: celular.substring(2, celular.length),
      categoriaTelefone: 'CELULAR',
      tipoTelefone: 'PROPRIO'
    });
    this.proposta.cliente.nomeCliente = controls.nomeCompleto.value;
    this.proposta.rastreioStatusList = [
      {
        codigo: 32,
        descricao: 'AGUARDANDO BIOMETRIA',
      },

      {
        codigo: 34,
        descricao: 'RESULTADO DA SIMULACAO',
      },
    ];

    this.salvarProposta();
  }

  private openModal() {
    const modal = this.modalService.show(
      PropostaEnviadaComponent,
      {
        ...appSettings.MODAL_PARAMS,
        initialState: { propostaId: this.proposta.id }
      }
    );

    modal.content.continue
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe((c: boolean) => {
        this.showLoader = false;
        if (c) {
          this.router.navigate(['/produtos/cdcloja/timelines'], {
            queryParams: { id: EcommerceEtapas.proposta.id },
          });
        } else {
          this.router.navigate(['ficha']);
        }
      });
  }

  private salvarProposta() {

    try {
      this.cdcLojaService
        .salvarProposta(this.proposta)
        .pipe(
          timeout(50000),
          take(1)
        ).subscribe(_ => this.enviaSMS());

    } catch (err) {
      toastr.error('Não foi possivel prosseguir com a proposta', 'Falha ao acessar o servidor');
    }

  }

  private enviaSMS() {
    const reqBody = {
      proposta: {
        id: this.proposta.id,
        parceiro: this.proposta.parceiro,
        cliente: {
          telefones: this.proposta.cliente.telefones,
        },
      },
    };

    this.cdcLojaService
      .enviarSms(reqBody)
      .pipe(
        finalize(() => this.showLoader = false),
        timeout(50000),
        take(1)
      ).subscribe(_ => this.openModal());
  }

  get enderecoResidencialForm(): AbstractControl {
    return this.enderecoForm.get('residencialForm');
  }

  private initObservers() {
    this.nomeClienteSubscriptions.push(this.nomeCliente.valueChanges.pipe(
      debounceTime(200),
      map(str => this.removeDigits(str))
    ).subscribe(((value) => {
      this.nomeCliente.setValue(value, { emitEvent: false });
    })));
  }

  private removeDigits(value: string): string {
    if (!value) {
      return '';
    }

    if (!!value.match(/\d/g)) {
      this.showMensagemNomeCliente = true;
      return value.replace(/\d/g, '');
    }
    this.showMensagemNomeCliente = false;
    return value;
  }

  get nomeCliente(): FormControl {
    return this.form.controls['nomeCompleto'] as FormControl;
  }

  get nomeClienteValido(): boolean {
    if (!!this.nomeCliente.value.match(/\d/g)) {
      this.showMensagemNomeCliente = true;
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.nomeClienteSubscriptions) {
      this.nomeClienteSubscriptions.forEach(sub => sub.unsubscribe());
    }
  }
}
