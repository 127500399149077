import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';

import { CdcLojaRestService } from '../../../omni-rest/cdc/cdc-loja-rest.service';

export interface UrlCapturaBiometriaDTO { id: string; QRCodeUrl: string; url: string; }

@Injectable()
export class VerificacaoSegurancaService {
  constructor(private restService: CdcLojaRestService) { }

  verificarCpfClienteProposta(
    cpf?: string,
    proposta?: number
  ): Observable<any> {
    return this.restService.get(`clientes/verificar/${cpf}/${proposta}/biolink`);
  }

  decrypt(ciphertext: string, encryptedBase64Key: string) {
    try {
      const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
      const decryptedData = CryptoJS.AES.decrypt(ciphertext, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decryptedData.toString(CryptoJS.enc.Utf8);
    } catch {
      return;
    }
  }

  decodePayloadJWT(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  checkBiometriaProposta(
    propostaId?: number
  ): Observable<any> {
    return this.restService.get(`clientes/verificar/${propostaId}`);
  }

  avancarEtapaPropostaSemBiometria(body: { proposal: number, document: string }): Observable<any> {
    return this.restService.post('biometria/skip', body);
  }

  verificarLinkCapturaBiometria(cpf: string): Observable<{ cancelado: boolean }> {
    return this.restService.get(`biometria/unico/verifica-cancelamento/${cpf}`);
  }

  criarUrlCapturaBiometria(propostaId: number): Observable<UrlCapturaBiometriaDTO> {
    return this.restService.post(`biometria/unico/create-url/${propostaId}`);
  }
}
