export class UrlParams {
  chave: number;
  tipoComunicacao: string;
}

export class ConfirmaDadosAssinatura {
  ddd: string;
  email: string;
  idCliente: number;
  idProposta: number;
  telefone: string;
  tipoComunicacao: string;
  token: string;
}

export class ConfirmacaoCPFUserChave {
  autenticacao?: {
    login: string;
    token: string;
  };
  urlAssinatura?: string;
  dadosProposta?: ConfirmaDadosAssinatura;
  numeroPreContrato?: string;
}

export class ParamsConfirmacaoCPFUserChave {
  cpf: string;
  chave: number;
  tipoComunicacao: string;
}
