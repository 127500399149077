import { Component, Input } from '@angular/core';
import { FichaListaFiltro } from './../model/ficha-lista-filtro';

@Component({
  selector: 'app-lista-ficha-aprovada',
  templateUrl: './lista-ficha-aprovada.component.html',
  styleUrls: ['./lista-ficha-aprovada.component.css']
})
export class ListaFichaAprovadaComponent {
  @Input() propostas = [];
  @Input() filtro: FichaListaFiltro;

}
