import { FichaEstapasCadastro } from '../../shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';

export class CdcLojaEtapas {
  public static INICIAL = { numero: 0, descricao: '' };
  public static VENDEDOR = { numero: 1, descricao: 'Vendedor' };
  public static DADOS_ANALISE = { numero: 2, descricao: 'Dados para análise' };
  public static RESULTADO_PARCIAL = { numero: 3, descricao: 'Resultado parcial' };
  public static DETALHES_CLIENTE = { numero: 4, descricao: 'Detalhes do cliente' };
  public static DEMAIS_DADOS = { numero: 5, descricao: 'Demais dados' };
  public static ANALISE = { numero: 6, descricao: 'Análise' };

  public static ENDERECOS_CLIENTE = { numero: 5, descricao: 'Endereços do cliente' };

  public static etapas(): FichaEstapasCadastro[] {
    return [
      this.VENDEDOR,
      this.DADOS_ANALISE,
      this.RESULTADO_PARCIAL,
      this.DETALHES_CLIENTE,
      this.DEMAIS_DADOS,
      this.ANALISE,
    ];
  }

  public static next(current: FichaEstapasCadastro): FichaEstapasCadastro {
    return this.getEtapaByNumero(current, current.numero + 1);
  }

  public static previous(current: FichaEstapasCadastro): FichaEstapasCadastro {
    return this.getEtapaByNumero(current, current.numero - 1);
  }

  private static getEtapaByNumero(current: FichaEstapasCadastro, numero: number) {
    const etapaEncontrada = this.etapas().find(etapa => etapa.numero === numero);
    return etapaEncontrada ? etapaEncontrada : current;
  }
}
