import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '../../shared/loader/loader.service';
import { LoginStorage } from '../../login/login.storage';
import { Router } from '@angular/router';
import { OmniRestService } from '../../omni-rest/omni-rest.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Banco } from './banco';

@Injectable()
export class BancoService extends OmniRestService {
  constructor(http: HttpClient, loaderService: LoaderService, loginStorage: LoginStorage, router: Router) {
    super(http, loaderService, loginStorage, router);
  }

  /** @override abstract */
  getBaseUrl(): string {
    return environment.URL_FINANCEIRO;
  }

  /** @override abstract */
  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'omni-autenticacao': this.loginStorage.loginAndTokenAsJson
    };
  }

  public bancos(): Observable<Banco[]> {
    /** @Override Method: GET*/
    return this.get('banco?ativoVisivelWeb=true').pipe(
      map((res: any) => {
        let result: Banco[] = [];
        if (res.hasOwnProperty('bancos')) {
          if (Array.isArray(res.bancos) && res.bancos.length) {
            result = (<Banco[]>res.bancos).sort((x, y) => x.codigo - y.codigo);
          }
        }
        return result;
      }));
  }
}
