import { FaefStateService } from './../../faef-state.service';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-instituicao-base',
  templateUrl: './instituicao-base.component.html',
  styleUrls: ['./instituicao-base.component.css']
})
export class InstituicaoBaseComponent implements OnInit, OnChanges {

  @Input() formulario: FormArray;

  constructor(
    private fb: FormBuilder,
    private state: FaefStateService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    const faef = this.state.getLast();
    if (faef && faef.faefCredito && faef.faefCredito.length > 0) {
      faef.faefCredito.forEach(() => this.add());
      this.formulario.patchValue(faef.faefCredito);
    } else {
      this.add();
    }
  }

  add() {
    if (this.formulario.controls.length < 2) {
      this.formulario.push(this.fb.group({
        localCredito: ['', Validators.required],
        quantidadeParcelasPendentes: ['', Validators.required],
        valorParcela: ['', Validators.required]
      }));
    }
  }

  remove(index) {
    this.formulario.removeAt(index);
  }

}
