import * as moment from 'moment';
import { DadoSimulacaoSeguro } from './dado-simulacao-seguro';
import { PropostaSimulacao } from './proposta/proposta-simulacao';

export class DadoSimulacao {

  private _carencia = 0;

  get carencia(): number {
    return this._carencia;
  }
  set carencia(value: number) {
    this._carencia = value;
  }

  numeroProposta: string;
  ultimaDataTaxa: number = Date.now();
  parcelas = 0;
  primeiraParcela: number;

  valor = 0;
  cotacao = 0;
  seguro: DadoSimulacaoSeguro = new DadoSimulacaoSeguro();
  parcelasSelecionar: Map<number, number> = new Map();
  entradaPorcentagem = 0;

  constructor() {
    this.primeiraParcela = moment()
      .add(30, 'days')
      .unix();
  }

  setParcelas(parcelas: number) {
    this.parcelas = parcelas;
  }

  setPrimeiraParcela(primeiraParcela: number) {
    this.primeiraParcela = primeiraParcela;
    this.calculaCarencia();
  }

  private calculaCarencia() {
    const dataCalculada = moment().add(30, 'day');
    const dataPrimeiraParcela = moment.unix(this.primeiraParcela);
    const hora: any = { hour: 0, minute: 0, second: 0, millisecond: 0 };
    dataCalculada.set(hora);
    dataPrimeiraParcela.set(hora);
    this.carencia = dataPrimeiraParcela.diff(dataCalculada, 'days');
  }

  init(proposta: PropostaSimulacao) {
    this.numeroProposta = proposta.getId();
    this.parcelas = proposta.getTaxaClinte().getParcelas();
    this.cotacao = proposta.getCotacaoVeiculo();
    this.valor = proposta.getTaxaClinte().getValorLiberado();
  }

  setCotacao(cotacao: number) {
    this.cotacao = cotacao;
  }

  getCarencia(): number {
    return this.carencia;
  }

  setNumeroProposta(numeroProposta: string) {
    this.numeroProposta = numeroProposta;
  }

  getNumeroProposta(): string {
    return this.numeroProposta;
  }

  getUltimaDataTaxa(): number {
    return this.ultimaDataTaxa;
  }

  getParcelas(): number {
    return this.parcelas;
  }

  setValor(valor: number) {
    this.valor = valor;
  }

  getValor(): number {
    return this.valor;
  }

  setSeguro(seguro: DadoSimulacaoSeguro) {
    this.seguro = seguro;
  }

  getSeguro(): DadoSimulacaoSeguro {
    return this.seguro;
  }

  setEntradaPorcentagem(entradaPorcentagem: number) {
    this.entradaPorcentagem = entradaPorcentagem;
  }

  getEntradaPorcentagem(): number {
    return this.entradaPorcentagem;
  }
}
