import { FichaService } from '../../ficha-veiculo.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-aprovada',
  templateUrl: './ficha-timeline-aprovada.component.html',
  styleUrls: ['./ficha-timeline-aprovada.component.css'],
})
export class FichaTimelineAprovadaComponent {
  @Input() timeline: any;
  @Input() timelineActive: boolean;
  @Input() idFicha: number;
  @Input() proposta: any;

  body = {};
  idEnviando = true;

  constructor(private fichaService: FichaService) {}

  isCardDireito(): boolean {
    if (this.timeline['cardDireito']) {
      return this.timeline.cardDireito.length > 0;
    }
    return false;
  }

  prosseguirNegocio(acao: string) {
    this.idEnviando = false;

    switch (acao) {
      case 'PROSSEGUE_NEGOCIO':
        this.body = { id: this.idFicha, clienteProssegue: 'S' };
        this.prosseguirNegociacao(this.body);
        break;
      case 'N_PROSSEGUE_NEGOCIO':
        this.body = { id: this.idFicha, clienteProssegue: 'N' };
        this.prosseguirNegociacao(this.body);
        break;
      case 'DESISTIU_VEICULO':
        this.body = { id: this.idFicha, idCodDesistencia: '1' };
        this.sendMotivoDesistencia(this.body);
        break;
      case 'OUTRA_FINANCEIRA':
        this.body = { id: this.idFicha, idCodDesistencia: '2' };
        this.sendMotivoDesistencia(this.body);
        break;
      default:
        break;
    }
  }

  sendMotivoDesistencia(body: any) {
    this.fichaService.sendMotivoDesistencia(this.body).subscribe(
      (response) => {
        this.idEnviando = true;
        location.reload();
      },
      (error) => {
        console.log(error);
        this.idEnviando = true;
        alert(error.retorno);
      }
    );
  }

  prosseguirNegociacao(body: any) {
    this.fichaService.prosseguirNegociacao(this.body).subscribe(
      (response) => {
        this.idEnviando = true;
        location.reload();
      },
      (error) => {
        console.log('erro:\n', error);
        alert(error.retorno);
      }
    );
  }

  ok() {
    // This is intentional
  }

  close() {
    // This is intentional
  }
}
