import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyLetter], [appOnlyLetter][ngModel]'
})
export class OnlyLetterDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (!(/[a-zA-Z]/.test(event.key)) && event.code !== 'Space') {
      event.preventDefault();
    }
  }
}
