import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { SelectAgenteResult } from '../login/select-agente/select-agente-result';
import { PromotorService } from '../services/promotor/promotor.service';
import { StorageService } from '../services/storage/storage.service';
import { SelectProdutoService } from '../shared/select-produto/select-produto.service';
import { SimuladorVeiculoFichaService } from '../simulador-veiculo/services/simulador-veiculo-ficha.service';
import { environment } from './../../environments/environment';
import { LoginStorage } from './../login/login.storage';
import { SelectAgenteResultStatus } from './../login/select-agente/select-agente-result-status';
import { SelectAgenteComponent } from './../login/select-agente/select-agente.component';
import { ModalMessageComponent } from './../shared/modais/modal-message/modal-message.component';
import { MenuSharedService } from './menu-shared.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild('selecionarAgente', { static: true }) selecionarAgente: SelectAgenteComponent;
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  public version = environment.VERSION_APP;

  loginStorage: LoginStorage;
  produtos: any[];
  promotor: any;
  liberaCessao: boolean = false;

  public isEfetiveMais: boolean = false;
  public showSimuladorMenu = false;

  isLojistaTipoFabrica = true;

  constructor(
    loginStorage: LoginStorage,
    private sharedService: MenuSharedService,
    private produtoService: SelectProdutoService,
    private promotorService: PromotorService,
    private router: Router,
    private simuladorVeciluService: SimuladorVeiculoFichaService,
    private storageService: StorageService
  ) {
    this.loginStorage = loginStorage;
  }

  ngOnInit() {
    this.verificaEfetiveMais();
    this.verificaTipoLojista();
    this.getExibirMenuSimulador();
    if (!this.isEfetiveMais) {
      this.verificaPromotor();
      this.getProdutos();
    }
    this.getExibirMenuSimulador();
  }

  verificaEfetiveMais() {
    this.isEfetiveMais = this.router.url.includes('efetive-mais');
    this.verificaPromotor();
    this.getProdutos();
    this.getExibirMenuSimulador();
  }

  verificaPromotor() {
    if (this.loginStorage.usuario.promotor.id) {
      const promotorId = this.loginStorage.usuario.promotor.id;
      this.promotorService.getOperador(promotorId).pipe(
        take(1))
        .subscribe((res) => (this.handlePromotor(res)));
    }
  }

  handlePromotor(response) {
    this.promotor = response.operador;
    this.liberaCessao = this.promotor.liberaCessao;
  }

  getProdutos(): void {
    this.produtoService.getProdutos().pipe(
      take(1))
      .subscribe((res) => (this.produtos = res));
  }

  private verificaTipoLojista() {
    const { lojistaTipoFabrica } = this.storageService.getSession() ;
    this.isLojistaTipoFabrica = lojistaTipoFabrica || false;
  }

  get isOperadorLiberaCessao() {
    return this.liberaCessao;
  }

  get posuiVeiculos() {
    return !!(this.produtos || []).filter(item => item.descricao === 'VEÍCULOS')[0];
  }

  get posuiCreditoPessoal() {
    return !!(this.produtos || []).filter(item => item.descricao === 'CRÉDITO PESSOAL')[0];
  }

  get posuiCdcLoja() {
    return !!(this.produtos || []).filter(item => item.descricao === 'CDC LOJA')[0];
  }

  get posuiCdcPremium() {
    return !!(this.produtos || []).filter(item => item.descricao === 'CDC PREMIUM')[0];
  }

  get possuiCdcLojaOuPremium() {
    return (!!(this.produtos || []).filter(item => item.descricao === 'CDC LOJA')[0]) ||
      (!!(this.produtos || []).filter(item => item.descricao === 'CDC PREMIUM')[0]);
  }

  onClickSelectAgente() {
    this.selecionarAgente.selecionar();
    this.selecionarAgente.onSelectAgente.pipe(
      take(1))
      .subscribe((result: SelectAgenteResult) => {
        this.getExibirMenuSimulador();
        if (result.status === SelectAgenteResultStatus.SUCCESS) {
          this.sharedService.notifyAgenteChange();
        } else if (result.status === SelectAgenteResultStatus.ERROR) {
          this.modalMessage.show();
        }
      });
  }

  getExibirMenuSimulador() {
    this.simuladorVeciluService.getExibirMenuSimulacao().pipe(take(1)).subscribe(result => this.showSimuladorMenu = result.allowAccess);
  }

  sair() {
    this.storageService.removeSessionItem('lojistaTipoFabrica');
    this.router.navigate(['/login']);
  }
}
