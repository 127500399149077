
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { ModalMessageComponent } from '../../shared/modais/modal-message/modal-message.component';
import { FichaVeiculoStatus } from '../ficha-veiculo.status';
import { PropostaService } from './../../services/proposta/proposta.service';
import { ModalLoaderComponent } from './../../shared/modais/modal-loader/modal-loader.component';
import { FichaTimeline } from './ficha-timeline';
import { FichaTimelineSharedService } from './ficha-timeline.shared.service';

@Component({
  selector: 'app-ficha-timeline',
  templateUrl: './ficha-timeline.component.html',
  styleUrls: ['./ficha-timeline.component.css']
})
export class FichaTimelineComponent implements OnInit, OnDestroy {
  fichaTimeline: FichaTimeline = new FichaTimeline();
  errorMessage: string;
  isBuscando = false;
  isFicha = false;

  private isAlive = true;
  private idFicha: number;
  private produto: string;

  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;
  @ViewChild('modalLoader', { static: true })
  modalLoader: ModalLoaderComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: FichaTimelineSharedService,
    private _propostaService: PropostaService
  ) {}

  ngOnInit() {
    this.initObservableParams();
    this.initObservableReloadFicha();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initObservableParams() {
    this.route.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe(async params => {
      this.idFicha = params.idFicha;
      this.produto = params.produto;
      this.buscarFicha();
    });
  }

  private initObservableReloadFicha() {
    this.sharedService.reloadFichaObservable.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => this.buscarFicha());
  }

  buscarFicha() {
    return new Promise((resolve, reject) => {
      this.fichaTimeline = new FichaTimeline();
      this.isBuscando = true;
      this._propostaService
        .getTimeline(this.idFicha)
        .pipe(
          take(1),
          finalize(() => this.isBuscando = false)
        )
        .subscribe(
          (res) => {
            res.timelineProposta.timeline = res.timelineProposta.timeline.filter(
              (card) => {
                return (
                  card.idStatus !== FichaVeiculoStatus.EM_CONSULTA &&
                  card.idStatus !== FichaVeiculoStatus.EM_PREENCHIMENTO &&
                  card.idStatus !== FichaVeiculoStatus.EMISSAO_DO_CONTRATO &&
                  card.idStatus !== FichaVeiculoStatus.CANCELAMENTO_ASSINATURA &&
                  card.idStatus !== FichaVeiculoStatus.INCLUSAO_GRAVAME
                );
              }
            );

            this.fichaTimeline = new FichaTimeline(res);

            resolve(res);
          },
          error => {
            this.errorMessage = error.error.message || 'Falha na Comunicação, verifique sua conexão.';
            reject(error);
          }
        );
    });
  }

  get isResultadoParcialCurrent(): boolean {
    return this.fichaTimeline.status === FichaVeiculoStatus.RESULTADO_PARCIAL;
  }

  isResultadoParcial(timeline: any): boolean {
    return timeline.idStatus === 1;
  }

  isAguardandoAnaliseDireta(timeline: any): boolean {
    return timeline.idStatus === 2;
  }

  isEmAnaliseDireta(timeline: any): boolean {
    return timeline.idStatus === 3;
  }

  isAprovada(timeline: any): boolean {
    return timeline.idStatus === 4;
  }

  isDocumentacaoNecessaria(timeline: any): boolean {
    return timeline.idStatus === 5;
  }

  isEmissaoPreContrato(timeline: any): boolean {
    return timeline.idStatus === 27;
  }

  isAssinaturaDigital(timeline: any): boolean {
    return timeline.idStatus === 21;
  }

  isDocumentacaoEnviada(timeline: any): boolean {
    return timeline.idStatus === 6;
  }

  isDocumentacaoEmAnalise(timeline: any): boolean {
    return timeline.idStatus === 7;
  }

  isPagamentoAgendado(timeline: any): boolean {
    return timeline.idStatus === 10;
  }

  isPagamentoRealizado(timeline: any): boolean {
    return timeline.idStatus === 11;
  }

  isContratoDisponivel(timeline: any): boolean {
    return timeline.idStatus === 14;
  }

  isReprovada(timeline: any): boolean {
    return timeline.idStatus === 12;
  }

  isReprovadaFormalizacao(timeline: any): boolean {
    return timeline.idStatus === 13;
  }

  isActive(timeline: any): boolean {
    return timeline.seqTimeline ? true : false;
  }

  isShowEnviarSOS() {
    return this.fichaTimeline.status === FichaVeiculoStatus.AGUARDANDO_ANALISE_DIRETA ||
      this.fichaTimeline.status === FichaVeiculoStatus.EM_ANALISE_DIRETA ||
        this.fichaTimeline.status === FichaVeiculoStatus.APROVADA;
  }

  onClickExcluirFicha() {
    this.modalMessage
      .show('Deseja excluir esta ficha?', 'Sim', 'Não')
      .ok.pipe(take(1))
      .subscribe(() => this.excluirFicha());
  }

  private excluirFicha() {
    this.modalLoader.show('Excluindo ficha...');
    this._propostaService
      .delete(this.fichaTimeline.proposta.id).pipe(
      take(1),
      finalize(() => this.modalLoader.hide()))
      .subscribe(
        result => this.router.navigateByUrl('/ficha'),
        error => this.modalMessage.show(error.error.message)
      );
  }
}
