import { Component, OnInit } from '@angular/core';
import { Proposta } from '../../../shared/models/proposta';
import { EcommerceEtapas } from '../ecommerce-etapas';
import * as moment from 'moment';

@Component({
  selector: 'app-resumo-financiamento',
  templateUrl: './resumo-financiamento.component.html',
  styleUrls: ['./resumo-financiamento.component.scss']
})
export class ResumoFinanciamentoComponent implements OnInit {

  proposta: Proposta;
  dataPrimeiroVencimento;

  constructor() { }

  ngOnInit() {
    this.proposta = EcommerceEtapas.proposta;
    this.formataData(this.proposta);
  }

  formataData(proposta) {
    this.dataPrimeiroVencimento = moment(proposta.dataPrimeiroVencimento).locale('pt-br').format('L');
  }

}
