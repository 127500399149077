
import { finalize } from 'rxjs/operators';
import { Component, Input, DoCheck, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { setFichaContent } from '../helpers/salvarFicha';
import { FichaService } from '../../ficha-veiculo.service';
import { FichaCadastro } from './../ficha-cadastro';

import { Data } from '../../../shared/partner/data';
import { DadosProfissionais } from '../../../shared/dados-profissionais/model/dados-profissionais';
import { DadosProfissionaisComponent } from '../../../shared/dados-profissionais/dados-profissionais.component';

@Component({
  selector: 'app-ficha-cadastro-detalhe-cliente',
  templateUrl: './ficha-cadastro-detalhe-cliente.component.html',
  styleUrls: ['./ficha-cadastro-detalhe-cliente.component.css']
})
export class FichaCadastroDetalheClienteComponent
  implements DoCheck, OnDestroy {
  @Input() fichaCadastro: FichaCadastro;
  @ViewChild('dadosProfissionaisComponent', { static: true }) dadosProfissionaisComponent: DadosProfissionaisComponent;

  private nomeClienteOriginal: string;

  nomeCliente: string;
  emailCliente: string;
  tipoDocumentoCliente = 0;
  numeroDocumentoCliente: string;
  orgaoDocumentoCliente: string;
  emissaoDocumentoCliente: string;
  foneCelularCliente: string;
  estadoCivilCliente = 0;
  nomeRealMaeCliente = '';
  nomeMaeCliente = '';
  nomePaiCliente: string;
  nacionalidadeCliente = 1;
  naturalUfCliente = '';
  naturalCidadeCliente = '';
  valorPatrimonioCliente: number;
  ppeCliente = false;
  foneResidencialCliente: string;
  foneComercialCliente: string;
  nomeReferenciaCliente: string;
  foneReferenciaCliente: string;
  relacReferenciaCliente = '';

  isOutraPessoaCliente = false;
  isNomeMaeCliente = false;
  isNomePaiCliente = false;

  subsNomeMaeCliente: Subscription;
  subsNacionalidadeCliente: Subscription;
  subsNaturalCidadeCliente: Subscription;
  subsProfissaoCliente: Subscription;

  listaNomeMaeCliente = [];
  listaNacionalidadeCliente = [];
  listaNaturalCidadeCliente = [];
  listaClasseProfissionalCliente = [];
  listaProfissaoCliente = [];

  isLoadingNomeMaeCliente = false;
  isLoadingNacionalidadeCliente = false;
  isLoadingNaturalCidadeCliente = false;
  isLoadingProfissaoCliente = false;

  isSelectedTipoDocumentoCliente = false;
  isSelectedEstadoCivilCliente = false;
  isSelectedNomeMaeCliente = false;
  isSelectedBrasilCliente = true;
  isSelectedNaturalUfCliente = false;
  isSelectedNaturalCidadeCliente = false;
  isSelectedClasseProfissionalCliente = false;
  isSelectedRelacReferenciaCliente = false;

  minDataEmissao: string;
  maxDataEmissao: string;

  hasData = false;

  isLoadedListaNomeMaeCliente = true;

  isSelectedNomeMaeClienteAfterBlur = true;
  isSelectedNaturalCidadeClienteAfterBlur = true;

  isNomeMaeClienteTexto = false;
  isNomePaiClienteTexto = false;

  dadoProfissional;

  constructor(private fichaService: FichaService) { }

  ngDoCheck() {
    if (!this.hasData && this.fichaCadastro.proposta['id']) {
      if (!this.fichaCadastro.getFichaLocalStorage) {
        this.setDadosRecuperados();
      }
      this.nomeClienteOriginal = this.fichaCadastro.proposta['cliente']['nome'];
      this.nomeCliente = this.nomeClienteOriginal;
      this.isOutraPessoaCliente = false;
      this.isNomeMaeCliente = true;
      this.isNomeMaeClienteTexto = false;
      this.isNomePaiCliente = true;
      this.isNomePaiClienteTexto = false;
      this.loadNomeMaeCliente();
      this.loadNacionalidadeCliente();
      this.hasData = true;
    }
  }

  ngOnDestroy() {
    if (this.subsNomeMaeCliente) {
      this.subsNomeMaeCliente.unsubscribe();
    }
    if (this.subsNacionalidadeCliente) {
      this.subsNacionalidadeCliente.unsubscribe();
    }
    if (this.subsNaturalCidadeCliente) {
      this.subsNaturalCidadeCliente.unsubscribe();
    }
    if (this.subsProfissaoCliente) {
      this.subsProfissaoCliente.unsubscribe();
    }
  }

  setDadosRecuperados() {
    const { cliente } =  this.fichaCadastro.proposta;

    this.fichaCadastro._client.email = cliente.email;
    this.fichaCadastro._client.nomePai = cliente.nomePai;
    this.fichaCadastro._client.idTipoDocumento = cliente.documento.tipo;
    this.fichaCadastro._client.rgIe = cliente.documento.numero;
    this.fichaCadastro._client.rgIeData = new Date((cliente.documento || {}).dataEmissao).toLocaleDateString('pt-BR');
    this.fichaCadastro._client.rgIeEmissor = cliente.documento.emissor;
    this.fichaCadastro._client.idNacionalidade = cliente.idNacionalidade;
    this.fichaCadastro._client.naturalDe = cliente.naturalDe;
    this.fichaCadastro._client.naturalDeUf = cliente.naturalDeUf;
    this.fichaCadastro._client.enderecos = this.getEnderecoTipo(1);
    this.fichaCadastro._client.tipoEnderecoCorrespondencia = cliente.enderecos.length > 0 ? this.getTipoEnderecoCorrespondencia() : undefined;
    this.fichaCadastro._client.enderecoCorrespondencia = this.getEnderecoTipo(7);
    this.fichaCadastro._client.valorPatrimonio = cliente.valorPatrimonio;
    this.fichaCadastro._client.idEstadoCivil = cliente.idEstadoCivil;
    this.fichaCadastro._client.clientePpe = cliente.clientePpe;
    this.fichaCadastro._client.telefones.celularAdicional = this.getTelefoneCategoria(11);
    this.fichaCadastro._client.telefones.residencial = this.getTelefoneCategoria(1);
    this.fichaCadastro._client.telefones.comercial = this.getTelefoneCategoria(14);

    if (cliente.clienteReferencia.length > 0) {
      this.fichaCadastro._client.clienteReferencia.setNome(cliente.clienteReferencia[0].nome);
      this.fichaCadastro._client.clienteReferencia.telefone = `${cliente.clienteReferencia[0].telefone.ddd + cliente.clienteReferencia[0].telefone.numero}`;
      this.fichaCadastro._client.clienteReferencia.setIdGrauRelacionamento(cliente.clienteReferencia[0].idGrauRelacionamento);
    }

    if (cliente.dadoProfissional.length > 0 && cliente.dadoProfissional[0].idProfissao !== 0) {
      this.setDadoProfissional(cliente.dadoProfissional[0]);
    }
  }

  setDadoProfissional(dadoProfissional) {
    const dadosProfissionais = new DadosProfissionais();
    dadosProfissionais.empresa.nome = dadoProfissional.nomeEmpresa;
    dadosProfissionais.empresa.telefone = `${dadoProfissional.trabalhoAnteriorDDD + dadoProfissional.trabalhoAnteriorTelefone}`;
    dadosProfissionais.empresa.endereco = this.getEnderecoTipo(2);
    dadosProfissionais.classeProfissional = {
      id: dadoProfissional.idClasseProfissional,
      descricao: '',
      empresa: true
    };
    dadosProfissionais.profissao = {
      id: dadoProfissional.idProfissao,
      descricao: '',
      idGrupoProfissao: dadoProfissional.idGrupoProfissao,
    };
    dadosProfissionais.dadosSelected();

    this.dadosProfissionaisComponent.setDadosRecuperados(dadosProfissionais);
  }

  getTelefoneCategoria(idCategoria) {
    let currentPhone = '';
    this.fichaCadastro.proposta.cliente.telefones.forEach((telefone) => {
      if (telefone.idCategoria === idCategoria) {
        currentPhone = `${telefone.ddd + telefone.numero}`;
      }
    });

    return currentPhone;
  }

  getEnderecoTipo(idTipoEndereco) {
    let currentEndereco;
    this.fichaCadastro.proposta.cliente.enderecos.forEach((endereco) => {
      if (endereco.idTipoEndereco === idTipoEndereco) {
        currentEndereco = endereco;
      }
    });

    return currentEndereco;
  }

  getTipoEnderecoCorrespondencia() {
    let tipoEnderecoCorrespondencia;
    this.fichaCadastro.proposta.cliente.enderecos.forEach((endereco) => {
      if (endereco.correspondencia) {
        tipoEnderecoCorrespondencia = endereco.idTipoEndereco;
      } else {
        tipoEnderecoCorrespondencia = '7';
      }
    });

    return tipoEnderecoCorrespondencia;
  }

  loadNomeMaeCliente() {
    this.listaNomeMaeCliente = [];
    this.isLoadingNomeMaeCliente = false;
    this.subsNomeMaeCliente = this.fichaService
      .getListaNomeMae(
      this.fichaCadastro.proposta['id'],
      this.fichaCadastro.proposta['cliente']['id']
      ).pipe(
      finalize(() => this.isLoadingNomeMaeCliente = false))
      .subscribe(
        res => {
          this.listaNomeMaeCliente = res.nomes;
          this.handleMissingNomeMaeCliente();
        },
        () => this.handleMissingNomeMaeCliente()
      );
  }

  private handleMissingNomeMaeCliente(): void {
    if (this.listaNomeMaeCliente.length === 0) {
      this.isNomeMaeCliente = false;
      this.isNomeMaeClienteTexto = true;
      this.isLoadedListaNomeMaeCliente = false;
    }
  }

  loadNacionalidadeCliente() {
    this.listaNacionalidadeCliente = [];
    this.isLoadingNacionalidadeCliente = true;
    this.subsNacionalidadeCliente = this.fichaService
      .getAllNacionalidade()
      .subscribe(
      res => {
        this.listaNacionalidadeCliente = res.nacionalidades;
        this.isLoadingNacionalidadeCliente = false;
        if (this.fichaCadastro._client.naturalDeUf) {
          this.loadNaturalCidadeCliente();
        }
      },
      err => {
        this.isLoadingNacionalidadeCliente = false;
      }
      );
  }
  getIdNacionalidade(data) {
    if (data !== '1') {
      this.fichaCadastro._client.naturalDeUf = '';
      this.fichaCadastro._client.naturalDe = '';
    }
  }

  loadNaturalCidadeCliente() {
    this.listaNaturalCidadeCliente = [];
    this.isSelectedNaturalCidadeCliente = false;
    this.isLoadingNaturalCidadeCliente = true;
    this.subsNaturalCidadeCliente = this.fichaService
      .getCidadePorUf(this.fichaCadastro._client.naturalDeUf)
      .subscribe(
      res => {
        this.listaNaturalCidadeCliente = res.cidades;
        this.isLoadingNaturalCidadeCliente = false;
      },
      err => {
        this.isLoadingNaturalCidadeCliente = false;
      }
      );
  }

  onClickOutraPessoaCliente() {
    this.isOutraPessoaCliente = !this.isOutraPessoaCliente;
    this.nomeCliente = this.isOutraPessoaCliente
      ? ''
      : this.nomeClienteOriginal;
  }

  onClickNomeMaeCliente() {
    this.isNomeMaeCliente = !this.isNomeMaeCliente;
    this.isNomeMaeClienteTexto = !this.isNomeMaeClienteTexto;
  }

  onClickNomePaiCliente() {
    this.isNomePaiCliente = !this.isNomePaiCliente;
    this.isNomePaiClienteTexto = !this.isNomePaiClienteTexto;

    if (!this.isNomePaiCliente) {
      this.fichaCadastro._client.nomePai = 'NAO DECLARADO';
    } else {
      this.fichaCadastro._client.nomePai = '';
    }
  }

  onChangeNomeMaeCliente(event) {
    this.nomeRealMaeCliente = event.target.value;
    this.nomeMaeCliente = this.listaNomeMaeCliente[event.target.selectedIndex - 1].nomeMae;
    this.isSelectedNomeMaeCliente = event.target.selectedIndex !== 0;
    this.onBlurNomeMaeCliente(event);
  }

  onChangeNaturalUfCliente(event) {
    this.naturalUfCliente = event.target.value;
    this.isSelectedNaturalUfCliente = true;
    this.naturalCidadeCliente = '';
    this.isSelectedNaturalCidadeCliente = false;
    this.loadNaturalCidadeCliente();
  }

  onChangeNaturalCidadeCliente(event) {
    this.naturalCidadeCliente = event.target.value;
    this.isSelectedNaturalCidadeCliente = event.target.selectedIndex !== 0;
    this.onBlurNaturalCidadeCliente(event);
  }

  onChangeRelacReferenciaCliente(event) {
    this.isSelectedRelacReferenciaCliente = event.target.selectedIndex !== 0;
  }

  onBlurNomeMaeCliente(event) {
    this.isSelectedNomeMaeClienteAfterBlur = event.target.selectedIndex !== 0;
  }

  onBlurNaturalCidadeCliente(event) {
    this.isSelectedNaturalCidadeClienteAfterBlur =
      event.target.selectedIndex !== 0;
  }

  setClientePpe(data) {
    this.fichaCadastro._client.clientePpe = data;
  }

  setFatca(data) {
    this.fichaCadastro._client.fatca = data;
  }

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
  }

  formatDateMiliseconds(data) {
    return Data.toMilissegundos(data);
  }

  onClickNext() {
    this.fichaCadastro._client.renda = this.fichaCadastro.rendaCliente;
    setFichaContent(this.fichaCadastro);
    this.fichaCadastro.onClickNext();
  }
}
