import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MaskModule } from 'soft-angular-mask';
import { PipesModule } from '../pipes/pipes.module';
import { EnderecoModule } from '../shared/endereco/endereco.module';
import { DirectivesModule } from './../directives/directives.module';
import { LicensePlateCheckDirective } from './../directives/license-plate-check.directive';
import { LicensePlateDirective } from './../directives/license-plate.directive';
import { LowerCaseDirective } from './../directives/lower-case.directive';
import { MoneyCheckDirective } from './../directives/money-check.directive';
import { TextDirectivesModule } from './../directives/texto/text.directives.module';
import { LoginService } from './../login/login.service';
import { CapitalizePipe } from './../pipes/capitalize/capitalize.pipe';
import { DadosProfissionaisModule } from './../shared/dados-profissionais/dados-profissionais.module';
import { SelectProdutoModule } from './../shared/select-produto/select-produto.module';
import { SharedModule } from './../shared/shared.module';
import { FichaCadastro } from './ficha-cadastro/ficha-cadastro';
import { FichaCadastroAnaliseComponent } from './ficha-cadastro/ficha-cadastro-analise/ficha-cadastro-analise.component';
import { FichaCadastroClienteComponent } from './ficha-cadastro/ficha-cadastro-cliente/ficha-cadastro-cliente.component';
import { FichaCadastroConsultandoDadosComponent } from './ficha-cadastro/ficha-cadastro-consultando-dados/ficha-cadastro-consultando-dados.component';
import { FichaCadastroDetalheAvalistaComponent } from './ficha-cadastro/ficha-cadastro-detalhe-avalista/ficha-cadastro-detalhe-avalista.component';
import { FichaCadastroDetalheClienteComponent } from './ficha-cadastro/ficha-cadastro-detalhe-cliente/ficha-cadastro-detalhe-cliente.component';
import { FichaCadastroEnderecoAvalistaComponent } from './ficha-cadastro/ficha-cadastro-endereco-avalista/ficha-cadastro-endereco-avalista.component';
import { FichaCadastroEnderecoClienteComponent } from './ficha-cadastro/ficha-cadastro-endereco-cliente/ficha-cadastro-endereco-cliente.component';
import { FichaCadastroLateralComponent } from './ficha-cadastro/ficha-cadastro-lateral/ficha-cadastro-lateral.component';
import { FichaCadastroLojistaVendedorComponent } from './ficha-cadastro/ficha-cadastro-lojista-vendedor/ficha-cadastro-lojista-vendedor.component';
import { FichaCadastroObservacaoComponent } from './ficha-cadastro/ficha-cadastro-observacao/ficha-cadastro-observacao.component';
import { ResultadoParcialModule } from './ficha-cadastro/ficha-cadastro-resultado-parcial/resultado-parcial.module';
import { FichaCadastroVeiculoComponent } from './ficha-cadastro/ficha-cadastro-veiculo/ficha-cadastro-veiculo.component';
import { FichaCadastroComponent } from './ficha-cadastro/ficha-cadastro.component';
import { FichaCadastroSharedService } from './ficha-cadastro/shared/ficha-cadastro-shared.service';
import { FichaCardComponent } from './ficha-card/ficha-card.component';
import { FichaAprovadaComponent } from './ficha-lista/ficha-aprovada/ficha-aprovada.component';
import { FichaContratoCanceladoComponent } from './ficha-lista/ficha-contrato-cancelado/ficha-contrato-cancelado.component';
import { FichaEmAnaliseComponent } from './ficha-lista/ficha-em-analise/ficha-em-analise.component';
import { FichaEmFechamentoComponent } from './ficha-lista/ficha-em-fechamento/ficha-em-fechamento.component';
import { FichaListaComponent } from './ficha-lista/ficha-lista.component';
import { FichaListaSharedService } from './ficha-lista/ficha-lista.shared.service';
import { FichaRecusadaComponent } from './ficha-lista/ficha-recusada/ficha-recusada.component';
import { NotificacaoAbaComponent } from './ficha-lista/notificacao-aba/notificacao-aba.component';
import { FichaModalAvalistaModule } from './ficha-timeline/ficha-modal-avalista/ficha-modal-avalista.module';
import { FichaTimelineAguardandoAnaliseDiretaComponent } from './ficha-timeline/ficha-timeline-aguardando-analise-direta/ficha-timeline-aguardando-analise-direta.component';
import { FichaTimelineAprovadaComponent } from './ficha-timeline/ficha-timeline-aprovada/ficha-timeline-aprovada.component';
import { FichaTimelineDocumentacaoNecessariaComponent } from './ficha-timeline/ficha-timeline-documentacao-necessaria/ficha-timeline-documentacao-necessaria.component';
import { FichaTimelineDocumentacaoEnviadaComponent } from './ficha-timeline/ficha-timeline-documentacao-enviada/ficha-timeline-documentacao-enviada.component';
import { FichaTimelineEmissaoPreContratoComponent } from './ficha-timeline/ficha-timeline-emissao-pre-contrato/ficha-timeline-emissao-pre-contrato.component';
import { FichaTimelineAssinaturaDigitalComponent } from './ficha-timeline/ficha-timeline-assinatura-digital/ficha-timeline-assinatura-digital.component';
import { FichaTimelineContratoDisponivelComponent } from './ficha-timeline/ficha-timeline-contrato-disponivel/ficha-timeline-contrato-disponivel.component';
import { FichaTimelineDocumentacaoEmAnaliseComponent } from './ficha-timeline/ficha-timeline-documentacao-em-analise/ficha-timeline-documentacao-em-analise.component';
import { FichaTimelineEmAnaliseDiretaComponent } from './ficha-timeline/ficha-timeline-em-analise-direta/ficha-timeline-em-analise-direta.component';
import { FichaTimelinePagamentoAgendadoComponent } from './ficha-timeline/ficha-timeline-pagamento-agendado/ficha-timeline-pagamento-agendado.component';
import { FichaTimelinePagamentoRealizadoComponent } from './ficha-timeline/ficha-timeline-pagamento-realizado/ficha-timeline-pagamento-realizado.component';
import { FichaTimelineReprovadaComponent } from './ficha-timeline/ficha-timeline-reprovada/ficha-timeline-reprovada.component';
// tslint:disable:max-line-length
import { FichaTimelineResultadoParcialComponent } from './ficha-timeline/ficha-timeline-resultado-parcial/ficha-timeline-resultado-parcial.component';
import { FichaTimelineSosInversoComponent } from './ficha-timeline/ficha-timeline-sos-inverso/ficha-timeline-sos-inverso.component';
import { SelectMotivoComponent } from './ficha-timeline/ficha-timeline-sos-inverso/select-motivo/select-motivo.component';
import { FichaTimelineSosRespondidoComponent } from './ficha-timeline/ficha-timeline-sos-respondido/ficha-timeline-sos-respondido.component';
import { AvalistaComponent } from './ficha-timeline/ficha-timeline-sos/avalista/avalista.component';
import { DocumentosComponent } from './ficha-timeline/ficha-timeline-sos/documentos/documentos.component';
import { FichaTimelineSosComponent } from './ficha-timeline/ficha-timeline-sos/ficha-timeline-sos.component';
import { ObservacaoComponent } from './ficha-timeline/ficha-timeline-sos/observacao/observacao.component';
import { ParecerSosComponent } from './ficha-timeline/ficha-timeline-sos/parecer-sos/parecer-sos.component';
import { FichaTimelineComponent } from './ficha-timeline/ficha-timeline.component';
import { FichaTimelineSharedService } from './ficha-timeline/ficha-timeline.shared.service';
import { FindFicha } from './ficha-veiculo.pipes';
import { BreadcrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { ListaFichaEmAnaliseComponent } from './ficha-lista/lista-ficha-em-analise/lista-ficha-em-analise.component';
import { ListaFichaAprovadaComponent } from './ficha-lista/lista-ficha-aprovada/lista-ficha-aprovada.component';
import { ListaFichaEmFechamentoComponent } from './ficha-lista/lista-ficha-em-fechamento/lista-ficha-em-fechamento.component';
import { ListaFichaRecusadaComponent } from './ficha-lista/lista-ficha-recusada/lista-ficha-recusada.component';
import { ListaFichaContratoCanceladoComponent } from './ficha-lista/lista-ficha-contrato-cancelado/lista-ficha-contrato-cancelado.component';
import { FichaTableComponent } from './ficha-table/ficha-table.component';
import { PaginatorComponent } from './ficha-table/paginator/paginator.component';
import { FichaCadastroEvent } from './ficha-cadastro/ficha-cadastro.event';
import { HeaderSortComponent } from './ficha-table/header-sort/header-sort.component';
import { BtnSmallModule } from './../shared/btn-small/btn-small.module';
import { FichaTimelineAddAvalistaComponent } from './ficha-timeline/ficha-timeline-add-avalista/ficha-timeline-add-avalista.component';
import { ModalEmissaoPreContratoComponent } from './ficha-timeline/ficha-timeline-emissao-pre-contrato/modal-emissao-pre-contrato/modal-emissao-pre-contrato.component';
import { OmniFacilUploadRestService } from '../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { FichaService } from './ficha-veiculo.service';
import { CpModule } from '../produtos/cp/cp.module';

@NgModule({
  imports: [
    CommonModule,
    MaskModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    RouterModule,
    SharedModule,
    EnderecoModule,
    ResultadoParcialModule,
    PipesModule,
    DadosProfissionaisModule,
    DirectivesModule,
    FichaModalAvalistaModule,
    TextDirectivesModule,
    BreadcrumbModule,
    SelectProdutoModule,
    BtnSmallModule,
    CpModule
  ],
  declarations: [
    FichaListaComponent,
    FichaEmAnaliseComponent,
    FichaEmFechamentoComponent,
    FichaAprovadaComponent,
    FichaRecusadaComponent,
    FichaContratoCanceladoComponent,
    FichaCardComponent,
    FichaTimelineComponent,
    CapitalizePipe,
    FichaTimelineResultadoParcialComponent,
    FichaTimelineAguardandoAnaliseDiretaComponent,
    FichaTimelineEmAnaliseDiretaComponent,
    FichaTimelineAprovadaComponent,
    FichaTimelineDocumentacaoNecessariaComponent,
    FichaTimelineEmissaoPreContratoComponent,
    FichaTimelineAssinaturaDigitalComponent,
    FichaTimelineDocumentacaoEnviadaComponent,
    FichaTimelineDocumentacaoEmAnaliseComponent,
    FichaTimelinePagamentoAgendadoComponent,
    FichaTimelinePagamentoRealizadoComponent,
    FichaTimelineContratoDisponivelComponent,
    FichaTimelineReprovadaComponent,
    FichaCadastroComponent,
    FichaCadastroLateralComponent,
    FichaCadastroClienteComponent,
    FichaCadastroVeiculoComponent,
    FichaCadastroLojistaVendedorComponent,
    FichaCadastroConsultandoDadosComponent,
    LowerCaseDirective,
    MoneyCheckDirective,
    LicensePlateDirective,
    LicensePlateCheckDirective,
    FindFicha,
    FichaTimelineSosComponent,
    ObservacaoComponent,
    AvalistaComponent,
    DocumentosComponent,
    ParecerSosComponent,
    FichaCadastroConsultandoDadosComponent,
    FichaCadastroDetalheClienteComponent,
    FichaCadastroDetalheAvalistaComponent,
    FichaCadastroEnderecoClienteComponent,
    FichaCadastroEnderecoAvalistaComponent,
    FichaCadastroObservacaoComponent,
    FichaCadastroAnaliseComponent,
    FichaTimelineSosRespondidoComponent,
    FichaTimelineSosInversoComponent,
    FichaTimelineAddAvalistaComponent,
    SelectMotivoComponent,
    NotificacaoAbaComponent,
    ListaFichaAprovadaComponent,
    ListaFichaEmAnaliseComponent,
    FichaTableComponent,
    HeaderSortComponent,
    PaginatorComponent,
    ListaFichaEmFechamentoComponent,
    ListaFichaRecusadaComponent,
    ListaFichaContratoCanceladoComponent,
    ModalEmissaoPreContratoComponent
  ],
  exports: [
    FichaListaComponent,
    FichaEmAnaliseComponent,
    FichaEmFechamentoComponent,
    FichaAprovadaComponent,
    FichaRecusadaComponent,
    FichaCardComponent,
    FichaTimelineComponent,
    CapitalizePipe,
    FichaTimelineResultadoParcialComponent,
    FichaTimelineAguardandoAnaliseDiretaComponent,
    FichaTimelineEmAnaliseDiretaComponent,
    FichaTimelineAprovadaComponent,
    FichaTimelineDocumentacaoNecessariaComponent,
    FichaTimelineDocumentacaoEnviadaComponent,
    FichaTimelineDocumentacaoEmAnaliseComponent,
    FichaTimelinePagamentoAgendadoComponent,
    FichaTimelinePagamentoRealizadoComponent,
    FichaTimelineContratoDisponivelComponent,
    FichaTimelineReprovadaComponent,
    FichaCadastroComponent,
    FichaCadastroLateralComponent,
    FichaCadastroClienteComponent,
    FichaCadastroVeiculoComponent,
    FichaCadastroLojistaVendedorComponent,
    FichaCadastroConsultandoDadosComponent,
    LowerCaseDirective,
    MoneyCheckDirective,
    LicensePlateDirective,
    LicensePlateCheckDirective,
    FindFicha,
    FichaTimelineSosComponent,
    ObservacaoComponent,
    AvalistaComponent,
    DocumentosComponent,
    ParecerSosComponent,
    FichaCadastroConsultandoDadosComponent,
    FichaCadastroDetalheClienteComponent,
    FichaCadastroDetalheAvalistaComponent,
    FichaCadastroEnderecoClienteComponent,
    FichaCadastroEnderecoAvalistaComponent,
    FichaCadastroObservacaoComponent,
    FichaCadastroAnaliseComponent,
    FichaTimelineSosRespondidoComponent,
    FichaTimelineSosInversoComponent,
    FichaTimelineAddAvalistaComponent,
    SelectMotivoComponent,
    NotificacaoAbaComponent,
    ListaFichaAprovadaComponent,
    ListaFichaEmAnaliseComponent,
    FichaTableComponent,
    HeaderSortComponent,
    PaginatorComponent,
    ListaFichaEmFechamentoComponent,
    ListaFichaRecusadaComponent
  ],
  providers: [
    FichaService,
    FichaCadastro,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    LoginService,
    FormsModule,
    ReactiveFormsModule,
    FindFicha,
    FichaCadastroSharedService,
    ObservacaoComponent,
    FichaTimelineSharedService,
    FichaListaSharedService,
    FichaCadastroEvent,
    OmniFacilUploadRestService
  ],
  entryComponents: [
    ModalEmissaoPreContratoComponent
  ]
})
export class FichaVeiculoModule {}
