import { FichaEstapasCadastro } from '../../shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';
import { Proposta } from '../../shared/models/proposta';
import { Simulacao } from '../../shared/models/proposta-simulacao';

export class EcommerceEtapas {
  public static INICIAL = { numero: 0, descricao: '' };
  public static DADOS_SIMULACAO = { numero: 1, descricao: 'Dados para Simulação' };
  public static SIMULACAO = { numero: 2, descricao: 'Simulação' };
  public static DADOS_CONTATO = { numero: 3, descricao: 'Dados para contato' };
  public static DADOS_CLIENTE = { numero: 4, descricao: 'Dados do cliente' };
  public static ENDERECOS_CLIENTE = { numero: 5, descricao: 'Endereços do cliente' };
  public static DEMAIS_DADOS = { numero: 6, descricao: 'Demais dados' };
  public static ANALISE = { numero: 7, descricao: 'Análise' };

  public static proposta: Proposta;
  public static simulador: Simulacao;

  public static etapas(): FichaEstapasCadastro[] {
    return [
      this.DADOS_SIMULACAO,
      this.SIMULACAO,
      this.DADOS_CONTATO,
      this.DADOS_CLIENTE,
      this.ENDERECOS_CLIENTE,
      this.DEMAIS_DADOS,
      this.ANALISE,
    ];
  }

  public static next(current: { numero: number; }): FichaEstapasCadastro {
    return this.getEtapaByNumero(current, current.numero + 1);
  }

  public static previous(current: { numero: number; }): FichaEstapasCadastro {
    return this.getEtapaByNumero(current, current.numero - 1);
  }

  private static getEtapaByNumero(current: any, numero: number) {
    const etapa = this.etapas().find(etapa => etapa.numero === numero);
    return etapa ? etapa : current;
  }
}
