import { TimelineSosVO } from '../../../../shared/models/timelineVO';

import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-observacao',
  templateUrl: './observacao.component.html'
})
export class ObservacaoComponent implements OnInit, OnDestroy {

  @Input() sos: TimelineSosVO;
  @Input() local: number;
  observacaoSos: string;

  ngOnInit() {
    console.log('criando componente Observação');
  }

  ngOnDestroy() {
    console.log('destruindo componente Observação');
  }

  get valid() {
    return this.observacaoSos;
  }

  get observacoes() {
    return this.sos.motivos.filter(acao => !acao.idDocto);
  }

  get observacaoFormatada() {
    const lines = this.sos.observacao.split('\n');
    lines[0] = `<b>${lines[0]}</b>`;
    return lines.join('<br>');
  }
}
