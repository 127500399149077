import { DialogService } from './../../../../services/dialog/dialog.service';
import { UploadArquivosComponent } from './../../../../shared/upload-arquivos/upload-arquivos.component';
import { appSettings } from './../../../../../environments/app.setings';
import { ModalAssinaturaComponent } from './../modal-assinatura/modal-assinatura.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocumentoVO } from './../../../../shared/upload-arquivos/documentoVO';
import { DocumentoPendenteVO, TimelineStatusVO } from '../../../shared/models/timelineVO';
import { Proposta } from './../../../shared/models/proposta';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CreditoPessoalService } from '../../services/cp.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-card-assinatura',
  templateUrl: './card-assinatura.component.html',
  styleUrls: ['./card-assinatura.component.scss']
})
export class CardAssinaturaComponent implements OnInit {

  @Input() proposta: Proposta;
  @Input() timeline: TimelineStatusVO;
  @Input() nextCard: TimelineStatusVO;
  @Output() executar: EventEmitter<any> = new EventEmitter();

  @ViewChild(UploadArquivosComponent, { static: false }) uploadComponent;

  documentosPendentes: DocumentoVO[];
  alfabetizado = true;
  assinaturaDigital = true;

  constructor(
    private modal: BsModalService,
    private dialogService: DialogService,
    private service: CreditoPessoalService
  ) { }

  ngOnInit() {
    this.popularDocumentosPendentes();
  }

  pushDocumentacao() {
    console.log('enviou para formalização');
    this.executarAcao('push_assinatura');
  }

  executarAcao(acao: string) {
    this.executar.emit({ action: acao.toLowerCase() });
  }

  changeAlfabetizado(value: boolean) {
    if (!value && this.hasEnvelope) {
      const message = 'Alterar a alfabetização fará com que os dados preenchidos, até então, sejam perdidos.</p><p><strong>Deseja continuar?</strong>';
      this.changeToManual(message, () => this.alfabetizado = true);
    }
  }

  get assinaturaManual() {
    return !this.assinaturaDigital;
  }

  set assinaturaManual(value: boolean) {
    this.assinaturaDigital = !value;
    this.popularDocumentosPendentes();
  }

  verificarMudancaTipo() {
    if (this.uploadComponent.fileList.length > 0) {
      const message = 'Alterar a Alfabetização ou a Opção de Assinatura irá fazer com que os dados preenchidos,'
                    + ' até então, sejam perdidos.</p><p><strong>Deseja continuar?</strong>';
      this.dialogService.confirm({
        body: message,
        title: 'Atenção',
        classList: ['modal-md'],
        textClass: 'text-warning',
        iconClass: 'text-warning fa fa-exclamation-triangle',
        callbackSuccess: () => {
          this.assinaturaManual = !this.assinaturaManual;
          this.uploadComponent.limpaListFile();
        }
      });
    } else if (this.assinaturaDigital && this.hasEnvelope) {
      const message = 'Alterar a opção de assinatura irá fazer com que os dados preenchidos, até então, sejam perdidos.</p><p><strong>Deseja continuar?</strong>';
      this.changeToManual(message);
    } else {
      this.assinaturaManual = !this.assinaturaManual;
    }
  }

  private changeToManual(message, callbackCancel?) {
    this.dialogService.confirm({
      body: message,
      title: 'Atenção',
      classList: ['modal-md'],
      textClass: 'text-warning',
      iconClass: 'text-warning fa fa-exclamation-triangle',
      callbackSuccess: () => {
        this.assinaturaManual = !this.assinaturaManual;
        this.timeline.informacaoComplementar.tipoAssinaturaEnum = 'MANUAL';
        this.service.changeToManualSign(this.proposta.contratoId);
      },
      callbackError: callbackCancel
    });
  }

  popularDocumentosPendentes() {
    if (!this.timeline.informacaoComplementar || !this.timeline.informacaoComplementar.documentosPendentes) {
      return;
    }

    this.documentosPendentes = this.timeline
      .informacaoComplementar
      .documentosPendentes
      .filter(item => item.tipoCliente !== 2)
      .map(item => (
        {
          ...item,
          assinado: this.timeline.informacaoComplementar.assinadoDigitalmente,
          somenteDownload: this.assinaturaDigital ? true : item.somenteDownload,
          url: this.filterUrl(item)
        }
      ));
  }

  private filterUrl(item: DocumentoPendenteVO) {
    if (!item.urls) {
      return '';
    }
    const filteredUrl = item
      .urls
      .filter(url => url.alfabetizado === undefined || url.alfabetizado === this.alfabetizado)
      .filter(_ => item.somenteDownload || this.assinaturaManual || this.timeline.informacaoComplementar.assinadoDigitalmente)[0];

    if (filteredUrl) {
      return filteredUrl.url;
    }

    return '';
  }

  abrirModalAssinatura() {
    const initialState = { proposta: this.proposta.id, contrato: this.proposta.contratoId };
    const modal = this.modal.show(ModalAssinaturaComponent, { ...appSettings.MODAL_PARAMS, initialState });
    const componente = <ModalAssinaturaComponent> modal.content;
    componente.changeMetodoAssinatura
      .pipe(
        takeWhile(() => componente.alive)
      )
      .subscribe(value => this.timeline.informacaoComplementar.tipoAssinaturaEnum = value);
  }

  get assinado() {
    return this.timeline.informacaoComplementar.assinadoDigitalmente;
  }

  get hasAvalista() {
    return !!this.proposta.avalistas['1'];
  }

  get hasEnvelope() {
    const tipoAssinatura = (this.timeline.informacaoComplementar || {}).tipoAssinaturaEnum;
    return !!tipoAssinatura && tipoAssinatura !== 'MANUAL';
  }

  get subtitulo() {
    if (this.hasAvalista) {
      if (this.hasEnvelope) {
        return 'Os documentos foram enviados com sucesso.<br><strong>Aguarde a assinatura digital do cliente e dos avalistas</strong>';
      }
      return 'Aguardando assinatura do cliente e dos avalistas';
    }

    if (this.hasEnvelope) {
      return 'Os documentos foram enviados com sucesso.<br><strong>Aguarde a assinatura digital do cliente</strong>';
    }
    return 'Aguardando assinatura do cliente';
  }

}
