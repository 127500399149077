import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LojistaService } from '../lojista/lojista.service';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class TipoLojistaGuard implements CanActivate {

  constructor(
    private lojistaService: LojistaService,
    private router: Router,
    private storageService: StorageService
  ) { }

  canActivate(): Observable<boolean> | boolean {
    return this.lojistaService.verificarTipoLojista().pipe(
      map(({ lojistaTipoFabrica }) => {
        this.storageService.patchSession({ lojistaTipoFabrica });
        if (!!lojistaTipoFabrica) {
          this.goToReports();
          return false;
        }
        return true;
      }
      ),
      catchError((_) => {
        return of(true);
      }));
  }

  private goToReports() {
    this.router.navigate(['/produtos/cdcloja/relatorios']);
  }
}
