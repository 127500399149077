import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-contrato-disponivel',
  templateUrl: './ficha-timeline-contrato-disponivel.component.html',
  styleUrls: ['./ficha-timeline-contrato-disponivel.component.css']
})
export class FichaTimelineContratoDisponivelComponent {

  @Input() timeline: any;
  @Input() timelineActive: boolean;

}
