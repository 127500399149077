import { Injectable } from '@angular/core';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';
import { Observable } from 'rxjs';

@Injectable()
export class TipoVisualizacaoService {

  constructor(private restService: OmniMaisRestService) { }

  setVisualizacao(body: { idAgente: number, tipoVisualizacao: string }): Observable<any> {
    return this.restService.post('usuario/selecionarListagemProposta', body);
  }
}
