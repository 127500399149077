import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take, takeWhile, timeout } from 'rxjs/operators';
import * as toastr from 'toastr';

import { ContextSharedService } from '../../../context-shared/context-shared.service';
import { FichaEstapasCadastro } from '../../shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';
import { TipoProdutoEnum } from '../../shared/enums/tipo-produto.enum';
import { NovaProposta } from '../../shared/models/nova-proposta';
import { Proposta } from '../../shared/models/proposta';
import { PropostaCadastro } from '../../shared/models/proposta-cadastro';
import { PropostaSimulacao } from '../../shared/models/proposta-simulacao';
import { PropostaStateService } from '../../shared/states/proposta-state.service';
import { CreditoPessoalService } from '../services/cp.service';
import { DialogService } from './../../../services/dialog/dialog.service';
import { CreditoPessoalEtapas } from './cadastro-etapas';
import { DadosAnaliseComponent } from './etapas/dados-analise/dados-analise.component';
import { FaefStateService } from './etapas/faef/faef-state.service';
import { LoginStorage } from './../../../login/login.storage';
import { StorageService } from '../../../services/storage/storage.service';

@Component({
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CpNovaFichaComponent implements OnInit, OnDestroy {

  @ViewChild(DadosAnaliseComponent, { static: false }) dadosAnalise: DadosAnaliseComponent;

  formUpdateEmitter: EventEmitter<any> = new EventEmitter();
  propostaCadastro = new PropostaCadastro(new NovaProposta());
  propostaSimulacao = new PropostaSimulacao();
  etapaAtual = CreditoPessoalEtapas.INICIAL;
  etapas: FichaEstapasCadastro[];
  formValid: false;
  etapasCriadas: number[] = [];
  showLoader = false;
  alive = true;
  statusWebLogic = false;
  idPropostaWebLogic: string = '';
  loginStorage: LoginStorage;

  constructor(
    private context: ContextSharedService,
    private router: Router,
    private state: PropostaStateService,
    private cpService: CreditoPessoalService,
    private activeRoute: ActivatedRoute,
    private faefState: FaefStateService,
    private dialogService: DialogService,
    private storageService: StorageService
  ) {this.loginStorage = new LoginStorage(storageService);}

  private async initParams() {
    const params = (<any>this.activeRoute.paramMap).source._value;

    if (params.id) {
      const novaProposta = new NovaProposta();
      novaProposta.tipoProduto = `${params.produto}`.replace(' ', '_');
      novaProposta.produto = novaProposta.tipoProduto;
      this.propostaCadastro = new PropostaCadastro(novaProposta);

      const proposta = await this.cpService.findById(params.id).toPromise();
      if (!proposta.valorLiquido) {
        return;
      }

      this.propostaCadastro.tipoProduto = proposta.produto;
      this.state.setState(proposta);

      if (!this.podePularFaef(proposta.produto)) {
        this.initEtapasFaef(proposta.produto);
      }

      if (proposta.transients) {
        proposta.transients.isConfirmadoTelefone = proposta.transients.celular_confirmado;
      }

      this.propostaCadastro.lojaVendedor.lojaId = proposta.lojista;
      this.propostaCadastro.operacao = proposta.operacao.id;
      if (proposta.favorecido) {
        this.propostaCadastro.banco = proposta.favorecido.banco;
      }

      const etapa = (proposta.rastreioStatus || { codigo: 0, descricao: '' }).descricao;
      if (etapa.includes('EM PREENCHIMENTO')) {
        const fichaEtapaAtual = this.etapas.find(etapaF => etapaF.numero === Math.trunc(proposta.etapaAtual));
        if (proposta.etapaAtual && fichaEtapaAtual) {
          const etapaFaef = await this.verifyFaefState(proposta);
          const subEtapaAtual = etapaFaef || proposta.etapaAtual;
          const subetapa = (fichaEtapaAtual.subetapas || []).find(etapaF => etapaF.numero === subEtapaAtual);
          this.etapaAtual = subetapa || fichaEtapaAtual;

        } else {
          this.etapaAtual = CreditoPessoalEtapas.CLIENTE_DETALHES;
        }
      } else if (etapa.includes('RESULTADO PARCIAL')) {
        this.etapaAtual = CreditoPessoalEtapas.RESULTADO_PARCIAL;
      }

      this.enableAvalistas(proposta);
      this.etapasCriadas.push(this.etapaAtual.numero);
    }
  }

  async ngOnInit() {
    this.etapas = CreditoPessoalEtapas.etapas();
    this.setHideEtapa('AVALISTA1', true);
    this.setHideEtapa('AVALISTA2', true);
    this.setHideEtapa('AVALISTA3', true);
    this.setHideEtapa('DADOS_FAEF', true);

    await this.initParams();

    if (this.etapaAtual === CreditoPessoalEtapas.INICIAL) {
      this.context
        .get()
        .ifPresentOrElse(
          value => this.initEtapas(value),
          () => this.router.navigateByUrl('nova')
        );
    }

    this.state
      .getState()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        proposta => console.log('proposta atualizada', proposta)
      );
  }

  ngOnDestroy() {
    this.state.clear();
    this.alive = false;
  }

  private initEtapas(value: NovaProposta) {
    if (this.podePularFaef(value.tipoProduto)) {
      const etapa = this.etapas.find(etapaF => etapaF.numero === CreditoPessoalEtapas.DADOS_FAEF.numero);
      etapa.hide = true;
    } else {
      this.initEtapasFaef(value.tipoProduto);
    }

    this.propostaCadastro = new PropostaCadastro(value);
    this.etapaAtual = CreditoPessoalEtapas.VENDEDOR;
    this.etapasCriadas.push(this.etapaAtual.numero);
  }

  private initEtapasFaef(produto: string) {
    if (produto === TipoProdutoEnum.MICROCREDITO || produto === 'MICROCRÉDITO-RL') {
      const etapa = this.etapas.find(etapaF => etapaF.numero === CreditoPessoalEtapas.DADOS_FAEF.numero);
      etapa.hide = false;
    }
  }

  onClickPrevious() {
    if (this.isEtapaVendedor) {
      this.router.navigateByUrl('nova');
    } else {
      (this.etapaAtual = CreditoPessoalEtapas.previous(this.etapaAtual));
    }
    this.formUpdateEmitter.emit(this.etapaAtual);
    this.scrollTop();
  }

  async onClickNext() {
    switch (this.etapaAtual.numero) {
      case CreditoPessoalEtapas.DADOS_ANALISE.numero:
        if (this.dadosAnalise.dadosClienteForm.get('cpf').value != null && this.dadosAnalise.dadosClienteForm.get('cpf').value !== '') {
          return this.criarProposta();
        }
        return this.nextStep();
      case CreditoPessoalEtapas.RESULTADO_PARCIAL.numero:
        return this.preAnalisarCrivo();
      case CreditoPessoalEtapas.DEMAIS_DADOS.numero:
        return this.finalizarProposta();
      case CreditoPessoalEtapas.ANALISE.numero:
        this.etapaAtual = { numero: 0, descricao: 'finalizada' };
        return this.router.navigate(['/produtos/cp/timelines'], { queryParams: { id: this.state.getLast().id } });
      case CreditoPessoalEtapas.VENDEDOR.numero:
        const proposta = this.state.getLast();
        proposta.lojista = { id: Number(this.propostaCadastro.lojaVendedor.lojaId) };
        proposta.promotor = { id: Number(this.propostaCadastro.lojaVendedor.promotorId) };
        proposta.operacao = { id: Number(this.propostaCadastro.operacao) };

        if (this.propostaCadastro.banco) {
          proposta.favorecido = { banco: this.propostaCadastro.banco };
        }

        this.state.setState(proposta);
        return await this.nextStep();
      default:
        return await this.nextStep();
    }
  }

  private async nextStep() {
    const proximaEtapa = CreditoPessoalEtapas.next(this.etapaAtual);
    if (Math.trunc(proximaEtapa.numero) === CreditoPessoalEtapas.ANALISE.numero) {
      await this.saveStep(proximaEtapa);
    } else {
      await this.save(proximaEtapa);
    }
    this.etapaAtual = proximaEtapa;
    this.etapasCriadas.push(this.etapaAtual.numero);
    this.formUpdateEmitter.emit(this.etapaAtual);
    this.scrollTop();
  }

  private async save(proximaEtapa: FichaEstapasCadastro) {
    if (Math.trunc(this.etapaAtual.numero) === CreditoPessoalEtapas.DADOS_FAEF.numero
     && Math.trunc(proximaEtapa.numero) === CreditoPessoalEtapas.DADOS_FAEF.numero
    ) {
      const faef = this.faefState.getLast();
      faef.etapaAtual = proximaEtapa.numero;
      return this.faefState.setState(faef);
    }

    if (this.etapaAtual.numero > CreditoPessoalEtapas.RESULTADO_PARCIAL.numero) {
      try {
        this.showLoader = true;
        const proposta = this.state.getLast();
        proposta.seguro = !proposta.seguro ? 0 : proposta.seguro;

        await this.cpService.salvarProposta(proposta, proximaEtapa.numero).toPromise();
        console.log('Ficha atualizada: Dados salvos com segurança');
      } catch (error) {
        console.error('Erro: Tentativa de salvar ficha falhou', error);
      } finally {
        this.showLoader = false;
      }
    }
  }

  private async saveStep(proximaEtapa: FichaEstapasCadastro) {
    try {
      this.showLoader = true;
      await this.cpService.salvarEtapaCadastroProposta(this.state.getLast(), proximaEtapa.numero).toPromise();
      console.log('Etapa atualizada: Etapa salva com sucesso');
    } catch (error) {
      console.error('Erro: Tentativa de salvar etapa falhou', error);
    } finally {
      this.showLoader = false;
    }
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  private criarProposta() {
    this.showLoader = true;
    const propostaSimulacao = this.state.getLast();
    this.cpService
      .criarProposta(propostaSimulacao)
      .pipe(
        take(1),
        finalize(() => this.showLoader = false)
      )
      .subscribe(response => {
        const proposta = response.proposta;
        proposta.contratoRepique = propostaSimulacao.contratoRepique;
        this.state.setState(proposta);
        this.enableAvalistas(proposta);
        this.nextStep();
      });
  }

  private enableAvalistas(proposta: Proposta) {
    Object.getOwnPropertyNames(proposta.avalistas)
      .map(key => `AVALISTA${key}`)
      .forEach(key => {
        this.setHideEtapa(key, false);
      });
  }

  private setHideEtapa(key: string, hide: boolean) {
    const etapa = this.etapas.find(etapaF => etapaF.numero === CreditoPessoalEtapas[key].numero);
    etapa.hide = hide;
  }

  private preAnalisarCrivo() {
    this.showLoader = true;
    this.cpService
      .preAnalisarCrivo(this.state.getLast())
      .pipe(
        timeout(90000),
        take(1),
        finalize(() => this.showLoader = false)
      )
      .subscribe(
        response => {
          const proposta = <Proposta> response.proposta;
          this.state.setState(proposta);
          if (proposta.statusAnaliseCpf === 'RECUSADO') {
            this.dialogService.info({
              title: 'Proposta Recusada',
              body: 'Fora da política interna!',
              classList: ['modal-md'],
              textClass: 'text-warning',
              iconClass: 'text-warning fa fa-exclamation-triangle',
              callbackOK: () => this.router.navigateByUrl('ficha')
            });
          } else if (proposta.statusAnaliseCpf === 'AGUARDANDO') {
            this.idPropostaWebLogic = response.proposta.id;
            this.statusWebLogic = true;
          } else {
            this.nextStep();
          }
        },
        error => {
          console.log(error);
          toastr.error('Falha ao analisar');
        }
      );
  }

  private finalizarProposta() {
    this.showLoader = true;
    const proposta = this.state.getLast();
    proposta.seguro = !proposta.seguro ? 0 : proposta.seguro;
    this.cpService
      .finalizaSimulacao(proposta)
      .pipe(
        take(1),
        finalize(() => this.showLoader = false)
      )
      .subscribe(response => {
        this.state.setState(response.proposta);
        this.nextStep();
        this.faefState.clear();
      });
  }

  private podePularFaef(tipoProduto: string): boolean {
    return (tipoProduto === TipoProdutoEnum.MICROCREDITO || tipoProduto === 'MICROCRÉDITO-RL') && !!this.loginStorage.agente && Number(this.loginStorage.agente.id) === 1914;
  }

  isShowEtapa(numero) {
    return this.etapasCriadas.find(num => num === numero);
  }

  get solicitante() {
    return this.propostaCadastro.solicitante;
  }

  get currentPageBreadcrumb() {
    switch (this.propostaCadastro.produto) {
      case TipoProdutoEnum.MICROCREDITO: return 'Microcrédito';
      case TipoProdutoEnum.CREDITO_PESSOAL: return 'Crédito Pessoal';
      default: return 'Nova Ficha';
    }
  }

  get numeroEtapaAtual() {
    return this.etapaAtual.numero;
  }

  get isEtapaVendedor() {
    return this.etapaAtual.numero === CreditoPessoalEtapas.VENDEDOR.numero;
  }

  get isShowButtonPrevious() {
    return ![
      CreditoPessoalEtapas.RESULTADO_PARCIAL.numero,
      CreditoPessoalEtapas.ANALISE.numero
    ].includes(this.numeroEtapaAtual);
  }

  async verifyFaefState(proposta: Proposta) {
    const isPropostaMicrocredito = proposta.produto === TipoProdutoEnum.MICROCREDITO || proposta.produto === 'MICROCRÉDITO-RL';
    const isEtapaFaef = Math.trunc(proposta.etapaAtual) === CreditoPessoalEtapas.DADOS_FAEF.numero;
    if (isPropostaMicrocredito) {
      try {
        await this.faefState.init(proposta.id, proposta.cliente);
        const faef = this.faefState.getLast();
        if (faef && isEtapaFaef) {
          return faef.etapaAtual;
        }
      } catch (error) {
        console.log('falha ao carregar faef', error);
      }

      return null;
    }
  }

  etapaNaoPossuiControleIndependente() {
    const etapasIndependente = [2, 3, 4, 5, 6, 7, 8, 9];
    return etapasIndependente.indexOf(Math.trunc(this.etapaAtual.numero)) < 0;
  }

  handleWebLogicStatus({ status, perfilCliente }) {
    this.statusWebLogic = false;

    // atualiza estado da proposta com dados vindos do resultado da análise
    const proposta = this.state.getLast();
    proposta.statusAnaliseCpf = status;
    proposta.cliente.perfilCliente = perfilCliente;
    this.state.setState(proposta);

    switch (status) {
      case 'APROVADO':
        this.nextStep();
        console.log('Proposta aprovada');
        break;
      case 'RECUSADO':
        this.dialogService.info({
          title: 'Proposta Recusada',
          body: 'Fora da política interna!',
          classList: ['modal-md'],
          textClass: 'text-error',
          iconClass: 'text-error fa fa-exclamation-triangle',
          callbackOK: () => this.router.navigateByUrl(`/produtos/cp/timelines?id=${this.idPropostaWebLogic}`)
        });
        break;
      case 'PENDENTE':
        this.nextStep();
        console.log('Proposta PENDENTE');
        break;
      default:
        console.log('Nenhum status encontrado.');
    }
  }

}
