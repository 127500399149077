import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PanelModule } from '../../panel/panel.module';
import { PipesModule } from './../../../pipes/pipes.module';
import { SharedModule } from './../../../shared/shared.module';
import { PainelRelacaoFichasComponent } from './painel-relacao-fichas.component';
import { RecordListComponent } from './record-list/record-list.component';
import { RecordComponent } from './record/record.component';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    BrowserAnimationsModule,
    BrowserModule,
    PipesModule,
    SharedModule
  ],
  declarations: [
    PainelRelacaoFichasComponent,
    RecordComponent,
    RecordListComponent
  ],
  exports: [PainelRelacaoFichasComponent]
})
export class PainelRelacaoFichasModule { }
