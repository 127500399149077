import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { OmniRestModule } from '../omni-rest/omni-rest.module';
import { BancoService } from './banco/banco.service';
import { CepService } from './cep/cep.service';
import { CidadeService } from './cidade/cidade.service';
import { ClasseProfissionalService } from './classe-profissional/classe-profissional.service';
import { DadosFavorecidoService } from './dados-favorecido/dados-favorecido.service';
import { EstadoCivilService } from './estado-civil/estado-civil.service';
import { GrauInstrucaoService } from './grau-instrucao/grau-instrucao.service';
import { CessaoGuard } from './guards/cessao.guard';
import { PromotorGuard } from './guards/promotor.guard';
import { TipoLojistaGuard } from './guards/tipo-lojista.guard';
import { LojistaService } from './lojista/lojista.service';
import { MessagingService } from './messaging/messaging.service';
import { NacionalidadesService } from './nacionalidade/nacionalidades.service';
import { ProfissaoService } from './profissao/profissao.service';
import { PromotorService } from './promotor/promotor.service';
import { PropostaService } from './proposta/proposta.service';
import { ReportService } from './report/report.service';
import { TipoIdentificacaoService } from './tipo-identificacao/tipo-identificacao.service';
import { TipoMoradiaService } from './tipo-moradia/tipo-moradia.service';
import { TipoVisualizacaoService } from './tipo-visualizacao/tipo-visualizacao.service';
import { TiposCombustiveisService } from './tipos-combustiveis/tipos-combustiveis.service';
import { UfService } from './uf/uf.service';
import { VendedorService } from './vendedor/vendedor.service';

@NgModule({
  imports: [
    CommonModule,
    OmniRestModule,
  ],
  declarations: [],
  providers: [
    CidadeService,
    NacionalidadesService,
    UfService,
    EstadoCivilService,
    CepService,
    TipoIdentificacaoService,
    ClasseProfissionalService,
    ProfissaoService,
    PropostaService,
    MessagingService,
    DadosFavorecidoService,
    BancoService,
    LojistaService,
    VendedorService,
    TipoVisualizacaoService,
    ReportService,
    GrauInstrucaoService,
    TipoMoradiaService,
    PromotorGuard,
    CessaoGuard,
    TiposCombustiveisService,
    TipoLojistaGuard,
  ]
})
export class ServicesModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServicesModule,
      providers: [
        PromotorService,
      ]
    };
  }

}
