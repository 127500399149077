import { BsModalService } from 'ngx-bootstrap';
import { appSettings } from './../../../../environments/app.setings';
import { VisualizacaoDocumentacaoComponent } from './../visualizacao-documentacao/visualizacao-documentacao.component';
import { Component, Input, ViewChild, OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { ModalComponent } from '../../modais/modal/modal.component';
import { ReportService } from '../../../services/report/report.service';
import { VehicleAccessory, VehicleState, Report } from '../../models/report/report.model';
import { DialogService } from '../../../services/dialog/dialog.service';
import { clearJSONReferences, normalizeList } from '../../helper/helper';
import { RenavanPartner } from '../../partner/renavan-partner';

@Component({
  selector: 'app-report-documentation-item',
  templateUrl: './report-documentation-item.component.html',
  styleUrls: ['./report-documentation-item.component.scss']
})
export class ReportDocumentationItemComponent implements OnInit {
  @ViewChild('modalReport', { static: true }) modalReport: ModalComponent;
  @ViewChild('inputRenavam', { static: true }) inputRenavam: ElementRef;
  @Input() document: any;
  @Input() proposalId: number;
  @Input() reportCopy: Report;
  @Input() timeline: any;
  @Output() changeReport = new EventEmitter;
  report: Report;
  reportStep: number;
  reportInfo: any = {};
  vehicleAccessories: VehicleAccessory[] = [];
  originalVehicleAccessories: VehicleAccessory[];
  vehicleStates: VehicleState[];
  originalVehicleStates: VehicleState[];
  incorrectRenavam: boolean;
  requestedRenavam: string;
  renavam: string;
  isRenavamInvalid = false;

  constructor(
    private reportService: ReportService,
    private dialodService: DialogService,
    private modalService: BsModalService,
  ) {}

  async ngOnInit() {
    await this.recordReportInfo();

    if (this.reportInfo.vehicleType === 'AUTOMOVEL') {
      await this.recordVehicleAccessories();
    }

    await this.recordVehicleStates();
    this.resetReport();
  }

  openReportModal() {
    this.reportStep = 1;

    this.modalReport.show();
  }

  closeReportModal() {
    this.modalReport.hide();
  }

  updateReport(report: Report) {
    this.reportCopy = clearJSONReferences(report);
    this.report = report;

    this.changeReport.emit(report);
  }

  updateDocument() {
    const
      report = {
        ...this.report,
        states: this.vehicleStates,
        renavam: this.renavam
      };

    if (Array.isArray(this.vehicleAccessories)) {
      report.accessories = this.vehicleAccessories.map((acessory: VehicleAccessory) => ({ ...acessory, selected: !!acessory.selected }));
    }

    this.updateReport(report);

    this.closeReportModal();
  }

  createReport() {
    if (this.reportCopy) {
      const message = 'Já existe um documento preenchido, deseja gerar um novo laudo? Isso vai substituir as informações preenchidas anteriormente.';
      this.dialodService.confirm({ body: message, callbackSuccess: () => this.updateDocument() });
    } else {
      this.updateDocument();
    }
  }

  async recordReportInfo() {
    const response = await this.reportService.recordReportInfo(this.proposalId).toPromise();

    this.reportInfo = {
      reportLocation: response.reportInformation.localReport,
      surveyorDocument: response.reportInformation.localReport,
      surveyorName: response.reportInformation.surveyor,
      vehicleBrand: response.vehicleInformation.brand,
      vehicleModel: response.vehicleInformation.model,
      vehicleRenavam: response.vehicleInformation.renavam,
      vehicleType: response.vehicleInformation.vehicleType,
      vehicleModelYear: response.vehicleInformation.yearModel,
      vehicle0Km: response.vehicleInformation.vehicle0Km
    };

    this.renavam = this.reportInfo.vehicleRenavam;
    this.requestedRenavam = this.reportInfo.vehicleRenavam;
  }

  async recordVehicleStates() {
    await this.reportService
      .recordVehicleStates().toPromise()
      .then(response => {
        this.vehicleStates = response.vehicleStateDTO;
        this.originalVehicleStates = [...response.vehicleStateDTO];
      });
  }

  async recordVehicleAccessories() {
    await this.reportService
      .recordVehicleAccessories()
      .toPromise().then(response => {
        this.vehicleAccessories = response.vehicleAccessoryDTO;
        this.originalVehicleAccessories = [...response.vehicleAccessoryDTO];
      });
  }

  resetReport() {
    if (this.reportCopy) {
      if (this.vehicleAccessories) {
        this.vehicleAccessories
          .forEach((acessory: VehicleAccessory, index: number) => acessory.selected = this.reportCopy.accessories[index].selected);
      }

      this.vehicleStates.forEach((state: VehicleState, index: number) => {
        state.good = this.reportCopy.states[index].good;
        state.regular = this.reportCopy.states[index].regular;
        state.bad = this.reportCopy.states[index].bad;
      });

      this.renavam = this.reportCopy.renavam;
    } else {
      if (this.vehicleAccessories) {
        this.vehicleAccessories
          .forEach((acessory: VehicleAccessory) => acessory.selected = false);
      }

      this.vehicleStates.forEach((state: VehicleState) => {
        state.good = false;
        state.regular = false;
        state.bad = false;
      });

      this.renavam = '';
    }
  }

  isReportDataValid(): boolean {
    return !!(
      this.vehicleStates &&
      this.vehicleStates.filter((state: VehicleState) => state.good || state.regular || state.bad).length === this.vehicleStates.length &&
      (this.renavam && this.renavam.length ? RenavanPartner.isValid(this.renavam) : this.reportInfo.vehicle0Km === 'S')
    );
  }

  goToStep(step: number) {
    this.reportStep = step;

    normalizeList(document.querySelectorAll('.modal')).forEach((element: HTMLElement) => element.scrollTop = 0);
  }

  goToBackStep() {
    if (this.reportStep > 1) {
      this.goToStep(this.reportStep - 1);
    } else {
      this.dialodService.confirm({ body: 'Ao fechar as informações inseridas serão descartadas, deseja continuar?', callbackSuccess: () => {
        this.closeReportModal();
        this.resetReport();
      }});
    }
  }

  handleChangeState(event: any, state: VehicleState) {
    [
      'good',
      'regular',
      'bad'
    ].forEach(status => state[status] = false);

    state[event.target.value] = true;
  }

  handleChangeIncorrectRenavam(event: any) {
    if (event.target.checked) setTimeout(() => this.inputRenavam.nativeElement.select(), 0);
    else this.renavam = this.requestedRenavam;
  }

  handleBlurRenavam() {
    if (this.renavam && this.renavam.length) {
      this.isRenavamInvalid = !RenavanPartner.isValid(this.renavam);
    } else {
      this.isRenavamInvalid = this.reportInfo.vehicle0Km === 'N';
    }
  }

  get mustShowAviso() {
    return this.timeline && this.timeline.filter(time => time.idStatus === 21)[0].dataHora === undefined;
  }

  showAvisoDocumentacao() {
    const initialState = {};
    this.modalService.show(VisualizacaoDocumentacaoComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
  }
}
