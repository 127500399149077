import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LoginStorage } from './../../../../login/login.storage';
import { DadoSimulacaoSeguro } from './../model/dado-simulacao-seguro';
import { GrupoSeguro } from '../model/proposta/grupo-seguro';
import { GrupoAssistencia } from '../model/proposta/grupo-assistencia';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GrupoSeguroSelecionado } from '../model/proposta/grupo-seguro-selecionado';
import { GrupoAssistenciaSelecionado } from '../model/proposta/grupo-assistencia-selecionado';
import { FichaService } from '../../../ficha-veiculo.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { InfoProposta } from '../model/info-proposta.model';
import { SegurosService } from '../../../seguros.service';
import { SimulacaoRouboFurto } from '../model/simulacao-roubo-furto.model';
import { FormGroup, NgForm } from '@angular/forms';
import { Questions } from '../model/questions.model';

@Component({
  selector: 'seguro',
  templateUrl: './seguro.component.html',
  styleUrls: ['./seguro.component.scss'],
})
export class SeguroComponent implements OnInit, OnChanges {
  @Input() retornoMax: number;
  @Input() retorno: number;
  @Input() proposta: string;
  @Input() cpf: string;
  @Input() isZeroKm: boolean;
  @Input() grupoSeguroIds: GrupoSeguroSelecionado[] = [];
  @Input() grupoAssistenciaIds: GrupoAssistenciaSelecionado[] = [];
  @Input() gruposSeguros: GrupoSeguro[] = [];
  @Input() gruposAssistencias: GrupoAssistencia[] = [];
  @Input() anoModelo: string;
  @Output() seguroSelected: EventEmitter<DadoSimulacaoSeguro> =
    new EventEmitter();
  @ViewChild('formRouboFurto', { static: false }) formRouboFurto: FormGroup;
  @ViewChild('msgErroInsurance', { static: false })
  msgErroInsurance: TemplateRef<any>;

  private retornoAnterior: number;
  private grupoSeguroIdsAnterior: GrupoSeguroSelecionado[];
  private grupoAssistenciaIdsAnterior: GrupoAssistenciaSelecionado[];
  public isLojista = false;
  public exibeSeguro = false;
  private grupoSeguroSelecionado: GrupoSeguroSelecionado[] = [];
  private grupoAssistenciaSelecionado: GrupoAssistenciaSelecionado[] = [];
  private modalRef: BsModalRef;
  textSaibaMais: string = 'Cobertura de roubo e furto com 100% da tabela fipe';
  textPrincipalCondutor: string = 'Condutor que pilota a moto 85% do tempo';
  isPrincipalCondutor: boolean = true;
  isBackgroundBlue: boolean = true;
  isRouboFurto: boolean = false;
  passo: number = 1;
  infoProposta: InfoProposta = new InfoProposta();
  simulacaoRequest: SimulacaoRouboFurto = new SimulacaoRouboFurto();
  questions: Questions = new Questions();
  public readonly GRUPO_ID_SEGURO_ROUBO_FURTO = 32;

  constructor(
    private loginStorage: LoginStorage,
    private modalService: BsModalService,
    private segurosService: SegurosService
  ) {}

  ngOnInit() {
    this.isLojista = this.loginStorage.usuario.isLojista;
    let isRouboFurtoAux = false;

    this.segurosService
      .findInfoProposta(this.proposta)
      .pipe(catchError((err) => of(console.log(err))))
      .subscribe((result) => {
        this.handleResultDadoInfoProposta(result);
        isRouboFurtoAux = this.infoProposta.isRouboFurto
          ? this.infoProposta.isRouboFurto
          : false;
        //isRouboFurtoAux = true;//remover depois
        if (isRouboFurtoAux) {
          this.segurosService
            .findPerguntas()
            .pipe(catchError((err) => of(console.log(err))))
            .subscribe((result1) => this.handleResultRerguntas(result1));
        }
      });

    //this.infoProposta.grupo2 = 'MOTO';//remover depois
    //this.isRouboFurto = true;//remover depois
    this.simulacaoRequest.possuiOutroVeic = false;
    this.simulacaoRequest.anoModelo = this.anoModelo;
    this.simulacaoRequest.zeroKm = this.isZeroKm;
    this.simulacaoRequest.cpfCnpj = this.cpf;
  }

  ngOnChanges() {
    this.retornoAnterior = this.retorno;
    this.grupoSeguroIdsAnterior = this.grupoSeguroIds;
    this.grupoAssistenciaIdsAnterior = this.grupoAssistenciaIds;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-md',
      keyboard: false,
      ignoreBackdropClick: true,
    });
    this.grupoSeguroSelecionado = this.grupoSeguroIds;
    this.grupoAssistenciaSelecionado = this.grupoAssistenciaIds;
  }

  openModalRouboFurto(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }

  onSelectionSeguro(id: number, grupoId: number) {
    const seguro = new GrupoSeguroSelecionado(id, grupoId);
    this.grupoSeguroSelecionado = this.grupoSeguroSelecionado.filter(
      (x) => x.grupoId !== grupoId
    ); // remove todos do grupo
    this.grupoSeguroSelecionado.push(seguro);
  }

  onSelectionAssistencia(id: number, grupoId: number) {
    const assist = new GrupoAssistenciaSelecionado(id, grupoId);
    this.grupoAssistenciaSelecionado = this.grupoAssistenciaSelecionado.filter(
      (x) => x.grupoId !== grupoId
    ); // remove todos do grupo
    this.grupoAssistenciaSelecionado.push(assist);
  }

  onClickClose() {
    this.retorno = this.retornoAnterior;
    this.grupoSeguroIds = this.grupoSeguroIdsAnterior;
    this.grupoAssistenciaIds = this.grupoAssistenciaIdsAnterior;

    this.modalRef.hide();
  }

  onSaveClick() {
    if (
      this.retorno !== this.retornoAnterior ||
      this.grupoSeguroSelecionado.length > 0 ||
      this.grupoAssistenciaSelecionado.length > 0
    ) {
      const seguro = new DadoSimulacaoSeguro();
      seguro.setRetorno(this.retorno);
      if (this.grupoSeguroSelecionado.length > 0) {
        seguro.setGrupoSeguroIds(this.grupoSeguroSelecionado);
      }
      if (this.grupoAssistenciaSelecionado.length > 0) {
        seguro.setGrupoAssistenciaIds(this.grupoAssistenciaSelecionado);
      }
      this.seguroSelected.emit(seguro);
    }

    this.modalRef.hide();
  }

  onSaveInfoPropostaClick() {
    this.principalCondutorChange(this.isPrincipalCondutor ? 'S' : 'N');
    let dtNascimento = this.simulacaoRequest.dtNascimento;
    let dtNascimentoPrincipalCondutor =
      this.simulacaoRequest.dtNascimentoPrincipalCondutor;
    let request = new SimulacaoRouboFurto();
    request.cpfCnpj = String(this.simulacaoRequest.cpfCnpj);
    request.nome = String(this.simulacaoRequest.nome);
    request.dtNascimento = String(
      dtNascimento.substring(4) +
        '-' +
        dtNascimento.substring(2, 4) +
        '-' +
        dtNascimento.substring(0, 2)
    );
    request.sexo = Number(this.simulacaoRequest.sexo);
    request.cepPernoite = String(this.simulacaoRequest.cepPernoite);
    request.estadoCivil = Number(this.simulacaoRequest.estadoCivil);
    request.marca = String(this.simulacaoRequest.marca);
    request.modelo = String(this.simulacaoRequest.modelo);
    request.codFipe = String(this.simulacaoRequest.codFipe);
    request.anoModelo = String(this.simulacaoRequest.anoModelo);
    request.anoFabricacao = String(this.simulacaoRequest.anoFabricacao);
    request.zeroKm = Boolean(this.simulacaoRequest.zeroKm);
    request.utilizacaoDut = Number(this.simulacaoRequest.utilizacaoDut);
    request.tipoUtilizacao = Number(this.simulacaoRequest.tipoUtilizacao);
    request.possuiOutroVeic = Boolean(this.simulacaoRequest.possuiOutroVeic);
    request.cpfCnpjPrincipalCondutor = String(
      this.simulacaoRequest.cpfCnpjPrincipalCondutor
    );
    request.nomePrincipalCondutor = String(
      this.simulacaoRequest.nomePrincipalCondutor
    );
    request.dtNascimentoPrincipalCondutor =
      dtNascimentoPrincipalCondutor.substring(4) +
      '-' +
      dtNascimentoPrincipalCondutor.substring(2, 4) +
      '-' +
      dtNascimentoPrincipalCondutor.substring(0, 2);
    request.sexoPrincipalCondutor = Number(
      this.simulacaoRequest.sexoPrincipalCondutor
    );
    request.estadoCivilPrincipalCondutor = Number(
      this.simulacaoRequest.estadoCivilPrincipalCondutor
    );
    request.pergunta1 = Number(this.simulacaoRequest.pergunta1);
    request.pergunta2 = Number(this.simulacaoRequest.pergunta2);
    request.pergunta3 = Number(this.simulacaoRequest.pergunta3);
    request.pergunta4 = Number(this.simulacaoRequest.pergunta4);
    this.segurosService
      .saveCotacao(this.proposta, request)
      .pipe(catchError((err) => of(this.errSaveCotacao(err))))
      .subscribe((result) => this.handlerSaveCotacao(result));
  }

  hasGrupoSeguro() {
    return this.gruposSeguros || this.gruposSeguros.length > 0;
  }

  hasGrupoAssistencia() {
    return this.gruposAssistencias || this.gruposAssistencias.length > 0;
  }

  checkAssistenciaNone(idGrupo: number): boolean {
    const assistencias = this.gruposAssistencias.find(
      (o) => o.id === idGrupo
    ).assistencias;
    const intersection = assistencias.filter((element) =>
      this.grupoAssistenciaIds.some((x) => x.assistenciaId === element.id)
    );

    return intersection.length === 0;
  }

  checkSeguroNone(idGrupo: number): boolean {
    const seguros = this.gruposSeguros.find((o) => o.id === idGrupo).seguros;
    const intersection = seguros.filter((element) =>
      this.grupoSeguroIds.some((x) => x.seguroId === element.id)
    );

    return intersection.length === 0;
  }

  checkSeguro = (seguroId: number): boolean =>
    this.grupoSeguroIds
      ? this.grupoSeguroIds.some((x) => x.seguroId === seguroId)
      : false;
  checkAssistencia = (assistenciaId: number): boolean =>
    this.grupoAssistenciaIds
      ? this.grupoAssistenciaIds.some((x) => x.assistenciaId === assistenciaId)
      : false;

  principalCondutorChange(v: string) {
    this.isPrincipalCondutor = v === 'S' ? true : false;
    this.simulacaoRequest.cpfCnpjPrincipalCondutor = this.isPrincipalCondutor
      ? this.simulacaoRequest.cpfCnpj
      : '';
    this.simulacaoRequest.nomePrincipalCondutor = this.isPrincipalCondutor
      ? this.simulacaoRequest.nome
      : '';
    this.simulacaoRequest.dtNascimentoPrincipalCondutor = this
      .isPrincipalCondutor
      ? this.simulacaoRequest.dtNascimento
      : '';
    this.simulacaoRequest.estadoCivilPrincipalCondutor = this
      .isPrincipalCondutor
      ? this.simulacaoRequest.estadoCivil
      : 0;
    this.simulacaoRequest.sexoPrincipalCondutor = this.isPrincipalCondutor
      ? this.simulacaoRequest.sexo
      : 0;
  }
  private handleResultDadoInfoProposta(result) {
    this.infoProposta = result.infoProposta;
    if (this.infoProposta.dataNascimento) {
      const data = new Date(this.infoProposta.dataNascimento);
      let dia = this.padZero(data.getDate(), 2);
      let mes = this.padZero(data.getMonth() + 1, 2);
      let ano = data.getFullYear();
      let dataFormatada = dia + mes + ano;
      this.simulacaoRequest.dtNascimento = dataFormatada;
    }
    this.simulacaoRequest.codFipe = this.infoProposta.codFipe
      ? this.infoProposta.codFipe
      : null;
    this.simulacaoRequest.marca = this.infoProposta.marca
      ? this.infoProposta.marca
      : null;
    this.simulacaoRequest.modelo = this.infoProposta.modelo
      ? this.infoProposta.modelo
      : null;
    this.simulacaoRequest.nome = this.infoProposta.nome
      ? this.infoProposta.nome
      : null;
    this.simulacaoRequest.anoFabricacao = this.infoProposta.anoFabric
      ? String(this.infoProposta.anoFabric)
      : '0';
    if (this.infoProposta.sexo) {
      this.simulacaoRequest.sexo = this.infoProposta.sexo
        .toUpperCase()
        .startsWith('F')
        ? 1
        : 2;
    }
    this.principalCondutorChange(this.isPrincipalCondutor ? 'S' : 'N');
  }
  private handleResultRerguntas(result) {
    this.questions.questions = result.questions;
    if (
      this.infoProposta.grupo2 == 'MOTO' &&
      this.simulacaoRequest.codFipe != null &&
      this.simulacaoRequest.marca != null &&
      this.simulacaoRequest.modelo != null &&
      this.simulacaoRequest.anoFabricacao != '0'
    ) {
      this.isRouboFurto = true;
    }
  }
  private handlerSaveCotacao(result) {
    if (result['status'] == 'SUCCESS') {
      this.onSelectionSeguro(62, 32);
      this.onSaveClick();
    }
  }
  private errSaveCotacao(err) {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(this.msgErroInsurance, {
      class: 'modal-md',
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }
  private padZero(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  hasRouboFurto() {
    return this.gruposSeguros.some(
      (grupo) => grupo.id === this.GRUPO_ID_SEGURO_ROUBO_FURTO
    );
  }

  filterRouboFurto() {
    return this.isLojista
      ? this.gruposSeguros.filter(
          (grupo) => grupo.id === this.GRUPO_ID_SEGURO_ROUBO_FURTO
        )
      : this.gruposSeguros;
  }
}
