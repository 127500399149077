import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CpfCnpjValidatorDirective } from './cpf-cnpj/cpf-cnpj-validator.directive';
import { CpfCnpjDirective } from './cpf-cnpj/cpf-cnpj.directive';
import { CpfValidatorDirective } from './cpf/cpf-validator.directive';
import { DataEmissaoValidatorDirective } from './data/data-emissao-validator.directive';
import { DataNascimentoValidatorDirective } from './data/data-nascimento-validator.directive';
import { EmailValidatorDirective } from './email-validator.directive';
import { MoedaValidatorDirective } from './moeda/moeda-validator.directive';
import { ShowErrorMessagesDirective } from './show-error-messages/show-error-messages.directive';
import { TelefoneDirective } from './telefone/telefone.directive';
import { UpperCaseDirective } from './upper-case.directive';
import { DateCheckDirective } from './date-check.directive';
import { DDDTelefoneValidator } from './ddd-telefone-validator.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ShowErrorMessagesDirective,
    UpperCaseDirective,
    TelefoneDirective,
    CpfValidatorDirective,
    DataNascimentoValidatorDirective,
    DataEmissaoValidatorDirective,
    MoedaValidatorDirective,
    CpfCnpjValidatorDirective,
    CpfCnpjDirective,
    EmailValidatorDirective,
    DateCheckDirective,
    DDDTelefoneValidator
  ],
  exports: [
    ShowErrorMessagesDirective,
    UpperCaseDirective,
    TelefoneDirective,
    CpfValidatorDirective,
    DataNascimentoValidatorDirective,
    DataEmissaoValidatorDirective,
    MoedaValidatorDirective,
    CpfCnpjValidatorDirective,
    CpfCnpjDirective,
    EmailValidatorDirective,
    DateCheckDirective,
    DDDTelefoneValidator
  ]
})
export class DirectivesModule {}
