import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-slider-valor-financiado',
  templateUrl: './slider-valor-financiado.component.html',
  styleUrls: ['./slider-valor-financiado.component.css'],
})
export class SliderValorFinanciadoComponent implements OnInit, OnChanges {
  @Input() valorMin: number;
  @Input() valorMax: number;
  @Input() valor: number;
  @Input() disabled: boolean;
  @Output() valorChange: EventEmitter<number> = new EventEmitter();

  private valorEmmited = 0;

  ngOnInit() {
    this.initAndRefreshSlider();
    this.initOnChangeSlader();
  }

  ngOnChanges() {
    if (this.valorEmmited !== this.valor) {
      this.initAndRefreshSlider();
    }
    this.verifyDisabled();
  }

  initAndRefreshSlider() {
    $('#slider-valor').slider({
      value: this.valor,
      step: 50,
      min: this.valorMin,
      max: this.valorMax,
      ticks: [this.valorMin, this.valorMax],
      tooltip: 'hide',
      ticks_tooltip: false,
    });
    $('#slider-valor').slider('refresh');
    this.verifyDisabled();
  }

  initOnChangeSlader() {
    $('#slider-valor')
      .slider()
      .on('change', (ev) => {
        this.valorEmmited = $('#slider-valor').data('slider').getValue();
        this.valorChange.emit(this.valorEmmited);
      });
  }

  private verifyDisabled(): void {
    $('#slider-valor').slider(this.disabled ? 'disable' : 'enable');
    if (this.disabled) {
      $('#slider-valor').slider({
        value: this.valorEmmited,
      });
      $('#slider-valor').slider('refresh', { useCurrentValue: true });
    }
  }
}
