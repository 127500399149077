import { Router } from '@angular/router';
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-carregar-ficha',
  templateUrl: './modal-carregar-ficha.component.html'
})
export class ModalCarregarFichaComponent {
  propostaId: number;
  onHide = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private bsModalRef: BsModalRef
  ) {}

  close(withoutAction:boolean = true) {
    this.onHide.emit(withoutAction);
    this.bsModalRef.hide();
  }

  load() {
    this.router.navigate([`/produtos/cp/fichas/${this.propostaId}`]);
    this.close(false);
  }
}
