import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';

import { appSettings } from '../../../environments/app.setings';
import { TimerToken } from './timer-token';
import { TokenNotReceivedInfoModalComponent } from './token-not-received/token-not-received-info-modal.component';

@Component({
  selector: 'app-timer-token',
  templateUrl: './timer-token.component.html',
  styleUrls: ['./timer-token.component.css']
})
export class TimerTokenComponent implements OnInit, OnDestroy {
  @Input() butonText = 'Enviar Token para o celular';
  @Input() onSolicitarToken: () => Promise<TimerToken>;
  @Input() onValidarToken: (token: string) => Promise<void>;
  @Output() onSuccessValidate = new EventEmitter<void>();

  tokenStatus: 'SUCCESS' | 'FAIL' | 'SENT';
  timeLeft;
  private timer;
  showLoader: boolean = false;
  enviado: boolean = false;
  solicitou: boolean = false;
  tokenForm: FormGroup;

  alive = true;

  constructor(private fb: FormBuilder, private modal: BsModalService) { }

  ngOnInit() {
    this.solicitou = false;
    this.initForms();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  initForms(): void {
    this.tokenForm = this.fb.group({
      codigoConfirmacao: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.tokenForm.get('codigoConfirmacao').valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeWhile(() => this.alive),
      )
      .subscribe((value: any) => {
        console.log('token status', value);
        if (value.length === 6) {
          console.log('validando token');
          this.validarToken(value);
        }
      });
  }

  async validarToken(token) {
    this.showLoader = true;
    if (token) {
      try {
        await this.onValidarToken(token);
        this.onTokenValidadoComSucesso();
        this.showLoader = false;
      } catch (error) {
        this.tokenStatus = 'FAIL';
        this.showLoader = false;
      }
    }
  }

  onTokenValidadoComSucesso(token?) {
    if (token) {
      this.enviado = true;
      this.solicitou = true;
      this.tokenForm.patchValue({ token });
    }
    this.tokenStatus = 'SUCCESS';
    this.onSuccessValidate.emit();
    this.tokenForm.controls['codigoConfirmacao'].disable();
  }

  async solicitarToken() {
    this.showLoader = true;
    this.solicitou = true;
    try {
      const response = await this.onSolicitarToken();
      const { timeLeft } = response;
      this.tokenStatus = 'SENT';
      this.initTimer(timeLeft);
    } catch (error) {
      if (error.data) {
        this.initTimer(error.data);
      }
    } finally {

      this.showLoader = false;
    }
  }

  initTimer(time) {
    this.timeLeft = time;
    this.timer = setInterval(
      () => {
        this.enviado = true;
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.stop();
          this.enviado = false;
        }
      },
      1000
    );
  }

  stop() {
    clearInterval(this.timer);
  }

  openTokenNotReceivedInfoModal() {
    return this.modal.show(TokenNotReceivedInfoModalComponent, { ...appSettings.MODAL_PARAMS });
  }
}
