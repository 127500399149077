import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-grau-parentesco',
  templateUrl: './grau-parentesco.component.html',
  styleUrls: ['./grau-parentesco.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GrauParentescoComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GrauParentescoComponent),
      multi: true,
    },
  ],
})
export class GrauParentescoComponent
  implements Validator, ControlValueAccessor
{
  @Input() required = false;
  relacao: string;

  onChange() {
    this.propagateChange(this.relacao);
  }

  validate(): { [key: string]: any } {
    return this.required && !this.relacao ? { required: true } : null;
  }

  public writeValue(obj: string) {
    if (obj) {
      this.relacao = obj;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // This is intentional
  }

  private propagateChange = (_: any) => {
    // This is intentional
  }
}
