import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import * as moment from 'moment';

import { appSettings } from '../../../../../environments/app.setings';
import { ClienteEndereco, ClienteRenda, ClienteTelefone } from '../../../../produtos/shared/models/cliente';
import { IdDescricao } from '../../../../produtos/shared/models/id-descricao';
import { Proposta } from '../../../../produtos/shared/models/proposta';
import { ClasseProfissionalService } from '../../../../services/classe-profissional/classe-profissional.service';
import { EstadoCivil, EstadoCivilService } from '../../../../services/estado-civil/estado-civil.service';
import { NacionalidadesService } from '../../../../services/nacionalidade/nacionalidades.service';
import { DadosProfissionais } from '../../../../shared/dados-profissionais/model/dados-profissionais';
import { NewModalMessageComponent } from '../../../../shared/modais/new-modal-message/new-modal-message.component';
import { Data } from '../../../../shared/partner/data';
import { TimerToken } from '../../../../shared/timer-token/timer-token';
import { BiometriaStatus } from '../../../shared/models/biometria-status';
import { ParametroRendaProduto } from '../../../shared/models/parametro-renda-produto';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { EcommerceEtapas } from '../ecommerce-etapas';
import { SmsNotReceivedComponent } from './sms-not-received/sms-not-received.component';

@Component({
  selector: 'app-dados-cliente',
  templateUrl: './dados-cliente.component.html',
  styleUrls: ['./dados-cliente.component.scss'],
})
export class DadosClienteComponent implements OnInit, OnDestroy {
  @ViewChild('modalMensagem', { static: true }) modalMensagem: NewModalMessageComponent;
  @Input() parametroRendaProduto: ParametroRendaProduto;
  @Output() next = new EventEmitter();

  dadosClienteForm: FormGroup;
  dadoProfissional = new DadosProfissionais();
  showLoader = false;
  proposta: Proposta;
  endereco: ClienteEndereco;
  hasBiometria = false;

  dataRange = {
    min: 22,
    max: 85,
    message: 'A idade deve ser entre 22 e 85 anos',
  };

  classesProfissionais: any[];

  referencias: number[] = [];
  removableReferenciasCount: number = 0;

  telefones: number[] = [];
  removableTelefonesCount: number = 0;

  listaNacionalidadeCliente: any[];
  isLoadingNacionalidadeCliente = false;
  estadosCivis: EstadoCivil[];

  resendCodeEnable = true;
  countdownTime = 3;
  clicouBotaoReenviar: boolean = false;
  showMensagemNomeCliente = false;
  nomeClienteSubscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private classeProfissionalService: ClasseProfissionalService,
    private nacionalidadesService: NacionalidadesService,
    private estadoCivilService: EstadoCivilService,
    private modalService: BsModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cdcLojaService: CdcLojaService,
  ) { }

  ngOnInit() {
    this.initForms();
    this.fillClassesProfissionais();
    this.fillEstadosCivis();
    this.loadNacionalidadeCliente();
    this.initObservers();
  }

  private initForms(): void {
    this.proposta = EcommerceEtapas.proposta;
    this.onSolicitarBiometriaStatus();

    this.endereco = this.proposta.cliente.enderecos.filter(end => end.tipoEndereco === 'RESIDENCIAL')[0];
    const cliente = this.proposta.cliente;
    const telefone = cliente.telefones[0];
    let celular: string;
    if (telefone) {
      celular = `(${telefone.ddd}) ${telefone.nrTelefone.substring(
        0,
        5
      )}-${telefone.nrTelefone.substring(5, 9)}`;
    }
    const nomeCliente = (this.removeDigits(cliente.nomeCliente) || '').trim();

    this.dadosClienteForm = this.fb.group({
      cpf: [cliente.cpf],
      nomeCliente: [nomeCliente, Validators.required],
      dataNascimento: [moment(new Date(cliente.dtNascimento)).format('DDMMYYYY'), Validators.required],
      email: [cliente.email, Validators.required],
      telefoneCelularCliente: [celular, Validators.required],
      nacionalidade: ['', Validators.required],
      sexoCliente: ['', Validators.required],
      estadoCivilCliente: ['', Validators.required],
      isPoliticamenteExposto: ['', Validators.required],
      renda: ['', [Validators.required, Validators.maxLength(17)]],
      fatca: [this.proposta.cliente.fatca || 'N', Validators.required]
    });
  }

  verificaBiometriaTelefoneConfirmado(proposta) {
    const isTelefoneConfirmado = proposta.transients.celular_confirmado;
    if (isTelefoneConfirmado) this.hasBiometria = true;
  }

  resendSMS() {
    this.clicouBotaoReenviar = true;
    this.onSolicitarBiometriaStatus();
  }

  async onSolicitarBiometriaStatus() {
    const response = await this.getStatusBiometriaAsync();
    const { realizada } = response;
    this.handleStatusBiometria(realizada);
  }

  getStatusBiometriaAsync(): Promise<BiometriaStatus> {

    return this.cdcLojaService
      .getStatusBiometria(this.proposta.id)
      .toPromise();
  }

  handleStatusBiometria(realizada) {
    if (realizada) {
      this.hasBiometria = true;
    } else if (!realizada && this.clicouBotaoReenviar) {
      this.onSolicitarToken();
    }
  }

  solicitarToken(): Promise<TimerToken> {

    if (this.dadosClienteForm.value.telefoneCelularCliente.invalid) {
      this.handleError('Telefone inválido!');
      return;
    }

    return this.cdcLojaService.enviarSms({
      proposta: {
        cliente: {
          telefones: [{
            ddd: this.dadosClienteForm.value.telefoneCelularCliente.replace(/[^0-9]/g, '').substring(0, 2),
            nrTelefone: this.dadosClienteForm.value.telefoneCelularCliente.replace(/[^0-9]/g, '').substring(2),
            categoriaTelefone: 'CELULAR',
            tipoTelefone: 'PROPRIO',
          }]
        },
        id: this.proposta.id,
        parceiro: this.proposta.parceiro
      }
    }).toPromise();

  }

  async onSolicitarToken() {
    this.showLoader = true;
    try {
      const response = await this.solicitarToken();
      const { timeLeft } = response;
      this.initTimer(timeLeft);
    } catch (error) {
      if (error.data) {
        this.initTimer(error.data);
      }
    } finally {
      this.showLoader = false;
      this.resendCodeEnable = false;
      this.clicouBotaoReenviar = false;
    }
  }

  initTimer(timer) {
    this.countdownTime = timer;
  }

  private fillClassesProfissionais(): void {
    this.classeProfissionalService
      .classes()
      .pipe(take(1))
      .subscribe((res) => {
        this.classesProfissionais = res;
      });
  }

  private fillEstadosCivis(): void {
    this.estadoCivilService
      .findEstadoCivil()
      .pipe(take(1))
      .subscribe((res) => (this.estadosCivis = res));
  }

  async loadNacionalidadeCliente() {
    this.listaNacionalidadeCliente = [];
    this.isLoadingNacionalidadeCliente = true;
    try {
      this.listaNacionalidadeCliente = await this.nacionalidadesService
        .nacionalidades()
        .toPromise();
    } finally {
      this.isLoadingNacionalidadeCliente = false;
    }
  }

  handleCountdownEvent(event: { action: string }) {
    if (event.action === 'done') {
      this.resendCodeEnable = true;
    } else {
      this.resendCodeEnable = false;
    }
  }

  addReferencia(): void {
    if (this.filterReferencias().length < 1) {
      this.referencias.push(this.removableReferenciasCount++);
      this.changeDetectorRef.detectChanges();
    }
  }

  filterReferencias() {
    return this.referencias.filter((ele) => ele !== -1);
  }

  removeReferencia($event: number): void {
    this.referencias[$event] = -1;
  }

  addTelefone(): void {
    if (this.telefones.length < 2) {
      this.telefones.push(this.removableTelefonesCount++);
      this.changeDetectorRef.detectChanges();
    }
  }

  removeTelefone($event: number): void {
    this.telefones.pop();
    this.removableTelefonesCount--;
  }

  openNotReceivedModal() {
    const modal = this.modalService.show(
      SmsNotReceivedComponent,
      appSettings.MODAL_PARAMS
    );
  }

  onClickPrevious() {
    this.router.navigate(['/produtos/cdcloja/timelines'], {
      queryParams: { id: this.proposta.id },
    });
  }

  async onClickNext() {
    this.showLoader = true;

    const formValue = this.dadosClienteForm.getRawValue();
    const celular = formValue.telefoneCelularCliente.replace(/[^\d]/g, '');
    const telefone: ClienteTelefone = {
      ddd: celular.substring(0, 2),
      nrTelefone: celular.substring(2, celular.length),
      categoriaTelefone: 'CELULAR',
      tipoTelefone: 'PROPRIO',
    };

    const estadoCivil: EstadoCivil = this.estadosCivis.find(el => el.codigo === formValue.estadoCivilCliente);

    this.proposta.cliente = {
      cpf: formValue.cpf,
      nomeCliente: formValue.nomeCliente,
      dtNascimento: Data.toMilissegundos(formValue.dataNascimento),
      email: formValue.email,
      estadoCivil: this.buildEstadoCivil(estadoCivil),
      ppe: formValue.isPoliticamenteExposto,
      nacionalidade: this.listaNacionalidadeCliente.find(el => el.id === Number(formValue.nacionalidade)),
      sexo: formValue.sexoCliente,
      telefones: [telefone, ...this.buildClienteTelefones(this.dadosClienteForm.controls)],
      dadosProfissionais: {
        classeProfissional: this.dadoProfissional.classeProfissional,
        nomeEmpresa: this.dadoProfissional.empresa.nome,
        profissao: {
          id: Number(this.dadoProfissional.profissao.id),
          idGrupoProfissao: Number(this.dadoProfissional.profissao.idGrupoProfissao)
        }
      },
      rendas: [this.buildTelefoneEmpresaRenda(formValue, telefone)],
      referencias: this.buildClienteReferencias(this.dadosClienteForm.controls),
    };

    this.proposta.cliente.biometriaRealizada = this.hasBiometria;
    this.proposta.ppe = formValue.isPoliticamenteExposto;
    this.proposta.cliente.fatca = formValue.fatca;
    this.proposta.cliente.enderecos = [this.endereco];
    EcommerceEtapas.proposta = this.proposta;

    await this.cdcLojaService.salvarProposta(this.proposta).toPromise();

    this.showLoader = false;
    this.next.emit();
  }

  
  get isFormValid() {
    return this.dadosClienteForm.valid && this.dadoProfissional.valid;
  }

  private buildEstadoCivil(estadoCivil: EstadoCivil): IdDescricao {
    return this.proposta.cliente.estadoCivil = {
      id: Number(estadoCivil.codigo),
      descricao: estadoCivil.descricao
    };
  }

  private buildTelefoneEmpresaRenda(formValue, telefoneCliente) {
    const telefoneEmpresa = this.dadoProfissional.telefoneEmpresa;
    let ddd;
    let numberTelefone;

    if (telefoneEmpresa) {
      ddd = telefoneEmpresa.substring(0, 2);
      numberTelefone = telefoneEmpresa.substring(2, telefoneEmpresa.length);
    } else {
      ddd = telefoneCliente.ddd;
      numberTelefone = telefoneCliente.nrTelefone;
    }
    const renda: ClienteRenda = {
      tipoRenda: 'SALARIO',
      valor: formValue.renda,
      empresa: this.dadoProfissional.empresa.nome,
      empresaDdd: ddd,
      empresaFone: numberTelefone,
      empresaRamal: '00'
    };
    return renda;
  }

  private buildClienteReferencias(controls: {
    [key: string]: AbstractControl;
  }): any[] {
    return Object.keys(controls)
      .filter((key) => key.startsWith('referenciaForm'))
      .map((key) => {
        return {
          // tipoReferencia: controls[key].value.tipoReferencia,
          nome: controls[key].value.nomeReferencia,
          ddd: controls[key].value.telefoneReferencia
            .replace(/[^\d]/g, '')
            .substring(0, 2),
          nrTelefone: controls[key].value.telefoneReferencia
            .replace(/[^\d]/g, '')
            .substring(2),
          tipoReferencia: { id: controls[key].value.tipoReferencia },
        };
      });
  }

  private buildClienteTelefones(controls: {
    [key: string]: AbstractControl;
  }): ClienteTelefone[] {
    return Object.keys(controls)
      .filter((key) => key.startsWith('telefoneForm'))
      .map((key) => {
        const foneForm = (<FormGroup>controls[key]).getRawValue();
        return {
          tipoTelefone: 'PROPRIO',
          categoriaTelefone: foneForm.tipoTelefone,
          ddd: foneForm.telefone.replace(/[^\d]/g, '').substring(0, 2),
          nrTelefone: foneForm.telefone.replace(/[^\d]/g, '').substring(2),
        };
      });
  }

  private handleError(error: string) {
    return this.modalMensagem.showModal({
      isTituloVermelho: true,
      titulo: 'Falha',
      mensagem: error
    });
  }

  get enderecoResidencialForm(): FormGroup {
    return this.dadosClienteForm.get('enderecoForm') as FormGroup;
  }

  get filiacao(): FormGroup {
    return this.dadosClienteForm.get('filiacao') as FormGroup;
  }

  private initObservers() {
    this.nomeClienteSubscriptions.push(this.nomeCliente.valueChanges.pipe(
      debounceTime(200),
      map(str => this.removeDigits(str))
    ).subscribe(((value) => {
      this.nomeCliente.setValue(value, { emitEvent: false });
    })));
  }

  ngOnDestroy() {
    if (this.nomeClienteSubscriptions) {
      this.nomeClienteSubscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  private removeDigits(value: string): string {
    if (!value) {
      return '';
    }

    if (!!value.match(/\d/g)) {
      this.showMensagemNomeCliente = true;
      return value.replace(/\d/g, '');
    }
    this.showMensagemNomeCliente = false;
    return value;
  }

  get nomeCliente(): FormControl {
    return this.dadosClienteForm.controls['nomeCliente'] as FormControl;
  }

  get nomeClienteValido(): boolean {
    if (!!this.nomeCliente.value.match(/\d/g)) {
      this.showMensagemNomeCliente = true;
      return false;
    }
    return true;
  }
}
