import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { BsModalRef } from 'ngx-bootstrap';
import { MaskModule } from 'soft-angular-mask';

import { StorageService } from '../services/storage/storage.service';
import { DirectivesModule } from './../directives/directives.module';
import { BellNotificationComponent } from './bell-notification/bell-notification.component';
import { BellPlusComponent } from './bell-plus/bell-plus.component';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { BtnGroupComponent } from './btn-group/btn-group.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CentralLoaderComponent } from './central-loader/central-loader.component';
import { AnimatedButtonInputComponent } from './components/buttons/animated-button-input/animated-button-input.component';
import { AnimatedButtonComponent } from './components/buttons/animated-button/animated-button.component';
import { GrradientButtonComponent } from './components/buttons/gradient-button/gradient-button.component';
import { AddModalComponent } from './components/inputs/auto-complete/add-modal/add-modal.component';
import { AutoCompleteComponent } from './components/inputs/auto-complete/auto-complete.component';
import { ButtonSelectItemComponent } from './components/inputs/button-select-item/button-select-item.component';
import { ButtonSelectComponent } from './components/inputs/button-select/button-select.component';
import { EmotionSelectItemComponent } from './components/inputs/emotion-select-item/emotion-select-item.component';
import { EmotionSelectComponent } from './components/inputs/emotion-select/emotion-select.component';
import { InputDateComponent } from './components/inputs/input-date/input-date.component';
import { SwitchComponent } from './components/inputs/switch/switch.component';
import { CustomDualListComponent } from './custom-dual-list/custom-dual-list.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { DialogComponent } from './dialog/dialog.component';
import { FallbackImageDirective } from './directives/fallback-image.directive';
import { EstadoCivilComponent } from './estado-civil/estado-civil.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { GrauParentescoComponent } from './grau-parentesco/grau-parentesco.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { LoadersModule } from './loaders/loaders.module';
import { ModaisModule } from './modais/modais.module';
import { ModalAlteracoesComponent } from './modais/modal-alteracoes/modal-alteracoes.component';
import { ModalFotoDicaComponent } from './modais/modal-foto-dica/modal-foto-dica.component';
import { SaibaMaisModule } from './saiba-mais/saiba-mais.module';
import { ProdutoStorage } from './select-produto/produto.storage';
import { SelectProdutoService } from './select-produto/select-produto.service';
import { SliderComponent } from './slider/slider.component';
import { ConfirmModalComponent } from './timer-token/confirm-modal/confirm-modal.component';
import { TimerTokenComponent } from './timer-token/timer-token.component';
import { TokenNotReceivedInfoModalComponent } from './timer-token/token-not-received/token-not-received-info-modal.component';
import { TipoIdentificacaoComponent } from './tipo-edentificacao/tipo-identificacao.component';
import { TipoEnderecoComponent } from './tipo-endereco/tipo-endereco.component';
import { UploadArquivosDropComponent } from './upload-arquivos-drop/upload-arquivos-drop.component';
import { UploadArquivosDropDirective } from './upload-arquivos-drop/upload-arquivos-drop.directive';
import { UploadArquivosModalComponent } from './upload-arquivos-modal/upload-arquivos-modal.component';
import { UploadArquivosMultiavalistasComponent } from './upload-arquivos-multiavalistas/upload-arquivos-multiavalistas.component';
import { UploadDocumentationItemMultiavalistasComponent } from './upload-arquivos-multiavalistas/upload-documentation-item-multiavalistas/upload-documentation-item-multiavalistas.component';
import { ReportDocumentationItemComponent } from './upload-arquivos/report-documentation-item/report-documentation-item.component';
import { UploadArquivosComponent } from './upload-arquivos/upload-arquivos.component';
import { UploadDocumentationItemComponent } from './upload-arquivos/upload-documentation-item/upload-documentation-item.component';
import { UploadFotoGarantiaComponent } from './upload-arquivos/upload-foto-garantia/upload-foto-garantia.component';
import { VisualizaImagemComponent } from './upload-arquivos/visualiza-imagem/visualiza-imagem.component';
import { UserPlusComponent } from './user-plus/user-plus.component';
import { Util } from './utils';

import { VisualizacaoDocumentacaoComponent } from './upload-arquivos/visualizacao-documentacao/visualizacao-documentacao.component';
import { PerfilClienteModule } from './perfil-cliente/perfil-cliente.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DirectivesModule,
    ModaisModule,
    LoadersModule,
    SaibaMaisModule,
    MaskModule,
    ReactiveFormsModule,
    PerfilClienteModule,
    AngularDualListBoxModule,
    PerfilClienteModule,
  ],
  declarations: [
    LoaderComponent,
    CalendarComponent,
    BellNotificationComponent,
    BellPlusComponent,
    UserPlusComponent,
    BtnGroupComponent,
    CentralLoaderComponent,
    GrauParentescoComponent,
    EstadoCivilComponent,
    FormGroupComponent,
    TipoEnderecoComponent,
    TipoIdentificacaoComponent,
    CustomSelectComponent,
    SliderComponent,
    UploadArquivosComponent,
    UploadFotoGarantiaComponent,
    SliderComponent,
    DialogComponent,
    FallbackImageDirective,
    UploadDocumentationItemComponent,
    ReportDocumentationItemComponent,
    SwitchComponent,
    AnimatedButtonComponent,
    AutoCompleteComponent,
    AnimatedButtonInputComponent,
    GrradientButtonComponent,
    UploadArquivosMultiavalistasComponent,
    UploadDocumentationItemMultiavalistasComponent,
    ButtonSelectComponent,
    ButtonSelectItemComponent,
    InputDateComponent,
    EmotionSelectComponent,
    EmotionSelectItemComponent,
    UploadArquivosMultiavalistasComponent,
    UploadDocumentationItemMultiavalistasComponent,
    TimerTokenComponent,
    ConfirmModalComponent,
    TokenNotReceivedInfoModalComponent,
    ModalFotoDicaComponent,
    UploadArquivosDropComponent,
    UploadArquivosDropDirective,
    VisualizaImagemComponent,
    UploadArquivosModalComponent,
    AddModalComponent,
    CustomDualListComponent,
    AddModalComponent,
    VisualizacaoDocumentacaoComponent,
  ],
  exports: [
    LoaderComponent,
    CalendarComponent,
    BellNotificationComponent,
    BellPlusComponent,
    UserPlusComponent,
    BreadcrumbModule,
    BtnGroupComponent,
    CentralLoaderComponent,
    GrauParentescoComponent,
    EstadoCivilComponent,
    FormGroupComponent,
    TipoEnderecoComponent,
    TipoIdentificacaoComponent,
    CustomSelectComponent,
    ModaisModule,
    LoadersModule,
    SaibaMaisModule,
    PerfilClienteModule,
    SliderComponent,
    UploadArquivosComponent,
    UploadFotoGarantiaComponent,
    SliderComponent,
    DialogComponent,
    FallbackImageDirective,
    UploadDocumentationItemComponent,
    ReportDocumentationItemComponent,
    SwitchComponent,
    AnimatedButtonComponent,
    AutoCompleteComponent,
    AnimatedButtonInputComponent,
    GrradientButtonComponent,
    UploadArquivosMultiavalistasComponent,
    UploadDocumentationItemMultiavalistasComponent,
    ButtonSelectComponent,
    ButtonSelectItemComponent,
    InputDateComponent,
    EmotionSelectComponent,
    EmotionSelectItemComponent,
    UploadArquivosMultiavalistasComponent,
    UploadDocumentationItemMultiavalistasComponent,
    TimerTokenComponent,
    ModalFotoDicaComponent,
    UploadArquivosDropComponent,
    CustomDualListComponent,
  ],
  entryComponents: [
    TokenNotReceivedInfoModalComponent,
    ModalFotoDicaComponent,
    UploadArquivosDropComponent,
    VisualizaImagemComponent,
    ModalAlteracoesComponent,
    AddModalComponent,
    VisualizacaoDocumentacaoComponent,
  ],
  providers: [
    LoaderService,
    Util,
    SelectProdutoService,
    ProdutoStorage,
    StorageService,
    BsModalRef,
  ],
})
export class SharedModule { }
