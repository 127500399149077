export class RenavanPartner {
  public static isValid(renavam: string): boolean {
    if (!renavam) { return false; }
    if (renavam.toString().length !== 11) { return false; }

    const controlList = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const renavamArray: any[] = renavam.split('');
    const renavamNoDigit: any[] = renavamArray.slice(0, 10);

    let valueResult = 0;
    for (let i = 0; i < renavamNoDigit.length; i++) {
      valueResult += renavamNoDigit[i] * controlList[i];
    }

    valueResult = 11 - (valueResult % 11);
    valueResult = (valueResult >= 10 ? 0 : valueResult);

    const renavamLastDigit = renavamArray[10];
    return renavamLastDigit === valueResult.toString();
  }
}
