
import { take } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LoginService } from '../login.service';
import { ModalComponent } from './../../shared/modais/modal/modal.component';
import { LoginStorage } from './../login.storage';
import { SelectAgenteResult } from './select-agente-result';
import { SelectAgenteResultFactory } from './select-agente-result-factory';
import { PromotorService } from '../../services/promotor/promotor.service';

@Component({
  selector: 'app-select-agente',
  templateUrl: './select-agente.component.html',
  styleUrls: ['./select-agente.component.css']
})
export class SelectAgenteComponent implements OnInit {

  @Output() onSelectAgente: EventEmitter<SelectAgenteResult> = new EventEmitter();

  @ViewChild('selecionarAgenteModal', { static: true }) selecionarAgenteModal: ModalComponent;

  agentes: any[];
  agente: any;

  constructor(
    private loginStorage: LoginStorage,
    private loginService: LoginService,
    private promotorService: PromotorService) {
  }

  ngOnInit() {
    this.initObservablesModalSelecionar();
  }

  async selecionar() {
    await this.verificaPromotor();
    this.initAgentes();
    if (this.agentes.length === 1) {
      this.postAgente();
    } else if (this.agentes.length > 1) {
      this.selecionarAgenteModal.show();
    } else {
      this.onSelectAgente.next(SelectAgenteResultFactory.success());
    }
  }

  private initAgentes() {
    this.agentes = this.loginStorage.usuario.agentesSelecionar;
    if (this.agentes && this.agentes.length > 0) {
      this.agente = this.agentes[this.getIndexAgenteSelected()];
    }
  }

  private getIndexAgenteSelected() {
    const agente = this.loginStorage.agente;
    if (agente) {
      const index = this.agentes.findIndex(ag => ag.id === agente.id);
      return index >= 0 ? index : 0;
    }
    return 0;
  }

  private initObservablesModalSelecionar() {
    this.selecionarAgenteModal.close.subscribe(() => {
      this.onSelectAgente.next(SelectAgenteResultFactory.canceled());
    });

    this.selecionarAgenteModal.ok.subscribe(() => {
      this.postAgente();
    });
  }

  private postAgente() {
    this.loginService
      .selecionarAgente(this.agente.id).pipe(
      take(1))
      .subscribe(
      () => {
        this.loginStorage.registerAgente(this.agente);
        this.onSelectAgente.next(SelectAgenteResultFactory.success());
      },
      error => this.onSelectAgente.next(SelectAgenteResultFactory.error()));
  }

  private verificaPromotor() {
    return new Promise<void>(async (resolve) => {
      if (!!this.loginStorage.usuario.promotor.id) {
        await this.promotorService.getOperadorLiberaCessao(this.loginStorage.usuario.promotor.id);
      }
      resolve();
    });
  }
}
