import { SelectAgenteResultStatus } from './select-agente-result-status';

export class SelectAgenteResult {

  constructor(private _status: SelectAgenteResultStatus) {  }

  get status(): SelectAgenteResultStatus {
    return this._status;
  }
}
