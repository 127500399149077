import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ModalErroAoAcessarCameraComponent } from './../modal-erro-ao-acessar-camera/modal-erro-ao-acessar-camera.component';

@Component({
  selector: 'app-capture-selfie-verificacao',
  templateUrl: './capture-selfie-verificacao.component.html',
  styleUrls: ['./capture-selfie-verificacao.component.scss'],
})
export class CaptureSelfieVerificacaoComponent implements OnInit {
  @Output() capture = new EventEmitter<File>();
  @Output() backStep = new EventEmitter<any>();
  webcamTrigger: Subject<void> = new Subject<void>();
  nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  textoErroWebcam: string = 'Não há webcams disponíveis.';
  hasMultWebcam = false;
  hasWebcam = false;
  showLoader = false;

  constructor(private modal: BsModalService) {}

  ngOnInit() {
    this.initWebcam();
  }

  private async initWebcam(): Promise<void> {
    this.showLoader = true;
    try {
      await this.initMediaDevices();
      if (!this.hasMultWebcam) {
        await new Promise((resolve) => {
          setTimeout(async () => {
            await this.initMediaDevices();
            resolve();
            // tslint:disable-next-line: align
          }, 100);
        });
      }
    } finally {
      this.showLoader = false;
    }
  }

  private async initMediaDevices(): Promise<void> {
    try {
      const mediaDevices: MediaDeviceInfo[] = await WebcamUtil.getAvailableVideoInputs();
      this.hasMultWebcam = mediaDevices && mediaDevices.length > 1;
      this.hasWebcam = mediaDevices && mediaDevices.length > 0;
    } catch (error) {
      this.handleErroWebcam(error);
    }
  }

  handleImageCapture(imagem: WebcamImage): void {
    if (imagem && imagem.imageAsBase64) {
      this.capture.emit(this.createFileFromImageUri(imagem.imageAsBase64));
    }
  }

  handleErroWebcam($event) {
    this.hasWebcam = false;
    this.onClickAvisoNoWebcam();
    console.error($event);
  }

  onClickAvisoNoWebcam() {
    const modal = this.modal.show(ModalErroAoAcessarCameraComponent, {
      class: 'modal-sm3',
      keyboard: false,
      ignoreBackdropClick: true,
    }).content;

    modal.closeModal.subscribe(() => {
      this.backStep.emit();
    });
  }

  onClickTirarUmaFoto(): void {
    this.webcamTrigger.next();
  }

  get randomString(): string {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(2);
  }

  private createFileFromImageUri(imageAsBase64: string): File {
    const fileName = `${this.randomString}${this.randomString}${this.randomString}${this.randomString}`;
    const completeFileName = `${fileName}.jpeg`;
    const blobImage = this.base64ToBlob(imageAsBase64);
    return new File([blobImage], completeFileName, { type: 'image/jpeg' });
  }

  private base64ToBlob(base64image: string) {
    const byteString = atob(base64image);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    byteString.split('').forEach((chr, i) => {
      int8Array[i] = chr.charCodeAt(0);
    });

    return new Blob([arrayBuffer], { type: 'image/jpeg' });
  }

  public goNextWebcam(): void {
    this.nextWebcam.next(true);
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
