export class RecuperaGarantia {
  private anoModelo: number;
  private chassi: string;
  private condicao: string;
  private cor: string;
  private descMarcaModelo: string;
  private especie: number;
  private bem: string;
  private placa: string;
  private tipoQuadro: string;
  private valor: number;
  private cotacao: number;
  private idMarca: number;
  private idModelo: number;
  private idVersao: number;

  constructor(obj) {
    this.anoModelo = obj.anoModelo || '';
    this.chassi = obj.chassi || '';
    this.condicao = obj.condicao || '';
    this.cor = obj.cor || '';
    this.descMarcaModelo = obj.descMarcaModelo || '';
    this.especie = parseInt(obj.especie, 10) || null;
    this.bem = obj.bem || '';
    this.placa = obj.placa || '';
    this.tipoQuadro = obj.tipoQuadro || '';
    this.valor = parseFloat(obj.valor) || null;
    this.cotacao = parseFloat(obj.cotacao) || null;
    this.idMarca = parseInt(obj.marca.id, 10) || null;
    this.idModelo = parseInt(obj.modelo.id, 10) || null;
    this.idVersao = parseInt(obj.versao.id, 10) || null;
  }

  getPlaca(): string {
    return this.placa;
  }
}
