import { StatusModel } from './../../../models/statusModel';
import { FichaStatus } from './ficha-status-interface';
export class StatusAprovada implements FichaStatus {
  status: StatusModel[] = [];

  constructor() {
    this.status.push(new StatusModel('Aprovada', '4'));
  }

  getStatus() {
    return this.status;
  }
}
