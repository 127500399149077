
import { finalize, take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CidadeService } from '../../services/cidade/cidade.service';
import { UfService } from '../../services/uf/uf.service';

@Component({
  selector: 'app-uf-cidade-select',
  templateUrl: './uf-cidade-select.component.html',
  styleUrls: ['./uf-cidade-select.component.css']
})
export class UfCidadeSelectComponent implements OnInit {

  @Input() uf: string;
  @Input() cidade: string;
  @Input() disabled = false;

  @Output() ufChange: EventEmitter<string> = new EventEmitter();
  @Output() cidadeChange: EventEmitter<string> = new EventEmitter();

  ufs: any[];
  cidades: any[];

  isBuscandoCidade = false;

  constructor(private ufService: UfService, private cidadeService: CidadeService) { }

  ngOnInit() {
    this.ufService.ufs().pipe(take(1)).subscribe((res: any[]) => this.ufs = res);
  }

  onChangeUf() {
    this.ufChange.emit(this.uf);
    this.cidade = undefined;
    this.cidadeChange.emit(this.cidade);

    if (this.uf) {
      this.refreshCidades(this.uf);
    }
  }

  onChangeCidade() {
    this.cidadeChange.emit(this.cidade);
  }

  refreshCidades(uf: string) {
    this.isBuscandoCidade = true;
    this.cidadeService
      .cidades(uf).pipe(
      take(1),
      finalize(() => this.isBuscandoCidade = false))
      .subscribe(res => this.cidades = res);
  }
}
