import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-parcela-personalizada',
  templateUrl: './parcela-personalizada.component.html',
  styleUrls: ['./parcela-personalizada.component.css']
})
export class ParcelaPersonalizadaComponent implements OnInit {

  @Input() periodRange: { min, max };

  alive: boolean = true;
  alterarValorForm: FormGroup;
  parcelasPersonalizada: number[];
  onSave: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.alterarValorForm = this.fb.group({
      quantidadeParcelas: ['', Validators.required]
    });
    this.gerarParcelas();
  }

  gerarParcelas(): void {
    this.parcelasPersonalizada = [];

    const min = this.periodRange.min || 3;
    const max = this.periodRange.max || 18;

    for (let i = min; i <= max; i++) {
      this.parcelasPersonalizada.push(i);
    }
  }

  onPersonalizarParcela() {
    this.onSave.emit(this.alterarValorForm.value);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }
}
