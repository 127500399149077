import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { PanelModule } from './../panel/panel.module';
import { SelectPeriodoComponent } from './select-periodo.component';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    SharedModule
  ],
  declarations: [SelectPeriodoComponent],
  exports: [SelectPeriodoComponent]
})
export class SelectPeriodoModule { }
