import { Injectable } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

export interface AutenticacaoCliente {
  isAutenticacaoManual: boolean;
  artefatos?: AutenticacaoManual;
}

export interface AutenticacaoManual {
  biometria: WebcamImage | File;
  celularFoiValidado: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoClienteService {
  private autenticacaoCliente$ = new Subject<AutenticacaoCliente>();

  atualizarAutenticacaoManual(value: AutenticacaoCliente) {
    this.autenticacaoCliente$.next(value);
  }

  get autenticacaoClienteRealizada(): Observable<AutenticacaoCliente> {
    return this.autenticacaoCliente$.asObservable();
  }
}
