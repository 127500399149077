
import { take } from 'rxjs/operators';
import { Component, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-new-modal-message',
  templateUrl: './new-modal-message.component.html'
})
export class NewModalMessageComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  @Input() titulo: string = 'Título';
  @Input() mensagem: string = 'Mensagem';
  @Input() labelFechar: string = 'Fechar';
  @Input() labelOk: string = 'Ok';
  @Input() showFecharBtn: boolean = true;
  @Input() showOkBtn: boolean = true;
  @Input() isTituloVermelho: boolean = false;

  @Output() hiddenModalSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  showModal(
    options?: {
      titulo?: string,
      mensagem?: string,
      labelFechar?: string,
      labelOk?: string,
      showFecharBtn?: boolean,
      showOkBtn?: boolean,
      isTituloVermelho?: boolean
    }): void {

    if (options) {
      this.titulo = options.titulo || this.titulo;
      this.mensagem = options.mensagem || this.mensagem;
      this.labelFechar = options.labelFechar || this.labelFechar;
      this.labelOk = options.labelOk || this.labelOk;
      this.showFecharBtn = options.showFecharBtn || this.showFecharBtn;
      this.showOkBtn = options.showOkBtn || this.showOkBtn;
      this.isTituloVermelho = options.isTituloVermelho || this.isTituloVermelho;
    }

    this.modalService
      .onHidden.pipe(
      take(1))
      .subscribe(
        () => this.hiddenModalSubject.next(true)
      );

    this.bsModalRef = this.modalService.show(this.template);
  }

  getModalsCount() {
    return this.modalService.getModalsCount();
  }
}
