import { StorageService } from './../../../services/storage/storage.service';
import { takeWhile, catchError } from 'rxjs/operators';
import {
  Component,
  DoCheck,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FichaService } from '../../ficha-veiculo.service';
import { setFichaContent } from '../helpers/salvarFicha';
import { RecuperaAvalista } from '../model/recupera/recupera-avalista';
import { RecuperaCliente } from '../model/recupera/recupera-cliente';
import { FichaCadastro } from './../ficha-cadastro';
import { Pessoa } from './../model/pessoa';
import { Proposta } from './../model/proposta';
import { Seguro } from './../model/seguro';
import { FichaCadastroSharedService } from './../shared/ficha-cadastro-shared.service';
import { DadoSimulacao } from './model/dado-simulacao';
import { AssistenciaSimulacao } from './model/proposta/assistencia-simulacao';
import { PropostaSimulacao } from './model/proposta/proposta-simulacao';
import { SeguroSimulacao } from './model/proposta/seguro-simulacao';
import { Parcela } from './parcela/parcela';
import { ModalMessageComponent } from './../../../shared/modais/modal-message/modal-message.component';
import { NewModalMessageComponent } from './../../../shared/modais/new-modal-message/new-modal-message.component';
import { of } from 'rxjs';
import { GrupoSeguro } from './model/proposta/grupo-seguro';
import { GrupoAssistencia } from './model/proposta/grupo-assistencia';
import { GrupoAssistenciaSelecionado } from './model/proposta/grupo-assistencia-selecionado';
import { GrupoSeguroSelecionado } from './model/proposta/grupo-seguro-selecionado';
import { LeadsOnlineService } from '../../../produtos/leads/services/leads.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-ficha-cadastro-resultado-parcial',
  templateUrl: './ficha-cadastro-resultado-parcial.component.html',
  styleUrls: ['./ficha-cadastro-resultado-parcial.component.css'],
})
export class FichaCadastroResultadoParcialComponent
  implements OnInit, OnDestroy, DoCheck
{
  @Input() fichaCadastro: FichaCadastro;

  @ViewChild('messageModal', { static: true })
  messageModal: NewModalMessageComponent;

  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;

  @ViewChild('modelAttentionMessage', { static: true })
  modelAttentionMessage: ModalMessageComponent;

  private readonly PARCELAS: number[] = [12, 18, 24, 36, 48];
  private readonly TAXA_CLIENTE_NOT_FOUND: string = 'TAXA_CLIENTE_NOT_FOUND';
  private readonly PLACA_INVALIDA: string = 'PLACA_INVALIDA';
  private readonly FIPE_NOT_FOUND: string = 'FIPE_NOT_FOUND';

  simulacao: DadoSimulacao = new DadoSimulacao();
  proposta: PropostaSimulacao = new PropostaSimulacao({});
  errorMessage: string;
  valorInicialSelecionado: number;

  isExecutandoSimulacao = false;
  isGettingFicha = false;
  parcelasSimuladas: Parcela[] = [];
  isPrimeiraVez = true;
  isButtonNextDisabled = false;
  private isAlive = true;
  private isInitObservable = true;
  private isPossuiSimuacaoPendente = false;
  private quantidadeParcelasProcessadas = 0;

  public todosGruposSeguros: GrupoSeguro[] = [];
  public todosGruposAssistencias: GrupoAssistencia[] = [];
  public todosSeguros = [];
  public todasAssistencias = [];

  constructor(
    private route: ActivatedRoute,
    private fichaService: FichaService,
    private leadsOnlineService: LeadsOnlineService,
    private sharedService: FichaCadastroSharedService,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.initObservableResultadoParcial();
  }

  ngDoCheck() {
    if (this.isAlive && this.fichaCadastro.getFichaLocalStorage) {
      this.isAlive = false;
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.isInitObservable = false;
  }

  private initObservableResultadoParcial() {
    this.sharedService.resultadoParcialObservable
      .pipe(takeWhile(() => this.isInitObservable))
      .subscribe((proposta: PropostaSimulacao) => {
        this.proposta = proposta;
        this.fichaCadastro._client.cpf = this.proposta.cliente.getCpf();
        this.loadInfoCliente();
        this.simulacao = new DadoSimulacao();
        this.simulacao.init(this.proposta);
        this.iniciarSimulacoes();
      });
  }

  simularProposta(id: string) {
    this.fichaService
      .getDadosProposta(id)
      .pipe(catchError((err) => of(this.handleErrorSimulacao(err))))
      .subscribe((result) => this.handleResultDadoSimulacao(result));
  }

  private handleResultDadoSimulacao(result) {
    this.simulacao.setNumeroProposta(result.proposta.id);
    this.initView(result);
  }

  private handleErrorSimulacao(err) {
    const error = err.error || err;
    this.isExecutandoSimulacao = false;
    if (error.status === this.TAXA_CLIENTE_NOT_FOUND) {
      this.handleErroTaxaClienteNaoEncontrada(error);
    } else if (error.status === this.PLACA_INVALIDA) {
      this.fillDataError(error);
      this.perguntaCorrigirDadosPlaca(error);
      this.fichaCadastro.setErrorStatus(error.status);
      this.sharedService.resultadoParcialObservable.subscribe(
        (result) => {
          this.unsetIsGettingFicha();
          this.validaParcelas(result);
        },
        (error) => this.handleSimulacaoError(error)
      );
    } else if (error.status === this.FIPE_NOT_FOUND) {
      this.fillDataError(error);
      this.fichaCadastro.setErrorStatus(error.status);
      this.handleErroFipeNaoEncontrada(error);
      this.sharedService.resultadoParcialObservable.subscribe(
        (result) => {
          this.unsetIsGettingFicha();
          this.validaParcelas(result);
        },
        (error) => this.handleSimulacaoError(error)
      );
    } else {
      this.handleErroInesperado();
    }
  }

  private fillDataError(data) {
    this.fichaCadastro.placaVeiculo = data.veiculos[0].placa;
    this.fichaCadastro.setPayload('cpf', this.formatCpf(data.cliente.cpf));
    this.fichaCadastro.setPayload(
      'dataNascimento',
      data.cliente.dataNascimento
    );
    this.fichaCadastro.setPayload('ddd', data.cliente.telefones[0].ddd);
    this.fichaCadastro.setPayload('celular', data.cliente.telefones[0].numero);
    this.fichaCadastro.setPayload('renda', `${data.cliente.renda}`);
    this.fichaCadastro.setPayload('grupo1', data.grupo1);
    this.fichaCadastro.setPayload('lojaId', `${data.lojista.id}`);
    this.fichaCadastro.setPayload(
      'vendedorId',
      this.storageService.getLocal().vendedorId
    );
    if (data.avalistas && data.avalistas.length > 0) {
      this.fichaCadastro.setPayload('avalistas', [
        {
          cpf: data.avalistas[0].cpf,
          dataNascimento: data.avalistas[0].dataNascimento,
          ddd: data.avalistas[0].telefones[0].ddd,
          celular: data.avalistas[0].telefones[0].numero,
          renda: `${data.avalistas[0].renda}`,
          grauParentesco: `${data.avalistas[0].grauParentescoCliente}`,
        },
      ]);
    }
  }

  private handleErroFipeNaoEncontrada(error: any) {
    this.showErrorMessage(error.message, () => {
      this.sharedService.selecionarVeiculoManualmente();
      this.fichaCadastro.passoCorrigirFipeNotFound();
      this.fichaCadastro.setIdSimulacao(error.id);
    });
  }

  private perguntaCorrigirDadosPlaca(error: any) {
    this.modalMessage.show(
      'Não foi possível completar a simulação.\n\n A placa informada é inválida. Deseja preencher as informações do veículo manualmente ?',
      'Sim',
      'Não'
    );
    this.modalMessage.ok
      .take(1)
      .subscribe(() => this.handleErroFipeNaoEncontradaWithoutMessage(error));
    this.modalMessage.close
      .take(1)
      .subscribe(() => this.handleErroPlacaInvalida(error));
    this.modalMessage.modalClose.subscribe();
  }

  private handleErroPlacaInvalida(error: any) {
    this.fichaCadastro.passoCorrigirPLacaInvalida();
    this.fichaCadastro.setIdSimulacao(error.id);
  }

  private handleErroFipeNaoEncontradaWithoutMessage(error: any) {
    this.sharedService.selecionarVeiculoManualmente();
    this.fichaCadastro.passoCorrigirFipeNotFound();
    this.fichaCadastro.hasUsado = true;
    this.fichaCadastro.setIdSimulacao(error.id);
  }

  private handleErroTaxaClienteNaoEncontrada(error: any) {
    this.showErrorMessage(error.message, () => this.home());
  }

  private handleErroInesperado() {
    this.messageModal.showModal({
      mensagem: 'Ocorreu um erro inesperado ao buscar os dados da simulação.',
      titulo: 'Atenção!',
    });
  }

  private initView(result) {
    this.proposta = new PropostaSimulacao(result);
    this.setDataAvalista(result);
    this.getRendaCliente(result);
    this.getRendaAvalista(result);
    this.fichaCadastro._client.cpf = this.proposta.cliente.getCpf();
    this.proposta.getTaxaClinte().retornoMax = result.taxaCliente
      ? result.taxaCliente.retornoMax
      : 0;
    this.loadInfoCliente();
    const avalistas = this.proposta.getAvalistas();
    if (avalistas && avalistas.length > 0) {
      this.fichaCadastro.setAvalista(true);
    } else {
      this.fichaCadastro.setAvalista(false);
    }
    this.setLeads(result.proposta.origem);
    this.simulacao.init(this.proposta);
    this.valorInicialSelecionado = this.simulacao.getValor();
    this.iniciarSimulacoes();
    this.unsetIsGettingFicha();
    this.fichaCadastro.grupo1 = result.grupo1;
    this.fichaCadastro.garantias = [{
      anoModelo: result.veiculos[0].anoModelo,
      descMarcaModelo: `${result.veiculos[0].marca.descricao}/${result.veiculos[0].modelo.descricao}`
    }];
    this.fichaCadastro.garantiasOriginais = this.fichaCadastro.garantias;
  }

  private handleErrorInitView(result) {
    if (result.proposta && result.veiculos && result.taxaCliente) {
      this.initView(result);
    } else {
      this.errorMessage = result.error.message;
    }
  }

  setDataAvalista(data) {
    if (data.avalistas instanceof Array && data.avalistas.length > 0) {
      this.fichaCadastro._avalista = new RecuperaAvalista(
        data.avalistas[0]
      ).$avalista;
    }
  }

  getRendaCliente(data) {
    if (data.cliente) {
      this.fichaCadastro.rendaCliente = data.cliente.renda;
    }
  }

  getRendaAvalista(data) {
    if (data.avalistas) {
      this.fichaCadastro.rendaAvalista = data.avalistas.renda;
    }
  }

  loadInfoCliente() {
    this.fichaService
      .recuperarDadosFicha(
        this.formatCpf(this.fichaCadastro._client.cpf),
        this.fichaCadastro.IDLojista
      )
      .subscribe(
        (res) => {
          this.varificaTipoPessoa(res.clientes);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  varificaTipoPessoa(data) {
    if (!data) {
      return console.log(new Error('Não recebeu nenhum dado '));
    }

    data.forEach((item) => {
      if (item.tipo === 'CLIENTE' && !this.fichaCadastro.getFichaLocalStorage) {
        this.fichaCadastro._client = new RecuperaCliente(item).$client;
      } else if (
        item.tipo === 'AVALISTA' &&
        !this.fichaCadastro.getFichaLocalStorage
      ) {
        this.fichaCadastro._avalista = new RecuperaAvalista(item).$avalista;
      }
    });
  }

  formatCpf(data) {
    if (data) {
      return data.replace(/[\.\-]/g, '');
    }
  }

  iniciarSimulacoes() {
    if (!this.isExecutandoSimulacao) {
      this.isPossuiSimuacaoPendente = false;
      this.quantidadeParcelasProcessadas = 0;
      this.simular();
    } else {
      this.isPossuiSimuacaoPendente = true;
    }
  }

  simular() {
    this.isExecutandoSimulacao = true;
    if (this.isPrimeiraVez) {
      this.simulacao.getSeguro().setGrupoAssistenciaIds([]);
      this.simulacao.getSeguro().setGrupoSeguroIds([]);
    }
    if (!this.fichaCadastro.isLeads) {
      this.fichaService
        .simulacao(this.simulacao, 0)
        .pipe(takeWhile(() => this.isExecutandoSimulacao))
        .subscribe(
          result => this.validaParcelas(result),
          error => this.handleSimulacaoError(error)
        );
    } else {
      this.leadsOnlineService
      .simulacao(this.simulacao, 0)
      .pipe(takeWhile(() => this.isExecutandoSimulacao))
      .subscribe(
        (result) => this.validaParcelas(result),
        (error) => this.handleSimulacaoError(error)
      );
    }
  }

  async getAllSeguros(resultadoSimulacao) {
    this.isExecutandoSimulacao = true;
    if (resultadoSimulacao.seguros && resultadoSimulacao.seguros.length > 0) {
      await this.obterGruposSeguro();
      await this.obterSeguros();
      this.handlerSegurosResult(resultadoSimulacao);
    } else {
      this.proposta.gruposSeguros = [];
      this.getAllAssistencias(resultadoSimulacao);
    }
  }

  async obterSeguros() {
    const seguros = await this.fichaService.getAllSeguros().toPromise();

    if (!seguros) {
      this.messageModal.showModal({
        titulo: 'Atenção!',
        mensagem:
          'Ocorreu um erro inesperado ao obter os Seguros, tente novamente.',
      });
      return;
    }

    this.todosSeguros = seguros.tiposSeguro;
  }

  async obterGruposSeguro() {
    this.todosGruposSeguros = await this.fichaService
      .getAllGrupoSeguro()
      .toPromise();

    if (!this.todosGruposSeguros) {
      this.messageModal.showModal({
        titulo: 'Atenção!',
        mensagem:
          'Ocorreu um erro inesperado ao obter os Grupos Seguro, tente novamente.',
      });
    }
  }

  async obterGruposAssistencia() {
    this.todosGruposAssistencias = await this.fichaService
      .getAllTipoAssistencia()
      .toPromise();

    if (!this.todosGruposAssistencias) {
      this.messageModal.showModal({
        titulo: 'Atenção!',
        mensagem:
          'Ocorreu um erro inesperado ao obter os Grupos Assistencias, tente novamente.',
      });
    }
  }

  async obterAssistencias() {
    this.todasAssistencias = await this.fichaService
      .getAllAssistencias()
      .toPromise();

    if (!this.todosGruposAssistencias) {
      this.messageModal.showModal({
        titulo: 'Atenção!',
        mensagem:
          'Ocorreu um erro inesperado ao obter os Assistencias, tente novamente.',
      });
    }
  }

  async getAllAssistencias(resultadoSimulacao) {
    this.proposta.assistencias = [];
    await this.obterGruposAssistencia();
    await this.obterAssistencias();

    if (
      resultadoSimulacao.assistencias &&
      resultadoSimulacao.assistencias.length > 0
    ) {
      this.handlerAssistenciasResult(resultadoSimulacao);
    } else {
      this.isExecutandoSimulacao = false;
    }
  }

  validaParcelas(result) {
    this.isButtonNextDisabled = false;
    if (result.parcelamentos.length === 0) {
      this.handleErrorSemParcelamento();
    } else if (result.parcelamentos.length < this.PARCELAS.length) {
      this.handleErrorParcelamentoIndisponivel();
      this.montaParcelas(result);
    } else {
      this.montaParcelas(result);
    }
  }

  montaParcelas(result) {
    this.isPrimeiraVez = false;
    this.montaParcelasVazio();
    const parcelaSelecionada = cloneDeep(this.simulacao.parcelas);
    let parcelaAdd = new Parcela();
    result.parcelamentos.forEach((parcela: any) => {
      parcelaAdd = new Parcela();
      if (this.PARCELAS.includes(parcela.parcelas)) {
        parcelaAdd.setQuantidade(parcela.parcelas);
        parcelaAdd.setValor(parcela.valorParcela);
        parcelaAdd.setExibir(true);
        parcelaAdd.setTaxa(parcela.taxa);
        parcelaAdd.setValorTotal(parcela.valorTotal);
        parcelaAdd.setFator(parcela.fator);
        if (
          !parcelaSelecionada ||
          parcelaSelecionada === parcelaAdd.getQuantidade()
        ) {
          this.simulacao.parcelas = parcelaAdd.getQuantidade();
          this.proposta.getTaxaClinte().valorParcela = parcelaAdd.getValor();
          this.proposta.getTaxaClinte().parcelas = parcelaAdd.getQuantidade();
          this.proposta.getTaxaClinte().fator = parcelaAdd.getFator();
          this.proposta.getTaxaClinte().taxaFinal = parcelaAdd.getTaxa();
        }
        const index = this.PARCELAS.findIndex(
          (element) => element === parcela.parcelas
        );
        this.parcelasSimuladas[index] = parcelaAdd;
      }
    });

    this.parcelasSimuladas.sort(
      (a, b) => a.getQuantidade() - b.getQuantidade()
    );
    this.carregaDadosDetalhe(result);
    this.getAllSeguros(result);
  }

  carregaDadosDetalhe(result) {
    this.proposta.getTaxaClinte().parcelaMax = result.valorMaxParcela;
    this.proposta.getTaxaClinte().valorRegistro = result.valorRegistro;
    this.proposta.getTaxaClinte().valorSircof = result.valorSircof;
    this.proposta.getTaxaClinte().valorTc = result.valorTc;
    this.proposta.getTaxaClinte().valorDv = result.valorDv;
    this.proposta.getTaxaClinte().entrada = result.valorEntrada;
    this.proposta.getTaxaClinte().valorLiquido = result.valorLiquido;
    this.proposta.getTaxaClinte().valorRetorno = result.valorRetorno;
  }

  montaParcelasVazio() {
    this.parcelasSimuladas = [];
    this.PARCELAS.forEach((opcao: number) => {
      const parcelaAdd = new Parcela();
      this.parcelasSimuladas.push(parcelaAdd);
    });
    this.isExecutandoSimulacao = false;
  }

  handlerSegurosResult(resultadoSimulacao) {
    const seguroSelecionadosDefault: GrupoSeguroSelecionado[] = [];
    this.proposta.gruposSeguros = [];
    resultadoSimulacao.seguros.forEach((grupoSeguroSimulacao: any) => {
      const grupoSeguro = new GrupoSeguro();
      grupoSeguro.id = grupoSeguroSimulacao.id;
      const seguro = this.todosGruposSeguros.find(o => o.id === grupoSeguro.id);
      grupoSeguro.descricao = (seguro ? seguro.descricao : null);
      grupoSeguro.seguros = [];
      if (grupoSeguroSimulacao.items) {
        grupoSeguroSimulacao.items.forEach((grupoSeguroSimulacaoCadaSeguro: any) => {
          const seguroItems: any = new Object();
          seguroItems.id = grupoSeguroSimulacaoCadaSeguro.id;
          seguroItems.valor = grupoSeguroSimulacaoCadaSeguro.valor;
          if (grupoSeguroSimulacaoCadaSeguro.selected) {
            seguroSelecionadosDefault.push(new GrupoSeguroSelecionado(grupoSeguroSimulacaoCadaSeguro.id, grupoSeguro.id));
          }
          this.todosSeguros.forEach((seguroLista: any) => {
            if (seguroLista.id === grupoSeguroSimulacaoCadaSeguro.id) {
              seguroItems.descricao = seguroLista.descricaoAPP;
              seguroItems.percentual = seguroLista.percentual;
              seguroItems.valorMaximo = seguroLista.valorMaximoParc;
              seguroItems.saibaMais = seguroLista.saibaMais;
            }
          });
          grupoSeguro.seguros.push(new SeguroSimulacao(seguroItems));
        });
      }
      this.proposta.gruposSeguros.push(grupoSeguro);
    });
    this.simulacao.getSeguro().setGrupoSeguroIds(seguroSelecionadosDefault);
    this.getAllAssistencias(resultadoSimulacao);
  }

  handlerAssistenciasResult(resultadoSimulacao) {
    const assistenciasSelecionadosDefault:GrupoAssistenciaSelecionado[] = [];
    this.proposta.gruposAssistencias = [];
    resultadoSimulacao.assistencias.forEach((grupoAssistenciaSimulacao: any) => {
      // Cria Grupo Assistencia
      const grupoAssistencia = new GrupoAssistencia();
      grupoAssistencia.id = grupoAssistenciaSimulacao.id;
      const assistencia = this.todosGruposAssistencias.find(o => o.id === grupoAssistencia.id);
      grupoAssistencia.descricao = (assistencia ? assistencia.descricao : null);
      grupoAssistencia.assistencias = [];
      if (grupoAssistenciaSimulacao.items) {
        grupoAssistenciaSimulacao.items.forEach((grupoCadaAssistencia: any) => {
          const assistencia: any = new Object(); // assistencia
          assistencia.id = grupoCadaAssistencia.id;
          if (grupoCadaAssistencia.selected) {
            assistenciasSelecionadosDefault.push(new GrupoAssistenciaSelecionado(grupoCadaAssistencia.id, grupoAssistencia.id));
          }
          this.todasAssistencias.forEach((assistenciaLista: any) => {
            if (assistenciaLista.id === grupoCadaAssistencia.id) {
              assistencia.descricao = assistenciaLista.descricaoAplicativo;
              assistencia.saibaMais = assistenciaLista.descricaoSaibaMais;
              assistencia.valor = assistenciaLista.valor;
            }
          });
          grupoAssistencia.assistencias.push(new AssistenciaSimulacao(assistencia));
        });
      }
      this.proposta.gruposAssistencias.push(grupoAssistencia);
    });
    this.simulacao.getSeguro().setGrupoAssistenciaIds(assistenciasSelecionadosDefault);
    this.isExecutandoSimulacao = false;
  }

  handleSimulacaoError(error) {
    this.messageModal.showModal({
      mensagem:
        'Ocorreu um erro inesperado em nossos servidores, tente novamente.',
      titulo: 'Atenção!',
    });
    this.home();
  }

  handleErrorSemParcelamento() {
    this.messageModal.showModal({
      mensagem: 'Parcelamento não disponível para o valor selecionado.',
      titulo: 'Atenção!',
    });
    this.montaParcelasVazio();
    this.isButtonNextDisabled = true;
  }

  handleErrorParcelamentoIndisponivel() {
    this.messageModal.showModal({
      mensagem: 'Algumas opções de parcelamento não disponíveis.',
      titulo: 'Atenção!',
    });
  }

  onChangeSeguro(seguro) {
    this.simulacao.setSeguro(seguro);
    this.iniciarSimulacoes();
  }

  onChangeValorSimular(valor) {
    if (this.simulacao.getValor() !== valor) {
      this.simulacao.setValor(valor);
      this.iniciarSimulacoes();
    }
  }

  onChangeParcela(simular) {
    if (simular) {
      this.iniciarSimulacoes();
    }
  }

  onUpdateVeiculo() {
    this.isExecutandoSimulacao = false;
    this.isPossuiSimuacaoPendente = false;
  }

  onClickNext() {
    if (
      this.proposta.taxaCliente &&
      this.proposta.taxaCliente.retornoMax > 0 &&
      this.simulacao.seguro.retorno === 0
    ) {
      this.showAttentionMessageZeroStar(
        'O valor das Estrelas está igual a ZERO. Deseja continuar mesmo assim ?'
      );
      return;
    }

    this.initializeGoToNextTab();
  }

  private initializeGoToNextTab() {
    const propostaSimulada = this.proposta;
    const propostaEncerrar = this.translateProposta(propostaSimulada);
    this.translateSeguro(propostaEncerrar);
    this.translateAssistencia(propostaEncerrar);
    this.translateCliente(propostaSimulada, propostaEncerrar);
    this.translateAvalistas(propostaSimulada, propostaEncerrar);
    this.fichaCadastro.initializeProposta(propostaEncerrar);
    setFichaContent(this.fichaCadastro);
    this.fichaCadastro.onClickNext();
  }

  private translateProposta(propostaSimulada: PropostaSimulacao): Proposta {
    const taxaClienteSimulada = propostaSimulada.getTaxaClinte();
    const propostaEncerrar = new Proposta();

    propostaEncerrar.setId(propostaSimulada.getId());

    if (taxaClienteSimulada.getCarencia()) {
      propostaEncerrar.setCarencia(taxaClienteSimulada.getCarencia());
    } else {
      propostaEncerrar.setCarencia(this.simulacao.getCarencia());
    }

    propostaEncerrar.setValorEntrada(taxaClienteSimulada.getEntrada());
    propostaEncerrar.setValorTotal(taxaClienteSimulada.getValorLiberado());
    propostaEncerrar.setValorLiquido(taxaClienteSimulada.getValorLiquido());
    propostaEncerrar.setPercRetorno(
      this.simulacao.getSeguro().getPercRetorno()
    );
    propostaEncerrar.setValorParcela(taxaClienteSimulada.getValorParcela());
    propostaEncerrar.setQtdParcelas(taxaClienteSimulada.getParcelas());

    propostaEncerrar.setFator(taxaClienteSimulada.getFator());
    propostaEncerrar.setTaxaProposta(taxaClienteSimulada.getTaxaFinal());
    propostaEncerrar.setValorRegistro(taxaClienteSimulada.getValorRegistro());
    propostaEncerrar.setValorSircof(taxaClienteSimulada.getValorSircof());
    propostaEncerrar.setValorTc(taxaClienteSimulada.getValorTc());
    propostaEncerrar.setValorDv(taxaClienteSimulada.getValorDv());
    propostaEncerrar.setValorEntrada(taxaClienteSimulada.getEntrada());
    propostaEncerrar.setValorRetorno(taxaClienteSimulada.getValorRetorno());
    this.proposta.taxaCliente.seguro = taxaClienteSimulada.getSeguro();
    return propostaEncerrar;
  }

  private translateSeguro(propostaEncerrar: Proposta): void {
    const segurosIds = this.simulacao
      .getSeguro()
      .getGrupoSeguroIds()
      .map((elem) => elem.seguroId);

    if (
      segurosIds &&
      segurosIds.length > 0 &&
      this.proposta.gruposSeguros.length > 0
    ) {
      const segurosParaAdicionar = [];

      this.proposta.gruposSeguros.forEach((grupo) => {
        grupo.seguros.forEach((seguro) => {
          if (segurosIds.includes(seguro.id)) {
            segurosParaAdicionar.push(new Seguro(seguro.id, seguro.valor));
          }
        });
      });

      propostaEncerrar.setSeguro(segurosParaAdicionar);
    }
  }

  private translateAssistencia(propostaEncerrar: Proposta): void {
    const assistenciasIds = this.simulacao
      .getSeguro()
      .getGrupoAssistenciaIds()
      .map((elem) => elem.assistenciaId);

    if (
      assistenciasIds &&
      assistenciasIds.length > 0 &&
      this.proposta.gruposAssistencias.length > 0
    ) {
      const assistenciaParaAdicionar = [];

      this.proposta.gruposAssistencias.forEach((grupo) => {
        grupo.assistencias.forEach((assist) => {
          if (assistenciasIds.includes(assist.id)) {
            assistenciaParaAdicionar.push(new Seguro(assist.id, assist.valor));
          }
        });
      });

      propostaEncerrar.setAssistencia(assistenciaParaAdicionar);
    }
  }

  private translateCliente(
    propostaSimulada: PropostaSimulacao,
    propostaEncerrar: Proposta
  ) {
    const clienteSimulado = propostaSimulada.getCliente();
    const cliente = new Pessoa();
    cliente.setId(clienteSimulado.getId());
    cliente.setCpf(clienteSimulado.getCpf());
    cliente.setNome(clienteSimulado.getNome());
    cliente.setNomeMae(clienteSimulado.getNomeMae());
    cliente.setRenda(clienteSimulado.getRenda());
    cliente.setEmail(clienteSimulado.getEmail());
    cliente.setNomePai(clienteSimulado.getNomePai());
    cliente.setTelefones(clienteSimulado.getTelefones());
    cliente.setIdNacionalidade(clienteSimulado.getIdNacionalidade());
    cliente.setNaturalDe(clienteSimulado.getNaturalDe());
    cliente.setNaturalDeUf(clienteSimulado.getNaturalDeUf());
    cliente.setEnderecos(clienteSimulado.getEnderecos());
    cliente.setValorPatrimonio(clienteSimulado.getValorPatrimonio());
    cliente.setIdEstadoCivil(clienteSimulado.getIdEstadoCivil());
    cliente.setSexo(clienteSimulado.getSexo());
    cliente.setDocumento(clienteSimulado.getDocumento());
    cliente.setClientePpe(clienteSimulado.getPpe() ? 'S' : 'N');
    cliente.setFatca(clienteSimulado.getFatca() ? 'S' : 'N');
    cliente.setDadoProfissional(clienteSimulado.getProfissional());
    cliente.setClienteReferencia(clienteSimulado.getReferencia());

    propostaEncerrar.setCliente(cliente);
  }

  private translateAvalistas(
    propostaSimulada: PropostaSimulacao,
    propostaEncerrar: Proposta
  ) {
    const avalistasSimulado = propostaSimulada.getAvalistas();
    if (avalistasSimulado && avalistasSimulado.length > 0) {
      this.fichaCadastro.setAvalista(true);
      const avalistas = avalistasSimulado.map((obj) => {
        const avalista = new Pessoa();
        avalista.setId(obj.getId());
        avalista.setCpf(obj.getCpf());
        avalista.setRenda(obj.getRenda());
        avalista.setTelefones(obj.getTelefones());

        return avalista;
      });
      propostaEncerrar.setAvalistas(avalistas);
    }
  }

  setIsGettingFicha() {
    this.isGettingFicha = true;
  }

  unsetIsGettingFicha() {
    this.isGettingFicha = false;
  }

  private showErrorMessage(message: string, next: () => void) {
    this.modalMessage.show(message).modalClose.take(1).subscribe(next);
  }

  private showAttentionMessageZeroStar(message: string) {
    this.modelAttentionMessage.show(message).modalClose.take(1);
  }

  public onReturnContinueAttentionMessageZeroStar() {
    this.initializeGoToNextTab();
  }

  private home() {
    this.router.navigate(['/ficha']);
  }

  private setLeads(origem: number) {
    if (origem === 4) {
      this.fichaCadastro.isLeads = true;
    } else {
      this.fichaCadastro.isLeads = false;
    }
  }
}
