import { Agrupamento } from './agrupamento';
import * as moment from 'moment';
export class AgrupamentoData implements Agrupamento {

  descricao = 'data';

  getKey(proposta: any): string {
    return moment(proposta.emissaoProposta).format('DD/MM/YYYY');
  }

  compare(proposta1: any, proposta2: any): number {
    if  (proposta1.emissaoProposta > proposta2.emissaoProposta) {
      return 1;
    }
    return (proposta1.emissaoProposta < proposta2.emissaoProposta) ? -1 : 0;
  }
}
