import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { Proposta } from './../../../shared/models/proposta';
import { Cliente } from './../../../shared/models/cliente';
import { TimelineDetalhePagamentoVO, TimelineStatusVO } from '../../../shared/models/timelineVO';
import { appSettings as globals, appMicrocreditoSettings } from '../../../../../environments/app.setings';

@Component({
  selector: 'app-card-timeline',
  templateUrl: './card-timeline.component.html',
  styleUrls: ['./card-timeline.component.scss']
})
export class CardTimelineComponent {

  @Input() proposta: Proposta;
  @Input() timeline: TimelineStatusVO;
  @Input() detalhePagamento: TimelineDetalhePagamentoVO[];
  @Input() nextCard: TimelineStatusVO;
  @Input() vendedores: any[];
  @Output() executar: EventEmitter<any> = new EventEmitter();

  acoes = {
    FINALIZAR_FICHA: 'Sim',
    DESISTIR_FICHA: 'Não'
  };

  diasGeracaoContratoForm: FormGroup;
  promotorForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.diasGeracaoContratoForm = this.fb.group({
      diasGeracaoValor: ['', [Validators.required]]
    });

    this.promotorForm = this.fb.group({
      promotorId: ['', [Validators.required]]
    });
  }

  get permiteLinkParaFicha(): boolean {
    return this.nextCard && !this.nextCard.seqTimeline && this.timeline.tituloStatus === 'RESULTADO PARCIAL';
  }

  get cpfAvalistas(): Map<number, string> {
    const avalistas = new Map<number, string>();
    if (this.proposta.avalistas) {
      (<any>Object).entries(this.proposta.avalistas).forEach(([key, value]) => {
        const avalista = <Cliente>value;
        avalistas.set(Number(key), avalista.cpf);
      });
    }
    return avalistas;
  }

  get localSos(): number {
    if (this.timeline.idStatus === 3) {
      return 1;
    }

    return 2;
  }

  get isMicrocredito(): boolean {
    if (!this.proposta) return false;
    return this.proposta.grupo2 === 'MICROCREDITO' || this.proposta.grupo2 === 'MICROCRÉDITO-RL';
  }

  get acceptImages(): string {
    return this.isMicrocredito ? appMicrocreditoSettings.ACCEPT_IMAGES : globals.ACCEPT_IMAGES;
  }

  irParaFicha() {
    this.executarAcao('navigate_to_ficha');
  }

  reload() {
    this.executarAcao('reload');
  }

  pushDocumentacao() {
    this.executarAcao('push_documentacao');
  }

  atualizaDiasGeracaoContrato(value: number) {
    this.proposta.geracao = value;
  }

  atualizaPromotor(id: any) {
    const nome = this.vendedores.filter(t => t.id === id)[0].nome;
    this.proposta.promotor = { id, nome };
  }

  executarAcao(acao: string) {
    this.executar.emit({ action: acao.toLowerCase() });
  }

  get exibeDetalhePagamento(): boolean {
    if (this.timeline.idStatus === 11 && this.detalhePagamento && this.detalhePagamento.length > 0) {
      return true;
    }
    return false;
  }

  get exibeFavorecidoPagamento(): boolean {
    if (this.detalhePagamento.length > 1) {
      return true;
    }
    return false;
  }

  get diasGeracaoContrato(): number {
    return Number(this.diasGeracaoContratoForm.get('diasGeracaoValor').value);
  }

  get idPromotor(): number {
    return Number(this.promotorForm.get('promotorId').value);
  }

  get formValid() {
    if (this.timeline.informacaoComplementar.propostaAutomatica) {
      return this.diasGeracaoContratoForm.valid && this.promotorForm.valid;
    }

    return this.diasGeracaoContratoForm.valid;
  }

}
