export interface StatusCapturaFotoUnico{
  dataCriacao: number;
  dataUltimaAtualizacao: number;
  proposta: number;
  status: StatusProcessoUnico;
}

export enum StatusProcessoUnico {
  AGUARDANDO_DOCUMENTOS = 1,
  EM_DIVERGENCIA = 2,
  CONCLUIDO = 3,
  CANCELADO = 4,
  ERRO = 5,
  ENVIANDO_MENSAGEM = 6,
  AGUARDANDO_CAPTURA_MENSAGEM = 7,
  REENVIANDO_MENSAGEM = 8,
}
