import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { map, takeWhile } from 'rxjs/operators';

import { appSettings } from '../../../../environments/app.setings';
import { environment } from '../../../../environments/environment';
import { LoginStorage } from '../../../login/login.storage';
import { ConfirmModalComponent } from '../../../shared/modais/confirm-modal/confirm-modal.component';
import { SelectProdutoService } from '../../../shared/select-produto/select-produto.service';
import { FichaEstapasCadastro } from '../../shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';
import { ParametroRendaProduto } from '../../shared/models/parametro-renda-produto';
import { CdcLojaService } from '../services/cdc-loja.service';
import { EcommerceEtapas } from './ecommerce-etapas';

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss'],
})
export class EcommerceComponent implements OnInit {
  private STATUS_CRIVO_RECUSADO = 'RECUSADO';

  etapaAtual = EcommerceEtapas.INICIAL;
  showLoader = false;
  etapas: FichaEstapasCadastro[];
  operacoes: any;
  parametroRendaProduto: ParametroRendaProduto = {
    valorRendaMinima: 600,
    valorSolicitadoMaximo: 75000,
    valorSolicitadoMinimo: 200,
  };
  tabelas: any;
  produtos: any;
  clienteNaoElegivel: boolean = false;

  constructor(
    private cdcLojaService: CdcLojaService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private loginStorage: LoginStorage,
    private modal: BsModalService,
    private selectProdutoService: SelectProdutoService,
  ) { }

  ngOnInit() {
    this.showLoader = true;
    this.etapas = EcommerceEtapas.etapas();

    this.selectProdutoService
      .getProdutos()
      .pipe(map(this.checkProduto))
      .subscribe((produtos) => {
        this.produtos = produtos.filter(
          (item) => environment.SHOW_CP || item.descricao !== 'CRÉDITO PESSOAL'
        );

        this.actRoute.paramMap.subscribe(async (params) => {
          const cdcLojaRequest = await this.cdcLojaService
            .operacoes(this.produtos[0].descricao)
            .toPromise();
          this.parametroRendaProduto = cdcLojaRequest.parametroRendaProduto;
          this.operacoes = cdcLojaRequest.operacoes;

          this.tabelas = await this.cdcLojaService
            .tabelas(this.operacoes[0].id)
            .toPromise();

          const etapa = params.get('etapa');
          if (etapa) {
            const proposta = Number(params.get('proposta'));
            EcommerceEtapas.proposta = await this.cdcLojaService
              .findById(proposta)
              .toPromise();
            EcommerceEtapas.proposta.tabela = {
              id: this.tabelas[0].id,
              descricao: this.tabelas[0].descricao,
            };
            EcommerceEtapas.proposta.parceiro =
              this.loginStorage.usuario.parcerias[0];
            this.etapaAtual = EcommerceEtapas.etapas()[etapa];
          } else {
            this.etapaAtual = EcommerceEtapas.DADOS_SIMULACAO;
          }
          this.showLoader = false;
        });
      });
  }

  checkProduto(res) {
    return res.map((value) => {
      return {
        descricao: value.descricao,
        checked: true,
      };
    });
  }

  private irParaTimeline() {
    this.etapaAtual = { numero: 0, descricao: 'finalizada' };
    this.router.navigate(['/produtos/cdcloja/timelines'], { queryParams: { id: EcommerceEtapas.proposta.id } });
  }

  get currentPageBreadcrumb() {
    return 'E-commerce';
  }

  get numeroEtapaAtual() {
    return this.etapaAtual.numero;
  }

  get isClienteNaoElegivel() {
    return this.clienteNaoElegivel;
  }

  onClickNext() {
    if (this.etapaAtual.numero === EcommerceEtapas.DADOS_SIMULACAO.numero && EcommerceEtapas.proposta.statusAnaliseCpf === this.STATUS_CRIVO_RECUSADO) {
      this.clienteNaoElegivel = true;
    }
    if (this.etapaAtual.numero === EcommerceEtapas.ANALISE.numero) {
      this.irParaTimeline();
      return;
    }
    this.etapaAtual = EcommerceEtapas.next(this.etapaAtual);
  }

  onClickPrevious() {
    if (this.etapaAtual.numero === 1) {
      this.router.navigate(['ficha']);
      return;
    }
    this.etapaAtual = EcommerceEtapas.previous(this.etapaAtual);
  }

  excluirProposta() {
    const initialState = {
      titulo: 'Confirmação de exclusão',
      mensagem: 'Tem certeza que deseja excluir essa proposta?',
    };

    const modal = this.modal.show(ConfirmModalComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
    const confirm = <ConfirmModalComponent>modal.content;

    confirm.confirmValue
      .pipe(takeWhile(() => confirm.alive))
      .subscribe(async (value) => {
        if (value) {
          await this.cdcLojaService
            .excluirFicha(EcommerceEtapas.proposta.id)
            .toPromise();
          delete EcommerceEtapas.proposta;
          delete EcommerceEtapas.simulador;
          this.router.navigate(['ficha']);
        }
      });
  }

  etapaNaoPossuiControleIndependente() {
    const etapasIndependente = [0, 1, 2, 3, 4, 5, 6];
    return etapasIndependente.indexOf(this.etapaAtual.numero) < 0;
  }
}
