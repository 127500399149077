import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectProdutoModule } from './../../shared/select-produto/select-produto.module';
import { PanelModule } from './../panel/panel.module';
import { SelectPeriodoModule } from './../select-periodo/select-periodo.module';
import { MeuDesempenhoComponent } from './meu-desempenho.component';
import { PainelMeuDesempenhoComponent } from './painel-meu-desempenho/painel-meu-desempenho.component';
import { PainelRelacaoFichasModule } from './painel-relacao-fichas/painel-relacao-fichas.module';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    SelectPeriodoModule,
    PainelRelacaoFichasModule,
    SelectProdutoModule
  ],
  declarations: [MeuDesempenhoComponent, PainelMeuDesempenhoComponent],
  exports: [MeuDesempenhoComponent]
})
export class MeuDesempenhoModule {}
