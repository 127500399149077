export class InfoProposta {
    codFipe?: string;
    dataNascimento?: string;
    grupo2?: string;
    isRouboFurto?: boolean;
    marca?:string;
    modelo?:string;
    nome?: string;
    proposta?: number;
    sexo?: string;
    anoFabric?: number;
}
