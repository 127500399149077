import { Directive, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[licensePlateCheck],[licensePlateCheck][ngModel]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicensePlateCheckDirective)
    }
  ]
})
export class LicensePlateCheckDirective implements Validator, OnChanges {
  @Input() licensePlateCheck: string;

  private onChange: () => void;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onChange) {
      this.onChange();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    const regex = new RegExp('^[a-zA-Z]{3}[0-9]{1}[a-zA-Z0-9]{1}[0-9]{2}$');
    if (this.licensePlateCheck && regex.test(this.licensePlateCheck)) {
      return null;
    }

    return { message: 'Placa Inválida' };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }
}
