import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-btn-group',
  templateUrl: './btn-group.component.html',
  styleUrls: ['./btn-group.component.css'],
})
export class BtnGroupComponent {
  @Input() title: string;
}
