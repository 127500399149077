export class Endereco {
  idTipoEndereco: number;
  uf: string;
  cidade: string;
  endereco: string;
  bairro: string;
  numero: string;
  complemento: string;
  cep: string;

  constructor(idTipoEndereco?: number) {
    this.idTipoEndereco = idTipoEndereco;
  }
}
