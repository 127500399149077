import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-pagamento-realizado',
  templateUrl: './ficha-timeline-pagamento-realizado.component.html',
  styleUrls: ['./ficha-timeline-pagamento-realizado.component.css']
})
export class FichaTimelinePagamentoRealizadoComponent {

  @Input() timeline: any;
  @Input() timelineActive: boolean;
}
