import { Directive, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[dateCheck],[dateCheck][ngModel]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateCheckDirective)
    }
  ]
})
export class DateCheckDirective implements Validator, OnChanges {
  @Input() dateCheck: string;
  @Input() dateMin: string;
  @Input() dateMax: string;
  @Input() dateCheckOneMonth: string;
  @Input() dateCheckOneMonthMin: string;
  @Input() dateFormat = 'dd/MM/yyyy';

  private onChange: () => void;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onChange) {
      this.onChange();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    let dateCheck = null;
    let dateMin = null;
    let dateMax = null;
    let startDate = null;
    let endDate = null;

    if (this.dateCheck) {
      dateCheck = this.getValidDate(this.dateCheck);

      if (dateCheck === null) {
        return { dateCheck: true };
      }
    } else {
      return null;
    }

    if (this.dateMin) {
      dateMin = this.getValidDate(this.dateMin);
    }

    if (this.dateMax) {
      dateMax = this.getValidDate(this.dateMax);
    }

    if (this.dateCheckOneMonth) {
      startDate = this.getValidDate(this.dateCheckOneMonth);
    }

    if (this.dateCheckOneMonthMin) {
      endDate = this.getValidDate(this.dateCheckOneMonthMin);
    }

    if (dateMin) {
      if (dateCheck.isBefore(dateMin)) {
        return { dateRange: true };
      }
    }

    if (dateMax) {
      if (dateCheck.isAfter(dateMax)) {
        return { dateRange: true };
      }
    }

    if (startDate) {
      const dataInicial = moment(startDate, 'DDMMYYY');
      const oneMonthAgo = dataInicial.add(6, 'M');

      if (dateCheck.isAfter(oneMonthAgo)) {
        return { dateRangeMonth: true };
      }
    }

    if (endDate) {
      const dataFinal = moment(endDate, 'DDMMYYY');
      const oneMonthBefore = dataFinal.subtract(6, 'M');

      if (dateCheck.isBefore(oneMonthBefore)) {
        return { dateRangeMonth: true };
      }
    }

    return null;
  }

  getValidDate(d: string) {
    if (d.length !== this.dateFormat.length) {
      return null;
    }

    const date = moment(d, this.dateFormat);

    if (date.isValid()) {
      return date;
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }

}
