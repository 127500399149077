import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, forwardRef, Input, Renderer2, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SwitchComponent),
    multi: true
  }]
})
export class SwitchComponent implements ControlValueAccessor {

  value: boolean = false;
  onChange: (_: any) => void = (_: any) => {
    // This is intentional
  }
  onTouched: () => void = () => {
    // This is intentional
  }

  @Input() id?: string = '';
  @Input() label?: string;

  @ViewChild('input', { static: true }) inputEl: ElementRef;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) { }

  ngAfterViewInit() {
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: boolean): void {
    this.value = value;
    this.updateChanges();
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._renderer.setProperty(this.inputEl.nativeElement, 'disabled', isDisabled);
  }
}
