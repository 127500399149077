import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent {

  @Input() panelTitleIcon: string;
  @Input() panelTitleSmall: string;
  @Input() panelTitle: string;
  @Input() padding = 'padding';
  @Input() loading = false;
  @Input() noData = false;
  @Input() noDataText = 'Dados não encontrados';

  panelTitleIconAlt = `${this.panelTitleSmall} ${this.panelTitle}`;

}
