import { AgrupamentoData } from './agrupamento-data';
import { AgrupamentoLoja } from './agrupamento-loja';
import { Agrupamento } from './agrupamento';

export class AgrupamentoFactory {

  public static porLoja(): Agrupamento {
    return new AgrupamentoLoja();
  }

  public static porData(): Agrupamento {
    return new AgrupamentoData();
  }
}
