import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-select-item',
  templateUrl: './button-select-item.component.html',
  styleUrls: ['./button-select-item.component.scss']
})
export class ButtonSelectItemComponent {

  @Input() value: any;
  @Input() title: string;
  @Input() disabled = false;

  @Output() onSelect = new EventEmitter<any>();
  isSelected: boolean = false;

  select() {
    this.isSelected = true;
    this.onSelect.emit(this.value);
  }

  get className() {
    return `option ${this.isSelected ? 'active' : ''}`;
  }
}
