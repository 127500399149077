import { take, takeWhile, finalize } from 'rxjs/operators';
import { Component, forwardRef, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { CepService } from './../../services/cep/cep.service';
import { EnderecoCep } from './../../services/cep/endereco-cep';
import { EnderecoMultiplo } from './../../services/cep/endereco-multiplo';
import { UfCidadeSelectComponent } from './../uf-cidade-select/uf-cidade-select.component';
import { NewModalMessageComponent } from './../modais/new-modal-message/new-modal-message.component';
import { BsModalService } from 'ngx-bootstrap';
import { appSettings } from '../../../environments/app.setings';
import { ModalMultiplosEnderecosComponent } from './modal-multiplos-enderecos/modal-multiplos-enderecos.component';
import { ModalEncontrarCepComponent } from './modal-encontrar-cep/modal-encontrar-cep.component';

@Component({
  selector: 'app-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.css'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EnderecoComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnderecoComponent),
      multi: true,
    },
  ]
})
export class EnderecoComponent implements Validator, ControlValueAccessor {

  @Input() icon: string;
  @Input() label: string;

  public endereco: any = {};

  @ViewChild('appUfCidade', { static: true }) appUfCidade: UfCidadeSelectComponent;
  @ViewChild('modalErro', { static: false }) modalErro:NewModalMessageComponent;

  public isBuscandoCep = false;
  public hasLogradouro: boolean = false;
  public hasBairro: boolean = false;

  constructor(
    private cepService: CepService,
    private changeDetectorRef: ChangeDetectorRef,
    private modal: BsModalService
  ) { }

  onChange() {
    this.endereco.cep = (this.endereco.cep || '').replace('-', '');
    this.propagateChange(this.endereco);

    this.changeDetectorRef.detectChanges();
  }

  onCepExit() {
    if (this.endereco.cep) {
      this.isBuscandoCep = true;
      this.cepService
        .getEnderecoByCep(this.endereco.cep.replace('-', ''))
        .pipe(
          finalize(() => this.isBuscandoCep = false),
          take(1)
        )
        .subscribe((res) => {
          if (res.enderecos.length === 1) {
            const endereco = new EnderecoCep(res);
            this.handleFindEnderecoCep(endereco);
            return;
          }
          this.handleMultiplosEnderecos(res.enderecos);
        });
    }
  }

  handleMultiplosEnderecos(enderecos: EnderecoMultiplo) {
    this.endereco = {};

    const initialState = { listaEnderecos: enderecos };
    const modal = this.modal.show(ModalMultiplosEnderecosComponent, { ...appSettings.MODAL_PARAMS, initialState });

    const modalMultiplosEnderecos = <ModalMultiplosEnderecosComponent>modal.content;

    modalMultiplosEnderecos.closeModal
      .pipe(
        takeWhile(() => modalMultiplosEnderecos.alive)
      )
      .subscribe((res) => {
        if (res) {
          const endereco = new EnderecoMultiplo(res);
          this.handleFindEnderecoCep(endereco);
        }
      });
  }

  onClickFindCep() {
    const initialState = {};
    const modal = this.modal.show(ModalEncontrarCepComponent, { ...appSettings.MODAL_PARAMS, initialState });

    const modalEncontrarCep = <ModalEncontrarCepComponent>modal.content;

    modalEncontrarCep.closeModal
      .pipe(
        takeWhile(() => modalEncontrarCep.alive)
      )
      .subscribe((res) => {
        if (res) {
          const endereco = new EnderecoCep(res);
          this.handleFindEnderecoCep(endereco);
        }
      });
  }

  validate(): { [key: string]: any; } {
    return this.isValid ? null : { valid: false };
  }

  get isValid(): boolean {
    return this.endereco.endereco &&
      this.endereco.uf &&
      this.endereco.cidade &&
      this.endereco.bairro &&
      this.endereco.numero &&
      this.endereco.numero.length > 0 &&
      this.endereco.cep;
  }

  private handleFindEnderecoCep(res) {
    this.hasLogradouro = res.logradouro !== '';
    this.hasBairro = res.bairro !== '';

    this.endereco = {};

    if (res.logradouro) {
      this.endereco.endereco = res.logradouro;
    }

    if (res.uf) {
      this.endereco.uf = res.uf;
    }

    if (res.cidade) {
      this.endereco.cidade = res.cidade;
    }

    if (res.bairro) {
      this.endereco.bairro = res.bairro;
    }

    if (res.numero) {
      this.endereco.numero = res.numero;
    }

    if (res.cep) {
      this.endereco.cep = res.cep;
    }

    this.refresh();
  }

  refresh() {
    if (this.endereco.uf) {
      this.appUfCidade.refreshCidades(this.endereco.uf);
    }
  }

  public writeValue(obj: any) {
    if (obj) {
      this.endereco = obj;
      this.refresh();
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // This is intentional
  }

  private propagateChange = (_: any) => {
    // This is intentional
  }
}
