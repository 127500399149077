const NACIONALIDADE_BRASILEIRA = 1;

export class Nacionalidade {

  idNacionalidade: number;
  naturalDeUf: string;
  naturalDe: string;

  constructor(idNacionalidade: number = NACIONALIDADE_BRASILEIRA, naturalDeUf?: string, naturalDe?: string) {
    this.idNacionalidade = idNacionalidade;
    this.naturalDeUf = naturalDeUf;
    this.naturalDe = naturalDe;
  }

  get valid(): boolean {
    const isValidBrasileiro: boolean =
    this.idNacionalidade && this.idNacionalidade === NACIONALIDADE_BRASILEIRA &&
    this.naturalDe !== undefined && this.naturalDeUf !== undefined;

    const isValidOutro: boolean = this.idNacionalidade && this.idNacionalidade !== NACIONALIDADE_BRASILEIRA;
    return isValidBrasileiro || isValidOutro;
  }

  get isBrasileira(): boolean {
    return this.idNacionalidade === NACIONALIDADE_BRASILEIRA;
  }
}
