import { Proposta } from './../../../../shared/models/proposta';
import { PropostaStateService } from './../../../../shared/states/proposta-state.service';
import { NacionalidadesService } from './../../../../../services/nacionalidade/nacionalidades.service';
import { FichaService } from './../../../../../ficha-veiculo/ficha-veiculo.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from '../../../../shared/models/cliente';
import * as moment from 'moment';

@Component({
  selector: 'app-dados-complementares',
  templateUrl: './dados-complementares.component.html'
})
export class DadosComplementaresComponent implements OnInit, OnDestroy {

  @Input() avalistaNumero = -1;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  proposta: Proposta;

  isNomePaiClienteTexto: boolean = false;

  listaNaturalCidadeCliente = [];
  isLoadingNaturalCidadeCliente = false;

  listaNacionalidadeCliente = [];
  isLoadingNacionalidadeCliente = false;

  @Input() cliente: Cliente;

  constructor(
    private state: PropostaStateService,
    private fichaService: FichaService,
    private nacionalidadesService: NacionalidadesService
  ) { }

  ngOnInit() {
    this.proposta = this.state.getLast();
    this.cliente = { ...this.clienteEntity };
    if (!this.cliente.nacionalidade) {
      this.cliente.nacionalidade = { id: 1 };
    }

    console.log('Iniciando Dados Complementares Component');
    if (this.cliente.tipoDocumentoDataEmissao) {
      this.cliente.tipoDocumentoDataEmissao = moment.unix(this.cliente.tipoDocumentoDataEmissao / 1000).format('DDMMYYYY');
    }

    this.loadNacionalidadeCliente();
  }

  ngOnDestroy() {
    console.log('Destruindo Dados Complementares Component');
  }

  salvar() {
    const tipoDocumentoDataEmissao = moment(this.cliente.tipoDocumentoDataEmissao, 'DDMMYYYY').unix() * 1000;
    this.clienteEntity = { ...this.cliente, tipoDocumentoDataEmissao };
    this.state.setState(this.proposta);
  }

  onClickNomePaiCliente() {
    this.isNomePaiClienteTexto = !this.isNomePaiClienteTexto;

    if (this.isNomePaiClienteTexto) {
      this.cliente.nomePai = 'NAO DECLARADO';
    } else {
      this.cliente.nomePai = '';
    }
  }

  async loadNacionalidadeCliente() {
    this.listaNacionalidadeCliente = [];
    this.isLoadingNacionalidadeCliente = true;
    try {
      this.listaNacionalidadeCliente = await this.nacionalidadesService.nacionalidades().toPromise();
      if (this.cliente.naturalDeUf) {
        await this.loadNaturalCidadeCliente();
      }
    } finally {
      this.isLoadingNacionalidadeCliente = false;
    }
  }

  onChangeNacionalidade(data) {
    if (data !== '1') {
      this.cliente.naturalDeUf = '';
      this.cliente.naturalDe = '';
    }
  }

  async loadNaturalCidadeCliente() {
    this.listaNaturalCidadeCliente = [];
    this.isLoadingNaturalCidadeCliente = true;

    try {
      const res = await this.fichaService
        .getCidadePorUf(this.cliente.naturalDeUf)
        .toPromise();
      this.listaNaturalCidadeCliente = res.cidades;
    } finally {
      this.isLoadingNaturalCidadeCliente = false;
    }

  }

  onChangeNaturalUfCliente() {
    this.cliente.naturalDe = '';
    this.loadNaturalCidadeCliente();
  }

  onClickNext(): void {
    this.salvar();
    this.next.emit();
  }

  onClickPrevious() {
    this.salvar();
    this.previous.emit();
  }

  get clienteEntity() {
    if (this.avalistaNumero > 0) {
      return this.proposta.avalistas[`${this.avalistaNumero}`];
    }

    return this.proposta.cliente;
  }

  set clienteEntity(cliente) {
    if (this.avalistaNumero > 0) {
      this.proposta.avalistas[`${this.avalistaNumero}`] = cliente;
    } else {
      this.proposta.cliente = cliente;
    }

  }

}
