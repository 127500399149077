import { Component, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { takeWhile, finalize } from 'rxjs/operators';
import { toFileHandle } from '../upload-arquivos/file-handler';
import { DocumentoVO } from '../upload-arquivos-multiavalistas/documentoVO';
import { OmniFacilUploadRestService } from '../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { appSettings } from './../../../environments/app.setings';
import { ModalAlteracoesComponent } from '../modais/modal-alteracoes/modal-alteracoes.component';
import { UploadFile } from './upload-file';
import { DialogService } from '../../services/dialog/dialog.service';
import { normalizeList } from '../../shared/helper/helper';

@Component({
  selector: 'app-upload-arquivos-drop',
  templateUrl: './upload-arquivos-drop.component.html',
  styleUrls: ['./upload-arquivos-drop.component.scss']
})
export class UploadArquivosDropComponent {

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Input() titulo?: string = 'Documentação Necessária - Selecione todos os documentos';
  @Input() labelFechar?: string = 'Voltar';
  @Input() labelOk?: string = 'Anexar Documentos';
  @Input() labelAdicionar?: string = 'Adicionar Documento';
  @Input() cliente: { cpf: any };
  @Input() origem?: number = 1;
  @Input() proposta: string;
  @Input() tiposDocumentos?: DocumentoVO[] = [];
  @Input() acceptFiles: string;
  @Input() uploadOnButtonAnexar?: boolean = true;
  @Input() files: UploadFile[] = [];
  @Output() onUploaded = new EventEmitter<UploadFile[]>();

  alive: boolean = true;
  dropped: boolean = false;
  showLoader: boolean = false;

  constructor(
    private omniRestService: OmniFacilUploadRestService,
    public sanitizer: DomSanitizer,
    private bsModalRef: BsModalRef,
    private modal: BsModalService,
    private dialogService: DialogService) { }

  ngOnDestroy() {
    this.alive = false;
  }

  async onClickAnexarDocumentos() {
    if (this.uploadOnButtonAnexar) {
      for (const file of this.files) {
        await this.postOmniDoc(file.file.file, file.tipo);
      }
    }
    this.onUploaded.next(this.files);
    this.bsModalRef.hide();
  }

  onClickClose() {
    if (this.dropped) {
      this.openModalAlteracoesFeitas();
    } else {
      this.bsModalRef.hide();
    }
  }

  handleFileChange(event: any): FileList {
    const target = event.target;
    let files = null;

    if (event.target.files) {
      files = event.target.files;
    } else if (event.dataTransfer) {
      files = event.dataTransfer.files;
    }

    const extensoesAceitas = this.acceptFiles.split(',');
    const extensaoInvalida = (filename: string) => extensoesAceitas.filter(ext => filename.toLowerCase().endsWith(ext)).length === 0;

    if (normalizeList(files).some((file: File) => extensaoInvalida(file.name))) {
      const extensoes = extensoesAceitas.map(ext => `"${ext}"`).join(', ');
      const message = `Um ou mais dos arquivos selecionados, não está em um formato aceito. Selecione arquivos com as extensões: ${extensoes}.`;
      console.log(message);
      target.value = null;
      this.dialogService.confirm({ body: message, callbackSuccess: () => target.click() });
      return null;
    }

    if (normalizeList(files).some((file: File) => (file.size / 1048576) > 10)) {
      target.value = null;
      this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionadas tem tamanho superior à 10mb.', callbackSuccess: () => target.click() });
      return null;
    }

    return files;
  }

  getThumbImage(fileHandle) {
    if (fileHandle.file.name.toLowerCase().endsWith('.pdf')) {
      return this.sanitizer.bypassSecurityTrustUrl('../assets/images/PDF_file_icon.svg');
    }

    return this.sanitizer.bypassSecurityTrustUrl(fileHandle.url);
  }

  filesDropped(event): void {
    const filesEvent = this.handleFileChange(event);

    if (!filesEvent) {
      return;
    }

    for (let i = 0; i < filesEvent.length; i++) {
      const file = filesEvent[i];
      this.files.push({ file: toFileHandle(file) });
    }

    this.dropped = true;
  }

  removeFile(fileIndex: number) {
    this.files.splice(fileIndex, 1);

    if (this.files.length === 0) {
      this.dropped = false;
    }
  }

  onClickAdicionar(): void {
    (this.fileInput.nativeElement as HTMLElement).click();
  }

  postOmniDoc(file: File, idDocto: number) {
    this.showLoader = true;
    const proposta = { id: Number(this.proposta), cliente: this.cliente, origem: this.origem };
    return this.omniRestService
      .post(file, proposta, idDocto)
      .pipe(
        finalize(() => this.showLoader = false)
      )
      .toPromise();
  }

  onFileSelected(event): void {
    const filesEvent = this.handleFileChange(event);

    if (filesEvent && filesEvent.length) {
      const files = Array.from(filesEvent).map(file => ({ file: toFileHandle(file as File) }));
      this.files.push(...files);
      this.dropped = true;
    }
  }

  onChangeTipo(event) {
    const target = event.target;
    const tamanho = this.files.filter(file => file.tipo === target.value).length;
    if (tamanho > 10) {
      target.value = null;

      return this.dialogService.confirm({ body: 'O máximo são 10 imagens por documento.', callbackSuccess: () => target.click() });
    }
  }

  get validForm() {
    return this.files.every(file => !!file.tipo);
  }

  openModalAlteracoesFeitas() {
    const modal = this.modal.show(ModalAlteracoesComponent, { ...appSettings.MODAL_PARAMS });
    const decisao = <ModalAlteracoesComponent>modal.content;

    decisao.confirmValue
      .pipe(
        takeWhile(() => decisao.alive)
      ).subscribe(decisaoSub => {
        if (decisaoSub) {
          modal.hide();
          this.dropped = false;
          this.bsModalRef.hide();
        }
      });
  }

}
