import { finalize, take } from 'rxjs/operators';
import { Component, DoCheck, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import { ClasseProfissionalService } from '../../services/classe-profissional/classe-profissional.service';
import { ProfissaoService } from './../../services/profissao/profissao.service';
import { ClasseProfissional } from './model/classe-profissional';
import { DadosProfissionais } from './model/dados-profissionais';
import { Empresa } from './model/empresa';
import { Profissao } from './model/profissao';

@Component({
  selector: 'app-dados-profissionais',
  templateUrl: './dados-profissionais.component.html',
  styleUrls: ['./dados-profissionais.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DadosProfissionaisComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DadosProfissionaisComponent),
      multi: true,
    },
  ],
})
export class DadosProfissionaisComponent
  implements OnInit, DoCheck, Validator, ControlValueAccessor
{
  @Input() exibirCampoTelefone = true;

  dadosProfissionais: DadosProfissionais = new DadosProfissionais();
  classes: ClasseProfissional[] = [];
  profissoes: Profissao[] = [];
  isLoadingProfissao = false;

  constructor(
    private classeService: ClasseProfissionalService,
    private profissaoService: ProfissaoService
  ) {}

  ngOnInit() {
    this.classeService
      .classes()
      .pipe(take(1))
      .subscribe((classes) => this.handleClasses(classes));
  }

  ngDoCheck() {
    if (this.dadosProfissionais.isSelectDados) {
      this.setClasseProfissionalIfSelected();
      this.buscarProfissoes();
    }
  }

  validate(): { [key: string]: any } {
    return this.dadosProfissionais.valid ? null : { valid: false };
  }

  onChangeClasse() {
    this.resetValiablesChangeClasse();
    this.onChange();
    this.buscarProfissoes();
  }

  onChange() {
    this.propagateChange(this.dadosProfissionais);
  }

  public writeValue(obj: DadosProfissionais) {
    if (obj) {
      this.dadosProfissionais = obj;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // This is intentional
  }

  private propagateChange = (_: any) => {
    // This is intentional
  }

  private resetValiablesChangeClasse() {
    this.profissoes = [];
    this.dadosProfissionais.profissao = undefined;
    this.dadosProfissionais.empresa = this.dadosProfissionais.isEmpresa
      ? this.dadosProfissionais.empresa
      : new Empresa();
  }

  private buscarProfissoes() {
    const idClasse = this.dadosProfissionais.idClasseProfissional;
    if (idClasse) {
      this.isLoadingProfissao = true;
      this.profissaoService
        .profissoes(idClasse)
        .pipe(
          take(1),
          finalize(() => (this.isLoadingProfissao = false))
        )
        .subscribe((result) => this.handleProfissoes(result.profissoes));
    }
  }

  private handleClasses(classes: ClasseProfissional[]) {
    this.classes = classes;
    this.setClasseProfissionalIfSelected();
    this.buscarProfissoes();
  }

  private handleProfissoes(profissoes: Profissao[]) {
    this.profissoes = profissoes;
    this.setProfissaoIfSelected();
  }

  private setClasseProfissionalIfSelected() {
    if (this.dadosProfissionais.idClasseProfissional) {
      this.dadosProfissionais.dadosSelected();
      this.dadosProfissionais.classeProfissional =
        this.classes.find(
          (cl) => cl.id === this.dadosProfissionais.idClasseProfissional
        ) || undefined;
    }
  }

  private setProfissaoIfSelected() {
    if (this.dadosProfissionais.idProfissao) {
      this.dadosProfissionais.profissao =
        this.profissoes.find(
          (pr) => pr.id === this.dadosProfissionais.idProfissao
        ) || undefined;
    }
  }

  setDadosRecuperados(dadosRecuperados: DadosProfissionais) {
    this.dadosProfissionais = dadosRecuperados;

    if (this.dadosProfissionais.classeProfissional.id) {
      this.dadosProfissionais.dadosSelected();
      this.dadosProfissionais.classeProfissional =
        this.classes.find(
          (cl) => cl.id === this.dadosProfissionais.classeProfissional.id
        ) || undefined;
    }

    this.buscarProfissoes();
  }
}
