import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FichaTimelineSharedService {

  private reloadFichaSubject = new Subject<any>();
  reloadFichaObservable = this.reloadFichaSubject.asObservable();

  reloadFicha() {
    this.reloadFichaSubject.next();
  }
}
