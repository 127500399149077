import { BsModalService } from 'ngx-bootstrap';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { OmniFacilUploadRestService } from '../../../../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { Proposta } from '../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { appSettings as globals, appMicrocreditoSettings } from '../../../../../../environments/app.setings';
import { DocumentoVO, DocumentoEnviadoVO } from './../../../../../shared/upload-arquivos/documentoVO';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { LoginStorage } from './../../../../../login/login.storage';
import { StorageService } from '../../../../../services/storage/storage.service';

const FILE_MAX_SIZE = 10485760;

@Component({
  selector: 'app-identificacao',
  templateUrl: './identificacao.component.html',
  styleUrls: ['./identificacao.component.css']
})
export class IdentificacaoComponent implements OnInit, OnDestroy {
  @Input() numeroEtapaAtual: number;
  @Input() tipoProduto: string;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('modalAvisos', { static: true }) modalAvisos: NewModalMessageComponent;
  @ViewChild('selfieInput', { static: true }) selfieInput: ElementRef;

  showLoader: boolean = false;
  identificacaoForm: FormGroup;

  tituloPainel: string;

  file: File;
  arquivosEnviados: number = 0;
  nomesArquivosEnviados: string[] = [];

  isSizeLimit = false;
  isAlive: boolean = true;

  hasBiometria: boolean = true;

  showWebcam: boolean = true;
  imagemAtualSubject: BehaviorSubject<WebcamImage | File | string> = new BehaviorSubject(null);
  webcamTrigger: Subject<void> = new Subject<void>();
  fotosEnviadas: number = 0;
  isAceitaImagem: boolean = false;
  documentosAdicionais: FileList[] = [];

  hasWebcam: boolean = false;
  textoErroWebcam: string = 'Não há webcams disponíveis.';

  valid: boolean = false;

  proposta: Proposta;
  requiredDocuments: DocumentoVO[] = [];
  documents: DocumentoVO[] = [];
  requiredDocumentsOK = true;
  selfiePreview;
  loginStorage: LoginStorage;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private omniRestService: OmniFacilUploadRestService,
    private changeDetectorRef: ChangeDetectorRef,
    private state: PropostaStateService,
    private dialogService: DialogService,
    private storageService: StorageService) {
    this.loginStorage = new LoginStorage(storageService);
     }

  ngOnInit() {
    this.proposta = this.state.getLast();

    this.initWebcam();
    this.initForm();
    this.initObservers();

    this.tituloPainel = 'Como esse empréstimo vai te ajudar?';

    if (this.isMicrocredito) {
      this.requiredDocumentsOK = false;
      this.requiredDocuments = [
        {
          idDocto: 5,
          descDocto: 'Documento de Identificação'
        }
      ];
      this.documents = [
        {
          idDocto: 6,
          descDocto: 'Comprovante de Residência'
        },
        {
          idDocto: 117,
          descDocto: 'Fachada do negócio'
        }
      ];
    }

    this.omniRestService
      .buscarDocumentosPorProposta(this.proposta.id)
      .then(imagens => this.validarImagens(imagens));
  }

  validarImagens(imagens: DocumentoEnviadoVO[]) {

    const selfie = imagens.find(img => img.idDocto === 119);
    if (selfie) {
      this.handleImageCapture(selfie.url as string);
      this.showWebcam = false;
    }

    this.requiredDocuments.forEach(requiredDoc => {
      const documentosJaUpados = imagens.filter(doc => requiredDoc.idDocto === doc.idDocto);
      if (documentosJaUpados.length) {
        requiredDoc.documentosEnviado = documentosJaUpados;
      }
    });

    this.documents.forEach(documentoOpcional => {
      const documentosJaUpados = imagens.filter(doc => documentoOpcional.idDocto === doc.idDocto);
      if (documentosJaUpados.length) {
        documentoOpcional.documentosEnviado = documentosJaUpados;
      }
    });

    if (this.requiredDocuments.every(doc => !!doc.documentosEnviado && doc.documentosEnviado.length > 0)) {
      this.onPushDocumentos();
    }

    this.changeDetectorRef.detectChanges();
  }

  onPushDocumentos() {
    this.requiredDocumentsOK = true;
    this.handleIdentificacaoFormChanges();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initWebcam(): void {
    this.showLoader = true;
    WebcamUtil.getAvailableVideoInputs()
      .then(
        (mediaDevices: MediaDeviceInfo[]) => {
          this.hasWebcam = mediaDevices && mediaDevices.length > 0;
          this.showLoader = false;

          if (!this.isCP) {
            this.valid = this.requiredDocumentsOK;
          }
        }
      ).catch(
        error => {
          this.handleErroWebcam(error);
          this.showLoader = false;
        }
      );
  }

  private initForm() {
    this.identificacaoForm = this.fb.group({
      documentoOpcional: [''],
      observacoes: [this.proposta.observacoes || '']
    });

    this.documentosAdicionais = this.proposta.transients.documentosAdicionais || [];
    this.nomesArquivosEnviados = this.proposta.transients.nomesArquivosEnviados || [];
    this.arquivosEnviados = this.proposta.transients.arquivosEnviados || 0;

    this.showWebcam = !this.proposta.transients.selfie;
    this.imagemAtualSubject.next(this.proposta.transients.selfie);

    if (this.isCP) {
      this.identificacaoForm.addControl('comoAjuda', this.fb.control(this.proposta.comoAjuda || '', Validators.required));
    }
  }

  get isCP() {
    return this.tipoProduto !== 'MICROCREDITO' && this.tipoProduto !== 'MICROCRÉDITO-RL' ;
  }

  get isMicrocredito() {
    return this.tipoProduto === 'MICROCREDITO' || this.tipoProduto === 'MICROCRÉDITO-RL' ;
  }

  get acceptImages(): string {
    return this.isMicrocredito ? appMicrocreditoSettings.ACCEPT_IMAGES : globals.ACCEPT_IMAGES;
  }

  get acceptFiles(): string {
    return this.isMicrocredito ? appMicrocreditoSettings.ACCEPT_IMAGES_AND_PDF : globals.ACCEPT_IMAGES_AND_PDF;
  }

  get canSkipStep(): boolean {
    return this.isMicrocredito && !!this.loginStorage.agente && Number(this.loginStorage.agente.id) === 1914;
  }

  private initObservers(): void {
    this.identificacaoForm
      .valueChanges.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => this.handleIdentificacaoFormChanges());

    this.imagemAtualSubject.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => this.previewSelfie());
  }

  private handleIdentificacaoFormChanges(): void {
    this.valid = this.imagemAtualSubject.value && this.requiredDocumentsOK && this.identificacaoForm.valid;
    this.proposta.transients.selfie = this.imagemAtualSubject.value;

    const identificacao = this.identificacaoForm.value;
    this.proposta.comoAjuda = identificacao.comoAjuda;
    this.proposta.observacoes = identificacao.observacoes;
    this.proposta.transients.documentosAdicionais = this.documentosAdicionais;
    this.proposta.transients.nomesArquivosEnviados = this.nomesArquivosEnviados;
    this.proposta.transients.arquivosEnviados = this.arquivosEnviados;
  }

  onFileSelected(event): void {
    if (event.target.files && event.target.files[0]) {
      const fileList: FileList = event.target.files;

      if (this.isValidFileList(fileList)) {
        this.documentosAdicionais.push(fileList);
        this.handleUploadSuccessful(fileList);
      }
    }
  }

  isValidFileList(fileList: FileList): boolean {
    if (fileList.length > 10) {
      this.modalAvisos.showModal({
        titulo: 'Aviso',
        mensagem: 'Só é possível enviar 10 arquivos.'
      });
      return false;
    }

    if (fileList.length + this.arquivosEnviados > 10) {
      const arquivosRestantes = 10 - this.arquivosEnviados;
      const arquivoText = arquivosRestantes === 1 ? 'arquivo' :'arquivos';
      this.modalAvisos.showModal({
        titulo: 'Aviso',
        mensagem: `Só é possível enviar 10 arquivos. Você pode enviar mais ${arquivosRestantes} ${arquivoText}.`
      });
      return false;
    }

    if (Array.from(fileList).filter(file => file.size > FILE_MAX_SIZE).length > 0) {
      this.modalAvisos.showModal({
        titulo: 'Aviso',
        mensagem: 'Os arquivos enviados são maiores que o permitido. O tamanho máximo é 10MB.'
      });
      return false;
    }
    return true;
  }

  async uploadDocumentsToOmniDoc(fileList: FileList) {
    this.showLoader = true;

    try {
      const origem = 9;
      await this.omniRestService
      .post(fileList, { ...this.proposta, origem }, 170)
      .toPromise();
    } catch (error) {
      this.modalAvisos.showModal({
        titulo: 'Erro',
        mensagem: 'Erro realizando o upload dos documentos.'
      });
      throw error;
    } finally {
      this.showLoader = false;
    }
  }

  handleUploadSuccessful(fileList: FileList): void {
    this.arquivosEnviados += fileList.length;

    Array.from(fileList).forEach(
      file => this.nomesArquivosEnviados.push(file.name)
    );

    if (this.isFullDocumentos) {
      this.identificacaoForm.get('documentoOpcional').disable();
    }
    this.handleIdentificacaoFormChanges();
  }

  handleImageCapture(imagem: WebcamImage | File | string): void {
    this.imagemAtualSubject.next(imagem);
    this.handleIdentificacaoFormChanges();
  }

  onClickTirarUmaFoto(): void {
    this.showWebcam = !this.showWebcam;
    this.webcamTrigger.next();
  }

  selecionarSelfieManual(event) {
    const acceptImagesMymes = (this.isMicrocredito ? appMicrocreditoSettings : globals).ACCEPT_IMAGES_MYMES;
    if (event && event.target
        && event.target.files && event.target.files[0]
        && acceptImagesMymes.includes(event.target.files[0].type)) {
      this.handleImageCapture(event.target.files[0]);
      this.showWebcam = false;
    } else {
      const message = `A foto não está em um formato aceito. Estes são os formatos aceitos: ${acceptImagesMymes.join(', ')}.`;
      this.dialogService.confirm({ body: message, callbackSuccess: () => event.target.click() });
    }
  }

  onClickTirarOutraFoto(): void {
    if (this.hasWebcam) {
      if (this.isEnabledFotoAndEnviar()) {
        this.showWebcam = !this.showWebcam;
        this.imagemAtualSubject.next(null);
        this.handleIdentificacaoFormChanges();
      }
    } else {
      (this.selfieInput.nativeElement as HTMLElement).click();
    }
  }

  async previewSelfie() {
    const imgFile = this.imagemAtualSubject.value;
    if (!imgFile) {
      this.selfiePreview = null;
    } else if (imgFile instanceof WebcamImage) {
      this.selfiePreview = imgFile.imageAsDataUrl;
    } else if (imgFile instanceof File) {
      this.selfiePreview = await this.readFileAsDataURL(imgFile);
    } else {
      this.selfiePreview = imgFile;
    }
  }

  private async readFileAsDataURL(file) {
    return await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });
  }

  async onClickEnviar() {
    const selfie = this.imagemAtualSubject.value;
    if (selfie) {
      if (selfie instanceof WebcamImage) {
        const imageAsFile: File = this.createFileFromImageUri();
        await this.uploadImagemToOmniDoc(imageAsFile);
      } else if (selfie instanceof File) {
        await this.uploadImagemToOmniDoc(selfie);
      }
    }

    for (const documento of this.documentosAdicionais) {
      await this.uploadDocumentsToOmniDoc(documento);
    }

  }

  async uploadImagemToOmniDoc(file: File) {
    this.showLoader = true;

    try {
      const origem = this.isMicrocredito ? 14 : 9;
      await this.omniRestService
        .post(file, { ...this.proposta, origem }, 119)
        .toPromise();
      this.handleSucessoEnvioImagem();
    } catch (error) {
      this.modalAvisos.showModal({
        titulo: 'Erro',
        mensagem: 'Houve um erro ao enviar a foto.'
      });
      throw error;
    } finally {
      this.showLoader = false;
    }
  }

  private handleSucessoEnvioImagem(): void {
    ++this.fotosEnviadas;
    this.isAceitaImagem = true;

    this.handleIdentificacaoFormChanges();
  }

  isEnabledFotoAndEnviar(): boolean {
    return this.fotosEnviadas < 3;
  }

  createFileFromImageUri(): File {
    const base64image = (<WebcamImage> this.imagemAtualSubject.value).imageAsBase64;
    const fileName = `${this.randomString}${this.randomString}${this.randomString}${this.randomString}`;
    const completeFileName = `${fileName}.jpeg`;
    const blobImage = this.base64ToBlob(base64image);
    return new File([blobImage], completeFileName, { type: 'image/jpeg' });
  }

  base64ToBlob(base64image: string) {
    const byteString = atob(base64image);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    byteString.split('').forEach(
      (chr, i) => {
        int8Array[i] = chr.charCodeAt(0);
      }
    );

    return new Blob([arrayBuffer], { type: 'image/jpeg' });
  }

  handleErroWebcam($event) {
    this.textoErroWebcam = 'Erro iniciando a webcam. Verifique as permissões de vídeo e a conectividade da webcam.';
    console.error($event);
  }

  onSelecionarDocumentosClick(): void {
    if (!this.isFullDocumentos) {
      (this.fileInput.nativeElement as HTMLElement).click();
    }
  }

  async onClickNext() {
    try {
      this.handleIdentificacaoFormChanges();
      await this.onClickEnviar();
      this.state.setState(this.proposta);
      this.next.emit();
    } catch (error) {
      console.log(error);
    }
  }

  private async enviarParaMesaCredito() {
    await this.onClickEnviar();
    this.state.setState(this.proposta);
    this.next.emit();
  }

  onClickPrevious() {
    this.handleIdentificacaoFormChanges();
    this.state.setState(this.proposta);
    this.previous.emit();
  }

  removerDocumento(index: number) {
    this.arquivosEnviados--;
    this.nomesArquivosEnviados.splice(index, 1);
    this.documentosAdicionais.splice(index, 1);
    this.handleIdentificacaoFormChanges();
  }

  get isFinishedEnvios(): boolean {
    return this.fotosEnviadas >= 3;
  }

  get isFullDocumentos(): boolean {
    return this.arquivosEnviados >= 10;
  }

  get randomString(): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substring(2);
  }

  get valorSolicitado(): number {
    return Number(this.identificacaoForm.get('valorSolicitado').value);
  }

  get hasArquivo(): boolean {
    return this.arquivosEnviados > 0 ;
  }

  get possuiCelularPendente() {
    return !this.proposta.cliente.transients.isConfirmadoTelefone
        || this.avalistas.some(avalista => !avalista.transients.isConfirmadoTelefone);
  }

  get avalistas() {
    return Object.getOwnPropertyNames(this.proposta.avalistas)
            .map(key => this.proposta.avalistas[key]);
  }

}
