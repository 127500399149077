import { TaxaClienteSimulacao } from './../model/proposta/taxa-cliente-simulacao';

export class Parcela {
  quantidade: number;
  valor: number;
  permitida = false;
  recusada = false;
  taxaValida = true;
  exibir = false;
  taxa: number;
  fator: number;
  valorTotal: number;

  constructor(taxaCliente?: TaxaClienteSimulacao, parcelaRecusada?: number) {
    if (taxaCliente) {
      this.quantidade = taxaCliente.getParcelas();
      this.valor = taxaCliente.getValorParcela();
      this.permitida = this.valor <= taxaCliente.getParcelaMax();
      this.recusada = this.valor >= taxaCliente.getParcelaRecusa();
      this.fator = taxaCliente.fator;

      if (this.recusada) {
        this.valor = null;
      }
    }

    if (parcelaRecusada) {
      this.quantidade = parcelaRecusada;
      this.permitida = false;
      this.recusada = true;
      this.valor = null;
    }
  }

  getQuantidade(): number {
    return this.quantidade;
  }

  getValor(): number {
    return this.valor;
  }

  isPermitida(): boolean {
    return this.permitida;
  }

  isTaxaValida(): boolean {
    return this.taxaValida;
  }

  setQuantidade(quantidade: number) {
    this.quantidade = quantidade;
  }

  setValor(valor: number) {
    this.valor = valor;
  }

  setExibir(exibir: boolean) {
    this.exibir = exibir;
  }

  isExibir(): boolean {
    return this.exibir;
  }

  getTaxa(): number {
    return this.taxa;
  }

  setTaxa(taxa: number) {
    this.taxa = taxa;
  }

  getFator(): number {
    return this.fator;
  }

  setFator(fator: number) {
    this.fator = fator;
  }

  setValorTotal(valorTotal: number) {
    this.valorTotal = valorTotal;
  }

  getValorTotal(): number {
    return this.valorTotal;
  }
}
