import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PromotorService } from '../promotor/promotor.service';

@Injectable()
export class CessaoGuard implements CanActivate {
  constructor(
    private promotorService: PromotorService,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> | boolean {
    if (!this.promotorService.isOperadorLiberaCessao) {
      this.goToFichas();
      return false;
    }
    return true;
  }

  private goToFichas() {
    this.router.navigate(['/ficha']);
  }
}
