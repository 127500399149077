import { Component, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-proposta-enviada',
  templateUrl: './proposta-enviada.component.html',
  styleUrls: ['./proposta-enviada.component.scss']
})
export class PropostaEnviadaComponent {
  @Input() propostaId: number;

  continue: EventEmitter<boolean> = new EventEmitter();
  alive = true;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  onAction(event: boolean) {
    this.continue.emit(event);
    this.alive = false;
    this.bsModalRef.hide();
  }
}
