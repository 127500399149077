import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appCpfValidator],[appCpfValidator][ngModel]',
  providers: [{
    multi: true,
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CpfValidatorDirective)
  }]
})
export class CpfValidatorDirective {
  validate(c: AbstractControl): { [key: string]: any } {
    return (this.validateCpf(c.value)) ? null : {
      message: 'CPF Inválido',
    };
  }

  validateCpf(cpf) {
    if (cpf) {
      const data = cpf.replace(/[\.\-]/g, '');
      let soma = 0;
      let resto;

      if (data.match(/\b(\d)\1{10}\b/)) {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        soma = soma + parseInt(data.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }

      if (resto !== parseInt(data.substring(9, 10))) {
        return false;
      }

      soma = 0;
      for (let i = 1; i <= 10; i++) {
        soma = soma + parseInt(data.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

      }

      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }

      if (resto !== parseInt(data.substring(10, 11))) {
        return false;
      }
      return true;
    }
  }
}
