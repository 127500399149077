export class LojistaSimulacao {

  private id: number;

  constructor(obj) {
    this.id = obj.id;
  }

  getId(): number {
    return this.id;
  }
}
