import { TelefonePartner } from './../../../../shared/partner/telefone-partner';
export class TelefoneNumero {
  ddd: string;
  numero: string;

  set $dddNumero(value: string) {
    this.ddd = TelefonePartner.getDdd(value);
    this.numero = TelefonePartner.getNumero(value);
  }

  get $dddNumero() {
    return TelefonePartner.getDddNumero(this.ddd, this.numero);
  }
}
