import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-central-loader',
  templateUrl: './central-loader.component.html',
  styleUrls: ['./central-loader.component.css']
})
export class CentralLoaderComponent {
  @Input() text: string;
  @Input() show = true;
}
