import { Endereco } from '../../../ficha-veiculo/ficha-cadastro/model/endereco-cliente';

export class Empresa {
  nome: string;
  telefone?: string;
  endereco = new Endereco();

  get valid(): boolean {
    if (this.telefone) {
      return this.nome && this.telefone.length >= 10;
    }
    return !!this.nome;
  }
}
