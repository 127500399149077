import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-custom',
  templateUrl: './panel-custom.component.html',
  styleUrls: ['./panel-custom.component.scss']
})
export class PanelCustomComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() color: string;
}
