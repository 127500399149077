import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderCirclesComponent } from './loader-circles/loader-circles.component';
import { LoaderDefaultComponent } from './loader-default/loader-default.component';
import { LoaderPhasesComponent } from './loader-phases/loader-phases.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [LoaderDefaultComponent, LoaderPhasesComponent, LoaderCirclesComponent],
  exports: [LoaderDefaultComponent, LoaderPhasesComponent, LoaderCirclesComponent]
})
export class LoadersModule { }
