import { Directive, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgControl } from '@angular/forms';

@Directive({
  selector: '[appShowErrorMessages]'
})
export class ShowErrorMessagesDirective implements AfterViewInit {

  @Input() dateMessage?: string;
  @Input() dateRangeMessage?: string;
  @Input() dateRangeMonthMessage?: string;
  @Input() requiredMessage = 'Campo obrigatório';
  @Input() invalidMessage = 'Valor inválido';
  @Input() customErrorMessage = 'Valor inválido';

  private _idElementMessage: string;

  constructor(private _el: ElementRef, private _fg: ControlContainer, private formControl: NgControl) {
  }

  ngAfterViewInit() {
    this.createIdElementMessage();
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    this.removeComponentMessage();
  }

  @HostListener('blur', ['$event'])
  showErrorMessages() {
    this.removeComponentMessage();
    if (this.control.errors) {
      this.showMessage(this.getErrorMessage(this.control.errors));
    }
  }

  private createIdElementMessage() {
    this._idElementMessage = `${this.elementName}-error-message`;
  }

  private showMessage(message: string) {
    this._el.nativeElement.insertAdjacentHTML('afterend', `<div id="${this._idElementMessage}" class="text-danger">${message}</div>`);
  }

  private removeComponentMessage() {
    const el = document.getElementById(this._idElementMessage);
    if (el) {
      el.remove();
    }
  }

  private getErrorMessage(errors: any): string {
    if (errors.required) { return this.requiredMessage; }
    if (errors.email) { return 'O email informado é inválido'; }
    if (errors.message) { return errors.message; }
    if (errors.minlength) { return `${this.invalidMessage}, quantidade miníma de caracteres: ${errors.minlength.requiredLength}`; }
    if (errors.email) { return 'E-mail inválido'; }
    if (errors.max) { return `Valor máximo permitido é ${errors.max.max}`; }
    if (errors.dateCheck && this.dateMessage) { return this.dateMessage; }
    if (errors.dateRange && this.dateRangeMessage) { return this.dateRangeMessage; }
    if (errors.dateRangeMonth && this.dateRangeMonthMessage) { return this.dateRangeMonthMessage; }
    if (errors[`${this.elementName}Error`]) { return this.customErrorMessage; }
    return JSON.stringify(errors);
  }

  get form() { return this._fg.formDirective ? (this._fg.formDirective as FormGroupDirective).form : null; }

  get control(): any {
    return this.form.controls[this.elementName] || {};
  }

  get elementName(): string {
    if (this._el.nativeElement.name) {
      return this._el.nativeElement.name;
    }

    if (this.formControl.name) {
      return this.formControl.name;
    }

    throw new Error('Para utilizar a diretiva appShowErrorMessages a tag deve possuir o atributo "name".');
  }

}
