import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, finalize, map, take, takeWhile } from 'rxjs/operators';
import { WebcamUtil } from 'ngx-webcam';

import { LoginStorage } from '../../../../../login/login.storage';
import { ClasseProfissionalService } from '../../../../../services/classe-profissional/classe-profissional.service';
import { EstadoCivil, EstadoCivilService } from '../../../../../services/estado-civil/estado-civil.service';
import { Nacionalidade, NacionalidadesService } from '../../../../../services/nacionalidade/nacionalidades.service';
import { ClasseProfissional } from '../../../../../shared/dados-profissionais/model/classe-profissional';
import { Profissao } from '../../../../../shared/dados-profissionais/model/profissao';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TimerTokenComponent } from '../../../../../shared/timer-token/timer-token.component';
import { Cliente } from '../../../../shared/models/cliente';
import { Proposta } from '../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { CdcLojaService } from '../../../services/cdc-loja.service';
import { appSettings } from './../../../../../../environments/app.setings';
import { ProfissaoService } from './../../../../../services/profissao/profissao.service';
import { LinkNotSentModalComponent } from './link-not-sent-modal/link-not-sent-modal.component';

@Component({
  selector: 'app-detalhes-cliente',
  templateUrl: './detalhes-cliente.component.html',
  styleUrls: ['./detalhes-cliente.component.scss']
})
export class DetalhesClienteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modalMensagem', { static: true }) modalMensagem: NewModalMessageComponent;
  @ViewChild('smsModal', { static: true }) smsModal: NewModalMessageComponent;
  @ViewChild(TimerTokenComponent, { static: true })
  timerToken: TimerTokenComponent;

  @Input() biometriaAtiva = false;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  detalhesClienteForm: FormGroup;
  conjugeForm: FormGroup;
  showFormularioConjuge = false;
  estadoCivilClienteSubscriptions: Subscription[] = [];
  profissaoClienteSubscriptions: Subscription[] = [];
  filiacaoClienteSubscriptions: Subscription[] = [];
  nomeClienteSubscriptions: Subscription[] = [];
  showMensagemNomeCliente: boolean = false;

  showLoader: boolean = false;
  isAlive: boolean = true;
  isCpfCnpjReadOnly: boolean = false;
  tokenValidado: boolean = false;
  tokenInvalido: boolean = false;

  isConfirmadoTelefone: boolean = false;

  dataRange: Object = {
    min: 18,
    max: 85,
    message: 'Data inválida'
  };

  estadosCivis: EstadoCivil[] = [];
  classesProfissionais: ClasseProfissional[] = [];
  profissoes: Profissao[] = [];
  profissoesConjuge: any[];
  nacionalidades: Nacionalidade[] = [];

  proposta: Proposta;
  linkAutenticacaoEnviado = false;

  fluxoFotoObrigatoria: boolean = false;
  hasWebcam: boolean = false;
  showValidacaoCelular = false;
  showValidacaoBiometria = false;

  constructor(
    private fb: FormBuilder,
    private estadoCivilService: EstadoCivilService,
    private classeProfissionalService: ClasseProfissionalService,
    private profissaoService: ProfissaoService,
    private nacionalidadeService: NacionalidadesService,
    private state: PropostaStateService,
    private cdcLojaService: CdcLojaService,
    private modal: BsModalService,
    private loginStorage: LoginStorage,
  ) { }

  ngOnInit() {
    this.proposta = this.state.getLast();
    this.initForms();
    this.initDefaultObservers();
    this.fillForms();
    this.initObservers();
    this.checkBiometriaAtiva();
    this.validaCenariosBiometria();
    this.biometriaAtiva = this.proposta.lojista.liberadoBiometria;
  }

  ngAfterViewInit() {
    setTimeout(
      () => {
        if (this.isConfirmadoTelefone) {
          this.timerToken.onTokenValidadoComSucesso('XXXXXX');
        }
      },
      100
    );
  }

  ngOnDestroy() {
    this.isAlive = false;

    if (this.estadoCivilClienteSubscriptions) {
      this.estadoCivilClienteSubscriptions.forEach(sub => sub.unsubscribe());
    }

    if (this.profissaoClienteSubscriptions) {
      this.profissaoClienteSubscriptions.forEach(sub => sub.unsubscribe());
    }

    if (this.filiacaoClienteSubscriptions) {
      this.filiacaoClienteSubscriptions.forEach(sub => sub.unsubscribe());
    }

    if (this.nomeClienteSubscriptions) {
      this.nomeClienteSubscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  private initForms(): void {
    let telefoneCelularCliente = '';
    const celularCliente = (this.proposta.cliente.telefones || []).filter(t => t.categoriaTelefone === 'CELULAR' && t.tipoTelefone === 'PROPRIO')[0];
    if (celularCliente) {
      telefoneCelularCliente = this.formatFone(celularCliente.ddd, celularCliente.nrTelefone);
    }

    const profissao = this.proposta.cliente.dadosProfissionais.profissao;
    const nomeCliente = (this.removeDigits(this.proposta.cliente.nomeCliente) || '').trim();

    this.detalhesClienteForm = this.fb.group({
      nomeCliente: [nomeCliente, [Validators.required, Validators.maxLength(60), Validators.minLength(3), this.validaNomeCliente]],
      emailCliente: [
        this.proposta.cliente.email || '',
        [Validators.maxLength(60)]
      ],
      generoCliente: [this.proposta.cliente.sexo || '', Validators.required],
      estadoCivilCliente: [(this.proposta.cliente.estadoCivil ? this.proposta.cliente.estadoCivil.id : ''), Validators.required],
      profissaoCliente: [
        profissao ? profissao.id : '',
        Validators.required
      ],
      empresaCliente: [
        this.proposta.cliente.dadosProfissionais.nomeEmpresa || '',
        this.isExibeEmpresa ? [Validators.required, Validators.maxLength(100)] : []
      ],
      telefoneCelularCliente: [
        telefoneCelularCliente, [Validators.required]
      ],
      isPoliticamenteExposto: [`${!!this.proposta.ppe}`, Validators.required],
      fatca: [this.proposta.cliente.fatca || 'N', Validators.required],
      nacionalidadeCliente: [`${(this.proposta.cliente.nacionalidade || {}).id || '1'}`, Validators.required],
      filiacao: this.fb.group({
        nomeMae: [this.preencherCampoFiliacao('nomeMae'), [Validators.required, Validators.minLength(5)]],
        nomePai: [this.preencherCampoFiliacao('nomePai')],
        semFiliacao: [false],
      })
    });

    if (this.hasConjuge()) {
      this.preencherFormularioConjuge();
    }
  }

  private preencherCampoFiliacao(campo: string): string {
    if (this.proposta.cliente[campo] && this.proposta.cliente[campo] !== 'NAO DECLARADO') {
      return this.proposta.cliente[campo];
    }
    return null;
  }

  private initDefaultObservers(): void {
    this.fillEstadosCivis();
    this.fillNacionalidades();
    this.fillClassesProfissionais();
    this.fillProfissoes(this.proposta.cliente.dadosProfissionais.classeProfissional.id);
  }

  private fillEstadosCivis(): void {
    this.estadoCivilService
      .findEstadoCivil()
      .pipe(
        take(1)
      )
      .subscribe((res) => (this.estadosCivis = res));
  }

  private fillClassesProfissionais(): void {
    this.classeProfissionalService
      .classes().pipe(
        take(1))
      .subscribe((res) => (this.classesProfissionais = res));
  }

  private fillProfissoes(res: number): void {
    this.profissaoService
      .profissoes(res).pipe(
        takeWhile(() => this.isAlive))
      .subscribe(({ profissoes }) => (this.profissoes = profissoes));
  }

  private fillProfissoesConjuge(res: number, clear = true): void {
    if (clear) {
      if (this.isExibeEmpresaConjuge) {
        this.conjugeForm.get('empresaConjuge').setValue('');
      } else {
        this.conjugeForm.get('empresaConjuge').setValue('nao apresentar');
      }
    }

    this.profissaoService
      .profissoes(res).pipe(
        takeWhile(() => this.isAlive))
      .subscribe(({ profissoes }) => (this.profissoesConjuge = profissoes));
  }

  private fillNacionalidades(): void {
    this.nacionalidadeService
      .nacionalidades().pipe(
        take(1))
      .subscribe((res) => (this.nacionalidades = res));
  }

  private validaNomeCliente(control: FormControl) {
    return (control.value || '').trim().length === 0 ? {'nomeInvalido': true} : null;
  }

  get formValid() {
    this.validaNomeCliente;
    return this.detalhesClienteForm.valid;
  }

  private fillProposta(): void {
    this.buildCliente();
    this.buildConjuge();
    this.proposta.ppe = this.detalhesClienteForm.value.isPoliticamenteExposto === 'true';
    this.proposta.transients.isConfirmadoTelefone = this.isConfirmadoTelefone;

    this.proposta.rastreioStatus = {
      codigo: 32,
      descricao: 'AGUARDANDO BIOMETRIA',
    };
  }

  private buildConjuge(): void {
    const profissaoCliente = this.proposta.cliente.dadosProfissionais.profissao.id;
    if ((profissaoCliente !== 931 && profissaoCliente !== 932)) {
      if (this.proposta.conjuge) {
        this.proposta.conjuge = {};
      }
      return;
    }

    if (this.conjugeForm) {
      this.proposta.conjuge = {
        ...this.proposta.conjuge,
        dadosProfissionais: {
          classeProfissional: {
            id: Number(
              this.conjugeForm.get('classeProfissionalConjuge').value
            )
          },
          profissao: {
            id: Number(this.profissaoConjuge.id),
            idGrupoProfissao: Number(this.idGrupoProfissaoConjuge)
          },
          nomeEmpresa: this.isExibeEmpresaConjuge ? this.conjugeForm.get('empresaConjuge').value : this.descricaoClasseProfissionaisConjuge
        }
      };
    }
  }

  private buildCliente(): void {
    if (!this.proposta.cliente) {
      this.proposta.cliente = {};
    }

    const clienteRawValues = this.detalhesClienteForm.getRawValue();
    this.buildClienteTelefone();

    this.proposta.cliente.dadosProfissionais = {
      ...this.proposta.cliente.dadosProfissionais,
      profissao: {
        id: Number(this.profissaoCliente.id),
        idGrupoProfissao: Number(this.idGrupoProfissao)
      },
      nomeEmpresa: this.isExibeEmpresa ? clienteRawValues.empresaCliente : this.descricaoClasseProfissionaisCliente
    };

    this.proposta.cliente.email = clienteRawValues.emailCliente;
    this.proposta.cliente.estadoCivil = {
      id: Number(clienteRawValues.estadoCivilCliente)
    };
    this.proposta.cliente.nacionalidade = {
      id: Number(clienteRawValues.nacionalidadeCliente)
    };
    this.proposta.cliente.nomeCliente = clienteRawValues.nomeCliente;
    this.proposta.cliente.sexo = clienteRawValues.generoCliente;
    this.proposta.cliente.fatca = this.detalhesClienteForm.get('fatca').value;

    this.proposta.cliente.rendas.forEach(renda => {
      const cliente = this.proposta.cliente;
      const telefone = cliente.telefones.filter(t => t.categoriaTelefone === 'COMERCIAL')[0]
        || cliente.telefones[0];

      renda.empresa = cliente.dadosProfissionais.nomeEmpresa;
      renda.empresaDdd = telefone.ddd;
      renda.empresaFone = telefone.nrTelefone;
      renda.empresaRamal = '00';
    });

    const filiacao = this.filiacao.getRawValue();
    if (filiacao.semFiliacao) {
      this.proposta.cliente.nomePai = 'NAO DECLARADO';
      this.proposta.cliente.nomeMae = 'NAO DECLARADO';
    } else {
      this.proposta.cliente.nomeMae = filiacao.nomeMae;
      this.proposta.cliente.nomePai = filiacao.nomePai || '';
    }
  }

  get filiacao(): FormGroup {
    return this.detalhesClienteForm.get('filiacao') as FormGroup;
  }

  get profissaoConjuge(): Profissao {
    return (this.profissoesConjuge.find(
      (pro) => pro.id === Number(this.conjugeForm.get('profissaoConjuge').value)
    ));
  }

  get profissaoCliente(): Profissao {
    return (this.profissoes.find(
      (pro) => (pro.id === Number(this.detalhesClienteForm.get('profissaoCliente').value))
    ));
  }

  get idGrupoProfissao(): number {
    return this.profissaoCliente.idGrupoProfissao;
  }

  get idGrupoProfissaoConjuge(): number {
    return this.profissaoConjuge.idGrupoProfissao;
  }

  private buildClienteTelefone(): void {
    if (!this.proposta.cliente.telefones) {
      this.proposta.cliente.telefones = [];
    }

    const celularEncontradoIdx = this.proposta.cliente.telefones.findIndex((telefone) => telefone.categoriaTelefone === 'CELULAR');
    if (celularEncontradoIdx >= 0) {
      this.proposta.cliente.telefones[celularEncontradoIdx] = {
        categoriaTelefone: 'CELULAR',
        ddd: this.detalhesClienteForm.get('telefoneCelularCliente').value.replace(/\D/g, '').substring(0, 2),
        nrTelefone: this.detalhesClienteForm.get('telefoneCelularCliente').value.replace(/\D/g, '').substring(2),
        tipoTelefone: 'PROPRIO'
      };
      return;
    }
    this.addCelularCliente();
  }

  private addCelularCliente() {
    this.proposta.cliente.telefones.push({
      categoriaTelefone: 'CELULAR',
      ddd: this.detalhesClienteForm.get('telefoneCelularCliente').value.replace(/\D/g, '').substring(0, 2),
      nrTelefone: this.detalhesClienteForm.get('telefoneCelularCliente').value.replace(/\D/g, '').substring(2),
      tipoTelefone: 'PROPRIO'
    });
  }

  private fillForms(): void {
    this.isConfirmadoTelefone = !!this.proposta.transients.isConfirmadoTelefone;
    if (this.proposta.cliente) {
      const nomeCliente = (this.removeDigits(this.proposta.cliente.nomeCliente) || '').trim();
      this.detalhesClienteForm.patchValue({
        nomeCliente
      });
    }
    if (this.hasConjuge() && this.proposta.conjuge.dadosProfissionais && this.proposta.conjuge.dadosProfissionais.classeProfissional) {
      const classeProfissionalConjuge = this.proposta.conjuge.dadosProfissionais.classeProfissional.id;
      this.fillProfissoesConjuge(classeProfissionalConjuge, false);
    }
  }

  hasConjuge(): boolean {
    return !!this.proposta.conjuge && !!this.proposta.conjuge.cpf;
  }

  handleSmsConfirmacao($event: boolean): void {
    // remover
    this.isConfirmadoTelefone = $event;
  }

  prosseguir() {
    if (this.biometriaAtiva && !this.linkAutenticacaoEnviado) {
      return this.openModalConfirmarProsseguir();
    }
    return true;
  }

  openModalConfirmarProsseguir() {
    const modal = this.modal.show(LinkNotSentModalComponent, { ...appSettings.MODAL_PARAMS });
    const confirm = <LinkNotSentModalComponent>modal.content;

    confirm.confirmValue
      .pipe(
        takeWhile(() => confirm.alive)
      )
      .subscribe(() => this.scrollTop());
  }

  onClickNext() {
    if (this.prosseguir()) {
      this.goNext();
    }
  }

  goNext() {
    this.fillProposta();
    this.state.setState(this.proposta);
    this.next.emit();
  }

  onClickPrevious() {
    this.fillProposta();
    this.state.setState(this.proposta);
    this.previous.emit();
  }

  get isExibeEmpresa() {
    return ![7, 9].includes(this.proposta.cliente.dadosProfissionais.classeProfissional.id);
  }

  get isExibeEmpresaConjuge() {
    const classeProfissional = Number(this.conjugeForm.get('classeProfissionalConjuge').value);
    return classeProfissional !== 7;
  }

  get classesProfissionaisConjuge() {
    return this.classesProfissionais.filter(e => e.id !== 9);
  }

  get descricaoClasseProfissionaisCliente() {
    const classe = this.classesProfissionais.filter(e => e.id === this.proposta.cliente.dadosProfissionais.classeProfissional.id)[0] || { descricao: 'nao informado' };
    return classe.descricao;
  }

  get descricaoClasseProfissionaisConjuge() {
    const classeProfissionalId = Number(this.conjugeForm.get('classeProfissionalConjuge').value);
    const classe = this.classesProfissionais.filter(e => e.id === classeProfissionalId)[0] || { descricao: 'nao informado' };
    return classe.descricao;
  }

  private formatFone(ddd: string, fone: string): string {
    if (!fone || fone.length < 8) {
      return '';
    }

    const telefone = fone;
    const telefoneSize = telefone.length;
    const telefonePart1 = telefone.slice(0, telefoneSize - 4);
    const telefonePart2 = telefone.slice(telefoneSize - 4, telefoneSize);
    return `(${ddd}) ${telefonePart1}-${telefonePart2}`;
  }

  private handleError(error: string) {
    return this.modalMensagem.showModal({
      titulo: 'Atenção!',
      mensagem: error
    });
  }

  private adicionarConjugeForm() {
    this.detalhesClienteForm.addControl('conjuge', this.conjugeForm);
    this.onClasseProfissionalConjugeChange();
  }

  private removerConjugeForm() {
    this.detalhesClienteForm.removeControl('conjuge');
  }

  private preencherFormularioConjuge() {
    this.showFormularioConjuge = true;

    const conjuge: Cliente = this.proposta.conjuge || { dadosProfissionais: {}, telefones: [{}] };

    let classeProfissionalConjuge;
    let profissaoConjuge;
    let empresaConjuge = '';
    if (conjuge.dadosProfissionais) {
      classeProfissionalConjuge = (conjuge.dadosProfissionais.classeProfissional || {}).id;
      profissaoConjuge = (conjuge.dadosProfissionais.profissao || {}).id;
      empresaConjuge = conjuge.dadosProfissionais.nomeEmpresa;
    }

    let dtNascimento = '';
    if (this.proposta.conjuge && this.proposta.conjuge.dtNascimento) {
      dtNascimento = moment(new Date(this.proposta.conjuge.dtNascimento)).format('DD/MM/YYYY');
    }

    this.conjugeForm = this.fb.group({
      cpf: [conjuge.cpf],
      dataNascimento: [dtNascimento],
      classeProfissionalConjuge: [classeProfissionalConjuge, Validators.required],
      profissaoConjuge: [profissaoConjuge, Validators.required],
      empresaConjuge: [empresaConjuge || '']
    });

    this.adicionarConjugeForm();
  }

  private initObservers() {
    this.estadoCivilClienteListener();
    this.profissaoClienteListener();
    this.filiacaoFormListener();
  }

  private estadoCivilClienteListener() {
    this.estadoCivilClienteSubscriptions.push(this.detalhesClienteForm.controls['estadoCivilCliente'].valueChanges.subscribe(((_) => {
      const profissaoCliente = Number(this.detalhesClienteForm.controls['profissaoCliente'].value) || 0;
      this.verificarClasseProfissional(profissaoCliente);
    })));
  }

  private profissaoClienteListener() {
    this.profissaoClienteSubscriptions.push(this.detalhesClienteForm.controls['profissaoCliente'].valueChanges.subscribe(((value) => {
      const profissaoCliente = Number(value) || 0;
      this.verificarClasseProfissional(profissaoCliente);
    })));

    this.nomeClienteSubscriptions.push(this.nomeCliente.valueChanges.pipe(
      debounceTime(200),
      map(str => this.removeDigits(str))
    ).subscribe(((value) => {
      this.nomeCliente.setValue(value, { emitEvent: false });
    })));
  }
  private filiacaoFormListener() {
    this.filiacaoClienteSubscriptions.push(this.filiacao.get('semFiliacao').valueChanges.subscribe(((value) => {
      if (value) {
        this.filiacao.get('nomeMae').clearValidators();
        this.filiacao.get('nomeMae').setValue(null);
        this.filiacao.get('nomePai').setValue(null);
      } else {
        this.filiacao.get('nomeMae').setValidators([Validators.required, Validators.minLength(5)]);
        const nomeMae = this.filiacao.get('nomeMae').value;
        this.filiacao.get('nomeMae').setValue(nomeMae);
      }
      this.filiacao.updateValueAndValidity();
    })));
  }

  private verificarClasseProfissional(profissaoCliente: number) {
    if (!!(profissaoCliente === 931 || profissaoCliente === 932)) {
      if (!this.detalhesClienteForm.controls['conjuge']) {
        this.preencherFormularioConjuge();
      }
      return;
    }
    this.showFormularioConjuge = false;
    this.removerConjugeForm();
  }

  private onClasseProfissionalConjugeChange() {
    this.conjugeForm.get('classeProfissionalConjuge')
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((res) => this.fillProfissoesConjuge(res));
  }

  enviarLinkParaAutenticacao() {
    if (this.detalhesClienteForm.controls['nomeCliente'].invalid) {
      this.handleError('Preencha o campo Nome para enviar o token de segurança!');
      return;
    }

    if (this.detalhesClienteForm.controls['telefoneCelularCliente'].invalid) {
      this.handleError('Telefone inválido!');
      return;
    }

    this.showLoader = true;
    const telefoneCliente = this.telefoneCelularCliente;
    const idAgente = this.idAgente;

    const body = {
      proposta: {
        cliente: {
          nomeCliente: this.nomeCliente.value,
          cpf: this.proposta.cliente.cpf,
          telefones: [{
            ddd: telefoneCliente.ddd,
            nrTelefone: telefoneCliente.nrTelefone
          }],
        },
        id: this.proposta.id,
        agente: { id: idAgente },
        rastreioStatus: { ...this.rastreioStatus },
        operacao: {
          produto: this.tipoProduto
        },
      }
    };

    this.cdcLojaService.enviarSmsVerificacaoSeguranca(body).pipe(
      finalize(() => (this.showLoader = false)),
    ).subscribe(() => {
      this.linkAutenticacaoEnviado = true;
    });
  }
  private removeDigits(value: string): string {
    if (!value) {
      return '';
    }

    if (!!value.match(/\d/g)) {
      this.showMensagemNomeCliente = true;
      return value.replace(/\d/g, '');
    }
    this.showMensagemNomeCliente = false;
    return value;
  }

  solicitarToken() {
    // TOKEN
    return () => {

      if (this.detalhesClienteForm.value.telefoneCelularCliente.invalid) {
        this.handleError('Telefone inválido!');
        return;
      }

      return this.cdcLojaService.enviarSms({
        proposta: {
          cliente: {
            telefones: [{
              ddd: this.detalhesClienteForm.value.telefoneCelularCliente.replace(/[^0-9]/g, '').substring(0, 2),
              nrTelefone: this.detalhesClienteForm.value.telefoneCelularCliente.replace(/[^0-9]/g, '').substring(2)
            }]
          },
          id: this.proposta.id
        }
      }).toPromise();
    };
  }

  validarToken() {
    // TOKEN
    return (token) => {
      const codigoConfirmacao = token;
      const idProposta = !this.proposta.id || this.proposta.id === -1 ? '11999065' : `${this.proposta.id}`;
      return this.cdcLojaService
        .verificarToken(idProposta, codigoConfirmacao, this.detalhesClienteForm.value.telefoneCelularCliente.replace(/[^0-9]/g, ''))
        .toPromise();
    };
  }

  handleConfirmacaoSuccess() {
    // TOKEN
    this.detalhesClienteForm.controls['telefoneCelularCliente'].disable();
    this.isConfirmadoTelefone = true;
  }

  get nomeCliente(): FormControl {
    return this.detalhesClienteForm.controls['nomeCliente'] as FormControl;
  }

  get nomeClienteValido(): boolean {
    if (!!this.nomeCliente.value.match(/\d/g)) {
      this.showMensagemNomeCliente = true;
      return false;
    }
    return true;
  }

  get idAgente(): number {
    if (this.proposta.agente) {
      return this.proposta.agente.id;
    }
    return this.loginStorage.agente.id;
  }

  get rastreioStatus(): { codigo: number, descricao: string } {
    if (this.proposta.rastreioStatus) {
      return this.proposta.rastreioStatus;
    }
    return { codigo: 18, descricao: 'EM PREENCHIMENTO' };
  }

  get tipoProduto(): string {
    if (this.proposta.operacao.produto) {
      return this.proposta.operacao.produto;
    }
    return this.proposta.operacao.tipoProduto;
  }

  get telefoneCelularCliente() {
    return {
      ddd: this.detalhesClienteForm.value.telefoneCelularCliente.replace(/\D/g, '').substring(0, 2),
      nrTelefone: this.detalhesClienteForm.value.telefoneCelularCliente.replace(/\D/g, '').substring(2)
    };
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  get isLojistaPiloto(): boolean {
    return this.proposta.lojista.liberadoBiometria;
  }

  private checkBiometriaAtiva() {
    this.cdcLojaService.checkParametro('FLAG_FLUXO_FOTO_OBRIG').subscribe((response) => {
      if (response.key === 'FLAG_FLUXO_FOTO_OBRIG') {
        this.fluxoFotoObrigatoria = response.value === 'S';
      }
    });
  }

  private validaCenariosBiometria() {
    if (this.isLojistaPiloto) {
      this.showValidacaoCelular = false;
      this.showValidacaoBiometria = true;

    } else {
      this.showValidacaoCelular = true;
      this.showValidacaoBiometria = false;
    }
  }

  private initWebcam() {
    WebcamUtil.getAvailableVideoInputs()
      .then(
        (mediaDevices: MediaDeviceInfo[]) => {
          this.hasWebcam = mediaDevices && mediaDevices.length > 0;
        }
      ).catch((_) => {
        this.hasWebcam = false;
      });
    this.hasWebcam;
  }
}
