import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as toastr from 'toastr';

import { LoginStorage } from '../../../../login/login.storage';
import { CdcLojaRestService } from '../../../../omni-rest/cdc/cdc-loja-rest.service';
import { ParametroRendaProduto } from '../../../../produtos/shared/models/parametro-renda-produto';
import { VendedorService } from '../../../../services/vendedor/vendedor.service';
import { AddModalConfig } from '../../../../shared/components/inputs/auto-complete/add-modal-config';
import { Proposta } from '../../../shared/models/proposta';
import { AnaliseAutomaticaStatus, DisparoAnaliseAutomatica } from '../../models/analise-automatica-status';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { EcommerceEtapas } from '../ecommerce-etapas';

interface Seller {
  email: string;
  id: number;
}

interface InsertSellerRequestBody {
  vendedor: {
    codigoLojista: number;
    email: string;
  };
}

@Component({
  selector: 'app-dados-simulacao',
  templateUrl: './dados-simulacao.component.html',
  styleUrls: ['./dados-simulacao.component.scss'],
})
export class DadosSimulacaoComponent implements OnInit, OnDestroy {
  @Input() tabelas: any;
  @Input() operacoes: any;
  @Input() parametroRendaProduto: ParametroRendaProduto;

  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  private STATUS_CRIVO_RECUSADO = 'RECUSADO';

  vendedores: Seller[];
  showLoader = false;
  form: FormGroup;
  addModalForm: FormGroup;
  modalConfig: AddModalConfig = {
    title: 'CADASTRAR VENDEDOR',
    label: 'E-mail coorporativo',
    type: 'email',
    formControlName: 'vendedor',
    okBtnLabel: 'Finalizar Cadastro',
  };
  IdLojista: number;
  errorMessage: string;

  showLoaderPhases = false;
  showTryAgainButton = false;
  propostaCriadaId: number;
  timeout: any;
  reenviarParaAnalise = false;

  constructor(
    private fb: FormBuilder,
    private cdcLojaRestService: CdcLojaRestService,
    private vendedorService: VendedorService,
    private loginStorage: LoginStorage,
    private cdcLojaService: CdcLojaService
  ) { }

  vendedorKey = (item: { id: any }) => item.id;
  vendedorLabel = (item: { email: any }) => item.email;

  async ngOnInit() {
    this.IdLojista = this.loginStorage.usuario.lojaTO.id;
    this.initForm();
    await this.getSellers();
  }

  private initForm(): void {
    this.form = this.fb.group({
      vendedor: ['', Validators.required],
      valorSolicitado: ['', Validators.required],
      cpf: ['', Validators.required],
    });

    this.addModalForm = this.fb.group({
      vendedor: ['', Validators.required],
    });
  }

  private async getSellers() {

    try {
      const vendedores = await this.vendedorService
        .vendedoresByLojista(this.IdLojista)
        .toPromise();

      this.vendedores = vendedores.vendedores
        .filter((v: Seller) => v.email) // filter não necessário em produção se o cadastro estiver certo e não retornar cadastros com emails nulos
        .map((v: Seller) => {
          {
            return {
              email: v.email.toLowerCase(),
              id: v.id,
            };
          }
        });
    } catch (err) {
      this.vendedores = [];
    }
  }

  async onAddVendedor(email: string) {
    this.showLoader = true;
    this.errorMessage = '';

    const body: InsertSellerRequestBody = {
      vendedor: {
        email,
        codigoLojista: this.IdLojista,
      },
    };

    try {
      const res: any = await this.cdcLojaRestService
        .post('sellers/email', body)
        .toPromise();
      await this.getSellers();
      this.form.controls.vendedor.setValue(res.vendedor.id);
    } catch (err) {
      this.errorMessage = err.error.messages[0];
      const lojistaId = this.vendedores.find(
        (v) => v.email.toLowerCase() === email.toLowerCase()
      );
      this.form.controls.vendedor.setValue(lojistaId.id);
    }
    this.showLoader = false;
  }

  async onClickNext() {
    this.showLoaderPhases = true;
    if (this.reenviarParaAnalise) {
      this.enviarPropostaParaAnalise(EcommerceEtapas.proposta);
      return;
    }

    const propostaBody: Proposta = {
      plataformaInclusao: 'WEB',
      carencia: 0,
      valorEntrada: 0,
      lojista: {
        id: this.IdLojista,
      },
      vendedor: {
        id: this.form.controls.vendedor.value,
      },
      operacao: {
        id: this.operacoes[0].id,
      },
      cliente: {
        cpf: this.form.controls.cpf.value,
      },
      valorLiquido: this.form.controls.valorSolicitado.value,
      tabela: {
        id: this.tabelas[0].id,
        descricao: this.tabelas[0].descricao,
      },
      rastreioStatus: {
        codigo: 31,
        descricao: 'EM SIMULAÇÃO',
      },
      parceiro: this.loginStorage.usuario.parcerias[0]
    };

    const propostaCriada = await this.cdcLojaService
      .criarProposta(propostaBody)
      .toPromise();

    EcommerceEtapas.proposta = {
      carencia: 0,
      plataformaInclusao: 'WEB',
      vendedor: {
        id: this.form.controls.vendedor.value,
        email: this.vendedores.find(
          (v) => v.id === this.form.controls.vendedor.value
        ).email,
      },
      valorLiquido: this.form.controls.valorSolicitado.value,
      cliente: {
        cpf: this.form.controls.cpf.value,
      },
      id: propostaCriada.id,
      tabela: {
        id: this.tabelas[0].id,
        descricao: this.tabelas[0].descricao,
      },
      operacao: {
        cessao: propostaCriada.operacao.cessao,
      },
      simulacao: {
        id: propostaCriada.simulacao.id,
      },
      parceiro: this.loginStorage.usuario.parcerias[0],
    };

    this.propostaCriadaId = propostaCriada.id;
    this.enviarPropostaParaAnalise(propostaCriada);
  }

  async nextStep(propostaCriada) {
    if (propostaCriada.statusAnaliseCrivo !== this.STATUS_CRIVO_RECUSADO) {
      EcommerceEtapas.simulador = await this.cdcLojaService
        .newSimulacao(EcommerceEtapas.proposta)
        .toPromise();

      EcommerceEtapas.proposta.qtdeParcelas =
        EcommerceEtapas.simulador.termsOfPayment[0].period;
    }

    EcommerceEtapas.proposta.statusAnaliseCpf = propostaCriada.statusAnaliseCrivo;
    this.showLoader = false;
    this.showLoaderPhases = false;
    this.next.emit();
  }

  onClickPrevious() {
    this.previous.emit();
  }

  dropDownClick() {
    this.errorMessage = '';
  }

  private analisarPropostaAssincrona(tentativa: number, propostaCriada) {
    const maximoTentativas = 18;

    this.cdcLojaService.analisarProposta(propostaCriada, DisparoAnaliseAutomatica.RESULTADO_PARCIAL).subscribe(
      ({ status }) => {
        if (tentativa >= maximoTentativas) {
          this.reiniciarTentativas();
        } else if (status === AnaliseAutomaticaStatus.AGUARDANDO_ANALISE_ASSINCRONA && tentativa < maximoTentativas) {
          this.timeout = setTimeout(() => this.analisarPropostaAssincrona(tentativa + 1, propostaCriada), 5000);
        } else if (status === AnaliseAutomaticaStatus.APROVADO || status === AnaliseAutomaticaStatus.PENDENTE || status === AnaliseAutomaticaStatus.RECUSADO) {
          propostaCriada.statusAnaliseCrivo = status;
          this.nextStep(propostaCriada);
        }
      },
      () => {
        if (tentativa < maximoTentativas) {
          this.timeout = setTimeout(() => this.analisarPropostaAssincrona(tentativa + 1, propostaCriada), 5000);
          return;
        }
        this.showLoaderPhases = false;
      });
  }

  private reiniciarTentativas() {
    this.showLoaderPhases = false;
    this.showTryAgainButton = true;
    toastr.warning('Por favor, clique em "analisar novamente" para recomeçar.', 'Ops, essa operação está demorando mais que o esperado!');
  }

  onClickTryAgain() {
    this.showTryAgainButton = false;
    this.showLoaderPhases = true;
    this.scrollTop();
    this.analisarPropostaAssincrona(1, { id: this.propostaCriadaId });
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  get cpfField(): FormControl {
    return this.form.controls.cpf as FormControl;
  }

  private enviarPropostaParaAnalise(propostaCriada) {
    this.cdcLojaService.enviarPropostaParaAnalise(this.propostaCriadaId, this.loginStorage.usuario.parcerias[0]).subscribe(
      () => {
        this.scrollTop();
        this.analisarPropostaAssincrona(1, propostaCriada);
        this.reenviarParaAnalise = false;
      },
      () => {
        this.showLoaderPhases = false;
        this.reenviarParaAnalise = true;
      });
  }
}
