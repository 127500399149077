import { Component, Input, OnChanges } from '@angular/core';

import { Agrupamento } from './dados/agrupamento/agrupamento';
import { AgrupamentoFactory } from './dados/agrupamento/agrupamento-factory';
import { RelacaoFichas } from './dados/relacao-fichas';

@Component({
  selector: 'app-painel-relacao-fichas',
  templateUrl: './painel-relacao-fichas.component.html',
  styleUrls: ['./painel-relacao-fichas.component.css'],
})
export class PainelRelacaoFichasComponent implements OnChanges {
  @Input() loading;
  @Input() propostasDashboard;
  @Input() periodo;

  labels: string[] = [];
  relacaoFichas: RelacaoFichas;
  agrupamento: Agrupamento = AgrupamentoFactory.porData();

  ngOnChanges() {
    this.labels = [];
    if (this.propostasDashboard && this.propostasDashboard.length > 0) {
      this.relacaoFichas = new RelacaoFichas(
        this.propostasDashboard,
        this.agrupamento,
      );
      this.montarLabels();
    }
  }

  propostasPorLabel(label: string): any[] {
    return this.relacaoFichas.propostas.get(label);
  }

  agruparPorData() {
    this.setAgrupamento(AgrupamentoFactory.porData());
  }

  agruparPorLoja() {
    this.setAgrupamento(AgrupamentoFactory.porLoja());
  }

  private setAgrupamento(agrupamento: Agrupamento) {
    if (agrupamento.descricao !== this.agrupamento.descricao) {
      this.agrupamento = agrupamento;
      this.relacaoFichas.alterarAgrupamento(agrupamento);
      this.montarLabels();
    }
  }

  private montarLabels() {
    this.labels = [];
    this.relacaoFichas.propostas.forEach((value, key) => {
      this.labels.push(key);
    });
  }
}
