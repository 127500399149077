import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-animated-button-input',
  templateUrl: './animated-button-input.component.html',
  styleUrls: ['./animated-button-input.component.css'],
})
export class AnimatedButtonInputComponent {
  @Input() label: string;
  @Input() afterLabel?: string;
  @Input() onclick: () => Promise<any>;
  @Input() onerror: (error) => void = () => {
    // This is intentional
  }
  @Input() onsuccess: (value: any) => void = (_) => {
    // This is intentional
  }
  @Input() small: boolean = false;
  @Input() disabled: boolean = false;
  @Input() clicked = false;

  onClick() {
    if (!this.clicked) {
      this.onclick().then(this.onsuccess).catch(this.onerror);
    }
  }
}
