import { TelefoneNumero } from './telefone-numero';
import { Endereco } from './endereco';

export class DadoProfissional {
  idClasseProfissional: number;
  idProfissao: number;
  nomeEmpresa: string;
  endereco: Endereco;
  telefone: TelefoneNumero = new TelefoneNumero();
}
