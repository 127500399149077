import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {

  @Input() currentPage: string;
  @Input() currentPageDetail: string;
  @Input() routerCurrentPage: string;
  @Input() voltar = 'show';

}
