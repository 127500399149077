export class SimulacaoRouboFurto{
    cpfCnpj?: string = null;
    nome?: string = null;
    dtNascimento?: string = null;
    sexo?: number = null;
    cepPernoite?: string = null;
    estadoCivil?: number = null;
    marca?: string = null;
    modelo?: string = null;
    codFipe?: string = null;
    anoModelo?: string = null;
    anoFabricacao?: string = null;
    zeroKm?: boolean = null;
    utilizacaoDut?: number = null;
    tipoUtilizacao?: number = null;
    possuiOutroVeic?: boolean = null;
    cpfCnpjPrincipalCondutor?: string = null;
    nomePrincipalCondutor?: string = null;
    dtNascimentoPrincipalCondutor?: string = null;
    sexoPrincipalCondutor?: number = null;
    estadoCivilPrincipalCondutor?: number = null;
    pergunta1?: number = null;
    pergunta2?: number = null;
    pergunta3?: number = null;
    pergunta4?: number = null;
}
