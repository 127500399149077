import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-popover';
import { SaibaMaisComponent } from './saiba-mais.component';

@NgModule({
  imports: [CommonModule, FormsModule, PopoverModule],
  declarations: [SaibaMaisComponent],
  exports: [SaibaMaisComponent]
})
export class SaibaMaisModule {}
