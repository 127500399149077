import { FichaTimelineSharedService } from './../ficha-timeline.shared.service';
import { FichaService } from './../../ficha-veiculo.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-ficha-timeline-documentacao-necessaria',
  templateUrl: './ficha-timeline-documentacao-necessaria.component.html'
})
export class FichaTimelineDocumentacaoNecessariaComponent implements OnInit, OnDestroy {
  @Input() timeline: any;
  @Input() timelineActive: boolean;
  @Input() cliente: {};
  @Input() idFicha: number;
  @Input() fullTimeline: any;
  documents = [];
  alive = true;

  constructor(
      private fichaService: FichaService,
      private sharedService: FichaTimelineSharedService
  ) {}

  onPushDocumentacao(body) {
    this.fichaService.pushDocumentacaoEnviada(body)
      .pipe(
        takeWhile(() => this.alive)
      )
      .subscribe(
        _ => this.sharedService.reloadFicha(),
        error => alert(error.retorno),
      );
  }

  ngOnInit() {
    if (
      this.timeline &&
      Array.isArray(this.timeline.cardDireito) &&
      this.timeline.cardDireito.length &&
      this.timeline.cardDireito[0].acoes
    ) {
      this.timeline.cardDireito[0].acoes.forEach(action => {
        switch (action.acao) {
          case 'LISTA_DOCTOS':
            Array.prototype.push.apply(this.documents, action.docs);

            break;

          case 'LISTA_DOCTOS_AVAL':
            Array.prototype.push.apply(this.documents, action.docs.map(document => {

              document.descPendencia = `AVALISTA - ${document.descPendencia}`;
              return document;
            }));

            break;
        }
      });
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
