import { RecuperaEndereco } from './recupera-endereco';
import { RecuperaTelefone } from './recupera-telefone';
import { RecuperaProfissional } from './recupera-profissional';
import { RecuperaDocumento } from './recupera-documento';

import * as moment from 'moment';

export class RecuperaPessoa {
  private id: number;
  private cpf: string;
  private tipo: string;
  private nome: string;
  private categoria: string;
  private ppe: boolean;
  private dataNascimento: string;
  private nomeMae: string;
  private sexo: string;
  private documento: RecuperaDocumento;
  private profissional: RecuperaProfissional;
  private telefones: RecuperaTelefone[];
  private enderecos: RecuperaEndereco[];

  constructor(obj) {
    this.id = obj.id;
    this.cpf = obj.cpf;
    this.tipo = obj.tipo;
    this.nome = obj.nome;
    this.categoria = obj.categoria;
    this.ppe = obj.ppe;
    this.dataNascimento = moment.utc(parseInt(obj.dataNascimento, 10)).format('DD/MM/YYYY');
    this.nomeMae = obj.nomeMae;
    this.sexo = obj.sexo;
    if (obj.profissional) {
      this.profissional = new RecuperaProfissional(obj.profissional[0]);
    }
    if (obj.telefones) {
      this.telefones = [];
      for (const value of obj.telefones) {
        this.telefones.push(new RecuperaTelefone(value));
      }
    }
    if (obj.enderecos) {
      this.enderecos = [];
      for (const value of obj.enderecos) {
        this.enderecos.push(new RecuperaEndereco(value));
      }
    }
  }

  getId(): number {
    return this.id;
  }

  getCpf(): string {
    return this.cpf;
  }

  getTipo(): string {
    return this.tipo;
  }

  getNome(): string {
    return this.nome;
  }

  getCategoria(): string {
    return this.categoria;
  }

  getPpe(): boolean {
    return this.ppe;
  }

  getDataNascimento(): string {
    return this.dataNascimento;
  }

  getNomeMae(): string {
    return this.nomeMae;
  }

  getSexo(): string {
    return this.sexo;
  }

  getDocumento(): RecuperaDocumento {
    return this.documento;
  }

  getProfissional(): RecuperaProfissional {
    return this.profissional;
  }

  getTelefones(): RecuperaTelefone[] {
    return this.telefones;
  }

  getCelulares(): RecuperaTelefone[] {
    const celulares = [];
    for (let idx = 0; idx < this.telefones.length; idx++) {
      if (this.getTelefones()[idx].getIdCategoria() === 3) {
        celulares.push(this.getTelefones()[idx]);
      }
    }
    return celulares;
  }

  getTelefonePorCategoria(categoria: number): string {
    let telefone = null;
    for (let idx = 0; idx < this.telefones.length; idx++) {
      if (this.getTelefones()[idx].getIdCategoria() === categoria) {
        telefone = this.getTelefones()[idx].getDdd().trim() === null ? '00' : this.getTelefones()[idx].getDdd().trim() +
                           this.getTelefones()[idx].getTelefone();
        break;
      }
    }
    return telefone;
  }

  getEnderecos(): RecuperaEndereco[] {
    return this.enderecos;
  }

  getEnderecoPorTipo(tipo: number): RecuperaEndereco {
    let endereco: any;
    for (let idx = 0; idx < this.enderecos.length; idx++) {
      if (this.getEnderecos()[idx].getTipo() === tipo) {
        endereco = this.getEnderecos()[idx];
        break;
      }
    }
    return endereco;
  }

}
