import { take, takeWhile } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  DoCheck,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoginStorage } from '../../login/login.storage';
import { fichasFilter } from '../helpers/filter';
import { ModalMessageComponent } from './../../shared/modais/modal-message/modal-message.component';
import { FichaListaSharedService } from './../ficha-lista/ficha-lista.shared.service';
import { FichaListaFiltro } from './../ficha-lista/model/ficha-lista-filtro';
import { HeaderSort } from './header-sort/header-sort';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-ficha-table',
  templateUrl: './ficha-table.component.html',
  styleUrls: ['./ficha-table.component.css'],
})
export class FichaTableComponent implements OnInit, OnDestroy, DoCheck {
  @Input() propostas = [];
  @Input() idFase: number;
  @Input() filtro: FichaListaFiltro;

  @Output() emitFiltro = new EventEmitter<FichaListaFiltro>();

  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;

  fichasFiltradas: any[];
  fichas: any[];

  isEmAnalise: boolean;
  isAprovada: boolean;
  isEmFechamento: boolean;
  isRecusada: boolean;

  isSos: boolean;
  isPagamentoAgendado: boolean;
  isCardEsquerdo: boolean;

  dataLimiteAtendimento: number;

  qtdPorPagina = 30;
  pagina = 0;
  numeroFichas = 0;
  fichasOrdenadas: any[];

  currentSort: BehaviorSubject<HeaderSort> = new BehaviorSubject(
    new HeaderSort()
  );
  direction = 'desc';

  procurarItem: string;
  sosText: string = ' <em class="fa fa-bell"></em>';

  isAlive: boolean = true;

  filtroChanges: any = this.filtro;

  constructor(
    private fichaListaSharedService: FichaListaSharedService,
    private router: Router,
    private loginStorage: LoginStorage
  ) {}

  ngOnInit() {
    this.filter();

    this.currentSort
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.filter());
  }

  ngDoCheck(): void {
    if (!Object.is(this.filtroChanges, this.filtro)) {
      this.filter();
    }
    this.filtroChanges = _.cloneDeep(this.filtro);
  }

  onKeyupProcurarItem() {
    this.filter();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  filter() {
    let fichasFiltradas = fichasFilter(this.filtro, this.propostas);
    if (this.procurarItem) {
      fichasFiltradas = this.procurarFicha(fichasFiltradas, this.procurarItem);
    }
    this.numeroFichas = fichasFiltradas.length;

    this.fichasOrdenadas = this.fichasPaginadas(
      this.sortColumn(fichasFiltradas, this.currentSort.value)
    );
  }

  procurarFicha(fichas, dados) {
    const data = dados.toUpperCase();

    return fichas.filter((ficha) => {
      const vendedor = ficha.vendedor || { nmLoja: '', nome: '' };
      return (
        (ficha.statusTimeline.tituloStatus || '').includes(data) ||
        (ficha.id.toString() || '').includes(data) ||
        (ficha.cliente.nome || '').includes(data) ||
        (ficha.cliente.cpf || '').includes(data) ||
        (ficha.valorLiquido ? ficha.valorLiquido.toString() : '').includes(
          data
        ) ||
        (ficha.statusTimeline.usuarioAcao || '').includes(data) ||
        moment(ficha.dataHoraEnvio || 0)
          .format('DD/MM/YYYY - HH:mm')
          .includes(data) ||
        (ficha.statusTimeline.cardEsquerdo
          ? ficha.statusTimeline.cardEsquerdo[1].valor
          : ''
        ).includes(data) ||
        (vendedor.nmLoja || '').includes(data) ||
        (vendedor.nome || '').includes(data)
      );
    });
  }

  paginar($event: any) {
    this.pagina = $event - 1;
    this.filter();
  }

  fichasPaginadas(fichas) {
    const aux = [];
    for (
      let i = this.pagina * this.qtdPorPagina;
      i < this.pagina * this.qtdPorPagina + this.qtdPorPagina;
      i++
    ) {
      if (i >= fichas.length) {
        break;
      }
      aux.push(fichas[i]);
    }
    return aux;
  }

  sortColumn(fichas: any[], currentSort: HeaderSort) {
    let fichasOrdenadas = [];

    if (currentSort.direction === 'asc') {
      fichasOrdenadas = [...fichas].sort((fichaAnt, fichaPost) =>
        this.sortAsc(fichaAnt, fichaPost, currentSort)
      );
    } else {
      fichasOrdenadas = [...fichas].sort((fichaAnt, fichaPost) =>
        this.sortDesc(fichaAnt, fichaPost, currentSort)
      );
    }
    return fichasOrdenadas;
  }

  sortAsc(fichaAnt: any, fichaPost: any, currentSort: any) {
    const fichaAnterior = this.extrairValor(fichaAnt, currentSort);
    const fichaPosterior = this.extrairValor(fichaPost, currentSort);
    if (fichaAnterior > fichaPosterior) return 1;
    if (fichaAnterior < fichaPosterior) return -1;
    return 0;
  }

  sortDesc(fichaAnt: any, fichaPost: any, currentSort: any) {
    const fichaAnterior = this.extrairValor(fichaAnt, currentSort);
    const fichaPosterior = this.extrairValor(fichaPost, currentSort);
    if (fichaAnterior < fichaPosterior) return 1;
    if (fichaAnterior > fichaPosterior) return -1;
    return 0;
  }

  extrairValor(ficha, currentSort) {
    const columnName: string[] = currentSort.column.split('.');

    return columnName.length > 1
      ? ficha[columnName[0]][columnName[1]] || ''
      : ficha[columnName[0]] || '';
  }

  goToTimeline(proposta): void {
    this.isCardEsquerdo = proposta.statusTimeline.cardEsquerdo;
    if (this.isCardEsquerdo) {
      this.isSos = proposta.statusTimeline.cardEsquerdo[0].valor === 'SOS';
      if (this.isSos) {
        this.dataLimiteAtendimento =
          proposta.statusTimeline.dataLimiteAtendimento;
      }
    }

    if (
      this.isSos &&
      this.dataLimiteAtendimento &&
      moment(this.dataLimiteAtendimento).isBefore(moment())
    ) {
      this.modalMessage
        .show(
          'Esta ficha foi recusada após expiração do prazo de atendimento da SOS!'
        )
        .modalClose.pipe(take(1))
        .subscribe(() => this.fichaListaSharedService.reloadFichaLista());
    } else if (['CDC LOJA', 'CDC PREMIUM'].includes(proposta.produto)) {
      const sigla = 'cdcloja';
      this.router.navigate([`/produtos/${sigla}/timelines`], {
        queryParams: { id: proposta.id },
      });
    } else if (['MICROCREDITO', 'CRÉDITO PESSOAL'].includes(proposta.produto)) {
      const sigla = 'cp';
      this.router.navigate([`/produtos/${sigla}/timelines`], {
        queryParams: { id: proposta.id },
      });
    } else {
      this.router.navigate(['/timeline'], {
        queryParams: { idFicha: proposta.id },
      });
    }
  }

  iconeProduto(produto: string): string {
    if (produto) {
      switch (produto.toUpperCase()) {
        case 'CRÉDITO PESSOAL':
          return 'icon credito-pessoal';
        case 'CDC LOJA':
          return 'icon cdc-loja';
        case 'MICROCREDITO':
          return 'icon microcredito';
      }
    }
    return 'icon financiamento';
  }

  setupTdTitle(vendedor: any): string {
    if (vendedor.email) {
      return `Email: ${vendedor.email}
      Celular: ${vendedor.telCelular}`;
    }
    return '';
  }

  getPrazoFromProposta(proposta: any): string {
    return proposta.statusTimeline.cardEsquerdo
      ? proposta.statusTimeline.cardEsquerdo[1].valor
      : '';
  }

  get isLojista(): boolean {
    return this.loginStorage.usuario.tipoUsuarioTO.id === 7;
  }

  get isOperador(): boolean {
    return this.loginStorage.usuario.tipoUsuarioTO.id === 8;
  }
}
