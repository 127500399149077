import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberOrCharacterWhiteSpace]'
})
export class OnlyNumberOrCharacterDirectiveWhiteSpace {
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (!(/[a-zA-Z0-9]/.test(event.key)) && event.code !== 'Space') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    event.preventDefault();
  }
}
