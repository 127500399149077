
import { takeWhile } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DadosProfissionais } from '../../shared/dados-profissionais/model/dados-profissionais';
import { LoginStorage } from './../../login/login.storage';
import { ModalMessageComponent } from './../../shared/modais/modal-message/modal-message.component';
import { FichaCadastro } from './ficha-cadastro';
import { FichaCadastroResultadoParcialComponent } from './ficha-cadastro-resultado-parcial/ficha-cadastro-resultado-parcial.component';
import { FichaCadastroVeiculoComponent } from './ficha-cadastro-veiculo/ficha-cadastro-veiculo.component';
import { FichaCadastroEvent } from './ficha-cadastro.event';
import { getFichaContent, removeFichaContent, setFichaContent } from './helpers/salvarFicha';
import { Avalista } from './model/avalista';
import { Cliente } from './model/cliente';
import { TipoProdutoEnum } from '../../simulador-veiculo/enums/tipo-produto.enum';

@Component({
  selector: 'app-ficha-cadastro',
  templateUrl: './ficha-cadastro.component.html',
  styleUrls: ['./ficha-cadastro.component.css'],
})
export class FichaCadastroComponent implements OnInit, OnDestroy {
  fichaCadastro: FichaCadastro = new FichaCadastro();
  private isAlive = true;
  private content;
  private urlData = {};

  @ViewChild('resultadoParcial', { static: true })
  resultadoParcial: FichaCadastroResultadoParcialComponent;

  @ViewChild('cadastroVeiculo', { static: true }) cadastroVeiculo: FichaCadastroVeiculoComponent;

  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  constructor(
    private route: ActivatedRoute,
    private loginStorage: LoginStorage,
    private fichaEvent: FichaCadastroEvent) { }

  ngOnInit() {
    this.initObservableParams();
    this.fichaCadastro.avalistaParentesco = 'none';
  }
  ngOnDestroy() {
    this.isAlive = false;
  }

  recuperarFichaComAnalise(item, id) {
    if (item && item['proposta']['id'] !== id) {
      this.modalMessage.show(
        'Já existe um registro salvo para essa proposta, deseja carregar os dados?',
        'SIM',
        'NÃO',
      );
      this.content = item;
    }
  }

  checkTypeFicha(params) {
    getFichaContent().then((item) => {
      if (item && Object.keys(item).length > 0) {
        if (item['passo'] > 5 && params.url === 'resultadoparcial') {
          this.recuperarFichaComAnalise(item, params.id);
        }
      }
    });
  }

  checkHasFicha(params) {
    this.checkTypeFicha(params);
  }

  getContext() {
    return window.location.pathname;
  }

  checkResponseModal(show) {
    if (show) {
      try {
        Object.keys(this.fichaCadastro).forEach((data) => {
          this.fichaCadastro[data] = this.content[data];
        });
      } catch (err) {
        throw new Error('ocorreu um erro para recuperar a ficha');
      } finally {
        if (this.getContext().substring(0, 17) === '/resultadoparcial') {
          this.fichaCadastro.getFichaLocalStorage = true;
        }
        this.insertProfissao();
        this.insertPrototype();
      }
    } else {
      removeFichaContent();
    }
  }

  private initObservableParams() {
    this.route.params.pipe(takeWhile(() => this.isAlive)).subscribe((params) => {
      this.urlData = { ...params, url: this.route.snapshot.url[0].path };
      this.handleParamsAndUrl(this.urlData);
      this.checkHasFicha(this.urlData);
    });
  }

  private handleParamsAndUrl(params) {
    this.isAlive = false;
    switch (params.url) {
      case 'resultadoparcial':
        this.handleUrlResultadoParcial(params);
        break;
      case 'novaplaca':
        this.handleInformarNovaPlaca(params);
        break;
      case 'informargarantia':
        this.handleInformarGarantia(params);
        break;
      case 'nova-ficha-veiculo':
        this.handlerNovaFichaVeiculo(params);
        break;
      default:
        break;
    }
  }

  private handleUrlResultadoParcial(params: any) {
    this.fichaCadastro.setPasso(this.fichaCadastro.PASSO_RESULTADO_PARCIAL);
    const id: string = params['id'];
    this.resultadoParcial.setIsGettingFicha();
    this.resultadoParcial.simularProposta(id);
  }

  private handleInformarNovaPlaca(params: any) {
    const id: string = params['id'];
    this.fichaCadastro.passoCorrigirPLacaInvalida();
    this.cadastroVeiculo.informarNovaPlaca(id);
  }

  private handleInformarGarantia(params: any) {
    const id: string = params['id'];
    this.fichaCadastro.placaVeiculo = '';
    this.fichaCadastro.hasZeroKm = false;
    this.fichaCadastro.hasUtilizacaoAgropecuaria = false;
    this.fichaCadastro.passoCorrigirFipeNotFound();
    this.cadastroVeiculo.informarGarantia(id);
  }

  private handlerNovaFichaVeiculo(params: any) {
    this.fichaCadastro.produtoTipo = (params.produto || '').toUpperCase();
    this.fichaCadastro.solicitanteTipo = (params.solicitante || '').toUpperCase();

    let idLojista = this.fichaCadastro.IDLojista;
    if (params.solicitante === 'TIPO_SOLIC_CLIENTE' || params.solicitante === 'TIPO_SOLIC_OUTRA') {
      idLojista = this.loginStorage.agente.lojaBalcaoId;
    }

    this.fichaCadastro.setPayload('grupo1', params.produto === 'financiamento' ? TipoProdutoEnum.FINANCIMENTO : TipoProdutoEnum.REFINANCIAMENTO);

    this.fichaCadastro.setPayload('lojaId', idLojista);
    setFichaContent(this.fichaCadastro);
  }

  // Parte feita do código
  // Após recuperar a ficha, faz se necessário inserir todos os métodos das classes atribuidas dentro
  // de ficha cadastro
  insertPrototype() {
    Object.setPrototypeOf(
      this.fichaCadastro,
      Object.getPrototypeOf(new FichaCadastro()),
    );
    Object.setPrototypeOf(
      this.fichaCadastro._client,
      Object.getPrototypeOf(new Cliente()),
    );
    Object.setPrototypeOf(
      this.fichaCadastro._client,
      Object.getPrototypeOf(new FichaCadastro()._client),
    );
    Object.setPrototypeOf(
      this.fichaCadastro._avalista,
      Object.getPrototypeOf(new Avalista()),
    );
    Object.setPrototypeOf(
      this.fichaCadastro._avalista,
      Object.getPrototypeOf(new FichaCadastro()._avalista),
    );
  }
  public insertProfissao() {
    if (this.fichaCadastro._client.dadoProfissional.classeProfissional) {
      this.fichaCadastro._client.dadoProfissional = DadosProfissionais.of(
        this.fichaCadastro._client.dadoProfissional.classeProfissional.id,
        this.fichaCadastro._client.dadoProfissional.profissao.id,
        this.fichaCadastro._client.dadoProfissional.empresa.nome,
        this.fichaCadastro._client.dadoProfissional.empresa.telefone,
        this.fichaCadastro._client.dadoProfissional.empresa.endereco,
      );
    }
    if (this.fichaCadastro._avalista.dadoProfissional.classeProfissional) {
      this.fichaCadastro._avalista.dadoProfissional = DadosProfissionais.of(
        this.fichaCadastro._avalista.dadoProfissional.classeProfissional.id,
        this.fichaCadastro._avalista.dadoProfissional.profissao.id,
        this.fichaCadastro._avalista.dadoProfissional.empresa.nome,
        this.fichaCadastro._avalista.dadoProfissional.empresa.telefone,
        this.fichaCadastro._avalista.dadoProfissional.empresa.endereco,
      );
    }
  }
}
