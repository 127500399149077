export class Endereco {
  public idTipoEndereco = 0;
  public uf = '';
  public cidade = '';
  public endereco = '';
  public bairro = '';
  public numero = '';
  public complemento = '';
  public cep = '-';

  constructor(data?: any) {
    this.idTipoEndereco = (data || {}).idTipoEndereco;
    this.uf = (data || {}).uf;
    this.cidade = (data || {}).cidade;
    this.endereco = (data || {}).endereco;
    this.bairro = (data || {}).bairro;
    this.numero = (data || {}).numero;
    this.complemento = (data || {}).complemento;
    this.cep = (data || {}).cep;
  }

  get $cep() {
    if (this.cep) {
      return this.cep.replace('-', '');
    }
  }
  get $idTipoEndereco() {
    return this.idTipoEndereco === 2 ? this.idTipoEndereco : 1;
  }
}
