import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit, OnDestroy {

  @Input() titulo: string = 'Título';
  @Input() mensagem: string = '';
  @Input() rawMessage: string = '';
  @Input() labelFechar: string = 'Não';
  @Input() labelOk: string = 'Sim';
  @Input() showFecharBtn: boolean = true;
  @Input() showOkBtn: boolean = true;
  @Input() isTituloVermelho: boolean = false;
  @Input() centered: boolean = false;

  @Output() confirmValue = new EventEmitter<boolean>();

  alive: boolean = true;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  showModal(
    options?: {
      titulo?: string,
      mensagem?: string,
      labelFechar?: string,
      labelOk?: string,
      showFecharBtn?: boolean,
      showOkBtn?: boolean,
      isTituloVermelho?: boolean
    }): void {

    if (options) {
      this.titulo = options.titulo || this.titulo;
      this.mensagem = options.mensagem || this.mensagem;
      this.labelFechar = options.labelFechar || this.labelFechar;
      this.labelOk = options.labelOk || this.labelOk;
      this.showFecharBtn = options.showFecharBtn || this.showFecharBtn;
      this.showOkBtn = options.showOkBtn || this.showOkBtn;
      this.isTituloVermelho = options.isTituloVermelho || this.isTituloVermelho;
    }

  }

  onClickOk() {
    this.confirmValue.next(true);
    this.bsModalRef.hide();
  }

  onClickClose() {
    this.confirmValue.next(false);
    this.bsModalRef.hide();
  }

}
