import { Injectable } from '@angular/core';
import { Dialog, DialogButton } from './dialog.model';
import { dialogTypeCSSClass } from './dialog.map';
import { DialogType } from './dialog.enum';

// Modal.prototype.checkScrollbar = function () {
//   var fullWindowWidth = window.innerWidth
//   if (!fullWindowWidth) { // workaround for missing window.innerWidth in IE8
//     var documentElementRect = document.documentElement.getBoundingClientRect()
//     fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left)
//   }
//   this.bodyIsOverflowing = document.body.clientWidth < fullWindowWidth
//   this.scrollbarWidth = this.measureScrollbar()
// }

// Modal.prototype.setScrollbar = function () {
//   var bodyPad = parseInt((this.$body.css('padding-right') || 0), 10)
//   this.originalBodyPad = document.body.style.paddingRight || ''
//   var scrollbarWidth = this.scrollbarWidth
//   if (this.bodyIsOverflowing) {
//     this.$body.css('padding-right', bodyPad + scrollbarWidth)
//     $(this.fixedContent).each(function (index, element) {
//       var actualPadding = element.style.paddingRight
//       var calculatedPadding = $(element).css('padding-right')
//       $(element)
//         .data('padding-right', actualPadding)
//         .css('padding-right', parseFloat(calculatedPadding) + scrollbarWidth + 'px')
//     })
//   }
// }

// Modal.prototype.resetScrollbar = function () {
//   this.$body.css('padding-right', this.originalBodyPad)
//   $(this.fixedContent).each(function (index, element) {
//     var padding = $(element).data('padding-right')
//     $(element).removeData('padding-right')
//     element.style.paddingRight = padding ? padding : ''
//   })
// }

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  nativeElement: HTMLElement;
  title: string;
  body: string;
  textClass: string;
  iconClass: string;
  buttons: DialogButton[];
  classList: string[];
  onClose: () => void;

  setNativeElement(nativeElement: HTMLElement): void {
    this.nativeElement = nativeElement;
  }

  emit({ title, body, buttons, classList, textClass, iconClass, onClose }: Dialog): void {
    this.title = title;
    this.body = body;
    this.buttons = buttons;
    this.classList = classList;
    this.textClass = textClass;
    this.iconClass = iconClass;
    this.onClose = onClose;

    document.body.classList.add('modal-open');
    this.nativeElement.classList.add('in');

    this.nativeElement.style.display = 'block';
  }

  close(): void {
    if (!document.querySelectorAll('.modal.in:not(.dialog-modal)').length) {
      document.body.classList.remove('modal-open');
    }

    this.nativeElement.classList.remove('in');

    this.nativeElement.style.display = 'none';
    typeof this.onClose === 'function' && this.onClose.call(this);
  }

  success(body: string, callback?: () => void): void {
    this.emit({
      body,
      title: 'Sucesso',
      buttons: [
        {
          label: 'Ok',
          classList: [`btn-${dialogTypeCSSClass[DialogType.Primary]}`],
          callback: (): void => {
            typeof callback === 'function' && callback.call(this);

            this.close();
          }
        }
      ],
      classList: ['modal-sm']
    });
  }

  error(body: string, callback?: () => void): void {
    this.emit({
      body,
      title: 'Erro',
      buttons: [
        {
          label: 'Ok',
          classList: [`btn-${dialogTypeCSSClass[DialogType.Danger]}`],
          callback: (): void => {
            typeof callback === 'function' && callback.call(this);

            this.close();
          }
        }
      ],
      classList: ['modal-sm']
    });
  }

  warning(body: string, callback?: () => void): void {
    this.emit({
      body,
      title: 'Atenção',
      buttons: [
        {
          label: 'Ok',
          classList: [`btn-${dialogTypeCSSClass[DialogType.Warning]}`],
          callback: (): void => {
            typeof callback === 'function' && callback.call(this);

            this.close();
          }
        }
      ],
      classList: ['modal-sm']
    });
  }

  confirm({ body, title = 'Confirmar', classList = ['modal-sm'], callbackSuccess, callbackError, iconClass, textClass } :
  {
    body: string,
    title?: string,
    classList?: string[],
    iconClass?: string,
    textClass?: string,
    callbackSuccess: () => void,
    callbackError?: () => void
  }): void {
    this.emit({
      body,
      title,
      classList,
      textClass,
      iconClass,
      buttons: [
        {
          label: 'Cancelar',
          classList: ['btn-border-orange'],
          callback: (): void => {
            typeof callbackError === 'function' && callbackError.call(this);

            this.close();
          }
        },
        {
          label: 'Ok',
          classList: ['btn-full-orange'],
          callback: (): void => {
            typeof callbackSuccess === 'function' && callbackSuccess.call(this);

            this.close();
          }
        }
      ]
    });
  }

  info({ body, title = 'Atenção', classList = ['modal-sm'], iconClass, textClass, callbackOK, labelOk = 'Ok' } :
  {
    body: string,
    title?: string,
    classList?: string[],
    iconClass?: string,
    textClass?: string,
    callbackOK?: () => void,
    labelOk?: string
  }): void {
    this.emit({
      body,
      title,
      classList,
      textClass,
      iconClass,
      onClose: callbackOK,
      buttons: [
        {
          label: labelOk,
          classList: ['btn-full-orange'],
          callback: (): void => {
            typeof callbackOK === 'function' && callbackOK.call(this);

            this.close();
          }
        }
      ]
    });
  }
}
