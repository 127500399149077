import { LoginStorage } from './../../login/login.storage';
import { DocumentoVO } from './documentoVO';
import { CpfCnpj } from '../partner/cpf-cnpj';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { appSettings as globals } from '../../../environments/app.setings';

import { DialogService } from '../../services/dialog/dialog.service';
import { normalizeList } from '../../shared/helper/helper';
import { Documentation } from '../../shared/enums/documentation.enum';
import { Report } from '../../shared/models/report/report.model';
import { ReportService } from '../../services/report/report.service';
import { OmniFacilUploadRestService } from '../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';

@Component({
  selector: 'app-upload-arquivos-multiavalistas',
  templateUrl: './upload-arquivos-multiavalistas.component.html',
  styleUrls: ['./upload-arquivos-multiavalistas.component.scss'],
})
export class UploadArquivosMultiavalistasComponent implements OnInit, OnChanges {
  @Input() documentos: DocumentoVO[] = [];
  @Input() cliente: { cpf: any };
  @Input() idFicha: string;
  @Input() isOrigemSos: boolean;
  @Input() hideInfo?: boolean = false;
  @Input() buttonLabel?: string;
  @Input() hideSendButton?: boolean;
  @Input() enableSendButton?: boolean;
  @Input() origem?: number = 1;
  @Input() acceptFiles = globals.ACCEPT_IMAGES;
  @Input() cpfAvalistas?:  Map<number, string> = new Map<number, string>();
  @Output() pushDocumentacao = new EventEmitter();

  report: Report;
  isSizeLimit = false;
  isDocsObrigatorio = false;
  fileList: Map<number, FileList[]> = new Map<number, FileList[]>();
  normalizedFileList: Map<number, any[]> = new Map<number, any[]>();
  fotosGarantia: File[] = [];
  mensagem: string;
  isEnviandoDocumento = false;
  documentosPorPessoa: Map<number, DocumentoVO[]> = new Map<number, DocumentoVO[]>();
  documentosAnexar: DocumentoVO[] = [];
  documentosAnexarAvalista: DocumentoVO[] = [];
  Documentation = Documentation;
  isShopkeeper: boolean;

  constructor(
    private loginStorage: LoginStorage,
    private omniRestService: OmniFacilUploadRestService,
    private dialogService: DialogService,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.loadDocumentosAnexar();
  }

  ngOnChanges(changes) {
    if (changes.documentos &&
        JSON.stringify(changes.documentos.previousValue) !== JSON.stringify(changes.documentos.currentValue)) {
      this.loadDocumentosAnexar();
    }
  }

  private loadDocumentosAnexar() {
    this.documentosAnexar = (this.documentos || []).filter(documento => {
      if (this.isShopkeeper) {
        return (
          documento.idDocto !== Documentation.Warranty &&
          documento.idPendencia !== Documentation.Report &&
          documento.tipoCliente !== 2
        );
      }

      return documento.idDocto !== Documentation.Warranty &&
             documento.tipoCliente !== 2;
    });

    this.documentosAnexarAvalista = (this.documentos || []).filter(documento => {
      if (this.isShopkeeper) {
        return (
          documento.idDocto !== Documentation.Warranty &&
          documento.idPendencia !== Documentation.Report &&
          documento.tipoCliente === 2
        );
      }

      return documento.idDocto !== Documentation.Warranty &&
             documento.tipoCliente === 2;
    });

    if (this.documentosPorPessoa.size === 0) {
      this.documentosAnexarAvalista.filter(documento => {
        if (this.cpfAvalistas !== null && this.cpfAvalistas.size !== 0 && this.cpfAvalistas.get(documento.idAvalista) != null) {
          let docAvalista: DocumentoVO;
          docAvalista = documento;
          docAvalista.cpf = this.cpfAvalistas.get(documento.idAvalista);
          if (this.documentosPorPessoa.get(documento.idAvalista)) {
            this.documentosPorPessoa.get(documento.idAvalista).push(docAvalista);
          } else {
            const documentosAux: DocumentoVO[] = [];
            documentosAux.push(docAvalista);
            this.documentosPorPessoa.set(documento.idAvalista, documentosAux);
          }
        }
      });
    }

    this.documentosPorPessoa.set(0, this.documentosAnexar);

    this.isShopkeeper = this.loginStorage.usuario.isLojista;
  }

  hasWarrantyPicturesDocument(idPessoa: number): boolean {
    return this.documentosPorPessoa.get(idPessoa).some(document => document.idDocto === Documentation.Warranty);
  }

  hasReportDocument(idPessoa: number): boolean {
    return this.documentosPorPessoa.get(idPessoa).some(document => document.idPendencia === Documentation.Report);
  }

  hasAllWarrantyPicturesDocument(): boolean {
    return this.getAllDocumentosAnexar().some(document => document.idDocto === Documentation.Warranty);
  }

  hasAllReportDocument(): boolean {
    return this.getAllDocumentosAnexar().some(document => document.idPendencia === Documentation.Report);
  }

  getCpfAvalista(idAvalista): string {
    return CpfCnpj.formatWithMask(this.cpfAvalistas.get(idAvalista));
  }

  getFileList(idPessoa: number) {
    if (this.fileList.get(idPessoa)) {
      return this.fileList.get(idPessoa);
    }

    this.fileList.set(idPessoa, []);
    return this.fileList.get(idPessoa);
  }

  async sendDocuments() {
    if (!this.isEnviandoDocumento && this.validaEnvio()) {
      try {
        this.isEnviandoDocumento = true;

        let totalDocumentsToSend = this.getAllDocumentosAnexar().length;
        let totalSentDocuments = 0;

        if (this.hasAllWarrantyPicturesDocument()) totalDocumentsToSend++;
        if (this.hasAllReportDocument()) totalDocumentsToSend++;

        this.showMessageEnviandoFile(totalDocumentsToSend, totalSentDocuments);

        for (let indexPessoa = 0; indexPessoa < this.documentosPorPessoa.size; indexPessoa++) {
          const filesPessoa = this.normalizedFileList.get(indexPessoa) || [];
          for (let index = 0; index < filesPessoa.length; index++) {
            if (Array.isArray(filesPessoa[index])) {
              const normalizedFileList: [] = filesPessoa[index];
              if (totalSentDocuments < totalDocumentsToSend) this.showMessageEnviandoFile(totalDocumentsToSend, ++totalSentDocuments);

              for (let indexFile = 0; indexFile < normalizedFileList.length; indexFile = indexFile + 1) {
                const file = normalizedFileList[indexFile];
                const idDocto = this.documentosPorPessoa.get(indexPessoa)[index].idDocto;
                if (this.documentosPorPessoa.get(indexPessoa)[index].cpf) {
                  this.cliente = { cpf: this.documentosPorPessoa.get(indexPessoa)[index].cpf };
                }
                await this.postOmniDoc(file, idDocto);
              }
            }
          }
        }

        for (const value of this.fotosGarantia) {
          if (totalSentDocuments < totalDocumentsToSend) this.showMessageEnviandoFile(totalDocumentsToSend, ++totalSentDocuments);

          const file = value;

          await this.postOmniDoc(file, Documentation.Warranty);
        }

        if (this.report) {
          if (totalSentDocuments < totalDocumentsToSend) this.showMessageEnviandoFile(totalDocumentsToSend, ++totalSentDocuments);

          await this.reportService
            .createReport({
              ...this.report,
              proposalId: this.idFicha
            })
            .toPromise();
        }

        if (!this.isOrigemSos) {
          this.pushDocumentacaoEnviada();
        }

        this.limpaListFile();
      } finally {
        this.isEnviandoDocumento = false;
      }
    }
  }

  private showMessageEnviandoFile(total: number, enviado: number) {
    this.mensagem = `Enviando ${enviado} de ${total}...`;
  }

  handleChangeReport(report: Report) {
    this.report = report;
  }

  handleFileChange(event: any, pessoaId: number, index: number) {
    this.isDocsObrigatorio = false;
    this.isSizeLimit = false;

    const files = event.target.files;
    const target = event.target;
    const idPessoa = pessoaId || 0;
    const normalizedFileList: any[] = this.getDocumentsFileLists(idPessoa);

    if (files && files[0]) {
      this.getFileList(idPessoa)[index] = files;

      const extensoesAceitas = this.acceptFiles.split(',');
      const extensaoInvalida = (filename: string) => extensoesAceitas.filter(ext => filename.endsWith(ext)).length === 0;

      if (normalizeList(files).some((file: File) => extensaoInvalida(file.name))) {
        const extensoes = extensoesAceitas.map(ext => `"${ext}"`).join(', ');
        const message = `Um ou mais dos arquivos selecionados, não está em um formato aceito. Selecione arquivos com as extensões: ${extensoes}.`;
        console.log(message);
        return this.dialogService.confirm({ body: message, callbackSuccess: () => target.click() });
      }

      if ((normalizedFileList[index] && (normalizedFileList[index].length + files.length) > 10) || files.length > 10) {
        target.value = null;

        return this.dialogService.confirm({ body: 'O máximo são 10 imagens por documento.', callbackSuccess: () => target.click() });
      }

      if (normalizeList(files).some((file: File) => (file.size / 1048576) > 10)) {
        target.value = null;

        return this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionadas tem tamanho superior à 10mb.', callbackSuccess: () => target.click() });
      }

      if (
        normalizedFileList[index] &&
        normalizeList(files).some((file: File) => normalizeList(normalizedFileList[index]).some(selectedFile => {
          return (
            file.name === selectedFile.name &&
            file.size === selectedFile.size &&
            file.type === selectedFile.type
          );
        }))
      ) {
        target.value = null;

        return this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionada(s) já foi adicionada.', callbackSuccess: () => target.click() });
      }

      if (normalizedFileList[index] && normalizedFileList[index].length) {
        Array.prototype.push.apply(normalizedFileList[index], normalizeList(files));
      } else {
        normalizedFileList[index] = normalizeList(files);
      }
      this.normalizedFileList.set(idPessoa, normalizedFileList);
      target.value = null;
    }
  }

  fileGarantiaChange(file, index) {
    this.isDocsObrigatorio = false;
    this.isSizeLimit = false;
    this.fotosGarantia[index] = file;
  }

  removeFile(list: any[], fileIndex: number) {
    list.splice(fileIndex, 1);
  }

  removeAllFiles(filesDocuments: any, documentPersonIndex: number, documentIndex: number) {
    filesDocuments.get(documentPersonIndex)[documentIndex] = [];
  }

  getDocumentsFileLists(idPessoa: number) {
    if (this.normalizedFileList.get(idPessoa)) {
      let doc = [];
      doc = this.normalizedFileList.get(idPessoa).filter((list, index) => Object.keys(this.normalizedFileList.get(idPessoa)).find(key => key === String(index)));
      return doc;
    }
    this.normalizedFileList.set(idPessoa, []);
    return this.normalizedFileList.get(idPessoa);
  }

  getAllDocumentsFileLists() {
    const documentosAuxFileLists = [];
    this.normalizedFileList.forEach((value, key) => {
      value.forEach(item => documentosAuxFileLists.push(item));
    });
    return documentosAuxFileLists;
  }

  getNormalizedFileList(idPessoa: number, index: number) {
    if (this.normalizedFileList.get(idPessoa)) {
      const file = this.normalizedFileList.get(idPessoa)[index];
      return file || [];
    }
    return [];
  }

  getAllDocumentosAnexar() {
    const documentosAux = [];
    this.documentosPorPessoa.forEach((value, key) => {
      value.forEach(item => documentosAux.push(item));
    });
    return documentosAux;
  }

  validaEnvio(): boolean {
    return this.enableSendButton ||
        !!(
          (
            this.getAllDocumentosAnexar()
              .filter(e => !e.somenteDownload).length - (this.hasAllReportDocument() ? 1 : 0)
          ) === this.getAllDocumentsFileLists().filter(f => f.length > 0).length &&
          (!this.hasAllWarrantyPicturesDocument() || this.fotosGarantia.length === 3) &&
          (!this.hasAllReportDocument() || this.report)
        );
  }

  postOmniDoc(file: File, idDocto: number) {
    const proposta = { id: Number(this.idFicha), cliente: this.cliente, origem: this.origem };
    return this.omniRestService
      .post(file, proposta, idDocto)
      .toPromise()
      .then();
  }

  limpaListFile() {
    this.fileList = new Map<number, FileList[]>();
    this.normalizedFileList = new Map<number, any[]>();
  }

  pushDocumentacaoEnviada() {
    const body = { id: this.idFicha };
    this.pushDocumentacao.next(body);
  }

  formatCpf(data) {
    if (data) {
      return data.replace(/[\.\-]/g, '');
    }
  }

}
