import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { LoginStorage } from './../../login/login.storage';
import { LoaderService } from './../../shared/loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniRestService } from '../omni-rest.service';

@Injectable({
  providedIn: 'root'
})
export class OmniGiroMaisRecusadaRestService extends OmniRestService {

  constructor(http: HttpClient, loaderService: LoaderService, loginStorage: LoginStorage, router: Router) {
    super(http, loaderService, loginStorage, router);
  }

  getBaseUrl(): string {
    return environment.URL_RODAS;
  }

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'omni-autenticacao': this.loginStorage.loginAndTokenAsJson,
    };
  }
}
