
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmniMaisRestService } from '../omni-rest/omni-mais/omni-mais-rest.service';
import { Push } from './push';

@Injectable()
export class ConfiguracaoService {
  constructor(private restService: OmniMaisRestService) {}

  buscarPushPorUsuario(): Observable<Push[]> {
    return this.restService
      .get('usuario/buscarPushPorUsuario/1').pipe(
      map(res =>
        res.tiposPush.map(
          push => new Push(push.idTipoPush, push.pushLigado, push.descTipoPush),
        ),
      ));
  }

  associarPushUsuario(configuracoes: any) {
    return this.restService.post('usuario/associarPushUsuario', configuracoes);
  }
}
