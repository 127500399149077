import { CategoriaSimulacao } from './categoria-simulacao';
import { VersaoSimulacao } from './versao-simulacao';
import { ModeloSimulacao } from './modelo-simulacao';
import { MarcaSimulacao } from './marca-simulacao';
export class VeiculoSimulacao {

  placa: string;
  uf: string;
  categoria: CategoriaSimulacao;
  marca: MarcaSimulacao;
  anoModelo: string;
  modelo: ModeloSimulacao;
  versao: VersaoSimulacao;
  anoFabricacao: string;
  cotacao = 0;
  valor = 0;
  condicao: string;
  grupo1: string;
  grupo2: string;
  fipe: string;
  utilizacaoAgropecuaria: boolean;
  zero: boolean;
  descricao = '';

  constructor(obj) {
    this.placa = obj.placa || '';
    this.uf = obj.uf || '';
    this.categoria = new CategoriaSimulacao(obj.categoria || {});
    this.marca = new MarcaSimulacao(obj.marca || {});
    this.anoModelo = obj.anoModelo || '';
    this.modelo = new ModeloSimulacao(obj.modelo || {});
    this.versao = new VersaoSimulacao(obj.versao || {});
    this.anoFabricacao = obj.anoFabricacao || '';
    this.cotacao = obj.cotacao || 0;
    this.valor = obj.valor || 0;
    this.condicao = obj.condicao || '';
    this.grupo1 = obj.grupo1 || '';
    this.grupo2 = obj.grupo2 || '';
    this.fipe = obj.fipe || '';
    this.utilizacaoAgropecuaria = obj.utilizacaoAgropecuaria || false;
    this.zero = this.condicao === 'ZERO' || false;
    this.descricao = `${this.marca.getDescricao()} ${this.modelo.getDescricao()} | ${this.anoModelo} | ${this.versao.getDescricao()}`;
  }

  getPlaca(): string {
    return this.placa;
  }

  getUf(): string {
    return this.uf;
  }

  getCategoria(): CategoriaSimulacao {
    return this.categoria;
  }

  getMarca(): MarcaSimulacao {
    return this.marca;
  }

  getAnoModelo(): string {
    return this.anoModelo;
  }

  getModelo(): ModeloSimulacao {
    return this.modelo;
  }

  getVersao(): VersaoSimulacao {
    return this.versao;
  }

  getAnoFabricacao(): string {
    return this.anoFabricacao;
  }

  getCotacao(): number {
    return this.cotacao;
  }

  getValor(): number {
    return this.valor;
  }

  getCondicao(): string {
    return this.condicao;
  }

  getGrupo1(): string {
    return this.grupo1;
  }

  getGrupo2(): string {
    return this.grupo2;
  }

  getFipe(): string {
    return this.fipe;
  }

  isUtilizacaoAgropecuaria(): boolean {
    return this.utilizacaoAgropecuaria;
  }

  isZero(): boolean {
    return this.zero;
  }
}
