import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cliente-nao-elegivel',
  templateUrl: './cliente-nao-elegivel.component.html',
  styleUrls: ['./cliente-nao-elegivel.component.scss']
})
export class ClienteNaoElegivelComponent {

  constructor(private router: Router) { }

  finalizar() {
    this.router.navigate(['/ficha']);
  }

}
