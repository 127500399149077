import { Cliente } from './../../../../shared/models/cliente';
import { MicrocreditoService } from './../../../services/microcredito.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Faef } from './model/faef';

@Injectable({
  providedIn: 'root'
})
export class FaefStateService {
  private faef: BehaviorSubject<Faef> = new BehaviorSubject<Faef>(null);
  private faef$ = this.faef.asObservable();

  constructor(private microcreditoService: MicrocreditoService) {}

  async init(propostaId: number, cliente?: Cliente) {
    if (!this.getLast() || this.getLast().proposta !== propostaId) {
      await this.loadCache(propostaId, cliente);
    }
  }

  async loadCache(propostaId, cliente?: Cliente) {
    try {
      const bd = await this.microcreditoService.findByProposta(propostaId);
      const cache = JSON.parse(localStorage.getItem(`faef_${propostaId}`)) || { proposta: propostaId };
      this.faef.next(null);

      const faefCarregado = (bd || cache) as Faef;

      if (cliente) {
        this.microcreditoService.defineCliente(faefCarregado, cliente);
      }

      if (faefCarregado.faefVendasMedia && faefCarregado.faefVendasMedia.faefVendasMediaMes) {
        faefCarregado.faefVendasMedia.faefVendasMediaMes
          .forEach((media: any) => media.tipoMes = `${media.tipoMes}`);
      }

      this.setState(faefCarregado);
    } catch (error) {}
  }

  getLast(): Faef {
    return this.faef.getValue();
  }

  getState() {
    return this.faef$;
  }

  setState(faefNew: Faef) {
    let faef = faefNew;
    if (this.getLast() && faef) {
      faef = { ...this.getLast(), ...faef };
    }
    console.log('faef atualizado:', faef);
    localStorage.setItem(`faef_${faef.proposta}`, JSON.stringify(faef));
    this.faef.next(faef);
  }

  clear() {
    console.log('Limpando estado do faef');
    Object.getOwnPropertyNames(localStorage).filter(e => e.startsWith('faef_')).forEach(e => localStorage.removeItem(e));
    this.faef.next(null);
  }
}
