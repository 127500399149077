export class TipoOperacaoSelecao {
  tipoProduto: string;
  operacao: string;
  produto: string;
  banco: string;
  leads: boolean;
  parametroRendaProduto?: ParametroRendaProduto;
}

export class ParametroRendaProduto {
  valorRendaMinima: number;
  valorSolicitadoMaximo: number;
  valorSolicitadoMinimo: number;
}
