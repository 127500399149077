import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { takeWhile } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { VerificacaoSegurancaService } from '../verificacao-seguranca.service';
import { ModalDicasFotoVerificacaoComponent } from './before-selfie/modal-dicas-foto/modal-dicas-foto-verificacao.component';
import { ModalAvisoIosComponent } from './bem-vindo/modal-aviso-ios/modal-aviso-ios.component';
import { ModalAvisoCpfInvalidoComponent } from './verificacao-seguranca-identificacao/modal-aviso-cpf-invalido/modal-aviso-cpf-invalido.component';

enum StatusVerificacao {
  BEMVINDO = 1,
  IDENTIFICACAO = 2,
  VERIFICAR = 3,
  BEFORE_SELFIE = 4,
  DONE = 5,
  EXPIRED = 6,
}

const MENSAGEM_CPF_INVALIDO = 'Oops, parece que os números digitados não correspondem com o seu CPF.';

@Component({
  selector: 'app-verificacao-seguranca-clearsale',
  templateUrl: './verificacao-seguranca-clearsale.component.html',
  styleUrls: ['./verificacao-seguranca-clearsale.component.scss'],
})
export class VerificacaoSegurancaClearsaleComponent implements OnInit, OnDestroy {
  readonly statusVerificacao = StatusVerificacao;
  statusVerificacaoAtual = StatusVerificacao.BEMVINDO;
  isLoading = false;
  isAlive = true;
  loadingGetToken = false;
  cpf: string;
  urlBiolinkClearSale: string;

  scr: boolean;
  termosDeUso: boolean;
  decodedCpf: string;
  propostaId: number;
  biometriaAtiva: 'S' | 'N';

  constructor(
    private modal: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private verificacaoSegurancaService: VerificacaoSegurancaService
  ) { }

  ngOnInit() {
    this.initObservableParams();

    if (this.isIos() && !this.isSafari()) {
      this.modal.show(ModalAvisoIosComponent, {
        class: 'modal-sm',
        keyboard: false,
        ignoreBackdropClick: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private initObservableParams(): void {
    this.route.queryParams
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((params: any) => {
        if (!params.token) {
          this.router.navigate(['/login']);
          return;
        }

        const decripted = this.verificacaoSegurancaService.decrypt(
          params.token.replace(/ /g, '+'),
          environment.ECOMMERCE_ENCRYPT_KEY
        );
        const decoded =
          this.verificacaoSegurancaService.decodePayloadJWT(decripted);

        if (decoded.status !== 32) {
          this.statusVerificacaoAtual = StatusVerificacao.EXPIRED;
          return;
        }
        this.decodedCpf = decoded.cpf;
        this.propostaId = Number(decoded.proposta);
        this.biometriaAtiva = decoded.biometriaAtiva.valor;
      });
  }

  onClickNext(): void {
    if (this.isDisabledButtonClick) {
      return;
    }
    switch (this.status) {
      case StatusVerificacao.BEMVINDO:
        this.statusVerificacaoAtual = StatusVerificacao.IDENTIFICACAO;
        break;
      case StatusVerificacao.IDENTIFICACAO:
        this.onContinuarVerificaCpf();
        break;
      case StatusVerificacao.VERIFICAR:
        this.statusVerificacaoAtual = StatusVerificacao.BEFORE_SELFIE;
        break;
      case StatusVerificacao.BEFORE_SELFIE:
        this.redirecionarCapturaClearsale();
        break;
    }
  }

  onClickDicaFotoSelfie() {
    this.modal.show(ModalDicasFotoVerificacaoComponent, {
      class: 'modal-sm2',
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }

  get status(): number {
    return this.statusVerificacaoAtual;
  }

  async onContinuarVerificaCpf(): Promise<void> {
    this.loadingGetToken = true;
    this.isLoading = true;

    try {
      if (this.biometriaAtiva === 'N') {
        this.verificacaoSegurancaService.avancarEtapaPropostaSemBiometria({ proposal: this.propostaId, document: this.cpf }).subscribe(
          () => {
            this.statusVerificacaoAtual = StatusVerificacao.DONE;
          });
        return;
      }

      const response = await this.verificacaoSegurancaService.verificarCpfClienteProposta(this.cpf, this.propostaId).toPromise();
      this.urlBiolinkClearSale = response.biolink.url;
      this.statusVerificacaoAtual = StatusVerificacao.VERIFICAR;
    } catch (err) {
      if (!err || !err.error) {
        return;
      }
      this.showModalInvalidCpf(err.error.messages[0]);
    } finally {
      this.loadingGetToken = false;
      this.isLoading = false;
    }
  }

  private showModalInvalidCpf(message: string) {
    const modal = this.modal.show(ModalAvisoCpfInvalidoComponent, {
      class: 'modal-sm2',
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: { message },
    }).content;

    modal.closeModal.subscribe(() => {
      this.statusVerificacaoAtual = StatusVerificacao.EXPIRED;
    });
  }
  changeCpf(cpf): void {
    if (cpf) {
      if (cpf.length === 11) {
        if (this.decodedCpf !== cpf) {
          this.showModalInvalidCpf(MENSAGEM_CPF_INVALIDO);
          return;
        }
      }
    }
    this.cpf = cpf;
  }

  private isSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  private isIos() {
    const iosVersions = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ];

    const hasIos = iosVersions.filter(
      (version) => version === navigator.platform
    );

    return (
      hasIos.length > 0 ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  public get isDisabledButtonClick(): boolean {
    return (
      (this.status === StatusVerificacao.BEMVINDO && this.isIos() && !this.isSafari()) ||
      (this.status === StatusVerificacao.IDENTIFICACAO &&
        (this.loadingGetToken || !this.cpf || this.cpf.length !== 11)) ||
      (this.status === StatusVerificacao.VERIFICAR && (!this.scr || !this.termosDeUso))
    );
  }

  private redirecionarCapturaClearsale() {
    if (this.urlBiolinkClearSale) {
      window.location.href = this.urlBiolinkClearSale;
    }
  }
}
