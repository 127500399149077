import { Directive, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appTelefone]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TelefoneDirective,
    multi: true
  }]
})
export class TelefoneDirective implements ControlValueAccessor {

  onTouched: any;
  onChange: any;

  constructor(private _element: ElementRef) { }

  writeValue(value: any): void {
    if (value) {
      this._element.nativeElement.value = this.format(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {
    $event.target.value = this.format($event.target.value);
    this.changeNgModel($event.target.value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    const e = <KeyboardEvent>event;

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }

    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

    if (event.target.value && event.target.value.length >= 15) {
      e.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    $event.target.value = this.format($event.target.value);
    this.changeNgModel($event.target.value);
    this.onTouched();
  }

  private changeNgModel(value: string) {
    if (value) {
      this.onChange(this.replace(value));
    } else {
      this.onChange('');
    }
  }

  private format(value: string): string {
    if (value) {
      let v = this.replace(value);
      if (v.length > 11) {
        v = v.substring(0, 11);
      }

      v = v.replace(/\D/g, '');
      v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
      v = v.replace(/(\d)(\d{4})$/, '$1-$2');
      return v;
    }
    return value;
  }

  private replace(value: string) {
    if (value) {
      return value.toString().replace(/[^\w]/gi, '');
    }
    return value;
  }
}
