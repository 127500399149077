
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';
import { EnderecoCep } from './endereco-cep';

@Injectable()
export class CepService {

  constructor(private _restService: OmniMaisRestService) { }

  findEnderecoByCep(cep: string) {
    return this._restService.get(`cep/buscarEnderecoPorCep/${cep}`).pipe(map(res => new EnderecoCep(res)));
  }

  getEnderecoByCep(cep: string) {
    return this._restService.get(`cep/buscarEnderecoPorCep/${cep}`);
  }

  getCepByEndereco(endereco) {
    if (endereco.bairro === '') {
      delete endereco.bairro;
    }
    return this._restService.post('cep/buscarCepPorEndereco', { ...endereco });
  }
}
