import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface Nacionalidade {
  id: number;
  descricao: string;
}

@Injectable()
export class NacionalidadesService {

  constructor(private http: HttpClient) { }

  nacionalidades(): Observable<Nacionalidade[]> {
    return this.http.get<Nacionalidade[]>('assets/dados/nacionalidades.json');
  }

}
