import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnChanges {
  @Input() titleButton: string;
  @Input() titleCalendar: string;
  @Input() subtitleCalendar?: string;
  @Input() dateSelected: number;
  @Output() dateSelectedChange = new EventEmitter<number>();

  @Input() startDate: number;
  @Input() endDate: number;
  @Input() restrictDates: boolean = false;
  @Input() validDays: number[] = [5, 10, 15, 20, 25];

  ngOnChanges() {
    this.restrictDates ? this.initDatePickerRestrict() : this.initDatePicker();
    this.setDateSelected();
  }

  private initDatePickerRestrict() {
    const validDays = this.validDays;
    $('.selected-date').datepicker('destroy');
    $('.selected-date').datepicker({
      language: 'pt-BR',
      format: 'mm/dd/yyyy',
      startDate: moment.unix(this.startDate / 1000).toDate(),
      endDate: moment.unix(this.endDate / 1000).toDate(),
      beforeShowDay(date) {
        const dayActual = +moment(date).format('DD');
        if (!validDays.includes(dayActual)) {
          return {
            enabled: false,
          };
        }
        return;
      },
    });
  }

  private initDatePicker() {
    $('.selected-date').datepicker('destroy');
    $('.selected-date').datepicker({
      maxViewMode: 0,
      language: 'pt-BR',
      todayHighlight: true,
      startDate: moment.unix(this.startDate / 1000).toDate(),
      endDate: moment.unix(this.endDate / 1000).toDate(),
    });
  }

  private setDateSelected() {
    if (this.dateSelected) {
      $('.selected-date').datepicker(
        'setDate',
        moment.unix(this.dateSelected).toDate()
      );
    }
  }

  onSaveClick() {
    this.dateSelectedChange.emit(moment(this.getDate()).unix());
  }

  onCloseClick() {
    this.setDateSelected();
  }

  getDate(): Date {
    return $('.selected-date:first').datepicker('getDate');
  }
}
