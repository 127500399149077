import { ModalMessageComponent } from './../../../../shared/modais/modal-message/modal-message.component';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { DadoSimulacao } from './../model/dado-simulacao';
import { PropostaSimulacao } from './../model/proposta/proposta-simulacao';
import { Parcela } from './parcela';

@Component({
  selector: 'parcela',
  templateUrl: './parcela.component.html',
  styleUrls: ['./parcela.component.css']
})
export class ParcelaComponent {

  @Input() proposta: PropostaSimulacao;
  @Input() simulacao: DadoSimulacao;
  @Input() parcelasSimuladas: Parcela[];

  @Output() simular: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  private parcelaSelecionada: Parcela;

  onSelectParcela(parcela: Parcela) {
    this.parcelaSelecionada = parcela;
    this.simulacao.setParcelas(parcela.getQuantidade());
    this.proposta.getTaxaClinte().valorParcela = parcela.getValor();
    this.proposta.getTaxaClinte().parcelas = parcela.getQuantidade();
    this.proposta.getTaxaClinte().taxaFinal = parcela.getTaxa();
    this.proposta.getTaxaClinte().fator = parcela.getFator();
    this.proposta.getTaxaClinte().valorLiberado = parcela.getValorTotal();
  }

  onChangePrimeiraParcela(data) {
    this.simulacao.setPrimeiraParcela(data);
    this.simular.emit(true);
  }

}
