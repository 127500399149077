export interface RelatorioRequest {
  relatorio: {
    dataFinal: number;
    dataInicial: number;
    lojista: number;
    idRelatorio: number;
    statusPagamento?: StatusPagamento;
    pagamentoRealizado?: boolean;
    parceiro?: {
      id: number;
      chave: string;
      descricao: string;
    };
  };
}

export enum StatusPagamento {
  TODOS = 0,
  PENDENTE = 1,
  REALIZADO = 2
}
