import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

import { Proposta } from '../../../../../shared/models/proposta';

@Component({
  selector: 'app-altera-tabela',
  templateUrl: './altera-tabela.component.html',
  styleUrls: ['./altera-tabela.component.css']
})
export class AlteraTabelaComponent implements OnInit {
  @Input() tabelas: any[];
  @Input() proposta: Proposta;

  formulario: FormGroup;
  onSave: EventEmitter<any> = new EventEmitter();
  alive = true;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.formulario = this.fb.group({
      tabela: [this.proposta.tabela.id, Validators.required]
    });
  }

  get tabelaId() {
    return Number(this.formulario.get('tabela').value);
  }

  save() {
    this.onSave.emit(this.tabelas.filter(t => t.id === this.tabelaId)[0]);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }

}
