import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '../../../directives/directives.module';
import { ServicesModule } from '../../../services/services.module';
import { ModaisModule } from '../../../shared/modais/modais.module';
import { BotaoNavegacaoCleanComponent } from './botao-navegacao-clean/botao-navegacao-clean.component';
import { BotaoNavegacaoComponent } from './botao-navegacao/botao-navegacao.component';
import { ConsultandoDadosComponent } from './consultando-dados/consultando-dados.component';
import { FichaEtapasCadastroComponent } from './ficha-etapas-cadastro/ficha-etapas-cadastro.component';

@NgModule({
  imports: [
    CommonModule,
    ServicesModule,
    FormsModule,
    ReactiveFormsModule,
    ModaisModule,
    DirectivesModule,
  ],
  declarations: [
    BotaoNavegacaoComponent,
    BotaoNavegacaoCleanComponent,
    ConsultandoDadosComponent,
    FichaEtapasCadastroComponent
  ],
  exports: [
    BotaoNavegacaoComponent,
    BotaoNavegacaoCleanComponent,
    FichaEtapasCadastroComponent,
    ConsultandoDadosComponent
  ],
})
export class FichaCadastroComponentesModule { }
