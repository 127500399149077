import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';

import { FichaService } from '../../../ficha-veiculo.service';

@Component({
  selector: 'app-observacao',
  templateUrl: './observacao.component.html',
  styleUrls: ['./observacao.component.css']
})
export class ObservacaoComponent {

  @Input() acoes: any;
  @Input() idFicha: string;
  @ViewChild('textAreaObservacao', { static: false })
  private inputObservacao: ElementRef;

  public observacaoSos: string;
  isObsObrigatorio: boolean;

  constructor(private fichaService: FichaService) { }

  sendObservacao() {

    if (!this.validaEnvioObservacao()) {
      return;
    }

    const body = { idProposta: this.idFicha, observacaoSos: this.observacaoSos };
    return this.fichaService.sendSosObservacao(body)
      .toPromise()
      .then((data) => {
        console.log('Sucesso:\n', data);
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 500 || error.status === 404) {
          console.log(`erro ${error.status}`, error);
          return observableThrowError(new Error(error.status));
        }
        console.log('erro default', error);
      });
  }

  public validaEnvioObservacao() {
    if (this.observacaoSos) {
      this.isObsObrigatorio = false;
      return true;
    }
    this.isObsObrigatorio = true;
    return false;
  }

  public hasObservacao(): boolean {
    return JSON.stringify(this.acoes).includes('GRAVAR_OBS');
  }
}
