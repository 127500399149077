import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface EstadoCivil {
  codigo: string;
  descricao: string;
}

@Injectable()
export class EstadoCivilService {

  constructor(
    private http: HttpClient
  ) { }

  findEstadoCivil(): Observable<EstadoCivil[]> {
    return this.http.get<EstadoCivil[]>('assets/estado-civil.json');
  }

}
