import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from './../../../shared/shared.module';
import { MaskModule } from 'soft-angular-mask';

import { FichaCadastroResultadoParcialComponent } from './ficha-cadastro-resultado-parcial.component';
import { SeguroComponent } from './seguro/seguro.component';
import { SliderRetornoComponent } from './seguro/slider-retorno/slider-retorno.component';
import { ValorFinanciadoComponent } from './valor-financiado/valor-financiado.component';
import { SliderValorFinanciadoComponent } from './valor-financiado/slider-valor-financiado/slider-valor-financiado.component';
import { VeiculoComponent } from './veiculo/veiculo.component';
import { ParcelaComponent } from './parcela/parcela.component';
import { SaibaMaisModule } from './../../../shared/saiba-mais/saiba-mais.module';
import { ModaisModule } from './../../../shared/modais/modais.module';
import { ModalModule } from 'ngx-bootstrap';
import { AlterarValorComponent } from './valor-financiado/alterar-valor/alterar-valor.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { SegurosService } from '../../seguros.service';

@NgModule({
  imports: [
    SharedModule,
    PipesModule,
    DirectivesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaskModule,
    SaibaMaisModule,
    ModaisModule,
    ModalModule.forRoot()
  ],
  declarations: [
    FichaCadastroResultadoParcialComponent,
    SeguroComponent,
    SliderRetornoComponent,
    ValorFinanciadoComponent,
    SliderValorFinanciadoComponent,
    VeiculoComponent,
    ParcelaComponent,
    AlterarValorComponent
  ],
  exports: [
    FichaCadastroResultadoParcialComponent,
    ValorFinanciadoComponent,
    AlterarValorComponent,
    SliderRetornoComponent,
  ],
  providers:[
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    SegurosService,
  ],
  entryComponents: [AlterarValorComponent],
})
export class ResultadoParcialModule { }
