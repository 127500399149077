import { ButtonSelectorData } from '../button-selector/button-selector-data';
import { TipoNegociacaoEnum } from './enum/tipo-negociacao.enum';
import { TipoProdutoEnum } from './enum/tipo-produto.enum';
import { TipoVeiculoEnum } from '../../../../produtos/shared/enums/tipo-veiculo.enum';

export class OperacoesHandler {

  public static handle(values: [{descricao: string}]): ButtonSelectorData[] {
    const operacoes = [];
    values
      .forEach(value => this.handleDescricao(value.descricao, operacoes));
    return operacoes.sort((a, b) => a.order - b.order);
  }

  private static handleDescricao(descricao, operacoes: ButtonSelectorData[]) {
    if (descricao === 'VEÍCULOS') {
      operacoes.push(new ButtonSelectorData(TipoProdutoEnum.FINANCIMENTO, 'Financiamento', 'financiamento', 1));
      operacoes.push(new ButtonSelectorData(TipoProdutoEnum.REFINANCIAMENTO, 'Refinanciamento', 'refinanciamento', 2));
    } else if (descricao === 'CDC LOJA') {
      operacoes.push(new ButtonSelectorData(TipoProdutoEnum.CDC_LOJA, 'CDC Loja', 'cdc-loja', 3));
    } else if (descricao === 'CRÉDITO PESSOAL') {
      operacoes.push(new ButtonSelectorData(TipoProdutoEnum.CREDITO_PESSOAL, 'Crédito Pessoal', 'credito-pessoal', 4));
    } else if (descricao === TipoNegociacaoEnum.DEBITO_AUTOMATICO) {
      operacoes.push(new ButtonSelectorData(descricao, 'Débito Automático', 'icon debito-automatico', 1));
    } else if (descricao === TipoNegociacaoEnum.CARNE) {
      operacoes.push(new ButtonSelectorData(descricao, 'Carnê', 'icon carne', 2));
    } else if (descricao === TipoProdutoEnum.MICROCREDITO) {
      operacoes.push(new ButtonSelectorData(descricao, 'Microcrédito', 'icon microcredito', 4));
    } else if (descricao === TipoProdutoEnum.CDC_PREMIUM) {
      operacoes.push(new ButtonSelectorData(descricao, 'CDC Premium', 'cdc-premium', 3));
    } else if (descricao === TipoProdutoEnum.LEADS) {
      operacoes.push(new ButtonSelectorData(TipoVeiculoEnum.AUTOMOVEIS, 'Automóveis', 'icon automovel', 1));
      operacoes.push(new ButtonSelectorData(TipoVeiculoEnum.MOTOCICLETAS, 'Motocicletas', 'icon motocicleta', 2));
      operacoes.push(new ButtonSelectorData(TipoVeiculoEnum.PESADOS, 'Pesados', 'icon pesado', 3));
    }
  }
}
