import { Proposta } from './../../../../../shared/models/proposta';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-alterar-valor',
  templateUrl: './alterar-valor.component.html',
  styleUrls: ['./alterar-valor.component.css']
})
export class AlterarValorComponent implements OnInit {
  alive: boolean = true;
  alterarValorForm: FormGroup;
  parcelasPersonalizada: number[];
  onSave: EventEmitter<any> = new EventEmitter();

  @Input() proposta: Proposta;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.alterarValorForm = this.fb.group({
      valorFinanciamento: ['', Validators.required]
    });
  }

  get valorFinanciamento() {
    return this.alterarValorForm.get('valorFinanciamento');
  }

  save() {
    this.onSave.emit(this.valorFinanciamento.value);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }

  get valorSolicitado() {
    return this.valorFinanciamento.value * 1;
  }

  get valorDivida() {
    if (!this.proposta.contratoRepique) {
      return 0;
    }

    return this.proposta.contratoRepique.valores.valorCorrigido;
  }

  get valorLiberado() {
    if (!this.valorSolicitado || !this.valorDivida || this.valorDivida > this.valorSolicitado) {
      return 0;
    }

    return this.valorSolicitado - this.valorDivida;
  }

  get formValid() {
    return this.alterarValorForm.valid &&
      (this.valorDivida === 0 || this.valorSolicitado >= this.valorDivida);
  }
}
