import { Data } from '../partner/data';

export function formatDateMiliseconds(data) {
  return Data.toMilissegundos(data);
}

export function formatDate(data) {
  return formatDateMiliseconds(data);
}

export function getFone(data) {
  if (data) {
    return {
      ddd: data.toString().substring(1, 3),
      numero: data.toString().substring(4)
    };
  }
}

export function getFoneEmpresa(data) {
  if (data) {
    return {
      ddd: data.toString().substring(0, 2),
      numero: data.toString().substring(2)
    };
  }
}

export function getFoneArray(data) {
  return Object.keys(data).map(item => {
    if (data[item]) {
      return {
        ddd: data[item].toString().substring(1, 3),
        numero: data[item].toString().substring(4)
      };
    }
  });
}

// ------------------

// ------------------

export function allTelefones(data) {
  return  Object.keys(data).map((item) => {
    return {
      idCategoria: (function () {
        if (item === 'residencial') {
          return '1';
        }
        if (item === 'comercial') {
          return '14';
        }
        if (item === 'celularAdicional') {
          return '11';
        }
        return '3';
      })(),
      ddd: (function () {
        if (data[item]) {
          return data[item].substring(0, 2);
        }
        return '';
      })(),
      numero: (function () {
        if (data[item]) {
          return data[item].substring(2);
        }
        return '';
      })(),
      idTipoTelefone: (function () {
        if (item === 'celular') {
          return '1';
        }
        return '2';
      })(),
    };
  }).filter(x => x.numero !== null && x.numero !== undefined && x.numero !== '');
}

export function isEmptyObj(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}
