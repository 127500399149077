export class Util {

  public convertToUTC(date: string): number {
    if (date) {
      const dateSplitted = date.split('/');
      return Date.parse(`${dateSplitted[1]} ${dateSplitted[0]} ${dateSplitted[2]}`);
    }
  }

  retirarFormatacao(campoTexto) {
    if (campoTexto) {
      return campoTexto.replace(/(\.|\/|\-)/g, '');
    }
  }

  getDdd(tel: string): string {
    if (tel) {
      const telefone = tel.replace(/[^0-9]/g, '');
      if (telefone !== null) {
        if (telefone.length >= 2) {
          return telefone.substring(0, 2);
        }
      }
      return null;
    }
    return tel;

  }

  getFone(tel: string): string {
    if (tel) {
      const telefone = tel.replace(/[^0-9]/g, '');
      if (telefone != null) {
        if (telefone.length > 2) {
          return telefone.substring(2, telefone.length);
        }
      }
      return null;
    }
    return tel;
  }
}
