import { LoginLoja } from './login-loja';
import { LoginAgente } from './login-agente';
import { LoginTipoUsuarioTO } from './login-tipo-usuario-to';
import { LoginPromotor } from './login-promotor';

export class LoginUsuarioTO {
  id: number;
  login: string;
  token: string;
  excedeProducao: string;
  visualizacaoProposta: string;
  agentes: LoginAgente[];
  tipoUsuarioTO: LoginTipoUsuarioTO;
  lojaTO: LoginLoja;
  promotor: LoginPromotor;
  parcerias: Parcerias[];

  constructor(obj) {
    this.id = obj.id;
    this.login = obj.login;
    this.token = obj.token;
    this.excedeProducao = obj.excedeProducao;
    this.agentes = obj.agentes || [];
    this.tipoUsuarioTO = obj.tipoUsuarioTO || {};
    this.lojaTO = obj.lojaTO || {};
    this.visualizacaoProposta = obj.visualizacaoProposta || 'C';
    this.promotor = obj.promotor || {};
    this.parcerias = obj.parcerias || [];
  }

  get isLojista(): boolean {
    return this.tipoUsuarioTO && this.tipoUsuarioTO.id === 7;
  }

  get agentesSelecionar(): any[] {
    if (this.isLojista) {
      return [this.lojaTO.agenteTO];
    }
    return this.agentes;
  }

  get agentesSelecionarSize(): number {
    const ags = this.agentesSelecionar;
    if (ags) {
      return ags.length;
    }
    return 0;
  }
}

export interface Parcerias {
  id:        number;
  descricao: string;
  chave:     string;
}
