import { StorageService } from './../../../services/storage/storage.service';

import { takeWhile, finalize } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginStorage } from '../../../login/login.storage';
import { ModalMessageComponent } from '../../../shared/modais/modal-message/modal-message.component';
import { FichaService } from '../../ficha-veiculo.service';
import { setFichaContent } from '../helpers/salvarFicha';
import { FichaCadastro } from './../ficha-cadastro';

@Component({
  selector: 'app-ficha-cadastro-lojista-vendedor',
  templateUrl: './ficha-cadastro-lojista-vendedor.component.html',
  styleUrls: ['./ficha-cadastro-lojista-vendedor.component.css'],
})
export class FichaCadastroLojistaVendedorComponent
  implements OnInit, OnDestroy
{
  public TIPO_SOLIC_CLIENTE = 'CLIENTE';
  public TIPO_SOLIC_OUTRA = 'OUTRA';

  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;

  @Input()
  fichaCadastro: FichaCadastro;

  alive = true;

  listaLojistas = [];
  listaVendedores = [];
  isListaLojistas = true;
  isListaVendedores = true;
  isVendedorSelected: boolean;

  isSelectedListaLojistaAfterBlur = true;
  isSelectedListaVendedoresAfterBlur = true;
  error: boolean = true;
  lojaVendedor = 0;
  nomeVendedor: any = '';
  idLojista: number;

  constructor(
    private fichaService: FichaService,
    private loginStorage: LoginStorage,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.initLojistaEVendedores();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private initLojistaEVendedores() {
    if (this.fichaCadastro.passo === this.fichaCadastro.PASSO_VENDEDOR) {
      const usuario = this.loginStorage.usuario;
      if (usuario.isLojista) {
        this.fichaCadastro.IDLojista = usuario.lojaTO.id;
        this.loadListaVendedores();
      } else if (
        this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_CLIENTE ||
        this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_OUTRA
      ) {
        this.fichaCadastro.IDLojista = this.loginStorage.agente.lojaBalcaoId;
        this.loadListaVendedores();
      } else {
        this.loadListaLojas();
      }
    }
  }

  nextAvailable(formValid) {
    return this.fichaCadastro.solicitanteTipo === 'VENDEDOR' && formValid;
  }

  loadListaLojas() {
    if (
      this.fichaCadastro.tipoSolicitante === 'VENDEDOR' ||
      this.fichaCadastro.tipoSolicitante === null
    ) {
      this.isListaLojistas = false;
      this.fichaService
        .getLojistaByOperador()
        .pipe(
          finalize(() => (this.isListaLojistas = true)),
          takeWhile(() => this.alive)
        )
        .subscribe(
          (res) => {
            this.listaLojistas = res.lojas;
            this.listaLojistas.sort(this.sortByName);
          },
          () =>
            this.modalMessage.show(
              'A consulta de loja do vendedor não retornou resultados'
            )
        );
    }
  }

  loadListaVendedores() {
    this.fichaCadastro.IDVendedor = '';
    this.isListaVendedores = false;
    this.listaVendedores = [];
    this.fichaService
      .getVendedoresByLojista(this.fichaCadastro.IDLojista)
      .pipe(
        finalize(() => (this.isListaVendedores = true)),
        takeWhile(() => this.alive)
      )
      .subscribe((res) => {
        this.listaVendedores = res.vendedores;
        this.listaVendedores.sort(this.sortByName);
      });
  }

  private sortByName(a, b): number {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
    return 0;
  }

  onChangeListaLojista(event) {
    this.loadListaVendedores();
  }

  onClickPrevious() {
    this.router.navigateByUrl('ficha/nova');
  }

  onClickNext() {
    this.idLojista = this.fichaCadastro.IDLojista;
    if (
      this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_CLIENTE ||
      this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_OUTRA
    ) {
      this.idLojista = this.loginStorage.agente.lojaBalcaoId;
    }
    this.fichaCadastro.setPayload('lojaId', this.idLojista);
    this.fichaCadastro.setPayload('vendedorId', this.fichaCadastro.IDVendedor);
    this.storageService.patchLocal({
      vendedorId: this.fichaCadastro.IDVendedor,
    });
    this.fichaCadastro.onClickNext();
    setFichaContent(this.fichaCadastro);
  }
}
