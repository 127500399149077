export const environment = {
  URL_OMNI_MAIS_API: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/rodas/v1/api/',
  URL_OMNI_MAIS_API_V2: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/rodas/v2/api/',
  URL_OMNI_DOC_UPLOAD: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/doc-upload/api/',
  URL_OMNI_MAIS_VIABILIDADE: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/cob-viabilidade/api/',
  URL_OMNI_FACIL: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/login-omnifacil/api/',
  URL_OMNI_GERAL: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/geral/api/',
  URL_CDC_LOJA: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/cdcl/api/',
  URL_CREDITO_PESSOAL: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/cp/api/',
  URL_OMNI_VEHICLE_REPORT: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/auto-laudo/api/',
  URL_MICROCREDITO: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/cp-mc/api/',
  URL_LEADS_ONLINE: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/leads/api/',
  URL_FINANCEIRO: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/financeiro/api/',
  URL_PARAMETROS_COBRANCA: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/agentes-digitais/parametros/api/v1/',
  URL_MESA_EFETIVE_MAIS: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/mesa-efetive-mais/api/',
  URL_DIGITAL_AGENT: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/agentes-digitais/parametros/api/v1/',
  URL_RODAS: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/rodas/v2/api/',
  production: false,
  VERSION_APP: '2.4.0',
  SHOW_CP: true,
  ECOMMERCE_ENCRYPT_KEY: 'VkN6ZlpIaWNMbU1vQlpJQQ==',
  SIMULACAO_TENTATIVAS: 20,
  URL_OMNI_CDCL_API_GATEWAY: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/cdcl/api/',
  URL_RODAS_FICHA: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/rodas-ficha/api/',
  URL_RODAS_VEICULOS: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/veiculos/api/',
  URL_SEGUROS: 'https://api.hmg-omnicfi.us-east-1.omniaws.io/seguro/api/',
};
