import { Component, Input } from '@angular/core';

import { fichasFilter } from '../../helpers/filter';
import { FichaListaFiltro } from './../model/ficha-lista-filtro';

@Component({
  selector: 'app-ficha-recusada',
  templateUrl: './ficha-recusada.component.html',
  styleUrls: ['./ficha-recusada.component.css']
})
export class FichaRecusadaComponent {

  @Input() propostas = [];
  @Input() filtro: FichaListaFiltro;

  filter() {
    return fichasFilter(this.filtro, this.propostas);
  }

}
