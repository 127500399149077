import { BsModalRef } from 'ngx-bootstrap';
import { Component } from '@angular/core';

@Component({
  selector: 'app-visualizacao-documentacao',
  templateUrl: './visualizacao-documentacao.component.html',
  styleUrls: ['./visualizacao-documentacao.component.css'],
})
export class VisualizacaoDocumentacaoComponent {
  constructor(public modal: BsModalRef) {}
}
