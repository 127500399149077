import { PropostaStateService } from './../../../../../shared/states/proposta-state.service';
import { MicrocreditoService } from './../../../../services/microcredito.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FaefStateService } from './../faef-state.service';
import { Faef, FaefFinanceiro } from './../model/faef';

import * as toastr from 'toastr';

@Component({
  selector: 'app-dados-financeiros',
  templateUrl: './dados-financeiros.component.html',
  styleUrls: ['./dados-financeiros.component.scss']
})
export class DadosFinanceirosComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() loading = new EventEmitter(true);

  financeiroForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private state: FaefStateService,
    private propostaState: PropostaStateService,
    private service: MicrocreditoService
  ) { }

  ngOnInit() {
    this.createForms();

    const faef = this.state.getLast();
    if (faef.faefFinanceiroEmpreendimento) {
      this.financeiroForm.patchValue(faef.faefFinanceiroEmpreendimento, { emitEvent: false });
    }
  }

  private createForms() {
    this.financeiroForm = this.fb.group({
      despesasOperacionaisNegocio: ['', Validators.required],
      despesasAlimentacaoFamilia: ['', Validators.required],
      despesasAluguelResidencial: ['', Validators.required],
      despesasVestuarioFamilia: ['', Validators.required],
      despesasEducacaoFamilia: ['', Validators.required],
      despesasServicosDiversos: ['', Validators.required],
      despesasMoveisEletrodomesticos: ['', Validators.required],
      somaRendaFamiliar: ['', Validators.required],
      somaCaixaBanco: ['', Validators.required],
      contasReceber: ['', Validators.required],
      estoque: ['', Validators.required],
      veiculos: ['', Validators.required],
      maquinasEquipamentos: ['', Validators.required],
      dividaFornecedores: ['', Validators.required],
      contasPagar: ['', Validators.required]
    });
  }

  async onClickNext() {
    this.updateState();
    await this.calculateFaef();
    this.next.emit();
  }

  onClickPrevious() {
    this.updateState();
    this.previous.emit();
  }

  private updateState() {
    const faef:Faef = this.state.getLast();
    const financeiro = <FaefFinanceiro> this.financeiroForm.value;
    faef.faefFinanceiroEmpreendimento = {
      despesasOperacionaisNegocio: Number(financeiro.despesasOperacionaisNegocio),
      despesasAlimentacaoFamilia: Number(financeiro.despesasAlimentacaoFamilia),
      despesasAluguelResidencial: Number(financeiro.despesasAluguelResidencial),
      despesasVestuarioFamilia: Number(financeiro.despesasVestuarioFamilia),
      despesasEducacaoFamilia: Number(financeiro.despesasEducacaoFamilia),
      despesasServicosDiversos: Number(financeiro.despesasServicosDiversos),
      despesasMoveisEletrodomesticos: Number(financeiro.despesasMoveisEletrodomesticos),
      somaRendaFamiliar: Number(financeiro.somaRendaFamiliar),
      somaCaixaBanco: Number(financeiro.somaCaixaBanco),
      contasReceber: Number(financeiro.contasReceber),
      estoque: Number(financeiro.estoque),
      veiculos: Number(financeiro.veiculos),
      maquinasEquipamentos: Number(financeiro.maquinasEquipamentos),
      dividaFornecedores: Number(financeiro.dividaFornecedores),
      contasPagar: Number(financeiro.contasPagar)
    };

    this.state.setState(faef);
  }

  private async calculateFaef() {
    try {
      this.loading.emit(true);
      const faef = await this.service.calculate(this.state.getLast(), this.propostaState.getLast().cliente);
      this.state.setState(faef);
    } catch (error) {
      console.log(error);
      toastr.error('Não foi possível efetuar o cálculo do FAEF', 'Falha ao comunicar com servidor');
      throw error;
    } finally {
      this.loading.emit(false);
    }
  }

}
