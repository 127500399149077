import * as moment from 'moment';
import { Periodo } from './periodo';

export class Ano implements Periodo {

  padrao = false;

  descricao = 'Ano';

  constructor(padrao?: boolean) {
    this.padrao = padrao || false;
  }

  get dataInicio(): number {
    return moment().startOf('year').unix() * 1000;
  }

  get dataFim(): number {
    return moment.now();
  }
}
