import { IdDescription } from './../../../../shared/models/id-description';
import { TipoMoradiaService } from './../../../../../services/tipo-moradia/tipo-moradia.service';
import { Component, Input, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { CepService } from '../../../../../services/cep/cep.service';
import { EnderecoCep } from '../../../../../services/cep/endereco-cep';
import { CidadeService } from '../../../../../services/cidade/cidade.service';
import { UfService } from '../../../../../services/uf/uf.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';

@Component({
  selector: 'app-endereco-base',
  templateUrl: './endereco-base.component.html',
  styleUrls: ['./endereco-base.component.css']
})
export class EnderecoBaseComponent implements OnInit, OnDestroy {
  @Input() tipoEndereco: string;
  @Input() avalista: boolean = false;
  @Input() enderecoForm: FormGroup;
  @Input() avalistaNumero = -1;
  @Output() repetirEndResidencialEvent = new EventEmitter<boolean>();
  currentForm: FormGroup;

  @ViewChild('cepInvalidoModal', { static: true }) cepInvalidoModal: NewModalMessageComponent;

  public ufs: any;
  public cidades: any;
  public hasLogradouro: boolean = false;
  public hasBairro: boolean = false;
  public cepUnico: boolean = false;
  public isBuscandoCep: boolean = false;
  public exibeCampos: boolean = false;
  public tiposMoradias: IdDescription[] = [];
  public repetirEnderecoResidencial: boolean = false;

  private isAlive: boolean = true;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private cidadeService: CidadeService,
    private tipoMoradiaService: TipoMoradiaService,
    private ufService: UfService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.initObservers();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initForm(): void {
    const tipoMoradia = new FormControl('');
    const tempoResidencia = new FormControl('');

    if (this.isResidencial && !this.isAvalista) {
      tipoMoradia.setValidators(Validators.required);
      tempoResidencia.setValidators(Validators.required);
      this.initTiposMoradia();
    }

    this.currentForm = this.fb.group({
      tipoMoradia,
      tempoResidencia,
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      uf: [{ value: '', disabled: true }, Validators.required],
      cidade: [{ value: '', disabled: true }, Validators.required],
      tipoEndereco: [`${this.tipoEndereco.toUpperCase()}`],
      correspondencia: [false],
      referencia: [''],
    });

    this.enderecoForm.addControl(`${this.tipoEndereco}Form`, this.currentForm);
  }

  private async initTiposMoradia() {
    try {
      const response = await this.tipoMoradiaService.listar();
      this.tiposMoradias = response.entities;
    } catch (error) {
      console.error(error);
      toastr.error('Tente recarregar proposta para carregar esta informação', 'Falha ao listar tipo de moradia');
    }
  }

  private initObservers(): void {
    this.fillUfs();

    this.uf
      .valueChanges.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(res => this.fillCidades(res));

    this.cep
      .valueChanges.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => this.handleOnCepChange());
  }

  private handleOnCepChange(): void {
    if (this.cep.valid) {
      this.isBuscandoCep = true;
      this.cepService
        .findEnderecoByCep(this.cep.value.replace(/[^\d]/g, ''))
        .pipe(
          finalize(() => {
            this.isBuscandoCep = false;
            this.exibeCampos = true;
          }),
          take(1)
        )
        .subscribe(
          (res) => {
            this.fillEndereco(res);
            this.hasLogradouro = res.logradouro !== '' ?  true : false;
            this.hasBairro = res.bairro !== '' ?  true : false;
            this.cepUnico = !!(res.cepUnico);
          },
          (error) => this.handleCepError(error)
        );
    }
  }

  private fillEndereco(res: EnderecoCep): void {
    this.currentForm.patchValue({
      logradouro: res.logradouro,
      bairro: res.bairro,
      uf: res.uf,
      cidade: res.cidade,
      tipoEndereco: this.tipoEndereco.toUpperCase()
    });
  }

  private handleCepError(error: any) {
    if (error.error) {
      this.cepInvalidoModal.showModal({
        titulo: 'Erro',
        mensagem: error.error.message
      });
    }
    this.resetForm();
  }

  private resetForm(): void {
    this.currentForm.reset();
    this.uf.setValue('');
    this.cidade.setValue('');
    this.currentForm.controls.correspondencia.setValue(false);
  }

  private fillUfs(): void {
    this.ufService
      .ufs().pipe(
      take(1))
      .subscribe(res => this.ufs = res);
  }

  private fillCidades(uf: string): void {
    this.cidadeService
      .cidades(uf).pipe(
      take(1))
      .subscribe(res => this.cidades = res);
  }

  get cep(): AbstractControl {
    return this.currentForm.get('cep');
  }

  get endereco(): AbstractControl {
    return this.currentForm.get('logradouro');
  }

  get numero(): AbstractControl {
    return this.currentForm.get('numero');
  }

  get complemento(): AbstractControl {
    return this.currentForm.get('complemento');
  }

  get bairro(): AbstractControl {
    return this.currentForm.get('bairro');
  }

  get uf(): AbstractControl {
    return this.currentForm.get('uf');
  }

  get cidade(): AbstractControl {
    return this.currentForm.get('cidade');
  }

  get tempoResidencia(): AbstractControl {
    return this.currentForm.get('tempoResidencia');
  }

  get isResidencial() {
    return this.tipoEndereco === 'residencial' && this.avalistaNumero < 1;
  }

  get isAvalista() {
    return this.avalista;
  }

  get referencia(): AbstractControl {
    return this.currentForm.get('referencia');
  }
  public repetirEndereco(data: boolean) {
    this.repetirEndResidencialEvent.emit(data);
  }
}
