
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CidadeService {

  constructor(
    private http: HttpClient
  ) { }

  cidades(uf: string): Observable<any> {
    return this.http.get('assets/dados/estados-cidades.json').pipe(
      map((res: any[]) => res.filter(data => data.sigla === uf)[0].cidades));
  }
}
