export enum FichaVeiculoStatus {
    RESULTADO_PARCIAL_PENDENTE = 15,
    RESULTADO_PARCIAL = 1,
    AGUARDANDO_ANALISE_DIRETA = 2,
    EM_ANALISE_DIRETA = 3,
    RECUSADA = 12,
    APROVADA = 4,
    DOCUMENTACAO_NECESSARIA = 5,
    DOCUMENTACAO_ENVIADA = 6,
    EM_ANALISE = 7,
    RECUSADA_FORMALIZACAO = 13,
    PAGAMENTO_AGENDADO = 10,
    PAGAMENTO_REALIZADO = 11,
    CONTRATO_DISPONÍVEL = 14,
    EM_CONSULTA = 16,
    EM_PREENCHIMENTO = 18,
    EMISSAO_DO_CONTRATO = 20,
    CANCELAMENTO_ASSINATURA = 28,
    ASSINATURA_DOCUMENTOS = 21,
    EMISSAO_PRE_CONTRATO = 27,
    INCLUSAO_GRAVAME = 25
}
