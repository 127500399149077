import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdcLojaRestService } from './cdc/cdc-loja-rest.service';
import { CreditoPessoalRestService } from './cp/credito-pessoal-rest.service';
import { LeadsOnlineRestService } from './leads/leads-online-rest.service';
import { MicrocreditoRestService } from './microcredito/microcredito-rest.service';
import { OmniFacilRestService } from './omni-facil/omni-facil-rest.service';
import { OmniGeralRestService } from './omni-geral/omni-geral-rest.service';
import { OmniGiroMaisRecusadaRestService } from './omni-giro-mais-recusada/omni-giro-mais-recusada-rest.service';
import { OmniMaisRestService } from './omni-mais/omni-mais-rest.service';
import { OmniMaisV2RestService } from './omni-mais/omni-mais-v2-rest.service';
import { OmniVehicleReportRestService } from './omni-vehicle-report/omni-vehicle-report-rest.service';
import { OmniVerificacaoSegurancaService } from './omni-verificacao-seguranca/omni-verificacao-seguranca.service';
import { OmniWsRestService } from './omni-ws/omni-ws-rest.service';
import { SegurosRestService } from './seguros/seguros-rest.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    OmniWsRestService,
    OmniFacilRestService,
    OmniGeralRestService,
    OmniMaisRestService,
    OmniGiroMaisRecusadaRestService,
    CdcLojaRestService,
    CreditoPessoalRestService,
    OmniVehicleReportRestService,
    OmniVerificacaoSegurancaService,
    MicrocreditoRestService,
    LeadsOnlineRestService,
    OmniMaisV2RestService,
    SegurosRestService
  ]
})
export class OmniRestModule { }
