import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { appSettings as globals } from '../../../../../../../../../environments/app.setings';
import { ModalErroValidacaoComponent } from '../../../modal-erro-validacao/modal-erro-validacao.component';
import { ModalValidandoComponent } from '../../../modal-validando/modal-validando.component';
import { ModalDicaDeFotoComponent } from '../modal-dica-de-foto/modal-dica-de-foto.component';
import { ModalInfoAppOmniComponent } from '../modal-info-app-omni/modal-info-app-omni.component';

@Component({
  selector: 'app-captura-foto',
  templateUrl: './captura-foto.component.html',
  styleUrls: ['./captura-foto.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CapturaFotoComponent),
      multi: true
    }
  ]
})
export class CapturaFotoComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() isLojistaPiloto: boolean;
  @Input() propostaId: number;
  @Input() isEnabledFotoAndEnviar = true;
  @Input() celularFoiValidado = false;

  @Output() showLoader = new EventEmitter<boolean>();
  @Output() cancelarBiometria = new EventEmitter<void>();
  @Output() excluirFicha = new EventEmitter<void>();
  @Output() finalizarBiometria = new EventEmitter<void>();

  readonly ACCEPT_IMAGES = globals.ACCEPT_IMAGES;
  hasWebcam: boolean = false;
  showWebcam: boolean = true;
  tirouFoto: boolean = false;
  webcamTrigger: Subject<void> = new Subject<void>();

  numeroTentativasBiometria: number = 3;
  hasErrorBiometriaAssessment: boolean = false;
  biometriaAprovada: boolean = false;
  selfiePreview;

  isAlive: boolean = true;

  onChange: any = () => { };
  onTouch: any = () => { };

  constructor(private modal: BsModalService) { }

  ngOnInit() {
    this.initWebcam();
  }

  writeValue(obj: WebcamImage | File): void {
    this.selfiePreview = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private initWebcam() {
    WebcamUtil.getAvailableVideoInputs()
      .then(
        (mediaDevices: MediaDeviceInfo[]) => {
          this.hasWebcam = mediaDevices && mediaDevices.length > 0;
          this.showLoader.emit(false);

          if (!this.hasWebcam) {
            this.cancelarBiometria.emit();
          }
        }
      ).catch((_) => {
        this.showLoader.emit(false);
      });
  }

  handleImageCapture(imagem: WebcamImage | File): void {
    if (this.isLojistaPiloto) {
      this.tirouFoto = true;
      this.openModalValidando(imagem);
    }

    this.previewSelfie(imagem);
    this.onChange(imagem);
  }

  onClickTirarUmaFoto(): void {
    this.showWebcam = !this.showWebcam;
    this.webcamTrigger.next();
  }

  onClickTirarOutraFoto(): void {
    this.hasErrorBiometriaAssessment = false;
    if (this.numeroTentativasBiometria === 0) {
      this.numeroTentativasBiometria = 3;
    }

    if (this.hasWebcam) {
      this.showWebcam = !this.showWebcam;
      this.onChange(null);
    }
  }

  async previewSelfie(imagem) {
    const imgFile = imagem;
    if (!imgFile) {
      this.selfiePreview = null;
    } else if (imgFile instanceof WebcamImage) {
      this.selfiePreview = imgFile.imageAsDataUrl;
    } else {
      this.selfiePreview = await this.readFileAsDataURL(imgFile);
    }
  }

  private readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (_) => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });
  }

  openModalValidando(imagem) {
    const imgFile = imagem;
    let photo: string;

    if (this.tirouFoto && imgFile instanceof WebcamImage) {
      photo = imgFile.imageAsBase64;
    }

    const initialState = {
      foto: photo,
      proposta: this.propostaId
    };

    const modal = this.modal.show(ModalValidandoComponent, { ...globals.MODAL_PARAMS, initialState });
    const validou = <ModalValidandoComponent>modal.content;

    validou.onValidar
      .pipe(
        takeWhile(() => validou.alive)
      ).subscribe(retorno => {
        if (retorno) {
          this.biometriaAprovada = true;
          return;
        }
        this.numeroTentativasBiometria--;

        if (this.numeroTentativasBiometria === 0) {
          this.hasErrorBiometriaAssessment = false;
          return;
        }
        this.handleErrorBiometriaUnico();
      });
  }

  private handleErrorBiometriaUnico() {
    this.hasErrorBiometriaAssessment = true;
    this.openModalErroValidacao();
  }

  private openModalErroValidacao() {
    const initialState = {
      retornoAssessment: 'Erro ao realizar biometria. Por favor aproxime-se da câmera, centralize o rosto na área de captura e tente novamente!'
    };
    this.modal.show(ModalErroValidacaoComponent, { ...globals.MODAL_PARAMS, initialState });
  }

  onClickSaibaMais() {
    this.openModalErroValidacao();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  abrirModalDicaDeFoto() {
    this.modal.show(ModalDicaDeFotoComponent, { ...globals.MODAL_PARAMS });
  }

  finalizar() {
    this.finalizarBiometria.emit();
  }

  excluir() {
    this.excluirFicha.emit();
  }

  abrirModalSobreAppOmniMais() {
    this.modal.show(ModalInfoAppOmniComponent, { ...globals.MODAL_PARAMS });
  }
}
