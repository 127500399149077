import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';

@Injectable()
export class VendedorService {

  constructor(private restService: OmniMaisRestService) { }

  vendedoresByLojista(idLojista): Observable<any> {
    return this.restService.get(`vendedor/listByLojista/${idLojista}`);
  }
}
