import { Component, EventEmitter, Output, OnChanges, Input } from '@angular/core';
import { FichaService } from '../../../ficha-veiculo.service';
import { Motivo } from './motivo';

@Component({
  selector: 'app-select-motivo',
  templateUrl: './select-motivo.component.html',
  styleUrls: ['./select-motivo.component.css']
})
export class SelectMotivoComponent implements OnChanges {

  @Input() motivos: Motivo[];

  motivoDescricao: string;
  isMotivo = false;
  motivosSelecionar: Motivo[] = [];
  isShowMotivos = false;

  @Output() motivoChange: EventEmitter<number> = new EventEmitter();

  constructor(private fichaService: FichaService) { }

  ngOnChanges() {
    this.motivoDescricao = '';
    this.motivosSelecionar = this.motivos;
  }

  showHideMotivos() {
    this.isShowMotivos = !this.isShowMotivos;
    if (this.isShowMotivos && this.motivoDescricao) {
      this.filtrarMotivos(this.motivoDescricao);
    }
  }

  hideMotivos() {
    this.isShowMotivos = false;
  }

  onChangeMotivoDescricao(value) {
    this.filtrarMotivos(value);
    if (this.isMotivo) {
      this.isMotivo = false;
      this.motivoChange.emit(null);
    }
  }

  private filtrarMotivos(descricao) {
    this.isShowMotivos = true;
    this.motivosSelecionar = this.motivos.filter(motivo => motivo.descricao.toUpperCase().includes((descricao || '').toUpperCase()));
  }

  selectMotivo(motivo: Motivo) {
    this.motivoChange.emit(motivo.id);
    this.motivoDescricao = motivo.descricao;
    this.isMotivo = true;
    this.isShowMotivos = false;
  }
}
