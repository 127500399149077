import { Avalista } from '../avalista';
import { TelefoneCliente } from '../telefone-cliente';
import { Referencia } from '../referencia';
import { DadosProfissionais } from '../../../../shared/dados-profissionais/model/dados-profissionais';
import { Endereco } from '../endereco-cliente';
export class RecuperaAvalista {
  private avalista = new Avalista();

  constructor(data) {
    this.avalista.id = data.id;
    this.avalista.cpf = data.cpf;
    this.avalista.dataNascimento = new Date(data.dataNascimento).toLocaleDateString('pt-BR');
    this.avalista.nome = data.nome;
    this.avalista.email = data.email;
    this.avalista.nomeMae = data.nomeMae;
    this.avalista.nomePai = data.nomePai;
    this.avalista.idTipoDocumento = (data.documento || {}).tipo;
    this.avalista.rgIe = (data.documento || {}).numero;
    this.avalista.rgIeEmissor = (data.documento || {}).emissor;
    this.avalista.rgIeUf = (data.documento || {}).uf;
    this.avalista.rgIeData = new Date((data.documento || {}).dataEmissao).toLocaleDateString('pt-BR');
    this.avalista.idEstadoCivil = (data.estadoCivil || {}).id;
    this.avalista.idNacionalidade = (data.nacionalidade || {}).id;
    this.avalista.naturalDe = (data.natural || {}).cidade;
    this.avalista.naturalDeUf = (data.natural || {}).uf;
    this.avalista.clientePpe = data.ppe;
    this.avalista.dadoProfissional = this.profissional(data.profissional);
    this.avalista.avalistaReferencia = new Referencia(this.referencia(data.referencias));
    this.avalista.enderecos = this.endereco(data.enderecos);
    this.avalista.enderecoCorrespondencia = new Endereco(data.enderecos);
    this.avalista.telefones = new TelefoneCliente(data.telefones);

    if (data.profissional && data.profissional.length) {
      this.avalista.renda = data.profissional[0].salario;
    }
  }

  get $avalista() {
    return this.avalista;
  }
  profissional(data) {
    if (data) {
      let idClasseProfissional;
      let idProfissao;
      let nomeEmpresa;
      let telefone;
      let endereco;
      data.forEach(item => {
        if (item.classeProfissional) {
          idClasseProfissional = item.classeProfissional.id;
        }
        if (item.profissao) {
          idProfissao = item.profissao.id;
        }
        if (item.nome) {
          nomeEmpresa = item.nome;
        }
        if (item.telefoneEmpresa) {
          telefone = item.telefoneEmpresa;
        }
        if (item.endereco) {
          endereco = item.endereco;
        }
      });
      return DadosProfissionais.of(idClasseProfissional, idProfissao, nomeEmpresa, telefone, endereco);
    }
    return DadosProfissionais.of(0, 0, '', '', new Endereco());
  }

  referencia(data) {
    if (data) {
      return data.forEach(item => {
        return {
          nome: item.nome,
          telefone: item.telefone,
          idGrauRelacionamento: item.tipo.id
        };
      });
    }
    return null;
  }

  endereco(data) {
    if (data) {
      data.forEach(item => {
        if (item.tipo.id === 2) {
          this.avalista.dadoProfissional.empresa.endereco = item;
        }
      });
      return new Endereco(data);
    }
  }
}
