import { DadosProfissionaisModule } from './../../../shared/dados-profissionais/dados-profissionais.module';
import { NacionalidadeModule } from './../../../shared/nacionalidade/nacionalidade.module';
import { TextDirectivesModule } from './../../../directives/texto/text.directives.module';
import { DirectivesModule } from './../../../directives/directives.module';
import { FichaModalAvalistaComponent } from './ficha-modal-avalista.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaskModule } from 'soft-angular-mask';
import { SharedModule } from '../../../shared/shared.module';
import { EnderecoModule } from '../../../shared/endereco/endereco.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    MaskModule,
    SharedModule,
    TextDirectivesModule,
    NacionalidadeModule,
    EnderecoModule,
    DadosProfissionaisModule
  ],
  declarations: [
    FichaModalAvalistaComponent
  ],
  exports: [
    FichaModalAvalistaComponent
  ],
  entryComponents: [FichaModalAvalistaComponent]
})
export class FichaModalAvalistaModule { }
