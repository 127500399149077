import { Component, Input } from '@angular/core';

import { FichaEstapasCadastro } from './ficha-etapas-cadastro';

@Component({
  selector: 'app-ficha-etapas-cadastro',
  templateUrl: './ficha-etapas-cadastro.component.html',
  styleUrls: ['./ficha-etapas-cadastro.component.css']
})
export class FichaEtapasCadastroComponent {
  @Input() titulo;
  @Input() etapas: FichaEstapasCadastro[];
  @Input() etapaAtual: FichaEstapasCadastro;

  fichaCadastro = {
    passo: 1,
    getAvalista() {
      return true;
    }
  };

  get etapasAtivas() {
    if (!this.etapas) return [];
    return this.etapas.filter(e => !e.hide);
  }

  showSubetapas(etapa) {
    return etapa.numero === Math.trunc(this.etapaAtual.numero) && etapa.subetapas;
  }
}
