import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-erro',
  templateUrl: './modal-erro.component.html',
  styleUrls: ['./modal-erro.component.scss']
})
export class ModalErroComponent implements OnDestroy {

  form: FormGroup;
  onChangeDigitarNovamente = new EventEmitter();

  alive = true;

  constructor(
    private instance: BsModalRef
  ) { }

  ngOnDestroy() {
    this.alive = false;
  }

  close() {
    this.instance.hide();
  }

  onClickConfirm() {
    this.onChangeDigitarNovamente.emit();
    this.close();
  }
}
