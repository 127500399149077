import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfiguracaoComponent } from './configuracao/configuracao.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FichaCadastroComponent } from './ficha-veiculo/ficha-cadastro/ficha-cadastro.component';
import { FichaListaComponent } from './ficha-veiculo/ficha-lista/ficha-lista.component';
import { FichaTimelineComponent } from './ficha-veiculo/ficha-timeline/ficha-timeline.component';
import { InicioComponent } from './ficha/cadastro/inicio/inicio.component';
import { AuthenticationComponent } from './login/authentication/authentication.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login/login.guard';
import { PromotorGuard } from './services/guards/promotor.guard';
import { TipoLojistaGuard } from './services/guards/tipo-lojista.guard';
import { SimuladorComponent } from './simulador/simulador.component';
import { ViabilidadeGuard } from './viabilidade-agente/viabilidade.guard';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'authentication', component: AuthenticationComponent },
  {
    path: 'ficha',
    component: FichaListaComponent,
    canActivate: [LoginGuard, PromotorGuard, TipoLojistaGuard],
  },
  {
    path: 'timeline',
    component: FichaTimelineComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'nova',
    component: InicioComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'resultadoparcial/:id',
    component: FichaCadastroComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'nova-ficha-veiculo/:produto/:solicitante',
    component: FichaCadastroComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'novaplaca/:id',
    component: FichaCadastroComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'informargarantia/:id',
    component: FichaCadastroComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'meu-desempenho',
    component: DashboardComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'configuracoes',
    component: ConfiguracaoComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'ficha/nova',
    component: InicioComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'produtos/cdcloja',
    loadChildren: () =>
      import('./produtos/cdc-loja/cdc-loja.module').then(
        (m) => m.CdcLojaModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'produtos/cp',
    loadChildren: () =>
      import('./produtos/cp/cp.module').then((m) => m.CpModule),
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'simulador',
    component: SimuladorComponent,
    canActivate: [LoginGuard, PromotorGuard],
  },
  {
    path: 'verificacao-seguranca/:tipoComunicacao/:chave',
    loadChildren: () =>
      import(
        './ficha-veiculo/ficha-verificacao-seguranca/ficha-verificacao-seguranca.module'
      ).then((m) => m.FichaVerificacaoSegurancaModule),
  },
  {
    path: 'simulador-veiculo',
    loadChildren: () =>
      import('./simulador-veiculo/simulador-veiculo.module').then(
        (m) => m.SimuladorVeiculoModule
      ),
  },
  {
    path: 'ecommerce/verificacao-seguranca',
    loadChildren: () =>
      import(
        './produtos/cdc-loja/verificacao-seguranca/verificacao-seguranca.module'
      ).then((m) => m.VerificacaoSegurancaModule),
  },
  {
    path: 'produtos/leads',
    loadChildren: () =>
      import('./produtos/leads/leads.module').then((m) => m.LeadsOnlineModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'efetive-mais/:login/:token/:agente',
    loadChildren: () =>
      import('./efetive-mais/efetive-mais.module').then(
        (m) => m.EfetiveMaisModule
      ),
  },
  {
    path: 'giro-mais/:login/:token/:agente',
    loadChildren: () =>
      import('./giro-mais/giro-mais.module').then((m) => m.GiroMaisModule),
  },
  {
    path: 'parametro-cobranca/:login/:token/:agente',
    loadChildren: () =>
      import('./parametros-cobranca/parametros-cobranca.module').then(
        (m) => m.ParametrosCobrancaModule
      ),
  },
  {
    path: 'efetive-mais/:login/:token/:agente',
    loadChildren: () =>
      import('./efetive-mais/efetive-mais.module').then(
        (m) => m.EfetiveMaisModule
      ),
  },
  {
    path: 'giro-mais/:login/:token/:agente',
    loadChildren: () =>
      import('./giro-mais/giro-mais.module').then((m) => m.GiroMaisModule),
  },
  {
    path: 'viabilidade',
    loadChildren: () =>
      import('./viabilidade-agente/viabilidade-agente.module').then(
        (m) => m.ViabilidadeAgenteModule
      ),
    canActivateChild: [ViabilidadeGuard],
  },
  {
    path: 'verificacao-seguranca',
    loadChildren: () =>
      import(
        './produtos/cdc-loja/verificacao-seguranca/verificacao-seguranca.module'
      ).then((m) => m.VerificacaoSegurancaModule),
  },
  {
    path: 'pesquisa-agente-cep/:login/:token',
    loadChildren: () =>
      import('./pesquisa-agente-cep/pesquisa-agente-cep.module').then(
        (m) => m.PesquisaAgenteCepModule
      ),
  },
  {
    path: 'ficha-cadastral/:id/:login/:token',
    loadChildren: () =>
      import('./agentes/agentes.module').then((m) => m.AgentesModule),
  },
  {
    path: 'verificacao-seguranca',
    loadChildren: () =>
      import(
        './produtos/cdc-loja/verificacao-seguranca/verificacao-seguranca.module'
      ).then((m) => m.VerificacaoSegurancaModule),
  },
  {
    path: 'pesquisa-agente-cep/:login/:token',
    loadChildren: () =>
      import('./pesquisa-agente-cep/pesquisa-agente-cep.module').then(
        (m) => m.PesquisaAgenteCepModule
      ),
  },
  {
    path: 'verificacao-seguranca/aceite-cliente/:tipoComunicacao/:chave',
    loadChildren: () =>
      import('./customer-acceptance/customer-acceptance.module').then(
        (m) => m.CustomerAcceptanceModule
      ),
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
