import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UfService {

  constructor(private http: HttpClient) { }

  public ufs() {
    return this.http.get('assets/dados/uf.json');
  }

}
