import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';

import { Proposta } from '../../../../shared/models/proposta';
import { CpfCnpj } from '../../../../../shared/partner/cpf-cnpj';
import { TipoNegociacaoEnum } from '../../../../shared/enums/tipo-negociacao.enum';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';

@Component({
  selector: 'app-analise',
  templateUrl: './analise.component.html',
  styleUrls: ['./analise.component.css']
})
export class AnaliseComponent implements OnInit, OnDestroy {
  @Input() formUpdateEmitter: EventEmitter<any>;
  proposta: Proposta;
  isAlive: boolean = true;

  avalistas = [];

  constructor(
    private state: PropostaStateService
  ) {}

  ngOnInit() {
    this.proposta = this.state.getLast();
    if (this.hasAvalistas) {
      this.avalistas = Array.from((Object as any).values(this.proposta.avalistas));
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  get produtoEscolhido() {
    if (this.proposta) {
      switch (this.proposta.operacao.produto) {
        case TipoNegociacaoEnum.CARNE:
          return 'Crédito Pessoal - Carnê';
        case TipoNegociacaoEnum.DEBITO_AUTOMATICO:
          return 'Crédito Pessoal - Débito Automático ';
        default:
          return this.proposta.operacao.produto;
      }
    }

    return '';
  }

  get cpfCliente() {
    return CpfCnpj.formatWithMask(this.proposta.cliente.cpf);
  }

  getCpfAvalista(cpf) {
    return CpfCnpj.formatWithMask(cpf);
  }

  get nomeCliente() {
    return this.proposta.cliente.nomeCliente.toLowerCase();
  }

  get cpfFavorecido() {
    if (this.isFavorecido) {
      return CpfCnpj.formatWithMask(this.proposta.favorecido.cpfCnpj);
    }
    return '';
  }

  get nomeFavorecido() {
    if (this.isFavorecido) {
      return this.proposta.favorecido.nomeFavorecido.toLowerCase();
    }
    return '';
  }

  get isFavorecido() {
    return (
      this.proposta &&
      this.proposta.favorecido &&
      this.proposta.favorecido.cpfCnpj
    );
  }

  get hasAvalistas(): boolean {
    return (
      this.proposta &&
      this.proposta.avalistas &&
      Array.from((Object as any).values(this.proposta.avalistas)).length > 0
    );
  }
}
