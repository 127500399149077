import { GrauInstrucaoService } from './../../../../../services/grau-instrucao/grau-instrucao.service';
import { IdDescription } from './../../../../shared/models/id-description';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { debounceTime, finalize, take, takeWhile } from 'rxjs/operators';
import * as moment from 'moment';
import { Cliente, ClienteReferencia, ClienteTelefone, } from '../../../../shared/models/cliente';
import { BancoService } from '../../../../../services/banco/banco.service';
import { ClasseProfissionalService } from '../../../../../services/classe-profissional/classe-profissional.service';
import { EstadoCivilService } from '../../../../../services/estado-civil/estado-civil.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TipoNegociacaoEnum } from '../../../../shared/enums/tipo-negociacao.enum';
import { ProfissaoService } from './../../../../../services/profissao/profissao.service';
import { Proposta } from '../../../../shared/models/proposta';
import { CreditoPessoalService } from '../../../services/cp.service';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { Select2OptionData } from 'ng2-select2/ng2-select2.interface';
import { Data } from '../../../../../shared/partner/data';

@Component({
  selector: 'app-detalhes-cliente',
  templateUrl: './detalhes-cliente.component.html',
  styleUrls: ['./detalhes-cliente.component.css'],
})
export class DetalhesClienteComponent implements OnInit, OnDestroy {

  @Input() tipoNegociacaoEnum: TipoNegociacaoEnum;
  @Input() avalistaNumero = 0;
  @Input() banco: string;
  @ViewChild('modalMensagem', { static: true })
  modalMensagem: NewModalMessageComponent;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  detalhesClienteForm: FormGroup;
  conjugeForm: FormGroup;
  dadosFavorecidoForm: FormGroup;

  showLoader: boolean = false;
  isAlive: boolean = true;
  isCpfCnpjReadOnly: boolean = false;
  isFiltroBancoReadOnly: boolean = false;

  isConfirmadoTelefone: boolean = false;
  telefones: number[] = [];
  removableTelefonesCount: number = 0;

  referencias: number[] = [];
  removableReferenciasCount: number = 0;

  dataRange: Object = {
    min: 18,
    max: 85,
    message: 'Data inválida',
  };

  estadosCivis: any;
  classesProfissionais: any = [];
  profissoes: any = [];
  profissoesConjuge: any[];
  bancos: any[];
  bancoSelecionadoDescricao: string;

  grauInstrucaoList: IdDescription[];
  proposta: Proposta;

  perfilCliente: string;

  private PRODUTO_CREDITO_PESSOAL_DEBITO_AUTOMATICO: string = 'CRÉDITO PESSOAL-DA';

  public exampleData: Select2OptionData[];

  constructor(
    private fb: FormBuilder,
    private estadoCivilService: EstadoCivilService,
    private classeProfissionalService: ClasseProfissionalService,
    private grauInstrucaoService: GrauInstrucaoService,
    private profissaoService: ProfissaoService,
    private bancoService: BancoService,
    private changeDetectorRef: ChangeDetectorRef,
    private state: PropostaStateService,
    private cpService: CreditoPessoalService
  ) { }

  ngOnInit() {
    this.proposta = this.state.getLast();
    if (this.avalistaNumero === 0) {
      this.proposta.motivoRecusaAssinaturaDigital = null;
    }
    this.initForms();
    this.initDefaultObservers();
    this.fillForms();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private _hasConjuge: any = false;
  get hasConjuge(): any {
    return this._hasConjuge;
  }

  set hasConjuge(value: any) {
    const isMarried = [1, 6, 7, 8];
    if (isMarried.includes(+value)) {
      this.setFormConjuge();
      this._hasConjuge = true;
    } else {
      this.detalhesClienteForm.removeControl('conjuge');
      this._hasConjuge = false;
    }
  }

  private setFormConjuge(): void {
    const conjuge: Cliente = this.proposta.conjuge || {
      dadosProfissionais: {},
      telefones: [{}],
    };
    let classeProfissionalConjuge;
    let profissaoConjuge;
    let empresaConjuge = '';
    if (conjuge.dadosProfissionais) {
      classeProfissionalConjuge = (
        conjuge.dadosProfissionais.classeProfissional || {}
      ).id;
      profissaoConjuge = (conjuge.dadosProfissionais.profissao || {}).id;
      empresaConjuge = conjuge.dadosProfissionais.nomeEmpresa;
    }
    const fone = (this.proposta.conjuge.telefones || [])[0] || {};
    const telefoneEmpresaConjuge = this.formatFone(fone.ddd, fone.nrTelefone);
    this.conjugeForm = this.fb.group({
      cpf: [conjuge.cpf, Validators.required],
      dataNascimento: [moment(new Date(this.proposta.conjuge.dtNascimento)).format('DD/MM/YYYY')],
      classeProfissionalConjuge: [classeProfissionalConjuge, Validators.required],
      profissaoConjuge: [profissaoConjuge, Validators.required],
      empresaConjuge: [empresaConjuge || ''],
      telefoneEmpresa: [telefoneEmpresaConjuge],
    });
    this.conjugeForm
      .get('classeProfissionalConjuge')
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((res) => this.fillProfissoesConjuge(res));
    this.detalhesClienteForm.addControl('conjuge', this.conjugeForm);
  }

  private initForms(): void {
    let telefoneEmpresaCompleto = '';
    if (
      this.clienteEntity.telefones &&
      this.clienteEntity.telefones.length > 1
    ) {
      const telefoneEmpresa = this.clienteEntity.telefones.filter(
        (t) => t.categoriaTelefone === 'COMERCIAL'
      )[0];

      if (telefoneEmpresa) {
        telefoneEmpresaCompleto = this.formatFone(
          telefoneEmpresa.ddd,
          telefoneEmpresa.nrTelefone
        );
      }
    }
    const requiredParaCliente = () =>
      this.avalistaNumero > 0 ? [] : [Validators.required];
    const profissao = this.clienteEntity.dadosProfissionais.profissao;
    const tempoServico = this.clienteEntity.dadosProfissionais.tempoServico
      ? moment
        .unix(this.clienteEntity.dadosProfissionais.tempoServico / 1000)
        .format(this.tempoServicoFormato)
      : null;
    this.detalhesClienteForm = this.fb.group({
      cpf: [this.clienteEntity.cpf],
      nomeCliente: [
        this.clienteEntity.nomeCliente || '',
        [Validators.required, Validators.maxLength(60)],
      ],
      apelidoCliente: [
        this.clienteEntity.apelidoCliente || '',
        [Validators.maxLength(60)],
      ],
      emailCliente: ['', [Validators.maxLength(60)]],
      sexoCliente: [this.clienteEntity.sexo || '', Validators.required],
      estadoCivil: this.fb.group({
        id: [
          this.clienteEntity.estadoCivil ? this.clienteEntity.estadoCivil.id : '',
          Validators.required,
        ],
      }),
      dependentes: [this.clienteEntity.dependentes || 0, requiredParaCliente()],
      grauInstrucao: [
        this.clienteEntity.grauInstrucao
          ? this.clienteEntity.grauInstrucao
          : '',
        requiredParaCliente(),
      ],
      profissaoCliente: [profissao ? profissao.id : '', Validators.required],
      empresaCliente: [
        this.clienteEntity.dadosProfissionais.nomeEmpresa || '',
        this.isExibeEmpresa
          ? [Validators.required, Validators.maxLength(100)]
          : [],
      ],
      tempoServico: [
        `${tempoServico || ''}`,
        this.isExibeEmpresa ? [Validators.required] : [],
      ],
      telefoneEmpresaCliente: [
        telefoneEmpresaCompleto,
        this.isExibeEmpresa ? [Validators.required] : [],
      ],
      isPoliticamenteExposto: [`${!!this.proposta.ppe}`, Validators.required],
      fatca: [this.clienteEntity.fatca || 'N'],
    });
    this.perfilCliente = this.clienteEntity.perfilCliente;
    if (this.avalistaNumero < 1) {
      this.dadosFavorecidoForm = this.fb.group({
        favorecido: ['', Validators.required],
        nomeFavorecido: ['', [Validators.required, Validators.maxLength(60)]],
        cpfCnpj: ['', Validators.required],
        banco: ['', Validators.required],
        filtroBanco: [''],
        agencia: ['', [Validators.required, Validators.maxLength(10)]],
        contaCorrente: ['', [Validators.required, Validators.maxLength(18)]],
        digitoContaCorrente: ['', [Validators.required, Validators.maxLength(2)],
        ],
      });
      this.detalhesClienteForm.addControl(
        'dadosFavorecido',
        this.dadosFavorecidoForm
      );
      this.dadosFavorecidoForm
        .get('favorecido')
        .valueChanges.pipe(takeWhile(() => this.isAlive))
        .subscribe((res) => this.handleFavorecidoChange(res));
    }
  }

  private initDefaultObservers(): void {
    this.fillEstadosCivis();
    this.fillClassesProfissionais();
    this.fillBancos();
    this.fillGrauInstrucao();
    this.fillProfissoes(
      this.clienteEntity.dadosProfissionais.classeProfissional.id
    );
    this.detalhesClienteForm.get('estadoCivil').get('id').valueChanges.subscribe((data) => {
      this.hasConjuge = data;
    });
    this.emailCliente.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        debounceTime(5000)
      )
      .subscribe((value) => this.emailEmUso(value));

    if (this.clienteEntity.email) {
      this.emailCliente.patchValue(this.clienteEntity.email);
    }
  }

  private fillEstadosCivis(): void {
    this.estadoCivilService
      .findEstadoCivil()
      .pipe(take(1))
      .subscribe((res) => (this.estadosCivis = res));
  }

  private fillClassesProfissionais(): void {
    this.classeProfissionalService
      .classes()
      .pipe(take(1))
      .subscribe((res) => (this.classesProfissionais = res));
  }

  private async fillGrauInstrucao() {
    try {
      const response = await this.grauInstrucaoService.listar();
      this.grauInstrucaoList = response.entities;
    } catch (error) {
      console.error(error);
      toastr.error(
        'Tente recarregar proposta para carregar esta informação',
        'Falha ao listar grau de instrução'
      );
    }
  }

  private fillProfissoes(res: number): void {
    this.profissaoService
      .profissoes(res).pipe(
        takeWhile(() => this.isAlive))
      .subscribe((resSub: any) => {
        /**
         * Converte o tipo para (Select2OptionData)
         */
        this.profissoes = (resSub.profissoes).map((r) => {
          return {
            id: r.id,
            idGrupoProfissao: r.idGrupoProfissao,
            text: `${r.descricao}`.toString()
          };
        });
        this.profissoes.unshift({ id: '', disabled: true, text: 'Selecione uma opção' });
      });
  }

  private fillProfissoesConjuge(res: number, clear = true): void {
    if (clear) {
      if (this.isExibeEmpresaConjuge) {
        this.conjugeForm.get('empresaConjuge').setValue('');
        this.conjugeForm.get('telefoneEmpresa').setValue('');
      } else {
        this.conjugeForm.get('empresaConjuge').setValue('nao apresentar');
        this.conjugeForm.get('telefoneEmpresa').setValue('(00) 0000-0000');
      }
    }

    this.profissaoService
      .profissoes(res)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((resSub) => (this.profissoesConjuge = resSub.profissoes));
  }

  private fillBancos(): void {
    if (this.proposta.cliente.banco != null) {
      this.banco = this.proposta.cliente.banco;
    }
    this.bancoService
      .bancos()
      .pipe(take(1))
      .subscribe((res: any[]) => {
        this.bancos = res.filter(
          (banco) =>
            !this.isDebitoAutomatico ||
            Number(banco.codigo) === Number(this.proposta.favorecido.banco)
        );
        /**
         * Converte o tipo para (Select2OptionData)
         */
        this.bancos = this.bancos.map((r: any) => {
          return {
            id: r.codigo,
            // tslint:disable-next-line:prefer-template
            text: `${r.codigo + ' - ' + r.descricao}`.toString()
          };
        });
        this.bancos.unshift({
          id: '',
          disabled: true,
          text: 'Selecione uma opção',
        });
      });
  }

  get formValid() {
    return this.detalhesClienteForm.valid;
  }

  private fillProposta(): void {
    this.buildDadosFavorecido();
    this.buildCliente();
    if (this.hasConjuge) {
      this.buildConjuge();
    } else {
      this.proposta.conjuge = {};
    }
    this.proposta.ppe =
      this.detalhesClienteForm.value.isPoliticamenteExposto === true;
    this.clienteEntity.transients.isConfirmadoTelefone =
      this.isConfirmadoTelefone;
  }

  private handleFavorecidoChange(favorecido: string): void {
    this.isCpfCnpjReadOnly = false;
    if (favorecido === 'ORIGEM') {
      this.showLoader = true;
      this.cpService
        .dadosBancarios(this.proposta.lojista.id)
        .pipe(
          finalize(() => (this.showLoader = false)),
          take(1)
        )
        .subscribe(
          (res) => this.changeFavorecido(res),
          () => this.modalMensagem.showModal()
        );
    } else {
      Object.keys(this.dadosFavorecidoForm.getRawValue()).forEach((key) => {
        if (
          [
            'nomeFavorecido',
            'banco',
            'agencia',
            'contaCorrente',
            'digitoContaCorrente',
            'cpfCnpj',
          ].includes(key)
        ) {
          this.dadosFavorecidoForm
            .get(key)
            .patchValue('', { emitEvent: false });
          this.dadosFavorecidoForm.get(key).enable({ emitEvent: false });
        }
      });
      if (favorecido === 'CLIENTE') {
        this.isFiltroBancoReadOnly = this.isDebitoAutomatico;
        this.dadosFavorecidoForm.patchValue(
          {
            nomeFavorecido: this.detalhesClienteForm.get('nomeCliente').value,
            cpfCnpj: this.proposta.cliente.cpf,
          },
          {
            emitEvent: false,
          }
        );
      } else {
        this.isFiltroBancoReadOnly = false;
      }
      this.dadosFavorecidoForm.updateValueAndValidity();
    }
  }

  private changeFavorecido(res: any): void {
    if (res && res.lojistaVO.razaoSocial) {
      res.lojistaVO.nomeFavorecido = res.lojistaVO.razaoSocial;
      this.dadosFavorecidoForm.patchValue(
        {
          nomeFavorecido: res.lojistaVO.nome,
          cpfCnpj: res.lojistaVO.cpfCnpj,
          agencia: res.lojistaVO.agencia,
          contaCorrente: res.lojistaVO.contaCorrente,
          digitoContaCorrente: res.lojistaVO.digitoContaCorrente,
          banco: res.lojistaVO.banco,
        },
        {
          emitEvent: false,
        }
      );
      this.dadosFavorecidoForm.updateValueAndValidity({ emitEvent: false });
      this.isCpfCnpjReadOnly = true;
    }
  }

  private buildConjuge(): void {
    if (this.conjugeForm) {
      const telefone: ClienteTelefone = this.isExibeEmpresaConjuge
        ? {
          categoriaTelefone: 'COMERCIAL',
          tipoTelefone: 'RECADOS',
          ddd: this.conjugeForm
            .get('telefoneEmpresa')
            .value.replace(/[^\d]/g, '')
            .substring(0, 2),
          nrTelefone: this.conjugeForm
            .get('telefoneEmpresa')
            .value.replace(/[^\d]/g, '')
            .substring(2),
        }
        : {
          ...this.proposta.cliente.telefones[0],
          categoriaTelefone: 'COMERCIAL',
          tipoTelefone: 'RECADOS',
        };

      this.proposta.conjuge = {
        ...this.proposta.conjuge,
        dadosProfissionais: {
          classeProfissional: {
            id: Number(this.conjugeForm.get('classeProfissionalConjuge').value),
          },
          profissao: {
            id: Number(this.profissaoConjuge.id),
            idGrupoProfissao: Number(this.idGrupoProfissaoConjuge),
          },
          nomeEmpresa: this.isExibeEmpresaConjuge
            ? this.conjugeForm.get('empresaConjuge').value
            : this.descricaoClasseProfissionaisConjuge,
        },
        telefones: [telefone],
      };

      const splited = this.proposta.conjuge.cpf.split(/\.|\-/);
      const cpfWithoutMask = splited.join('');
      this.proposta.conjuge.cpf = cpfWithoutMask;
      this.proposta.conjuge.dtNascimento = Data.toMilissegundos(this.proposta.conjuge.dtNascimento.toString());
    }
  }

  private buildDadosFavorecido(): void {
    if (this.dadosFavorecidoForm) {
      this.proposta.favorecido = {
        agencia: this.getNumeroSemDigito(this.numeroAgencia),
        digitoAgencia: this.getDigito(this.numeroAgencia),
        contaCorrente: this.numeroContaCorrente,
        digitoContaCorrente: this.digitoContaCorrente,
        banco: this.dadosFavorecidoForm.get('banco').value,
        cpfCnpj: this.dadosFavorecidoForm.get('cpfCnpj').value,
        favorecido: this.dadosFavorecidoForm.get('favorecido').value,
        nomeFavorecido: this.dadosFavorecidoForm.get('nomeFavorecido').value,
      };
    }
  }

  get numeroAgencia() {
    return this.dadosFavorecidoForm.get('agencia').value;
  }

  get numeroContaCorrente() {
    return this.dadosFavorecidoForm.get('contaCorrente').value;
  }

  get digitoContaCorrente() {
    return this.dadosFavorecidoForm.get('digitoContaCorrente').value;
  }

  private getNumeroSemDigito(value: string) {
    return value && value.includes('-')
      ? value.substr(0, value.indexOf('-'))
      : value;
  }

  private getDigito(value: string) {
    return value && value.includes('-')
      ? value.substr(value.indexOf('-') + 1, value.length - 1)
      : '';
  }

  private buildCliente(): void {
    this.buildClienteArrays();
    this.clienteEntity.dadosProfissionais = {
      ...this.clienteEntity.dadosProfissionais,
      profissao: {
        id: Number(this.profissaoCliente.id),
        idGrupoProfissao: Number(this.idGrupoProfissao),
      },
      nomeEmpresa: this.isExibeEmpresa
        ? this.detalhesClienteForm.get('empresaCliente').value
        : this.descricaoClasseProfissionaisCliente,
      tempoServico: this.isExibeEmpresa
        ? moment(
          this.detalhesClienteForm.get('tempoServico').value,
          this.tempoServicoFormato
        ).unix() * 1000
        : null,
    };

    this.clienteEntity.email = this.emailCliente.value;
    const estadoCivilId = parseInt(this.detalhesClienteForm.get('estadoCivil').value.id);
    this.clienteEntity.estadoCivil = { id: estadoCivilId };
    this.clienteEntity.nomeCliente =
      this.detalhesClienteForm.get('nomeCliente').value;
    this.clienteEntity.apelidoCliente =
      this.detalhesClienteForm.get('apelidoCliente').value;
    this.clienteEntity.sexo = this.detalhesClienteForm.get('sexoCliente').value;

    this.clienteEntity.rendas.forEach((renda) => {
      const cliente = this.clienteEntity;
      const telefone =
        cliente.telefones.filter(
          (t) => t.categoriaTelefone === 'COMERCIAL'
        )[0] || cliente.telefones[0];

      renda.empresa = cliente.dadosProfissionais.nomeEmpresa;
      renda.empresaDdd = telefone.ddd;
      renda.empresaFone = telefone.nrTelefone;
      renda.empresaRamal = '00';
    });
    this.clienteEntity.dependentes =
      this.detalhesClienteForm.get('dependentes').value;
    this.clienteEntity.grauInstrucao =
      this.detalhesClienteForm.get('grauInstrucao').value;
    this.clienteEntity.fatca =
      this.detalhesClienteForm.get('fatca').value;
  }

  get profissaoConjuge() {
    return (
      this.profissoesConjuge.find(
        (pro) =>
          pro.id === Number(this.conjugeForm.get('profissaoConjuge').value)
      ) || {}
    );
  }

  get profissaoCliente() {
    return (
      this.profissoes.find(
        (pro) =>
          pro.id ===
          Number(this.detalhesClienteForm.get('profissaoCliente').value)
      ) || {}
    );
  }

  get idGrupoProfissao(): number {
    return this.profissaoCliente.idGrupoProfissao;
  }

  get idGrupoProfissaoConjuge(): number {
    return this.profissaoConjuge.idGrupoProfissao;
  }

  private buildClienteArrays(): void {
    const controls = this.detalhesClienteForm.controls;
    this.clienteEntity.telefones = this.buildClienteTelefones(controls);

    if (this.isExibeEmpresa) {
      this.clienteEntity.telefones.push({
        categoriaTelefone: 'COMERCIAL',
        tipoTelefone: 'RECADOS',
        ddd: this.detalhesClienteForm
          .get('telefoneEmpresaCliente')
          .value.replace(/[^\d]/g, '')
          .substring(0, 2),
        nrTelefone: this.detalhesClienteForm
          .get('telefoneEmpresaCliente')
          .value.replace(/[^\d]/g, '')
          .substring(2),
      });
    } else {
      this.clienteEntity.telefones.push({
        ...this.clienteEntity.telefones[0],
        categoriaTelefone: 'COMERCIAL',
        tipoTelefone: 'RECADOS',
      });
    }

    this.clienteEntity.referencias = this.buildClienteReferencias(controls);
  }

  private buildClienteTelefones(controls: {
    [key: string]: AbstractControl;
  }): any[] {
    return Object.keys(controls)
      .filter((key) => key.startsWith('telefoneForm'))
      .map((key) => {
        const foneForm = (<FormGroup>controls[key]).getRawValue();
        return {
          tipoTelefone: 'PROPRIO',
          categoriaTelefone: foneForm.tipoTelefone,
          ddd: foneForm.telefone.replace(/[^\d]/g, '').substring(0, 2),
          nrTelefone: foneForm.telefone.replace(/[^\d]/g, '').substring(2),
        };
      });
  }

  private buildClienteReferencias(controls: {
    [key: string]: AbstractControl;
  }): any[] {
    return Object.keys(controls)
      .filter((key) => key.startsWith('referenciaForm'))
      .map((key) => {
        return {
          // tipoReferencia: controls[key].value.tipoReferencia,
          nome: controls[key].value.nomeReferencia,
          ddd: controls[key].value.telefoneReferencia
            .replace(/[^\d]/g, '')
            .substring(0, 2),
          nrTelefone: controls[key].value.telefoneReferencia
            .replace(/[^\d]/g, '')
            .substring(2),
          tipoReferencia: { id: controls[key].value.tipoReferencia },
        };
      });
  }

  private fillForms(): void {
    this.isConfirmadoTelefone =
      !!this.clienteEntity.transients.isConfirmadoTelefone;
    if (this.clienteEntity) {
      this.detalhesClienteForm.patchValue({
        nomeCliente: (this.clienteEntity.nomeCliente || '').trim(),
      });
      this.detalhesClienteForm.patchValue({
        apelidoCliente: (this.clienteEntity.apelidoCliente || '').trim(),
      });
      this.detalhesClienteForm.get('estadoCivil').patchValue(this.clienteEntity.estadoCivil);
      if (this.clienteEntity.telefones) {
        this.clienteEntity.telefones
          .filter((fone) => fone.tipoTelefone === 'PROPRIO')
          .sort((a, _) => (a.categoriaTelefone === 'CELULAR' ? -1 : 1))
          .forEach(this.fillFoneForm.bind(this));
      }
      if (this.clienteEntity.referencias) {
        this.clienteEntity.referencias.forEach(
          this.fillReferenciaForm.bind(this)
        );
      }
    }

    if (
      this.hasConjuge &&
      this.proposta.conjuge.dadosProfissionais &&
      this.proposta.conjuge.dadosProfissionais.classeProfissional
    ) {
      const classeProfissionalConjuge =
        this.proposta.conjuge.dadosProfissionais.classeProfissional.id;
      this.fillProfissoesConjuge(classeProfissionalConjuge, false);
    }

    if (this.dadosFavorecidoForm) {
      if (this.proposta.favorecido && this.proposta.favorecido.contaCorrente) {
        const digitoAgencia = `${this.proposta.favorecido.digitoAgencia}`;
        this.dadosFavorecidoForm.patchValue(
          {
            agencia: `${this.proposta.favorecido.agencia}${this.proposta.favorecido.digitoAgencia ? digitoAgencia : ''
              }`,
            banco: this.proposta.favorecido.banco,
            contaCorrente: this.proposta.favorecido.contaCorrente,
            digitoContaCorrente: this.proposta.favorecido.digitoContaCorrente,
            cpfCnpj: this.proposta.favorecido.cpfCnpj,
            favorecido: this.proposta.favorecido.favorecido,
            nomeFavorecido: this.proposta.favorecido.nomeFavorecido,
          },
          {
            emitEvent: false,
          }
        );
      }
      this.handleDebitoAutomatico();
    }
  }

  fillFoneForm(fone: ClienteTelefone, index: number) {
    setTimeout(
      () => {
        if (index > 0) {
          this.addTelefone();
        }
        const form = <FormGroup>(
          this.detalhesClienteForm.controls[`telefoneForm_${index - 1}`]
        );
        form.setValue(
          {
            tipoTelefone: fone.categoriaTelefone,
            telefone: this.formatFone(fone.ddd, fone.nrTelefone),
          },
          { emitEvent: false }
        );
      },
      500,
    );
  }

  fillReferenciaForm(referencia: ClienteReferencia, index: number) {
    setTimeout(
      () => {
        if (index > 0) {
          this.addReferencia();
        }
        const form = <FormGroup>(
          this.detalhesClienteForm.controls[`referenciaForm_${index - 1}`]
        );
        const disabled = form.disabled;
        form.enable();
        form.setValue({
          tipoReferencia: referencia.tipoReferencia.id,
          nomeReferencia: referencia.nome,
          telefoneReferencia: this.formatFone(
            referencia.ddd,
            referencia.nrTelefone
          ),
        });

        if (disabled) {
          form.disable();
        }
      },
      500,
    );
  }

  addTelefone(): void {
    if (this.telefones.length < 2) {
      this.telefones.push(this.removableTelefonesCount++);
      this.changeDetectorRef.detectChanges();
    }
  }

  removeTelefone($event: number): void {
    this.telefones.pop();
    this.removableTelefonesCount--;
  }

  addReferencia(): void {
    if (this.filterReferencias().length < 1) {
      this.referencias.push(this.removableReferenciasCount++);
      this.changeDetectorRef.detectChanges();
    }
  }

  filterReferencias(): number[] {
    return this.referencias.filter((ele) => ele !== -1);
  }

  removeReferencia($event: number): void {
    this.referencias[$event] = -1;
  }

  onChangeBanco(bancoId) {
    this.dadosFavorecidoForm.get('banco').setValue(bancoId);
  }

  handleSmsConfirmacao($event: boolean): void {
    this.isConfirmadoTelefone = $event;
  }

  handleDebitoAutomatico(): void {
    if (this.isDebitoAutomatico) {
      if (this.proposta.favorecido && !this.proposta.favorecido.contaCorrente) {
        this.dadosFavorecidoForm.patchValue(
          {
            banco: this.proposta.favorecido.banco,
            favorecido: 'CLIENTE',
            cpfCnpj: this.proposta.cliente.cpf,
            nomeFavorecido: this.proposta.cliente.nomeCliente,
          },
          { emitEvent: false }
        );
      }
      this.dadosFavorecidoForm.get('favorecido').disable({ emitEvent: false });
    }
  }

  get filtroBanco(): AbstractControl {
    return this.dadosFavorecidoForm.get('filtroBanco');
  }

  get isDebitoAutomatico(): boolean {
    return (
      this.tipoNegociacaoEnum === TipoNegociacaoEnum.DEBITO_AUTOMATICO ||
      this.proposta.produto === this.PRODUTO_CREDITO_PESSOAL_DEBITO_AUTOMATICO
    );
  }

  onClickNext() {
    this.fillProposta();
    this.state.setState(this.proposta);
    this.next.emit();
  }

  onClickPrevious() {
    this.fillProposta();
    this.state.setState(this.proposta);
    this.previous.emit();
  }

  get isExibeEmpresa() {
    return ![7, 9].includes(
      this.clienteEntity.dadosProfissionais.classeProfissional.id
    );
  }

  get isExibeEmpresaConjuge() {
    if (this.conjugeForm && this.conjugeForm.get('classeProfissionalConjuge')) {
      const classeProfissional = Number(
        this.conjugeForm.get('classeProfissionalConjuge').value
      );
      return classeProfissional !== 7;
    }
    return false;

  }

  get emailCliente() {
    return this.detalhesClienteForm.get('emailCliente');
  }

  get classesProfissionaisConjuge() {
    return this.classesProfissionais.filter((e) => e.id !== 9);
  }

  get descricaoClasseProfissionaisCliente() {
    const classe = this.classesProfissionais.filter(
      (e) =>
        e.id === this.clienteEntity.dadosProfissionais.classeProfissional.id
    )[0] || { descricao: 'nao informado' };
    return classe.descricao;
  }

  get descricaoClasseProfissionaisConjuge() {
    const classeProfissionalId = Number(
      this.conjugeForm.get('classeProfissionalConjuge').value
    );
    const classe = this.classesProfissionais.filter(
      (e) => e.id === classeProfissionalId
    )[0] || { descricao: 'nao informado' };
    return classe.descricao;
  }

  private formatFone(ddd: string, fone: string): string {
    if (!fone || fone.length < 8) {
      return '';
    }
    const telefone = fone;
    const telefoneSize = telefone.length;
    const telefonePart1 = telefone.substr(0, telefoneSize - 4);
    const telefonePart2 = telefone.substr(telefoneSize - 4, 4);
    return `(${ddd}) ${telefonePart1}-${telefonePart2}`;
  }

  validarAgenciaBancaria() {
    const banco = this.dadosFavorecidoForm.value.banco;
    const agencia = this.dadosFavorecidoForm.value.agencia;
    this.cpService
      .agenciaBancaria(banco, agencia)
      .pipe(take(1))
      .subscribe((res) => this.validarAgencia(res));
  }

  validarAgencia(res: any) {
    if (res.existeAgencia === false) {
      this.modalMensagem.showModal({
        titulo: 'Ocorreu uma falha!',
        mensagem: 'Agência não cadastrada para o banco selecionado!',
      });
    }
  }

  alterarNomeFavorecido() {
    if (
      this.proposta.produto ===
      this.PRODUTO_CREDITO_PESSOAL_DEBITO_AUTOMATICO ||
      this.tipoNegociacaoEnum === this.PRODUTO_CREDITO_PESSOAL_DEBITO_AUTOMATICO
    ) {
      this.showLoader = true;
      this.dadosFavorecidoForm.patchValue(
        {
          nomeFavorecido: this.detalhesClienteForm.value.nomeCliente,
        },
        {
          emitEvent: false,
        }
      );
      this.showLoader = false;
    }
  }

  get tempoServicoFormato() {
    return 'MMYYYY';
  }

  get clienteEntity() {
    let cliente;
    if (this.avalistaNumero > 0) {
      cliente = this.proposta.avalistas[`${this.avalistaNumero}`];
    } else {
      cliente = this.proposta.cliente;
    }

    cliente.transients = cliente.transients || {};
    return cliente;
  }

  async emailEmUso(email) {
    this.clienteEntity.transients.emailExistente =
      await this.verificaEmailEmUso(email);
  }

  async verificaEmailEmUso(email) {
    if (!email) return false;

    // verifico se email foi repetido entre avalistas/cliente
    if (this.avalistaNumero > 0) {
      if (email === this.proposta.cliente.email) {
        return true;
      }

      for (let i = this.avalistaNumero - 1; i > 0; i--) {
        if (this.proposta.avalistas[i].email === email) {
          return true;
        }
      }
    }

    try {
      const response = await this.cpService.verificarEmailDuplicado(
        this.proposta.cliente.cpf,
        email
      );
      return response.emailEmUso;
    } catch (error) {
      console.log(error);
    }
  }

  public changedBancos($event: any) {
    if (!$event) return;
    if (!this.detalhesClienteForm) return;
    if ($event.hasOwnProperty('value')) {
      this.detalhesClienteForm
        .get('dadosFavorecido')
        .get('banco')
        .setValue($event.value);
    }
  }

  public changedProfissoes($event: any) {
    if (!$event) return;
    if (!this.detalhesClienteForm) return;
    if ($event.hasOwnProperty('value')) {
      this.detalhesClienteForm.get('profissaoCliente').setValue($event.value);
    }
  }

  public changedProfissoesConjuge(id) {
    this.fillProfissoesConjuge(id, false);
    this.isExibeEmpresaConjuge;
    this.profissaoService
      .profissoes(id)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((res) => {
        this.profissoesConjuge = res.profissoes;
      });
  }

  public changedCPFConjuge(data) {
    this.proposta.conjuge.cpf = data;
  }

  public changedDataNascimentoConjuge(data) {
    this.proposta.conjuge.dtNascimento = data;
  }

  setFatca(data) {
    this.detalhesClienteForm.patchValue({
      fatca: data,
    });
  }

  setPoliticamenteExposto(data) {
    this.detalhesClienteForm.value.isPoliticamenteExposto = data.toString();
    this.detalhesClienteForm.get('isPoliticamenteExposto').setValue(data);
    this.detalhesClienteForm.patchValue({
      ppe: data,
    });
    this.proposta.cliente.ppe = data;
    if (this.avalistaNumero > 0) {
      this.proposta.avalistas[this.avalistaNumero].ppe = data;
    }
    this.proposta.ppe = data;
  }

  public omitSpecialCharacter(event: any): void {
    const pattern = /^[a-zA-Z\s]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    }
  }
}
