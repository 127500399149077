import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-aguardando-analise-direta',
  templateUrl: './ficha-timeline-aguardando-analise-direta.component.html',
  styleUrls: ['./ficha-timeline-aguardando-analise-direta.component.css']
})
export class FichaTimelineAguardandoAnaliseDiretaComponent {

  @Input() timeline: any;
  @Input() timelineActive: boolean;

}
