import { NgModule } from '@angular/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';

import { DirectivesModule } from '../../directives/directives.module';
import { SharedModule } from '../../shared/shared.module';
import { ConfirmModalComponent } from '../../shared/timer-token/confirm-modal/confirm-modal.component';
import { UploadArquivosModalComponent } from '../../shared/upload-arquivos-modal/upload-arquivos-modal.component';
import { UploadArquivosComponent } from '../../shared/upload-arquivos/upload-arquivos.component';
import { ProdutosSharedModule } from '../shared/shared.module';

import { CdcLojaNovaFichaComponent } from './cadastro/cadastro.component';
import { AnaliseComponent } from './cadastro/etapas/analise/analise.component';
import { DadosAnaliseComponent } from './cadastro/etapas/dados-analise/dados-analise.component';
import { ModalCarregarFichaComponent } from './cadastro/etapas/dados-analise/modal-carregar-ficha/modal-carregar-ficha.component';
import { DetalhesClienteComponent } from './cadastro/etapas/detalhes-cliente/detalhes-cliente.component';
import { LinkNotSentModalComponent } from './cadastro/etapas/detalhes-cliente/link-not-sent-modal/link-not-sent-modal.component';
import { ReferenciaBaseComponent } from './cadastro/etapas/detalhes-cliente/referencia-base/referencia-base.component';
import { TelefoneBaseComponent } from './cadastro/etapas/detalhes-cliente/telefone-base/telefone-base.component';
import { EnderecoBaseComponent } from './cadastro/etapas/enderecos-cliente/endereco-base/endereco-base.component';
import { EnderecosClienteComponent } from './cadastro/etapas/enderecos-cliente/enderecos-cliente.component';
import { FichaExcluidaComponent } from './cadastro/etapas/ficha-excluida/ficha-excluida.component';
import { AppWebcamComponent } from './cadastro/etapas/identificacao/app-webcam/app-webcam.component';
import { AutenticacaoManualEmAndamentoComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao-manual-em-andamento/autenticacao-manual-em-andamento.component';
import { CapturaFotoComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao-manual-em-andamento/captura-foto/captura-foto.component';
import { ModalDicaDeFotoComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao-manual-em-andamento/modal-dica-de-foto/modal-dica-de-foto.component';
import { ModalInfoAppOmniComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao-manual-em-andamento/modal-info-app-omni/modal-info-app-omni.component';
import { ValidacaoCelularComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao-manual-em-andamento/validacao-celular/validacao-celular.component';
import { AutenticacaoRealizadaComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao-realizada/autenticacao-realizada.component';
import { AutenticacaoComponent } from './cadastro/etapas/identificacao/autenticacao/autenticacao.component';
import { ModalConfirmarProcessoAutenticacaoComponent } from './cadastro/etapas/identificacao/autenticacao/modal-confirmar-processo-autenticacao/modal-confirmar-processo-autenticacao.component';
import { IdentificacaoComponent } from './cadastro/etapas/identificacao/identificacao.component';
import { MercadoriaComponent } from './cadastro/etapas/identificacao/mercadoria/mercadoria.component';
import { ModalErroValidacaoComponent } from './cadastro/etapas/identificacao/modal-erro-validacao/modal-erro-validacao.component';
import { ModalValidandoComponent } from './cadastro/etapas/identificacao/modal-validando/modal-validando.component';
import { AlteraTabelaComponent } from './cadastro/etapas/resultado-parcial/altera-tabela/altera-tabela.component';
import { ParcelaPersonalizadaComponent } from './cadastro/etapas/resultado-parcial/parcela-personalizada/parcela-personalizada.component';
import { ParcelaComponent } from './cadastro/etapas/resultado-parcial/parcela/parcela.component';
import { ResultadoParcialComponent } from './cadastro/etapas/resultado-parcial/resultado-parcial.component';
import { RetencaoComponent } from './cadastro/etapas/resultado-parcial/retencao/retencao.component';
import { SelecionarLojistaVendedorComponent } from './cadastro/etapas/selecionar-lojista-vendedor/selecionar-lojista-vendedor.component';
import { CdcLojaRoutingModule } from './cdc-loja-routing.module';
import { CdcLojaComponent } from './cdc-loja.component';
import { ApprovalModalComponent } from './cessao/approval-modal/approval-modal.component';
import { CessaoComponent } from './cessao/cessao.component';
import { MultiApprovalComponent } from './cessao/multi-approval/multi-approval.component';
import { SingleApprovalComponent } from './cessao/single-approval/single-approval.component';
import { EcommerceModule } from './ecommerce/ecommerce.module';
import { ProductionComponent } from './reports/production/production.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsService } from './reports/reports.service';
import { CdcLojaService } from './services/cdc-loja.service';
import { CdcLojaTimelineModule } from './timeline/cdc-loja-timeline.module';
import { VerificacaoSegurancaModule } from './verificacao-seguranca/verificacao-seguranca.module';

@NgModule({
  declarations: [
    CdcLojaComponent,
    CdcLojaNovaFichaComponent,
    AnaliseComponent,
    DadosAnaliseComponent,
    ModalCarregarFichaComponent,
    DetalhesClienteComponent,
    ReferenciaBaseComponent,
    TelefoneBaseComponent,
    EnderecosClienteComponent,
    EnderecoBaseComponent,
    IdentificacaoComponent,
    MercadoriaComponent,
    ResultadoParcialComponent,
    AlteraTabelaComponent,
    ParcelaComponent,
    ParcelaPersonalizadaComponent,
    RetencaoComponent,
    SelecionarLojistaVendedorComponent,
    ReportsComponent,
    ProductionComponent,
    ModalValidandoComponent,
    ModalErroValidacaoComponent,
    CessaoComponent,
    SingleApprovalComponent,
    MultiApprovalComponent,
    ApprovalModalComponent,
    AppWebcamComponent,
    AutenticacaoComponent,
    ModalConfirmarProcessoAutenticacaoComponent,
    AutenticacaoRealizadaComponent,
    AutenticacaoManualEmAndamentoComponent,
    CapturaFotoComponent,
    ValidacaoCelularComponent,
    ModalDicaDeFotoComponent,
    ModalInfoAppOmniComponent,
    FichaExcluidaComponent,
    LinkNotSentModalComponent
  ],
  imports: [
    ProdutosSharedModule,
    CdcLojaRoutingModule,
    SharedModule,
    CdcLojaTimelineModule,
    DirectivesModule,
    Ng2SearchPipeModule,
    OrderModule,
    EcommerceModule,
    VerificacaoSegurancaModule
  ],
  exports: [
    ModalValidandoComponent
  ],
  entryComponents: [
    ModalCarregarFichaComponent,
    AlteraTabelaComponent,
    ParcelaPersonalizadaComponent,
    RetencaoComponent,
    ConfirmModalComponent,
    UploadArquivosComponent,
    UploadArquivosModalComponent,
    ModalValidandoComponent,
    ModalErroValidacaoComponent,
    SingleApprovalComponent,
    MultiApprovalComponent,
    ApprovalModalComponent,
    ModalConfirmarProcessoAutenticacaoComponent,
    ModalDicaDeFotoComponent,
    ModalInfoAppOmniComponent,
    LinkNotSentModalComponent,
  ],
  providers: [
    CdcLojaService,
    ReportsService
  ]
})
export class CdcLojaModule { }
