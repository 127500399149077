
import { Component } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import * as moment from 'moment';

import { DashboardService } from '../dashboard.service';
import { PeriodoFactory } from '../select-periodo/periodo/periodo.factory';
import { Produto } from './../../shared/select-produto/model/produto';
import { Filtro } from './../filtro';
import { Mes } from './../select-periodo/periodo/mes';
import { Periodo } from './../select-periodo/periodo/periodo';
import { PeriodoUtils } from './../select-periodo/periodo/periodo-utils';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent  {
  private filtro: Filtro = new Filtro();
  opcoesPeriodos = [
    PeriodoFactory.mes(PeriodoFactory.PADRAO),
    PeriodoFactory.ano()
  ];
  dados: any = {};
  loading = true;
  tituloRanking: string;
  produtos: any;
  periodo: any;

  constructor(private service: DashboardService) {}

  produtoChange(produtos: Produto[]): void {
    this.produtos = produtos;

    this.filtro.produtos = produtos.map(produto => {
      return {
        checked: produto.checked,
        descricao: produto.descricaoNormalized
      };
    });
    this.buscarRanking();
  }

  periodoChange(periodo: Periodo) {
    this.periodo = periodo;
    this.defineTituloRanking(periodo);
    this.filtro.dtInicio = periodo.dataInicio;
    this.filtro.dtFim = periodo.dataFim;
    this.filtro.limiteRegistros = 4;
    this.buscarRanking();
  }

  private buscarRanking(): void {
    if (this.produtos !== undefined && this.periodo !== undefined) {
      this.loading = true;
      this.dados = {};
      this.service
        .ranking(this.filtro).pipe(
        take(1),
        finalize(() => (this.loading = false)))
        .subscribe(result => this.dados = result.retorno);
    }
  }

  get isRanking(): boolean {
    return (
      this.dados &&
      this.dados.listasRanking &&
      this.dados.listasRanking.length > 0
    );
  }

  get isMeuRanking(): boolean {
    return (
      this.dados &&
      this.dados.meuRanking &&
      this.dados.meuRanking.nmLojistaOperador
    );
  }

  get isDetalhes(): boolean {
    return this.dados && this.dados.detalhes && this.dados.detalhes.length > 0;
  }

  private defineTituloRanking(periodo: Periodo) {
    const dataInicio = periodo.dataInicio;
    if (periodo instanceof Mes) {
      this.tituloRanking = PeriodoUtils.meses[moment(dataInicio).month()];
    } else {
      this.tituloRanking = moment(dataInicio).format('YYYY');
    }
  }
}
