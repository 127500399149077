import { appSettings } from './../../../../environments/app.setings';
import { BsModalService } from 'ngx-bootstrap';
import { VisualizacaoDocumentacaoComponent } from './../visualizacao-documentacao/visualizacao-documentacao.component';
import { Component, Input, Output, EventEmitter } from '@angular/core';

const FILE_MAX_SIZE = 10485760;

@Component({
  selector: 'app-upload-foto-garantia',
  templateUrl: './upload-foto-garantia.component.html',
  styleUrls: ['./upload-foto-garantia.component.css'],
})
export class UploadFotoGarantiaComponent {
  @Input() descricao = 'Foto garantia';
  @Input() timeline: any;
  @Output() fileChange: EventEmitter<File> = new EventEmitter();
  @Output() errorSize: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: BsModalService) {}

  imgUrl: string;
  fileName: string;

  emitFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.size > FILE_MAX_SIZE) {
        this.errorSize.emit();

        return;
      }

      const reader = new FileReader();

      reader.onload = (onloadEvent: any) => {
        this.imgUrl = onloadEvent.target.result;
      };

      reader.readAsDataURL(file);
      this.fileChange.emit(file);

      this.fileName = file.name;
    }
  }

  get mustShowAviso() {
    return (
      this.timeline &&
      this.timeline.filter((time) => time.idStatus === 21)[0].dataHora ===
        undefined
    );
  }

  showAvisoDocumentacao() {
    const initialState = {};
    this.modalService.show(VisualizacaoDocumentacaoComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
  }
}
