import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader-state';

@Injectable()
export class LoaderService {

  private loaderSubject = new Subject<LoaderState>();
  private requests: number = 0;
  loaderState = this.loaderSubject.asObservable();

  show() {
    this.requests++;
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    this.requests--;

    if (this.requests === 0) {
      this.loaderSubject.next(<LoaderState>{ show: false });
    }
  }

}
