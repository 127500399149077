import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['paginator.component.css']
})
export class PaginatorComponent implements OnInit, OnChanges {
  public static readonly TOTAL_PAGS_PADRAO: number = 30;
  public static readonly PAG_PADRAO: number = 1;
  public static readonly REG_PADRAO: number = 0;
  public static readonly ADJACENTES_PADRAO: number = 4;

  @Input() qtdPorPagina: number;
  @Input() totalRegistros: number;
  @Input() qtdAdjacentes: number; // use números pares para uma melhor exibição
  @Output() onPaginate: EventEmitter<number> = new EventEmitter<number>();

  pagina: number;
  paginas: number[];
  exibirProximo: boolean;
  exibirAnterior: boolean;
  qtdPaginas: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.qtdAdjacentes = this.qtdAdjacentes || PaginatorComponent.ADJACENTES_PADRAO;
    this.qtdPorPagina = this.qtdPorPagina || PaginatorComponent.TOTAL_PAGS_PADRAO;
    this.pagina = +this.route.snapshot.queryParams['pagina'] || PaginatorComponent.PAG_PADRAO;
    this.totalRegistros = this.totalRegistros || PaginatorComponent.REG_PADRAO;
    this.qtdPaginas = Math.ceil(this.totalRegistros / this.qtdPorPagina);
    this.gerarLinks();
  }

  ngOnChanges(): void {
    this.ngOnInit();
    this.onPaginate.emit(this.pagina);
  }

  gerarLinks() {
    this.paginas = [];
    for (let i = this.iniAdjacente; i <= this.fimAdjacente; i++) {
      this.paginas.push(i);
    }
  }

  paginar(pagina: number, $event: any) {
    $event.preventDefault();
    this.pagina = pagina;
    this.gerarLinks();
    this.onPaginate.emit(pagina);
    window.scroll({ top: 200, behavior: 'smooth' });
  }

  get isFirstPagina(): boolean {
    return this.pagina === 1;
  }

  get isLastPagina(): boolean {
    return this.pagina === this.qtdPaginas;
  }

  get iniAdjacente(): number {
    if (this.isFirstPagina) return this.pagina;
    if (this.pagina - this.qtdAdjacentes < 1) return 1;
    if (this.isLastPagina) {
      return (this.pagina - this.qtdAdjacentes - 1 < 1
        ? 1
        : this.pagina - this.qtdAdjacentes - 1);
    }
    return this.pagina - this.qtdAdjacentes;
  }

  get fimAdjacente(): number {
    if (this.isLastPagina) return this.pagina;
    if (this.pagina + this.qtdAdjacentes > this.qtdPaginas) return this.qtdPaginas;
    if (this.isFirstPagina) {
      return (this.pagina + this.qtdAdjacentes + 1 >= this.qtdPaginas
        ? this.qtdPaginas
        : this.pagina + this.qtdAdjacentes + 1);
    }
    return this.pagina + this.qtdAdjacentes;
  }
}
