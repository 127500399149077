export class DadosFavorecido {
  idProposta: number;
  nomeTitular: string;
  cpfCnpj: string;
  banco: number;
  agencia: string;
  digitoAgencia: string;
  contaCorrente: string;
  digitoContaCorrente: string;
  codigoDestino: number;
}
