import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNotCaracter], [appNotCaracter][ngModel]'
})
export class NotCaracterDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (!(/[[\w\s]/.test(event.key)) && event.code !== 'Space') {
      event.preventDefault();
    }
  }
}
