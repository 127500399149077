import { LoginStorage } from './../../../login/login.storage';
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FichaCadastro } from './../ficha-cadastro';
import { FichaService } from '../../ficha-veiculo.service';
import { ModalComponent } from './../../../shared/modais/modal/modal.component';
import { setFichaContent } from '../helpers/salvarFicha';
import { RecuperaCliente } from '../model/recupera/recupera-cliente';
import { RecuperaAvalista } from '../model/recupera/recupera-avalista';
import { Avalista } from '../model/avalista';

import * as moment from 'moment';
import { Data } from '../../../shared/partner/data';

@Component({
  selector: 'app-ficha-cadastro-cliente',
  templateUrl: './ficha-cadastro-cliente.component.html',
  styleUrls: ['./ficha-cadastro-cliente.component.css'],
})
export class FichaCadastroClienteComponent implements OnInit, OnDestroy {
  public TIPO_SOLIC_CLIENTE = 'CLIENTE';
  public TIPO_SOLIC_OUTRA = 'OUTRA';

  @Input() fichaCadastro: FichaCadastro;

  showButtonRecuperaFicha: boolean = false;
  avalistaCpf: string;
  avalistaDataNascimento: string;
  avalistaCelular: string;
  avalistaRendaComprovada: string;
  avalistaRelac: string;
  isSelectedRelacAvalista = false;
  idLojista: number;
  avalistaRecuperado: Avalista;

  isAvalista = false;

  subsValidarClienteCpf: Subscription;
  isValidatingClienteCpf = false;
  isValidatingAvalistaCpf = false;
  cadastroAntigoContent;
  dataRange = {
    min: 18,
    max: 85,
    message: 'A idade deve ser entre 18 e 85 anos',
  };

  constructor(
    private fichaService: FichaService,
    private router: Router,
    private loginStorage: LoginStorage,
    private renderer: Renderer2,
  ) {}

  @ViewChild('modal', { static: true }) modal: ModalComponent;
  @ViewChild('modalAvalista', { static: true }) modalAvalista: ModalComponent;

  @ViewChild('dataNascimento', { static: true }) dataNascimento: ElementRef;

  ngOnInit() {
    this.fichaCadastro.avalistaParentesco = '';
  }

  ngOnDestroy() {
    if (this.subsValidarClienteCpf) {
      this.subsValidarClienteCpf.unsubscribe();
    }
  }

  onClickAvalista() {
    this.isAvalista = !this.isAvalista;
    this.fichaCadastro.setAvalista(this.isAvalista);
  }

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
  }

  checkResponseModal(event) {
    if (event) {
      this.fichaCadastro.setCliente(this.cadastroAntigoContent.clientes[0]);
      this.isValidatingClienteCpf = false;
    }
  }
  getCpfCliente() {
    if (this.fichaCadastro._client.cpf) {
      this.isValidatingClienteCpf = true;
      this.idLojista = this.fichaCadastro.IDLojista;
      if (this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_CLIENTE || this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_OUTRA) {
        this.idLojista = this.loginStorage.agente.lojaBalcaoId;
      }
      this.fichaService
        .recuperarDadosFicha(
          this.formatCpf(this.fichaCadastro._client.cpf),
          this.idLojista
        )
        .subscribe(
          (res) => {
            this.isValidatingClienteCpf = false;
            this.cadastroAntigoContent = res;
            if (this.cadastroAntigoContent.id && this.cadastroAntigoContent.simulacao) {
              this.showButtonRecuperaFicha = true;
            }
            this.modal.show();
          },
          (err) => {
            this.isValidatingClienteCpf = false;
          },
          () => {
            this.isValidatingClienteCpf = false;
          }
        );
    }
  }

  getCpfAvalista() {
    if (this.fichaCadastro._avalista.cpf) {
      this.isValidatingAvalistaCpf = true;
      this.idLojista = this.fichaCadastro.IDLojista;
      if (this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_CLIENTE || this.fichaCadastro.solicitanteTipo === this.TIPO_SOLIC_OUTRA) {
        this.idLojista = this.loginStorage.agente.lojaBalcaoId;
      }
      this.fichaService
        .recuperarDadosFicha(
          this.formatCpf(this.fichaCadastro._avalista.cpf),
          this.idLojista
        )
        .subscribe(
          (res) => {
            this.isValidatingAvalistaCpf = false;
            this.preencheAvalista(res);
            this.modalAvalista.show();
          },
          (err) => {
            this.isValidatingAvalistaCpf = false;
          },
          () => {
            this.isValidatingAvalistaCpf = false;
          });
    }
  }

  preencheAvalista(res) {
    this.avalistaRecuperado = new Avalista();
    this.avalistaRecuperado.cpf = res.clientes[0].cpf;
    this.avalistaRecuperado.dataNascimento = moment(new Date(res.clientes[0].dataNascimento)).format('DD/MM/YYYY');
    this.avalistaRecuperado.telefones.celular = res.clientes[0].telefones[0].ddd + res.clientes[0].telefones[0].telefone;
    this.avalistaRecuperado.renda = res.clientes[0].profissional[0].salario;
  }

  recuperarFicha() {
    this.modal.hide();
    this.getFicha(this.cadastroAntigoContent);
  }
  recuperarDados() {
    this.setDadosInFichaCadastro(this.cadastroAntigoContent);
    this.renderer.selectRootElement(this.dataNascimento.nativeElement).focus();
    this.modal.hide();
  }
  continuar() {
    this.modalAvalista.hide();
  }
  recuperarDadosAvalista() {
    this.fichaCadastro._avalista = this.avalistaRecuperado;
    this.modalAvalista.hide();
  }

  iniciarNovaFicha() {
    this.modal.hide();
  }

  onClickNext() {
    this.fichaCadastro.setPayload(
      'cpf',
      this.formatCpf(this.fichaCadastro._client.cpf)
    );
    this.fichaCadastro.setPayload(
      'dataNascimento',
      this.formatDateMiliseconds(this.fichaCadastro._client.dataNascimento)
    );
    this.fichaCadastro.setPayload(
      'ddd',
      this.fichaCadastro._client.telefones.celular.substring(0, 2)
    );
    this.fichaCadastro.setPayload(
      'celular',
      this.fichaCadastro._client.telefones.celular.substring(2)
    );
    this.fichaCadastro.setPayload('renda', this.fichaCadastro._client.renda);

    if (this.isAvalista) {
      this.fichaCadastro.setPayload('avalistas', [
        {
          cpf: this.formatCpf(this.fichaCadastro._avalista.cpf),
          dataNascimento: this.formatDateMiliseconds(this.fichaCadastro._avalista.dataNascimento),
          ddd: this.fichaCadastro._avalista.telefones.celular.substring(0, 2),
          celular: this.fichaCadastro._avalista.telefones.celular.substring(2),
          renda: this.fichaCadastro._avalista.renda,
          grauParentesco: this.fichaCadastro._avalista.grauParentesco,
        }
      ]);
    }

    this.fichaCadastro.onClickNext();

    setFichaContent(this.fichaCadastro);
  }

  duplicatedCpf() {
    if (this.fichaCadastro._client.cpf) {
      return this.fichaCadastro._client.cpf === this.fichaCadastro._avalista.cpf;
    }
  }

  formatCpf(data) {
    if (data) {
      return data.replace(/[\.\-]/g, '');
    }
  }

  validateNextButton(formCliente, formAvalista) {
    if ((formCliente && !this.isAvalista) || (formCliente && formAvalista))  {
      return false;
    }
    return true;
  }

  formatDateMiliseconds(data) {
    return Data.toMilissegundos(data);
  }

  onChangeRelacAvalista(event) {
    this.isSelectedRelacAvalista = event.target.selectedIndex !== 0;
  }

  setDadosInFichaCadastro(data) {
    this.fichaCadastro._client = new RecuperaCliente(data.clientes[0]).$client;

    if (data.avalista) {
      this.fichaCadastro._avalista = new RecuperaAvalista(data.avalista[0]).$avalista;
    }
  }

  getFicha(data) {
    this.router.navigate([`/resultadoparcial/${data.id}`]);
  }
}
