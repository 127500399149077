import { DialogService } from './../../../../services/dialog/dialog.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Component, OnInit, EventEmitter, OnDestroy, Input } from '@angular/core';

import * as toastr from 'toastr';
import { CreditoPessoalService } from '../../services/cp.service';

@Component({
  selector: 'app-modal-alterar-email',
  templateUrl: './modal-alterar-email.component.html',
  styleUrls: ['./modal-alterar-email.component.css']
})
export class ModalAlterarEmailComponent implements OnInit, OnDestroy {

  @Input() cpf: string;
  form: FormGroup;
  onChangeEmail = new EventEmitter();
  alive = true;
  loading = false;

  constructor(
    private instance: BsModalRef,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private service: CreditoPessoalService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: []
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  close() {
    this.instance.hide();
  }

  async save() {
    try {
      this.loading = true;
      const email = this.form.get('email').value;
      const emailEmUso = await this.emailEmUso(email);
      if (emailEmUso) {
        const message = 'O email informado está vinculado a outro CPF em nosso banco de dados.</p>'
              + '<p>Para prosseguir, troque por outro e-mail ou utilize outra modalidade de <strong>Assinatura Digital</strong>.</p>'
              + '<p>Caso nenhuma das opções digitais funcionem, temos a opção de <strong>Assinatura Manual</strong>.';
        this.dialogService.info({
          body: message,
          title: 'Atenção',
          classList: ['modal-md'],
          textClass: 'text-warning',
          iconClass: 'text-warning fa fa-exclamation-triangle'
        });
      } else {
        this.changeMail(email);
      }
    } catch (error) {
      toastr.error(error.message);
    } finally {
      this.loading = false;
    }
  }

  private async changeMail(email) {
    try {
      this.loading = true;
      await this.service.changeEmail({ email, cpf: this.cpf });
      this.onChangeEmail.emit({ email });
      this.instance.hide();
    } finally {
      this.loading = false;
    }
  }

  private async emailEmUso(email) {
    const response = await this.service.verificarEmailDuplicado(this.cpf, email);
    return response.emailEmUso;
  }
}
