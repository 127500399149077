import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FaefStateService } from './../faef-state.service';
import { Faef, FaefComercio } from './../model/faef';

@Component({
  selector: 'app-vendas-margem',
  templateUrl: './vendas-margem.component.html',
  styleUrls: ['./vendas-margem.component.css']
})
export class VendasMargemComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  vendaForm: FormGroup;
  mercadoriaForm: FormGroup;
  mercadorias: FaefComercio[];
  editing = -1;

  constructor(
    private fb: FormBuilder,
    private state: FaefStateService
  ) { }

  ngOnInit() {
    this.createForms();

    const faef = this.state.getLast();
    this.mercadorias = faef.faefComercio || [];
    if (faef.faefVendas) {
      this.vendaForm.patchValue(faef.faefVendas, { emitEvent: false });
    }
  }

  private createForms() {
    this.vendaForm = this.fb.group({
      faefPrazoCliente: ['', Validators.required],
      valorADoisMeses: ['', Validators.required],
      valorATresMeses: ['', Validators.required],
      valorAUmMes: ['', Validators.required]
    });

    this.mercadoriaForm = this.fb.group({
      nomeMercadoria: ['', Validators.required],
      precoUnitarioCompra: ['', Validators.required],
      precoUnitarioVenda: ['', Validators.required],
      quantidadeEstoque: ['', Validators.required],
      quantidadeVendida: ['', Validators.required]
    });
  }

  private updateState() {
    const faef:Faef = this.state.getLast();
    const { faefPrazoCliente, valorADoisMeses, valorATresMeses, valorAUmMes } = this.vendaForm.value;

    faef.faefVendas = {
      faefPrazoCliente: Number(faefPrazoCliente),
      valorADoisMeses: Number(valorADoisMeses),
      valorATresMeses: Number(valorATresMeses),
      valorAUmMes: Number(valorAUmMes)
    };

    faef.faefComercio = this.mercadorias.map(m => ({
      ...m,
      precoUnitarioCompra: Number(m.precoUnitarioCompra),
      precoUnitarioVenda: Number(m.precoUnitarioVenda),
      quantidadeEstoque: Number(m.quantidadeEstoque),
      quantidadeVendida: Number(m.quantidadeVendida)
    }));
    this.state.setState(faef);
  }

  async onClickNext() {
    this.updateState();
    this.next.emit();
  }

  onClickPrevious() {
    this.updateState();
    this.previous.emit();
  }

  addMercadoria() {
    if (this.editing < 0) {
      this.mercadorias.push(this.mercadoriaForm.value as FaefComercio);
    } else {
      this.mercadorias[this.editing] = this.mercadoriaForm.value as FaefComercio;
    }
    this.mercadoriaForm.reset();
    this.editing = -1;
  }

  onMercadoriaEdit(index: number) {
    this.editing = index;
    this.mercadoriaForm.setValue(this.mercadorias[index]);
  }

  onMercadoriaRemove(index: number) {
    this.mercadoriaForm.reset();
    this.editing = -1;
    this.mercadorias = this.mercadorias.filter(m => m !== this.mercadorias[index]);
  }

  cancelar() {
    this.mercadoriaForm.reset();
    this.editing = -1;
  }

}
