import { Telefone } from './telefone';
export class Referencia {
  public nome: string;
  public idGrauRelacionamento: number;
  public telefone: any;

  constructor(nome?: string, idGrauRelacionamento?: number, telefone?: Telefone) {
    this.nome = nome;
    this.idGrauRelacionamento = idGrauRelacionamento;
    this.telefone = telefone;
  }

  setNome(nome: string) {
    this.nome = nome;
  }

  setIdGrauRelacionamento(idGrauRelacionamento: number) {
    this.idGrauRelacionamento = idGrauRelacionamento;
  }

  setTelefone(telefone: Telefone) {
    this.telefone = telefone;
  }
}
