import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeWhile, take, finalize } from 'rxjs/operators';
import { CepService } from '../../../../../../services/cep/cep.service';
import { EnderecoCep } from '../../../../../../services/cep/endereco-cep';
import { NewModalMessageComponent } from '../../../../../../shared/modais/new-modal-message/new-modal-message.component';

@Component({
  selector: 'app-endereco-base',
  templateUrl: './endereco-base.component.html',
  styleUrls: ['./endereco-base.component.scss']
})
export class EnderecoBaseComponent implements OnInit, OnDestroy {
  @ViewChild('cepInvalidoModal', { static: true }) cepInvalidoModal: NewModalMessageComponent;
  @Input() tipoEndereco: string;
  @Input() enderecoForm: FormGroup;
  @Input() disabled = false;
  @Output() repetirEndResidencialEvent = new EventEmitter<boolean>();
  currentForm: FormGroup;

  private isAlive: boolean = true;
  hasLogradouro: boolean = false;
  hasBairro: boolean = false;
  isBuscandoCep: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cepService: CepService
  ) { }

  ngOnInit() {
    this.initForm();
    this.initObservers();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initForm(): void {
    this.currentForm = this.fb.group({
      cep: [{ value: '', disabled: this.disabled }, Validators.required],
      logradouro: [{ value: '', disabled: this.disabled }, Validators.required],
      numero: [{ value: '', disabled: this.disabled }, Validators.required],
      complemento: [{ value: '', disabled: this.disabled }],
      bairro: [{ value: '', disabled: this.disabled }, Validators.required],
      uf: [{ value: '', disabled: true }, Validators.required],
      cidade: [{ value: '', disabled: true }, Validators.required],
      tipoEndereco: [{ value: `${this.tipoEndereco.toUpperCase()}`, disabled: this.disabled }],
      correspondencia: [{ value: false, disabled: this.disabled }]
    });

    this.enderecoForm.addControl(`${this.tipoEndereco}Form`, this.currentForm);
  }

  private initObservers(): void {
    this.cep
      .valueChanges.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => this.handleOnCepChange());
  }

  private handleOnCepChange(): void {
    if (this.cep.valid) {
      this.isBuscandoCep = true;

      this.cepService
        .findEnderecoByCep(this.cep.value.replace(/[^\d]/g, ''))
        .pipe(
          finalize(() => this.isBuscandoCep = false),
          take(1)
        )
        .subscribe(
          (res) => {
            this.fillEndereco(res);
            this.hasLogradouro = res.logradouro !== '';
            this.hasBairro = res.bairro !== '';
          },
          (error) => this.handleCepError(error)
        );
    }
  }

  private fillEndereco(res: EnderecoCep): void {
    this.currentForm.patchValue({
      logradouro: res.logradouro,
      bairro: res.bairro,
      uf: res.uf,
      cidade: res.cidade,
      tipoEndereco: this.tipoEndereco.toUpperCase(),
    });
  }

  private handleCepError(error: any) {
    if (error.error) {
      this.cepInvalidoModal.showModal({
        titulo: 'Erro',
        mensagem: error.error.message
      });
    }
    this.resetForm();
  }

  private resetForm(): void {
    this.currentForm.reset();
    this.uf.setValue('');
    this.cidade.setValue('');
    this.currentForm.controls.correspondencia.setValue(false);
  }

  get cep(): AbstractControl {
    return this.currentForm.get('cep');
  }

  get endereco(): AbstractControl {
    return this.currentForm.get('logradouro');
  }

  get numero(): AbstractControl {
    return this.currentForm.get('numero');
  }

  get complemento(): AbstractControl {
    return this.currentForm.get('complemento');
  }

  get bairro(): AbstractControl {
    return this.currentForm.get('bairro');
  }

  get uf(): AbstractControl {
    return this.currentForm.get('uf');
  }

  get cidade(): AbstractControl {
    return this.currentForm.get('cidade');
  }
  public repetirEndereco(data: boolean) {
    this.repetirEndResidencialEvent.emit(data);
  }
}
