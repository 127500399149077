import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-erro-ao-acessar-camera',
  templateUrl: './modal-erro-ao-acessar-camera.component.html',
  styleUrls: ['./modal-erro-ao-acessar-camera.component.scss'],
})
export class ModalErroAoAcessarCameraComponent {
  closeModal: Subject<boolean> = new Subject();

  constructor(private modalInstance: BsModalRef) {}

  close() {
    this.closeModal.next(true);
    this.modalInstance.hide();
  }
}
