import { throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SegurosRestService } from '../omni-rest/seguros/seguros-rest.service';
@Injectable()
export class SegurosService {
  constructor(
    private segurosRestService: SegurosRestService
  ) { }
  findInfoProposta(idProposta: string): Observable<any> {
    return this.segurosRestService.get(`suhai/info/${idProposta}`);
  }
  findPerguntas(): Observable<any> {
    return this.segurosRestService.get(`suhai/question`);
  }
  saveCotacao(idProposta: string, data): Observable<any> {
    return this.segurosRestService.post(`suhai/salvar/cotacao/${idProposta}`, data);
  }
}
