import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectPeriodoModule } from '../select-periodo/select-periodo.module';
import { SelectProdutoModule } from './../../shared/select-produto/select-produto.module';
import { PanelModule } from './../panel/panel.module';
import { RankingComponent } from './ranking.component';

@NgModule({
  imports: [
    CommonModule,
    SelectPeriodoModule,
    SelectProdutoModule,
    PanelModule
  ],
  declarations: [
    RankingComponent
  ],
  exports: [
    RankingComponent
  ]
})
export class RankingModule { }
