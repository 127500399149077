import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BreadcrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { SharedModule } from './../shared/shared.module';
import { ConfiguracaoComponent } from './configuracao.component';
import { ConfiguracaoService } from './configuracao.service';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, BreadcrumbModule],
  declarations: [ConfiguracaoComponent],
  providers: [ConfiguracaoService],
})
export class ConfiguracaoModule {}
