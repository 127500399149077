import { Injectable } from '@angular/core';
import { CreditoPessoalRestService } from '../../../omni-rest/cp/credito-pessoal-rest.service';
import { Observable, of as observableOf } from 'rxjs';
import { Proposta } from '../../shared/models/proposta';
import { Cliente } from '../../shared/models/cliente';
import { TimelinePropostaVO } from '../../shared/models/timelineVO';
import { catchError } from 'rxjs/operators';
import { HttpStatus } from '../../../omni-rest/http-status';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class CreditoPessoalService {
  constructor(private restService: CreditoPessoalRestService) {}

  analisarCpf(body: any): Observable<any> {
    return this.restService.post('clientes/consulta-cpf', body);
  }

  listaLojistaOperador(operacao?: number): Observable<any> {
    return this.restService.get(`lojistas/${operacao}`);
  }

  listarPromotores(lojista: number): Observable<any> {
    return this.restService.get(`lojistas/${lojista}/promotores`);
  }

  dadosBancarios(idLojista: any): Observable<any> {
    return this.restService.get(`lojistas/lista-dadosbancarios/${idLojista}`);
  }

  experienciaCredito(solicitante: string): Observable<any> {
    return this.restService.get(`lojistas/lista-experiencia/${solicitante}`);
  }

  produtos() {
    return this.restService.get('operacoes/produtos');
  }

  operacoes(produto?: string) {
    if (produto) {
      return this.restService.get(`operacoes/${produto}`);
    }
    return this.restService.get('operacoes');
  }

  tabelas(operacao: number,  valor: number) {
    return this.restService.get(`operacoes/${operacao}/${valor}/tabelas`);
  }

  enviarSms(idCliente: number, body: any): Observable<any> {
    return this.restService.post(`clientes/${idCliente}/envia-sms`, body);
  }

  verificarToken(clienteId: number, token: string): Observable<any> {
    return this.restService.get(`clientes/${clienteId}/verifica-token/${token}`);
  }

  simulacao(body: any): Observable<any> {
    return this.restService.post('clientes/simulacao', body);
  }

  newSimulacao(proposta: Proposta): Observable<any> {
    const params = [];
    params['assistencia'] = proposta.assistencia === undefined ? -1 : proposta.assistencia;
    params['seguro'] = proposta.seguro === undefined ? -1 : proposta.seguro;
    params['prazo'] = proposta.qtdeParcelas || 0;
    params['carencia'] = proposta.carencia || 0;
    params['tabela-cdc'] = proposta.tabela.id;
    params['valor-entrada'] = 0;
    params['valor-liberado'] = proposta.valorLiquido;
    params['operacao'] = proposta.operacao.id;
    if (proposta.id != null) {
      return this.restService.get(`proposta/${proposta.id}/simula-valores`, { params });
    }
    return this.restService.get('proposta/simula-valores', { params });
  }

  private removerTransients(propostaEntity: Proposta): Proposta {
    const proposta = JSON.parse(JSON.stringify(propostaEntity));
    delete proposta.transients;
    if (proposta.cliente) {
      delete proposta.cliente.transients;
    }
    if (proposta.avalistas) {
      for (const key in proposta.avalistas) {
        delete proposta.avalistas[key].transients;
      }
    }
    return proposta;
  }

  finalizaSimulacao(propostaEntity: Proposta): Observable<any> {
    const proposta = this.removerTransients(propostaEntity);
    return this.restService.post(`proposta/${proposta.id}/envia-mesa`, { proposta });
  }

  criarProposta(propostaEntity: Proposta): Observable<any> {
    const proposta = this.removerTransients(propostaEntity);
    delete proposta.cliente.id;
    if (proposta.avalistas) {
      Object.getOwnPropertyNames(proposta.avalistas)
      .forEach(prop => delete proposta.avalistas[prop].id);
    }
    return this.restService.post('proposta', { proposta });
  }

  preAnalisarCrivo(propostaEntity: Proposta): Observable<any> {
    const proposta = this.removerTransients(propostaEntity);
    return this.restService.put(`proposta/${proposta.id}/analyze`, { proposta });
  }

  getWebLogicStatusAnalise(idProposta: any): Observable<any> {
    return this.restService.get(`proposta/${idProposta}/status/analise`).pipe(catchError((error, source) => {
      if (error.status === HttpStatus.BAD_REQUEST) {
        return observableOf(error.error);
      }
      return observableThrowError(error);
    }));
  }

  salvarProposta(propostaEntity: Proposta, etapa: number): Observable<any> {
    const proposta = this.removerTransients(propostaEntity);
    return this.restService.put(`proposta/${proposta.id}`, { proposta, etapa });
  }

  finalizaFicha(proposta: Proposta): Observable<any> {
    return this.restService.put(`proposta/${proposta.id}/finaliza-ficha`, { cliente: proposta.cliente, geracao: proposta.geracao, promotor: proposta.promotor });
  }

  desistirFicha(propostaEntity: Proposta, motivo: number): Observable<any> {
    propostaEntity.codigoMotivoDesistencia = motivo;
    const proposta = this.removerTransients(propostaEntity);
    return this.restService.put(`proposta/${proposta.id}/motivo-desistencia`, { motivo });
  }

  getTimeline(propostaId: number): Observable<TimelinePropostaVO> {
    return this.restService.get(`proposta/${propostaId}/timeline`);
  }

  findById(propostaId: number): Observable<Proposta> {
    return this.restService.get(`proposta/${propostaId}`);
  }

  consultarCPF(cpf: string): Observable<Cliente> {
    return this.restService.get(`clientes/${cpf}`);
  }

  excluirFicha(propostaId: number): Observable<string> {
    return this.restService.delete(`proposta/${propostaId}`);
  }

  getMotivosSosInverso(propostaId: number) {
    return this.restService.get(`proposta/${propostaId}/motivos-sos-inverso`);
  }

  getDiasPagamento() {
    return this.restService.get(`proposta/dias-pagamento`);
  }

  sendMotivoSosInverso(propostaId: number, propostaSos: any) {
    propostaSos.idProposta = propostaId;
    return this.restService.post(`proposta/${propostaId}/sos/inverso`, { propostaSos });
  }

  sendRespostaSos(propostaId: number, observacao: string, local: number) {
    if (local === 1) {
      return this.restService.put(`proposta/${propostaId}/sos/resposta`, { observacao, local });
    }

    return this.restService.put(`proposta/${propostaId}/confirm-refused-documents`, {});
  }

  pushDocumentacao(propostaId: number): Observable<TimelinePropostaVO> {
    return this.restService.put(`proposta/${propostaId}/documents`, {});
  }

  pushFormalizacao(propostaId: number): Observable<TimelinePropostaVO> {
    return this.restService.put(`proposta/${propostaId}/formalization`, {});
  }

  createEnvelopeDocusign(contractId: string) {
    return this.intercept(async() => {
      const response = await this.restService.get(`contracts/${contractId}/envelope`).toPromise();
      if (response.status === 'SUCCESS') {
        return response.url;
      }

      throw new Error(response.message);
    });
  }

  sendToken(proposta: number, tipoComunicacao: number) {
    return this.intercept(() => this.restService.post(`proposta/${proposta}/${tipoComunicacao}`).toPromise());
  }

  resendToken(body: any) {
    return this.intercept(() => this.restService.post('proposta/reenviar-assinatura', body).toPromise());
  }

  findSignTypes(contractId: string) {
    return this.intercept(() => this.restService.get(`contracts/${contractId}/sign-types`).toPromise());
  }

  changeToManualSign(contractId: string) {
    return this.intercept(() => this.restService.put(`contracts/${contractId}/manual-sign`).toPromise());
  }

  async intercept(runnable: () => any) {
    try {
      return await runnable();
    } catch (response) {
      if (response.error && response.error.messages && !`${response.error.messages[0]}`.includes('500')) {
        throw new Error(response.error.messages[0]);
      }
      console.log(response);

      throw new Error('Falha ao acessar o serviço!');
    }
  }

  changeEmail({ cpf, email }) {
    return this.intercept(() => this.restService.put(`clientes/${cpf}/update-email`, { email }).toPromise());
  }

  verificarContratoAssinado(contractId: string) {
    return this.restService.get(`contracts/${contractId}/signed`).toPromise();
  }

  agenciaBancaria(banco: number, agencia: string) {
    return this.restService.get(`clientes/${banco}/${agencia}`);
  }

  verificarEmailDuplicado(cpf, email) {
    const params = [];
    params['email'] = email;
    return this.restService.get(`clientes/${cpf}/emails/validate`, { params }).toPromise();
  }

  salvarEtapaCadastroProposta(proposta: Proposta, etapa: number): Observable<any> {
    return this.restService.put(`proposta/${proposta.id}/etapa-cadastro/${etapa}`);
  }

}
