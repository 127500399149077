import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Parcerias } from '../../../login/model/login-usuario-to';
import { CdcLojaRestService } from '../../../omni-rest/cdc/cdc-loja-rest.service';
import { TimerToken } from '../../../shared/timer-token/timer-token';
import { Cliente, ClienteTelefone } from '../../shared/models/cliente';
import { Proposta } from '../../shared/models/proposta';
import { TimelinePropostaVO } from '../../shared/models/timelineVO';
import { ContratoCessao } from '../cessao/contrato-cessao';
import { PaginaCessao } from '../cessao/pagina-cessao';
import { DisparoAnaliseAutomatica } from '../models/analise-automatica-status';
import { StatusCapturaFotoUnico } from '../models/captura-unico';
import { MotivoRecusa, MotivoRecusaDTO } from '../models/motivo-recusa';

export interface Tabela {
  id: number;
  carencia: number;
  descricao: string;
}

@Injectable()
export class CdcLojaService {
  constructor(private restService: CdcLojaRestService) { }

  analisarCpf(body: any): Observable<any> {
    return this.restService.post('clientes/consulta-cpf', body);
  }

  listaLojistaOperador(operacao?: number): Observable<any> {
    return this.restService.get(`lojistas/${operacao}`);
  }

  dadosBancarios(idLojista: any): Observable<any> {
    return this.restService.get(`lojistas/lista-dadosbancarios/${idLojista}`);
  }

  experienciaCredito(solicitante: string): Observable<any> {
    return this.restService.get(`lojistas/lista-experiencia/${solicitante}`);
  }

  produtos() {
    return this.restService.get('operacoes/produtos');
  }

  operacoes(produto?: string) {
    if (produto) {
      return this.restService.get(`operacoes/${produto}`);
    }
    return this.restService.get('operacoes');
  }

  tabelas(operacao: number): Observable<Tabela[]> {
    return this.restService.get(`operacoes/${operacao}/tabelas`);
  }

  enviarSms(body: {
    proposta: {
      cliente: {
        telefones: ClienteTelefone[];
      };
      id: number;
      parceiro?: Parcerias;
    }
  }): Observable<TimerToken> {
    return this.restService.post('clientes/envia-sms', body);
  }

  verificarToken(proposta: string, token: string, telefone: string): Observable<any> {
    return this.restService.get(`clientes/verifica-token/${proposta}/${token}/${telefone}`);
  }

  simulacao(body: any): Observable<any> {
    return this.restService.post('clientes/simulacao', body);
  }

  newSimulacao(proposta: Proposta): Observable<any> {
    const params: Record<string, number> = {};
    params['assistencia'] = proposta.assistencia === undefined ? -1 : proposta.assistencia;
    params['seguro'] = proposta.seguro === undefined ? -1 : proposta.seguro;
    params['prazo'] = proposta.qtdeParcelas || 0;
    params['carencia'] = proposta.carencia || 0;
    params['tabela-cdc'] = proposta.tabela.id;
    params['valor-entrad'] = 0;
    params['valor-liberado'] = proposta.valorLiquido;

    return this.restService.get(`proposta/${proposta.id}/simula-valores`, { params });
  }

  finalizaSimulacao(proposta: Proposta): Observable<any> {
    delete proposta.transients;
    return this.restService.post(`proposta/${proposta.id}/envia-mesa`, { proposta });
  }

  criarProposta(proposta: Proposta): Observable<any> {
    delete proposta.transients;
    return this.restService.post('proposta', { proposta });
  }

  salvarProposta(proposta: Proposta): Observable<any> {
    delete proposta.transients;
    return this.restService.put(`proposta/${proposta.id}`, { proposta });
  }

  finalizaFicha(proposta: Proposta): Observable<any> {
    return this.restService.put(`proposta/${proposta.id}/finaliza-ficha`, { cliente: proposta.cliente });
  }

  desistirFicha(proposta: Proposta, motivo: number): Observable<any> {
    proposta.codigoMotivoDesistencia = motivo;
    delete proposta.transients;
    return this.restService.put(`proposta/${proposta.id}/motivo-desistencia`, { motivo });
  }

  getTimeline(propostaId: number): Observable<TimelinePropostaVO> {
    return this.restService.get(`proposta/${propostaId}/timeline`);
  }

  findById(propostaId: number): Observable<Proposta> {
    return this.restService.get(`proposta/${propostaId}`);
  }

  consultarCPF(cpf: string): Observable<Cliente> {
    return this.restService.get(`clientes/${cpf}`);
  }

  excluirFicha(propostaId: number): Observable<string> {
    return this.restService.delete(`proposta/${propostaId}`);
  }

  getMotivosSosInverso(propostaId: number) {
    return this.restService.get(`proposta/${propostaId}/motivos-sos-inverso`);
  }

  sendMotivoSosInverso(propostaId: number, propostaSos: any) {
    propostaSos.idProposta = propostaId;
    return this.restService.post(`proposta/${propostaId}/sos/inverso`, { propostaSos });
  }

  sendRespostaSos(propostaId: number, observacao: string, local: number) {
    if (local === 1) {
      return this.restService.put(`proposta/${propostaId}/sos/resposta`, { observacao, local });
    }

    return this.restService.put(`proposta/${propostaId}/confirm-refused-documents`, {});
  }

  pushDocumentacao(propostaId: number): Observable<TimelinePropostaVO> {
    return this.restService.put(`proposta/${propostaId}/documents`, {});
  }

  pushFormalizacao(propostaId: number): Observable<TimelinePropostaVO> {
    return this.restService.put(`proposta/${propostaId}/formalization`, {});
  }

  criaEnvelopeAssinatura(contrato: String, tipoAssinatura: String): Observable<any> {
    return this.restService.post(`contracts/${contrato}/${tipoAssinatura}`);
  }

  buscarRetornoAssinatura(contrato: String) {
    return this.restService.get(`contracts/${contrato}/batch-status`);
  }

  reenviaEnvelopeAssinatura(propostaId: number, numContrato: string, tpAssinatura: string) {
    const body = {
      proposta: propostaId,
      contrato: numContrato,
      tipoAssinatura: tpAssinatura
    };
    return this.intercept(() => this.restService.post('contracts/resend-sign', body).toPromise());
  }

  createEnvelopeDocusign(contractId: string) {
    return this.intercept(async () => {
      const response = await this.restService.get(`contracts/${contractId}/envelope`).toPromise();
      if (response.status === 'SUCCESS') {
        return response.url;
      }

      throw new Error(response.message);
    });
  }

  sendSmsToken(contractId: string) {
    return this.intercept(() => this.restService.get(`contracts/${contractId}/1`).toPromise());
  }

  sendEmailToken(contractId: string) {
    return this.intercept(() => this.restService.get(`contracts/${contractId}/2`).toPromise());
  }

  async intercept(runnable: () => any) {
    try {
      return await runnable();
    } catch (response) {
      if (response.error && response.error.messages && !`${response.error.messages[0]}`.includes('500')) {
        throw new Error(response.error.messages[0]);
      }
      console.error(response);

      throw new Error('Falha ao acessar o serviço!');
    }
  }

  changeEmail({ propostaId, email }) {
    return this.intercept(() => this.restService.put(`clientes/update-email/${propostaId}`, { email }).toPromise());
  }

  verificarContratoAssinado(contractId: string) {
    return this.restService.get(`contracts/${contractId}/signed`).toPromise();
  }

  cadastrarVendedor({ codigoLojista, nome }) {
    if (!codigoLojista) {
      throw new Error('Selecione uma loja!');
    }

    const vendedor = { codigoLojista, nome: nome.toUpperCase() };
    return this.intercept(() => this.restService.post('sellers', { vendedor }).toPromise());
  }

  changeFlagAnalfabeto({ propostaId, analfabeto }) {
    return this.intercept(() => this.restService.put(`clientes/update-flag-analfabeto/${propostaId}`, { analfabeto }).toPromise());
  }

  getAssessment(body: any): Observable<any> {
    return this.restService.post('clientes/assessment', body);
  }

  saveCreditRequest(proposta: number, body: any): Observable<any> {
    return this.restService.post(`clientes/credit-request/${proposta}`, body);
  }

  paginatedListAssignment(page: number, size: number, liberado: boolean): Observable<PaginaCessao> {
    const params: Record<string, string> = {
      page: `${page}`,
      size: `${size}`,
    };
    return this.restService.get(`assignment/released/${liberado}`, { params });
  }

  filteredListAssignment(liberado: boolean, filtro: { proposta?: string, contrato?: string, cpf?: string }): Observable<ContratoCessao[]> {
    const params: Record<string, string> = {};
    (<any>Object).entries(filtro).forEach(([key, value]) => {
      if (!!value) {
        params[key] = `${value}`;
      }
    });

    return this.restService.get(`assignment/list/released/${liberado}`, { params });
  }

  public signAssignment(body: any): Observable<any> {
    return this.restService.put('assignment/sign', body);
  }

  public saveAssingment(body: any): Observable<any> {
    return this.restService.put('assignment/save', body);
  }

  public listarMercadoria(): Observable<any> {
    return this.restService.get('mercadorias/tipos');
  }

  public getStatusBiometria(proposta: number): Observable<any> {
    return this.restService.get(`biometria/${proposta}/status`);
  }

  public enviarBiometriaUnico(proposta: number, body: any): Observable<any> {
    return this.restService.post(`biometria/unico/${proposta}`, body);
  }

  analisarProposta({ id }: Proposta, disparo: DisparoAnaliseAutomatica): Observable<{ status: string }> {
    return this.restService.get(`proposta/${id}/${disparo}/resultado-analise`);
  }

  checkAnalisePropostaAssincrona(): Observable<{
    analiseAutomaticaAssincrona: 'S' | 'N'
    status: string
  }> {
    return this.restService.get('proposta/tipo-analise');
  }

  public getStatusBiometriaProposta(propostaId: number): Observable<StatusCapturaFotoUnico> {
    return this.restService.get(`biometria/unico/status/${propostaId}`);
  }

  cancelarLinkAutenticacao(propostaId: number): Observable<void> {
    return this.restService.put(`biometria/unico/${propostaId}/cancelar-link`, null);
  }

  getMotivosRecusaProposta(): Observable<MotivoRecusaDTO> {
    return this.restService.get('proposta/motivos-recusa');
  }

  atualizarMotivosRecusaProposta(propostaId: number, motivos: MotivoRecusa[]): Observable<void> {
    return this.restService.put(`proposta/${propostaId}/motivo-recusa`, { motivos });
  }

  enviarSmsVerificacaoSeguranca(body: {
    proposta: {
      cliente: {
        nomeCliente: string;
        cpf: string;
        telefones: ClienteTelefone[];
      };
      id: number;
      agente: { id: number };
      rastreioStatus?: { codigo: number; descricao: string };
      operacao: {
        produto: string;
      };
    }
  }): Observable<void> {
    return this.restService.post('clientes/seguranca/envia-sms', body);
  }

  enviarPropostaParaAnalise(propostaId: number, body?: Parcerias): Observable<void> {
    return this.restService.post(`clientes/${propostaId}/enviar-para-analise`, body);
  }

  checkParametro(parametro: string): Observable<{ key: string; value: 'S' | 'N' }> {
    return this.restService.get(`parametro/${parametro}`);
  }

  checkCelularValidado(propostaId: number): Observable<any> {
    return this.restService.get(`proposta/${propostaId}/celular-validado`);
  }
}
