
import { Component } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { Periodo } from '../select-periodo/periodo/periodo';
import { Produto } from './../../shared/select-produto/model/produto';
import { DashboardService } from './../dashboard.service';
import { Filtro } from './../filtro';
import { PeriodoFactory } from './../select-periodo/periodo/periodo.factory';

declare var Chart: any;

@Component({
  selector: 'app-meu-desempenho',
  templateUrl: './meu-desempenho.component.html',
  styleUrls: ['./meu-desempenho.component.css']
})
export class MeuDesempenhoComponent {

  private filtro: Filtro = new Filtro();

  opcoesPeriodos = [PeriodoFactory.mes(), PeriodoFactory.semana(), PeriodoFactory.tresDias(PeriodoFactory.PADRAO)];
  dadosDesempenho: any;
  loading = true;
  periodo;
  produtos;

  constructor(private service: DashboardService) { }

  produtoChange(produtos: Produto[]): void {
    this.produtos = produtos;

    this.filtro.produtos = produtos.map(produto => {
      return {
        checked: produto.checked,
        descricao: produto.descricaoNormalized
      };
    });
    this.buscarDashboard();
  }

  periodoChange(periodo: Periodo): void {
    this.periodo = periodo.descricao;
    this.filtro.dtInicio = periodo.dataInicio;
    this.filtro.dtFim = periodo.dataFim;
    this.buscarDashboard();
  }

  private buscarDashboard(): void {
    if (this.produtos !== undefined && this.periodo !== undefined) {
      this.loading = true;
      this.dadosDesempenho = {};
      this.service.buscarDashboardLojistaOperador(this.filtro).pipe(
        take(1),
        finalize(() => this.loading = false))
        .subscribe(result => this.dadosDesempenho = result.retorno);
    }
  }

}
