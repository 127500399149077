import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-botao-navegacao-clean',
  templateUrl: './botao-navegacao-clean.component.html',
  styleUrls: ['./botao-navegacao-clean.component.scss']
})
export class BotaoNavegacaoCleanComponent {
  @Input() previousShow = true;
  @Input() previousEnabled = true;
  @Input() nextShow = true;
  @Input() nextEnabled = true;
  @Input() nextLabel = 'Continuar';
  @Input() previousLabel = 'Voltar';
  @Input() buttonIcon = 'fa-arrow-left';

  @Output() previous = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  onClickPrevious() {
    this.previous.emit();
  }

  onClickNext() {
    this.next.emit();
  }
}
