
import { take } from 'rxjs/operators';
import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { NacionalidadesService } from '../../services/nacionalidade/nacionalidades.service';
import { Nacionalidade } from './nacionalidade';

@Component({
  selector: 'app-nacionalidade',
  templateUrl: './nacionalidade.component.html',
  styleUrls: ['./nacionalidade.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NacionalidadeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NacionalidadeComponent),
      multi: true,
    }]
})
export class NacionalidadeComponent implements OnInit, Validator, ControlValueAccessor {

  nacionalidade: Nacionalidade = new Nacionalidade();

  nacionalidades: any[];
  ufs: any[];
  cidades: any[];

  isBuscandoCidade = false;

  constructor(
    private _nacionalidadeService: NacionalidadesService) { }

  ngOnInit() {
    this._nacionalidadeService.nacionalidades().pipe(take(1)).subscribe(res => this.nacionalidades = res);
  }

  onChangeNacionalidade() {
    this.nacionalidade.naturalDeUf = undefined;
    this.nacionalidade.naturalDe = undefined;
    this.onChange();
  }

  onChange() {
    this.propagateChange(this.nacionalidade);
  }

  validate(): { [key: string]: any; } {
    return this.nacionalidade.valid ? null : { valid: false };
  }

  public writeValue(obj: Nacionalidade) {
    if (obj) {
      this.nacionalidade = obj;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // This is intentional
  }

  private propagateChange = (_: any) => {
    // This is intentional
  }
}
