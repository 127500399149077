import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css'],
})
export class RecordComponent implements OnChanges {
  @Input() label: string;
  @Input() propostas: any[];
  @Input() periodo;

  isShowList = true;

  ngOnChanges() {
    this.isShowList = this.periodo !== 'Mês';
  }

  onHideShowRecordsList() {
    this.isShowList = !this.isShowList;
  }
}
