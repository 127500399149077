import { Cliente, ClienteTelefone } from './../../../../../shared/models/cliente';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Proposta } from './../../../../../shared/models/proposta';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-confirmar-telefone',
  templateUrl: './modal-confirmar-telefone.component.html',
  styleUrls: ['./modal-confirmar-telefone.component.scss']
})
export class ModalConfirmarTelefoneComponent implements OnInit, OnDestroy {

  @Input() proposta: Proposta;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  clienteForm: FormGroup;
  avalistasForm: FormGroup[];
  alive = true;

  constructor(public modalRef: BsModalRef, private fb: FormBuilder) { }

  ngOnInit() {
    this.clienteForm = this.fb.group([]);
    this.avalistasForm = this.avalistas.map(_ => this.fb.group([]));

    this.fillClienteForm(this.cliente, this.clienteForm);
    this.avalistas.forEach((avalista, i) => this.fillClienteForm(avalista, this.avalistasForm[i]));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private fillClienteForm(cliente: Cliente, clienteForm: FormGroup) {
    if (cliente.telefones) {
      const fone = cliente.telefones
        .filter(foneF => foneF.tipoTelefone === 'PROPRIO')
        .sort((a, _) => a.categoriaTelefone === 'CELULAR' ? -1 : 1)[0];
      this.fillFoneForm(fone, clienteForm);
    }
  }

  private fillFoneForm(fone: ClienteTelefone, formGroup: FormGroup) {
    setTimeout(
      () => {
        const form = <FormGroup> formGroup.controls['telefoneForm_-1'];
        if (!form) return;

        form.setValue(
          {
            tipoTelefone: fone.categoriaTelefone,
            telefone: this.formatFone(fone.ddd, fone.nrTelefone)
          },
          { emitEvent: false }
        );
      },
      500);
  }

  close() {
    this.modalRef.hide();
    this.onClose.emit(true);
  }

  get cliente() {
    return this.proposta.cliente;
  }

  get avalistas() {
    return Object.getOwnPropertyNames(this.proposta.avalistas)
            .map(key => this.proposta.avalistas[key]);
  }

  isConfirmadoTelefone(cliente: Cliente) {
    return cliente.transients.isConfirmadoTelefone === true;
  }

  get isTodosValidados() {
    return this.cliente.transients.isConfirmadoTelefone
      && (
        this.avalistas.length === 0
        || this.avalistas.every(avalista => avalista.transients.isConfirmadoTelefone)
      );
  }

  private formatFone(ddd: string, fone: string): string {
    if (!fone || fone.length < 8) {
      return '';
    }

    const telefone = fone;
    const telefoneSize = telefone.length;
    const telefonePart1 = telefone.substr(0, telefoneSize - 4);
    const telefonePart2 = telefone.substr(telefoneSize - 4, 4);
    return `(${ddd}) ${telefonePart1}-${telefonePart2}`;
  }
}
