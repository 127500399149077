export class ButtonSelectorData {

  id: string;
  label: string;
  icon: string;
  description: string;
  order: number;

  constructor(_id?, _label?, _icon?, _order?, description?) {
    this.id = _id;
    this.label = _label;
    this.icon = _icon;
    this.order = _order;
    this.description = description;
  }
}
