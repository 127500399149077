import * as moment from 'moment';
import { Periodo } from './periodo';

export class Mes implements Periodo {

  padrao = false;

  descricao = 'Mês';

  constructor(padrao?: boolean) {
    this.padrao = padrao || false;
  }

  get dataInicio(): number {
    return moment().startOf('month').unix() * 1000;
  }

  get dataFim(): number {
    return moment.now();
  }
}
