import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskModule } from 'soft-angular-mask';
import { SharedModule } from '../shared.module';
import { UfCidadeSelectModule } from '../uf-cidade-select/uf-cidade-select.module';
import { DirectivesModule } from './../../directives/directives.module';
import { TextDirectivesModule } from './../../directives/texto/text.directives.module';
import { LoadersModule } from './../loaders/loaders.module';
import { EnderecoComponent } from './endereco.component';
import { ModalModule } from 'ngx-bootstrap';
import { ModalMultiplosEnderecosComponent } from './modal-multiplos-enderecos/modal-multiplos-enderecos.component';
import { ModalEncontrarCepComponent } from './modal-encontrar-cep/modal-encontrar-cep.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    SharedModule,
    MaskModule,
    TextDirectivesModule,
    UfCidadeSelectModule,
    LoadersModule,
    ModalModule,
    ReactiveFormsModule
  ],
  declarations: [
    EnderecoComponent,
    ModalMultiplosEnderecosComponent,
    ModalEncontrarCepComponent,
  ],
  entryComponents: [
    ModalMultiplosEnderecosComponent,
    ModalEncontrarCepComponent,
  ],
  exports: [EnderecoComponent],
})
export class EnderecoModule { }
