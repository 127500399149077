import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeWhile, finalize } from 'rxjs/operators';
import * as toastr from 'toastr';

import { CdcLojaService } from '../../services/cdc-loja.service';
import { appSettings } from './../../../../../environments/app.setings';
import { ClienteTelefone } from './../../../shared/models/cliente';
import { Proposta } from './../../../shared/models/proposta';
import { ModalAlterarEmailComponent } from './../modal-alterar-email/modal-alterar-email.component';
import { ModalDocusignComponent } from './../modal-docusign/modal-docusign.component';
import { StatusAssinatura } from '../../models/status-assinatura';
import { TipoAssinatura } from '../../models/tipo-assinatura';

@Component({
  selector: 'app-modal-assinatura',
  templateUrl: './modal-assinatura.component.html',
  styleUrls: ['./modal-assinatura.component.scss']
})
export class ModalAssinaturaComponent {

  @Input() proposta: Proposta;
  enviouSms = false;
  enviouToken = false;
  enviouEmail = false;
  enviouSolicitacao = false;
  alive = true;
  timeout: any;
  showLoader = false;
  reenvio = false;

  constructor(
    private modal: BsModalService,
    private modalInstance: BsModalRef,
    private service: CdcLojaService) { }

  async sendToken() {
    try {
      return await this.service.createEnvelopeDocusign(this.proposta.contratoId);
    } finally {
      this.enviouToken = true;
    }
  }

  async reenviaEnvelopeAssinatura(tipoAssinatura: string) {
    this.reenvio = true;
    try {
      return await this.service.reenviaEnvelopeAssinatura(this.proposta.id, this.proposta.contratoId, tipoAssinatura);
    } finally {
      this.buscarRespostaAssinatura(1, tipoAssinatura);
      this.enviouSolicitacao = true;
    }
  }

  async criaEnvelopeAssinatura(tipoAssinatura: String) {
    try {

      const response = await this.service.criaEnvelopeAssinatura(this.proposta.contratoId, tipoAssinatura).toPromise();
      if (response.status === "SUCCESS") {
        this.buscarRespostaAssinatura(1, tipoAssinatura);
        this.enviouSolicitacao = true;
      }
      return response;

    } catch(err) {
      if (err.error && err.error.messages && err.error.messages.length > 0) {
        this.retornaErro(err.error.messages);
      } else {
        toastr.error('Falha ao gerar link de assinatura, tente novamente.');
      }  
    }
  }

  buscarRespostaAssinatura(tentativas: number, tipoAssinatura: String) {
    let tentativa = tentativas;
    this.showLoader = true;
    const maximoTentativas = 12;

    this.service.buscarRetornoAssinatura(this.proposta.contratoId)
      .pipe(
        takeWhile(() => this.alive))
      .subscribe(
        ({ status, messages }) => {
          if (tentativa >= maximoTentativas) {
            tentativa = 1;
            this.showLoader = false;
          } else if (status === StatusAssinatura.AGUARDANDO_LOTE || (status === StatusAssinatura.LOTE_GERADO && tentativa < maximoTentativas)) {
            this.timeout = setTimeout(() => this.buscarRespostaAssinatura(tentativa + 1, tipoAssinatura), 5000);
          } else if (status === StatusAssinatura.LOTE_NAO_GERADO) {
            this.showLoader = false;
            toastr.error('Falha ao gerar link de assinatura, tente novamente.');
          } else if (status === StatusAssinatura.FALHA_DOWNLOAD_DOC || status === StatusAssinatura.DOCUMENTO_AUSENTE || status === StatusAssinatura.TP_DOCUMENTO_NAO_ENCONTRADO) {
            this.showLoader = false;
            this.retornaErro(messages);
          } else if (status === StatusAssinatura.CLIENTE_NOTIFICADO) {
            this.showLoader = false;
            this.onTokenSend(messages, tipoAssinatura);
          }
        },
        () => {
          if (tentativa < maximoTentativas) {
            this.timeout = setTimeout(() => this.buscarRespostaAssinatura(tentativa + 1, tipoAssinatura), 5000);
            return;
          }
          this.showLoader = false;

        });
  }

  retornaErro(messages) {
    toastr.error(messages[0]);
  }

  resendToken(tipoAssinatura) {
    this.sendToken()
      .then((link) => this.onTokenSend(link, tipoAssinatura))
      .catch(this.showError);
  }

  async sendEmail() {
    try {
      return await this.service.sendEmailToken(this.proposta.contratoId);
    } finally {
      this.enviouEmail = true;
    }
  }

  resendEmail() {
    this.sendEmail()
      .catch(this.showError);
  }

  async sendSms() {
    try {
      return await this.service.sendSmsToken(this.proposta.contratoId);
    } finally {
      this.enviouSms = true;
    }
  }

  resendSms() {
    this.sendSms()
      .catch(this.showError);
  }

  onTokenSend(retorno, tipoAssinatura) {
    if (tipoAssinatura !== TipoAssinatura.TOKEN) {
      toastr.success('Link de assinatura gerado com sucesso!');
    } else {
      const link = retorno[0];
      const initialState = { urlContrato: link };
      this.modal.show(ModalDocusignComponent, { ...appSettings.MODAL_PARAMS, initialState });
    }
  }

  showError(error: Error) {
    toastr.error(error.message);
  }

  close() {
    this.modalInstance.hide();
  }

  alterarEmail() {
    const alterarEmailModal = <ModalAlterarEmailComponent>this.modal.show(ModalAlterarEmailComponent, appSettings.MODAL_PARAMS).content;
    alterarEmailModal.propostaId = this.proposta.id;
    alterarEmailModal.service = this.service;
    alterarEmailModal.onChangeEmail
      .pipe(takeWhile(() => alterarEmailModal.alive))
      .subscribe(event => this.proposta.cliente.email = event.email);
  }

  get celular(): ClienteTelefone {
    if (!this.proposta || !this.proposta.cliente) {
      return {};
    }

    return this.proposta
      .cliente
      .telefones
      .filter(item => 'CELULAR' === item.categoriaTelefone)[0];
  }

  get tipoAssinatura(): typeof TipoAssinatura {
    return TipoAssinatura;
  }
}
