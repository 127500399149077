export class TelefonePessoa {

  private idCategoria: number;
  private idTipoTelefone: number;
  private ddd: string;
  private numero: string;

  constructor(idCategoria?: number, idTipoTelefone?: number, ddd?: string, numero?: string) {
    this.idCategoria = idCategoria;
    this.idTipoTelefone = idTipoTelefone;
    this.ddd = ddd;
    this.numero = numero;
  }

  setIdCategoria(idCategoria: number) {
    this.idCategoria = idCategoria;
  }

  setIdTipoTelefone(idTipoTelefone: number) {
    this.idTipoTelefone = idTipoTelefone;
  }

  setDdd(ddd: string) {
    this.ddd = ddd;
  }

  setNumero(numero: string) {
    this.numero = numero;
  }

  getDdd(): string {
    return this.ddd;
  }

  getNumero(): string {
    return this.numero;
  }

}
