import { Injectable } from '@angular/core';
import { FichaCadastroTipo } from './ficha-cadastro-tipo';
import { Avalista } from './model/avalista';
import { Cliente } from './model/cliente';
import { Proposta } from './model/proposta';
import { RecuperaGarantia } from './model/recupera/recupera-garantia';
import { RecuperaPessoa } from './model/recupera/recupera-pessoa';

@Injectable()
export class FichaCadastro {
  public _tipo = FichaCadastroTipo.NOVA;

  //   Passos
  public PASSO_VENDEDOR = 1;
  public PASSO_CLIENTE = 2;
  public PASSO_VEICULO = 3;
  public PASSO_CONSULTANDO_DADOS = 4;
  public PASSO_RESULTADO_PARCIAL = 5;
  public PASSO_DETALHE_CLIENTE = 6;
  public PASSO_DETALHE_AVALISTA = 7;
  public PASSO_ENDERECO_CLIENTE = 8;
  public PASSO_ENDERECO_AVALISTA = 9;
  public PASSO_OBSERVACAO = 10;
  public PASSO_ANALISE = 11;

  //   Metadados
  public passo: number;
  public garantias = [];
  public codRenavam;
  public tipoSolicitante = '';
  public tipoProduto = '';
  public idLojista: any = '';
  public idVendedor: any = '';
  public isReadyToSave = false;
  public payload: any = {};
  public fromResultadoParcial = false;
  public idSimulacao: number = null;
  public proposta: any = {};
  [key: string]: any;
  public isClienteAproveitado = true;
  public isVeiculoAproveitado = true;
  public isDetalheClienteAproveitado = true;
  public isDetalheAvalistaAproveitado = true;
  public isEnderecoClienteAproveitado = true;
  public isEnderecoAvalistaAproveitado = true;
  public isFichaAntiga;
  public rendaCliente = 0;
  public getFichaLocalStorage = false;
  public grupo1: string;
  public isLeads = false;
  public garantiasOriginais = [];

  // Dados Cliente
  public _client = new Cliente();
  public _avalista = new Avalista();

  public _cliente: RecuperaPessoa;
  public enderecoCorrespondencia = '1';
  public _classeProfissionalCliente: any;

  // Dados Avalista
  public isAvalista = false;
  public avalistaCpf: string;
  public avalistaDataNascimento: any;
  public avalistaCelular: string;
  public avalistaRenda: number;
  public avalistaParentesco: string = '';
  public _classeProfissionalAvalista: any;
  public rendaAvalista = 0;

  // Dados Veículo
  public veiculoPlaca = '';
  public isUtilizacaoAgropecuaria = false;
  public isZeroKm = false;
  public isUsado = false;
  public ufLicenciamento = '';
  public veiculoCategoria = '';
  public veiculoMarca = '';
  public veiculoAno = '';
  public veiculoModelo = '';
  public veiculoVersao = '';
  public veiculoCor = '';
  public veiculoCombustivel = '';

  public garantia: RecuperaGarantia;

  public error: string;

  constructor() {
    this.passo = this.PASSO_VENDEDOR;
  }

  //   GETTERS AND SETTER METADADOS

  get solicitanteTipo() {
    return this.tipoSolicitante;
  }

  set solicitanteTipo(data) {
    this.tipoSolicitante = data;
  }

  get produtoTipo() {
    return this.tipoProduto;
  }
  set produtoTipo(data) {
    this.tipoProduto = data;
  }

  get IDLojista() {
    return this.idLojista;
  }

  set IDLojista(data) {
    this.idLojista = data;
  }

  get IDVendedor() {
    return this.idVendedor;
  }

  set IDVendedor(data) {
    this.idVendedor = data;
  }

  // GETTERS AND SETTER DADOS CLIENTES

  set $client(data) {
    this._client = data;
  }
  set $avalista(data) {
    this._avalista = data;
  }

  // GETTERS AND SETTER DADOS AVALISTA
  get cpfAvalista() {
    return this.avalistaCpf;
  }
  set cpfAvalista(data) {
    this.avalistaCpf = data;
  }

  get dataNascimentoAvalista() {
    return this.avalistaDataNascimento;
  }
  set dataNascimentoAvalista(data) {
    // espera receber data no formato UTC DD/MM/AAAA
    this.avalistaDataNascimento = data;
  }

  get telefoneCelularAvalista() {
    return this.avalistaCelular;
  }

  set telefoneCelularAvalista(data) {
    this.avalistaCelular = data;
  }

  get rendaComprovadaAvalista() {
    return this.avalistaRenda;
  }

  set rendaComprovadaAvalista(data) {
    this.avalistaRenda = data;
  }
  get parentescoAvalista() {
    return this.avalistaParentesco;
  }

  set parentescoAvalista(data) {
    this.avalistaParentesco = data;
  }

  // GETTERS AND SETTER DADOS VEICULO
  get placaVeiculo() {
    return this.veiculoPlaca;
  }
  set placaVeiculo(data) {
    this.veiculoPlaca = data;
  }

  get hasUtilizacaoAgropecuaria() {
    return this.isUtilizacaoAgropecuaria;
  }
  set hasUtilizacaoAgropecuaria(data) {
    this.isUtilizacaoAgropecuaria = data;
  }

  get hasZeroKm() {
    return this.isZeroKm;
  }

  set hasZeroKm(data) {
    this.isZeroKm = data;
    this.isUsado = false;
  }

  get hasUsado() {
    return this.isUsado;
  }

  get errorStatus() {
    return this.error;
  }

  setErrorStatus(errorStatus) {
    this.error = errorStatus;
  }

  set hasUsado(data) {
    this.isUsado = data;
    this.isZeroKm = false;
    this.isUtilizacaoAgropecuaria = false;
  }

  get categoriaVeiculo() {
    return this.veiculoCategoria;
  }
  set categoriaVeiculo(data) {
    this.veiculoCategoria = data;
  }
  get marcaVeiculo() {
    return this.veiculoMarca;
  }
  set marcaVeiculo(data) {
    this.veiculoMarca = data;
  }
  get anoVeiculo() {
    return this.veiculoAno;
  }
  set anoVeiculo(data) {
    this.veiculoAno = data;
  }
  get modeloVeiculo() {
    return this.veiculoModelo;
  }
  set modeloVeiculo(data) {
    this.veiculoModelo = data;
  }
  get versaoVeiculo() {
    return this.veiculoVersao;
  }
  set versaoVeiculo(data) {
    this.veiculoVersao = data;
  }
  get corVeiculo() {
    return this.veiculoCor;
  }
  set corVeiculo(data) {
    this.veiculoCor = data;
  }
  get combustivelVeiculo() {
    return this.veiculoCombustivel;
  }
  set combustivelVeiculo(data) {
    this.veiculoCombustivel = data;
  }
  get licenciamentoUF() {
    return this.ufLicenciamento;
  }
  set licenciamentoUF(data) {
    this.ufLicenciamento = data;
  }

  get tipo(): FichaCadastroTipo {
    return this._tipo;
  }

  passoAlterarVeiculo() {
    this._tipo = FichaCadastroTipo.ALTERACAO_VEICULO;
    this.passo = this.PASSO_VEICULO;
  }

  passoCorrigirFipeNotFound() {
    this._tipo = FichaCadastroTipo.CORRECAO_FIPE_NOT_FOUND;
    this.passo = this.PASSO_VEICULO;
  }

  get isPassoCorrigirFipeNotFound(): boolean {
    return this._tipo === FichaCadastroTipo.CORRECAO_FIPE_NOT_FOUND;
  }

  passoCorrigirPLacaInvalida() {
    this._tipo = FichaCadastroTipo.CORRECAO_PLACA_INVALIDA;
    this.passo = this.PASSO_VEICULO;
  }

  passoRetornarResultadoParcial() {
    this.passo = this.PASSO_RESULTADO_PARCIAL;
  }

  get isPassoCorrigirPLacaInvalida(): boolean {
    return this._tipo === FichaCadastroTipo.CORRECAO_PLACA_INVALIDA;
  }

  getPasso(): number {
    return this.passo;
  }

  setPasso(passo) {
    this.passo = passo;
  }

  getTipoSolicitante(): string {
    return this.tipoSolicitante;
  }

  setTipoSolicitante(tipoSolicitante: string) {
    this.tipoSolicitante = tipoSolicitante;
  }

  getTipoProduto(): string {
    return this.tipoProduto;
  }

  setTipoProduto(tipoProduto: string) {
    this.tipoProduto = tipoProduto;
  }

  getIdLojista() {
    return this.idLojista;
  }

  setIdLojista(idLojista) {
    this.idLojista = idLojista;
  }

  getIdVendedor() {
    return this.idVendedor;
  }

  setIdVendedor(idVendedor) {
    this.idVendedor = idVendedor;
  }

  getAvalista(): boolean {
    return this.isAvalista;
  }

  setAvalista(avalista: boolean) {
    this.isAvalista = avalista;
  }

  setPayload(key: string, value: any) {
    this.payload[key] = value;
  }

  getPayload(): any {
    return this.payload;
  }

  replacePayload(payload: any) {
    this.payload = payload;
  }

  clearPayLoad() {
    this.payload = {};
  }

  onClickPrevious() {
    const passo = this.getPasso();
    this.setPasso(
      passo -
      (!this.isAvalista &&
        (passo === this.PASSO_ENDERECO_CLIENTE ||
          passo === this.PASSO_OBSERVACAO)
        ? 2
        : 1),
    );
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  onClickNext() {
    const passo = this.getPasso();
    this.setPasso(
      passo +
      (!this.isAvalista &&
        (passo === this.PASSO_DETALHE_CLIENTE ||
          passo === this.PASSO_ENDERECO_CLIENTE)
        ? 2
        : 1),
    );
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  isSelected(passo): boolean {
    return this.passo === passo;
  }

  selectPasso(passo) {
    this.passo = passo;
  }

  initializeProposta(proposta: Proposta) {
    this.proposta = proposta;
  }

  getProposta(): Proposta {
    return this.proposta;
  }

  setToSave() {
    this.isReadyToSave = true;
  }

  unsetToSave() {
    this.isReadyToSave = false;
  }

  getIsReadyToSave(): boolean {
    return this.isReadyToSave;
  }

  setCliente(obj: any) {
    this._cliente = new RecuperaPessoa(obj);
    this.isClienteAproveitado = false;
  }

  getCliente(): RecuperaPessoa {
    return this._cliente;
  }

  setGarantia(obj: any) {
    this.garantia = new RecuperaGarantia(obj);
    this.isVeiculoAproveitado = false;
  }

  getGarantia(): RecuperaGarantia {
    return this.garantia;
  }

  setIdSimulacao(idSimulacao: number) {
    this.idSimulacao = idSimulacao;
  }

  getIdSimulacao(): number {
    return this.idSimulacao;
  }

  get fichaAntiga() {
    return this.isFichaAntiga;
  }

  set fichaAntiga(data) {
    this.isFichaAntiga = data;
  }

  set classeProfissionalCliente(classeProfissional: any) {
    this._classeProfissionalCliente = classeProfissional;
  }

  get classeProfissionalCliente(): any {
    return this._classeProfissionalCliente;
  }

  set classeProfissionalAvalista(classeProfissional: any) {
    this._classeProfissionalAvalista = classeProfissional;
  }

  get classeProfissionalAvalista(): any {
    return this._classeProfissionalAvalista;
  }
}
