import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

import { CdcLojaService } from '../../../../services/cdc-loja.service';

@Component({
  selector: 'app-modal-validando',
  templateUrl: './modal-validando.component.html',
  styleUrls: ['./modal-validando.component.scss']
})
export class ModalValidandoComponent implements OnInit, OnDestroy {

  @Input() foto: any;
  @Input() proposta: number;

  @Output() confirmValue = new EventEmitter<boolean>();
  @Output() onValidar = new EventEmitter<any>();

  titulo: string = '';
  mensagem: string = 'Mensagem';
  labelFechar: string = 'Ok, entendi';
  showFecharBtn: boolean = true;
  showOkBtn: boolean = true;
  isTituloVermelho: boolean = false;

  alive: boolean = true;
  assessment: any;

  constructor(
    private bsModalRef: BsModalRef,
    private service: CdcLojaService) { }

  ngOnInit() {
    this.cadastrarBiometriaUnico();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  showModal(
    options?: {
      titulo?: string,
      mensagem?: string,
      labelFechar?: string,
      labelOk?: string,
      showFecharBtn?: boolean,
      showOkBtn?: boolean,
      isTituloVermelho?: boolean
    }): void {

    if (options) {
      this.titulo = options.titulo || this.titulo;
      this.mensagem = options.mensagem || this.mensagem;
      this.labelFechar = options.labelFechar || this.labelFechar;
      this.showFecharBtn = options.showFecharBtn || this.showFecharBtn;
      this.showOkBtn = options.showOkBtn || this.showOkBtn;
      this.isTituloVermelho = options.isTituloVermelho || this.isTituloVermelho;
    }
  }

  onClickClose() {
    this.confirmValue.emit(false);
    this.bsModalRef.hide();
  }

  async cadastrarBiometriaUnico() {
    const request = {
      imgBase64: this.foto
    };
    const propostaId = this.proposta;

    try {
      const assessment = await this.service
        .enviarBiometriaUnico(propostaId, request)
        .toPromise();

      this.onValidar.emit(assessment);
    } catch (error) {
      console.error(error);
      this.onValidar.emit(null);
    }

    this.bsModalRef.hide();
  }

}
