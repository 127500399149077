import { BsModalRef } from 'ngx-bootstrap';
import { Component, Input } from '@angular/core';
import { FaefResultado } from '../../model/faef';

@Component({
  selector: 'app-modal-demonstrativo-resultado',
  templateUrl: './modal-demonstrativo-resultado.component.html',
  styleUrls: ['./modal-demonstrativo-resultado.component.scss']
})
export class ModalDemonstrativoResultadoComponent {

  @Input() model: FaefResultado;

  constructor(public modal: BsModalRef) { }

}
