export class Optional {

  private value: any;

  public static of(value): Optional {
    const instance = new Optional();
    instance.value = value;
    return instance;
  }

  public ifPresentOrElse(consumer: (value) => void, emptyAction: () => void): void {
    if (this.value) {
      consumer(this.value);
    } else {
      emptyAction();
    }
  }

  public orElse(other) {
    return this.value ? this.value : other;
  }
}
