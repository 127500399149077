import { BsModalRef } from 'ngx-bootstrap';
import { EnderecoTipo } from './model/endereco-tipo';
import { Endereco } from './model/endereco';
import { Proposta } from './model/proposta';
import { Avalista } from './model/avalista';

import { Subject } from 'rxjs';
import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-avalista',
  templateUrl: './ficha-modal-avalista.component.html',
  styleUrls: ['./ficha-modal-avalista.component.css']
})
export class FichaModalAvalistaComponent {

  /*   Refatoração começa aqui */
  avalista: Avalista = new Avalista();

  dataNascimentoValida;
  semRegistroPai = false;

  enderecoCorrespondencia: Endereco = new Endereco(EnderecoTipo.OUTRO);
  enderecoComercial: Endereco = new Endereco(EnderecoTipo.COMERCIAL);

  dataRange = {
    min: 18,
    max: 85,
    message: 'A idade deve ser entre 18 e 85 anos'
  };

  public onCloseModal: Subject<any> = new Subject();
  bsModalRef: BsModalRef;

  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  onSubmit() {
    if (this.avalista.tipoEnderecoCorresp === '7') { this.avalista.addEndereco(this.enderecoCorrespondencia); }
    if (this.avalista.isEmpresa) { this.avalista.$enderecoComercial = this.enderecoComercial; }
    this.avalista.removeFieldsNull();

    const proposta: Proposta = new Proposta();
    proposta.avalistas[0] = this.avalista;
    this.onCloseModal.next(proposta);
    this.bsModalRef.hide();
  }

  onClickSemRegistroPai() {
    this.semRegistroPai = !this.semRegistroPai;

    if (!this.semRegistroPai) {
      this.avalista.nomePai = 'NAO DECLARADO';
    } else {
      this.avalista.nomePai = '';
    }

    const el = document.getElementById('nome-pai-error-message');
    if (el) { el.remove(); }
  }
}
