import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { finalize, takeWhile } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { VerificacaoSegurancaService } from '../verificacao-seguranca.service';
import { ModalAvisoIosComponent } from './bem-vindo/modal-aviso-ios/modal-aviso-ios.component';
import { ModalAvisoCpfInvalidoComponent } from './verificacao-seguranca-identificacao/modal-aviso-cpf-invalido/modal-aviso-cpf-invalido.component';

enum StatusVerificacao {
  BEMVINDO = 1,
  IDENTIFICACAO = 2,
  VERIFICAR = 3,
  BEFORE_SELFIE = 4,
  DONE = 5,
  EXPIRED = 6,
}

const MENSAGEM_CPF_INVALIDO = 'Oops, parece que os números digitados não correspondem com o seu CPF.';

@Component({
  selector: 'app-verificacao-seguranca-unico',
  templateUrl: './verificacao-seguranca-unico.component.html',
  styleUrls: ['./verificacao-seguranca-unico.component.scss'],
})
export class VerificacaoSegurancaUnicoComponent implements OnInit, OnDestroy {
  readonly statusVerificacao = StatusVerificacao;
  statusVerificacaoAtual = StatusVerificacao.BEMVINDO;
  isLoading = false;
  isAlive = true;

  loadingGetToken = false;
  cpf: string;
  decodedCpf: string;
  urlCapturaBiometriaUnico: string;

  autorizarConsultaSCR: boolean;
  termosDeUso: boolean;
  private propostaId: number;

  constructor(
    private modal: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private verificacaoSegurancaService: VerificacaoSegurancaService
  ) { }

  ngOnInit() {
    this.initObservableParams();

    if (this.isIos() && !this.isSafari()) {
      this.modal.show(ModalAvisoIosComponent, {
        class: 'modal-sm',
        keyboard: false,
        ignoreBackdropClick: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private initObservableParams(): void {
    this.route.queryParams
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((params: any) => {
        if (!params.token) {
          this.router.navigate(['/login']);
          return;
        }

        const decripted = this.verificacaoSegurancaService.decrypt(
          params.token.replace(/ /g, '+'),
          environment.ECOMMERCE_ENCRYPT_KEY
        );
        const { cpf, proposta } = this.verificacaoSegurancaService.decodePayloadJWT(decripted);
        this.decodedCpf = cpf;
        this.propostaId = proposta;
      });
  }

  onClickNext(): void {
    if (this.isDisabledButtonClick) {
      return;
    }

    switch (this.status) {
      case StatusVerificacao.BEMVINDO:
        this.statusVerificacaoAtual = StatusVerificacao.IDENTIFICACAO;
        break;
      case StatusVerificacao.IDENTIFICACAO:
        this.onContinuarVerificaCpf();
        break;
      case StatusVerificacao.VERIFICAR:
        this.criarUrlCapturaBiometria();
        break;
      case StatusVerificacao.BEFORE_SELFIE:
        this.redirecionarCapturaUnico();
        break;
    }
  }

  get status(): number {
    return this.statusVerificacaoAtual;
  }

  private onContinuarVerificaCpf() {
    this.loadingGetToken = true;
    this.isLoading = true;

    this.verificacaoSegurancaService.verificarLinkCapturaBiometria(this.cpf).pipe(finalize(() => {
      this.loadingGetToken = false;
      this.isLoading = false;
    })).subscribe(
      ({ cancelado }) => {
        if (cancelado) {
          this.statusVerificacaoAtual = StatusVerificacao.EXPIRED;
          return;
        }
        this.statusVerificacaoAtual = StatusVerificacao.VERIFICAR;
      },
      (err) => {
        if (!err || !err.error) {
          return;
        }
        this.showModalInvalidCpf(err.error.messages[0]);
      });

  }

  private showModalInvalidCpf(message: string) {
    const modal = this.modal.show(ModalAvisoCpfInvalidoComponent, {
      class: 'modal-sm2',
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: { message },
    }).content;

    modal.closeModal.subscribe(() => {
      this.statusVerificacaoAtual = StatusVerificacao.EXPIRED;
    });
  }

  public changeCpf(cpf): void {
    if (cpf && cpf.length === 11) {
      if (this.decodedCpf !== cpf) {
        this.showModalInvalidCpf(MENSAGEM_CPF_INVALIDO);
        return;
      }
    }
    this.cpf = cpf;
  }

  private isSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  private isIos() {
    const iosVersions = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ];

    const hasIos = iosVersions.filter(
      (version) => version === navigator.platform
    );

    return (
      hasIos.length > 0 ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  public get isDisabledButtonClick(): boolean {
    return (
      (this.status === StatusVerificacao.BEMVINDO && this.isIos() && !this.isSafari()) ||
      (this.status === StatusVerificacao.IDENTIFICACAO &&
        (this.loadingGetToken || !this.cpf || this.cpf.length !== 11)) ||
      (this.status === StatusVerificacao.VERIFICAR && (!this.autorizarConsultaSCR || !this.termosDeUso))
    );
  }

  private criarUrlCapturaBiometria() {
    this.loadingGetToken = true;
    this.isLoading = true;

    this.verificacaoSegurancaService.criarUrlCapturaBiometria(this.propostaId).pipe(finalize(() => {
      this.loadingGetToken = false;
      this.isLoading = false;
    })).subscribe(
      (res) => {
        this.urlCapturaBiometriaUnico = res.url;
        this.statusVerificacaoAtual = StatusVerificacao.BEFORE_SELFIE;
      },
      (err) => {
        if (!err || !err.error) {
          return;
        }
      });
  }

  private redirecionarCapturaUnico() {
    if (this.urlCapturaBiometriaUnico) {
      window.location.href = this.urlCapturaBiometriaUnico;
    }
  }
}
