import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskModule } from 'soft-angular-mask';
import { PipesModule } from '../../../pipes/pipes.module';
import { BreadcrumbModule } from '../../../shared/breadcrumb/breadcrumb.module';
import { SharedModule } from '../../../shared/shared.module';
import { BannerTimelineComponent } from './banner-timeline/banner-timeline.component';
import { CardTimelineComponent } from './card-timeline/card-timeline.component';
import { CdcLojaTimelineComponent } from './cdc-loja-timeline.component';

import { DirectivesModule } from '../../../directives/directives.module';
import { TextDirectivesModule } from '../../../directives/texto/text.directives.module';
import { CdcLojaService } from '../services/cdc-loja.service';
import { CardAssinaturaComponent } from './card-assinatura/card-assinatura.component';
import { DadosComplementaresComponent } from './dados-complementares/dados-complementares.component';
import { ModalAlterarEmailComponent } from './modal-alterar-email/modal-alterar-email.component';
import { ModalAssinaturaComponent } from './modal-assinatura/modal-assinatura.component';
import { ModalDocusignComponent } from './modal-docusign/modal-docusign.component';
import { MotivoDesistenciaComponent } from './motivo-desistencia/motivo-desistencia.component';
import { SosInversoComponent } from './sos-inverso/sos-inverso.component';
import { DocumentosComponent } from './sos/documentos/documentos.component';
import { ObservacaoComponent } from './sos/observacao/observacao.component';
import { SosComponent } from './sos/sos.component';

@NgModule({
  declarations: [
    CdcLojaTimelineComponent,
    CardTimelineComponent,
    BannerTimelineComponent,
    MotivoDesistenciaComponent,
    SosInversoComponent,
    ObservacaoComponent,
    DocumentosComponent,
    SosComponent,
    CardAssinaturaComponent,
    ModalAssinaturaComponent,
    ModalAlterarEmailComponent,
    ModalDocusignComponent,
    DadosComplementaresComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbModule,
    SharedModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    MaskModule,
    TextDirectivesModule,
  ],
  entryComponents: [
    DadosComplementaresComponent,
    MotivoDesistenciaComponent,
    ModalAssinaturaComponent,
    ModalAlterarEmailComponent,
    ModalDocusignComponent,
  ],
  providers: [
    CdcLojaService
  ]
})
export class CdcLojaTimelineModule { }
