import { LoginStorage } from './../../../login/login.storage';
import { Component, Input, OnInit } from '@angular/core';

import { FichaCadastro } from './../ficha-cadastro';

@Component({
  selector: 'app-ficha-cadastro-lateral',
  templateUrl: './ficha-cadastro-lateral.component.html',
  styleUrls: ['./ficha-cadastro-lateral.component.css']
})
export class FichaCadastroLateralComponent implements OnInit {

  @Input() fichaCadastro: FichaCadastro;

  labelPasso1: string;

  constructor(private loginStorage: LoginStorage) { }

  ngOnInit() {
    this.definirLabels();
  }

  private definirLabels() {
    this.labelPasso1 = this.loginStorage.usuario.isLojista ? 'Produto' : 'Solicitante e Produto';
  }

}
