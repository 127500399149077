import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-link-not-sent-modal',
  templateUrl: './link-not-sent-modal.component.html',
  styleUrls: ['./link-not-sent-modal.component.scss']
})
export class LinkNotSentModalComponent implements OnDestroy {
  @Output() confirmValue = new EventEmitter<void>();

  alive: boolean = true;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnDestroy() {
    this.alive = false;
  }

  onClickOk() {
    this.confirmValue.emit();
    this.bsModalRef.hide();
  }
}
