import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OmniGeralRestService } from './../../omni-rest/omni-geral/omni-geral-rest.service';

@Injectable()
export class SelectProdutoService {
  constructor(private restService: OmniGeralRestService) {}

  getProdutos(): Observable<any> {
    return this.restService.get('operacoes/tipos-produto');
  }
}
