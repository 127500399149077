import { CdcLojaService } from './../../../../services/cdc-loja.service';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';

import { Proposta } from '../../../../../shared/models/proposta';

@Component({
  selector: 'app-altera-tabela',
  templateUrl: './altera-tabela.component.html',
  styleUrls: ['./altera-tabela.component.scss']
})
export class AlteraTabelaComponent implements OnInit {
  @Input() proposta: Proposta;

  formulario: FormGroup;
  tabelas: any[] = [];
  onSave: EventEmitter<any> = new EventEmitter();
  alive = true;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private cdcLojaService: CdcLojaService
  ) {}

  ngOnInit() {
    this.formulario = this.fb.group({
      tabela: ['', Validators.required]
    });

    this.fillTabelas();
  }

  get tabelaId() {
    return Number(this.formulario.get('tabela').value);
  }

  save() {
    this.onSave.emit(this.tabelas.filter(t => t.id === this.tabelaId)[0]);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }

  private fillTabelas() {
    this.cdcLojaService
      .tabelas(this.proposta.operacao.id)
      .pipe(take(1))
      .subscribe(
        res => this.tabelas = res,
        error => console.error(error)
      );
  }
}
