import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-repique-listagem',
  templateUrl: './repique-listagem.component.html',
  styleUrls: ['./repique-listagem.component.css']
})
export class RepiqueListagemComponent {

  alive: boolean = true;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() baseContratos: any[];

  constructor(public bsModalRef: BsModalRef) { }

  select(baseRepique) {
    this.onSelect.emit(baseRepique);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }
}
