import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css'],
})
export class ModalMessageComponent {
  @ViewChild('modalMessage', { static: true })
  public modalMessage: ModalDirective;
  @Input() size = 'md';
  @Input() title: string;
  @Input() message: string;
  @Input() hideClose: boolean;
  @Input() hideOk: boolean;
  @Input() labelOk = 'Ok';
  @Input() labelClose = 'Fechar';

  @Output() ok: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  private hidenSubscription: Subscription;

  show(
    message?: string,
    labelOk?: string,
    labelClose?: string
  ): ModalMessageComponent {
    this.message = this.returnMessage(message);
    this.labelOk = labelOk || this.labelOk;
    this.labelClose = labelClose || this.labelClose;

    this.initObservable();
    this.modalMessage.show();
    return this;
  }

  private returnMessage(message: string): string {
    let ret: string = '';
    if (message) {
      ret = message;
    } else if (this.message) {
      ret = this.message;
    } else {
      ret = 'Suas alterações serão descartadas. Deseja continuar?';
    }
    return ret;
  }

  private initObservable() {
    this.hidenSubscription = this.modalMessage.onHidden.subscribe(
      (reason: string) => {
        this.close.next();
        this.modalClose.next();
        this.hidenSubscription.unsubscribe();
      }
    );
  }

  hide() {
    this.modalMessage.hide();
  }

  onClickOk() {
    this.hidenSubscription.unsubscribe();
    this.hide();
    this.ok.next();
    this.modalClose.next();
  }

  onClickClose() {
    this.hidenSubscription.unsubscribe();
    this.hide();
    this.close.next();
    this.modalClose.next();
  }
}
