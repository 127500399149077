import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContratoCessao, OpcoesPagamento } from '../contrato-cessao';

@Component({
  selector: 'app-multi-approval',
  templateUrl: './multi-approval.component.html',
  styleUrls: ['./multi-approval.component.scss']
})
export class MultiApprovalComponent {
  @Input() contratos: ContratoCessao[] = [];
  @Input() optionIndexes: number[] = this.contratos.map(() => 0);
  @Output() confirmValue = new EventEmitter<boolean>();

  alive = true;

  changeD(contratoIndex: number, optionIndex: string) {
    this.optionIndexes[contratoIndex] = Number(optionIndex);
  }

  buttonToFocus(index: number): boolean {
    return this.optionIndexes.every((opt) => opt === index);
  }

  selectAll(index: number) {
    this.optionIndexes.fill(index);
  }

  get options(): OpcoesPagamento[] {
    return this.contratos[0].opcoesPagamento;
  }

}
