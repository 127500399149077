import { BsModalRef } from 'ngx-bootstrap';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-docusign',
  templateUrl: './modal-docusign.component.html',
  styleUrls: ['./modal-docusign.component.css']
})
export class ModalDocusignComponent{
  @Input() urlContrato: string;

  constructor(private modal: BsModalRef) {}

  close() {
    this.modal.hide();
  }
}
