import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanDeactivateGuard } from '../../services/can-deactivate/can-deactivate-guard.service';
import { CessaoGuard } from '../../services/guards/cessao.guard';
import { PromotorGuard } from '../../services/guards/promotor.guard';
import { CdcLojaNovaFichaComponent } from './cadastro/cadastro.component';
import { FichaExcluidaComponent } from './cadastro/etapas/ficha-excluida/ficha-excluida.component';
import { CessaoComponent } from './cessao/cessao.component';
import { EcommerceComponent } from './ecommerce/ecommerce.component';
import { ReportsComponent } from './reports/reports.component';
import { CdcLojaTimelineComponent } from './timeline/cdc-loja-timeline.component';

const
  routes: Routes = [
    {
      path: 'fichas',
      component: CdcLojaNovaFichaComponent,
      canActivate: [PromotorGuard]
    },
    {
      path: 'fichas/:id',
      component: CdcLojaNovaFichaComponent,
      canActivate: [PromotorGuard]

    },
    {
      path: 'timelines',
      component: CdcLojaTimelineComponent,
      canDeactivate: [CanDeactivateGuard],
      canActivate: [PromotorGuard]
    },
    {
      path: 'relatorios',
      component: ReportsComponent
    },
    {
      path: 'cessao',
      component: CessaoComponent,
      canActivate: [CessaoGuard]
    },
    {
      path: 'ecommerce/:etapa/:proposta',
      component: EcommerceComponent,
      canActivate: [PromotorGuard]
    },
    {
      path: 'ecommerce',
      component: EcommerceComponent,
      canActivate: [PromotorGuard]
    },
    {
      path: 'ficha-excluida',
      component: FichaExcluidaComponent,
      canActivate: [PromotorGuard]
    },
  ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CdcLojaRoutingModule { }
