export class AssistenciaSimulacao {
  public id: number;
  public descricao: string;
  public valor: number;
  public saibaMais: string;
  public selected: boolean;

  constructor(obj) {
    this.id = obj.id || 0;
    this.descricao = obj.descricao;
    this.valor = obj.valor;
    this.saibaMais = obj.saibaMais;
    this.selected = obj.selected;
  }

  getId(): number {
    return this.id;
  }

  getValor(): number {
    return this.valor;
  }
}
