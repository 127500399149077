import { Component, Input, ViewChild } from '@angular/core';

import { Proposta } from '../../../../shared/models/proposta';
import { TimelineSosVO } from '../../../../shared/models/timelineVO';
import { appSettings } from './../../../../../../environments/app.setings';
import { UploadArquivosComponent } from './../../../../../shared/upload-arquivos/upload-arquivos.component';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html'
})
export class DocumentosComponent {

  @Input() sos: TimelineSosVO;
  @Input() proposta: Proposta;

  @ViewChild(UploadArquivosComponent, { static: false })
  private uploadArquivosComponent: UploadArquivosComponent;

  acceptFiles = appSettings.ACCEPT_IMAGES_AND_PDF;

  fileUpload() {
    return this.uploadArquivosComponent.sendDocuments();
  }

  public validaEnvio(): boolean {
    return this.uploadArquivosComponent.validaEnvio();
  }

  get documentos() {
    return this.sos.motivos.filter(acao => acao.idDocto)
      .map(doc => ({ ...doc, descDocto: doc.descricao }));
  }
}
