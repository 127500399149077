import { Component, Input, Output, EventEmitter } from '@angular/core';

import { HeaderSort } from './header-sort';

@Component({
  selector: 'app-header-sort',
  templateUrl: './header-sort.component.html',
  styleUrls: ['./header-sort.component.css'],
})
export class HeaderSortComponent {

  @Input() description: string;
  @Input() column: string;
  @Input() current: HeaderSort;

  @Output() sort = new EventEmitter<HeaderSort>();

  direction = 'desc';

  onClickDirection() {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sort.emit(new HeaderSort(this.column, this.direction));
  }

}
