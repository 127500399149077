import { take } from 'rxjs/operators';
import { LoginService } from './../login/login.service';
import { LoginStorage } from './../login/login.storage';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ViabilidadeGuard implements CanActivateChild {
  constructor(
    private loginStorage: LoginStorage,
    private storageService: StorageService,
    private router: Router,
    private loginService: LoginService
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.loginStorage.isContainsToken()) {
    return true;
    }

    if (next.queryParams.login && next.queryParams.token) {
      const { login, token } = next.queryParams;
      const subject = new Subject<boolean>();
      this.storageService.renewUser(JSON.stringify({ login, token }))
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.loginStorage.register(result.usuario);
            this.loginStorage.registerDataExpiracao(result.dataExpiracao);
            if (result.usuario.hasOwnProperty('lojaTO')) {
              this.loginStorage.registerAgente(
                result.usuario.lojaTO.agenteTO
              );
            }
            subject.next(true);
            subject.complete();
          },
          (err) => {
            subject.next(false);
            subject.complete();
            this.goToLogin();
          }
        );
      return subject;
    }
  }

  private goToLogin() {
    this.router.navigate(['/login']);
  }
}
