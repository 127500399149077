import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { PanelModule } from './../../dashboard/panel/panel.module';
import { PipesModule } from './../../pipes/pipes.module';
import { ProdutoStorage } from './../../shared/select-produto/produto.storage';
import { SelectProdutoComponent } from './select-produto.component';
import { SelectProdutoService } from './select-produto.service';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    SharedModule,
    FormsModule,
    PanelModule,
    PipesModule
  ],
  declarations: [SelectProdutoComponent],
  exports: [SelectProdutoComponent],
  providers: [SelectProdutoService, ProdutoStorage]
})
export class SelectProdutoModule { }
