import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from '../../../../../shared/models/cliente';

@Component({
  selector: 'app-analise-avalista',
  templateUrl: './analise-avalista.component.html',
  styleUrls: ['./analise-avalista.component.scss']
})
export class AnaliseAvalistaComponent {

  @Input() avalista: Cliente;
  @Input() numero: number;
  @Input() classesProfissionais = [];
  @Output() remove = new EventEmitter<Cliente>();
  @Output() edit = new EventEmitter<Cliente>();

  grausParentescos = {
    0: 'Outros',
    1: 'Cônjuge',
    2: 'Pai / Mãe',
    3: 'Irmã(o)',
    4: 'Filho / Filha'
  };

  getClasseProfissional(classe) {
    return this.classesProfissionais.filter(e => e.id === Number(classe))[0];
  }
}
