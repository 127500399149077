import { LoginStorage } from './../../login/login.storage';
import { DocumentoVO, DocumentoEnviadoVO } from './documentoVO';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { appSettings as globals } from '../../../environments/app.setings';
import { BsModalService } from 'ngx-bootstrap';
import { appSettings } from './../../../environments/app.setings';

import { DialogService } from '../../services/dialog/dialog.service';
import { normalizeList } from '../../shared/helper/helper';
import { Documentation } from '../../shared/enums/documentation.enum';
import { Report } from '../../shared/models/report/report.model';
import { ReportService } from '../../services/report/report.service';
import { OmniFacilUploadRestService } from '../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { ModalFotoDicaComponent } from '../../shared/modais/modal-foto-dica/modal-foto-dica.component';
import { UploadArquivosDropComponent } from '../upload-arquivos-drop/upload-arquivos-drop.component';
import { takeWhile } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-arquivos',
  templateUrl: './upload-arquivos.component.html',
  styleUrls: ['./upload-arquivos.component.scss'],
})
export class UploadArquivosComponent implements OnInit, OnChanges {
  @Input() documentos: DocumentoVO[] = [];
  @Input() cliente: { cpf: any };
  @Input() idFicha: string;
  @Input() isOrigemSos: boolean;
  @Input() hideInfo?: boolean = false;
  @Input() buttonLabel?: string;
  @Input() hideSendButton?: boolean;
  @Input() enableSendButton?: boolean;
  @Input() origem?: number = 1;
  @Input() acceptFiles = globals.ACCEPT_IMAGES;
  @Output() pushDocumentacao = new EventEmitter();
  @Input() pr30?: boolean = false;
  @Input() showEnviado?: boolean = false;
  @Input() showUploadedItens?: boolean = false;
  @Input() centeredSendButton?: boolean = false;
  @Input() utilizaDropComponent: boolean = false;
  @Input() timeline: any;

  report: Report;
  isSizeLimit = false;
  isDocsObrigatorio = false;
  fileList: FileList[] = [];
  normalizedFileList = [];
  fotosGarantia: File[] = [];
  mensagem: string;
  isEnviandoDocumento = false;
  documentosAnexar: DocumentoVO[] = [];
  Documentation = Documentation;
  isShopkeeper: boolean;
  origemCdcl = false;
  hasFilesToUpload = false;

  constructor(
    private loginStorage: LoginStorage,
    private omniRestService: OmniFacilUploadRestService,
    private dialogService: DialogService,
    private reportService: ReportService,
    private modal: BsModalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loadDocumentosAnexar();
    this.loadAcceptFiles();
  }

  ngOnChanges(changes) {
    if (changes.documentos &&
      JSON.stringify(changes.documentos.previousValue) !== JSON.stringify(changes.documentos.currentValue)) {
      this.loadDocumentosAnexar();
    }

    this.hasFilesToUpload = this.getDocumentsFileLists().some(doc => doc.length > 0);
  }

  private loadDocumentosAnexar() {
    this.isShopkeeper = this.loginStorage.usuario.isLojista;

    this.documentosAnexar = (this.documentos || []).filter(documento => {
      if (this.isShopkeeper) {
        return (
          documento.idDocto !== Documentation.Warranty &&
          documento.idPendencia !== Documentation.Report
        );
      }

      return documento.idDocto !== Documentation.Warranty;
    });
  }

  hasWarrantyPicturesDocument(): boolean {
    return this.documentos.some(document => document.idDocto === Documentation.Warranty);
  }

  hasReportDocument(): boolean {
    return this.documentosAnexar.some(document => document.idPendencia === Documentation.Report);
  }

  async sendDocuments() {
    if (!this.isEnviandoDocumento && this.validaEnvio()) {
      try {
        this.isEnviandoDocumento = true;

        let totalDocumentsToSend = this.getDocumentsFileLists().length;
        let totalSentDocuments = 0;

        if (this.hasWarrantyPicturesDocument()) totalDocumentsToSend++;
        if (this.hasReportDocument()) totalDocumentsToSend++;

        this.showMessageEnviandoFile(totalDocumentsToSend, totalSentDocuments);

        for (let index = 0; index < this.normalizedFileList.length; index++) {
          if (Array.isArray(this.normalizedFileList[index])) {
            if (totalSentDocuments < totalDocumentsToSend) this.showMessageEnviandoFile(totalDocumentsToSend, ++totalSentDocuments);

            for (let indexFile = 0; indexFile < this.normalizedFileList[index].length; indexFile = indexFile + 1) {
              const file = this.normalizedFileList[index][indexFile];
              const idDocto = this.documentosAnexar[index].idDocto;
              const documento = this.documentosAnexar[index];

              await this.postOmniDoc(file, idDocto);
              await this.pushArquivosUpados(documento, file);
            }
          }
        }

        for (const value of this.fotosGarantia) {
          if (totalSentDocuments < totalDocumentsToSend) this.showMessageEnviandoFile(totalDocumentsToSend, ++totalSentDocuments);

          const file = value;

          await this.postOmniDoc(file, Documentation.Warranty);
        }

        if (this.report) {
          if (totalSentDocuments < totalDocumentsToSend) this.showMessageEnviandoFile(totalDocumentsToSend, ++totalSentDocuments);

          await this.reportService
            .createReport({
              ...this.report,
              proposalId: this.idFicha
            })
            .toPromise();
        }

        if (!this.isOrigemSos) {
          this.pushDocumentacaoEnviada();
        }

        this.limpaListFile();
        this.enviado();
        if (this.showUploadedItens) {
          this.mensagem = '';
        }
      } finally {
        this.isEnviandoDocumento = false;
      }
    }
  }

  private async readFileAsDataURL(file) {
    const result_base64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });

    return result_base64 as string;
  }

  private showMessageEnviandoFile(total: number, enviado: number) {
    this.mensagem = `Enviando ${enviado} de ${total}...`;
  }

  handleChangeReport(report: Report) {
    this.report = report;
  }

  handleFileChange(event: any, index: number) {
    this.isDocsObrigatorio = false;
    this.isSizeLimit = false;

    const files = event.target.files;
    const target = event.target;

    if (files && files[0]) {
      this.fileList[index] = files;

      const extensoesAceitas = this.acceptFiles.split(',');
      const extensaoInvalida = (filename: string) => extensoesAceitas.filter(ext => filename.toLowerCase().endsWith(ext)).length === 0;

      if (normalizeList(files).some((file: File) => extensaoInvalida(file.name))) {
        const extensoes = extensoesAceitas.map(ext => `"${ext}"`).join(', ');
        const message = `Um ou mais dos arquivos selecionados, não está em um formato aceito. Selecione arquivos com as extensões: ${extensoes}.`;
        console.log(message);
        return this.dialogService.confirm({ body: message, callbackSuccess: () => target.click() });
      }

      if ((this.normalizedFileList[index] && (this.normalizedFileList[index].length + files.length) > 10) || files.length > 10) {
        target.value = null;

        return this.dialogService.confirm({ body: 'O máximo são 10 imagens por documento.', callbackSuccess: () => target.click() });
      }

      if (normalizeList(files).some((file: File) => (file.size / 1048576) > 10)) {
        target.value = null;

        return this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionadas tem tamanho superior à 10mb.', callbackSuccess: () => target.click() });
      }

      if (
        this.normalizedFileList[index] &&
        normalizeList(files).some((file: File) => normalizeList(this.normalizedFileList[index]).some(selectedFile => {
          return (
            file.name === selectedFile.name &&
            file.size === selectedFile.size &&
            file.type === selectedFile.type
          );
        }))
      ) {
        target.value = null;

        return this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionada(s) já foi adicionada.', callbackSuccess: () => target.click() });
      }

      if (this.normalizedFileList[index] && this.normalizedFileList[index].length) {
        Array.prototype.push.apply(this.normalizedFileList[index], normalizeList(files));
      } else {
        this.normalizedFileList[index] = normalizeList(files);
      }

      target.value = null;
    }
    this.ngOnChanges({});
  }

  fileGarantiaChange(file, index) {
    this.isDocsObrigatorio = false;
    this.isSizeLimit = false;
    this.fotosGarantia[index] = file;
  }

  removeFile(list: any[], fileIndex: number) {
    list.splice(fileIndex, 1);
    this.ngOnChanges({});
  }

  removeAllFiles(filesDocuments: any, documentIndex: number) {
    filesDocuments[documentIndex] = [];
    this.ngOnChanges({});
  }

  getDocumentsFileLists() {
    return this.normalizedFileList.filter((list, index) => Object.keys(this.normalizedFileList).find(key => key === String(index)));
  }

  validaEnvio(): boolean {
    return this.enableSendButton ||
      !!(
        (this.documentosAnexar.filter(e => !e.somenteDownload).length - (this.hasReportDocument() ? 1 : 0)) === this.getDocumentsFileLists().filter(e => e.length > 0).length &&
        (!this.hasWarrantyPicturesDocument() || this.fotosGarantia.length === 3) &&
        (!this.hasReportDocument() || this.report)
      );
  }

  postOmniDoc(file: File, idDocto: number) {
    const proposta = { id: Number(this.idFicha), cliente: this.cliente, origem: this.origem };
    return this.omniRestService
      .post(file, proposta, idDocto)
      .toPromise();
  }

  enviado() {
    if (this.showEnviado) {
      this.mensagem = 'Enviado';
    } else {
      this.mensagem = null;
    }
  }

  limpaListFile() {
    this.fileList = [];
    this.normalizedFileList = [];
    this.ngOnChanges({});
  }

  pushDocumentacaoEnviada() {
    const body = { id: this.idFicha };
    this.pushDocumentacao.next(body);
  }

  openModalFotoDica() {
    this.modal.show(ModalFotoDicaComponent, { ...appSettings.MODAL_PARAMS });
  }

  loadAcceptFiles() {
    if (this.origem === 6) {
      this.acceptFiles = appSettings.ACCEPT_IMAGES_AND_PDF;
    }
  }

  openModalSelecionarTodosDocumentos() {
    const initialState = {
      tiposDocumentos: this.documentosAnexar,
      cliente: this.cliente,
      proposta: this.idFicha,
      origem: this.origem,
      acceptFiles: this.acceptFiles
    };
    const modal = this.modal.show(UploadArquivosDropComponent, { ...appSettings.MODAL_PARAMS, initialState });
    const uploaded = <UploadArquivosDropComponent>modal.content;

    uploaded.onUploaded
      .pipe(
        takeWhile(() => uploaded.alive)
      )
      .subscribe(arquivosUpados => {
        if (arquivosUpados) {
          this.documentosAnexar.forEach(documento => {
            const documentosEnviados: DocumentoEnviadoVO[] = arquivosUpados.filter(arquivoUpado => Number(arquivoUpado.tipo) === documento.idDocto)
              .map(arquivoUpado => ({
                name: arquivoUpado.file.file.name,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(arquivoUpado.file.url),
                idDocto: arquivoUpado.tipo
              }));

            if (documentosEnviados.length) {
              documento.documentosEnviado = [...(documento.documentosEnviado || []), ...documentosEnviados];
            }
          });
        }
      });
  }

  async pushArquivosUpados(documento: DocumentoVO, file: File) {
    if (this.showUploadedItens) {
      documento.documentosEnviado = documento.documentosEnviado || [];
      const base64Content = await this.readFileAsDataURL(file);
      documento.documentosEnviado.push({
        idDocto: documento.idDocto,
        name: file.name,
        url: base64Content,
        thumbUrl: '' // ainda não é utilizado thumbs, quando for necessário, reduzir a imagem e adicionar o base64 aqui.
      });
    }
  }
}
