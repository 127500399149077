
import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { ModalMessageComponent } from './../modal-message/modal-message.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {

  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @Input() size = 'md';
  @Input() title: string;
  @Input() hideClose: boolean;
  @Input() hideOk: boolean;
  @Input() disabledOk: boolean;
  @Input() hideButtons: boolean;
  @Input() labelOk = 'Ok';
  @Input() labelClose = 'Fechar';
  @Input() ignoreBackdropClick = false;
  @Input() keyboard = true;
  @Input() message: string;

  @Output() ok: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  private modalOptions: ModalOptions = new ModalOptions();
  private hidenSubscription: Subscription;

  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;
  private confirmationClose: boolean;

  show(confirmationClose?: boolean): ModalComponent {
    this.initObservable();
    this.confirmationClose = confirmationClose;
    this.modalOptions.ignoreBackdropClick = this.ignoreBackdropClick;
    this.modalOptions.keyboard = this.keyboard;
    this.modal.config = this.modalOptions;
    this.modal.show();
    return this;
  }

  private initObservable() {
    this.hidenSubscription = this.modal.onHidden
      .subscribe((reason: string) => {
        this.close.next();
        this.modalClose.next();
        this.hidenSubscription.unsubscribe();
      });
  }

  hide() {
    this.modal.hide();
  }

  onClickOk() {
    this.hidenSubscription.unsubscribe();
    this.hide();
    this.ok.next();
    this.modalClose.next();
  }

  onClickClose() {
    if (this.confirmationClose) {
      this.modalMessage.show();
      this.modalMessage.ok.pipe(take(1)).subscribe(() => {
        this.confirmationClose = false;
        this.onClickClose();
      });
      return;
    }

    this.hidenSubscription.unsubscribe();
    this.hide();
    this.close.next();
    this.modalClose.next();
  }
}
