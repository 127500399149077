import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { finalize, take, takeWhile, timeout } from 'rxjs/operators';

import { LoginStorage } from '../../../login/login.storage';
import { Lojista } from './models/lojista';
import { RelatorioRequest, StatusPagamento } from './models/relatorio-request';
import { ReportsService } from './reports.service';
import { TipoRelatorio } from './models/tipo-relatorio';
import { StorageService } from '../../../services/storage/storage.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  isAlive = true;
  relatorios: TipoRelatorio[] = [];
  page: any;
  relatorioForm: FormGroup;
  dadosRelatorio: any[];
  totais: any;

  showLoader: boolean = false;
  lojistas: Lojista[];
  clicouGerar = false;

  numeroPagina = 0;
  tamanhoPagina = 30;

  isFabrica = true;
  isParceriaDell = false;
  relatorioRequest: RelatorioRequest;
  statusPagamento = StatusPagamento;

  constructor(
    private fb: FormBuilder,
    private reportRestService: ReportsService,
    private loginStorage: LoginStorage,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    this.isParceriaDell = this.verificarParceriaDoUsuario('DELL');
    this.verificarTipoLojista();
    this.initForms();
    this.initObservers();
    this.onChangeRelatorio();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  initForms(): void {
    this.relatorioForm = this.fb.group({
      idRelatorio: ['', Validators.required],
      dataInicial: ['', Validators.required],
      dataFinal: ['', Validators.required],
      lojista: ['', Validators.required],
      statusPagamento: [''],
    });

    if (this.isParceriaDell) {
      this.relatorioForm.get('lojista').setValidators(null);
    }
  }

  initObservers(): void {
    this.reportRestService
      .buscarRelatorios().pipe(
        take(1))
      .subscribe(({ relatorios }) => (this.relatorios = this.sort(relatorios, 'nome')));

    this.reportRestService
      .buscarRelatoriosLojas().pipe(
        take(1))
      .subscribe(({ lojistas }) => {
        lojistas.forEach((lojista) => {
          if (!lojista.nomeFantasia) {
            lojista.nomeFantasia = lojista.razaoSocial;
          }
        });
        this.lojistas = this.sort(lojistas, 'nomeFantasia');
      });
  }

  changePage(event: { page: number; size: number; }) {
    this.onClickGerarRelatorio(event.page, event.size);
  }

  changeDetalhes(event: boolean) {
    this.clicouGerar = event;
  }

  get exibeTodasFiliais(): boolean {
    const idRelatorio = Number(this.relatorioForm.get('idRelatorio').value);
    return !!(idRelatorio === 13 || idRelatorio === 16);
  }

  onClickGerarRelatorio(page: number, size: number) {
    this.relatorioRequest = this.gerarRelatorioRequest;
    this.showLoader = true;

    if ((Number(this.relatorioRequest.relatorio.lojista) === 0) && Number(this.relatorioRequest.relatorio.idRelatorio) === 13) {
      this.getRelatorioAgrupado(this.relatorioRequest);
      return;
    }
    this.getRelatorioPaginado(page, size, this.relatorioRequest);
  }

  private getRelatorioPaginado(page: number, size: number, relatorioRequest: RelatorioRequest) {
    this.reportRestService
      .gerarRelatorio(page, size, relatorioRequest)
      .pipe(
        finalize(() => {
          this.showLoader = false;
          this.clicouGerar = false;
        }),
        timeout(50000),
        take(1)
      )
      .subscribe((data) => {
        this.page = data;
        this.page.numberOfElements = data.numberOfElements;
        this.dadosRelatorio = data.content;
      });
  }

  private getRelatorioAgrupado(relatorioRequest: RelatorioRequest) {
    this.reportRestService
      .gerarRelatorioAgrupado(relatorioRequest)
      .pipe(
        finalize(() => {
          this.showLoader = false;
          this.clicouGerar = false;
        }),
        timeout(50000),
        take(1)
      )
      .subscribe((data) => {
        this.dadosRelatorio = data.agrupado;
        this.totais = data.totais;
      });
  }

  get gerarRelatorioRequest(): RelatorioRequest {
    const filtro = this.relatorioForm.value;
    const dataInicial = moment(filtro.dataInicial, this.emissaoFormato).unix() * 1000;
    const dataFinal = moment(filtro.dataFinal, this.emissaoFormato).unix() * 1000;

    if (this.isParceriaDell) {
      return {
        relatorio: {
          dataFinal,
          dataInicial,
          lojista: this.getLojaParceira(),
          idRelatorio: Number(filtro.idRelatorio),
          statusPagamento: filtro.statusPagamento,
          parceiro: {
            ...this.getParceriaDoUsuario('DELL')
          }
        }
      };
    }

    return {
      relatorio: {
        dataFinal,
        dataInicial,
        lojista: filtro.lojista,
        idRelatorio: Number(filtro.idRelatorio),
        pagamentoRealizado: this.pagamentoRealizado(filtro.statusPagamento),
      }
    };
  }

  onChangeRelatorio() {
    this.relatorioForm
      .valueChanges
      .pipe(
        takeWhile(() => this.isAlive)
      )
      .subscribe(_ => {
        this.dadosRelatorio = null;
      });
  }

  get gerouRelatorio() {
    return this.clicouGerar;
  }

  get emissaoFormato() {
    return 'DDMMYYYY';
  }

  private verificarTipoLojista() {
    const { lojistaTipoFabrica } = this.storageService.getSession() ;
    this.isFabrica = lojistaTipoFabrica || false;
  }

  private verificarParceriaDoUsuario(chaveParceria: string): boolean {
    const { parcerias } = this.loginStorage.usuario;
    return parcerias.some(({ chave }) => chave === chaveParceria);
  }

  private getParceriaDoUsuario(chaveParceria: string): {
    id: number;
    chave: string;
    descricao: string;
  } {
    const { parcerias } = this.loginStorage.usuario;
    return parcerias.find(({ chave }) => chave === chaveParceria);
  }

  private getLojaParceira(): number {
    return this.loginStorage.usuario.lojaTO.id;
  }

  private pagamentoRealizado(statusPagamento: number): boolean {
    if (Number(statusPagamento) === StatusPagamento.TODOS) {
      return null;
    }
    return Number(statusPagamento) === StatusPagamento.REALIZADO;
  }

  private sort(lista: any[], campo: string): any[] {
    lista.sort((a: any, b: any) => a[campo].localeCompare(b[campo]));
    return lista;
  }
}
