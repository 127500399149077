import * as moment from 'moment';

export class Data {
  public static toMilissegundos(value: string): number {
    if (value) {
      const dataFormatada = moment(value, 'DDMMYYYY', true).format('DD/MM/YYYY');
      const dataStrict = moment(dataFormatada, 'DD/MM/YYYY', true).format();
      return Date.parse(`${dataStrict.split('/').reverse().join(' ')}`);
    }
    return undefined;
  }
}
