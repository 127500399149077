import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PanelCustomComponent } from './panel-custom.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PanelCustomComponent],
  exports: [PanelCustomComponent]
})
export class PanelCustomModule { }
