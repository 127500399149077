import {
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild,
} from '@angular/core';
import { take, takeWhile } from 'rxjs/operators';
import { ModalMessageComponent } from '../../../../shared/modais/modal-message/modal-message.component';
import { timer as observableTimer } from 'rxjs/internal/observable/timer';
import { CreditoPessoalService } from '../../../../../app/produtos/cp/services/cp.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-consultando-dados',
  templateUrl: './consultando-dados.component.html',
  styleUrls: ['./consultando-dados.component.scss'],
})
export class ConsultandoDadosComponent
  implements OnInit, OnDestroy
{
  @ViewChild('modalMessage', { static: true })
  @Input() idProposta: string;
  @Output() webLogicStatus = new EventEmitter();

  modalMessage: ModalMessageComponent;
  private isConsultarAnaliseProposta = false;

  isAlive = true;

  constructor(
    private cpService: CreditoPessoalService,
  ) {}

  ngOnInit() {
    this.iniciarConsultaWebLogic(this.idProposta);
  }
  ngOnDestroy() {
    this.isConsultarAnaliseProposta = false;
  }

  private iniciarConsultaWebLogic(idProposta: string) {
    this.isConsultarAnaliseProposta = true;
    const timerConsulta = observableTimer(1000, 20000);
    timerConsulta.pipe(
        takeWhile(() => this.isConsultarAnaliseProposta))
        .subscribe(() => {
          this.consultarAnaliseProposta(idProposta);
        });
  }

  private consultarAnaliseProposta(idproposta: string) {
    this.cpService
        .getWebLogicStatusAnalise(idproposta)
        .pipe(
            takeWhile(() => true)
        )
        .subscribe(
          result => this.handleResultSimulacao(result),
          error => this.handleErrorConsulta(error),
        );
  }

  private handleResultSimulacao(result: any) {
    if (result.statusAnaliseCpf === 'APROVADO' || 'RECUSADO' || 'PENDENTE') {
      this.isConsultarAnaliseProposta = false;
      this.webLogicStatus.emit({ status: result.statusAnaliseCpf, perfilCliente: result.perfilCliente });
    }
  }

  private handleErrorConsulta(error: HttpErrorResponse) {
    if (error.error.status === 'NOT_FOUND') {
      console.log('Error', 'Dados da análise não encontrado');
    }
  }
}
