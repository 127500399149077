import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import * as printJS from 'print-js';

import { CdcLojaRestService } from '../../../omni-rest/cdc/cdc-loja-rest.service';
import { appSettings } from './../../../../environments/app.setings';
import { DocumentoEnviadoVO, DocumentoVO } from './../documentoVO';
import { VisualizaImagemComponent } from './../visualiza-imagem/visualiza-imagem.component';
import { VisualizacaoDocumentacaoComponent } from './../visualizacao-documentacao/visualizacao-documentacao.component';

@Component({
  selector: 'app-upload-documentation-item',
  templateUrl: './upload-documentation-item.component.html',
  styles: [`
    .pb-3 { padding-bottom: 3px; }
    .pl-5 { padding-left: 5px; }
    .pb-5 { padding-bottom: 5px; }
    .pr-5 { padding-right: 5px; }
    .align-top { vertical-align: top !important }
    .lh-17 { line-height: 17px; }
  `]
})
export class UploadDocumentationItemComponent {
  @Input() files: any[];
  @Input() filesDocuments: any;
  @Input() document: DocumentoVO;
  @Input() documentIndex: number;
  @Input() acceptFiles;
  @Input() timeline: any;

  @Output() fileChange = new EventEmitter();
  @Output() removeFileCallback = new EventEmitter();
  @Output() removeAllFilesCallback = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    private cdcLojaRestService: CdcLojaRestService
  ) { }

  get hasFile() {
    return this.files && this.files.length;
  }

  get wasUploaded() {
    return (
      this.document.documentosEnviado &&
      this.document.documentosEnviado.length > 0
    );
  }

  get hasUploadDefined() {
    return this.document.url !== undefined;
  }

  get filesToUpload() {
    return this.files || [];
  }

  get uploadedFiles() {
    return this.document.documentosEnviado || [];
  }

  get mustShowAviso() {
    return this.timeline && this.timeline.filter(time => time.idStatus === 21)[0].dataHora === undefined;
  }

  show(imagem: DocumentoEnviadoVO) {
    const initialState = { imagem };
    this.modalService.show(VisualizaImagemComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
  }

  async print(document: DocumentoVO) {
    if (document.url.includes('reports')) {
      this.cdcLojaRestService
        .get(document.url, {}, 'arraybuffer')
        .subscribe((res) => {
          const pdfFile = new Blob([res.body], {
            type: 'application/pdf',
          });
          const pdfUrl = URL.createObjectURL(pdfFile);
          printJS({
            printable: pdfUrl,
            showModal: true,
            modalMessage: 'Carregando documento...',
          });
        });
    } else {
      window.open(document.url, '_blank').focus();
    }
  }

  showAvisoDocumentacao() {
    const initialState = {};
    this.modalService.show(VisualizacaoDocumentacaoComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
  }
}
