import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CdcLojaRestService } from '../../omni-rest/cdc/cdc-loja-rest.service';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';

@Injectable()
export class LojistaService {

  constructor(private restService: OmniMaisRestService, private cdcLojaRestService: CdcLojaRestService) { }

  listaLojistaOperador(): Observable<any> {
    return this.restService.get('lojista/listaLojistaOperador');
  }

  verificarTipoLojista(): Observable<{ lojistaTipoFabrica: boolean }> {
    return this.cdcLojaRestService.get('lojistas/tipo-lojista');
  }

}
