import { BsModalRef } from 'ngx-bootstrap';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-multiplos-enderecos',
  templateUrl: './modal-multiplos-enderecos.component.html',
  styleUrls: ['./modal-multiplos-enderecos.component.scss']
})
export class ModalMultiplosEnderecosComponent implements OnInit, OnDestroy {

  @Input() listaEnderecos: any;
  @Input() isBuscaPorEndereco?: boolean;

  alive = true;
  closeModal: Subject<any> = new Subject();

  formMultiplosEnderecos: FormGroup;

  constructor(
    private modalInstance: BsModalRef,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  initForm() {
    this.formMultiplosEnderecos = this.fb.group({
      enderecos: ['', Validators.required]
    });
  }

  onChangeEndereco(enderecoSelecionado) {
    this.formMultiplosEnderecos.patchValue({ enderecos: enderecoSelecionado });
  }

  onClickConfirmar() {
    this.closeModal.next(this.enderecoConfirmado);
    this.modalInstance.hide();
  }

  close() {
    this.modalInstance.hide();
  }

  getTipoLogradouro(tipo, endereco) {
    if (tipo && endereco) {
      return `${tipo} ${endereco} -`;
    }
  }

  get formValid() {
    return this.enderecos;
  }

  get enderecos() {
    return this.formMultiplosEnderecos.get('enderecos').value;
  }

  get enderecoConfirmado() {
    return this.formMultiplosEnderecos.getRawValue();
  }
}
