import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-after-selfie',
  templateUrl: './after-selfie.component.html',
  styleUrls: ['./after-selfie.component.scss'],
})
export class AfterSelfieComponent implements OnInit {
  @Input() foto: File;
  fotoUrl: any;

  async ngOnInit() {
    this.fotoUrl = await this.readURL(this.foto);
  }

  private readURL(file) {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e:any) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  }
}
