import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-aviso-cpf-invalido',
  templateUrl: './modal-aviso-cpf-invalido.component.html',
  styleUrls: ['./modal-aviso-cpf-invalido.component.scss'],
})
export class ModalAvisoCpfInvalidoComponent implements OnInit {
  @Input() message: string;
  closeModal: Subject<boolean> = new Subject();
  label: string = 'DIGITAR NOVAMENTE';
  isErroBiometria = false;

  constructor(private modalInstance: BsModalRef) {}

  ngOnInit() {
    this.verificaLabel();
  }

  close() {
    if (this.isErroBiometria) {
      this.closeModal.next(this.isErroBiometria);
    }
    this.modalInstance.hide();
  }

  verificaLabel() {
    if (this.message.includes('realizada')) {
      this.label = 'Ok, entendi';
      this.isErroBiometria = true;
    }
  }
}
