import { take, takeWhile, finalize } from 'rxjs/operators';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TipoVisualizacaoService } from '../../services/tipo-visualizacao/tipo-visualizacao.service';
import { FichaService } from '../ficha-veiculo.service';
import { LoginStorage } from './../../login/login.storage';
import { MenuSharedService } from './../../menu/menu-shared.service';
import { StatusModel } from './../../models/statusModel';
import { ModalMessageComponent } from './../../shared/modais/modal-message/modal-message.component';
import { FichaListaSharedService } from './ficha-lista.shared.service';
import { StatusEmAnalise } from './ficha-status/ficha-status-analise-impl';
import { StatusAprovada } from './ficha-status/ficha-status-aprovada-impl';
import { StatusFechamento } from './ficha-status/ficha-status-fechamento-impl';
import { FichaStatus } from './ficha-status/ficha-status-interface';
import { StatusRecusada } from './ficha-status/ficha-status-recusada-impl';
import { FichaListaFiltro } from './model/ficha-lista-filtro';
import { StatusContratoCancelado } from './ficha-status/ficha-status-contrato-cancelado-impl';
import { SelectProdutoComponent } from '../../shared/select-produto/select-produto.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ficha',
  templateUrl: './ficha-lista.component.html',
  styleUrls: ['./ficha-lista.component.css'],
})
export class FichaListaComponent
  implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;

  @ViewChild('produtos', { static: true })
  produtosComponent: SelectProdutoComponent;

  filtro: FichaListaFiltro = new FichaListaFiltro();

  propostasEmAnalise = [];
  quantSosPropostasEmAnalise = 0;
  propostasAprovada = [];
  quantSosPropostasAprovadas = 0;
  propostasEmFechamento = [];
  quantSosPropostasEmFechamento = 0;
  propostasRecusada = [];
  propostasContratoCancelado = [];

  propostasEmAnaliseFiltro = [];
  quantSosPropostasEmAnaliseFiltro = 0;

  isErroCarregamento = false;

  isEmAnalise: boolean;
  isPesquisandoEmAnalise = false;

  isEmFechamento: boolean;
  isPesquisandoEmFechamento = false;

  isAprovada: boolean;
  isPesquisandoAprovada = false;

  isRecusada: boolean;
  isPesquisandoisRecusada = false;

  isContratoCancelado: boolean;
  isPesquisandoisContratoCancelado = false;

  isEmAnaliseFiltro: boolean;
  isPesquisandoEmAnaliseFiltro = false;

  isFilters = false;

  disableTabs = false;

  status: StatusModel[] = [];
  interval: any;
  isAlive = true;

  showLoader: boolean = false;

  isCardView: boolean;
  produtosFiltro: any;

  constructor(
    private ref: ChangeDetectorRef,
    private fichaService: FichaService,
    private router: Router,
    private loginStorage: LoginStorage,
    private menuSharedService: MenuSharedService,
    private fichaListaSharedService: FichaListaSharedService,
    private tipoVisualizacaoService: TipoVisualizacaoService,
    private fb: FormBuilder
  ) { }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnInit() {
    this.onClickEmAnalise();
    this.isCardView = this.loginStorage.usuario.visualizacaoProposta === 'C';
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.isAlive = false;
  }

  private initObservableAgenteChange() {
    this.menuSharedService.onChangeAgente
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.loadFichas());
  }

  private initObservableReloadFichas() {
    this.fichaListaSharedService.reloadFichaListaObservable
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.loadFichas());
  }

  // private refreshByTime(secs: number) {
  //   this.interval = setInterval(() => {
  //     this.refreshFichas();
  //   }, 1000 * secs);
  // }

  refreshFichas() {
    if (
      !this.isPesquisandoEmAnalise &&
      !this.isPesquisandoAprovada &&
      !this.isPesquisandoEmFechamento &&
      !this.isPesquisandoisRecusada &&
      !this.isPesquisandoisContratoCancelado
    ) {
      this.loadFichas();
    }
  }

  onChangeFiltroProdutos(produtosFiltro) {
    this.produtosFiltro = produtosFiltro.map((ele) => {
      return {
        descricao: ele.descricaoNormalized,
        checked: ele.checked,
      };
    });

    this.createDataFilterProdutos(produtosFiltro);
    this.loadFichas();
    this.initObservableAgenteChange();
    this.initObservableReloadFichas();
  }

  createDataFilterProdutos(idFase) {
    return {
      idFase,
      produtos: this.produtosFiltro,
    };
  }

  private loadFichas() {
    this.isErroCarregamento = false;
    this.initVariablesControls();

    this.fichaService
      .findAllEmAnalise(this.createDataFilterProdutos(1))
      .pipe(finalize(() => (this.isPesquisandoEmAnalise = false)))
      .subscribe(
        (res) => {
          this.propostasEmAnalise = this.handlePropostas(res);
        },
        (err) => {
          this.propostasEmAnalise = [];
          this.tratarTimeout(err);
        }
      );

    this.fichaService
      .findAllAprovada(this.createDataFilterProdutos(2))
      .pipe(finalize(() => (this.isPesquisandoAprovada = false)))
      .subscribe(
        (res) => {
          this.propostasAprovada = this.handlePropostas(res);
        },
        (err) => {
          this.propostasAprovada;
          this.tratarTimeout(err);
        }
      );

    this.fichaService
      .findAllEmFechamento(this.createDataFilterProdutos(3))
      .pipe(finalize(() => (this.isPesquisandoEmFechamento = false)))
      .subscribe(
        (res) => {
          this.propostasEmFechamento = this.handlePropostas(res);
        },
        (err) => {
          this.propostasEmFechamento = [];
          this.tratarTimeout(err);
        }
      );

    this.fichaService
      .findAllRecusada(this.createDataFilterProdutos(4))
      .pipe(finalize(() => (this.isPesquisandoisRecusada = false)))
      .subscribe(
        (res) => (this.propostasRecusada = this.handlePropostas(res)),
        (err) => {
          this.propostasRecusada = [];
          this.tratarTimeout(err);
        }
      );

    if (this.showContratoCancelado) {
      this.fichaService
        .findAllContratoCancelado(this.createDataFilterProdutos(5))
        .pipe(finalize(() => (this.isPesquisandoisRecusada = false)))
        .subscribe(
          (res) => (this.propostasContratoCancelado = this.handlePropostas(res)),
          (err) => {
            this.propostasContratoCancelado = [];
            this.tratarTimeout(err);
          }
        );
    }
  }

  private tratarTimeout(response: any) {
    if (response.status === 504 && !this.isErroCarregamento) {
      this.modalMessage.show(response.error.message);
      this.isErroCarregamento = true;
    }
  }

  private initVariablesControls() {
    this.quantSosPropostasEmAnalise = 0;
    this.quantSosPropostasAprovadas = 0;
    this.quantSosPropostasEmFechamento = 0;
    this.isPesquisandoEmAnalise = true;
    this.isPesquisandoAprovada = true;
    this.isPesquisandoEmFechamento = true;
    this.isPesquisandoisRecusada = true;
  }

  onChangeView(status) {
    if (status !== this.isCardView) {
      this.isCardView = status;
      this.saveTipoVisualizacao(this.isCardView);
    }
  }

  private saveTipoVisualizacao(isCardView: boolean): void {
    this.showLoader = true;
    this.tipoVisualizacaoService
      .setVisualizacao({
        idAgente: this.loginStorage.usuario.id,
        tipoVisualizacao: isCardView ? 'C' : 'L',
      })
      .pipe(
        take(1),
        finalize(() => (this.showLoader = false))
      )
      .subscribe();
  }

  onClickEmAnalise(): void {
    this.hideAllTabs();
    this.isEmAnalise = true;
    this.filtro.data = '30';
    this.setStatus(new StatusEmAnalise());
    this.filtro.status = '-1';
  }

  onClickEmFechamento(): void {
    this.hideAllTabs();
    this.isEmFechamento = true;
    this.filtro.data = '30';
    this.setStatus(new StatusFechamento());
    this.filtro.status = '-1';
  }

  onClickEmAprovada(): void {
    this.hideAllTabs();
    this.isAprovada = true;
    this.filtro.data = '30';
    this.setStatus(new StatusAprovada());
    this.filtro.status = '-1';
  }

  onClickEmRecusada(): void {
    this.hideAllTabs();
    this.isRecusada = true;
    this.filtro.data = '30';
    this.setStatus(new StatusRecusada());
    this.filtro.status = '-1';
  }

  onClickEmContratoCancelado(): void {
    this.hideAllTabs();
    this.isContratoCancelado = true;
    this.filtro.data = '30';
    this.setStatus(new StatusContratoCancelado());
    this.filtro.status = '-1';
  }

  private hideAllTabs() {
    this.isEmAnalise = false;
    this.isEmFechamento = false;
    this.isAprovada = false;
    this.isRecusada = false;
    this.isContratoCancelado = false;
  }

  private setStatus(status: FichaStatus): void {
    this.status = status.getStatus();
  }

  private handlePropostas(res: any) {
    return res && res.propostasFase && res.propostasFase[0]
      ? res.propostasFase[0].propostas
      : [];
  }

  quantidadeSosAnalise(data) {
    this.quantSosPropostasEmAnalise = data;
  }
  quantidadeSosAprovada(data) {
    this.quantSosPropostasAprovadas = data;
  }
  quantidadeSosFechamento(data) {
    this.quantSosPropostasEmFechamento = data;
  }

  private calcularQuantidadeSOS(propostas: any): number {
    let quant = 0;
    propostas.forEach((proposta) => (quant += proposta.totalSosPendente));
    return quant;
  }

  addFicha() {
    if (this.loginStorage.usuario.excedeProducao === 'N') {
      this.loginStorage.usuario.parcerias.length === 0 ? this.router.navigate(['/nova']) : this.router.navigate(['produtos/cdcloja/ecommerce']);
    } else {
      this.modalMessage.show(
        'Excedido o percentual de produção permitido para o mês.'
      );
    }
  }

  get showContratoCancelado() {
    return (this.produtosComponent.produtos || []).some(({ descricao }) =>  (descricao === 'CDC LOJA') || descricao === 'CDC PREMIUM');
  }
}
