import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-sms-not-received',
  templateUrl: './sms-not-received.component.html',
  styleUrls: ['./sms-not-received.component.scss']
})
export class SmsNotReceivedComponent {

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  onAction() {
    this.bsModalRef.hide();
  }

}
