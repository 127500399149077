import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationComponent } from './authentication/authentication.component';
import { socialLoginConfig } from './login-social.config';
import { LoginFacebookComponent } from './login-social/login-facebook/login-facebook.component';
import { LoginGoogleComponent } from './login-social/login-google/login-google.component';
import { LoginComponent } from './login.component';
import { LoginGuard } from './login.guard';
import { LoginService } from './login.service';
import { LoginStorage } from './login.storage';
import { SelectAgenteComponent } from './select-agente/select-agente.component';

export function provideConfig() {
  return socialLoginConfig;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    SocialLoginModule,
  ],
  declarations: [
    LoginComponent,
    LoginFacebookComponent,
    LoginGoogleComponent,
    AuthenticationComponent,
    SelectAgenteComponent
  ],
  exports: [
    LoginComponent,
    LoginFacebookComponent,
    LoginGoogleComponent,
    AuthenticationComponent,
    SelectAgenteComponent
  ],
  providers: [
    LoginService,
    LoginGuard,
    LoginStorage,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
})
export class LoginModule { }
