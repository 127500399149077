import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-parcela-personalizada',
  templateUrl: './parcela-personalizada.component.html',
  styles: [
    `
      .modal-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px 40px;
        border-top: 1px solid #f2f2f2;
      }
      .modal-footer::after,
      .modal-footer::before {
        display: none;
      }
      .bt-back,
      .bt-go {
        padding: 8px 25px;
      }
      .modal-header {
        border-bottom: 1px solid #f2f2f2;
      }
      .modal-body {
        padding: 25px 20px;
      }
      .inputs {
        width: 100%;
      }
    `
  ]
})
export class ParcelaPersonalizadaComponent implements OnInit {

  alive: boolean = true;
  alterarValorForm: FormGroup;
  parcelasPersonalizada: number[];
  onSave: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.alterarValorForm = this.fb.group({
      quantidadeParcelas: ['', Validators.required]
    });
  }

  gerarParcelas(quantidadeMaxima: number, quantidadeMinima: number): void {
    this.parcelasPersonalizada = [];

    for (let i = quantidadeMinima; i <= quantidadeMaxima; i++) {
      this.parcelasPersonalizada.push(i);
    }
  }

  onPersonalizarParcela() {
    this.onSave.emit(this.alterarValorForm.value);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }
}
