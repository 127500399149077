import { Directive, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[moneyCheck],[moneyCheck][ngModel]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MoneyCheckDirective)
    }
  ]
})
export class MoneyCheckDirective implements Validator, OnChanges {
  @Input() moneyCheck: number;
  @Input() moneyMin: number;
  @Input() moneyMax: number;

  private onChange: () => void;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onChange) {
      this.onChange();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (!this.moneyCheck || this.moneyCheck === null || this.moneyCheck === undefined) {
      return null;
    }

    if (this.moneyMin) {
      return this.moneyCheck < this.moneyMin ? { moneyRange: true } : null;
    }

    if (this.moneyMax) {
      return this.moneyCheck > this.moneyMax ? { moneyRange: true } : null;
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onChange = fn;
  }

}
