export class RecuperaProfissional {
  private idClasseProfissional: number;
  private rendaAtribuida: boolean;
  private salario: number;
  private tipo: string;
  private cargo: number;
  private nome: string;
  private idProfissao: number;
  private grupoProfissao: number;

  constructor(obj) {
    this.idClasseProfissional = parseInt(obj.classeProfissional.id, 10);
    this.rendaAtribuida = obj.rendaAtribuida;
    this.salario = obj.salario;
    this.tipo = obj.tipo;
    this.cargo = obj.cargo;
    this.nome = obj.nome;
    if (obj.profissao) {
      this.idProfissao = obj.profissao.id;
      this.grupoProfissao = obj.profissao.grupoProfissao;
    }
  }

  getIdClasseProfissional(): number {
    return this.idClasseProfissional;
  }

  getRendaAtribuida(): boolean {
    return this.rendaAtribuida;
  }

  getSalario(): number {
    return this.salario;
  }

  getTipo(): string {
    return this.tipo;
  }

  getCargo(): number {
    return this.cargo;
  }

  getNome(): string {
    return this.nome;
  }

  getIdProfissao(): number {
    return this.idProfissao;
  }

  getGrupoProfissao(): number {
    return this.grupoProfissao;
  }

}
