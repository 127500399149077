import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextSharedService } from '../../../context-shared/context-shared.service';
import { LoginStorage } from '../../../login/login.storage';
import { NovaProposta } from './nova-proposta';
import { TipoSolicitanteEnum } from './quem-solicitou/tipo-solicitante.enum';
import { TipoNegociacaoEnum } from './tipo-operacao/enum/tipo-negociacao.enum';
import { TipoProdutoEnum } from './tipo-operacao/enum/tipo-produto.enum';
import { TipoOperacaoSelecao } from './tipo-operacao/tipo-operacao-selecao';
import { PropostaStateService } from '../../../produtos/shared/states/proposta-state.service';
import { StorageService } from '../../../services/storage/storage.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  dadosOperacao: TipoOperacaoSelecao;
  solicitante: string;

  constructor(
    private router: Router,
    private loginStorage: LoginStorage,
    private context: ContextSharedService,
    private state: PropostaStateService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.solicitante = this.isLojistaLogado
      ? TipoSolicitanteEnum.LOJA
      : this.solicitante;

    this.storageService.patchLocal({
      lastTimeAProposalStarted: (new Date).getTime()
    });
  }

  get isLojistaLogado() {
    return this.loginStorage.usuario.isLojista;
  }

  onDadosOperacaoChange(event: TipoOperacaoSelecao) {
    this.dadosOperacao = event;
    this.solicitante = this.isOperacaoCdcLoja
      ? TipoSolicitanteEnum.VENDEDOR
      : this.solicitante;
  }

  onSolicitanteChange(event) {
    this.solicitante = event;
  }

  get isShowSolicitante() {
    return (
      !this.isLojistaLogado &&
      (
        this.isDadosOperacaoVeiculosValid ||
        (this.isDadosOperacaoCreditoPessoalValid)
      )
    );
  }

  get isDadosOperacaoVeiculosValid() {
    return (this.isOperacaoVeiculo && !this.dadosOperacao.leads) || (this.isOperacaoVeiculo && this.dadosOperacao.leads && this.dadosOperacao.produto);
  }

  get isDadosOperacaoCreditoPessoalValid() {
    const valid =
      this.isOperacaoCreditoPessoal &&
      this.dadosOperacao.produto &&
      this.dadosOperacao.operacao;

    if (
      valid &&
      this.dadosOperacao.produto === TipoNegociacaoEnum.DEBITO_AUTOMATICO
    ) {
      return this.dadosOperacao.banco;
    }

    return valid;
  }

  get isOperacaoValid() {
    return (this.isOperacaoCdcLoja || this.isOperacaoMicrocredito || this.isOperacaoCdcPremium)
     && this.dadosOperacao.operacao;
  }

  get isOperacaoVeiculo() {
    return [
      TipoProdutoEnum.FINANCIMENTO,
      TipoProdutoEnum.REFINANCIAMENTO
    ].includes(this.dadosOperacao.tipoProduto);
  }

  get isOperacaoCdcLoja() {
    return this.dadosOperacao.tipoProduto === TipoProdutoEnum.CDC_LOJA;
  }

  get isOperacaoCdcPremium() {
    return this.dadosOperacao.tipoProduto === TipoProdutoEnum.CDC_PREMIUM;
  }

  get isOperacaoCreditoPessoal() {
    return this.dadosOperacao.tipoProduto === TipoProdutoEnum.CREDITO_PESSOAL;
  }

  get isOperacaoMicrocredito() {
    return this.dadosOperacao.tipoProduto === TipoProdutoEnum.MICROCREDITO;
  }

  get isNext() {
    return (
      this.solicitante &&
      (this.isOperacaoValid ||
        this.isDadosOperacaoCreditoPessoalValid ||
        this.isDadosOperacaoVeiculosValid)
    );
  }

  onclickNext() {
    this.onClickNextFichaVeiculo();
  }

  onClickNextFichaVeiculo() {
    this.navigateToNextRouteNavigate();
  }

  navigateToNextRouteNavigate() {
    this.state.clear();
    this.context.set(this.dadosSelecionado);
    this.router.navigate(this.nextRoute);
  }

  get nextRoute() {
    if (this.isOperacaoCdcLoja || this.isOperacaoCdcPremium) {
      return ['produtos/cdcloja/fichas'];
    }

    if (this.isOperacaoCreditoPessoal || this.isOperacaoMicrocredito) {
      return ['produtos/cp/fichas'];
    }

    if (this.isOperacaoVeiculo) {
      const operacao = this.dadosOperacao.tipoProduto === TipoProdutoEnum.FINANCIMENTO ? 'financiamento' : this.dadosOperacao.tipoProduto;
      if (this.dadosOperacao.leads) {
        return [
          'produtos/leads/nova-ficha-veiculo',
          operacao.toLocaleLowerCase(),
          this.dadosOperacao.produto,
          this.solicitante.toLocaleLowerCase()
        ];
      }
      return [
        'nova-ficha-veiculo',
        operacao.toLocaleLowerCase(),
        this.solicitante.toLocaleLowerCase()
      ];
    }

    return ['ficha'];
  }

  get dadosSelecionado(): NovaProposta {
    return {
      solicitante: this.solicitante,
      tipoProduto: this.dadosOperacao.tipoProduto,
      operacao: this.dadosOperacao.operacao,
      produto: this.dadosOperacao.produto,
      banco: this.dadosOperacao.banco,
      parametroRendaProduto: this.dadosOperacao.parametroRendaProduto
    };
  }
}
