import {
  Component,
  Input,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

import { CdcLojaRestService } from '../../../../../omni-rest/cdc/cdc-loja-rest.service';
import { ModalMessageComponent } from '../../../../../shared/modais/modal-message/modal-message.component';

@Component({
  selector: 'app-mercadoria',
  templateUrl: './mercadoria.component.html',
  styleUrls: ['./mercadoria.component.scss']
})
export class MercadoriaComponent implements OnInit, OnDestroy {
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;
  @Input() indentificacaoForm: FormGroup;
  @Input() valorLiquido: number;

  mercadoriaForm: FormGroup;
  mercadorias: any[];
  tiposMercadoria: any[];
  isAlive: boolean = true;

  constructor(
    private cdcLojaRestService: CdcLojaRestService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForms();
    this.initObservers();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  initForms(): void {
    this.mercadoriaForm = this.fb.group(
      {
        tiposMercadoria: ['', Validators.required],
        valorProduto: ['', Validators.required]
      },
    );

    this.indentificacaoForm.addControl(
      'mercadoriaForm',
      this.mercadoriaForm
    );
  }

  initObservers(): void {
    this.cdcLojaRestService
      .get('mercadorias/tipos').pipe(
        takeWhile(() => this.isAlive))
      .subscribe((res) => (this.handleMercadoria(res)));
  }

  handleMercadoria(res) {
    this.tiposMercadoria = res;
    this.filtraMercadoriaDell(this.tiposMercadoria);
  }

  filtraMercadoriaDell(mercadorias) {
    const mercadoria = mercadorias.filter(mercadoria => mercadoria.id === 11)[0];
    this.mercadoriaForm.controls['tiposMercadoria'].setValue(mercadoria.id);
    this.mercadoriaForm.controls['valorProduto'].setValue(this.valorLiquido);
    this.mercadoriaForm.disable();
  }

  get tipoMercadoria(): AbstractControl {
    return this.mercadoriaForm.get('tiposMercadoria');
  }

  get valorProduto(): AbstractControl {
    return this.mercadoriaForm.get('valorProduto');
  }
}
