import * as moment from 'moment';
import { Periodo } from './periodo';

export class TresDias implements Periodo {

  padrao = false;

  descricao = '3 dias';

  constructor(padrao?: boolean) {
    this.padrao = padrao || false;
  }

  get dataInicio(): number {
    return moment().subtract(3, 'day').unix() * 1000;
  }

  get dataFim(): number {
    return moment.now();
  }
}
