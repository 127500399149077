import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appDataEmissaoValidator],[appDataEmissaoValidator][ngModel]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DataEmissaoValidatorDirective)
    }
  ]
})
export class DataEmissaoValidatorDirective {

  @Input() dataNascimento: number;

  private message: string;

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validateData(c.value) ? null : { message: this.message };
  }

  validateData(dataEmissao) {
    this.message = undefined;

    if (!dataEmissao || dataEmissao.length < 8) {
      return true;
    }

    const emissao = moment(dataEmissao, 'DDMMYYYY');

    if (dataEmissao.length !== 8 || !emissao.isValid()) {
      this.message = 'Data inválida';
      return false;
    }

    if (!this.dataNascimento) {
      return true;
    }

    const nascimento = moment(new Date(this.dataNascimento));
    if (dataEmissao && emissao.isBefore(nascimento)) {
      this.message = 'Data de emissão do documento deve ser superior a data de nascimento';
      return false;
    }

    if (dataEmissao && emissao.isAfter(moment())) {
      this.message = 'Data de emissão do documento não pode ser superior a data atual';
      return false;
    }

    return true;
  }
}
