import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-aviso-cpf-invalido',
  templateUrl: './modal-aviso-cpf-invalido.component.html',
  styleUrls: ['./modal-aviso-cpf-invalido.component.scss'],
})
export class ModalAvisoCpfInvalidoComponent {
  @Input() message: string;

  constructor(private modalInstance: BsModalRef) {}

  close() {
    this.modalInstance.hide();
  }
}
