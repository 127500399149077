import { Injectable } from '@angular/core';
import { OmniGeralRestService } from '../../../omni-rest/omni-geral/omni-geral-rest.service';

@Injectable()
export class OperacoesGeralService {

  constructor(private restService: OmniGeralRestService) { }

  tiposProdutos() {
    return this.restService.get('operacoes/tipos-produto');
  }

  produtosPorUsuario(tipoOperacao) {
    return this.restService.get(`operacoes/produtos-por-usuario/${tipoOperacao}`);
  }
}
