import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-verificacao',
  templateUrl: './modal-verificacao.component.html',
  styleUrls: ['./modal-verificacao.component.scss']
})
export class ModalVerificacaoComponent implements OnInit, OnDestroy {

  form: FormGroup;
  onChangeCodigoSeguranca = new EventEmitter();
  alive = true;

  constructor(
    private instance: BsModalRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      codigoSeguranca: []
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  close() {
    this.instance.hide();
  }

  onClickConfirm() {
    if (!this.form.invalid) {
      const codigoSeguranca = this.form.get('codigoSeguranca').value;

      this.onChangeCodigoSeguranca.emit({ codigoSeguranca });
      this.close();
    }
  }
}
