import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FaefStateService } from './../faef-state.service';
import { Faef, FaefCompras } from './../model/faef';

@Component({
  selector: 'app-compras-trimestre',
  templateUrl: './compras-trimestre.component.html'
})
export class ComprasTrimestreComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  compraForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private state: FaefStateService
  ) { }

  ngOnInit() {
    this.createForms();

    const faef = this.state.getLast();
    if (faef.faefCompras) {
      this.compraForm.patchValue(faef.faefCompras, { emitEvent: false });
    }
  }

  private createForms() {
    this.compraForm = this.fb.group({
      comprasAUmMes: ['', Validators.required],
      comprasADoisMeses: ['', Validators.required],
      comprasATresMeses: ['', Validators.required],
      percentualComprasAVista: ['', [Validators.required, Validators.max(100), Validators.min(0)]]
    });
  }

  private updateState() {
    const faef:Faef = this.state.getLast();
    const faefCompras:FaefCompras = this.compraForm.value;
    faef.faefCompras = {
      comprasAUmMes: Number(faefCompras.comprasAUmMes),
      comprasADoisMeses: Number(faefCompras.comprasADoisMeses),
      comprasATresMeses: Number(faefCompras.comprasATresMeses),
      percentualComprasAVista: Number(faefCompras.percentualComprasAVista)
    };
    this.state.setState(faef);
  }

  async onClickNext() {
    this.updateState();
    this.next.emit();
  }

  onClickPrevious() {
    this.updateState();
    this.previous.emit();
  }

}
