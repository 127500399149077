import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginResult } from '../../login/model/login-result';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class StorageService {
  private localStorage = window.localStorage;
  private sessionStorage = window.sessionStorage;
  private key = '__storage__';

  constructor(
    private http: HttpClient
  ) { }

  patchLocal(data: any) {
    this.localStorage.setItem(this.key, JSON.stringify({
      ...this.getLocal(),
      ...data
    }));
  }

  getLocal(): any {
    return JSON.parse(this.localStorage.getItem(this.key)) || {};
  }

  removeLocalItem(itemKey: string) {
    const localData = this.getLocal();

    delete localData[itemKey];

    this.patchLocal({ ...localData });
  }

  patchSession(data: any) {
    this.sessionStorage.setItem(this.key, JSON.stringify({
      ...this.getSession(),
      ...data
    }));
  }

  getSession(): any {
    return JSON.parse(this.sessionStorage.getItem(this.key)) || {};
  }

  removeSessionItem(itemKey: string) {
    const localData = this.getSession();
    delete localData[itemKey];
    this.sessionStorage.setItem(this.key, JSON.stringify({ ...localData }));
  }

  renewUser(loginAndToken: string): Observable<LoginResult> {
    return this.http.post<LoginResult>(`${environment.URL_OMNI_FACIL}user/token`, {}, {headers: {
      'Content-Type': 'application/json',
      'omni-autenticacao': loginAndToken
    }});
  }
}
