import { Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { appSettings } from './../../../../../environments/app.setings';
import { FichaService } from '../../../ficha-veiculo.service';
import { FichaModalAvalistaComponent } from './../../ficha-modal-avalista/ficha-modal-avalista.component';

@Component({
  selector: 'app-avalista',
  templateUrl: './avalista.component.html',
  styleUrls: ['./avalista.component.css']
})
export class AvalistaComponent {
  @Input() acoes: any;
  @Input() idFicha: number;
  @Input() cpfCliente: any;
  bsModalRef: BsModalRef;
  isAvalistaObrigatorio: boolean;

  proposta: any;

  constructor(private modalService: BsModalService, private fichaService: FichaService) { }

  public openModalWithComponent() {
    this.bsModalRef = this.modalService.show(FichaModalAvalistaComponent, appSettings.MODAL_PARAMS);
    this.bsModalRef.content.cpfCliente = this.cpfCliente;
    (<FichaModalAvalistaComponent>this.bsModalRef.content).onCloseModal.subscribe(body => this.proposta = body);
    this.isAvalistaObrigatorio = false;
  }

  pushAvalista() {
    this.proposta.id = this.idFicha;
    return this.fichaService
      .pushAvalista({ proposta: this.proposta }).pipe(
        take(1))
      .subscribe();
  }

  validaEnvio(): boolean {
    if (this.proposta) {
      this.isAvalistaObrigatorio = false;
      return true;
    }
    this.isAvalistaObrigatorio = true;
    return false;
  }

  public hasIncluirAvalista(): boolean {
    if (this.acoes) {
      return JSON.stringify(this.acoes).includes('INCLUIR_AVAL');
    }
  }
}
