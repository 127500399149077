import { Directive, ElementRef, HostListener, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appCpfCnpj][formControlName],[appCpfCnpj][formControl],[appCpfCnpj][ngModel]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CpfCnpjDirective,
    multi: true,
  }],
})
export class CpfCnpjDirective implements ControlValueAccessor, OnChanges {

  onTouched: any;
  onChange: any;

  constructor(private _element: ElementRef) { }

  ngOnChanges(): void {
    if (this.onChange) {
      this.onChange();
    }
  }

  writeValue(value: any): void {
    if (value) {
      this._element.nativeElement.value = this.format(value);
    } else {
      this._element.nativeElement.value = '';
    }
  }

  private format(value: string): string {

    if (value) {
      let v = this.replace(value);
      v = v.replace(/\D/g, '');
      if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, '$1.$2');
        v = v.replace(/(\d{3})(\d)/, '$1.$2');
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      } else {
        v = v.replace(/^(\d{2})(\d)/, '$1.$2');
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
        v = v.replace(/(\d{4})(\d)/, '$1-$2');
      }
      return v;
    }
    return value;
  }

  private replace(value: string) {
    if (value) {
      return value.replace(/\D/g, '');
    }
    return value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: any) {
    $event.target.value = this.format($event.target.value);
    this.changeNgModel($event.target.value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    const e = <KeyboardEvent>event;

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }

    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

    if (event.target.value && event.target.value.length >= 18) {
      e.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    $event.target.value = this.format($event.target.value);
    this.changeNgModel($event.target.value);
    this.onTouched();
  }

  private changeNgModel(value: string) {
    if (value) {
      this.onChange(this.replace(value));
    } else {
      this.onChange('');
    }
  }
}
