import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FaefStateService } from './../faef-state.service';
import { Faef, FaefMediaMes } from './../model/faef';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-media-vendas',
  templateUrl: './media-vendas.component.html',
  styleUrls: ['./media-vendas.component.scss'],
})
export class MediaVendasComponent implements OnInit, OnDestroy {
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  public vendaForm: FormGroup;
  public mediasMes: FormArray;

  selectedMinSixType = false;
  descricaoMeses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  alive = true;

  constructor(private fb: FormBuilder, private state: FaefStateService) {}

  ngOnInit() {
    this.createForms();
    const faef = this.state.getLast();
    if (faef.faefVendasMedia) {
      this.vendaForm.patchValue(faef.faefVendasMedia, { emitEvent: false });
      this.initMedias(faef.faefVendasMedia.faefVendasMediaMes);
    } else {
      this.initMedias([]);
    }
  }

  private initMedias(medias) {
    const findTipoMes = (mes) =>
      String(
        (medias.filter((e) => e.mes === mes)[0] || { mes, tipoMes: '' }).tipoMes
      );
    const novoMes = (mes) =>
      this.fb.group({ mes: [mes], tipoMes: [findTipoMes(mes)] });
    this.mediasMes.push(novoMes(1));
    this.mediasMes.push(novoMes(2));
    this.mediasMes.push(novoMes(3));
    this.mediasMes.push(novoMes(4));
    this.mediasMes.push(novoMes(5));
    this.mediasMes.push(novoMes(6));
    this.mediasMes.push(novoMes(7));
    this.mediasMes.push(novoMes(8));
    this.mediasMes.push(novoMes(9));
    this.mediasMes.push(novoMes(10));
    this.mediasMes.push(novoMes(11));
    this.mediasMes.push(novoMes(12));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private createForms() {
    this.mediasMes = this.fb.array([]);
    this.mediasMes.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((_) => this.validateMediaMes());

    this.vendaForm = this.fb.group({
      valorMesBom: ['', Validators.required],
      valorMesRegular: ['', Validators.required],
      valorMesRuim: ['', Validators.required],
    });
  }

  private validateMediaMes() {
    const meses = this.mediasMes.value;
    const aux = [];
    /** @description selecionar no minimo 6 meses */
    const count = meses.reduce(
      (acc, item, indice, array) => {
        let accumulator = acc;
        if (item.tipoMes) {
          if (Number.isInteger(+item.tipoMes)) {
            accumulator = accumulator + 1;
          }
        }
        return accumulator;
      },
      0
    );
    if (count >= 6) {
      /**
       * @description meses consecutivos
       */
      meses
        .reduce(
          (acc, item, indice, array) => {
            if (item.tipoMes) {
              if (Number.isInteger(+item.tipoMes)) {
                acc.push(+item.mes);
              }
            }
            return acc;
          },
          []
        )
        .map((r, ind, arr) => {
          if (r === arr[ind + 1] - 1) {
            aux.push(true);
          } else if (arr[ind + 1] !== undefined) {
            aux.push(false);
          }
        });
      this.selectedMinSixType = aux.filter((m) => !m).length === 0;
    } else {
      this.selectedMinSixType = false;
    }
  }

  private updateState() {
    const faef: Faef = this.state.getLast();
    const { valorMesBom, valorMesRegular, valorMesRuim } = this.vendaForm.value;
    faef.faefVendasMedia = {
      valorMesBom: Number(valorMesBom),
      valorMesRegular: Number(valorMesRegular),
      valorMesRuim: Number(valorMesRuim),
      faefVendasMediaMes: this.mediasMes.value
        .filter((e) => !!e.tipoMes)
        .map((x) => x as FaefMediaMes),
    };
    this.state.setState(faef);
  }

  async onClickNext() {
    this.updateState();
    this.next.emit();
  }

  onClickPrevious() {
    this.updateState();
    this.previous.emit();
  }
}
