import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginStorage } from '../../login/login.storage';
import { environment } from './../../../environments/environment';
import { OmniRestService } from '../omni-rest.service';
import { LoaderService } from '../../shared/loader/loader.service';

@Injectable()
export class OmniVehicleReportRestService extends OmniRestService {
  constructor(
    http: HttpClient,
    loaderService: LoaderService,
    loginStorage: LoginStorage,
    router: Router
  ) {
    super(http, loaderService, loginStorage, router);
  }

  getBaseUrl(): string {
    return environment.URL_OMNI_VEHICLE_REPORT;
  }

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'omni-autenticacao': this.loginStorage.loginAndTokenAsJson
    };
  }

}
