import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { removeFichaContent, getFichaContent } from '../../ficha-cadastro/helpers/salvarFicha';
import { StorageService } from '../../../services/storage/storage.service';
@Component({
  selector: 'app-ficha-timeline-resultado-parcial',
  templateUrl: './ficha-timeline-resultado-parcial.component.html',
  styleUrls: ['./ficha-timeline-resultado-parcial.component.css']
})
export class FichaTimelineResultadoParcialComponent {

  @Input() timeline: any;
  @Input() timelineActive: boolean;
  @Input() idFicha: number;
  @Input() isFichaResultadoParcial: boolean;

  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  verificarFichaSalva() {
    getFichaContent()
      .then(item => {
        if (item && Object.keys(item).length > 0) {
          if (item['proposta'] && item['proposta'].id !== this.idFicha) {
            removeFichaContent();
          }
        }
      });
  }

  onClickQuadro() {
    this.verificarFichaSalva();
    if (this.isFichaResultadoParcial) {
      this.storageService.removeLocalItem('lastTimeAProposalStarted');

      this.router.navigate([`/resultadoparcial/${this.idFicha}`]);
    }
  }

}
