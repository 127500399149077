import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
import { FileHandle } from '../upload-arquivos/file-handler';

@Directive({
  selector: '[appUploadArquivosDrop]'
})
export class UploadArquivosDropDirective {

  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
  @Output() change = new EventEmitter();

  @HostBinding('style.background') private background = '#fff';

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';

    this.change.emit(evt);
  }

}
