
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY as observableEmpty, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { LoginStorage } from '../login/login.storage';
import { LoaderService } from '../shared/loader/loader.service';
import { HttpStatus } from './http-status';

export abstract class OmniRestService {

  constructor(
    protected http: HttpClient,
    protected loaderService: LoaderService,
    protected loginStorage: LoginStorage,
    protected router: Router) { }

  abstract getBaseUrl(): string;

  abstract getHeaders(): { [header: string]: string };

  private whitelist : string[] = ['user/login', 'user/forgot-password', 'user/password'];

  post<T>(url: string, body?: any): Observable<T> {
    if (body) {
      return this.intercept(
        url,
        this.http.post(
          this.getBaseUrl().concat(url),
          JSON.stringify(body),
          { headers: this.getHeaders(), responseType: 'text', observe: 'response' }
        )
      );
    }
    return this.intercept(url, this.http.post(this.getBaseUrl().concat(url), {}, { headers: this.getHeaders(), responseType: 'text', observe: 'response' }));
  }

  put<T>(url: string, body?: any): Observable<T> {
    if (body) {
      return this.intercept(
        url,
        this.http.put(
          this.getBaseUrl().concat(url),
          JSON.stringify(body),
          { headers: this.getHeaders(), responseType: 'text', observe: 'response' }
        )
      );
    }
    return this.intercept(url, this.http.put(this.getBaseUrl().concat(url), {}, { headers: this.getHeaders(), responseType: 'text', observe: 'response' }));
  }

  patch<T>(url: string, body?: any): Observable<T> {
    if (body) {
      return this.intercept(
        url,
        this.http.patch(
          this.getBaseUrl().concat(url),
          JSON.stringify(body),
          { headers: this.getHeaders(), responseType: 'text', observe: 'response' }
        )
      );
    }
    return this.intercept(url, this.http.patch(this.getBaseUrl().concat(url), {}, { headers: this.getHeaders(), responseType: 'text', observe: 'response' }));
  }

  get(url: string, options?: any, responseType = 'text'): Observable<any> {
    return this.intercept(url, this.http.get(this.getBaseUrl().concat(url), { ...options, responseType, headers: this.getHeaders(), observe: 'response' }));
  }

  delete(url: string, body?: any): Observable<any> {
    return this.intercept(url, this.http.request('delete', this.getBaseUrl().concat(url), {
      body,
      headers: this.getHeaders(),
      responseType: 'text', observe: 'response'
    }));
  }

  intercept(url:string, observable: Observable<any>): Observable<any> {
    if (!this.whitelist.includes(url) && this.loginStorage.isTokenExpired()) {
      this.router.navigate(['/login']);
      return observableEmpty;
    }
    this.loaderService.show();
    return observable.pipe(
      map(res => {
        if (res.body && typeof res.body === 'string' && !['SUCCESS', 'FAIL'].includes(res.body.replace(/\"/g, ''))) {
          return JSON.parse(res.body);
        }
        return res;
      }),
      catchError((error, source) => {
        if (error && error.status && error.status === HttpStatus.UNAUTHORIZED) {
          this.router.navigate(['/login']);
          return observableEmpty;
        }

        if (error.text && error.text()) {
          error.error = error.json();
        } else if (typeof error.error === 'string') {
          error.error = JSON.parse(error.error);
        }

        return observableThrowError(error);
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }
}
