import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-animated-button',
  templateUrl: './animated-button.component.html',
  styleUrls: ['./animated-button.component.css']
})
export class AnimatedButtonComponent implements OnInit {

  @Input() label: string;
  @Input() afterLabel?: string;
  @Input() onclick: () => Promise<any>;
  @Input() onerror: (error) => void = () => {
    // This is intentional
  }
  @Input() onsuccess: (value:any) => void = (_) => {
    // This is intentional
  }
  @Input() small: boolean = false;
  @Input() disabled: boolean = false;
  @Input() rollbackClickWhenError: boolean = false;
  @Input() animated: boolean = true;
  @Input() wasExecuted: boolean = false;
  clicked = false;
  lock = false;

  ngOnInit() {
    this.clicked = this.wasExecuted;
    this.lock = this.clicked;
  }

  async onClick() {
    if (!this.lock) {
      this.lock = true;
      if (this.animated) {
        this.clicked = true;
      }

      try {
        const response = await this.onclick();
        this.onsuccess(response);
      } catch (error) {
        this.onerror(error);
        if (this.rollbackClickWhenError) {
          this.clicked = false;
        }
      }
    }
  }
}
