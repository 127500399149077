import { DadoProfissional } from '../../../model/dado-profissional';
import { TelefonePessoa } from '../../../model/telefone-pessoa';
import { Endereco } from '../../../model/endereco';

interface Documento {
  tipo: number;
  numero: string;
  dataEmissao: string;
  emissor: string;
}

export class PessoaSimulacao {
  private id: number;
  private cpf: string;
  private nome: string;
  private telefones: TelefonePessoa[];
  private dataNascimento: number;
  private renda: number;
  private email: string;
  private nomeMae: string;
  private nomePai: string;
  private idNacionalidade: number;
  private naturalDe: string;
  private naturalDeUf: string;
  private enderecos: Endereco[];
  private valorPatrimonio: number;
  private idEstadoCivil: string;
  private documento: Documento;
  private sexo: string;
  private ppe: boolean;
  private fatca: boolean;
  private tipo: string;
  private categoria: string;
  private profissional: DadoProfissional;
  private referencia: any;

  constructor(obj) {
    this.id = obj.id;
    this.cpf = obj.cpf;
    this.nome = obj.nome;
    this.telefones = (obj.telefones || []).map(telefone => new TelefonePessoa(telefone.idCategoria, telefone.idTipoTelefone, telefone.ddd, telefone.numero));
    this.dataNascimento = obj.dataNascimento;
    this.renda = obj.renda || 0;
    this.email = obj.email;
    this.nomeMae = obj.nomeMae;
    this.nomePai = obj.nomePai;
    this.idNacionalidade = obj.idNacionalidade;
    this.naturalDe = obj.naturalDe;
    this.naturalDeUf = obj.naturalDeUf;
    this.enderecos = obj.enderecos || [];
    this.valorPatrimonio = obj.valorPatrimonio;
    this.idEstadoCivil = obj.idEstadoCivil;
    this.documento = obj.documento || {};
    this.sexo = obj.sexo;
    this.ppe = obj.ppe;
    this.fatca = obj.fatca;
    this.tipo = obj.tipo;
    this.categoria = obj.categoria;
    this.profissional = obj.profissional || [];
    this.referencia = obj.referencia || [];
  }

  getId(): number {
    return this.id;
  }

  getCpf(): string {
    return this.cpf;
  }

  getNome(): string {
    return this.nome;
  }

  getTelefones(): TelefonePessoa[] {
    return this.telefones;
  }

  getDataNascimento(): number {
    return this.dataNascimento;
  }

  getRenda(): number {
    return this.renda;
  }

  getEmail(): string {
    return this.email;
  }

  getNomeMae(): string {
    return this.nomeMae;
  }

  getValorPatrimonio(): number {
    return this.valorPatrimonio;
  }

  getIdEstadoCivil(): string {
    return this.idEstadoCivil;
  }

  getSexo(): string {
    return this.sexo;
  }

  getPpe(): boolean {
    return this.ppe;
  }

  getFatca(): boolean {
    return this.fatca;
  }

  getNomePai(): string {
    return this.nomePai;
  }

  getIdNacionalidade(): number {
    return this.idNacionalidade;
  }

  getNaturalDe(): string {
    return this.naturalDe;
  }

  getNaturalDeUf(): string {
    return this.naturalDeUf;
  }

  getTipo(): string {
    return this.tipo;
  }

  getCategoria(): string {
    return this.categoria;
  }

  getDocumento(): Documento {
    return this.documento;
  }

  getEnderecos(): Endereco[] {
    return this.enderecos;
  }

  getProfissional(): DadoProfissional {
    return this.profissional;
  }

  getReferencia(): any {
    return this.referencia;
  }
}
