import { Component, Input } from '@angular/core';

import { setFichaContent } from '../helpers/salvarFicha';
import { FichaCadastro } from './../ficha-cadastro';
import { Endereco } from '../model/endereco-cliente';

@Component({
  selector: 'app-ficha-cadastro-endereco-cliente',
  templateUrl: './ficha-cadastro-endereco-cliente.component.html',
  styleUrls: ['./ficha-cadastro-endereco-cliente.component.css']
})
export class FichaCadastroEnderecoClienteComponent {
  @Input() fichaCadastro: FichaCadastro;

  allreadySetEnderecoEmpresa = false;
  enderecoEmpresa: any =  {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    uf: '',
    cidade: '',
    idTipoEndereco: 2
  };

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
  }

  bloquearProximo(): boolean {
    const endereco: boolean = this.enderecoResidencialEstaValido();
    const enderecoCorrespondenciaValido: boolean = this.enderecoCorrespondenciaEstaValido();
    const tipoEnderecoCorrespondeValido: boolean = (this.fichaCadastro._client.tipoEnderecoCorrespondencia) || false;
    const enderecoEmpresarialValido: boolean = this.isInformarEnderecoComercial() ? this.enderecoEmpresarialEstaValido() : true;

    if (tipoEnderecoCorrespondeValido && this.fichaCadastro._client.tipoEnderecoCorrespondencia === '7') {
      return !(enderecoCorrespondenciaValido && endereco && enderecoEmpresarialValido);
    }

    return !(endereco && tipoEnderecoCorrespondeValido && enderecoEmpresarialValido);
  }

  onClickNext(): void {
    if (this.isInformarEnderecoComercial()) {
      this.fichaCadastro._client.dadoProfissional.empresa.endereco = this.enderecoEmpresa;
    }
    this.fichaCadastro._client.enderecos.idTipoEndereco = 1;
    if (this.fichaCadastro._client.enderecoCorrespondencia === undefined || this.fichaCadastro._client.enderecoCorrespondencia == null) {
      this.fichaCadastro._client.enderecoCorrespondencia = new Endereco();
    }
    this.fichaCadastro._client.enderecoCorrespondencia.idTipoEndereco = this.fichaCadastro._client.tipoEnderecoCorrespondencia === '7' ? 7 : undefined;

    this.fichaCadastro.onClickNext();
    setFichaContent(this.fichaCadastro);
  }

  isInformarEnderecoComercial(): boolean {
    if (this.fichaCadastro.passo === this.fichaCadastro.PASSO_ENDERECO_CLIENTE && !this.allreadySetEnderecoEmpresa) {
      const dadosEmpresarial = this.fichaCadastro._client.dadoProfissional;
      if (dadosEmpresarial && dadosEmpresarial.empresa && dadosEmpresarial.empresa.endereco) {
        this.enderecoEmpresa =  {
          cep: dadosEmpresarial.empresa.endereco.cep,
          endereco: dadosEmpresarial.empresa.endereco.endereco,
          numero: dadosEmpresarial.empresa.endereco.numero,
          complemento: dadosEmpresarial.empresa.endereco.complemento,
          bairro: dadosEmpresarial.empresa.endereco.bairro,
          uf: dadosEmpresarial.empresa.endereco.uf,
          cidade: dadosEmpresarial.empresa.endereco.cidade
        };
      }
      this.allreadySetEnderecoEmpresa = true;
    }

    return this.fichaCadastro._client
      && this.fichaCadastro._client.dadoProfissional
      && this.fichaCadastro._client.dadoProfissional.classeProfissional
      && this.fichaCadastro._client.dadoProfissional.classeProfissional.empresa;
  }

  enderecoCorrespondenciaEstaValido(): boolean {
    if (
      this.fichaCadastro._client.enderecoCorrespondencia &&
      (this.fichaCadastro._client.enderecoCorrespondencia.cep !== '' && this.fichaCadastro._client.enderecoCorrespondencia.cep !== undefined) &&
      (this.fichaCadastro._client.enderecoCorrespondencia.endereco !== '' && this.fichaCadastro._client.enderecoCorrespondencia.endereco !== undefined) &&
      (this.fichaCadastro._client.enderecoCorrespondencia.numero !== '' && this.fichaCadastro._client.enderecoCorrespondencia.numero !== undefined) &&
      (this.fichaCadastro._client.enderecoCorrespondencia.bairro !== '' && this.fichaCadastro._client.enderecoCorrespondencia.bairro !== undefined) &&
      (this.fichaCadastro._client.enderecoCorrespondencia.cidade !== '' && this.fichaCadastro._client.enderecoCorrespondencia.cidade !== undefined) &&
      (this.fichaCadastro._client.enderecoCorrespondencia.uf !== '' && this.fichaCadastro._client.enderecoCorrespondencia.uf !== undefined)
    ) {
      return true;
    }

    return false;
  }

  enderecoResidencialEstaValido(): boolean {
    if (
      this.fichaCadastro._client.enderecos &&
      (this.fichaCadastro._client.enderecos.cep !== '' && this.fichaCadastro._client.enderecos.cep !== undefined) &&
      (this.fichaCadastro._client.enderecos.endereco !== '' && this.fichaCadastro._client.enderecos.endereco !== undefined) &&
      (this.fichaCadastro._client.enderecos.numero !== '' && this.fichaCadastro._client.enderecos.numero !== undefined) &&
      (this.fichaCadastro._client.enderecos.bairro !== '' && this.fichaCadastro._client.enderecos.bairro !== undefined) &&
      (this.fichaCadastro._client.enderecos.cidade !== '' && this.fichaCadastro._client.enderecos.cidade !== undefined) &&
      (this.fichaCadastro._client.enderecos.uf !== '' && this.fichaCadastro._client.enderecos.uf !== undefined)
    ) {
      return true;
    }

    return false;
  }

  enderecoEmpresarialEstaValido(): boolean {
    if (
      this.enderecoEmpresa &&
      (this.enderecoEmpresa.cep !== '' && this.enderecoEmpresa.cep !== undefined) &&
      (this.enderecoEmpresa.endereco !== '' && this.enderecoEmpresa.endereco !== undefined) &&
      (this.enderecoEmpresa.numero !== '' && this.enderecoEmpresa.numero !== undefined) &&
      (this.enderecoEmpresa.bairro !== '' && this.enderecoEmpresa.bairro !== undefined) &&
      (this.enderecoEmpresa.cidade !== '' && this.enderecoEmpresa.cidade !== undefined) &&
      (this.enderecoEmpresa.uf !== '' && this.enderecoEmpresa.uf !== undefined)
    ) {
      return true;
    }

    return false;
  }
}
