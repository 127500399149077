import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

import { PropostaSimulacao } from '../../../../../shared/models/proposta-simulacao';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})
export class ProdutosComponent implements OnInit {
  alive = true;
  seguros = [];
  assistencias = [];
  onSave = new EventEmitter();
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.form = this.fb.group({
      seguroSelecionado: [''],
      assistenciaSelecionado: [''],
    });
  }

  init(propostaSimulacao: PropostaSimulacao) {
    this.seguros = propostaSimulacao.simulacao.paymentProtectionInsurances;
    this.assistencias = propostaSimulacao.simulacao.assistances;

    const seguroSelecionado = propostaSimulacao.proposta.seguro;
    const assistenciaSelecionado = propostaSimulacao.proposta.assistencia;

    this.form.patchValue({ seguroSelecionado, assistenciaSelecionado });
  }

  onClickSalvar(): void {
    const produtosSelecionados = this.form.value;
    this.onSave.emit({ seguro: produtosSelecionados.seguroSelecionado, assistencia: produtosSelecionados.assistenciaSelecionado });

    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }
}
