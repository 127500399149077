import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { DirectivesModule } from './../../directives/directives.module';
import { LoadersModule } from './../loaders/loaders.module';
import { UfCidadeSelectComponent } from './uf-cidade-select.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    FormsModule,
    LoadersModule
  ],
  declarations: [UfCidadeSelectComponent],
  exports: [UfCidadeSelectComponent]
})
export class UfCidadeSelectModule { }
