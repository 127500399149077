import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LoginStorage } from './login/login.storage';
import { MessagingService } from './services/messaging/messaging.service';
import { ProdutoStorage } from './shared/select-produto/produto.storage';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private currentRoute: string = undefined;

  constructor(
    private router: Router,
    private loginStorage: LoginStorage,
    private msgService: MessagingService,
    private produtoStorage: ProdutoStorage
  ) {
    router.events
      .distinctUntilChanged((previous: any, current: any) => {
        // Subscribe to any `NavigationEnd` events where the url has changed
        if (current instanceof NavigationEnd) {
          return previous.url === current.url;
        }
        return true;
      })
      .subscribe((x: any) => {
        gtag('config', 'UA-144762582-3', { page_path: x.url });
      });
  }

  message;

  ngOnInit() {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects;
      });
    this.msgService.refreshToken();
    this.msgService.init();
    this.msgService.requestPermission();
  }

  ngOnDestroy() {
    this.loginStorage.clear();
    this.produtoStorage.clear();
  }

  isShowMenu() {
    return (
      this.currentRoute &&
      !this.currentRoute.includes('/login') &&
      !this.currentRoute.includes('/authentication') &&
      !this.currentRoute.includes('/verificacao-seguranca') &&
      !this.currentRoute.includes('/external') &&
      !this.currentRoute.includes('/efetive-mais') &&
      !this.currentRoute.includes('/giro-mais') &&
      !this.currentRoute.includes('/parametro-cobranca') &&
      !this.currentRoute.includes('/pesquisa-agente-cep') &&
      !this.currentRoute.includes('/ficha-cadastral') &&
      !this.currentRoute.includes('/aceite-cliente')
    );
  }
}
