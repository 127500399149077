import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberOrCharacter]'
})
export class OnlyNumberOrCharacterDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (!(/[a-zA-Z0-9]/.test(event.key))) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    event.preventDefault();
  }
}
