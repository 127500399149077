import { Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class FichaListaSharedService {

  private reloadFichaListaSubject: Subject<any> = new Subject();
  reloadFichaListaObservable: Observable<any> = this.reloadFichaListaSubject.asObservable();

  reloadFichaLista() {
    this.reloadFichaListaSubject.next();
  }
}
