import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-confirmar-processo-autenticacao',
  templateUrl: './modal-confirmar-processo-autenticacao.component.html',
  styleUrls: ['./modal-confirmar-processo-autenticacao.component.scss']
})
export class ModalConfirmarProcessoAutenticacaoComponent implements OnDestroy {
  @Output() confirmValue = new EventEmitter<boolean>();
  alive: boolean = true;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnDestroy() {
    this.alive = false;
  }

  onClick(value: boolean) {
    this.confirmValue.emit(value);
    this.bsModalRef.hide();
  }
}
