import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDdXPtN-AZ5xG8-Eyx77Ul1f12_AmRT6XI',
  authDomain: 'https://fcm.googleapis.com/fcm/send',
  databaseURL: 'https://omnifacil-auto.firebaseio.com',
  projectId: 'br.com.omni.mais',
  storageBucket: 'omnifacil-auto.appspot.com',
  messagingSenderId: '129479194853',
};
firebase.initializeApp(config);

@Injectable()
export class MessagingService {

  private word = '';
  private token = '';
  messaging = firebase.messaging();
  public messageReceived: Subject<any>;

  constructor() {
    this.getToken();
    this.messageReceived = new Subject();
  }

  getToken() {
    document.addEventListener('keyup', (item) => {
      this.word = this.word + item.key;
    });
  }

  public init() {
    this.messaging.onTokenRefresh(() => {
      this.messaging.getToken()
        .then((refreshedToken) => {
          this.setTokenSentToServer('0');
          this.sendTokenToServer(refreshedToken);
          this.refreshToken();
        })
        .catch((err) => {
          throw new Error('Não foi possível recuperar o token atualizado');
        });
    });
    this.receiveMessage();
  }

  getTokenPush() {
    return this.token;
  }

  public isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
  }

  public setTokenSentToServer(sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
  }

  public sendTokenToServer(currentToken) {
    this.token = currentToken;
    if (!this.isTokenSentToServer()) {
      this.setTokenSentToServer(true);
    } else {
      console.warn('Token já foi enviado para o servidor, então não o envie novamente, a menos que ele mude');
    }
  }

  public receiveMessage() {
    this.messaging.onMessage((payload) => {
      this.messageReceived.next(payload);
      this.handleNotification(payload);
    });
  }

  public handleNotification(content) {
    try {
      this.notificationTypes(content.data.idProposta).forEach((item) => {
        if (item.type === content.data.status) {
          this.showNotification(this.createNotification(content, item));
        }
      });
    } catch (err) {
      throw new Error('Ocorreu um erro ao enviar ao criar o objeto de notificação');
    }
  }

  public showNotification(data) {
    if ('Notification' in window) {
      const notify = new Notification(data.title, data.options);
      notify.onclick = function () {
        window.location.assign(data.options.onclick);
      };
    }
  }

  public createNotification(content, location) {
    return {
      title: content.data.titulo,
      options: {
        icon: '../../../favicon.ico',
        body: content.data.descricao,
        onclick: `/${location.origin}`,
      },
    };
  }

  public notificationTypes(id) {
    return [
      {
        type: 'EM ANÁLISE',
        origin: `timeline?idFicha=${id}`,
      },
      {
        type: 'EM ANÁLISE DIRETA',
        origin: `timeline?idFicha=${id}`,
      },
      {
        type: 'PENDING',
        origin: `resultadoparcial/${id}`,
      },
    ];
  }

  requestPermission() {
    this.messaging.requestPermission()
      .then(() => {
        this.refreshToken();
      })
      .catch();
  }

  deleteToken() {
    this.messaging.getToken()
      .then((currentToken) => {
        this.messaging.deleteToken(currentToken)
          .then(() => {
            this.setTokenSentToServer('0');
            this.refreshToken();
          })
          .catch((err) => {
            throw new Error('Não foi possível deletar o token');
          });
      })
      .catch((err) => {
        throw new Error('Erro ao recuperar o token de identificação da instância');
      });
  }
  refreshToken() {
    this.messaging.getToken()
      .then((currentToken) => {
        if (currentToken) {
          this.sendTokenToServer(currentToken);
        } else {
          this.setTokenSentToServer('0');
          console.warn('Nenhum token de identificação de instância disponível. Solicite permissão para gerar um.');
        }
      })
      .catch((err) => {
        this.setTokenSentToServer('0');
      });
  }
}
