import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { VerificacaoSegurancaService } from './verificacao-seguranca.service';

@Component({
  selector: 'app-verificacao-seguranca',
  templateUrl: './verificacao-seguranca.component.html',
})
export class VerificacaoSegurancaComponent implements OnInit, OnDestroy {
  redirectToParceiro: boolean;
  isAlive = true;
  showLoader = true;

  constructor(private route: ActivatedRoute, private router: Router, private verificacaoSegurancaService: VerificacaoSegurancaService) { }

  ngOnInit() {
    this.initObservableParams();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private initObservableParams(): void {
    this.route.queryParams
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((params: any) => {
        if (!params.token) {
          this.router.navigate(['/login']);
          this.showLoader = false;
          return;
        }

        const decripted = this.verificacaoSegurancaService.decrypt(
          params.token.replace(/ /g, '+'),
          environment.ECOMMERCE_ENCRYPT_KEY
        );
        const { parceiro } = this.verificacaoSegurancaService.decodePayloadJWT(decripted);
        this.redirectToParceiro = parceiro;
        this.showLoader = false;
      });
  }
}
