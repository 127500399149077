import { debounceTime, takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormGroup,
} from '@angular/forms';
import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { appSettings } from '../../../../../environments/app.setings';
import { AddModalComponent } from './add-modal/add-modal.component';
import { AddModalConfig } from './add-modal-config';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
  ],
})
export class AutoCompleteComponent
  implements ControlValueAccessor, OnChanges, OnDestroy
{
  @Input() id?: string = '';
  @Input() key: (item: any) => any = (_) => null;
  @Input() label: (item: any) => string = (_) => '';
  @Input() items: any[];

  @Input() required = false;
  @Input() requiredMessage?: string;
  @Input() readOnly?: boolean = false;

  @Input() addModalForm?: FormGroup;
  @Input() addModalConfig?: AddModalConfig;

  @Output() add = new EventEmitter<string>();

  value: any;
  onChange: (_: any) => void = (_: any) => {
    // This is intentional
  }
  onTouched: () => void = () => {
    // This is intentional
  }

  @ViewChild('input', { static: true }) inputEl: ElementRef;

  itemDescricao: string;
  isShowItems = false;
  debouncer: Subject<boolean> = new Subject<boolean>();
  isActiveOutput = true;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private modal: BsModalService
  ) {
    this.debouncer
      .pipe(debounceTime(10000))
      .subscribe((value) => (this.isActiveOutput = value));
  }

  ngOnDestroy() {
    this.debouncer.unsubscribe();
  }

  ngAfterViewInit() {
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateChanges();
    this.onTouched();

    this.ngOnChanges();
    this.hideItems();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this._renderer.setProperty(
      this.inputEl.nativeElement,
      'disabled',
      isDisabled
    );
  }

  selectItem(item) {
    this.writeValue(this.key(item));
  }

  ngOnChanges() {
    if (this.itemSelecionado) {
      this.itemDescricao = this.itemLabel;
    }
  }

  showHideItems() {
    this.isShowItems = !this.isShowItems;
  }

  hideItems() {
    this.isShowItems = false;
  }

  showItems() {
    this.isShowItems = true;
  }

  onChangeItemDescricao() {
    if (this.itemSelecionado) {
      this.writeValue(null);
    }

    if (this.itemDescricao) {
      this.showItems();
    }
  }

  onBlur() {
    if (!this.itemSelecionado) {
      this.itemDescricao = '';
    }
  }

  onClickAdd(item) {
    if (this.isActiveOutput) {
      this.isActiveOutput = false;
      this.add.emit(item);
      this.debouncer.next(true);
    }
  }

  get itemsSelecionar() {
    if (this.itemDescricao && this.items) {
      return this.items
        .filter((item) =>
          this.label(item)
            .toLowerCase()
            .includes(this.itemDescricao.toLowerCase())
        )
        .sort((a, b) => {
          if (
            this.label(a).trim().toLowerCase() ===
            this.itemDescricao.trim().toLowerCase()
          ) {
            return -1;
          }

          if (
            this.label(b).trim().toLowerCase() ===
            this.itemDescricao.trim().toLowerCase()
          ) {
            return 1;
          }

          if (
            this.label(a)
              .toLowerCase()
              .startsWith(this.itemDescricao.toLowerCase())
          ) {
            return -1;
          }

          if (
            this.label(b)
              .toLowerCase()
              .startsWith(this.itemDescricao.toLowerCase())
          ) {
            return 1;
          }

          return 0;
        });
    }

    return this.items;
  }

  getItemPorLabel() {
    if (!this.items || !this.itemDescricao) {
      return null;
    }

    return this.items.filter(
      (item) =>
        this.label(item).trim().toLowerCase() ===
        this.itemDescricao.trim().toLowerCase()
    )[0];
  }

  get itemSelecionado() {
    if (!this.items) {
      return null;
    }

    return this.items.filter((item) => this.key(item) === this.value)[0];
  }

  get itemLabel() {
    if (this.itemSelecionado) {
      return this.label(this.itemSelecionado);
    }

    return '';
  }

  openAddModal() {
    this.addModalForm.reset();

    const initialState = {
      form: this.addModalForm,
      config: this.addModalConfig,
    };

    const modal = this.modal.show(AddModalComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
    const addModal = <AddModalComponent>modal.content;

    addModal.confirmValue
      .pipe(takeWhile(() => addModal.alive))
      .subscribe((decisao) => {
        if (decisao) {
          this.onClickAdd(addModal.form.controls[this.addModalConfig.formControlName].value);
        }
      });
  }
}
