import { Component, forwardRef, Injector, Input, OnInit, Type } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validator,
} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDateComponent)
    }
  ]
})
export class InputDateComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() format = 'DD/MM/YYYY';
  @Input() id?: string;

  dateValue: number;
  value: any;
  private ngControl: NgControl;

  onChange: (_: any) => void = (_: any) => {
    // This is intentional
  }
  onTouched: () => void = () => {
    // This is intentional
  }

  constructor(private inj: Injector) {}

  ngOnInit() {
    this.ngControl = this.inj.get<NgControl>(NgControl as Type<NgControl>);
  }

  updateChanges() {
    this.onChange(this.dateValue);
  }

  writeValue(value: any): void {
    this.convert(value);
    this.updateChanges();
    this.onTouched();
  }

  convert(value: any): void {
    if (!value) {
      this.value = null;
      this.dateValue = null;
    } else if (`${value}`.length < this.unslashedFormat.length) {
      this.value = value;
    } else if (`${value}`.length === this.unslashedFormat.length) {
      this.value = value;
      this.dateValue = moment(this.value, this.unslashedFormat).unix() * 1000;
    } else {
      this.dateValue = value;
      this.value = moment.unix(this.dateValue / 1000).format(this.unslashedFormat);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (Number.isNaN(this.dateValue)) {
      return { dateCheck: true };
    }
  }

  get maskModel() {
    return this.format.replace(/\w/g, '9');
  }

  get placeholder() {
    return this.format.replace(/\w/g, '0');
  }

  get unslashedFormat() {
    return this.format.replace(/\//g, '');
  }

  get name() {
    return this.ngControl.name || this.id;
  }
}
