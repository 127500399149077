import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import * as toastr from 'toastr';

import { MotivoRecusa } from '../../../models/motivo-recusa';
import { CdcLojaService } from '../../../services/cdc-loja.service';

@Component({
  selector: 'app-ficha-excluida',
  templateUrl: './ficha-excluida.component.html',
  styleUrls: ['./ficha-excluida.component.scss']
})
export class FichaExcluidaComponent implements OnInit, OnDestroy {

  propostaId: number;
  form: FormGroup;
  outroMotivo: MotivoRecusa;
  listaMotivos: MotivoRecusa[] = [];
  showLoader = true;
  opcaoOutroSubscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private cdcLojaService: CdcLojaService) { }

  ngOnInit() {
    this.initObservableParams();
    this.form = this.formBuilder.group({
      outroMotivo: false,
      justificativa: null,
      motivos: new FormArray([]),
    });

    this.getMotivosRecusa();
    this.initObservable();
  }

  ngOnDestroy(): void {
    if (this.opcaoOutroSubscriptions) {
      this.opcaoOutroSubscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  private initObservableParams() {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.propostaId = params.id;
      });
  }

  private getMotivosRecusa() {
    this.cdcLojaService.getMotivosRecusaProposta().pipe(finalize(() => { this.showLoader = false; })).subscribe(({ motivos }) => {
      const idx = motivos.findIndex((item) => item.descricao === 'Outro');
      if (idx !== -1) {
        this.outroMotivo = motivos.splice(idx)[0];
        this.listaMotivos = motivos;
      }
      this.addCheckboxes();
    });
  }

  private addCheckboxes() {
    this.listaMotivos.forEach(() => this.listFormArray.push(new FormControl(false)));
  }

  get listFormArray() {
    return this.form.controls.motivos as FormArray;
  }

  private initObservable() {
    this.opcaoOutroSubscriptions.push(this.form.get('outroMotivo').valueChanges.subscribe(((value) => {
      if (value) {
        this.form.get('justificativa').setValidators(Validators.required);
      } else {
        this.form.get('justificativa').clearValidators();
        this.form.get('justificativa').setValue('');
      }
      this.form.updateValueAndValidity();
    })));
  }

  checkboxValidator(min = 1): boolean {
    const totalSelected = this.listFormArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);
    return totalSelected >= min;
  }

  private excluirFicha() {
    this.cdcLojaService.excluirFicha(this.propostaId).pipe(finalize(() => this.showLoader = false)).subscribe(
      (_) => {
        toastr.success('Ficha excluida com sucesso');
        this.router.navigate(['/ficha']);
      },
      (error) => toastr.error(error, 'Falha ao excluir')
    );
  }

  submit() {
    this.showLoader = true;
    const rawValue = this.form.getRawValue();
    let motivos: MotivoRecusa[] = this.form.value.motivos
      .map((value: MotivoRecusa, idx: number) => value ? { id: this.listaMotivos[idx].id } : null)
      .filter((item) => !!item);

    if (rawValue.outroMotivo) {
      motivos = [...motivos, { id: this.outroMotivo.id, descricao: rawValue.justificativa }];
    }

    this.cdcLojaService.atualizarMotivosRecusaProposta(this.propostaId, motivos).subscribe(
      () => {
        this.excluirFicha();
      },
      () => this.showLoader = false);
  }
}
