import { Component,  OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-aviso-ios',
  templateUrl: './modal-aviso-ios.component.html',
  styleUrls: ['./modal-aviso-ios.component.scss']
})
export class ModalAvisoIosComponent implements OnDestroy {

  alive = true;

  constructor(
    private instance: BsModalRef
  ) { }

  ngOnDestroy() {
    this.alive = false;
  }

  close() {
    this.instance.hide();
  }

  onClickConfirm() {
    this.close();
  }
}
