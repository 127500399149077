import { Referencia } from './referencia';
import { DadoProfissional } from './dado-profissional';
import { Endereco } from './endereco';
import { TelefonePessoa } from './telefone-pessoa';

export class Pessoa {

  private id: number;
  private cpf: string;
  private dataNascimento: number;
  private nome: string;
  private renda: number;
  private email: string;
  private nomeMae: string;
  private nomePai: string;
  private idTipoDocumento: number;
  private rgIe: string;
  private rgIeEmissor: string;
  private rgIeUf: string;
  private rgIeDt: number;
  private idEstadoCivil: string;
  private idNacionalidade: number;
  private naturalDe: string;
  private naturalDeUf: string;
  private clientePpe: string;
  private fatca: string;
  private valorPatrimonio: number;
  private dadoProfissional: DadoProfissional;
  private clienteReferencia: Referencia;
  private enderecos: Endereco[];
  private tipoEnderecoCorresp: number;
  private telefones: TelefonePessoa[];
  private sexo: string;
  private documento: any;

  setId(id: number) {
    this.id = id;
  }

  setCpf(cpf: string) {
    this.cpf = cpf;
  }

  setDataNascimento(dataNascimento: number) {
    this.dataNascimento = dataNascimento;
  }

  setNome(nome: string) {
    this.nome = nome;
  }

  setRenda(renda: number) {
    this.renda = renda;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setNomeMae(nomeMae: string) {
    this.nomeMae = nomeMae;
  }

  setNomePai(nomePai: string) {
    this.nomePai = nomePai;
  }

  setIdTipoDocumento(idTipoDocumento: number) {
    this.idTipoDocumento = idTipoDocumento;
  }

  setRgIe(rgIe: string) {
    this.rgIe = rgIe;
  }

  setRgIeEmissor(rgIeEmissor: string) {
    this.rgIeEmissor = rgIeEmissor;
  }

  setRgIeUf(rgIeUf: string) {
    this.rgIeUf = rgIeUf;
  }

  setRgIeDt(rgIeDt: number) {
    this.rgIeDt = rgIeDt;
  }

  setIdEstadoCivil(idEstadoCivil: string) {
    this.idEstadoCivil = idEstadoCivil;
  }

  setIdNacionalidade(idNacionalidade: number) {
    this.idNacionalidade = idNacionalidade;
  }

  setNaturalDe(naturalDe: string) {
    this.naturalDe = naturalDe;
  }

  setNaturalDeUf(naturalDeUf: string) {
    this.naturalDeUf = naturalDeUf;
  }

  setClientePpe(clientePpe: string) {
    this.clientePpe = clientePpe;
  }

  setFatca(fatca: string) {
    this.fatca = fatca;
  }

  setValorPatrimonio(valorPatrimonio: number) {
    this.valorPatrimonio = valorPatrimonio;
  }

  setDadoProfissional(dadoProfissional: DadoProfissional) {
    this.dadoProfissional = dadoProfissional;
  }

  setClienteReferencia(clienteReferencia: Referencia) {
    this.clienteReferencia = clienteReferencia;
  }

  setEnderecos(enderecos: Endereco[]) {
    this.enderecos = enderecos;
  }

  addEndereco(endereco: Endereco) {
    if (!this.enderecos) {
      this.enderecos = [];
    }
    this.enderecos.push(endereco);
  }

  setTipoEnderecoCorresp(tipoEnderecoCorresp: number) {
    this.tipoEnderecoCorresp = tipoEnderecoCorresp;
  }

  setTelefones(telefones: TelefonePessoa[]) {
    this.telefones = telefones;
  }

  addTelefone(telefone: TelefonePessoa) {
    if (!this.telefones) {
      this.telefones = [];
    }
    this.telefones.push(telefone);
  }

  setSexo(sexo: string) {
    this.sexo = sexo;
  }

  setDocumento(documento: any) {
    this.documento = documento;
  }
}
