import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MicrocreditoService } from '../../../services/microcredito.service';
import {
  Agente,
  Parcela,
  RelatorioCobranca,
} from '../../model/relatorio-cobranca-mc';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoginStorage } from './../../../../../login/login.storage';
import { LoginUsuarioTO } from './../../../../../login/model/login-usuario-to';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'relatorio-cobranca-mc',
  templateUrl: './relatorio-cobranca-mc.component.html',
  styleUrls: ['./relatorio-cobranca-mc.component.css'],
})
export class RelatorioCobrancaMcComponent implements OnInit, OnDestroy {
  form: FormGroup;
  relatorioCobranca: RelatorioCobranca;
  agentesUsuario: Agente[] = [];
  p = 1;
  currentDiv: string;
  buscando = false;
  buscaRealizada = false;
  exibiuDadosBuscaAnterior = false;

  labelButton: string = 'Buscar';

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public qtdAvalista = 0;

  constructor(
    private service: MicrocreditoService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private loginStorage: LoginStorage
  ) {}

  private initParams() {
    const params = (<any>this.activeRoute.paramMap).source._value;

    if (params) {
      const usuario: LoginUsuarioTO = new LoginUsuarioTO(params);
      this.loginStorage.register(usuario);
    }
  }

  ngOnInit() {
    this.setOptions();
    this.initParams();
    this.buildAgentes();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private initForm(): void {
    this.form = this.fb.group({
      agentes: [null, Validators.required],
      dataInicial: [null, Validators.required],
      dataFinal: [null, Validators.required],
    });
  }

  buildAgentes() {
    this.service.getAgentesUsuarioLogado().subscribe((res) => {
      res.agentes.forEach((element) => {
        const agente: Agente = {};
        agente.codigo = element.id;
        agente.nome = element.nomeFantasia;
        this.agentesUsuario.push(agente);
      });
    });
  }

  onSubmit() {
    this.buscando = true;
    this.labelButton = 'Buscando...';
    this.dadosSelecionadosChange();
  }

  dadosSelecionadosChange() {
    this.setOptions();
    let agente: number = null;
    if (this.form.get('agentes').value !== 'TODOS') {
      agente = this.form.get('agentes').value;
    }
    this.service
      .getRelatorioCobranca(
        this.form.get('dataInicial').value,
        this.form.get('dataFinal').value,
        agente
      )
      .subscribe(
        (res) => {
          this.relatorioCobranca = res.relatorioCobranca;
          this.initDatatable();
          this.getQtdAvalista();
          this.buscando = false;
          this.labelButton = 'Buscar';
          this.buscaRealizada = true;
        },
        (err) => {
          this.buscando = false;
          this.labelButton = 'Buscar';
        }
      );
  }

  open(divVal) {
    this.currentDiv = divVal;
  }

  close() {
    this.currentDiv = null;
  }

  getParcelas(status: number, parcelas: Parcela[]) {
    let valor = '';
    parcelas.forEach((item) => {
      if (item.status === status) {
        if (valor !== '') {
          valor = `${valor} , `;
        }
        valor = valor + item.id.numero;
      }
    });
    return valor;
  }

  getCountParcelas(status: number, parcelas: Parcela[]) {
    let count = 0;
    parcelas.forEach((item) => {
      if (item.status === status) {
        count++;
      }
    });
    return count;
  }

  private initDatatable() {
    if (this.buscaRealizada && this.exibiuDadosBuscaAnterior) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.dtTrigger.next();
    } else {
      setTimeout(() => {
        if (this.dtTrigger.closed) {
          this.dtTrigger = new Subject<any>();
        }
        this.dtTrigger.next();
      }, 5);
    }
    this.exibiuDadosBuscaAnterior = this.relatorioCobranca.itens ? true : false;
  }

  private setOptions() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      language: {
        emptyTable: 'Nenhum registro encontrado',
        info: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
        infoEmpty: 'Mostrando 0 até 0 de 0 registros',
        infoFiltered: '(Filtrados de _MAX_ registros)',
        infoPostFix: '',
        lengthMenu: '_MENU_ resultados por página',
        loadingRecords: 'Carregando...',
        processing: 'Processando...',
        zeroRecords: 'Nenhum registro encontrado',
        search: 'Pesquisar',
        paginate: {
          next: 'Próximo',
          previous: 'Anterior',
          first: 'Primeiro',
          last: 'Último',
        },
        aria: {
          sortAscending: ': Ordenar colunas de forma ascendente',
          sortDescending: ': Ordenar colunas de forma descendente',
        },
      },
      dom: 'Bfrtip',
      buttons: ['excel'],
    };
  }

  getValidSearch() {
    return 'Buscar';
  }

  getQtdAvalista() {
    if (this.relatorioCobranca.itens && this.relatorioCobranca.itens.length) {
      this.qtdAvalista = this.relatorioCobranca.itens
        .sort((a, b) => a.avalistas.length - b.avalistas.length)
        .reverse()[0].avalistas.length;
    }
  }
}
