export const appSettings = {
  MODAL_PARAMS: { ignoreBackdropClick: true, keyboard: false },
  ACCEPT_IMAGES: '.gif,.jpg,.jpeg,.png,.bmp',
  ACCEPT_IMAGES_MYMES: ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/bmp'],
  ACCEPT_IMAGES_AND_PDF: '.gif,.jpg,.jpeg,.png,.bmp,.pdf',
};

export const appMicrocreditoSettings = {
  ACCEPT_IMAGES: '.gif,.jpg,.jpeg,.bmp',
  ACCEPT_IMAGES_MYMES: ['image/gif', 'image/jpg', 'image/jpeg', 'image/bmp'],
  ACCEPT_IMAGES_AND_PDF: '.gif,.jpg,.jpeg,.bmp,.pdf',
};
