import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';

@Component({
  selector: 'login-facebook',
  templateUrl: './login-facebook.component.html',
  styleUrls: ['./login-facebook.component.css']
})
export class LoginFacebookComponent {
  @Output() fbSocialUserChange: EventEmitter<SocialUser> = new EventEmitter();

  constructor(private fbAuthService: AuthService) { }

  signInWithFB(): void {
    this.fbAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);

    this.fbAuthService.authState
      .subscribe(user => {
        if (user && user.provider === 'FACEBOOK') {
          this.fbSocialUserChange.emit(user);
        }
      });
  }
}
