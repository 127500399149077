export class InformacoesCliente {
  cliente: {
    cpf: string;
    classeProfissional: string;
  };

  conjuge?: {
    cpf: string;
    dataNascimento: string;
  };

  renda: any;

  setValorSolicitado(valorSolicitado: any) {
    this.renda.valorSolicitado = valorSolicitado;
  }
}
