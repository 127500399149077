import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { finalize, take, takeWhile } from 'rxjs/operators';
import * as toastr from 'toastr';

import { LoginStorage } from '../../../login/login.storage';
import { UploadArquivosModalComponent } from '../../../shared/upload-arquivos-modal/upload-arquivos-modal.component';
import { TimelinePropostaVO, TimelineStatusVO } from '../../shared/models/timelineVO';
import { CdcLojaService } from '../services/cdc-loja.service';
import { appSettings } from './../../../../environments/app.setings';
import { ConfirmModalComponent } from './../../../shared/modais/confirm-modal/confirm-modal.component';
import { DadosComplementaresComponent } from './dados-complementares/dados-complementares.component';
import { MotivoDesistenciaComponent } from './motivo-desistencia/motivo-desistencia.component';

@Component({
  selector: 'app-cp-cdc-loja-timeline',
  templateUrl: './cdc-loja-timeline.component.html'
})

export class CdcLojaTimelineComponent {

  private classes = {
    1: 'parcial',
    2: 'inverted direct-analysis',
    3: 'inverted analysis',
    4: 'approved',
    5: 'required-docs',
    17: 'required-docs',
    6: 'inverted send-docs',
    30: 'direct-analysis',
    7: 'inverted analysis-docs',
    10: 'inverted scheduled',
    11: 'central paid',
    12: 'central reproved',
    13: 'central reproved',
    18: 'em-preenchimento',
    19: 'central reproved',
    24: 'central reproved',
    31: 'parcial',
    32: 'biometria',
    33: 'em-preenchimento',
    34: 'parcial',
  };

  fichaTimeline: TimelinePropostaVO;
  isBuscando = false;
  private propostaId: number;
  private callbackEvent: string;
  statusTimeline: string;
  etapaCadastro: number;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.documentacaoNecessariaTimeline) return true;
    const info = this.documentacaoNecessariaTimeline.informacaoComplementar;
    if (!info) return true;
    if (info.contadorDocsEnviados > 0) {
      return window.confirm('Suas alterações não foram salvas. Deseja realmente sair?');
    }
    return true;
  }

  constructor(
    private router: Router,
    private service: CdcLojaService,
    private route: ActivatedRoute,
    private modal: BsModalService,
    private loginStorage: LoginStorage,

  ) {
    this.initObservableParams();
  }

  private initObservableParams() {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.propostaId = params.id;
        this.callbackEvent = params.event;
        this.buscarFicha(true);
      });
  }

  async buscarFicha(primeiraBusca) {
    this.isBuscando = true;

    try {
      const ficha = await this.service.getTimeline(this.propostaId).toPromise();
      this.popularTimeline(ficha, primeiraBusca);
    } finally {
      this.isBuscando = false;
    }
  }

  private popularTimeline(ficha: TimelinePropostaVO, primeiraConsulta?: boolean) {
    this.fichaTimeline = ficha;
    this.fichaTimeline.timeline = this.fichaTimeline.timeline
      .filter(card => !!this.classes[card.idStatus]);

    // aguarda até docusign marcar documento como assinado
    const timelineAssinatura = ficha.timeline.filter(t => t.idStatus === 17)[0];
    if (
      primeiraConsulta
      && this.callbackEvent === 'signing_complete'
      && !timelineAssinatura.finalizado
      && !timelineAssinatura.informacaoComplementar.assinadoDigitalmente) {
      this.aguardarAssinatura(1);
    }

    if (!this.documentacaoNecessariaTimeline) return;
    if (this.documentacaoNecessariaTimeline.informacaoComplementar && !this.documentacaoNecessariaTimeline.finalizado) {
      this.documentacaoNecessariaTimeline.informacaoComplementar.contadorDocsEnviados = 0;
    }

  }

  private async aguardarAssinatura(tentativa: number) {
    const maximoTentativas = 10;
    try {
      this.isBuscando = true;
      const verify = await this.service.verificarContratoAssinado(this.fichaTimeline.proposta.contratoId);

      if (verify.assinado) {
        this.isBuscando = false;

        await this.buscarFicha(false);
      } else if (tentativa < maximoTentativas) {
        setTimeout(() => this.aguardarAssinatura(tentativa + 1), 5000);
      }
    } catch (error) {
      console.error(error);
      if (tentativa < maximoTentativas) {
        setTimeout(() => this.aguardarAssinatura(tentativa + 1), 5000);
      }
    }

    if (tentativa >= maximoTentativas) {
      this.isBuscando = false;
      toastr.error('Tente atualizar a página', 'Não foi possível verificar assinatura do contrato');
    }
  }

  private get documentacaoNecessariaTimeline(): TimelineStatusVO {
    return this.fichaTimeline.timeline.find(f => f.idStatus === 5);
  }

  className(timeline: TimelineStatusVO) {
    let prefix = '';
    if (timeline.rejeitado) {
      prefix = 'step-rejeitado';
    } else if (timeline.finalizado) {
      prefix = 'step-ok';
    } else if (timeline.seqTimeline) {
      prefix = 'actual';
    } else {
      prefix = 'disabled';
    }

    return `${prefix} cp-cdcl ${this.classes[timeline.idStatus]}`;
  }

  irParaFicha() {
    if (this.isParceiro()) {
      this.getEtapa(this.statusTimeline);
      this.router.navigate([`/produtos/cdcloja/ecommerce/${this.etapaCadastro}/${this.propostaId}`]);
    } else {
      this.router.navigate([`/produtos/cdcloja/fichas/${this.propostaId}`]);
    }
  }

  getEtapa(statusTimeline: string) {
    switch (statusTimeline) {
      case 'SIMULAÇÃO EM ANDAMENTO':
        this.etapaCadastro = 1;
        break;
      case 'AGUARDANDO BIOMETRIA':
        this.etapaCadastro = 3;
        break;
      case 'PRONTO PARA PREENCHIMENTO':
        this.etapaCadastro = 3;
        break;
    }
  }

  onAction(event) {
    this.statusTimeline = event.args;
    switch (event.action) {
      case 'navigate_to_ficha': return this.irParaFicha();
      case 'finalizar_ficha': return this.checkNextStep();
      case 'desistir_ficha': return this.abrirModalDesistir();
      case 'reload': return this.buscarFicha(false);
      case 'push_documentacao': return this.pushDocumentacao();
      case 'push_assinatura': return this.pushFormalizacao();
      case 'modal_documentacao': return this.abrirModalDocumentacao();
    }
  }

  private checkNextStep() {
    if (this.isParceiro()) {
      this.abrirModalDadosComplementares();
      return;
    }
    this.finalizaFicha();
  }

  private abrirModalDadosComplementares() {
    const initialState = { cliente: { ...this.fichaTimeline.proposta.cliente } };
    const modal = this.modal.show(DadosComplementaresComponent, { ...appSettings.MODAL_PARAMS, initialState });
    const dadosComplementares = <DadosComplementaresComponent>modal.content;
    dadosComplementares.event
      .pipe(takeWhile(() => dadosComplementares.alive))
      .subscribe(cliente => {
        this.fichaTimeline.proposta.cliente = { ...this.fichaTimeline.proposta.cliente, ...cliente };
        this.finalizaFicha();
        modal.hide();
      });
  }
  finalizaFicha() {
    this.isBuscando = true;
    const proposta = this.fichaTimeline.proposta;
    this.service
      .finalizaFicha(proposta)
      .pipe(take(1), finalize(() => this.isBuscando = false))
      .subscribe(
        () => this.buscarFicha(false),
        () => toastr.error('Tente novamente mais tarde.', 'Não foi possível gerar o contrato.')
      );
  }

  abrirModalDesistir() {
    const modal = this.modal.show(MotivoDesistenciaComponent, appSettings.MODAL_PARAMS);
    const motivoDesistencia = <MotivoDesistenciaComponent>modal.content;
    motivoDesistencia.event
      .pipe(takeWhile(() => motivoDesistencia.alive))
      .subscribe(motivo => this.desistirFicha(motivo));
  }

  abrirModalDocumentacao() {
    const initialState = {
      origem: '6',
      isOrigemSos: false,
      documentos: this.documentacaoNecessariaTimeline.informacaoComplementar.documentosPendentes,
      idFicha: this.fichaTimeline.proposta.id,
      cliente: this.fichaTimeline.proposta.cliente,
      acceptFiles: appSettings.ACCEPT_IMAGES_AND_PDF,
    };
    const modal = this.modal.show(UploadArquivosModalComponent, { ...appSettings.MODAL_PARAMS, initialState });
    const uploadArquivos = <UploadArquivosModalComponent>modal.content;
    uploadArquivos.pushDocumentacao.subscribe((res) => {
      this.documentacaoNecessariaTimeline.informacaoComplementar.contadorDocsEnviados = res[0];
      this.documentacaoNecessariaTimeline.informacaoComplementar.documentosPendentes = res[1];
    });
  }

  desistirFicha(motivo: number) {
    this.service
      .desistirFicha(this.fichaTimeline.proposta, motivo)
      .pipe(take(1))
      .subscribe(
        () => this.buscarFicha(false),
        () => toastr.error('Tente novamente mais tarde.', 'Não foi possível salvar a informação.')
      );
  }

  pushDocumentacao() {
    this.service
      .pushDocumentacao(this.fichaTimeline.proposta.id)
      .pipe(take(1))
      .subscribe(
        () => this.buscarFicha(false),
        () => toastr.error('Tente novamente mais tarde.', 'Não foi possível confirmar a documentação.')
      );
  }

  pushFormalizacao() {
    this.service
      .pushFormalizacao(this.fichaTimeline.proposta.id)
      .pipe(take(1))
      .subscribe(
        () => this.buscarFicha(false),
        () => toastr.error('Tente novamente mais tarde.', 'Não foi possível enviar para formalização.')
      );
  }

  get isResultadoParcialCurrent(): boolean {
    return this.fichaTimeline
      && this.fichaTimeline.timeline
      && this.fichaTimeline.timeline
        .filter(e => e.seqTimeline)
        .map(e => e.idStatus)
        .filter(e => [2, 3, 4, 5, 6, 7, 8, 9, 10].includes(e))
        .length === 0;
  }

  openModalExcluirFicha() {
    const modal = this.modal.show(ConfirmModalComponent, appSettings.MODAL_PARAMS);
    const confirm = <ConfirmModalComponent>modal.content;

    confirm.showModal({
      titulo: 'Excluir Ficha',
      mensagem: 'Deseja realmente excluir a Ficha?'
    });

    confirm.confirmValue
      .pipe(
        takeWhile(() => confirm.alive)
      )
      .subscribe(value => {
        if (value) {
          this.excluirFicha();
        }
      });

  }

  excluirFicha() {
    this.service.excluirFicha(this.propostaId)
      .toPromise()
      .then(
        () => {
          toastr.success('Ficha excluida com sucesso');
          this.router.navigate(['/ficha']);
        },
        error => toastr.error(error, 'Falha ao excluir')
      );
  }

  isParceiro() {
    if (this.loginStorage.usuario.parcerias.length > 0) {
      const parceiro = this.loginStorage.usuario.parcerias.filter(({ chave }) => chave && chave === 'DELL');
      return !!parceiro;
    }
    return false;
  }
}
