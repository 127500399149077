import { StatusModel } from './../../../models/statusModel';
import { FichaStatus } from './ficha-status-interface';
export class StatusFechamento implements FichaStatus{
  status:StatusModel[] = [];

  constructor() {
    this.status.push(new StatusModel('Documentação Necessária', '5'));
    this.status.push(new StatusModel('Documentação Enviada', '6'));
    this.status.push(new StatusModel('Em Análise', '7'));
    this.status.push(new StatusModel('Pagamento Agendado', '10'));
    this.status.push(new StatusModel('Pagamento Realizado', '11'));
    this.status.push(new StatusModel('Todos', '-1'));
  }

  getStatus() {
    return this.status;
  }
}
