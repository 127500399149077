import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-verificacao-seguranca-identificacao',
  templateUrl: './verificacao-seguranca-identificacao.component.html',
  styleUrls: ['./verificacao-seguranca-identificacao.component.scss'],
})
export class VerificacaoSegurancaIdentificacaoComponent implements OnInit {
  @Output() cpf = new EventEmitter<string>();
  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  async ngOnInit() {
    this.buildForm();
    this.onChangeCpf();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      cpf: ['', Validators.required],
    });
  }

  private onChangeCpf(): void {
    this.form.get('cpf').valueChanges.subscribe((cpf) => this.cpf.emit(cpf));
  }
}
