import { UploadArquivosMultiavalistasComponent } from './../../../../../shared/upload-arquivos-multiavalistas/upload-arquivos-multiavalistas.component';
import { Cliente } from './../../../../shared/models/cliente';
import { Proposta } from '../../../../shared/models/proposta';
import { TimelineSosVO } from '../../../../shared/models/timelineVO';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html'
})
export class DocumentosComponent implements OnInit, OnDestroy {

  @Input() sos: TimelineSosVO;
  @Input() proposta: Proposta;

  @ViewChild(UploadArquivosMultiavalistasComponent, { static: false })
  private uploadArquivosComponent: UploadArquivosMultiavalistasComponent;

  ngOnInit() {
    console.log('Criando DocumentosComponent');
  }

  ngOnDestroy() {
    console.log('Destruindo DocumentosComponent');
  }

  fileUpload() {
    return this.uploadArquivosComponent.sendDocuments();
  }

  public validaEnvio(): boolean {
    return this.uploadArquivosComponent.validaEnvio();
  }

  get documentos() {
    return this.sos.motivos.filter(acao => acao.idDocto)
      .map(doc => ({ ...doc, descDocto: doc.descricao }));
  }

  get cpfAvalistas(): Map<number, string> {
    const avalistas = new Map<number, string>();
    if (this.proposta.avalistas) {
      (<any>Object).entries(this.proposta.avalistas).forEach(([key, value]) => {
        const avalista = <Cliente>value;
        avalistas.set(Number(key), avalista.cpf);
      });
    }
    return avalistas;
  }
}
