import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-motivo-desistencia',
  templateUrl: './motivo-desistencia.component.html',
  styleUrls: ['./motivo-desistencia.component.css']
})
export class MotivoDesistenciaComponent implements OnInit, OnDestroy {

  event = new Subject<number>();
  alive = true;

  constructor(
    private modalInstance: BsModalRef,
  ) { }

  ngOnInit() {
    console.log('Criando MotivoDesistenciaComponent');
  }

  ngOnDestroy() {
    console.log('Destruindo MotivoDesistenciaComponent');
    this.alive = false;
  }

  desistir() {
    this.efetuarDesistencia(1);
  }

  outraFinanceira() {
    this.efetuarDesistencia(2);
  }

  fechar() {
    this.modalInstance.hide();
  }

  private efetuarDesistencia(motivo: number) {
    this.event.next(motivo);
    this.fechar();
  }

}
