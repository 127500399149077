import { Component, EventEmitter, Input, Output } from '@angular/core';

import { calcularQuantidadeSOS, fichasFilter } from '../../helpers/filter';
import { FichaListaFiltro } from './../model/ficha-lista-filtro';
@Component({
  selector: 'app-ficha-em-analise',
  templateUrl: './ficha-em-analise.component.html',
  styleUrls: ['./ficha-em-analise.component.css']
})
export class FichaEmAnaliseComponent {

  @Input() propostas = [];
  @Input() filtro: FichaListaFiltro;
  @Output() quantidadeSos = new EventEmitter();
  public fichasFiltradas;

  filter() {
    this.fichasFiltradas = fichasFilter(this.filtro, this.propostas);
    this.quantidadeSos.emit(calcularQuantidadeSOS(this.fichasFiltradas));
    return this.fichasFiltradas;
  }
}
