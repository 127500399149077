export class HeaderSort {
  column: string;
  direction: string;

  constructor(column?: string, direction?: string) {
    this.column = column || 'codigo';
    this.direction = direction || 'desc';
  }

  get values(): string {
    return `${this.column},${this.direction}`;
  }
}
