import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcommerceComponent } from './ecommerce.component';
import { DadosSimulacaoComponent } from './dados-simulacao/dados-simulacao.component';
import { ProdutosSharedModule } from '../../shared/shared.module';
import { SimulacaoComponent } from './simulacao/simulacao.component';
import { PrazoPrimeiroPgtoComponent } from './simulacao/prazo-primeiro-pgto/prazo-primeiro-pgto.component';
import { DadosContatoComponent } from './dados-contato/dados-contato.component';
import { PropostaEnviadaComponent } from './dados-contato/proposta-enviada/proposta-enviada.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { DadosClienteComponent } from './dados-cliente/dados-cliente.component';
import { ResumoFinanciamentoComponent } from './resumo-financiamento/resumo-financiamento.component';
import { DadosProfissionaisModule } from '../../../shared/dados-profissionais/dados-profissionais.module';
import { ReferenciaBaseComponent } from './dados-cliente/referencia-base/referencia-base.component';
import { TelefoneBaseComponent } from './dados-cliente/telefone-base/telefone-base.component';
import { CountdownModule } from 'ngx-countdown';
import { SmsNotReceivedComponent } from './dados-cliente/sms-not-received/sms-not-received.component';
import { FormsModule } from '@angular/forms';
import { EnderecoBaseComponent } from './enderecos-cliente/endereco-base/endereco-base.component';
import { EnderecosClienteComponent } from './enderecos-cliente/enderecos-cliente.component';
import { DemaisDadosComponent } from './demais-dados/demais-dados.component';
import { MercadoriaComponent } from './demais-dados/mercadoria/mercadoria.component';
import { AnaliseComponent } from './analise/analise.component';
import { ClienteNaoElegivelComponent } from './cliente-nao-elegivel/cliente-nao-elegivel.component';

@NgModule({
  declarations: [
    EcommerceComponent,
    DadosSimulacaoComponent,
    SimulacaoComponent,
    PrazoPrimeiroPgtoComponent,
    DadosContatoComponent,
    PropostaEnviadaComponent,
    DadosClienteComponent,
    ResumoFinanciamentoComponent,
    ReferenciaBaseComponent,
    TelefoneBaseComponent,
    SmsNotReceivedComponent,
    EnderecoBaseComponent,
    EnderecosClienteComponent,
    DemaisDadosComponent,
    MercadoriaComponent,
    AnaliseComponent,
    ClienteNaoElegivelComponent
  ],
  exports: [
    EcommerceComponent,
    DadosSimulacaoComponent
  ],
  imports: [
    CommonModule,
    ProdutosSharedModule,
    DirectivesModule,
    DadosProfissionaisModule,
    CountdownModule,
    FormsModule
  ],
  entryComponents: [
    PrazoPrimeiroPgtoComponent,
    PropostaEnviadaComponent,
    SmsNotReceivedComponent,
  ]
})
export class EcommerceModule { }
