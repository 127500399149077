import { DocumentoEnviadoVO } from './../documentoVO';
import { BsModalRef } from 'ngx-bootstrap';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-visualiza-imagem',
  templateUrl: './visualiza-imagem.component.html',
  styleUrls: ['./visualiza-imagem.component.css']
})
export class VisualizaImagemComponent {

  @Input() imagem: DocumentoEnviadoVO;

  constructor(
    public modal: BsModalRef,
    public sanitizer: DomSanitizer) { }

  get isPdf() {
    return this.imagem.name.toLowerCase().endsWith('.pdf');
  }

  getSafeURL(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
