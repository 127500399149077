export class CpfCnpj {
  public static clearMask(value): string {
    return value ? value.replace(/[\.\-\/]/g, '') : undefined;
  }

  public static formatWithMask(value) {
    const document = this.clearMask(value);
    if (document) {
      switch (document.length) {
        case 11:
          return `${document.substring(0, 3)}.${document.substring(
            3,
            6
          )}.${document.substring(6, 9)}-${document.substring(9)}`;
        case 14:
          return `${document.substring(0, 2)}.${document.substring(
            2,
            5
          )}.${document.substring(5, 8)}/${document.substring(
            8,
            12
          )}-${document.substring(12)}`;
        default:
          return document;
      }
    }
    return undefined;
  }
}
