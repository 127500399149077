
import { timer as observableTimer,  Observable } from 'rxjs';

import { take, finalize } from 'rxjs/operators';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FichaService } from '../../ficha-veiculo.service';
import { LoginStorage } from './../../../login/login.storage';
import { ModalLoaderComponent } from './../../../shared/modais/modal-loader/modal-loader.component';
import { ModalMessageComponent } from './../../../shared/modais/modal-message/modal-message.component';
import { FichaTimelineSharedService } from './../ficha-timeline.shared.service';
import { AvalistaComponent } from './avalista/avalista.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { ObservacaoComponent } from './observacao/observacao.component';

@Component({
  selector: 'app-ficha-timeline-sos',
  templateUrl: './ficha-timeline-sos.component.html',
  styleUrls: ['./ficha-timeline-sos.component.css']
})
export class FichaTimelineSosComponent {
  @Input() timelineActive: boolean;
  @Input() idFicha: string;
  @Input() cliente: {};
  @Input() cardDireito: any;
  @Input() dataLimiteAtendimento: number;

  isSos = false;

  @ViewChild('modalLoader', { static: true }) modalLoader: ModalLoaderComponent;
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;
  @ViewChild(ObservacaoComponent, { static: false }) private observacao: ObservacaoComponent;

  @ViewChild(DocumentosComponent, { static: false })
  private documentosComponent: DocumentosComponent;

  @ViewChild(AvalistaComponent, { static: false }) private avalistaComponent: AvalistaComponent;

  constructor(
    private fichaService: FichaService,
    private loginStorage: LoginStorage,
    private sharedService: FichaTimelineSharedService,
    private router: Router
  ) {}

  async sendSos() {
    if (!moment(this.dataLimiteAtendimento).isBefore(moment())) {
      if (!this.validaEnvioSOS()) {
        return;
      }

      if (this.hasObservacao()) {
        await this.observacao.sendObservacao();
      }

      if (this.hasDocumentos()) {
        await this.documentosComponent.fileUpload();
      }

      if (this.hasAvalista()) {
        this.avalistaComponent.pushAvalista();
      }

      this.modalLoader.show('Respondendo o SOS...');
      let message: string;
      this.pushRespostaSos().pipe(
        finalize(() => this.handleEnvioSosFinished(message)))
        .subscribe(
          () => (message = 'SOS Respondido com sucesso!'),
          () =>
            (message = 'Ocorreu um erro ao responder o SOS, tente novamente.')
        );

      observableTimer(2000).subscribe(() =>
        this.handleEnvioSosFinished('Sucesso')
      );
    } else {
      this.modalMessage
        .show()
        .modalClose.pipe(take(1))
        .subscribe(() => this.router.navigateByUrl('/ficha'));
    }
  }

  private handleEnvioSosFinished(message: string) {
    this.modalLoader.hide();
    this.sharedService.reloadFicha();
  }

  validaEnvioSOS(): boolean {
    if (this.hasObservacao() && !this.observacao.validaEnvioObservacao()) {
      return false;
    }

    if (this.hasAvalista() && !this.avalistaComponent.validaEnvio()) {
      return false;
    }

    if (this.hasDocumentos() && !this.documentosComponent.validaEnvio()) {
      return false;
    }

    return true;
  }

  hasDocumentos(): boolean {
    return JSON.stringify(this.cardDireito.acoes).includes('GRAVAR_DOCS') && !this.isLojista();
  }

  hasObservacao(): boolean {
    return JSON.stringify(this.cardDireito.acoes).includes('GRAVAR_OBS');
  }

  hasAvalista(): boolean {
    return JSON.stringify(this.cardDireito.acoes).includes('INCLUIR_AVAL');
  }

  pushRespostaSos(): Observable<void> {
    const body = { idProposta: this.idFicha };
    return this.fichaService.pushRespostaSos(body);
  }

  isLojista(): boolean {
    return this.loginStorage.usuario.tipoUsuarioTO.id === 7;
  }

  isAgente(): boolean {
    return this.loginStorage.usuario.tipoUsuarioTO.id === 8;
  }

  isRespondeSos(): boolean {
    const canAnswer = this.cardDireito.acoes.every((acao) => acao.respondeSos === 'S');

    return canAnswer && this.isLojista() || this.isAgente();
  }
}
