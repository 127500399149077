import { Agrupamento } from './agrupamento/agrupamento';
export class RelacaoFichas {

  private _propostasDashboard: any[];
  private _propostas: Map<string, any[]>;

  constructor(_propostasDashboard: any, _agrupamento: Agrupamento) {
    this._propostasDashboard = _propostasDashboard;
    this.agruparPropostas(_agrupamento);
  }

  get propostas(): Map<string, any[]> {
    return this._propostas;
  }

  alterarAgrupamento(_agrupamento: Agrupamento) {
    this.agruparPropostas(_agrupamento);
  }

  private agruparPropostas(_agrupamento: Agrupamento): void {
    this._propostas = new Map();
    if (this._propostasDashboard && this._propostasDashboard.length > 0) {
      this._propostasDashboard.sort((p1, p2) => _agrupamento.compare(p1, p2));
      this._propostasDashboard.forEach(proposta => {
        const key = _agrupamento.getKey(proposta);
        if (this._propostas.has(key)) {
          const propostas: any[] = this._propostas.get(key);
          propostas.push(proposta);
          this._propostas.set(key, propostas);
        } else {
          this._propostas.set(key, [proposta]);
        }
      });
    }
  }
}
