import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-motivo-desistencia',
  templateUrl: './motivo-desistencia.component.html'
})
export class MotivoDesistenciaComponent implements OnDestroy {
  event = new Subject<number>();
  alive = true;

  constructor(
    private modalInstance: BsModalRef,
  ) { }

  ngOnDestroy() {
    this.alive = false;
  }

  desistir() {
    this.efetuarDesistencia(1);
  }

  outraFinanceira() {
    this.efetuarDesistencia(2);
  }

  fechar() {
    this.modalInstance.hide();
  }

  private efetuarDesistencia(motivo: number) {
    this.event.next(motivo);
    this.fechar();
  }
}
