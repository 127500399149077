import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-documentacao-em-analise',
  templateUrl: './ficha-timeline-documentacao-em-analise.component.html',
  styleUrls: ['./ficha-timeline-documentacao-em-analise.component.css']
})
export class FichaTimelineDocumentacaoEmAnaliseComponent {
  @Input() cliente: any;
  @Input() timeline: any;
  @Input() timelineActive: boolean;
  @Input() idFicha: number;
}
