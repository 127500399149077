import { LoginStorage } from './../../login/login.storage';
import { DocumentoVO, DocumentoEnviadoVO } from '../upload-arquivos/documentoVO';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { appSettings as globals } from '../../../environments/app.setings';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { appSettings } from './../../../environments/app.setings';

import { DialogService } from '../../services/dialog/dialog.service';
import { normalizeList } from '../../shared/helper/helper';
import { Documentation } from '../../shared/enums/documentation.enum';
import { Report } from '../../shared/models/report/report.model';
import { ModalFotoDicaComponent } from '../../shared/modais/modal-foto-dica/modal-foto-dica.component';
import { UploadArquivosDropComponent } from '../upload-arquivos-drop/upload-arquivos-drop.component';
import { takeWhile } from 'rxjs/operators';
import { ModalAlteracoesComponent } from '../modais/modal-alteracoes/modal-alteracoes.component';
import { UploadFile } from '../upload-arquivos-drop/upload-file';
import { toFileHandle } from '../upload-arquivos/file-handler';

import * as _ from 'lodash';

@Component({
  selector: 'app-upload-arquivos-modal',
  templateUrl: './upload-arquivos-modal.component.html',
  styleUrls: ['./upload-arquivos-modal.component.scss']
})
export class UploadArquivosModalComponent implements OnInit {

  @Input() documentos: DocumentoVO[] = [];
  @Input() cliente: { cpf: string;  nomeCliente?: string; nome?: string}; // nomeCliente para modal CDCL e nome para modal Veículos
  @Input() idFicha: string;
  @Input() isOrigemSos: boolean;
  @Input() hideInfo?: boolean = false;
  @Input() buttonLabel?: string;
  @Input() hideSendButton?: boolean;
  @Input() enableSendButton?: boolean;
  @Input() origem?: number = 1;
  @Input() acceptFiles = globals.ACCEPT_IMAGES;
  @Output() pushDocumentacao = new EventEmitter();
  @Input() pr30?: boolean = false;
  @Input() showEnviado?: boolean = false;
  @Input() centeredSendButton?: boolean = false;
  @Input() utilizaDropComponent: boolean = false;

  report: Report;
  isSizeLimit = false;
  isDocsObrigatorio = false;
  fileList: FileList[] = [];
  normalizedFileList = [];
  fotosGarantia: File[] = [];
  mensagem: string;
  isEnviandoDocumento = false;
  documentosAnexar: DocumentoVO[] = [];
  Documentation = Documentation;
  isShopkeeper: boolean;
  origemCdcl = false;
  displayModalAlteracoes = false;
  reportNotUploaded: boolean = false;

  constructor(
    private loginStorage: LoginStorage,
    private dialogService: DialogService,
    private modal: BsModalService,
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.loadDocumentosAnexar();
    this.loadAcceptFiles();
    this.loadReport();
    this.pushDocsAnexados();
  }

  private pushDocsAnexados() {
    this.documentosAnexar.forEach(doc => {
      if (doc.documentosAnexados) {
        this.normalizedFileList.push(doc.documentosAnexados);
      } else doc.documentosAnexados = [];
    });
  }

  private loadDocumentosAnexar() {
    this.isShopkeeper = this.loginStorage.usuario.isLojista;
    this.documentosAnexar = _.cloneDeep(this.documentos || []).filter(documento => {
      if (this.isShopkeeper) {
        return (
            documento.idDocto !== Documentation.Warranty &&
            documento.idPendencia !== Documentation.Report
        );
      }

      return documento.idDocto !== Documentation.Warranty;
    });

  }

  private loadReport() {
    const laudoVistoria = this.documentos.filter(doc => doc.idDocto === 10)[0];
    if (this.hasReportDocument()) this.report = laudoVistoria.report;
  }

  hasWarrantyPicturesDocument(): boolean {
    return this.documentos.some(document => document.idDocto === Documentation.Warranty);
  }

  hasReportDocument(): boolean {
    return this.documentosAnexar.some(document => document.idPendencia === Documentation.Report);
  }

  async confirmDocuments() {
    if (!this.isEnviandoDocumento && this.enableConfirmButton()) {
      this.pushArquivosUpados();
      this.pushDocumentacaoAnexada();
      this.limpaListFile();
      this.bsModalRef.hide();
    }
  }

  private readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result as string);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });
  }

  handleChangeReport(report: Report) {
    this.displayModalAlteracoes = true;
    this.reportNotUploaded = true;
    this.report = report;
  }

  handleFileChange(event: any, index: number) {
    this.isDocsObrigatorio = false;
    this.isSizeLimit = false;

    const files = event.target.files;
    const target = event.target;

    if (files && files[0]) {
      this.fileList[index] = files;

      const extensoesAceitas = this.acceptFiles.split(',');
      const extensaoInvalida = (filename: string) => extensoesAceitas.filter(ext => filename.toLowerCase().endsWith(ext)).length === 0;

      if (normalizeList(files).some((file: File) => extensaoInvalida(file.name))) {
        const extensoes = extensoesAceitas.map(ext => `"${ext}"`).join(', ');
        const message = `Um ou mais dos arquivos selecionados, não está em um formato aceito. Selecione arquivos com as extensões: ${extensoes}.`;
        return this.dialogService.confirm({ body: message, callbackSuccess: () => target.click() });
      }

      if ((this.normalizedFileList[index] && (this.normalizedFileList[index].length + files.length) > 10) || files.length > 10) {
        target.value = null;

        return this.dialogService.confirm({ body: 'O máximo são 10 imagens por documento.', callbackSuccess: () => target.click() });
      }

      if (normalizeList(files).some((file: File) => (file.size / 1048576) > 10)) {
        target.value = null;

        return this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionadas tem tamanho superior à 10mb.', callbackSuccess: () => target.click() });
      }

      if (
        this.normalizedFileList[index] &&
        normalizeList(files).some((file: File) => normalizeList(this.normalizedFileList[index]).some(selectedFile => {
          return (
            file.name === selectedFile.name &&
            file.size === selectedFile.size &&
            file.type === selectedFile.type
          );
        }))
      ) {
        target.value = null;

        return this.dialogService.confirm({ body: 'Uma ou mais das imagens selecionada(s) já foi adicionada.', callbackSuccess: () => target.click() });
      }

      const handledFiles = [];
      for (const value of files) {
        const file = value;
        handledFiles.push({
          file: toFileHandle(file),
          tipo: index
        });
      }

      this.documentosAnexar.forEach((documento, indice) => {

        const documentosAnexados: DocumentoEnviadoVO[] = handledFiles.filter(arquivoUpado => Number(arquivoUpado.tipo) === indice)
          .map(arquivoUpado => this.mapArquivoUpado(arquivoUpado));

        if (documentosAnexados.length > 0) {
          this.normalizedFileList[indice] = [...this.normalizedFileList[indice] || [], ...documentosAnexados];
        }

      });

      target.value = null;
    }

  }

  fileGarantiaChange(file, index) {
    this.isDocsObrigatorio = false;
    this.isSizeLimit = false;
    this.fotosGarantia[index] = file;
    this.displayModalAlteracoes = true;
  }

  removeFile(index: number, fileIndex: number) {
    this.normalizedFileList[index].splice(fileIndex, 1);

  }

  removeAllFiles(filesDocuments: any, documentIndex: number) {
    filesDocuments[documentIndex] = [];

  }

  getDocumentsFileLists() {
    return this.normalizedFileList.filter(doc => doc.length > 0);
  }

  enableConfirmButton(): boolean {
    return this.enableSendButton ||
      !!(this.haveChanges
        || this.getDocumentsFileLists().length - (this.hasReportDocument() ? 1 : 0) > 0
        || (this.fotosGarantia.filter(e => e).length === 3)
        || (this.report && this.reportNotUploaded)
      );
  }

  private limpaListFile() {
    this.fileList = [];
    this.normalizedFileList = [];

  }

  private pushDocumentacaoAnexada() {
    const contadorDocsEnviados = new Set(this.getDocumentsFileLists()).size;
    this.pushDocumentacao.next([contadorDocsEnviados, this.documentosAnexar]);
  }

  openModalFotoDica() {
    this.modal.show(ModalFotoDicaComponent, { ...appSettings.MODAL_PARAMS });
  }

  loadAcceptFiles() {
    if (this.origem === 6) {
      this.acceptFiles = appSettings.ACCEPT_IMAGES_AND_PDF;
    }
  }

  openModalSelecionarTodosDocumentos() {
    const handledFiles: UploadFile[] = [];
    for (let index = 0; index < this.normalizedFileList.length; index++) {
      if (Array.isArray(this.normalizedFileList[index])) {
        for (let indexFile = 0; indexFile < this.normalizedFileList[index].length; indexFile = indexFile + 1) {
          const file = this.normalizedFileList[index][indexFile].file;
          const idDocto = this.documentosAnexar[index].idDocto;
          const url = this.normalizedFileList[index][indexFile].url;
          handledFiles.push({
            file: {
              file,
              url
            },
            tipo: idDocto
          });
        }
      }
    }

    const initialState = {
      tiposDocumentos: this.documentosAnexar,
      cliente: this.cliente,
      proposta: this.idFicha,
      origem: this.origem,
      acceptFiles: this.acceptFiles,
      uploadOnButtonAnexar: false,
      files: handledFiles
    };
    const modal = this.modal.show(UploadArquivosDropComponent, { ...appSettings.MODAL_PARAMS, initialState });
    const uploaded = <UploadArquivosDropComponent>modal.content;

    uploaded.onUploaded
      .pipe(
        takeWhile(() => uploaded.alive)
      )
      .subscribe((arquivosUpados: UploadFile[]) => {
        if (arquivosUpados) {
          this.normalizedFileList = [];
          this.documentosAnexar.forEach((documento, index) => {

            const documentosAnexados: DocumentoEnviadoVO[] = arquivosUpados.filter(arquivoUpado => Number(arquivoUpado.tipo) === documento.idDocto)
              .map(arquivoUpado => (this.mapArquivoUpado(arquivoUpado)));

            if (documentosAnexados.length > 0) {
              this.normalizedFileList[index] = [...this.normalizedFileList[index] || [], ...documentosAnexados];
            }

          });
        }
      });
  }

  private mapArquivoUpado(arquivoUpado: UploadFile): { name: string; url: string; idDocto: number; file: File; } {
    return {
      name: arquivoUpado.file.file.name,
      url: arquivoUpado.file.url,
      idDocto: Number(arquivoUpado.tipo),
      file: arquivoUpado.file.file
    };
  }

  private pushArquivosUpados() {

    this.documentosAnexar.forEach(async (documento: DocumentoVO, index: number) => {
      documento.documentosAnexados = [];
      (this.normalizedFileList[index] || []).forEach(async (file: { file: any; name: any; }) => {
        const base64Content = await this.readFileAsDataURL(file.file);
        documento.documentosAnexados.push({
          idDocto: documento.idDocto,
          name: file.name,
          url: base64Content,
          file: file.file
        });
      });
    });

  }

  private get haveChanges(): boolean {
    const validDocs = this.documentos.map(doc => doc.documentosAnexados).filter(e => (e || []).length > 0);
    const validNorm = this.normalizedFileList.filter(e => e.length > 0);
    return !(validNorm.length === validDocs.length
      && _.isEqual(validDocs.map(e => e.length), validNorm.map(e => e.length))
      && validDocs.every((element, index) => {
        return element.map(f  => f.file.name)[0] === validNorm[index].map(f  => f.file.name)[0];
      }));
  }

  onClose() {
    this.displayModalAlteracoes = this.haveChanges;
    if (this.displayModalAlteracoes) {
      this.openModalAlteracoesFeitas();
    } else {
      this.bsModalRef.hide();
    }
  }

  private openModalAlteracoesFeitas() {
    const modal = this.modal.show(ModalAlteracoesComponent, { ...appSettings.MODAL_PARAMS });
    const decisao = <ModalAlteracoesComponent>modal.content;

    decisao.confirmValue
      .pipe(
        takeWhile(() => decisao.alive)
      ).subscribe(decisaoSub => {
        if (decisaoSub) {
          modal.hide();
          this.bsModalRef.hide();
        }
      });
  }
}
