export class RecuperaTelefone {
  private ddd: string;
  private telefone: string;
  private idCategoria: number;
  private idTipo: number;

  constructor(obj) {
    this.ddd = obj.ddd;
    this.telefone = obj.telefone;
    this.idCategoria = parseInt(obj.categoria.id, 10);
    if (obj.tipo) {
      this.idTipo = parseInt(obj.tipo.id, 10);
    }
  }

  getDdd(): string {
    return this.ddd;
  }

  getTelefone(): string {
    return this.telefone;
  }

  getIdCategoria(): number {
    return this.idCategoria;
  }

  getIdTipo(): number {
    return this.idTipo;
  }
}
