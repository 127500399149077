export class SeguroSimulacao {
  public id: number;
  public descricao: string;
  public valor: number;
  public percentual: number;
  public valorMaximo: number;
  public tipoValor: string;
  public saibaMais: string;

  constructor(obj) {
    this.id = obj.id;
    this.descricao = obj.descricao;
    this.valor = obj.valor;
    this.percentual = obj.percentual;
    this.valorMaximo = obj.valorMaximo;
    this.tipoValor = obj.tipoValor;
    this.saibaMais = obj.saibaMais;
  }

  getId(): number {
    return this.id;
  }

  getValor(): number {
    return this.valor;
  }
}
