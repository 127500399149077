import { InformacoesCliente } from './informacoes-cliente';
import { NovaProposta } from './nova-proposta';
import { LojaVendedor } from './loja-vendedor';
import { ParametroRendaProduto } from './parametro-renda-produto';

export class PropostaCadastro {
  solicitante: string;
  tipoProduto: string;
  produto: string;
  banco: string;
  operacao: string;
  avalista: boolean;
  lojaVendedor = new LojaVendedor();
  informacoesCliente = new InformacoesCliente();
  parametroRendaProduto = new ParametroRendaProduto();

  constructor(obj: NovaProposta) {
    this.solicitante = obj.solicitante;
    this.tipoProduto = obj.tipoProduto;
    this.produto = obj.produto;
    this.banco = obj.banco;
    this.operacao = obj.operacao;
    this.avalista = obj.avalista;
    this.parametroRendaProduto = obj.parametroRendaProduto;
  }
}
