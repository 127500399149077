
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parecer-sos',
  templateUrl: './parecer-sos.component.html',
  styleUrls: ['./parecer-sos.component.css']
})
export class ParecerSosComponent {

  @Input() cardDireito: any;

  getParecerInfo(): string {
    const lineBreak = this.cardDireito.parecer.search('\n');
    return this.cardDireito.parecer.substring(0, lineBreak);
  }

  getParecerDesc(): string {
    const lineBreak = this.cardDireito.parecer.search('\n');
    const descParecer = this.cardDireito.parecer.substring(lineBreak + 1, this.cardDireito.parecer.length);
    return descParecer.split('\n').join('<br>');
  }
}
