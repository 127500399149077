export class EnderecoCep {

  private _consulta: any;

  constructor(obj) {
    this._consulta = (obj.enderecos || [])[0] || {};
  }

  get bairro() {
    return this._consulta.bairro || '';
  }

  get cep() {
    return this._consulta.cep || '';
  }

  get cepUnico() {
    return this._consulta.cepUnico || false;
  }

  get cidade() {
    return this._consulta.cidade || '';
  }

  get uf() {
    return this._consulta.uf || '';
  }

  get logradouro() {
    if (this._consulta.tipo && this._consulta.endereco) {
      return `${this._consulta.tipo} ${this._consulta.endereco}`;
    }
    return '';
  }

  get numero() {
    return this._consulta.numero || '';
  }
}
