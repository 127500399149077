import { ModalAlterarEmailComponent } from './../modal-alterar-email/modal-alterar-email.component';
import { DialogService } from './../../../../services/dialog/dialog.service';
import { ModalDocusignComponent } from './../modal-docusign/modal-docusign.component';
import { appSettings } from './../../../../../environments/app.setings';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as toastr from 'toastr';
import { CreditoPessoalService } from '../../services/cp.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-modal-assinatura',
  templateUrl: './modal-assinatura.component.html',
  styleUrls: ['./modal-assinatura.component.scss']
})
export class ModalAssinaturaComponent implements OnInit, OnDestroy {

  @Input() contrato: string;
  @Input() proposta: number;
  @Output() changeMetodoAssinatura = new EventEmitter<'SMS' | 'EMAIL' | 'TOKEN'>();

  loading = false;
  signatarios;
  metodosAssinatura;
  mensagemAssinaturaNaoPermitida;

  enviouSms = false;
  enviouToken = false;
  enviouEmail = false;

  alive = true;

  constructor(
    private modal: BsModalService,
    private modalInstance: BsModalRef,
    private dialogService: DialogService,
    private service: CreditoPessoalService) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    this.signatarios = [];
    this.metodosAssinatura = [];
    this.mensagemAssinaturaNaoPermitida = null;

    try {
      this.loading = true;
      const response = await this.service.findSignTypes(this.contrato);
      this.signatarios = response.signatarios || [];
      this.metodosAssinatura = response.metodosAssinatura || [];
      this.mensagemAssinaturaNaoPermitida = (response.messages || [])[0];
      this.enviouSms = this.temEnvelopeCriado('SMS');
      this.enviouEmail = this.temEnvelopeCriado('EMAIL');
      this.enviouToken = this.temEnvelopeCriado('TOKEN');
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private async sendAndReload(exec: Promise<any>) {
    try {
      this.loading = true;
      return await exec;
    } finally {
      await this.init();
    }
  }

  private changeSignType(type: 'SMS' | 'EMAIL' | 'TOKEN', exec: () => Promise<any>, then: (value) => Promise<any> = async (_) => ({})) {
    if (this.hasEnvelope) {
      const message = 'Alterar a opção de assinatura irá fazer com que os dados preenchidos, até então, sejam perdidos.</p><p><strong>Deseja continuar?</strong>';
      this.dialogService.confirm({
        body: message,
        title: 'Atenção',
        classList: ['modal-md'],
        textClass: 'text-warning',
        iconClass: 'text-warning fa fa-exclamation-triangle',
        callbackSuccess: () => {
          this.sendAndReload(exec())
            .then(value => then(value))
            .then(_ => this.changeMetodoAssinatura.emit(type));
        },
        callbackError: () => {
          this.init();
        }
      });

      return Promise.resolve();
    }

    return this.sendAndReload(exec())
      .then(value => then(value))
      .then(_ => this.changeMetodoAssinatura.emit(type));
  }

  sendEmail() {
    const promise = () => this.service.sendToken(this.proposta, 2);
    return this.changeSignType('EMAIL', promise);
  }

  sendSms() {
    const promise = () => this.service.sendToken(this.proposta, 1);
    return this.changeSignType('SMS', promise);
  }

  async resendToken(signatario, tpComunicacao) {
    try {
      this.loading = true;
      const body = {
        idProposta: this.proposta,
        cpf: signatario.cpf,
        tipoComunicacao: tpComunicacao
      };
      return await this.service.resendToken(body);
    } catch (error) {
      this.showError(error);
    } finally {
      this.loading = false;
    }
  }

  async onTokenSend(link) {
    const initialState = { urlContrato: link };
    this.modal.show(ModalDocusignComponent, { ...appSettings.MODAL_PARAMS, initialState });
  }

  showError(error: Error) {
    toastr.error(error.message);
  }

  close() {
    this.modalInstance.hide();
  }

  possuiAssinatura(tipo: 'SMS' | 'EMAIL' | 'TOKEN') {
    return this.metodosAssinatura.filter(metodo => metodo.tipo === tipo).length > 0;
  }

  private temEnvelopeCriado(tipo: 'SMS' | 'EMAIL' | 'TOKEN') {
    const metodoFound = this.metodosAssinatura.filter(metodo => metodo.tipo === tipo)[0];
    if (metodoFound) {
      return metodoFound.envelopeCriado;
    }
    return false;
  }

  get assinaturaIndividual() {
    return this.signatarios.length === 1;
  }

  get hasEnvelope() {
    return this.enviouEmail || this.enviouSms || this.enviouToken;
  }

  private alterarEmail(signatario) {
    const initialState = { cpf: signatario.cpf };
    const alterarEmailModal = <ModalAlterarEmailComponent> this.modal.show(ModalAlterarEmailComponent, { ...appSettings.MODAL_PARAMS, initialState }).content;
    alterarEmailModal.onChangeEmail
      .pipe(takeWhile(() => alterarEmailModal.alive))
      .subscribe(_ => this.init());
  }

}
