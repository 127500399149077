import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import printJs from 'print-js';
import { finalize, take, takeWhile, timeout } from 'rxjs/operators';

import { appSettings } from '../../../../environments/app.setings';
import { CdcLojaRestService } from '../../../omni-rest/cdc/cdc-loja-rest.service';
import { CdcLojaService } from '../services/cdc-loja.service';
import { ApprovalModalComponent } from './approval-modal/approval-modal.component';
import { ContratoCessao } from './contrato-cessao';
import { PaginaCessao } from './pagina-cessao';

@Component({
  selector: 'app-cessao',
  templateUrl: './cessao.component.html',
  styleUrls: ['./cessao.component.scss'],
})
export class CessaoComponent implements OnInit {
  filter: any;
  key: string;
  reverse: boolean = true;
  contratos: ContratoCessao[] = [];
  page: PaginaCessao;
  sizeSelection = [30, 50];
  size: number = this.sizeSelection[0];
  isAguardandoAssinatura: boolean;
  isAssinado: boolean = false;
  selecionados: boolean;
  liberado: boolean;
  contratosCessaoSelecionados: ContratoCessao[] = [];

  showLoader: boolean = false;
  isPesquisandoCessaoNaoAssinadaLoader: boolean = false;
  isPesquisandoCessaoAprovadaLoader: boolean = false;

  openFilter = false;

  constructor(
    private cdclojaService: CdcLojaService,
    private modal: BsModalService,
    private cdcLojaRestService: CdcLojaRestService
  ) { }

  ngOnInit() {
    this.selecionados = false;
    this.initObservers(0, this.size);
  }

  private carregarPaginaDeTermosDeCessao(page: number, size: number) {
    this.cdclojaService
      .paginatedListAssignment(page, size, this.liberado)
      .pipe(
        finalize(() => {
          this.showLoader = false;
          this.isPesquisandoCessaoNaoAssinadaLoader = false;
          this.isPesquisandoCessaoAprovadaLoader = false;
        }),
        timeout(50000),
        take(1)
      )
      .subscribe((data: PaginaCessao) => {
        this.setPagination(data);
        this.contratos = data.content;
      });
  }

  initObservers(page: number, size: number): void {
    this.liberado = false;
    this.isAguardandoAssinatura = true;
    this.showLoader = true;
    this.isPesquisandoCessaoNaoAssinadaLoader = true;

    this.carregarPaginaDeTermosDeCessao(page, size);
  }

  onClickEmAguardandoAssinatura(): void {
    this.contratosCessaoSelecionados = [];
    this.selecionados = false;

    this.isAguardandoAssinatura = true;
    this.isAssinado = false;

    this.initObservers(0, this.size);
  }

  onClickTermoCessaoAprovado(page = 0): void {
    this.selecionados = false;
    this.contratosCessaoSelecionados = [];

    this.liberado = true;
    this.showLoader = true;
    this.isPesquisandoCessaoAprovadaLoader = true;
    this.isAguardandoAssinatura = false;
    this.isAssinado = true;

    this.carregarPaginaDeTermosDeCessao(page, this.size);
  }

  selecionaTodos() {
    this.contratos.forEach(
      (cessao) => (cessao.selecionado = this.selecionados)
    );
    this.retornaContratosSelecionados();
  }

  isTodosSelecionados() {
    this.selecionados = this.contratos.every((cessao) => cessao.selecionado);
    this.retornaContratosSelecionados();
  }
  retornaContratosSelecionados() {
    this.contratosCessaoSelecionados = [];
    this.contratos.forEach((cessao) => {
      if (cessao.selecionado) {
        this.contratosCessaoSelecionados.push(cessao);
      }
    });
  }

  onClickAprovarEmLote() {
    const contratos = this.contratosCessaoSelecionados;
    const optionIndexes = this.contratosCessaoSelecionados.map(() => 0);

    const initialState = {
      contratos,
      optionIndexes,
      display: 'multi',
    };

    const modal = this.modal.show(ApprovalModalComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
    const confirm = <ApprovalModalComponent>modal.content;
    this.confirmarAprovacao(confirm);
  }

  onClickAssinar(contrato: ContratoCessao) {
    const initialState = {
      contratos: [contrato],
      display: 'single',
      showBotaoAssinatura: true,
    };
    const modal = this.modal.show(ApprovalModalComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
    const confirm = <ApprovalModalComponent>modal.content;
    this.confirmarAprovacao(confirm);
  }

  private confirmarAprovacao(confirm: ApprovalModalComponent) {
    confirm.confirmValue
      .pipe(takeWhile(() => confirm.alive))
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.initObservers(0, this.size);
        }
      });
  }

  getPDF(contrato: ContratoCessao) {
    return new Promise(async (resolve) => {
      await this.cdcLojaRestService
        .get(contrato.url, {}, 'arraybuffer')
        .toPromise()
        .then((res) => {
          const pdfFile = new Blob([res.body], {
            type: 'application/pdf',
          });
          resolve(URL.createObjectURL(pdfFile));
        });
    });
  }

  async onClickVisualizar(contrato: ContratoCessao) {
    const pdf = await this.getPDF(contrato);

    const initialState = {
      contrato,
      pdf,
      display: 'contrato',
      title: 'Visualizar Termo de Cessão de Crédito',
      onlyContract: true,
    };

    this.modal.show(ApprovalModalComponent, {
      ...appSettings.MODAL_PARAMS,
      initialState,
    });
  }

  async onClickImprimir(contrato: ContratoCessao) {
    printJs({
      printable: await this.getPDF(contrato),
      modalMessage: 'Carregando documento...',
      type: 'pdf',
      showModal: true,
    });
  }

  onClickChangePage(page = 0) {
    const pageAux = page ? page : 0;
    this.selecionados = false;

    if (this.isAssinado) {
      this.onClickTermoCessaoAprovado(pageAux);
      return;
    }

    this.initObservers(pageAux, this.size);
  }

  changePageSize(newSize: number) {
    this.size = newSize;
    this.onClickChangePage();
  }

  setPagination(page: PaginaCessao) {
    this.page = page;
    if (!this.page) {
      return;
    }

    const pages = [];
    const inc = this.page.number - 2 <= 0 ? 4 - this.page.number : 2;
    const dec =
      this.page.number + 2 >= this.page.totalPages
        ? 5 - (this.page.totalPages - this.page.number)
        : 2;
    const inicio = this.page.number - dec <= 0 ? 0 : this.page.number - dec;
    const fim =
      this.page.number + inc < this.page.totalPages
        ? this.page.number + inc
        : this.page.totalPages - 1;
    for (let i = inicio; i <= fim; i++) {
      pages.push(i);
    }
    this.page.pages = pages;
  }

  sort(key: string) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  get aprovarEmLote() {
    if (
      this.contratosCessaoSelecionados &&
      this.contratosCessaoSelecionados.length >= 2
    ) {
      return true;
    }
    return false;
  }

  get clickouAssinado() {
    return this.isAssinado;
  }

  filtrar(f: NgForm) {
    const filter = f.value;
    if (this.liberado) {
      this.isPesquisandoCessaoAprovadaLoader = true;
    } else {
      this.isPesquisandoCessaoNaoAssinadaLoader = true;
    }

    this.cdclojaService
      .filteredListAssignment(this.liberado, filter)
      .pipe(
        finalize(() => {
          this.showLoader = false;
          this.isPesquisandoCessaoNaoAssinadaLoader = false;
          this.isPesquisandoCessaoAprovadaLoader = false;
          this.openFilter = false;
        }),
        timeout(50000),
        take(1)
      )
      .subscribe((data: ContratoCessao[]) => {
        this.setPagination(null);
        this.contratos = data;
      });

  }
}
