import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.css'],
})
export class ModalLoaderComponent {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @Input() message = 'Carregando...';

  show(message?: string) {
    if (message) {
      this.message = message;
    }
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
