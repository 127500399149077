
import { Component, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { take, takeWhile, timeout } from 'rxjs/operators';
import * as toastr from 'toastr';

import { CdcLojaService } from '../../../../produtos/cdc-loja/services/cdc-loja.service';
import { CreditoPessoalService } from '../../../../produtos/cp/services/cp.service';
import { LeadsOnlineService } from '../../../../produtos/leads/services/leads.service';
import { BancoService } from '../../../../services/banco/banco.service';
import { OperacoesGeralService } from '../../../../services/geral/operacoes/operacoes.geral.service';
import { NewModalMessageComponent } from '../../../../shared/modais/new-modal-message/new-modal-message.component';
import { ButtonSelectorData } from '../button-selector/button-selector-data';
import { TipoNegociacaoEnum } from './enum/tipo-negociacao.enum';
import { TipoProdutoEnum } from './enum/tipo-produto.enum';
import { OperacoesHandler } from './operacoes.handler';
import { ParametroRendaProduto, TipoOperacaoSelecao } from './tipo-operacao-selecao';

@Component({
  selector: 'app-tipo-operacao',
  templateUrl: './tipo-operacao.component.html',
  styleUrls: ['./tipo-operacao.component.css']
})
export class TipoOperacaoComponent implements OnInit, OnDestroy {
  @Input() filterTipos?: string[];
  @Output() dadosSelecionados = new BehaviorSubject<TipoOperacaoSelecao>(
    new TipoOperacaoSelecao()
  );

  @ViewChild('modalMessage', { static: true })
  modalMessage: NewModalMessageComponent;

  tiposProduto: ButtonSelectorData[];
  tipoProduto: ButtonSelectorData = new ButtonSelectorData();

  produtos: ButtonSelectorData[];
  produto: ButtonSelectorData = new ButtonSelectorData();

  bancos: any[] = [];
  operacoes: any[] = [];
  parametroRendaProduto: ParametroRendaProduto = new ParametroRendaProduto();

  alive = true;
  form: FormGroup;
  cpCdcLojaService: CdcLojaService | CreditoPessoalService;

  leadsOnlineService: LeadsOnlineService;
  isleads: boolean = false;

  constructor(
    private fb: FormBuilder,
    private operacoesGeralService: OperacoesGeralService,
    private bancoService: BancoService,
    private injector: Injector,
  ) { }

  ngOnInit() {
    this.initBancos();
    this.initTiposProduto();
    this.initForm();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private initBancos(): void {
    this.bancoService
      .bancos().pipe(
        timeout(20000),
        take(1))
      .subscribe(
        (res: any[]) => this.bancos = res,
        () => this.modalMessage.showModal({ mensagem: 'Erro obtendo os bancos.' })
      );
  }

  private initTiposProduto(): void {
    this.operacoesGeralService
      .tiposProdutos().pipe(
        timeout(20000),
        take(1))
      .subscribe(
        async res => {
          const tiposProduto = OperacoesHandler.handle(this.filterTipos ? (res || []).filter(f => this.filterTipos.includes(f.descricao)) : res);
          if (this.isVeiculoArray(tiposProduto)) {
            await this.initLeadsOnline();
          }
          this.tiposProduto = tiposProduto;
        },
        () => this.modalMessage.showModal({ mensagem: 'Erro obtendo os tipos de produto.' })
      );
  }

  private initForm(): void {
    this.form = this.fb.group({
      tipoProduto: [''],
      produto: [''],
      banco: [''],
      operacao: ['']
    });

    this.form
      .valueChanges.pipe(
        takeWhile(() => this.alive))
      .subscribe(
        () => this.dadosSelecionadosChange()
      );
  }

  dadosSelecionadosChange() {
    const dados = new TipoOperacaoSelecao();
    dados.tipoProduto = this.tipoProdutoForm.value;
    dados.operacao = this.operacaoForm.value;
    dados.produto = this.produtoForm.value;
    dados.banco = this.bancoForm.value;
    dados.leads = this.isleads;

    if (this.parametroRendaProduto) {
      dados.parametroRendaProduto = this.parametroRendaProduto;
    }

    this.dadosSelecionados.next(dados);
  }

  onTipoProdutoChange($event: any): void {
    this.resetForm();

    this.tipoProduto = $event;
    this.tipoProdutoForm.setValue(this.tipoProduto.id);

    if (this.isCreditoPessoal) {
      this.cpCdcLojaService = this.injector.get(CreditoPessoalService);
      this.cpCdcLojaService
        .produtos().pipe(
          timeout(20000),
          take(1))
        .subscribe(
          res => this.produtos = OperacoesHandler.handle(res),
          () => this.modalMessage.showModal({ mensagem: 'Erro obtendo os produtos.' })
        );
    } else if (this.isCdcLoja || this.isCdcPremium) {
      this.cpCdcLojaService = this.injector.get(CdcLojaService);
      this.cpCdcLojaService
        .operacoes(this.tipoProduto.id).pipe(
          timeout(20000),
          take(1))
        .subscribe(
          (res) => {
            this.operacoes = res.operacoes;
            this.parametroRendaProduto = res.parametroRendaProduto;
          },
          () => this.modalMessage.showModal({ mensagem: 'Erro obtendo as operações.' })
        );
    } else if (this.isMicrocredito) {

      this.cpCdcLojaService = this.injector.get(CreditoPessoalService);
      this.cpCdcLojaService
        .operacoes('MICROCRÉDITO-RL')
        .pipe(timeout(20000), take(1))
        .subscribe(
          res => this.operacoes = res,
          () => this.modalMessage.showModal({ mensagem: 'Erro obtendo as operações.' })
        );
    } else if (this.isleads) {
      this.produtos = OperacoesHandler.handle([{ descricao: TipoProdutoEnum.LEADS }]);
    }
    this.dadosSelecionadosChange();
  }

  onProdutoChange($event: ButtonSelectorData): void {
    this.resetForm();

    if ($event.id) {
      this.produto = $event;
      this.produtoForm.setValue(this.produto.id);

      if (!this.isleads) {
        this.cpCdcLojaService
          .operacoes(this.produto.id).pipe(
            timeout(20000),
            take(1))
          .subscribe(
            res => this.operacoes = res,
            () => this.modalMessage.showModal({ mensagem: 'Erro obtendo as operações.' })
          );
      }
    }

    this.dadosSelecionadosChange();
  }

  private resetForm(): void {
    this.bancoForm.setValue('', { emitEvent: false });

    this.operacaoForm.setValue('', { emitEvent: false });
    this.operacoes = [];

    this.produto = new ButtonSelectorData();
    this.produtoForm.setValue('', { emitEvent: false });
  }

  isVeiculoArray(tiposProduto): boolean {
    return tiposProduto.some(e => e.id === TipoProdutoEnum.FINANCIMENTO);
  }

  get bancoForm(): AbstractControl {
    return this.form.get('banco');
  }

  get operacaoForm(): AbstractControl {
    return this.form.get('operacao');
  }

  get produtoForm(): AbstractControl {
    return this.form.get('produto');
  }

  get tipoProdutoForm(): AbstractControl {
    return this.form.get('tipoProduto');
  }

  get isCdcLoja(): boolean {
    return [TipoProdutoEnum.CDC_LOJA].includes(this.tipoProduto.id);
  }

  get isCreditoPessoal(): boolean {
    return [TipoProdutoEnum.CREDITO_PESSOAL].includes(this.tipoProduto.id);
  }

  get isVeiculo(): boolean {
    return [TipoProdutoEnum.FINANCIMENTO, TipoProdutoEnum.REFINANCIAMENTO].includes(this.tipoProduto.id);
  }

  get showProdutos(): boolean {
    return this.isCreditoPessoal || (this.isVeiculo && this.isleads);
  }

  get isDebitoAutomatico(): boolean {
    return this.isCreditoPessoal && this.produto.id === TipoNegociacaoEnum.DEBITO_AUTOMATICO;
  }

  get isCarne(): boolean {
    return this.isCreditoPessoal && this.produto.id === TipoNegociacaoEnum.CARNE;
  }

  get isMicrocredito(): boolean {
    return [TipoProdutoEnum.MICROCREDITO].includes(this.tipoProduto.id);
  }

  get isCdcPremium(): boolean {
    return [TipoProdutoEnum.CDC_PREMIUM].includes(this.tipoProduto.id);
  }

  get showBancos(): boolean {
    return this.isDebitoAutomatico;
  }

  get showOperacoes(): boolean {
    return ((this.isCdcLoja || this.isMicrocredito || this.isCdcPremium) && this.operacoes.length > 0)
      || (
        this.isCreditoPessoal && (this.isCarne || (this.isDebitoAutomatico && this.bancoForm.value.length > 0))
      );
  }

  get filterBancos(): any[] {
    return this.bancos
      .filter(ele => ele.codigo === '237' || ele.codigo === '033') // Bradesco ou Santander
      .map(ele => {
        return {
          codigo: ele.codigo,
          descricao: ele.descricao.toUpperCase().replace('S.A.SITE', 'S.A.')
        };
      });
  }

  async initLeadsOnline() {
    this.leadsOnlineService = this.injector.get(LeadsOnlineService);
    try {
      const response = await this.leadsOnlineService.getOriginadorLeads();
      this.isleads = response.originadorLeads;
    } catch (e) {
      toastr.warning('Erro ao consultar tipo de originador Leads Online. Caso seja um parceiro Leads, favor contatar o suporte', 'Atenção');
    }
  }
}
