
import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpStatus } from '../omni-rest/http-status';
import { OmniMaisRestService } from '../omni-rest/omni-mais/omni-mais-rest.service';
import { DadoSimulacao } from './ficha-cadastro/ficha-cadastro-resultado-parcial/model/dado-simulacao';
import { OmniVerificacaoSegurancaService } from '../omni-rest/omni-verificacao-seguranca/omni-verificacao-seguranca.service';
import { LeadsOnlineRestService } from '../omni-rest/leads/leads-online-rest.service';
import { ConfirmaDadosAssinatura } from './ficha-verificacao-seguranca/confirma-dados-assinatura';
import { GrupoSeguro } from './ficha-cadastro/ficha-cadastro-resultado-parcial/model/proposta/grupo-seguro';
import { DadosEnvio } from './ficha-timeline/ficha-timeline-emissao-pre-contrato/model/dados-envio';

@Injectable()
export class FichaService {

  constructor(
    private restService: OmniMaisRestService,
    private verificacaoRestService: OmniVerificacaoSegurancaService,
    private leadsOnlineRestService: LeadsOnlineRestService
  ) { }

  // Serviços da Tela Inicial --------------------------------------------------------------------------------------
  findAllEmAnalise(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllAprovada(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllEmFechamento(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllRecusada(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  findAllContratoCancelado(data): Observable<any> {
    return this.restService.post('proposta/listPropostaPorFaseOperadorV3', data);
  }

  analiseAutomatica(idProposta: string): Observable<any> {
    return this.restService.post(`veiculo/analise-automatica/${idProposta}`);
  }
  // Serviços da Tela Inicial --------------------------------------------------------------------------------------

  // Serviços do Cadastro de Nova Ficha ----------------------------------------------------------------------------
  getLojistaByOperador(): Observable<any> {
    return this.restService.get('lojista/listaLojistaOperador');
  }

  getVendedoresByLojista(idLojista): Observable<any> {
    return this.restService.get(`vendedor/listByLojista/${idLojista}`);
  }

  getTipoVeiculo(): Observable<any> {
    return this.restService.get('veiculo/buscarTipoVeiculo');
  }

  getMarcaPorTipo(idTipoVeiculo: string): Observable<any> {
    return this.restService.get(`veiculo/buscarMarcaPorTipo/${idTipoVeiculo}`);
  }

  getAnoModeloPorMarca(idTipoVeiculo: string, idMarca: string): Observable<any> {
    return this.restService.get(`veiculo/buscarAnoModeloPorMarca/${idTipoVeiculo}/${idMarca}`);
  }

  getModeloPorMarcaAno(idTipoVeiculo: string, idMarca: string, idAnoModelo: string): Observable<any> {
    return this.restService.get(`veiculo/buscarModeloPorMarcaAno/${idTipoVeiculo}/${idMarca}/${idAnoModelo}`);
  }

  getVersaoPorMarcaModeloAno(idTipoVeiculo: string, idMarca: string, idAnoModelo: string, idModelo: string): Observable<any> {
    return this.restService.get(`veiculo/buscarVersaoPorMarcaModeloAno/${idTipoVeiculo}/${idMarca}/${idModelo}/${idAnoModelo}`);
  }

  getCotacaoPorVersao(idTipoVeiculo: string, idMarca: string, idAnoModelo: string, idModelo: string, idVersao: string): Observable<any> {
    return this.restService.get(`veiculo/buscarCotacaoPorVersao/${idTipoVeiculo}/${idMarca}/${idModelo}/${idVersao}/${idAnoModelo}`);
  }

  getAllNacionalidade() {
    return this.restService.get('nacionalidade/list');
  }

  getCidadePorUf(uf: string) {
    return this.restService.get(`cidade/listCidadeByUf/${uf}`);
  }

  getAllProfissoes() {
    return this.restService.get('profissao/list');
  }

  getProfissoesByClasseProfissional(id: number) {
    return this.restService.get(`profissao/listarProfissoesPorClasse/${id}`);
  }

  getEnderecoByCep(cep: string) {
    return this.restService.get(`cep/buscarEnderecoPorCep/${cep}`);
  }

  simular(payload: any) {
    return this.restService.post('veiculo/simular', payload);
  }

  simulacao(simulacao: DadoSimulacao, parcelas?: number) {
    let url = `v2/proposta/${simulacao.getNumeroProposta()}/simulacao/detalhe` +
      `?ultimaDataTaxa=${simulacao.getUltimaDataTaxa()}` +
      `&retorno=${simulacao.getSeguro().getRetorno()}` +
      `&carencia=${simulacao.getCarencia()}` +
      `&entradaPorcentagem=${simulacao.getEntradaPorcentagem()}` +
      `&parcelas=${parcelas || 0}`;

    if (simulacao.getValor() && simulacao.getValor() > 0) {
      url = `${url}&valorLiberado=${simulacao.getValor()}`;
    } else {
      url = `${url}&valorLiberado=0`;
    }

    if (simulacao.getSeguro().getGrupoSeguroIds() != null && simulacao.getSeguro().getGrupoSeguroIds().length) {
      simulacao.getSeguro().getGrupoSeguroIds().map(elem => elem.seguroId).forEach((item) => {
        url = `${url}&seguroId=${item}`;
      });
    } else {
      url = `${url}&seguroId=-1`;
    }

    if (simulacao.getSeguro().getGrupoAssistenciaIds() != null && simulacao.getSeguro().getGrupoAssistenciaIds().length) {
      simulacao.getSeguro().getGrupoAssistenciaIds().map(elem => elem.assistenciaId).forEach((item) => {
        url = `${url}&assistenciaId=${item}`;
      });
    } else {
      url = `${url}&assistenciaId=-1`;
    }
    return this.restService.get(url);
  }

  getDadosProposta(idProposta: string): Observable<any> {
    return this.restService.get(`proposta/${idProposta}/dados`).pipe(catchError((error, source) => {
      if (error.status === HttpStatus.BAD_REQUEST) {
        return observableOf(error.error);
      }
      return observableThrowError(error);
    }));
  }

  getPropostaBySimulacao(idProposta: string) {
    return this.getDadosProposta(idProposta);
  }

  getAllGrupoSeguro(): Observable<GrupoSeguro[]> {
    return this.restService.get('grupoTipoSeguro');
  }

  getAllTipoAssistencia() {
    return this.restService.get('tipoAssistencias');
  }

  getAllSeguros() {
    return this.restService.get('tiposSeguro');
  }

  getAllAssistencias() {
    return this.restService.get('assistencias');
  }

  getListaNomeMae(idProposta: string, idCliente: string) {
    return this.restService.get(`cliente/listNomeMae/${idProposta}/${idCliente}`);
  }

  getOriginadorLeads() {
    return this.leadsOnlineRestService.get('agent/originator').toPromise();
  }

  finalizarSimulacao(payload: any) {
    return this.restService.post('veiculo/finalizarSimulacao', payload);
  }

  finalizarSimulacaoLeads(payload: any) {
    return this.leadsOnlineRestService.post('vehicle/finishSimulation', payload);
  }

  verificarCpf(cpf: string) {
    return this.restService.get(`cliente/verificar/${cpf}`);
  }

  recuperarDadosFicha(cpf: string, idlojista) {
    return this.restService.get(`veiculo/recuperar-dados/${cpf}?lojista=${idlojista}`);
  }
  // Serviços do Cadastro de Nova Ficha ---------------------------------------------------------------------------

  sendSosObservacao(body: any): Observable<any> {
    return this.restService.post('proposta/pushObservacaoSos/', body);
  }

  prosseguirNegociacao(body: any): Observable<any> {
    return this.restService.post('proposta/pushProssegueNegociacao', body);
  }

  sendMotivoDesistencia(body: any): Observable<any> {
    return this.restService.post('proposta/pushMotivoDesistencia', body);
  }

  pushDocumentacaoEnviada(body: any): Observable<any> {
    return this.restService.post('proposta/pushDocumentacaoEnviada', body);
  }

  pushRespostaSos(body: any): Observable<any> {
    return this.restService.post('proposta/pushRespostaSos', body);
  }

  pushAvalista(body: any): Observable<any> {
    return this.restService.post('proposta/pushAvalista', body);
  }

  // Serviços de Assinatura de Documentos ------------------------------------------------------------------------

  getDataSign(idProposta: string): Observable<any> {
    return this.restService.get(`proposta/${idProposta}/dados-assinatura`);
  }

  generatePreContract(dadosEnvio: DadosEnvio): Observable<any> {
    return this.restService.post('proposta/gerar-pre-contrato', dadosEnvio);
  }

  resendSign(obj): Observable<any> {
    return this.restService.post('proposta/reenviar-assinatura', { idProposta: obj.propostaId, tipoComunicacao: obj.tpComunicacao });
  }

  confirmContactDetails(dadosAssinatura: ConfirmaDadosAssinatura): Observable<any> {
    return this.verificacaoRestService.post('cliente/confirmar-dados-contato', dadosAssinatura);
  }

  getLojistaEscolheSeguro(): Observable<any> {
    return this.restService.get('lojista/alteraSeguro');
  }

}
