import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-btn-small',
  templateUrl: './btn-small.component.html',
  styleUrls: ['./btn-small.component.css']
})
export class BtnSmallComponent {
  @Input() title: string;
  @Input() color: string;
  @Input() isDisabled: boolean = false;
}
