import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ficha-timeline-documentacao-enviada',
  templateUrl: './ficha-timeline-documentacao-enviada.component.html',
  styleUrls: ['./ficha-timeline-documentacao-enviada.component.css']
})
export class FichaTimelineDocumentacaoEnviadaComponent {

  @Input() timeline: any;
  @Input() timelineActive: boolean;
}
