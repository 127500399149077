import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { timeout } from 'rxjs/operators';
import * as toastr from 'toastr';

import { Proposta } from '../../../shared/models/proposta';
import { TimelineSosVO } from '../../../shared/models/timelineVO';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { LoginStorage } from './../../../../login/login.storage';
import { DocumentosComponent } from './documentos/documentos.component';
import { ObservacaoComponent } from './observacao/observacao.component';

@Component({
  selector: 'app-sos',
  templateUrl: './sos.component.html'
})
export class SosComponent {
  @ViewChild(ObservacaoComponent, { static: false }) private observacao: ObservacaoComponent;
  @ViewChild(DocumentosComponent, { static: false }) private documentosComponent: DocumentosComponent;

  @Input() proposta: Proposta;
  @Input() sos: TimelineSosVO;
  @Input() local: number;
  @Input() dataLimiteAtendimento?: Date;
  @Output() sosEnviado = new EventEmitter<boolean>();

  toggleOn = false;

  constructor(
    private router: Router,
    private loginStorage: LoginStorage,
    private service: CdcLojaService
  ) { }

  async sendSos() {
    if (!this.dataLimiteAtendimento || !moment(this.dataLimiteAtendimento).isBefore(moment())) {
      if (!this.validaEnvioSOS()) {
        return;
      }

      if (this.hasDocumentos()) {
        await this.documentosComponent.fileUpload();
      }

      this.pushRespostaSos()
        .pipe(
          timeout(3000)
        )
        .subscribe(
          () => this.handleEnvioSosFinished('SOS Respondido com sucesso!', true),
          () => this.handleEnvioSosFinished('Ocorreu um erro ao responder o SOS, tente novamente.', false)
        );
    } else {
      toastr.error('Esta ficha foi recusada após expiração do prazo de atendimento da SOS', 'Atenção!');
      this.router.navigateByUrl('/ficha');
    }
  }

  private handleEnvioSosFinished(mensagem: string, enviado: boolean) {
    const toastrMessage = enviado ? toastr.success : toastr.error;
    toastrMessage(mensagem);

    if (enviado) {
      this.sosEnviado.next(enviado);
    }
  }

  validaEnvioSOS(): boolean {
    if (this.hasObservacao() && !this.observacao.valid) {
      return false;
    }

    if (this.hasDocumentos() && !this.documentosComponent.validaEnvio()) {
      return false;
    }

    return true;
  }

  hasDocumentos(): boolean {
    return this.sos.motivos.filter(e => e.idDocto).length > 0;
  }

  hasObservacao(): boolean {
    return this.sos.motivos.filter(e => !e.idDocto).length > 0;
  }

  pushRespostaSos() {
    return this.service.sendRespostaSos(this.proposta.id, this.observacao.observacaoSos, this.local);
  }

  get isLojista(): boolean {
    return this.loginStorage.usuario.tipoUsuarioTO.id === 7;
  }

  get hasSos() {
    return (this.sos.motivos || []).length > 0;
  }
}
