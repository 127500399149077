import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';

import * as toastr from 'toastr';
import { CdcLojaService } from '../../services/cdc-loja.service';

@Component({
  selector: 'app-modal-alterar-email',
  templateUrl: './modal-alterar-email.component.html',
  styleUrls: ['./modal-alterar-email.component.css']
})
export class ModalAlterarEmailComponent implements OnInit, OnDestroy {

  form: FormGroup;
  onChangeEmail = new EventEmitter();
  propostaId: number;
  service: CdcLojaService;

  alive = true;

  constructor(
    private instance: BsModalRef,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: []
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  close() {
    this.instance.hide();
  }

  save() {
    const email = this.form.get('email').value;
    this.service
      .changeEmail({ email, propostaId: this.propostaId })
      .then(() => {
        this.onChangeEmail.emit({ email });
        this.instance.hide();
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }
}
