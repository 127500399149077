import { Component, EventEmitter, Input, Output } from '@angular/core';

import { OpcoesPagamento } from '../contrato-cessao';

@Component({
  selector: 'app-single-approval',
  templateUrl: './single-approval.component.html',
  styleUrls: ['./single-approval.component.scss']
})
export class SingleApprovalComponent {

  @Input() opcoesPagamento: OpcoesPagamento[];

  @Output() selectedIndex = new EventEmitter<[number, number]>();
  index: number = 0;

  onSelect(index: number): void {
    this.index = index;
    this.selectedIndex.emit([0, index]);
  }

}
