import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { debounceTime, distinctUntilChanged, finalize, takeWhile } from 'rxjs/operators';

import { appSettings } from '../../../../../../../../../environments/app.setings';
import { TokenNotReceivedInfoModalComponent } from '../../../../../../../../shared/timer-token/token-not-received/token-not-received-info-modal.component';
import { CdcLojaService } from '../../../../../../../cdc-loja/services/cdc-loja.service';
import { ClienteTelefone } from '../../../../../../../shared/models/cliente';
import { Proposta } from '../../../../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../../../../shared/states/proposta-state.service';

export enum TokenStatus {
  SUCCESS = 1,
  FAIL = 2,
  SENT = 3
}
@Component({
  selector: 'app-validacao-celular',
  templateUrl: './validacao-celular.component.html',
  styleUrls: ['./validacao-celular.component.scss']
})
export class ValidacaoCelularComponent {
  @Input() butonText = 'Enviar Token para o celular';
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSuccessValidate = new EventEmitter<void>();

  readonly tokenStatus = TokenStatus;
  tokenStatusAtual: TokenStatus;
  timeLeft;
  private timer;
  showLoader: boolean = false;
  enviado: boolean = false;
  solicitou: boolean = false;
  tokenForm: FormGroup;

  alive = true;
  proposta: Proposta;
  celularValidado = false;

  constructor(private fb: FormBuilder, private modal: BsModalService, private state: PropostaStateService, private cdcLojaService: CdcLojaService) { }

  ngOnInit() {
    this.proposta = this.state.getLast();
    this.solicitou = false;
    this.initForms();
    this.checkCelularValidado();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  initForms(): void {
    this.tokenForm = this.fb.group({
      codigoConfirmacao: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.tokenForm.get('codigoConfirmacao').valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeWhile(() => this.alive),
      )
      .subscribe((value: string) => {
        if (value.length === 6) {
          this.validarToken(value);
        }
      });
  }

  private validarToken(codigoConfirmacao: string) {
    this.showLoader = true;
    if (codigoConfirmacao) {
      const celularCliente = this.celularCliente;
      this.cdcLojaService
        .verificarToken(`${this.proposta.id}`, codigoConfirmacao, `${celularCliente.ddd}${celularCliente.nrTelefone}`).pipe(
          finalize(() => {
            this.showLoader = false;
          })).subscribe(
            () => {
              this.onTokenValidadoComSucesso();
            },
            () => this.tokenStatusAtual = this.tokenStatus.FAIL);
    }
  }

  private onTokenValidadoComSucesso(token?) {
    if (token) {
      this.enviado = true;
      this.solicitou = true;
      this.tokenForm.patchValue({ token });
    }
    this.tokenStatusAtual = this.tokenStatus.SUCCESS;
    this.tokenForm.controls['codigoConfirmacao'].disable();
  }

  solicitarToken() {
    const celularCliente = this.celularCliente;
    if (!celularCliente) {
      return;
    }
    this.showLoader = true;
    this.solicitou = true;

    this.cdcLojaService.enviarSms({
      proposta: {
        cliente: {
          telefones: [{
            ddd: celularCliente.ddd,
            nrTelefone: celularCliente.nrTelefone
          }]
        },
        id: this.proposta.id,
        parceiro: this.proposta.parceiro
      }
    }).pipe(finalize(() => {
      this.showLoader = false;
    })).subscribe(
      ({ timeLeft }) => {
        this.tokenStatusAtual = this.tokenStatus.SENT;
        this.initTimer(timeLeft);
      },
      (error) => {
        if (error.data) {
          this.initTimer(error.data);
        }
      });
  }

  private initTimer(time: number) {
    this.timeLeft = time;
    this.timer = setInterval(
      () => {
        this.enviado = true;
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.stop();
          this.enviado = false;
        }
      },
      1000
    );
  }

  private stop() {
    clearInterval(this.timer);
  }

  abrirModalDeInformacao() {
    return this.modal.show(TokenNotReceivedInfoModalComponent, { ...appSettings.MODAL_PARAMS });
  }

  get celularCliente(): ClienteTelefone {
    return this.proposta.cliente.telefones.find(telefone => telefone.categoriaTelefone === 'CELULAR' && telefone.tipoTelefone === 'PROPRIO');
  }

  continuar() {
    this.onSuccessValidate.emit();
  }

  private checkCelularValidado() {
    this.cdcLojaService.checkCelularValidado(this.proposta.id).subscribe((response) => {
      this.celularValidado = response.validado;

      if (this.celularValidado) {
        this.onSuccessValidate.emit();
      }
    });
  }

}
