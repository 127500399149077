import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator], [appEmailValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true
    }
  ]
})

export class EmailValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    if (this.emailValidator(c.value)) {
      return null;
    }

    return { message: 'E-mail Inválido' };
  }

  emailValidator(emailInput: string) {
    if (!emailInput || emailInput.length === 0) {
      return true;
    }

    const email = emailInput.toLowerCase();
    // tslint:disable-next-line:max-line-length
    const regex = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])');
    const isValid = regex.test(email);
    let preValidate = [''];
    // check if '@' exists
    if (email.indexOf('@') !== -1) {
      const emailSplit = email.split('@');
      // check if '.' exists
      if (email.indexOf('.') !== -1) {
        // takes value between '@' and '.' to validate
        preValidate = emailSplit[1].split('.', 1);
      }
    }
    return preValidate && preValidate[0].length >= 2 ? isValid : false;
  }
}
