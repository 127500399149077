import { appSettings } from '../../../../environments/app.setings';
import { MotivoDesistenciaComponent } from './motivo-desistencia/motivo-desistencia.component';
import { ConfirmModalComponent } from '../../../shared/modais/confirm-modal/confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { TimelinePropostaVO, TimelineStatusVO } from '../../shared/models/timelineVO';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeWhile, take } from 'rxjs/operators';

import * as toastr from 'toastr';
import { CreditoPessoalService } from '../services/cp.service';
import { DialogService } from '../../../services/dialog/dialog.service';

@Component({
  selector: 'app-cp-timeline',
  templateUrl: './cp-timeline.component.html',
})
export class CreditoPessoalTimelineComponent implements OnInit, OnDestroy {

  private classes = {
    1: 'parcial',
    2: 'inverted direct-analysis',
    3: 'inverted analysis',
    4: 'approved',
    5: 'required-docs',
    17: 'required-docs',
    6: 'inverted send-docs',
    7: 'inverted analysis-docs',
    10: 'inverted scheduled',
    11: 'central paid',
    12: 'central reproved',
    13: 'central reproved'
  };

  fichaTimeline: TimelinePropostaVO;
  isBuscando = false;
  private propostaId: number;
  private callbackEvent: string;

  constructor(
    private router: Router,
    private service: CreditoPessoalService,
    private route: ActivatedRoute,
    private modal: BsModalService,
    private dialogService: DialogService
  ) {
    this.initObservableParams();
  }

  private initObservableParams() {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.propostaId = params.id;
        this.callbackEvent = params.event;
        this.buscarFicha(true);
      });
  }

  ngOnInit() {
    console.log('criando CdcLojaTimelineComponent');
  }

  ngOnDestroy() {
    console.log('destuindo CdcLojaTimelineComponent');
  }

  async buscarFicha(primeiraBusca) {
    this.isBuscando = true;

    try {
      const ficha = await this.service.getTimeline(this.propostaId).toPromise();
      const timelineAprovada = ficha.timeline.filter(t => t.idStatus === 4)[0];

      if (timelineAprovada.informacaoComplementar !== null && timelineAprovada.informacaoComplementar !== undefined) {
        /* tslint:disable */
        if (timelineAprovada.informacaoComplementar.propostaAutomatica === true) {
          const promotores = await this.service.listarPromotores(ficha.proposta.lojista.id).toPromise();
          promotores.push(this.vendedorNaoCadastrado());
          ficha.promotor = promotores;
        }
        /* tslint:enable */
      }

      this.popularTimeline(ficha, primeiraBusca);
      // Consulta Status Analise.
    } catch (error) {
      toastr.error('Não foi possível carregar timeline, aguarde um minuto e atualize a página.');
    } finally {
      this.isBuscando = false;
    }
  }

  private getStatusAnalise(statusAnalise: any) {
    const status = statusAnalise.statusAnaliseCpf;
    status === 'RECUSADO' && toastr.error('Proposta recusada. Fora da política interna!');
  }

  private popularTimeline(ficha: TimelinePropostaVO, primeiraConsulta?: boolean) {
    this.isBuscando = false;
    this.fichaTimeline = ficha;
    this.fichaTimeline.timeline = this.fichaTimeline.timeline.filter(card => !!this.classes[card.idStatus]);

    // aguarda até docusign marcar documento como assinado
    const timelineAssinatura = ficha.timeline.filter(t => t.idStatus === 17)[0];
    if (
      primeiraConsulta
      && this.callbackEvent === 'signing_complete'
      && !timelineAssinatura.finalizado
      && !timelineAssinatura.informacaoComplementar.assinadoDigitalmente) {
      this.aguardarAssinatura(1);
    }
  }

  private async aguardarAssinatura(tentativa: number) {
    const maximoTentativas = 10;
    try {
      this.isBuscando = true;
      const verify = await this.service.verificarContratoAssinado(this.fichaTimeline.proposta.contratoId);

      if (verify.assinado) {
        this.isBuscando = false;
        await this.buscarFicha(false);
      } else if (tentativa < maximoTentativas) {
        setTimeout(() => this.aguardarAssinatura(tentativa + 1), 5000);
      }
    } catch (error) {
      console.log(error);
      if (tentativa < maximoTentativas) {
        setTimeout(() => this.aguardarAssinatura(tentativa + 1), 5000);
      }
    }

    if (tentativa >= maximoTentativas) {
      this.isBuscando = false;
      toastr.error('Tente atualizar a página', 'Não foi possível verificar assinatura do contrato');
    }
  }

  className(timeline: TimelineStatusVO) {
    let prefix = '';
    if (timeline.rejeitado) {
      prefix = 'step-rejeitado';
    } else if (timeline.finalizado) {
      prefix = 'step-ok';
    } else if (timeline.seqTimeline) {
      prefix = 'actual';
    } else {
      prefix = 'disabled';
    }

    return `${prefix} cp-cdcl ${this.classes[timeline.idStatus]}`;
  }

  irParaFicha() {
    this.validarPropostaWebLogic().then((status) => {
      if (status) {
        this.router.navigate([`/produtos/cp/fichas/${this.propostaId}`]);
      }
    });
  }

  async validarPropostaWebLogic(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const statusAnalise = await this.service.getWebLogicStatusAnalise(this.propostaId).toPromise();
        this.getStatusAnalise(statusAnalise);
        resolve(true);
      } catch (error) {
        if (error.status === 404) {
          this.dialogService.info({
            title: 'Proposta sendo analisada',
            body: 'Proposta esta sendo analisada pela crivo, aguarde.',
            classList: ['modal-md'],
            textClass: 'text-warning',
            iconClass: 'text-warning fa fa-exclamation-triangle',
            callbackOK: () => this.router.navigateByUrl('/ficha')
          });
        }
        reject(false);
      }
    });
  }

  onAction({ action }) {
    switch (action) {
      case 'navigate_to_ficha': return this.irParaFicha();
      case 'finalizar_ficha': return this.finalizaFicha();
      case 'desistir_ficha': return this.abrirModalDesistir();
      case 'reload': return this.buscarFicha(false);
      case 'push_documentacao': return this.pushDocumentacao();
      case 'push_assinatura': return this.pushFormalizacao();
    }
  }

  abrirModalDesistir() {
    const modal = this.modal.show(MotivoDesistenciaComponent, appSettings.MODAL_PARAMS);
    const motivoDesistencia = <MotivoDesistenciaComponent> modal.content;
    motivoDesistencia.event
      .pipe(takeWhile(() => motivoDesistencia.alive))
      .subscribe(motivo => this.desistirFicha(motivo));
  }

  desistirFicha(motivo: number) {
    this.service
        .desistirFicha(this.fichaTimeline.proposta, motivo)
        .pipe(take(1))
        .subscribe(
          res => this.buscarFicha(false),
          error => toastr.error('Tente novamente mais tarde.', 'Não foi possível salvar a informação.')
        );
  }

  pushDocumentacao() {
    this.service
      .pushDocumentacao(this.fichaTimeline.proposta.id)
      .pipe(take(1))
      .subscribe(
        res => this.buscarFicha(false),
        error => toastr.error('Tente novamente mais tarde.', 'Não foi possível confirmar a documentação.')
      );
  }

  pushFormalizacao() {
    this.service
      .pushFormalizacao(this.fichaTimeline.proposta.id)
      .pipe(take(1))
      .subscribe(
        res => this.buscarFicha(false),
        error => toastr.error('Tente novamente mais tarde.', 'Não foi possível enviar para formalização.')
      );
  }

  async finalizaFicha() {
    this.isBuscando = true;
    const proposta = this.fichaTimeline.proposta;
    try {
      await this.service.finalizaFicha(proposta).toPromise();
      this.buscarFicha(false);
    } catch (error) {
      toastr.error('Tente novamente mais tarde.', 'Não foi possível gerar o contrato.');
      console.log(error);
    } finally {
      this.isBuscando = false;
    }
  }

  get isResultadoParcialCurrent(): boolean {
    return this.fichaTimeline
      && this.fichaTimeline.timeline
      && this.fichaTimeline.timeline
              .filter(e => e.seqTimeline)
              .map(e => e.idStatus)
              .filter(e => [2, 3, 4, 5, 6, 7, 8, 9, 10].includes(e))
              .length === 0;
  }

  openModalExcluirFicha() {
    const modal = this.modal.show(ConfirmModalComponent, appSettings.MODAL_PARAMS);
    const confirm = <ConfirmModalComponent>modal.content;

    confirm.showModal({
      titulo: 'Excluir Ficha',
      mensagem: 'Deseja realmente excluir a Ficha?'
    });

    confirm.confirmValue
      .pipe(
        takeWhile(() => confirm.alive)
      )
      .subscribe(value => {
        if (value) {
          this.excluirFicha();
        }
      });

  }

  excluirFicha() {
    this.service.excluirFicha(this.propostaId)
      .toPromise()
      .then(
        response => {
          toastr.success('Ficha excluida com sucesso');
          this.router.navigate(['/ficha']);
        },
        error => toastr.error(error, 'Falha ao excluir')
      );
  }

  private vendedorNaoCadastrado() {
    return { id: 0, nome: 'Não cadastrado' };
  }

}
