import { Referencia } from './referencia';
import { DadosProfissionais } from '../../../shared/dados-profissionais/model/dados-profissionais';
import { Endereco } from './endereco-cliente';
import { TelefoneCliente } from './telefone-cliente';
import { getFoneEmpresa, allTelefones, formatDateMiliseconds } from '../../../shared/helper/utils';

export class Avalista {
  public id: number;
  public cpf: string;
  public nome = '';
  public renda: number;
  public dataNascimento;
  public email: string;
  public nomeMae: any;
  public nomePai = '';
  public idTipoDocumento: number;
  public rgIe: string;
  public rgIeUf: string;
  public rgIeData;
  public grauParentesco: string;
  public tipoEnderecoCorrespondencia;
  public rgIeEmissor: string;
  public idEstadoCivil: string;
  public idNacionalidade: number;
  public naturalDe: string;
  public naturalDeUf: string;
  public clientePpe = 'N';
  public valorPatrimonio: number;
  public dadoProfissional = new DadosProfissionais();
  public avalistaReferencia = new Referencia();
  public enderecos = new Endereco();
  public enderecoCorrespondencia = new Endereco();
  public telefones = new TelefoneCliente();

  get proposta() {
    return [
      {
        id: this.id,
        cpf: this.cpf.replace(/\D/g, ''),
        nome: this.nome,
        renda: this.renda,
        email: this.email,
        nomeMae: this.nomeMae,
        nomePai: this.nomePai,
        idTipoDocumento: this.idTipoDocumento,
        rgIe: this.rgIe,
        rgIeUf: this.rgIeUf,
        rgIeDt: formatDateMiliseconds(this.rgIeData),
        grauParentesco: this.grauParentesco,
        rgIeEmissor: this.rgIeEmissor,
        idEstadoCivil: this.idEstadoCivil,
        idNacionalidade: this.idNacionalidade,
        naturalDe: this.naturalDe,
        naturalDeUf: this.naturalDeUf,
        clientePpe: this.clientePpe ? this.clientePpe : 'N',
        valorPatrimonio: this.valorPatrimonio,
        telefones: allTelefones(this.telefones),
        enderecos: this.enderecosPayload(this.enderecos, this.enderecoCorrespondencia),
        tipoEnderecoCorresp: this.tipoEnderecoCorrespondencia,
        dadoProfissional: {
          idClasseProfissional: this.dadoProfissional.idClasseProfissional,
          idProfissao: this.dadoProfissional.idProfissao,
          nomeEmpresa: this.dadoProfissional.nomeEmpresa,
          telefone: getFoneEmpresa(this.dadoProfissional.telefoneEmpresa),
          endereco: this.dadoProfissional.empresa.endereco
        },
        clienteReferencia: {
          nome: this.avalistaReferencia.nome,
          idGrauRelacionamento: this.avalistaReferencia.idGrauRelacionamento,
          telefone: getFoneEmpresa(this.avalistaReferencia.telefone),
        },
      },
    ];
  }

  enderecosPayload(...data) {
    return data
      .filter(endereco => endereco.idTipoEndereco)
      .map(item => {
        return {
          idTipoEndereco: item.idTipoEndereco,
          uf: item.uf,
          cidade: item.cidade,
          endereco: item.endereco,
          bairro: item.bairro,
          numero: item.numero,
          complemento: item.complemento,
          cep: item.cep,
        };
      });
  }
}
