import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { OmniVehicleReportRestService } from '../../omni-rest/omni-vehicle-report/omni-vehicle-report-rest.service';
import { Report } from '../../shared/models/report/report.model';

@Injectable()
export class ReportService {
  constructor(
    private restService: OmniVehicleReportRestService
  ) {}

  recordVehicleAccessories(): Observable<any> {
    return this.restService.get('vehicle/accessory');
  }

  recordVehicleStates(): Observable<any> {
    return this.restService.get('vehicle/state');
  }

  recordReport(proposalId: number): Observable<any> {
    return this.restService.get(`report/${proposalId}`);
  }

  createReport(report: Report): Observable<any> {
    return this.restService.post('report', { reportDTO: report });
  }

  recordReportInfo(proposalId: number): Observable<any> {
    return this.restService.get(`report/${proposalId}/init`);
  }
}
