import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

import { Parcela } from '../parcela/parcela';
import { PropostaStateService } from '../../../../../shared/states/proposta-state.service';
import { Proposta } from '../../../../../shared/models/proposta';

@Component({
  selector: 'app-retencao',
  templateUrl: './retencao.component.html',
  styles: [`
    .modal-footer {
      padding: 30px 40px;
      border-top: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
    }
    .bt-back {
      padding: 8px 25px;
    }
    .modal-header {
      border-bottom: 1px solid #f2f2f2;
    }
    .modal-body {
      padding: 25px 20px;
      font-size: 18px;
    }
    .mt-0 {
      margin-top: 0;
    }
    .mb-0 {
      margin-bottom: 0;
    }
  `]
})
export class RetencaoComponent implements OnInit {
  parcela: Parcela = new Parcela();
  proposta: Proposta;

  constructor(
    private bsModalRef: BsModalRef,
    private state: PropostaStateService
  ) { }

  ngOnInit() {
    this.proposta = this.state.getLast();
  }

  close() {
    this.bsModalRef.hide();
  }

  fillParcela(parcela: Parcela) {
    this.parcela = parcela;
  }
}
