import { Push } from './push';

export class Configuracao {
  pushPropostaAprovada = new Push(1, true, 'Proposta Aprovada', false);
  pushPropostaRecusada = new Push(2, true, 'Proposta Recusada', false);
  pushResultadoParcial = new Push(3, true, 'Resultado Parcial', true);
  pushSos = new Push(4, true, 'SOS', true);

  get configuracoes(): any {
    return {
      tiposPush: [
        this.pushSos,
        this.pushResultadoParcial,
        this.pushPropostaAprovada,
        this.pushPropostaRecusada,
      ],
    };
  }

  refreshConfiguracoes(configuracoes: Push[]) {
    this.pushPropostaAprovada.habilitaPush = this.isHabilitaPush(
      1,
      configuracoes,
    );
    this.pushPropostaRecusada.habilitaPush = this.isHabilitaPush(
      2,
      configuracoes,
    );
  }

  private isHabilitaPush(idTipoPush: number, configuracoes: Push[]) {
    if (configuracoes && configuracoes.length > 0) {
      const found = configuracoes.find(
        (push: Push) => push.idTipoPush === idTipoPush,
      );
      return (found && found.habilitaPush) || false;
    }
    return false;
  }
}
