import { Injectable } from '@angular/core';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';

@Injectable()
export class DadosFavorecidoService {

  constructor(private _restService: OmniMaisRestService) { }

  salvarDadosFavorecido(dadosFavorecido: any) {
    return this._restService.post('proposta/incluirDadosFavorecido/', dadosFavorecido);
  }
}
