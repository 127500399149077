import { TelefoneNumero } from './telefone-numero';

export class ClienteReferencia {
  nome: string;
  telefone: TelefoneNumero = new TelefoneNumero();

  set $telefone(value: string) {
    this.telefone.$dddNumero = value;
  }

  get $telefone(): string {
    return this.telefone.$dddNumero;
  }
}
