
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, take } from 'rxjs/operators';

import { ModalMessageComponent } from './../shared/modais/modal-message/modal-message.component';
import { Configuracao } from './configuracao';
import { ConfiguracaoService } from './configuracao.service';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.css'],
})
export class ConfiguracaoComponent implements OnInit {
  configuracao: Configuracao = new Configuracao();
  buscando = false;
  salvando = false;

  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  constructor(
    private configuracaoService: ConfiguracaoService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.buscarConfiguracoes();
  }

  private buscarConfiguracoes() {
    this.buscando = true;
    this.configuracaoService
      .buscarPushPorUsuario().pipe(
        finalize(() => (this.buscando = false)),
        take(1))
      .subscribe(res => this.configuracao.refreshConfiguracoes(res));
  }

  onClickSalvar() {
    this.salvando = true;
    this.configuracaoService
      .associarPushUsuario(this.configuracao.configuracoes).pipe(
        finalize(() => (this.salvando = false)),
        take(1))
      .subscribe(
        () => this.router.navigateByUrl('/ficha'),
        () =>
          this.modalMessage.show(
            'Falha na Comunicação, verifique sua conexão.',
          ),
      );
  }
}
