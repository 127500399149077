import {
  Directive,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[uppercase],[uppercase][ngModel]',
  host: {
    '(input)': 'input($event.target.value, $event.target)',
    '(blur)': 'focusout()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UpperCaseDirective),
      multi: true,
    },
  ],
})
export class UpperCaseDirective {
  @Input() charOnly: boolean;
  @Input() alphaNumOnly: boolean;
  @Input() justNumber: boolean;
  @Input() appNomeEmpresa: boolean;

  constructor(
    @Inject(Renderer2) private renderer: Renderer2,
    @Inject(ElementRef) private element: ElementRef
  ) {}

  writeValue(value: any) {
    if (value === undefined || value === null) {
      this.propagateChange(null);
      this.renderer.setProperty(this.element.nativeElement, 'value', '');
    } else {
      this.input(value);
    }
  }

  propagateChange = (_: any) => {
    // This is intentional
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public onTouched: any = () => {
    // This is intentional
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  input(val, event?) {
    let retVal = val.toUpperCase();
    if (this.charOnly) {
      retVal = retVal.replace(/[^\w\s/]|[\\//"]|_|\d/gi, '');
    }
    if (this.alphaNumOnly) {
      retVal = retVal
        .replace(new RegExp(/[ÁÂÀÄÃ]/, 'g'), 'A')
        .replace(new RegExp(/[ÉÊÈË]/, 'g'), 'E')
        .replace(new RegExp(/[ÍÎÌÏ]/, 'g'), 'I')
        .replace(new RegExp(/[ÓÔÒÖÕ]/, 'g'), 'O')
        .replace(new RegExp(/[ÚÛÙÜ]/, 'g'), 'U')
        .replace(new RegExp(/[Ç]/, 'g'), 'C')
        .replace(new RegExp(/[Ñ]/, 'g'), 'N')
        .replace(new RegExp(/[^A-Z0-9 ]/, 'g'), '');
    }
    if (this.justNumber) {
      retVal = retVal.replace(/[^0-9]/g, '');
    }
    if (this.appNomeEmpresa) {
      retVal = retVal.replace(/[^\w\s]|[\\//"]|_|[|,"/]/gi, '');
    }
    this.propagateChange(retVal);
    this.renderer.setProperty(this.element.nativeElement, 'value', retVal);

    setTimeout(() => {
      if (event !== undefined) {
        event.setSelectionRange(retVal.length, retVal.length);
      }
    },         0);
  }

  focusout() {
    this.onTouched();
  }
}
