export class CategoriaSimulacao {

  private id: string;
  private descricao: string;

  constructor(obj) {
    this.id = obj.id;
    this.descricao = obj.descricao;
  }

  getId(): string {
    return this.id;
  }

  getDescricao(): string {
    return this.descricao;
  }
}
