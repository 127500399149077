import { ParametroRendaProduto } from './parametro-renda-produto';

export class NovaProposta {
  tipoProduto: string;
  produto: string;
  banco: string;
  operacao: string;
  solicitante: string;
  avalista: boolean;
  parametroRendaProduto?: ParametroRendaProduto;
}
