import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ClasseProfissional } from '../../shared/dados-profissionais/model/classe-profissional';
import { OmniGeralRestService } from './../../omni-rest/omni-geral/omni-geral-rest.service';
@Injectable()
export class ClasseProfissionalService {

  constructor(private _restService: OmniGeralRestService) { }

  classes(): Observable<ClasseProfissional[]> {
    return this._restService.get('classes-profissionais');
  }
}
