import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaskModule } from 'soft-angular-mask';
import { DirectivesModule } from '../directives/directives.module';
import { TextDirectivesModule } from '../directives/texto/text.directives.module';
import { BreadcrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { SharedModule } from '../shared/shared.module';
import { SimuladorComponent } from './simulador.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TextDirectivesModule,
    MaskModule,
    SharedModule,
    DirectivesModule,
    BreadcrumbModule
  ],
  declarations: [SimuladorComponent]
})
export class SimuladorModule { }
