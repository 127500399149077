import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

import { Parcela } from '../parcela/parcela';
import { Proposta } from '../../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../../shared/states/proposta-state.service';

@Component({
  selector: 'app-retencao',
  templateUrl: './retencao.component.html',
  styleUrls: ['./retencao.component.css']
})
export class RetencaoComponent implements OnInit {
  parcela: Parcela = new Parcela();
  proposta: Proposta;
  constructor(
    private bsModalRef: BsModalRef,
    private state: PropostaStateService
  ) {}

  ngOnInit() {
    this.proposta = this.state.getLast();
  }

  close() {
    this.bsModalRef.hide();
  }

  fillParcela(parcela: Parcela) {
    this.parcela = parcela;
  }
}
