import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-dicas-foto-verificacao',
  templateUrl: './modal-dicas-foto-verificacao.component.html',
  styleUrls: ['./modal-dicas-foto-verificacao.component.scss']
})
export class ModalDicasFotoVerificacaoComponent {

  constructor(
    private modalInstance: BsModalRef,
  ) { }

  close() {
    this.modalInstance.hide();
  }

}
