import { Component, Input, Output, EventEmitter } from '@angular/core';

import { appSettings } from './../../../../../environments/app.setings';
import { Proposta } from './../../../shared/models/proposta';
import { TimelineStatusVO } from '../../../shared/models/timelineVO';
import { OmniFacilUploadRestService } from '../../../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { LoginStorage } from '../../../../login/login.storage';

@Component({
  selector: 'app-card-timeline',
  templateUrl: './card-timeline.component.html',
  styleUrls: ['./card-timeline.component.scss'],
})
export class CardTimelineComponent {

  STATUS_RECUSADO: number = 2;

  @Input() proposta: Proposta;
  @Input() timeline: TimelineStatusVO;
  @Input() nextCard: TimelineStatusVO;
  @Output() executar: EventEmitter<any> = new EventEmitter();

  acceptFiles = appSettings.ACCEPT_IMAGES_AND_PDF;
  recusada: boolean = false;
  mensagem: string;

  acoes = {
    FINALIZAR_FICHA: 'Sim',
    DESISTIR_FICHA: 'Não'
  };

  constructor(
    private omniRestService: OmniFacilUploadRestService,
    private loginStorage: LoginStorage
  ) { }

  get permiteLinkParaFicha(): boolean {
    return this.proposta.status !== this.STATUS_RECUSADO && this.timeline.seqTimeline
      && this.nextCard && !this.nextCard.seqTimeline
      && ['RESULTADO PARCIAL', 'EM PREENCHIMENTO', 'SIMULAÇÃO EM ANDAMENTO', 'AGUARDANDO BIOMETRIA', 'PRONTO PARA PREENCHIMENTO'].includes(this.timeline.tituloStatus);
  }

  get localSos(): number {
    if (this.timeline.idStatus === 3) {
      return 1;
    }

    return 2;
  }

  get isRecusada() {

    if (Number(this.timeline.idStatus) === 12) {
      this.recusada = true;
    } else {
      this.recusada = false;
    }

    return this.recusada;
  }

  irParaFicha() {
    this.executarAcao('navigate_to_ficha', this.timeline.tituloStatus);
  }

  reload() {
    this.executarAcao('reload');
  }

  private postOmniDoc(file: File, idDocto: number) {
    const proposta = { id: Number(this.proposta.id), cliente: this.proposta.cliente, origem: 6 };
    return this.omniRestService
      .post(file, proposta, idDocto)
      .toPromise();
  }

  uploadAndPush() {
    if (!this.enableSendBtn()) return;

    this.mensagem = 'Enviando arquivos...';

    const docsToUpload = this.timeline.informacaoComplementar.documentosPendentes.map(doc => doc.documentosAnexados);

    const promiseArray: Promise<any>[] = [];

    docsToUpload.forEach((doc) => {
      doc.forEach(async (file) => {
        promiseArray.push(this.postOmniDoc(file.file, file.idDocto));
      });
    });

    Promise.all(promiseArray).then(() => {
      delete this.mensagem;
      this.executarAcao('push_documentacao');

    }).catch(error => {
      this.mensagem = 'Tente novamente mais tarde. Não foi possível enviar a documentação.';
      console.error(error);
    });

  }

  enableSendBtn() {
    if (!this.timeline.informacaoComplementar) return false;
    if (!this.timeline.informacaoComplementar.documentosPendentes) return false;
    return this.docsRestantes === 0;
  }

  executarAcao(acao: string, args?: any) {
    if (!this.mensagem) this.executar.emit({ args, action: acao.toLowerCase() });
  }

  get docsRestantes(): number {
    return this.timeline.informacaoComplementar.documentosPendentes.length - this.timeline.informacaoComplementar.contadorDocsEnviados;
  }

  get isParceiro() {
    if (this.loginStorage.usuario.parcerias.length > 0) {
      const parceiro = this.loginStorage.usuario.parcerias.filter(({ chave }) => chave && chave === 'DELL');
      return !!parceiro;
    }
    return false;
  }

}
