import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginStorage } from '../../login/login.storage';
import { LoaderService } from '../../shared/loader/loader.service';
import { OmniRestService } from '../omni-rest.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class OmniFacilRestService extends OmniRestService {

  constructor(http: HttpClient, loaderService: LoaderService, loginStorage: LoginStorage, router: Router) {
    super(http, loaderService, loginStorage, router);
  }

  getBaseUrl(): string {
    return environment.URL_OMNI_FACIL;
  }

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'omni-autenticacao': this.loginStorage.loginAndTokenAsJson
    };
  }

}
