import { Cliente } from '../cliente';
import { TelefoneCliente } from '../telefone-cliente';
import { Referencia } from '../referencia';
import { DadosProfissionais } from '../../../../shared/dados-profissionais/model/dados-profissionais';
import { Endereco } from '../endereco-cliente';
export class RecuperaCliente {
  private cliente = new Cliente();

  constructor(data) {
    this.cliente.id = data.id;
    this.cliente.cpf = data.cpf;
    this.cliente.dataNascimento = new Date(data.dataNascimento).toLocaleDateString('pt-BR');
    this.cliente.nome = data.nome;
    this.cliente.email = data.email;
    this.cliente.nomeMae = data.nomeMae;
    this.cliente.nomePai = data.nomePai;
    this.cliente.idTipoDocumento = (data.documento || {}).tipo;
    this.cliente.rgIe = (data.documento || {}).numero;
    this.cliente.rgIeEmissor = (data.documento || {}).emissor;
    this.cliente.rgIeUf = (data.documento || {}).uf;
    this.cliente.rgIeData = new Date((data.documento || {}).dataEmissao).toLocaleDateString('pt-BR');
    this.cliente.idEstadoCivil = (data.estadoCivil || {}).id;
    this.cliente.idNacionalidade = (data.nacionalidade || {}).id;
    this.cliente.naturalDe = (data.natural || {}).cidade;
    this.cliente.naturalDeUf = (data.natural || {}).uf;
    this.cliente.clientePpe = data.ppe;
    this.cliente.fatca = data.fatca;
    this.cliente.dadoProfissional = this.profissional(data.profissional);
    this.cliente.clienteReferencia = new Referencia(this.referencia(data.referencias));
    this.cliente.enderecos = this.endereco(data.enderecos);
    this.cliente.enderecoCorrespondencia = new Endereco(data.enderecos);
    this.cliente.telefones = new TelefoneCliente(data.telefones);

    if (data.profissional && data.profissional.length) {
      this.cliente.renda = data.profissional[0].salario;
    }
  }

  get $client() {
    return this.cliente;
  }
  profissional(data) {
    if (data) {
      let idClasseProfissional;
      let idProfissao;
      let nomeEmpresa;
      let telefone;
      let endereco;
      data.forEach(item => {
        if (item.classeProfissional) {
          idClasseProfissional = item.classeProfissional.id;
        }

        if (item.profissao) {
          idProfissao = item.profissao.id;
        }

        if (item.nome) {
          nomeEmpresa = item.nome;
        }

        if (item.telefoneEmpresa) {
          telefone = item.telefoneEmpresa;
        }

        if (item.endereco) {
          endereco = item.endereco;
        }
      });

      return DadosProfissionais.of(idClasseProfissional, idProfissao, nomeEmpresa, telefone, endereco);
    }
  }

  referencia(data) {
    if (data) {
      return data.forEach(item => {
        return {
          nome: item.nome,
          telefone: item.telefone,
          idGrauRelacionamento: item.tipo.id
        };
      });
    }
    return null;
  }

  endereco(data) {
    if (data) {
      data.forEach(item => {
        if (item.tipo.id === 2) {
          this.cliente.dadoProfissional.empresa.endereco = item;
          this.cliente.dadoProfissional.empresa.endereco.cidade = item.cidade.nome;
        }
      });
      return new Endereco(data);
    }
  }
}
