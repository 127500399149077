import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmniMaisRestService } from './../../omni-rest/omni-mais/omni-mais-rest.service';
import {
  SimuladorVeiculoParamsSimulacao,
  SimuladorVeiculoResultSimulacao,
} from '../models/simulador-veiculo-ficha.model';

@Injectable()
export class SimuladorVeiculoFichaService {
  constructor(private restService: OmniMaisRestService) {}

  getSimulacao(
    params: SimuladorVeiculoParamsSimulacao
  ): Observable<SimuladorVeiculoResultSimulacao> {
    return this.restService.get('v2/proposta/simulacao', { params });
  }

  getExibirMenuSimulacao(): Observable<{
    allowAccess: boolean;
    messages: string[];
    status: string;
  }> {
    return this.restService.get('agents/show-menu-simulation');
  }
}
