import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberRestrictive]'
})
export class OnlyNumberRestrictiveDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    const e = <KeyboardEvent>event;
    this.preventKey(e);
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    const e = <KeyboardEvent>event;
    this.preventKey(e);
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    const e = <KeyboardEvent>event;
    this.preventKey(e);
  }

  private preventKey(e: KeyboardEvent) {
    const permittedsKey = [
      'Delete', 'Home', 'End', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab',
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];

    if (!permittedsKey.includes(`${e.key}`)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

}
