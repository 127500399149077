import { Nacionalidade } from './../../../../shared/nacionalidade/nacionalidade';
import { Telefone } from './telefone';
import { CpfCnpj } from './../../../../shared/partner/cpf-cnpj';
import { Data } from './../../../../shared/partner/data';

import { ClienteReferencia } from './cliente-referencia';
import { DadoProfissional } from './dado-profissional';
import { Endereco } from './endereco';
import { EnderecoTipo } from './endereco-tipo';

export class Avalista {
  cpf: string;
  grauParentesco: string;
  nome: string;
  dataNascimento: number;
  renda: number;
  email: string;
  nomeMae: string;
  nomePai: string;
  idTipoDocumento: number;
  rgIe: string;
  rgIeEmissor: string;
  rgIeDt: number;
  idEstadoCivil: number;
  idNacionalidade: number;
  naturalDe: string;
  naturalDeUf: string;
  clientePpe = 'N';
  valorPatrimonio: number;
  enderecos: Endereco[] = [];
  dadoProfissional: DadoProfissional = new DadoProfissional();
  clienteReferencia: ClienteReferencia = new ClienteReferencia();
  telefones: Telefone[] = [];
  tipoEnderecoCorresp: string;

  private _dataNascimento: string;
  private _dataEmissao: string;
  private _celularAvalista: Telefone = new Telefone(3, 1, undefined, undefined);
  private _nacionalidade: any;

  private _enderecoResidencial: Endereco = new Endereco(EnderecoTipo.RESIDENCIAL);

  private _telefoneResidencial = new Telefone(1, 1, undefined, undefined);
  private _telefoneComercial = new Telefone(2, 2, undefined, undefined);

  private _dadosProfissionais: any;

  set $cpf(value: string) {
    this.cpf = CpfCnpj.clearMask(value);
  }

  get $cpf(): string {
    return this.cpf;
  }

  set $dataNascimento(value) {
    this._dataNascimento = value;
    this.dataNascimento = Data.toMilissegundos(value);
  }

  get $dataNascimento(): string {
    return this._dataNascimento;
  }

  set $celularAvalista(value: string) {
    this._celularAvalista.$dddNumero = value;
    this.telefones[0] = this._celularAvalista;
  }

  get $celularAvalista(): string {
    return this._celularAvalista.$dddNumero;
  }

  set $dataEmissao(value) {
    this._dataEmissao = value;
    this.rgIeDt = Data.toMilissegundos(value);
  }

  get $dataEmissao(): string {
    return this._dataEmissao;
  }

  set $nacionalidade(nacionalidade: any) {
    this.idNacionalidade = nacionalidade.idNacionalidade;
    this.naturalDeUf = nacionalidade.naturalDeUf;
    this.naturalDe = nacionalidade.naturalDe;
    this._nacionalidade = new Nacionalidade(this.idNacionalidade, this.naturalDeUf, this.naturalDe);
  }

  get $nacionalidade(): any {
    return this._nacionalidade;
  }

  set $enderecoResidencial(value: Endereco) {
    this._enderecoResidencial = value;
    this.enderecos[0] = this._enderecoResidencial;
  }

  get $enderecoResidencial(): Endereco {
    return this._enderecoResidencial;
  }

  set $telefoneResidencial(value: string) {
    this._telefoneResidencial.$dddNumero = value;
    this.telefones[1] = this._telefoneResidencial;
  }

  get $telefoneResidencial() {
    return this._telefoneResidencial.$dddNumero;
  }

  set $telefoneComercial(value: string) {
    this._telefoneComercial.$dddNumero = value;
    this.telefones[2] = this._telefoneComercial;
  }

  get $telefoneComercial() {
    return this._telefoneComercial.$dddNumero;
  }

  set $dadoProfissional(value: any) {
    this._dadosProfissionais = value;
    this.handleDadosProfissionais(value);
  }

  get $dadoProfissional() {
    return this._dadosProfissionais;
  }

  get isEmpresa(): boolean {
    return this._dadosProfissionais
        && this._dadosProfissionais.classeProfissional
        && this._dadosProfissionais.classeProfissional.empresa;
  }

  set $enderecoComercial(value: Endereco) {
    this.dadoProfissional.endereco = value;
  }

  get $enderecoComercial() {
    return this.dadoProfissional.endereco;
  }

  addEndereco(endereco: Endereco) {
    this.enderecos.push(endereco);
  }

  private handleDadosProfissionais(value) {
    if (value.classeProfissional) { this.dadoProfissional.idClasseProfissional = value.classeProfissional.id; }
    if (value.empresa) { this.dadoProfissional.nomeEmpresa = value.empresa.nome; }
    if (value.profissao) { this.dadoProfissional.idProfissao = value.profissao.id; }

    if (value.empresa && value.empresa.telefone) {
      this.dadoProfissional.telefone.$dddNumero = value.empresa.telefone;
    } else {
      this.dadoProfissional.telefone = new Telefone();
    }
  }

  removeFieldsNull() {
    const telefonesAux: Telefone[] = [];
    this.telefones.forEach(telefone => { if (telefone && telefone.ddd && telefone.numero) { telefonesAux.push(telefone); } });
    this.telefones = telefonesAux;
  }
}
