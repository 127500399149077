import { ModalDemonstrativoResultadoComponent } from './modal-demonstrativo-resultado/modal-demonstrativo-resultado.component';
import { ModalIndicadoresComponent } from './modal-indicadores/modal-indicadores.component';
import { ModalBalancoPatrimonialComponent } from './modal-balanco-patrimonial/modal-balanco-patrimonial.component';
import { appSettings } from './../../../../../../../environments/app.setings';
import { BsModalService } from 'ngx-bootstrap';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FaefStateService } from './../faef-state.service';
import { Faef } from './../model/faef';
@Component({
  selector: 'app-resultados-contabeis',
  templateUrl: './resultados-contabeis.component.html',
  styleUrls: ['./resultados-contabeis.component.scss']
})
export class ResultadosContabeisComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  faef: Faef;

  constructor(
    private state: FaefStateService,
    private modal: BsModalService) { }

  ngOnInit() {
    this.faef = this.state.getLast();
  }

  onClickNext() {
    this.next.emit();
  }

  onClickPrevious() {
    this.previous.emit();
  }

  abrirModalBalancoPatrimonial() {
    this.showModal(ModalBalancoPatrimonialComponent);
  }

  abrirModalIndicadores() {
    this.showModal(ModalIndicadoresComponent);
  }

  abrirModalDemonstrativoResultado() {
    this.showModal(ModalDemonstrativoResultadoComponent);
  }

  private showModal(component) {
    const initialState = { model: this.faef.faefResultado };
    this.modal.show(component, { ...appSettings.MODAL_PARAMS, initialState });
  }
}
