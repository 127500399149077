import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Proposta } from '../models/proposta';

@Injectable({
  providedIn: 'root'
})
export class PropostaStateService {
  private propostaBase: Proposta = { plataformaInclusao: 'WEB', transients: {}, carencia: 0 };
  private proposta: BehaviorSubject<Proposta> = new BehaviorSubject<Proposta>(this.propostaBase);
  private proposta$ = this.proposta.asObservable();

  getLast(): Proposta {
    const entity = this.proposta.getValue();
    return { ...entity, transients: { ...entity.transients }, valorEntrada: 0 };
  }

  getState() {
    return this.proposta$;
  }

  setState(proposta: Proposta) {
    this.proposta.next(proposta);
  }

  clear() {
    console.log('Limpando estado da proposta');
    this.setState(this.propostaBase);
  }
}
