import { Pessoa } from './pessoa';
import { Assistencia } from './assistencia';
import { Seguro } from './seguro';
export class Proposta {

  private id: string;
  private carencia: number;
  private fator: number;
  private taxaProposta: number;
  private valorRegistro: number;
  private valorEntrada: number;
  private valorTotal: number;
  private valorLiquido: number;
  private valorRetorno: number;
  private percRetorno: number;
  private valorSircof: number;
  private valorTc: number;
  private valorDv: number;
  private valorParcela: number;
  private portarDivida: string;
  private qtdParcelas: number;
  private observacoes: string;
  private seguros: Seguro[] = [];
  private assistencias: Assistencia[] = [];
  private avalistas: Pessoa[];
  private cliente: Pessoa;

  setId(id: string) {
    this.id = id;
  }

  setCarencia(carencia: number) {
    this.carencia = carencia;
  }

  setFator(fator: number) {
    this.fator = fator;
  }

  setTaxaProposta(taxaProposta: number) {
    this.taxaProposta = taxaProposta;
  }

  setValorRegistro(valorRegistro: number) {
    this.valorRegistro = valorRegistro;
  }

  setValorEntrada(valorEntrada: number) {
    this.valorEntrada = valorEntrada;
  }

  setValorTotal(valorTotal: number) {
    this.valorTotal = valorTotal;
  }

  setValorLiquido(valorLiquido: number) {
    this.valorLiquido = valorLiquido;
  }

  setValorRetorno(valorRetorno: number) {
    this.valorRetorno = valorRetorno;
  }

  setPercRetorno(percRetorno: number) {
    this.percRetorno = percRetorno;
  }

  setValorSircof(valorSircof: number) {
    this.valorSircof = valorSircof;
  }

  setValorTc(valorTc: number) {
    this.valorTc = valorTc;
  }

  setValorDv(valorDv: number) {
    this.valorDv = valorDv;
  }

  setValorParcela(valorParcela: number) {
    this.valorParcela = valorParcela;
  }

  setPortarDivida(portarDivida: string) {
    this.portarDivida = portarDivida;
  }

  setQtdParcelas(qtdParcelas: number) {
    this.qtdParcelas = qtdParcelas;
  }

  setObservacoes(observacoes: string) {
    this.observacoes = observacoes;
  }

  setSeguro(seguros: Seguro[]) {
    this.seguros = seguros;
  }

  setAssistencia(assistencias: Assistencia[]) {
    this.assistencias = assistencias;
  }

  setAvalistas(avalistas: Pessoa[]) {
    this.avalistas = avalistas;
  }

  setCliente(cliente: Pessoa) {
    this.cliente = cliente;
  }

  addAvalista(avalista: Pessoa) {
    if (!this.avalistas) {
      this.avalistas = [];
    }
    this.avalistas.push(avalista);
  }

}
