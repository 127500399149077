import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { TermsOfPayment } from '../../../../../produtos/shared/models/proposta-simulacao';
import { Tabela } from '../../../../../produtos/shared/models/tabelas';

@Component({
  selector: 'app-prazo-primeiro-pgto',
  templateUrl: './prazo-primeiro-pgto.component.html',
  styleUrls: ['./prazo-primeiro-pgto.component.scss'],
})
export class PrazoPrimeiroPgtoComponent {
  onSave: EventEmitter<number> = new EventEmitter();
  alive = true;

  @Input() index = 0;
  @Input() opcoesPagamento: {
    tabela: Tabela;
    opcoesParcelamento: TermsOfPayment[];
  }[];

  constructor(public bsModalRef: BsModalRef) {}

  save() {
    this.onSave.emit(this.index);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }

  onSelect(index: number) {
    this.index = index;
  }

  isEven(array: any[]) {
    return array.length % 2 === 0;
  }
}
