import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Proposta } from '../../../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../../../shared/states/proposta-state.service';
import { AutenticacaoClienteService } from '../autenticacao-cliente.service';

export enum PassosAutenticacao {
  VALIDACAO_CELULAR = 1,
  CAPTURA_BIOMETRIA = 2,
  VALIDACAO_CELULAR_NAO_REALIZADA = 3,
  AUTENTICACAO_REALIZADA = 4
}

@Component({
  selector: 'app-autenticacao-manual-em-andamento',
  templateUrl: './autenticacao-manual-em-andamento.component.html',
  styleUrls: ['./autenticacao-manual-em-andamento.component.scss']
})
export class AutenticacaoManualEmAndamentoComponent implements OnInit {
  readonly STEPS = PassosAutenticacao;
  step = PassosAutenticacao.VALIDACAO_CELULAR;
  lineStyles = ['active', 'inactive'];
  celularFoiValidado = false;
  autenticacaoForm: FormGroup;
  proposta: Proposta;

  constructor(private fb: FormBuilder, private state: PropostaStateService, private router: Router, private biometriaService: AutenticacaoClienteService
  ) { }

  ngOnInit(): void {
    this.proposta = this.state.getLast();
    this.autenticacaoForm = this.fb.group({
      biometria: [null, Validators.required],
    });
  }

  cancelarValidacaoCelular() {
    this.celularFoiValidado = false;
    this.step = PassosAutenticacao.CAPTURA_BIOMETRIA;
    this.lineStyles = ['warning', 'active'];
  }

  celularValidado() {
    this.celularFoiValidado = true;
    this.step = PassosAutenticacao.CAPTURA_BIOMETRIA;
    this.lineStyles = ['done', 'active'];
  }

  finalizarBiometria() {
    const biometria = this.autenticacaoForm.controls['biometria'].value;
    if (biometria) {
      this.biometriaService.atualizarAutenticacaoManual({
        isAutenticacaoManual: true,
        artefatos: {
          biometria,
          celularFoiValidado: this.celularFoiValidado
        }
      });
      this.step = this.celularFoiValidado ? PassosAutenticacao.AUTENTICACAO_REALIZADA : PassosAutenticacao.VALIDACAO_CELULAR_NAO_REALIZADA;
      this.lineStyles = [this.lineStyles[0], 'done'];
    }
  }

  excluirFicha() {
    this.router.navigate(['/produtos/cdcloja/ficha-excluida'], { queryParams: { id: this.state.getLast().id } });
  }

  get isLojistaPiloto(): boolean {
    return this.proposta.lojista.liberadoBiometria;
  }
}
