import { appSettings } from './../../../../environments/app.setings';
import { FichaService } from '../../ficha-veiculo.service';
import { Subscription } from 'rxjs';
import { FichaCadastro } from './../ficha-cadastro';
import { Component, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { removeFichaContent } from '../helpers/salvarFicha';
import { ModalMessageComponent } from '.././../../shared/modais/modal-message/modal-message.component';
import { RenavanPartner } from '../../../shared/partner/renavan-partner';
import { FichaCadastroEvent } from '../ficha-cadastro.event';
import { FichaModalAvalistaComponent } from '../../ficha-timeline/ficha-modal-avalista/ficha-modal-avalista.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Data } from '../../../shared/partner/data';
import { finalize } from 'rxjs/operators';
import * as toastr from 'toastr';

@Component({
  selector: 'app-ficha-cadastro-observacao',
  templateUrl: './ficha-cadastro-observacao.component.html',
  styleUrls: ['./ficha-cadastro-observacao.component.css']
})
export class FichaCadastroObservacaoComponent implements OnDestroy {
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;
  @ViewChild('renavanInput', { static: true }) renavanInput: ElementRef;

  @Input() fichaCadastro: FichaCadastro;

  bsModalRef: BsModalRef;

  private subsProposta: Subscription;

  observacao: string;
  isSaving = true;
  countCaracteres: number = 0;
  hideClose: boolean = true;
  exibeIncluirAvalista: boolean = false;
  isLoading = false;
  isleads: boolean = false;

  constructor(private fichaService: FichaService, private fichaEvent: FichaCadastroEvent, private modalService: BsModalService) { }

  ngOnInit() {
    this.initLeadsOnline();
  }

  ngOnDestroy() {
    if (this.subsProposta) {
      this.subsProposta.unsubscribe();
    }
  }

  validaRenavam() {
    if (this.fichaCadastro.codRenavam) {
      if (RenavanPartner.isValid(this.fichaCadastro.codRenavam)) {
        this.fichaCadastro.garantiasOriginais = this.fichaCadastro.garantias;
        this.fichaCadastro.garantias = [{
          codRenavam: this.fichaCadastro.codRenavam
        }];
        this.fichaCadastro.proposta['garantias'] = this.fichaCadastro.garantias;
      } else {
        this.renavanInput.nativeElement.focus();
        this.hideClose = true;
        this.modalMessage.show(
          'Número de Renavam inválido! Favor tentar novamente'
        );
      }
    }
  }

  onChangeObs(event) {
    const limite = 0;
    this.countCaracteres = limite + event.length;
  }

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
  }

  formatDateMiliseconds(data) {
    return Data.toMilissegundos(data);
  }
  setPayload() {
    const clienteObj = this.fichaCadastro.proposta.cliente;
    this.fichaCadastro.proposta.cliente = JSON.parse(JSON.stringify(this.fichaCadastro._client.proposta));
    this.setPayloadCliente(clienteObj);
    if (this.observacao) {
      if (this.observacao.length > 0) {
        this.fichaCadastro.proposta['observacoes'] = this.observacao;
      }
    }
    if (this.fichaCadastro.getAvalista()) {
      const avalistaObj = this.fichaCadastro.proposta.avalistas;
      this.fichaCadastro.proposta.avalistas = JSON.parse(JSON.stringify(this.fichaCadastro._avalista.proposta));
      this.setPayloadAvalista(avalistaObj);
    } else {
      this.fichaCadastro.proposta.avalistas = [];
    }

    /* TO DO - verificar de onde virá o campo portar divida*/
    this.fichaCadastro.proposta.portarDivida = 'N';

    removeFichaContent();
  }

  setPayloadCliente(clienteObj) {
    this.fichaCadastro.proposta.cliente.id = clienteObj.id;
    if (clienteObj.renda !== undefined) {
      this.fichaCadastro.proposta.cliente.renda = clienteObj.renda;
    }
    this.validateClientProfessionalAddress();
  }

  setPayloadAvalista(avalistaObj) {
    if (this.fichaCadastro._avalista && this.fichaCadastro._avalista.dataNascimento) {
      const dateMatches: any[] = Array.from(`${this.fichaCadastro._avalista.dataNascimento}`.match(/^(\d{2})(\d{2})(\d{4})$/));

      this.fichaCadastro.proposta.avalistas[0].dataNascimento = (new Date(+dateMatches[3], (+dateMatches[2] - 1), +dateMatches[1])).getTime();
    }

    this.fichaCadastro.proposta.avalistas[0].id = avalistaObj[0].id;
    this.fichaCadastro.proposta.avalistas[0].renda = avalistaObj[0].renda;

    if (this.fichaCadastro.proposta.avalistas[0].idNacionalidade > 1) {
      this.fichaCadastro.proposta.avalistas[0].naturalDe = 'EX';
      this.fichaCadastro.proposta.avalistas[0].naturalDeUf = 'EX';
    }

    this.validateAvalistProfessionalAddress();
  }

  validateClientProfessionalAddress() {
    if (this.fichaCadastro.proposta.cliente.dadoProfissional.endereco &&
        (Object.keys(this.fichaCadastro.proposta.cliente.dadoProfissional.endereco).length === 0)) {
      delete this.fichaCadastro.proposta.cliente.dadoProfissional.endereco;
    }
  }

  validateAvalistProfessionalAddress() {
    if (this.fichaCadastro.proposta.avalistas[0].dadoProfissional.endereco &&
        (Object.keys(this.fichaCadastro.proposta.avalistas[0].dadoProfissional.endereco).length === 0)) {
      delete this.fichaCadastro.proposta.avalistas[0].dadoProfissional.endereco;
    }
  }

  onClickNext() {
    this.setPayload();
    this.saveProposta();
  }

  abrirInclusaoAvalista() {
    this.bsModalRef = this.modalService.show(FichaModalAvalistaComponent, appSettings.MODAL_PARAMS);
    this.bsModalRef.content.cpfCliente = this.fichaCadastro.proposta.cliente.cpf;
    (<FichaModalAvalistaComponent>this.bsModalRef.content).onCloseModal.subscribe(body => {
      this.setPayload();
      this.fichaCadastro.proposta.avalistas = [];
      this.fichaCadastro.proposta.avalistas.push(body.avalistas[0]);
      this.hideClose = false;
      this.modalMessage.show('Deseja salvar o avalista e enviar a proposta?', 'Sim', 'Não');
      this.modalMessage.ok.take(1)
      .subscribe(() => {
        const avalistaObj = this.fichaCadastro.proposta.avalistas;
        this.setPayloadAvalista(avalistaObj);
        this.saveProposta();
      });
    });
  }

  saveProposta() {
    this.isLoading = true;
    if (this.isleads) {
      this.subsProposta = this.fichaService
      .finalizarSimulacaoLeads({ proposta: this.fichaCadastro.proposta })
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (res: any) => {
          this.handleFinalizeSuccess(res);
        },
        (err) => {
          this.handleFinalizeError();
        }
      );
    } else {
      this.subsProposta = this.fichaService
      .finalizarSimulacao({ proposta: this.fichaCadastro.proposta })
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (res: any) => {
          this.handleFinalizeSuccess(res);
        },
        (err) => {
          this.handleFinalizeError();
        }
      );
    }
  }

  private handleFinalizeError() {
    this.hideClose = true;
    this.modalMessage.show(
      'Falha na Comunicação, verifique sua conexão.'
    );
    this.isSaving = false;
  }

  private handleFinalizeSuccess(res: any) {
    if (res.proposta) {
      this.fichaCadastro.proposta = res;
    }
    this.isSaving = false;
    this.fichaCadastro.onClickNext();
    this.fichaEvent.nextStep();
  }

  async initLeadsOnline() {
    try {
      const response = await this.fichaService.getOriginadorLeads();
      this.isleads = response.originadorLeads;
    } catch (e) {
      toastr.warning('Erro ao consultar tipo de originador Leads Online. Caso seja um parceiro Leads, favor contatar o suporte', 'Atenção');
    }
  }
}
