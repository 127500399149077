import { environment } from './../../../../environments/environment';
import { MessagingService } from './../../../services/messaging/messaging.service';
import { timer as observableTimer,  Observable } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { ModalComponent } from './../../../shared/modais/modal/modal.component';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { FichaCadastroSharedService } from './../shared/ficha-cadastro-shared.service';
import { FichaService } from '../../ficha-veiculo.service';
import { FichaCadastro } from './../ficha-cadastro';

import { DadoSimulacao } from './../ficha-cadastro-resultado-parcial/model/dado-simulacao';
import { PropostaSimulacao } from './../ficha-cadastro-resultado-parcial/model/proposta/proposta-simulacao';
import { ModalMessageComponent } from './../../../shared/modais/modal-message/modal-message.component';
import { StorageService } from '../../../services/storage/storage.service';
import { differenceInMilliseconds } from 'date-fns';

@Component({
  selector: 'app-ficha-cadastro-consultando-dados',
  templateUrl: './ficha-cadastro-consultando-dados.component.html',
  styleUrls: ['./ficha-cadastro-consultando-dados.component.css'],
})
export class FichaCadastroConsultandoDadosComponent
  implements OnInit, OnDestroy
{
  @Input() fichaCadastro: FichaCadastro;

  @ViewChild('modalMessage', { static: true })
  modalMessage: ModalMessageComponent;
  @ViewChild('messageReturnHome', { static: true })
  messageReturnHome: ModalComponent;

  hideClose: boolean = true;

  private isAlive = true;
  private isConsultarAnaliseProposta = false;
  private isExecutandoSimulacao = false;
  private numero = 0;
  private tentativaDados = 0;
  private isTelaSimulacao = true;

  private readonly SUCCESS: string = 'SUCCESS';
  private readonly ANALISE_PENDENTE: string = 'ANALISE_PENDENTE';
  private readonly REFUSED: string = 'REFUSED';
  private readonly FIPE_NOT_FOUND: string = 'FIPE_NOT_FOUND';
  private readonly PLACA_INVALIDA: string = 'PLACA_INVALIDA';
  private readonly TAXA_CLIENTE_NOT_FOUND: string = 'TAXA_CLIENTE_NOT_FOUND';
  private readonly ERRO_CRIVO: string = 'CRIVO_ERRO';
  private readonly AGUARDANDO_CARREGAMENTO: string = 'AGUARDANDO_CARREGAMENTO';
  private readonly GARANTIA_RECUSADA: string = 'GARANTIA_RECUSADA';

  constructor(
    private sharedService: FichaCadastroSharedService,
    private fichaService: FichaService,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.initObservableSimular();
  }

  ngOnDestroy() {
    this.isTelaSimulacao = false;
    this.isAlive = false;
    this.endControlesObservables();
  }

  private endControlesObservables() {
    this.isConsultarAnaliseProposta = false;
    this.isExecutandoSimulacao = false;
  }

  private initObservableSimular() {
    this.sharedService.simularPropostaObservable
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((simular: boolean) => {
        if (simular) {
          this.simularProposta();
        }
      });
  }

  private simularProposta() {
    const { lastTimeAProposalStarted } = this.storageService.getLocal();
    const timeSpentRecord = Date.now() - lastTimeAProposalStarted;

    if (lastTimeAProposalStarted) {
      this.fichaCadastro.setPayload(
        'tempoGastoFicha',
        differenceInMilliseconds(new Date(), lastTimeAProposalStarted)
      );
    } else {
      this.fichaCadastro.setPayload('tempoGastoFicha', 0);
    }
    this.fichaService
      .simular(this.fichaCadastro.getPayload())
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (result) => {
          this.iniciarTempoEspera(result);
          // this.pushNotificationGetDadosSimulacao(result);
          this.iniciarConsultaAnaliseProposta(result);
          this.storageService.removeLocalItem('lastTimeAProposalStarted');
        },
        (error) => this.handleErrorSimulacao(error.error, null)
      );
  }

  private iniciarTempoEspera(result) {
    if (result.timeout) {
      this.isConsultarAnaliseProposta = true;
      const timerEncerrarConsulta = observableTimer(result.timeout || 45000);
      timerEncerrarConsulta
        .pipe(takeWhile(() => this.isConsultarAnaliseProposta))
        .subscribe(() => {
          this.isConsultarAnaliseProposta = false;
          this.messageReturnHome
            .show()
            .modalClose.pipe(take(1))
            .subscribe(() => this.home());
        });
    }
  }

  private iniciarConsultaAnaliseProposta(result) {
    if ((result.proposta || {}).id) {
      this.isExecutandoSimulacao = false;
      const dadoSimulacao: DadoSimulacao = new DadoSimulacao();
      dadoSimulacao.setNumeroProposta(result.proposta.id);
      const timerConsulta = observableTimer(1000, 5000);
      timerConsulta
        .pipe(takeWhile(() => this.isConsultarAnaliseProposta))
        .subscribe(() => {
          this.consultarAnaliseProposta(dadoSimulacao);
        });
    }
  }

  private consultarAnaliseProposta(dadoSimulacao: DadoSimulacao) {
    if (!this.isExecutandoSimulacao && this.isTelaSimulacao) {
      this.isExecutandoSimulacao = true;
      this.fichaService
        .getDadosProposta(dadoSimulacao.getNumeroProposta())
        .pipe(takeWhile(() => this.isExecutandoSimulacao))
        .subscribe(
          (result) => this.handleResultDadoSimulacao(result, dadoSimulacao),
          (error) => this.handleErrorSimulacao(error.error, dadoSimulacao)
        );
    }
  }

  // private pushNotificationGetDadosSimulacao(result: any) {
  //   let keepSubscribe = true;
  //   const timeoutLoadDados = setTimeout(
  //     () => {
  //       keepSubscribe = false;
  //       this.messageReturnHome
  //         .show()
  //         .modalClose.pipe(take(1))
  //         .subscribe(() => this.home());
  //     },
  //     result.timeout
  //   );
  //   this.messagingService.messageReceived
  //     .pipe(takeWhile(() => keepSubscribe))
  //     .subscribe((value) => {
  //       clearTimeout(timeoutLoadDados);
  //       keepSubscribe = false;
  //       this.iniciarConsultaAnaliseProposta(result);
  //     });
  // }

  private handleResultDadoSimulacao(result, dadoSimulacao: DadoSimulacao) {
    if (result.status === this.AGUARDANDO_CARREGAMENTO) {
      this.isConsultarAnaliseProposta = false;
      this.isExecutandoSimulacao = false;
      if (this.tentativaDados <= environment.SIMULACAO_TENTATIVAS && this.isTelaSimulacao) {
        setTimeout(() => this.consultarAnaliseProposta(dadoSimulacao), 7200);
      } else {
        this.returnHomeWithMessage();
      }
      this.tentativaDados++;
    } else {
      if (result.status === this.REFUSED) {
        this.handleErrorRefused(result.message);
      }

      if (
        result.status !== this.ANALISE_PENDENTE &&
        result.status !== this.REFUSED
      ) {
        const proposta = new PropostaSimulacao(result);
        this.isExecutandoSimulacao = false;
        this.endControlesObservables();
        this.sharedService.simulacaoResultadoParcial(proposta);
        this.fichaCadastro.onClickNext();
        this.fichaCadastro.garantias = [{
          anoModelo: result.veiculos[0].anoModelo,
          descMarcaModelo: `${result.veiculos[0].marca.descricao}/${result.veiculos[0].modelo.descricao}`
        }];
        this.fichaCadastro.garantiasOriginais = this.fichaCadastro.garantias;
      }
    }
  }

  private returnHomeWithMessage(): void {
    this.messageReturnHome
      .show()
      .modalClose.pipe(take(1))
      .subscribe(() => this.home());
  }

  private handleErrorSimulacao(error, dadoSimulacao: DadoSimulacao) {
    this.isConsultarAnaliseProposta = false;
    this.isExecutandoSimulacao = false;
    this.tentativaDados = 0;
    if (error.status === this.FIPE_NOT_FOUND) {
      this.handleErroFipeNaoEncontrada(error);
    } else if (error.status === this.PLACA_INVALIDA) {
      this.fichaCadastro.setErrorStatus(error.status);
      this.perguntaCorrigirDadosPlaca(error);
    } else if (error.status === this.GARANTIA_RECUSADA) {
      this.perguntaCorrigirGarantia(error);
    } else if (error.status === this.TAXA_CLIENTE_NOT_FOUND) {
      this.handleErroTaxaClienteNaoEncontrada(error);
    } else if (error.status === this.ERRO_CRIVO) {
      this.handleErroCrivo(error);
    } else if (error.status === this.REFUSED) {
      this.handleErrorRefused(error);
    } else {
      this.handleErroInesperado(error);
    }
  }

  private handleErroInesperado(error) {
    this.tentativaDados = 1;
    if (error && error.message) {
      this.showErrorMessage(error.message, () => this.home());
    } else {
      this.showErrorMessage(
        'Não foi possível realizar a simulação. Verifique sua conexão com a internet e tente novamente.',
        () => this.home()
      );
    }
  }

  private handleErroPlacaInvalida(error: any) {
    this.fichaCadastro.passoCorrigirPLacaInvalida();
    this.fichaCadastro.setIdSimulacao(error.id);
  }

  private perguntaCorrigirDadosPlaca(error: any) {
    this.hideClose = false;
    this.modalMessage.show(
      'Não foi possível completar a simulação.\n\n A placa informada é inválida. Deseja preencher as informações do veículo manualmente ?',
      'Sim',
      'Não'
    );
    this.modalMessage.ok
      .take(1)
      .subscribe(() => this.handleErroFipeNaoEncontradaWithoutMessage(error));
    this.modalMessage.close
      .take(1)
      .subscribe(() => this.handleErroPlacaInvalida(error));
    this.modalMessage.modalClose.subscribe();
  }

  private perguntaCorrigirGarantia(error: any) {
    this.hideClose = false;
    this.modalMessage.show(
      'Não foi possível completar a simulação.\nA garantia informada foi recusada. Deseja informar outra placa ?',
      'Sim',
      'Não'
    );
    this.modalMessage.ok
      .take(1)
      .subscribe(() => this.handleErroPlacaInvalida(error));
    this.modalMessage.close.take(1).subscribe(() => this.home());
    this.modalMessage.modalClose.subscribe();
  }

  private handleErroFipeNaoEncontradaWithoutMessage(error: any) {
    this.sharedService.selecionarVeiculoManualmente();
    this.fichaCadastro.passoCorrigirFipeNotFound();
    this.fichaCadastro.hasUsado = true;
    this.fichaCadastro.setIdSimulacao(error.id);
  }

  private handleErroFipeNaoEncontrada(error: any) {
    this.showErrorMessage(error.message, () => {
      this.sharedService.selecionarVeiculoManualmente();
      this.fichaCadastro.passoCorrigirFipeNotFound();
      this.fichaCadastro.setIdSimulacao(error.id);
    });
  }

  private handleErroTaxaClienteNaoEncontrada(error: any) {
    this.showErrorMessage(error.message, () => this.home());
  }

  private handleErroCrivo(error: any) {
    this.showErrorMessage(error.message, () => this.home());
  }

  private handleErrorRefused(message) {
    this.showErrorMessage(message, () => this.home());
  }

  private home() {
    this.router.navigate(['/ficha']);
  }

  private showErrorMessage(message: string, next: () => void) {
    this.hideClose = true;
    this.modalMessage.show(message).modalClose.pipe(take(1)).subscribe(next);
  }
}
