import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

@Component({
  selector: 'app-estado-civil',
  templateUrl: './estado-civil.component.html',
  styleUrls: ['./estado-civil.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EstadoCivilComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EstadoCivilComponent),
      multi: true,
    }
  ]
})
export class EstadoCivilComponent implements Validator, ControlValueAccessor {

  @Input() required = false;
  estado: string;

  onChange() {
    this.propagateChange(this.estado);
  }

  validate(): { [key: string]: any; } {
    return this.required && !this.estado ? { required: true } : null;
  }

  public writeValue(obj: string) {
    if (obj) {
      this.estado = obj;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // This is intentional
  }

  private propagateChange = (_: any) => {
    // This is intentional
  }

}
