import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

import { ParametroRendaProduto } from '../../../models/parametro-renda-produto';

@Component({
  selector: 'app-alterar-valor',
  templateUrl: './alterar-valor.component.html',
  styleUrls: ['./alterar-valor.component.scss']
})
export class AlterarValorComponent implements OnInit {
  @Input() parametroRendaProduto: ParametroRendaProduto;

  alive: boolean = true;
  alterarValorForm: FormGroup;
  parcelasPersonalizada: number[];
  onSave: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.alterarValorForm = this.fb.group({
      valorFinanciamento: ['', Validators.required]
    });
  }

  get valorFinanciamento() {
    return this.alterarValorForm.get('valorFinanciamento');
  }

  save() {
    this.onSave.emit(this.valorFinanciamento.value);
    this.close();
  }

  close() {
    this.alive = false;
    this.bsModalRef.hide();
  }
}
