import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';

@Injectable()
export class PropostaService {

  constructor(private _restService: OmniMaisRestService) { }

  delete(id: string): Observable<any> {
    return this._restService.delete(`proposta/${id}`, id);
  }

  getTimeline(idFicha: number): Observable<any> {
    return this._restService.get(`proposta/timelinePropostaByIdV2/${idFicha}`);
  }

  getMotivosSosInverso() {
    return this._restService.get('proposta/listarMotivosSosInverso');
  }

  sendMotivoSosInverso(sos: any) {
    return this._restService.post('proposta/abrirSosInverso', sos);
  }

}
