import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

import { Cliente } from '../../../shared/models/cliente';
import { FichaService } from './../../../../ficha-veiculo/ficha-veiculo.service';
import { NacionalidadesService } from './../../../../services/nacionalidade/nacionalidades.service';

@Component({
  selector: 'app-dados-complementares',
  templateUrl: './dados-complementares.component.html'
})
export class DadosComplementaresComponent implements OnInit, OnDestroy {

  event = new Subject<Cliente>();
  alive = true;

  isNomePaiClienteTexto: boolean = false;

  listaNaturalCidadeCliente = [];
  isLoadingNaturalCidadeCliente = false;

  listaNacionalidadeCliente = [];
  isLoadingNacionalidadeCliente = false;

  @Input() cliente: Cliente;

  constructor(
    private modal: BsModalRef,
    private fichaService: FichaService,
    private nacionalidadesService: NacionalidadesService
  ) { }

  ngOnInit() {
    if (this.cliente.tipoDocumentoDataEmissao) {
      this.cliente.tipoDocumentoDataEmissao = moment.unix(this.cliente.tipoDocumentoDataEmissao / 1000).format('DDMMYYYY');
    }
    this.loadNacionalidadeCliente();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  salvar() {
    this.cliente.tipoDocumentoDataEmissao = moment(this.cliente.tipoDocumentoDataEmissao, 'DDMMYYYY').unix() * 1000;
    this.event.next(this.cliente);
  }

  onClickNomePaiCliente() {
    this.isNomePaiClienteTexto = !this.isNomePaiClienteTexto;
    this.cliente.nomePai = this.isNomePaiClienteTexto ? 'NAO DECLARADO' : '';
  }

  async loadNacionalidadeCliente() {
    this.listaNacionalidadeCliente = [];
    this.isLoadingNacionalidadeCliente = true;
    try {
      this.listaNacionalidadeCliente = await this.nacionalidadesService.nacionalidades().toPromise();
      if (this.cliente.naturalDeUf) {
        await this.loadNaturalCidadeCliente();
      }
    } finally {
      this.isLoadingNacionalidadeCliente = false;
    }
  }

  onChangeNacionalidade(data) {
    if (data !== '1') {
      this.cliente.naturalDeUf = '';
      this.cliente.naturalDe = '';
    }
  }

  async loadNaturalCidadeCliente() {
    this.listaNaturalCidadeCliente = [];
    this.isLoadingNaturalCidadeCliente = true;

    try {
      const res = await this.fichaService
        .getCidadePorUf(this.cliente.naturalDeUf)
        .toPromise();
      this.listaNaturalCidadeCliente = res.cidades;
    } finally {
      this.isLoadingNaturalCidadeCliente = false;
    }

  }

  onChangeNaturalUfCliente() {
    this.cliente.naturalDe = '';
    this.loadNaturalCidadeCliente();
  }

  fechar() {
    this.modal.hide();
  }

}
