import { Seguro } from '../../../model/seguro';

export class TaxaClienteSimulacao {
  parcelas = 0;
  valorParcela = 0;
  parcelaMax = 0;
  parcelaRecusa = 0;
  retornoMax = 0;
  seguroId = 0;
  seguro: Seguro;
  assistenciaId: number;
  dataCarenciaMin: number;
  dataCarenciaMax: number;
  fator: number;
  taxaFinal: number;
  taxaMin: number;
  taxaMax: number;
  valorRegistro: number;
  valorLiberado: number;
  valorRetorno: number;
  valorSircof: number;
  valorTc: number;
  valorDv: number;
  portarDivida = 'N';
  carencia: number;
  entrada: number;
  valorLiquido: number;

  constructor(obj) {
    this.parcelas = obj.parcelas || 0;
    this.valorParcela = obj.valorParcela || 0;
    this.parcelaMax = obj.parcelaMax || 0;
    this.parcelaRecusa = obj.parcelaRecusa || 0;
    this.retornoMax = obj.retorno || 0;
    this.seguroId = (obj.seguro || {}).id || 0;
    this.seguro = (obj.seguro || {}) || {};
    this.assistenciaId = (obj.assistencia || {}).id || 0;
    this.dataCarenciaMin = obj.dataCarenciaMin || null;
    this.dataCarenciaMax = obj.dataCarenciaMax || null;
    this.fator = obj.fator;
    this.taxaFinal = obj.taxaFinal;
    this.taxaMin = obj.taxaMin;
    this.taxaMax = obj.taxaMax;
    this.valorRegistro = obj.valorRegistro;
    this.valorLiberado = obj.valorLiberado;
    this.valorRetorno = obj.valorRetorno;
    this.valorSircof = obj.valorSircof;
    this.valorTc = obj.valorTc;
    this.valorDv = obj.valorDv;
    this.carencia = obj.carencia;
    this.entrada = obj.entrada;
    this.valorLiquido = obj.valorLiquido;
  }

  getParcelas(): number {
    return this.parcelas;
  }

  getValorParcela(): number {
    return this.valorParcela;
  }

  getParcelaMax(): number {
    return this.parcelaMax;
  }

  getParcelaRecusa(): number {
    return this.parcelaRecusa;
  }

  getSeguroId() {
    return this.seguroId;
  }

  getSeguro() {
    return this.seguro;
  }

  getAssistenciaId() {
    return this.assistenciaId;
  }

  getFator(): number {
    return this.fator;
  }

  getTaxaFinal(): number {
    return this.taxaFinal;
  }

  getTaxaMin(): number {
    return this.taxaMin;
  }

  getTaxaMax(): number {
    return this.taxaMax;
  }

  getValorRegistro(): number {
    return this.valorRegistro;
  }

  getValorLiberado(): number {
    return this.valorLiberado;
  }

  getValorRetorno(): number {
    return this.valorRetorno;
  }

  getValorSircof() {
    return this.valorSircof;
  }

  getValorTc(): number {
    return this.valorTc;
  }

  getValorDv(): number {
    return this.valorDv;
  }

  getCarencia(): number {
    return this.carencia;
  }

  getEntrada(): number {
    return this.entrada;
  }

  getValorLiquido(): number {
    return this.valorLiquido;
  }
}
