import { Injectable } from '@angular/core';

export enum NumeroEtapaCadastroFichaCDCLoja {
  Inicial = 0,
  Vendedor = 1,
  DadosAnalise = 2,
  ResultadoParcial = 3,
  DetalhesCliente = 4,
  EnderecosCliente = 5,
  DemaisDados = 6,
  Analise = 7
}

export const EtapaCadastroFichaCDCLojaMap = {
  0: '',
  1: 'Vendedor',
  2: 'Dados para análise',
  3: 'Resultado parcial',
  4: 'Detalhes do cliente',
  5: 'Endereços do cliente',
  6: 'Demais dados',
  7: 'Análise'
};

export interface EtapaCadastroFichaCDCLoja {
  numero: number;
  descricao: string;
}

@Injectable()
export class EtapasCDCLoja {
  obterProximaEtapa(estapaAtual: EtapaCadastroFichaCDCLoja): EtapaCadastroFichaCDCLoja {
    return this.obterEtapaPorNumero(estapaAtual, estapaAtual.numero + 1);
  }

  obterEtapaAnterior(estapaAtual: EtapaCadastroFichaCDCLoja): EtapaCadastroFichaCDCLoja {
    return this.obterEtapaPorNumero(estapaAtual, estapaAtual.numero + 1);
  }

  obterEtapas(): EtapaCadastroFichaCDCLoja[] {
    return Object.keys(EtapaCadastroFichaCDCLojaMap).map((item: any) => ({ numero: item, descricao: EtapaCadastroFichaCDCLojaMap[item] }));
  }

  obterEtapaPorNumero(estapaAtual: EtapaCadastroFichaCDCLoja, numero: number) {
    return this.obterEtapas().find(etapa => etapa.numero === numero) || estapaAtual;
  }
}
