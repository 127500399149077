import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CnpjPipe } from './cnpj/cnpj.pipe';
import { CpfPipe } from './cpf/cpf.pipe';
import { DateTimePipe } from './date/date-time.pipe';
import { TelefonePipe } from './telefone/telefone.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CpfPipe,
    CnpjPipe,
    DateTimePipe,
    TelefonePipe,
  ],
  exports: [
    CpfPipe,
    CnpjPipe,
    DateTimePipe,
    TelefonePipe,
  ]
})
export class PipesModule { }
