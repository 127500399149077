import { Component, Input } from '@angular/core';

@Component({
  selector: 'saiba-mais',
  templateUrl: './saiba-mais.component.html',
  styleUrls: ['./saiba-mais.component.scss']
})
export class SaibaMaisComponent {
  @Input() titulo: string;
  @Input() texto: string;
  @Input() isPopoverOnHover: boolean = false;
  @Input() isPositionStatic: boolean = false;
  @Input() isSmall: boolean = false;
  @Input() placement: string = 'bottom';
  @Input() backgroundBlue: boolean = false;
}
