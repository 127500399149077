import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SocialUser} from 'angularx-social-login';
import {finalize} from 'rxjs/operators';
import * as toastr from 'toastr';

import {PromotorService} from '../services/promotor/promotor.service';
import {environment} from './../../environments/environment.prod';
import {MessagingService} from './../services/messaging/messaging.service';
import {ModalMessageComponent} from './../shared/modais/modal-message/modal-message.component';
import {ProdutoStorage} from './../shared/select-produto/produto.storage';
import {LoginAction} from './login-action.enum';
import {LoginService} from './login.service';
import {LoginStorage} from './login.storage';
import {LoginCredencial} from './model/login-credencial';
import {SelectAgenteResult} from './select-agente/select-agente-result';
import {SelectAgenteResultStatus} from './select-agente/select-agente-result-status';
import {SelectAgenteComponent} from './select-agente/select-agente.component';
import {ChangePasswordRequest} from './model/change-password-request';
import { HttpStatus } from '../omni-rest/http-status';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild('selecionarAgente', { static: true }) selecionarAgente: SelectAgenteComponent;
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  credencial: LoginCredencial = new LoginCredencial();
  changePasswordCredential: ChangePasswordRequest = new ChangePasswordRequest();
  message: string;
  public version = environment.VERSION_APP;
  private action: LoginAction = LoginAction.LOGIN;
  private actionToBackAfterMessage: LoginAction;

  isLoggingIn = false;

  constructor(
    private loginService: LoginService,
    private loginStorage: LoginStorage,
    private router: Router,
    private actRoute: ActivatedRoute,
    private messaging: MessagingService,
    private produtoStorage: ProdutoStorage,
    private promotorService: PromotorService
  ) { }

  ngOnInit() {
    this.promotorService.clear();
    this.loginStorage.clear();
    this.produtoStorage.clear();
    this.initSelectAgenteSubscribe();
  }

  initSelectAgenteSubscribe() {
    this.selecionarAgente.onSelectAgente.subscribe(result =>
      this.onSelectAgente(result),
    );
  }

  isLogin(): boolean {
    return this.action === LoginAction.LOGIN;
  }

  isChangePassword(): boolean {
    return this.action === LoginAction.CHANGE_PASSWORD;
  }

  isRecoveryPassword(): boolean {
    return this.action === LoginAction.RECOVERY_PASSWORD;
  }

  isMessage(): boolean {
    return this.action === LoginAction.MESSAGE;
  }

  back(): void {
    if (this.actionToBackAfterMessage) {
      this.action = this.actionToBackAfterMessage;
      this.actionToBackAfterMessage = null;
    } else {
      this.action = LoginAction.LOGIN;
    }
  }

  recoveryPassword(): void {
    this.credencial.senha = undefined;
    this.action = LoginAction.RECOVERY_PASSWORD;
  }

  changePassword(): void {
    this.changePasswordCredential.login = this.credencial.login;
    this.changePasswordCredential.oldPassword = undefined;
    this.changePasswordCredential.newPassword = undefined;
    this.changePasswordCredential.newPasswordConfirmed = undefined;
    this.action = LoginAction.CHANGE_PASSWORD;
  }

  login(): void {
    if (!this.credencial.login || !this.credencial.senha) {
      toastr.error('Informe Usuário e Senha para realizar o login');
      return;
    }
    this.credencial.login = this.credencial.login.trim();
    this.isLoggingIn = true;
    this.credencial.tokenPush = this.messaging.getTokenPush();
    this.credencial.versaoAplicacao = environment.VERSION_APP;
    this.loginService.login(this.credencial).subscribe(
      (result) => {
        this.handleUser(result);
      },
      (error) => {
        if (error.status === HttpStatus.BAD_REQUEST && error.error.messages) {
          toastr.error(error.error.messages, 'Falha ao efetuar o login');
        } else {
          toastr.error(`Usuário ou Senha inválida. ${this.getSpanIdError(error)}`, 'Falha ao efetuar o login');
        }
        this.isLoggingIn = false;
      },
    );
  }

  handleUser(userData) {
    if ('promotor' in userData.usuario || userData.usuario.tipoUsuarioTO.descricao === 'LOJISTA') {
      this.loginStorage.register(userData.usuario);
      this.loginStorage.registerDataExpiracao(userData.dataExpiracao);
      this.selecionarAgente.selecionar();
    } else {
      this.modalMessage.show('Desculpe, você não é um operador :(');
      this.isLoggingIn = false;
    }
  }

  sendRecoveryPassword(): void {
    if (!this.credencial.login) {
      toastr.error('Informe o Usuário para realizar a recuperação de sua senha');
      return;
    }
    this.loginService
      .recoveryPassword(this.credencial)
      .pipe(
        finalize(() => {
          this.isLoggingIn = false;
        })
      )
      .subscribe(
          result => {
            toastr.success('Uma senha provisória foi enviada para seu e-mail');
            this.action = LoginAction.LOGIN;
          },
          error => toastr.error(`Não foi possível atribuir uma nova senha para o usuário informado.
            Favor informar novamente ou contatar o Suporte Técnico. ${this.getSpanIdError(error)}`,
                                'Falha ao redefinir senha')
      );
  }

  sendChangePassword() {
    if (!this.changePasswordCredential.login
      || !this.changePasswordCredential.oldPassword
      || !this.changePasswordCredential.newPassword
      || !this.changePasswordCredential.newPasswordConfirmed) {
      toastr.error('Informe todos os dados para realizar a alteração da sua senha');
      return;
    }
    if (this.changePasswordCredential.newPassword !== this.changePasswordCredential.newPasswordConfirmed) {
      toastr.error('Confirmação de senha inválida');
      return;
    }
    this.loginService
      .changePassword(this.changePasswordCredential)
      .subscribe({
        next: result => {
          if (result.status === 'SUCCESS') {
            toastr.success('Senha alterada com sucesso');
            this.credencial.login = this.changePasswordCredential.login;
            this.action = LoginAction.LOGIN;
          } else {
            toastr.error(result.messages[0]);
          }
        },
        error: error => {
          if (error.status === HttpStatus.BAD_REQUEST && error.error.messages) {
            toastr.error(error.error.messages[0]);
          } else {
            toastr.error(`Não foi possível alterar a senha para o usuário informado. Favor tentar novamente ou contatar o Suporte Técnico. ${this.getSpanIdError(error)}`,
                         'Falha ao alterar a senha');
          }
        }
      });
  }

  onKeyup(event) {
    if (event.keyCode !== 13) {
      return;
    }
    if (this.action === LoginAction.LOGIN) {
      this.login();
    }
    if (this.action === LoginAction.RECOVERY_PASSWORD) {
      this.sendRecoveryPassword();
    }
    if (this.action === LoginAction.CHANGE_PASSWORD) {
      this.sendChangePassword();
    }
  }

  selectRouter() {
    this.actRoute.queryParams.subscribe((params) => {
      if ('route' in params) {
        this.router.navigate([`/${params.route}`]);
      } else if (this.promotorService.isOperadorLiberaCessao) {
        this.router.navigate(['/produtos/cdcloja/cessao']);
      } else if (typeof this.loginStorage.redirectRoute === 'string') {
        this.router.navigate([`/${this.loginStorage.redirectRoute}`]);
        this.loginStorage.clearRedirectRoute();
      } else {
        this.router.navigate(['/ficha']);
      }
    });
  }

  private onSelectAgente(result: SelectAgenteResult) {
    switch (result.status) {
      case SelectAgenteResultStatus.SUCCESS:
        this.selectRouter();
        break;
      case SelectAgenteResultStatus.ERROR:
        toastr.warning('Falha na Comunicação, verifique sua conexão.');
        this.isLoggingIn = false;
        break;
      default:
        this.isLoggingIn = false;
    }
  }

  handleSocialUser(socialUser: SocialUser): void {
    this.credencial = new LoginCredencial();
    this.credencial.socialToken = socialUser.authToken;
    this.credencial.redeSocial = socialUser.provider;
    this.login();
  }

  private getSpanIdError(error: HttpErrorResponse): string {
    if (error.error && error.error.omniSpanId) {
      return `(${error.error.omniSpanId})`;
    }

    return '';
  }
}
