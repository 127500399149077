import { SelectAgenteResult } from './select-agente-result';
import { SelectAgenteResultStatus } from './select-agente-result-status';

export class SelectAgenteResultFactory {

  public static success(): SelectAgenteResult {
    return new SelectAgenteResult(SelectAgenteResultStatus.SUCCESS);
  }

  public static error(): SelectAgenteResult {
    return new SelectAgenteResult(SelectAgenteResultStatus.ERROR);
  }

  public static canceled(): SelectAgenteResult {
    return new SelectAgenteResult(SelectAgenteResultStatus.CANCELED);
  }
}
