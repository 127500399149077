import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrar'
})
export class FindFicha implements PipeTransform {

  public datepipe: DatePipe;

  transform(propostas: any[], nomeCpf: string, numero: string, statusString: string, data: string, sos: string): any[] {

    const status = statusString === '-1' ? undefined : statusString;

    if (propostas && propostas.length) {
      return propostas.filter(item => {
        if (nomeCpf && !item.cliente.nome.toLowerCase().includes(nomeCpf.replace(/\D/g, '').toLowerCase())
        && !item.cliente.cpf.toLowerCase().includes(nomeCpf.replace(/\D/g, '').toLowerCase())) {
          return false;
        }

        if (numero && item.id.toString().indexOf(numero.toString()) === -1) {
          return false;
        }

        if (status && item.statusTimeline.idStatus !== parseInt(status)) {
          return false;
        }

        if (sos && !JSON.stringify(item.statusTimeline).includes('SOS')) {
          return false;
        }

        const days = (24 * 60 * 60 * 1000) * +data;
        if (+item.dataHoraEnvio < +(Date.now() - days)) {
          return false;
        }
        return true;
      });
    }
    return propostas;
  }
}
