import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verificacao-seguranca-identificacao',
  templateUrl: './verificacao-seguranca-identificacao.component.html',
  styleUrls: ['./verificacao-seguranca-identificacao.component.scss'],
})
export class VerificacaoSegurancaIdentificacaoComponent implements OnInit, OnDestroy {
  @Output() cpf = new EventEmitter<string>();
  form: FormGroup;
  private destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder) { }

  async ngOnInit() {
    this.buildForm();
    this.onChangeCpf();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      cpf: ['', Validators.required],
    });
  }

  private onChangeCpf(): void {
    this.form.get('cpf').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((cpf) => {
      if (cpf && cpf.length === 11) {
        this.cpf.emit(cpf);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
