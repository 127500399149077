import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Item } from './item';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements OnChanges {

  @Input() items: Item[];
  @Input() readOnly = false;
  @Input() itemSelecionado: string;

  itemDescricao: string;
  isItem = false;
  itemsSelecionar: Item[] = [];
  isShowItems = false;

  @Output() itemChange: EventEmitter<number> = new EventEmitter();

  ngOnChanges() {
    this.itemDescricao = '';
    this.itemsSelecionar = this.items;
    if (this.itemSelecionado && this.itemSelecionado.length > 0) {
      this.itemDescricao = this.itemSelecionado;
      this.isItem = true;
    }
  }

  showHideItems() {
    this.isShowItems = !this.isShowItems;
    if (this.isShowItems && this.itemDescricao) {
      this.filtrarItems(this.itemDescricao);
    }
  }

  hideItems() {
    this.isShowItems = false;
  }

  onChangeItemDescricao(value) {
    this.filtrarItems(value);
    if (this.isItem) {
      this.isItem = false;
      this.itemChange.emit(null);
    }
  }

  private filtrarItems(descricao) {
    this.isShowItems = true;
    this.itemsSelecionar = this.items.filter(item => item.descricao.toUpperCase().includes((descricao || '').toUpperCase()));
  }

  selectItem(item: Item) {
    this.itemChange.emit(item.id);
    this.itemDescricao = item.descricao;
    this.isItem = true;
    this.isShowItems = false;
  }
}
