export enum AnaliseAutomaticaStatus {
  APROVADO = 'APROVADO',
  RECUSADO = 'RECUSADO',
  PENDENTE = 'PENDENTE',
  AGUARDANDO_ANALISE_ASSINCRONA = 'AGUARDANDO_ANALISE',
}

export enum DisparoAnaliseAutomatica {
  RESULTADO_PARCIAL = 1,
  FINALIZAR_PROPOSTA = 2
}
