import { Cliente } from './../../shared/models/cliente';
import { Faef, FaefResultado } from './../cadastro/etapas/faef/model/faef';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MicrocreditoRestService } from '../../../omni-rest/microcredito/microcredito-rest.service';
import { OmniGeralRestService } from '../../../omni-rest/omni-geral/omni-geral-rest.service';

@Injectable()
export class MicrocreditoService {
  constructor(private restService: MicrocreditoRestService,
              private restServiceGeral: OmniGeralRestService) {}

  async findByProposta(propostaId: number) {
    try {
      const result = await this.restService.get(`faef/${propostaId}`).toPromise();
      return result.faefVO;
    } catch (error) {
      return null;
    }
  }

  save(faef: Faef, propostaId: number) {
    if (faef.proposta) {
      return this.restService.put('faef', { faef }).toPromise();
    }

    faef.proposta = propostaId;
    return this.restService.post('faef', { faef }).toPromise();
  }

  async calculate(faef: Faef, cliente: Cliente) {
    this.defineCliente(faef, cliente);

    const result = await this.restService.post<{ faefResultadoVO: FaefResultado }>('faef/calculate', { faef }).toPromise();
    if (faef.faefResultado) {
      const { parecerAgente, notaConfiancaCliente, valorEmprestaria } = faef.faefResultado;
      faef.faefResultado = { ...result.faefResultadoVO, parecerAgente, notaConfiancaCliente, valorEmprestaria };
    } else {
      faef.faefResultado = result.faefResultadoVO;
    }
    return faef;
  }

  defineCliente(faef: Faef, cliente: Cliente) {
    faef.faefCliente = {
      cliente: cliente.id,
      cpf: cliente.cpf,
      dataNascimento: cliente.dtNascimento,
      estadoCivil: String(cliente.estadoCivil.id),
      grauInstrucao: String(cliente.grauInstrucao),
      nacionalidade: cliente.nacionalidade.id,
      numeroDependentes: cliente.dependentes,
      sexo: cliente.sexo,
      tempoResidencia: cliente.tempoResidencia,
      tipoResidencia: String(cliente.tipoMoradia)
    };
  }

  getRelatorioCobranca(dataInicial: string,  dataFinal: string, agente: number): Observable<any> {
    const params = (agente ? { agente, dataInicial, dataFinal } : { dataInicial, dataFinal });
    return this.restService.get('relatorio/cobranca', { params });
  }

  getRelatorioCobrancaParcela(dataInicial: string,  dataFinal: string, agente: number): Observable<any> {
    const params = (agente ? { agente, dataInicial, dataFinal } : { dataInicial, dataFinal });
    return this.restService.get('relatorio/cobranca/parcela', { params });
  }

  getAgentesUsuarioLogado(): Observable<any> {
    return this.restServiceGeral.get('usuario/agentes');
  }

}
