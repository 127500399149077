import { Component, Input } from '@angular/core';

import { TimelineSosVO } from '../../../../shared/models/timelineVO';

@Component({
  selector: 'app-observacao',
  templateUrl: './observacao.component.html'
})
export class ObservacaoComponent {

  @Input() sos: TimelineSosVO;
  @Input() local: number;
  observacaoSos: string;

  get valid() {
    return this.observacaoSos;
  }

  get observacoes() {
    return this.sos.motivos.filter(acao => !acao.idDocto);
  }

  get observacaoFormatada() {
    const lines = this.sos.observacao.split('\n');
    lines[0] = `<strong>${lines[0]}</strong>`;
    return lines.join('<br>');
  }
}
