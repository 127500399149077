import { Component, EventEmitter, Input, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { finalize, take, timeout } from 'rxjs/operators';

import { RelatorioRequest } from '../models/relatorio-request';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss'],
})
export class ProductionComponent {
  @Input() relatorios: any[];
  @Input() filtros: RelatorioRequest;
  @Input() totais;
  @Input() detalhes: boolean;
  @Input() isFabrica = false;
  @Input() isParceriaDell = false;
  @Input() isCessao = false;
  @Input('page') public set value(page: any) {
    if (!page) return;
    this.page = page;
    this.setPagination();
  }

  @Output() paginationEvent: EventEmitter<{ page: number; size: number; }> = new EventEmitter();
  @Output() detalhesEvent: EventEmitter<boolean> = new EventEmitter();

  filter: any;
  size: number = 50;
  page;

  tituloRelatorio: string;
  key: string = 'nome';
  reverse: boolean = false;
  showLoader: boolean = false;
  relatorioProducao: boolean = false;
  relatorioContratoCancelado: boolean = false;
  relatorioPropostasGeradas: boolean = false;
  lojaMatriz: boolean = false;
  clicouDetalhes: boolean = false;
  todasFiliais: boolean = false;
  gerarRelatorioRequest: any;
  idLojista: any;

  constructor(
    private reportRestService: ReportsService) { }

  onClickExportarExcel() {
    this.showLoader = true;

    const dataRelatorio = moment().format('DDMMYYYY');

    if (this.clicouDetalhes) {
      this.filtros.relatorio.lojista = this.idLojista;
    }

    this.reportRestService
      .exportarRelatorio(this.filtros)
      .pipe(finalize(() => (this.showLoader = false)))
      .subscribe((data) => {
        saveAs(data, `${dataRelatorio}.xlsx`);
      });
  }

  onClickExportarExcelAgrupado() {
    this.showLoader = true;

    const dataRelatorio = moment().format('DDMMYYYY');

    this.reportRestService
      .exportarRelatorioAgrupado(this.filtros)
      .pipe(finalize(() => (this.showLoader = false)))
      .subscribe((data) => {
        saveAs(data, `${dataRelatorio}.xlsx`);
      });
  }

  sort(key: string) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  onClickChangePage(page = 0) {
    setTimeout(() => {
      this.paginationEvent.emit({ page, size: this.size });
    });
  }

  setPagination() {
    const pages = [];
    const inc = this.page.number - 2 <= 0 ? 4 - this.page.number : 2;
    const dec =
      this.page.number + 2 >= this.page.totalPages
        ? 5 - (this.page.totalPages - this.page.number)
        : 2;
    const inicio = this.page.number - dec <= 0 ? 0 : this.page.number - dec;
    const fim =
      this.page.number + inc < this.page.totalPages
        ? this.page.number + inc
        : this.page.totalPages - 1;
    for (let i = inicio; i <= fim; i++) {
      pages.push(i);
    }
    this.page.pages = pages;
  }

  get isRelatorioProducao() {
    if (Number(this.filtros.relatorio.idRelatorio) === 13) {
      this.relatorioProducao = true;
      this.tituloRelatorio = 'Relatório de produção diária';
    }

    return this.relatorioProducao;
  }

  get isRelatorioContratosCancelados() {
    if (Number(this.filtros.relatorio.idRelatorio) === 14) {
      this.relatorioContratoCancelado = true;
      this.tituloRelatorio = 'Relatório de contratos cancelados';
    }

    return this.relatorioContratoCancelado;
  }

  get isRelatorioPropostasGeradas() {
    if (Number(this.filtros.relatorio.idRelatorio) === 15) {
      this.relatorioPropostasGeradas = true;
      this.tituloRelatorio = 'Relatório de propostas geradas';
    }

    return this.relatorioPropostasGeradas;
  }

  voltar() {
    this.showLoader = true;

    const idRelatorio = '13';
    const dataInicial = this.filtros.relatorio.dataInicial;
    const dataFinal = this.filtros.relatorio.dataFinal;

    this.gerarRelatorioRequest = {
      relatorio: {
        idRelatorio,
        dataInicial,
        dataFinal
      }
    };

    this.reportRestService
      .gerarRelatorioAgrupado(this.gerarRelatorioRequest)
      .pipe(
        finalize(() => (this.showLoader = false)),
        timeout(50000),
        take(1)
      )
      .subscribe((data) => {
        this.relatorios = data.agrupado;
        this.totais = data.totais;
      });

    this.clicouDetalhes = false;
    this.detalhesEvent.emit(this.clicouDetalhes);
  }

  onClickGerarRelatorio(page, size, idLojista) {
    this.showLoader = true;

    this.idLojista = idLojista;
    const lojista = idLojista;
    const idRelatorio = '13';
    const dataInicial = this.filtros.relatorio.dataInicial;
    const dataFinal = this.filtros.relatorio.dataFinal;

    this.gerarRelatorioRequest = {
      relatorio: {
        lojista,
        idRelatorio,
        dataInicial,
        dataFinal,
      },
    };

    this.reportRestService
      .gerarRelatorio(page, size, this.gerarRelatorioRequest)
      .pipe(
        finalize(() => (this.showLoader = false)),
        timeout(50000),
        take(1)
      )
      .subscribe((data) => {
        this.page = data;
        this.relatorios = data.content;
      });

    this.clicouDetalhes = true;
    this.detalhesEvent.emit(this.clicouDetalhes);
  }

  get isFabricaEmpty() {
    const valoresFabricas = this.relatorios.map((r) => r.valoresFabricas)[0];
    if (!valoresFabricas) {
      return true;
    }
    return this.relatorios.map((r) => r.valoresFabricas)[0].length === 0;
  }

  get isDataPgmtEmpty() {
    const datasPgmt = this.relatorios.filter(
      (r) => r.dataPagamento !== undefined
    )[0];
    if (!datasPgmt) {
      return true;
    }
    return (
      this.relatorios.filter((r) => r.dataPagamento !== undefined)[0].length ===
      0
    );
  }

  get isLojaMatriz() {
    this.clicouDetalhes = this.detalhes;

    if (Number(this.filtros.relatorio.lojista) === 0 && !this.clicouDetalhes) {
      this.lojaMatriz = true;
    } else {
      this.lojaMatriz = false;
    }

    return this.lojaMatriz;
  }
}
