import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { appSettings } from '../../../../../../environments/app.setings';
import { OmniFacilUploadRestService } from '../../../../../omni-rest/omni-facil-upload/omni-facil-upload-rest.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { ConfirmModalComponent } from '../../../../../shared/timer-token/confirm-modal/confirm-modal.component';
import { UploadArquivosComponent } from '../../../../../shared/upload-arquivos/upload-arquivos.component';
import { Proposta, PropostaMercadoria } from '../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { CdcLojaService } from '../../../services/cdc-loja.service';
import { DocumentoVO } from './../../../../../shared/upload-arquivos/documentoVO';
import { AutenticacaoClienteService } from './autenticacao/autenticacao-cliente.service';

@Component({
  selector: 'app-identificacao',
  templateUrl: './identificacao.component.html',
  styleUrls: ['./identificacao.component.scss']
})
export class IdentificacaoComponent implements OnInit, OnDestroy {
  @ViewChild('modalAvisos', { static: true }) modalAvisos: NewModalMessageComponent;
  @ViewChild(UploadArquivosComponent, { static: true }) uploadArquivos: UploadArquivosComponent;

  @Input() numeroEtapaAtual: number;
  @Input() showLoaderPhases = false;
  @Input() showTryAgainButton = false;
  @Input() biometriaAtiva = false;

  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
  @Output() analisarPropostaNovamente = new EventEmitter<void>();

  lojistaPiloto: number[] = [119011, 119011, 119009, 119005, 124350];
  proposta: Proposta;
  showLoader: boolean = true;
  identificacaoForm: FormGroup;
  produtos: number[] = [];
  quantidadeProdutos: number = 0;

  documents: DocumentoVO[] = [
    {
      idDocto: 5,
      descDocto: 'Documento de Identificação'
    },
    {
      idDocto: 7,
      descDocto: 'Comprovante de Renda'
    },
    {
      idDocto: 6,
      descDocto: 'Comprovante de Residência'
    }
  ];

  fotosEnviadas: number = 0;
  isAceitaImagem: boolean = false;
  isAutenticacaoManual: boolean = false;
  isAutenticacaoFinalizada: boolean = false;

  isAlive: boolean = true;
  valid: boolean = false;

  formSubscription: Subscription[] = [];
  biometriaSubscription: Subscription[] = [];

  fluxoFotoObrigatoria: boolean = false;
  hasWebcam: boolean = false;
  showValidacaoCelular = false;
  showValidacaoBiometria = false;

  constructor(
    private fb: FormBuilder,
    private omniRestService: OmniFacilUploadRestService,
    private changeDetectorRef: ChangeDetectorRef,
    private state: PropostaStateService,
    private modal: BsModalService,
    private autenticacaoClienteService: AutenticacaoClienteService,
    private cdcLojaService: CdcLojaService) { }

  ngOnInit() {
    this.proposta = this.state.getLast();
    this.verifyConjuge(this.proposta);
    this.initForm();
    this.initObservers();
    this.checkBiometriaAtiva();
    this.validaCenariosBiometria();
    this.showLoader = false;
    this.biometriaAtiva = this.proposta.lojista.liberadoBiometria;
  }

  ngOnDestroy() {
    this.isAlive = false;

    if (this.formSubscription) {
      this.formSubscription.forEach(s => s.unsubscribe());
    }

    if (this.biometriaSubscription) {
      this.biometriaSubscription.forEach(s => s.unsubscribe());
    }
  }

  private initForm() {
    // remover a biometria do form
    this.identificacaoForm = this.fb.group({
      biometria: [null],
      documentoOpcional: [''],
      observacoes: [this.proposta.observacoes || '']
    });

    this.identificacaoForm.addControl('valorTotalProdutos', this.fb.control('0'));
    this.identificacaoForm.addControl('valorSolicitado', this.fb.control(this.proposta.valorLiquido));

    if (this.proposta.mercadorias) {
      this.proposta.mercadorias
        .forEach(this.fillMercadoriaForm.bind(this));
    }
  }

  private initObservers(): void {
    this.formSubscription.push(this.identificacaoForm
      .valueChanges.pipe(
        takeWhile(() => this.isAlive))
      .subscribe(() => this.handleIdentificacaoFormChanges()));

    this.biometriaSubscription.push(this.autenticacaoClienteService.autenticacaoClienteRealizada.subscribe((value) => {
      if (value) {
        this.isAutenticacaoFinalizada = true;
        this.isAutenticacaoManual = value.isAutenticacaoManual;

        if (this.isAutenticacaoManual) {
          const { biometria, celularFoiValidado } = value.artefatos;
          this.proposta.transients.selfie = biometria;
          this.proposta.transients.isConfirmadoTelefone = celularFoiValidado;
          this.state.setState(this.proposta);
        }
        this.handleIdentificacaoFormChanges();
      }
    }));
  }

  private handleIdentificacaoFormChanges(): void {
    const identificacao = this.identificacaoForm.value;
    this.calculateValorTotal();

    if (this.fluxoFotoObrigatoria) {
      if (this.proposta.transients.selfie === null || this.proposta.transients.selfie === undefined) {
        this.proposta.transients.selfie = this.identificacaoForm.get('biometria').value;
      }
    }
    this.valid = this.identificacaoForm.valid && this.valorMercadoriasIgualSolicitado && !!this.isBiometriaRealizadaComFoto() && !!this.isBiometriaRealizada();
    this.proposta.observacoes = identificacao.observacoes;
  }

  private isBiometriaRealizadaComFoto() {
    if (!this.fluxoFotoObrigatoria) {
      return true;
    }
    return !!this.proposta.transients.selfie;
  }

  private isBiometriaRealizada(): boolean {

    if (this.isLojistaPiloto) {
      if (!this.isAutenticacaoFinalizada) {
        return false;
      }

      if (this.isAutenticacaoManual) {
        return !!this.proposta.transients.selfie;
      }
    }
    return true;
  }

  private calculateValorTotal(): void {
    const valores: number[] = [];
    this.proposta.mercadorias = [];

    Object.keys(this.identificacaoForm.getRawValue()).forEach(
      key => {
        if (key.startsWith('mercadoriaForm')) {
          const mercadoria = this.identificacaoForm.get(key).value;
          this.proposta.mercadorias.push({
            tipoMercadoria: mercadoria.tiposMercadoria,
            mercadoria: mercadoria.mercadoria,
            valor: mercadoria.valorProduto
          });
          valores.push(mercadoria.valorProduto || 0.00);
        }
      }
    );

    let valorTotal: number = 0;
    if (valores.length > 0) {
      valorTotal = valores.reduce(
        (prev, curr) => Number(prev) + Number(curr)
      );
    }

    this.identificacaoForm.get('valorTotalProdutos').patchValue(
      valorTotal, { emitEvent: false }
    );
  }

  private async uploadArquivosIdentificacao() {
    const biometria = this.identificacaoForm.value.biometria;
    if (biometria) {
      if (biometria instanceof WebcamImage) {
        const imageAsFile: File = this.createFileFromImageUri(biometria);
        await this.uploadImagemToOmniDoc(imageAsFile);
      } else {
        await this.uploadImagemToOmniDoc(biometria);
      }
    }
    await this.uploadArquivos.sendDocuments();
  }

  private async uploadImagemToOmniDoc(file: File) {
    this.scrollTop();
    this.showLoaderPhases = true;

    try {
      const origem = 6;
      await this.omniRestService
        .post(file, { ...this.proposta, origem }, 119)
        .toPromise();
      this.handleSucessoEnvioImagem();
    } catch (error) {
      this.modalAvisos.showModal({
        titulo: 'Erro',
        mensagem: 'Houve um erro ao enviar a foto.'
      });
      throw error;
    } finally {
      this.showLoader = false;
      this.showLoaderPhases = false;
    }
  }

  private handleSucessoEnvioImagem(): void {
    ++this.fotosEnviadas;
    this.isAceitaImagem = true;
    this.handleIdentificacaoFormChanges();
  }

  private createFileFromImageUri(imagemAtual): File {
    const base64image = (<WebcamImage>imagemAtual).imageAsBase64;
    const fileName = `${this.randomString}${this.randomString}${this.randomString}${this.randomString}`;
    const completeFileName = `${fileName}.jpeg`;
    const blobImage = this.base64ToBlob(base64image);
    return new File([blobImage], completeFileName, { type: 'image/jpeg' });
  }

  private base64ToBlob(base64image: string) {
    const byteString = atob(base64image);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    byteString.split('').forEach(
      (chr, i) => {
        int8Array[i] = chr.charCodeAt(0);
      }
    );

    return new Blob([arrayBuffer], { type: 'image/jpeg' });
  }

  addProduto(): void {
    this.produtos.push(this.quantidadeProdutos++);
    this.changeDetectorRef.detectChanges();
  }

  filterProdutos(): number[] {
    return this.produtos.filter(produto => produto !== -1);
  }

  destroyProduto($event: number): void {
    this.produtos[$event] = -1;
  }

  onClickNext() {
      this.finalizar(); 
  }

  private prosseguir() {
    this.proposta = this.state.getLast();
    if (!this.proposta.transients.isConfirmadoTelefone) {
      return this.openModalConfirmarProsseguir();
    }
    return true;
  }

  private openModalConfirmarProsseguir() {
    const modal = this.modal.show(ConfirmModalComponent, { ...appSettings.MODAL_PARAMS });
    const confirm = <ConfirmModalComponent>modal.content;
    confirm.confirmValue
      .pipe(takeWhile(() => confirm.alive))
      .subscribe(value => {
        if (value) {
          this.finalizar();
        }
      });
  }
  async finalizar() {
    try {
      this.handleIdentificacaoFormChanges();
      await this.uploadArquivosIdentificacao();
      this.state.setState(this.proposta);
      this.next.emit();
    } catch (error) {
      console.error(error);
    }
  }

  onClickPrevious() {
    this.handleIdentificacaoFormChanges();
    this.state.setState(this.proposta);
    this.previous.emit();
  }

  private fillMercadoriaForm(produto: PropostaMercadoria, index: number) {
    this.addProduto();

    setTimeout(
      () => {
        const form = <FormGroup>this.identificacaoForm.controls[`mercadoriaForm_${index}`];
        const disabled = form.disabled;
        form.enable();
        form.setValue({
          mercadoria: produto.mercadoria,
          tiposMercadoria: produto.tipoMercadoria,
          valorProduto: produto.valor
        });

        if (disabled) {
          form.disable();
        }
      },
      500);
  }

  private verifyConjuge(proposta) {
    const classeProfisional = Number(proposta.cliente.dadosProfissionais.classeProfissional.id);
    if (this.isNotOtherClasseProfissional(classeProfisional)) {
      proposta.conjuge = null;
    }
  }

  private isNotOtherClasseProfissional(classeProfissional: number): boolean {
    return classeProfissional !== 9;
  }

  get randomString(): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substring(2);
  }

  get valorSolicitado(): number {
    return Number(this.identificacaoForm.get('valorSolicitado').value);
  }

  get valorTotalProdutos(): number {
    return Number(this.identificacaoForm.get('valorTotalProdutos').value);
  }

  get valorMercadoriasIgualSolicitado(): boolean {
    return this.valorSolicitado.toFixed(2) === this.valorTotalProdutos.toFixed(2);
  }

  onClickTryAgain() {
    this.analisarPropostaNovamente.emit();
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  isEnabledFotoAndEnviar(): boolean {
    return this.fotosEnviadas < 3;
  }

  get isLojistaPiloto(): boolean {
    return this.proposta.lojista.liberadoBiometria;
  }

  private checkBiometriaAtiva() {
    this.cdcLojaService.checkParametro('FLAG_FLUXO_FOTO_OBRIG').subscribe((response) => {
      if (response.key === 'FLAG_FLUXO_FOTO_OBRIG') {
        this.fluxoFotoObrigatoria = response.value === 'S';
      }
    });
  }

  private validaCenariosBiometria() {
    if (this.isLojistaPiloto) {
      this.showValidacaoCelular = false;
      this.showValidacaoBiometria = true;
    } else {
      this.showValidacaoCelular = true;
      this.showValidacaoBiometria = false;

    }
  }

  private initWebcam() {
    WebcamUtil.getAvailableVideoInputs()
      .then(
        (mediaDevices: MediaDeviceInfo[]) => {
          this.hasWebcam = mediaDevices && mediaDevices.length > 0;
        }
      ).catch((_) => {
        this.hasWebcam = false;
      });
    this.hasWebcam;
  }
}
