import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take, finalize, takeWhile } from 'rxjs/operators';

import { NewModalMessageComponent } from '../../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TipoProdutoEnum } from '../../../../../shared/enums/tipo-produto.enum';
import { CdcLojaService } from '../../../../services/cdc-loja.service';
import { PropostaStateService } from '../../../../../shared/states/proposta-state.service';
// TODO remover este component
@Component({
  selector: 'app-telefone-base',
  templateUrl: './telefone-base.component.html',
  styleUrls: ['./telefone-base.component.css']
})
export class TelefoneBaseComponent implements OnInit, OnDestroy {
  @Input() needsConfirmation: boolean;
  @Input() isRemovable: boolean;
  @Input() index?: number = -1;
  @Input() detalhesClienteForm: FormGroup;
  @Input() tipoProduto: TipoProdutoEnum;
  @Input() isConfirmado: boolean = false;
  @Input() adicional: boolean = false;
  @Output() telefoneDestroyEventEmitter: EventEmitter<number> = new EventEmitter();
  @Output() confirmacaoEmitter: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('smsModal', { static: true }) smsModal: NewModalMessageComponent;

  telefoneForm: FormGroup;
  smsForm: FormGroup;

  isResidencial: boolean = false;
  showLoader: boolean = false;
  isAlive: boolean = true;

  idProposta: string;

  constructor(
    private fb: FormBuilder,
    private state: PropostaStateService,
    private cdcLojaService: CdcLojaService
  ) {}

  ngOnInit() {
    this.initForms();

    const proposta = this.state.getLast();
    this.idProposta = !proposta.id || proposta.id === -1 ? '11999065' : `${proposta.id}`;
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initForms(): void {
    let tipoDefault = this.tipoCelular;
    if (this.index > 0) {
      const tipoAnterior = this.detalhesClienteForm.get('telefoneForm_0').get('tipoTelefone');
      tipoAnterior.disable({ emitEvent: false });

      if (tipoAnterior.value === tipoDefault) {
        tipoDefault = 'RESIDENCIAL';
        this.isResidencial = true;
      }
    }

    this.telefoneForm = this.fb.group({
      tipoTelefone: [tipoDefault, Validators.required],
      telefone: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(15)]]
    });

    if (this.index > 0) {
      this.tipoTelefone.disable();
    }

    if (this.isConfirmado) {
      this.telefoneForm.disable();
    }

    if (!this.isRemovable) {
      this.smsForm = this.fb.group({
        codigoConfirmacao: ['', Validators.required]
      });
    }

    this.detalhesClienteForm.addControl(`telefoneForm_${this.index}`, this.telefoneForm);

    this.tipoTelefone
      .valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => this.onChangePhoneType(this.tipoTelefone.value));
  }

  sendConfirmacaoSms(): void {
    if (this.telefoneForm.invalid) {
      this.handleError('Telefone inválido!');
      return;
    }

    this.showLoader = true;
    this.cdcLojaService.enviarSms({
      proposta: {
        cliente: {
          telefones: [{
            categoriaTelefone: this.tipoTelefone.value,
            ddd: this.telefone.value.replace(/\D/g, '').substring(0, 2),
            nrTelefone: this.telefone.value.replace(/\D/g, '').substring(2)
          }]
        },
        id: Number(this.idProposta)
      }
    }).pipe(
      finalize(() => this.showLoader = false),
      take(1)
    )
    .subscribe(
      res => this.handleEnvioConfirmacaoSms(res),
      error => this.handleEnvioConfirmacaoSmsError(error));
  }

  private handleEnvioConfirmacaoSms(res: any): void {
    if (res.status === 'SUCCESS') {
      return this.smsModal.showModal({
        titulo: 'Sucesso',
        mensagem: 'Código de confirmação enviado com sucesso. Sua mensagem chegará em instantes.'
      });
    }
    this.handleEnvioConfirmacaoSmsError(res);
  }
  private handleEnvioConfirmacaoSmsError(error: any) {
    return this.handleError('Houve uma falha no envio do SMS de confirmação.');
  }

  private handleError(error: string) {
    return this.smsModal.showModal({
      isTituloVermelho: true,
      titulo: 'Falha',
      mensagem: error
    });
  }

  handleConfirmacao(): void {
    if (this.codigoConfirmacao.valid) {
      this.showLoader = true;

      this.cdcLojaService
        .verificarToken(this.idProposta, this.codigoConfirmacao.value, this.telefone.value.replace(/\D/g, ''))
        .pipe(
          finalize(() => this.showLoader = false),
          takeWhile(() => this.isAlive)
        )
        .subscribe(
          res => this.handleConfirmacaoSuccess(res),
          error => this.handleConfirmacaoError(error)
        );
    }
  }

  private handleConfirmacaoSuccess(res: any): any {
    if (res.status === 'SUCCESS') {
      this.isConfirmado = true;
      this.confirmacaoEmitter.next(true);
      this.telefone.disable();
    } else {
      this.handleConfirmacaoError(res);
    }
  }

  private handleConfirmacaoError(_: any): void {
    this.smsModal.showModal({
      titulo: 'Falha',
      mensagem: 'Código de verificação inválido. Por favor, tente novamente.'
    });
  }

  selfDestruct(): void {
    this.detalhesClienteForm.removeControl(`telefoneForm_${this.index}`);
    this.telefoneDestroyEventEmitter.emit(this.index);

    if (this.index > 0) {
      const tipoAnterior = this.detalhesClienteForm.get('telefoneForm_0').get('tipoTelefone');
      tipoAnterior.enable({ emitEvent: false });
    }

  }

  onChangePhoneType(phoneType): void {
    this.telefoneForm.get('telefone').setValue('');
    this.isResidencial = phoneType === 'RESIDENCIAL';
  }

  get tipoTelefone(): AbstractControl {
    return this.telefoneForm.get('tipoTelefone');
  }

  get telefone(): AbstractControl {
    return this.telefoneForm.get('telefone');
  }

  get codigoConfirmacao(): AbstractControl {
    return this.smsForm.get('codigoConfirmacao');
  }

  get isCreditoPessoal(): boolean {
    return this.tipoProduto === TipoProdutoEnum.CREDITO_PESSOAL;
  }

  get isCdcLoja(): boolean {
    return this.tipoProduto === TipoProdutoEnum.CDC_LOJA;
  }

  get tipoCelular(): string {
    if (this.adicional) {
      return 'CELULAR_ADICIONAL';
    }
    return 'CELULAR';
  }
}
