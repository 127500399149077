import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-popover';
import { PerfilClienteComponent } from './perfil-cliente.component';
import { NgRatingBarModule } from 'ng-rating-bar';

@NgModule({
  imports: [CommonModule, FormsModule, PopoverModule, NgRatingBarModule],
  declarations: [PerfilClienteComponent],
  exports: [PerfilClienteComponent]
})
export class PerfilClienteModule {}
