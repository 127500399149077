import { appSettings } from './../../../../environments/app.setings';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FichaService } from '../../ficha-veiculo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FichaModalAvalistaComponent } from '../ficha-modal-avalista/ficha-modal-avalista.component';
import { AvalistaComponent } from '../ficha-timeline-sos/avalista/avalista.component';

@Component({
  selector: 'app-ficha-timeline-add-avalista',
  templateUrl: './ficha-timeline-add-avalista.component.html',
  styleUrls: ['./ficha-timeline-add-avalista.component.css']
})
export class FichaTimelineAddAvalistaComponent implements OnInit {

  @ViewChild(AvalistaComponent, { static: true }) private avalistaComponent: AvalistaComponent;
  @Input() proposta: any;
  @Input() idFicha: any;
  @Input() cpfCliente: any;
  bsModalRef: BsModalRef;
  isHabilitarAddAvalista: boolean = false;

  constructor(
    private fichaService: FichaService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.idFicha = this.proposta.proposta.id;
    this.isHabilitarAddAvalista = (this.proposta.status === 2
      || this.proposta.status === 3 || this.proposta.status === 4
        || this.proposta.status === 5 || this.proposta.status === 6
          || this.proposta.status === 7) && this.proposta.proposta.possuiAvalista === 'N';
  }

  abrirInclusaoAvalista() {
    this.bsModalRef = this.modalService.show(FichaModalAvalistaComponent, appSettings.MODAL_PARAMS);
    this.bsModalRef.content.cpfCliente = this.proposta.cliente.cpf;
    (<FichaModalAvalistaComponent>this.bsModalRef.content).onCloseModal.subscribe(body => {
      this.proposta = body;
      this.proposta.id = this.idFicha;
      this.fichaService.pushAvalista({ proposta: this.proposta })
      .subscribe(
        (res: any) => this.isHabilitarAddAvalista = false,
        (error: any) => console.log(error)
      );
    });
  }
}
