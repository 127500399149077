import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, take, takeWhile } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap';
import * as moment from 'moment';
import * as toastr from 'toastr';

import { appSettings } from '../../../../../../environments/app.setings';
import { PropostaSimulacao } from '../../../../shared/models/proposta-simulacao';
import { Parcela } from './parcela/parcela';
import { ParcelaPersonalizadaComponent } from './parcela-personalizada/parcela-personalizada.component';
import { AlterarValorComponent } from '../../../../shared/components/cadastro-fichas-cdc/alterar-valor/alterar-valor.component';
import { ProdutosComponent } from '../../../../shared/components/cadastro-fichas-cdc/produtos/produtos.component';
import { Proposta } from '../../../../shared/models/proposta';
import { AlteraTabelaComponent } from './altera-tabela/altera-tabela.component';
import { RetencaoComponent } from './retencao/retencao.component';
import { CdcLojaService } from '../../../services/cdc-loja.service';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';
import { ParametroRendaProduto } from '../../../../shared/models/parametro-renda-produto';

@Component({
  selector: 'app-resultado-parcial',
  templateUrl: './resultado-parcial.component.html',
  styleUrls: ['./resultado-parcial.component.scss']
})
export class ResultadoParcialComponent implements OnInit, OnDestroy {
  @Input() parametroRendaProduto: ParametroRendaProduto;
  @Input() showTryAgainButton = false;
  @Input() showLoaderPhases = false;
  @Output() next = new EventEmitter<void>();
  @Output() analisarPropostaNovamente = new EventEmitter<void>();

  propostaSimulacao: PropostaSimulacao;
  propostaBackup: Proposta;
  form: FormGroup;
  showLoader = false;
  parcelas: Parcela[];
  parcelaMax: number;
  alive = true;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private state: PropostaStateService,
    private cdcLojaService: CdcLojaService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initForm();
    this.simula();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private initForm() {
    this.propostaSimulacao = new PropostaSimulacao();
    this.propostaSimulacao.proposta = this.state.getLast();
    this.propostaBackup = { ...this.propostaSimulacao.proposta };

    const units: moment.unitOfTime.DurationConstructor = 'days';
    this.propostaSimulacao.simulacao.primeiraParcela = moment().add((this.propostaSimulacao.proposta.carencia || 0) + 30, units).unix();

    this.form = this.fb.group({
      valorFinanciamento: [`${this.propostaSimulacao.proposta.valorLiquido}`, Validators.required],
      quantidadeParcelas: ['', Validators.required]
    });

    this.form.get('quantidadeParcelas')
      .valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(e => this.onChangeParcela(e));

  }

  private calculateParcelas(): void {
    this.parcelas = this.propostaSimulacao.simulacao.termsOfPayment.map((term) => new Parcela(term.period, term.amount, term.retentionFee, term.retention, term.net));
    this.parcelaMax = this.parcelas[0].valor;
  }

  simula(): void {
    this.showLoader = true;

    this.cdcLojaService
      .newSimulacao(this.propostaSimulacao.proposta)
      .pipe(
        take(1),
        finalize(() => this.showLoader = false),
        catchError(error => {
          this.propostaSimulacao.proposta = this.propostaBackup;
          this.state.setState(this.propostaBackup);
          toastr.warning('Favor selecionar outra Tabela ou Alterar Valor Solicitado.', 'Parâmetros não encontrados');
          throw (error);
        })
      )
      .subscribe(res => this.handleSimulacao(res));
  }

  private handleSimulacao(simulacao: any) {
    this.propostaSimulacao.simulacao.paymentProtectionInsurances = simulacao.paymentProtectionInsurances || [];
    this.propostaSimulacao.simulacao.assistances = simulacao.assistances || [];
    this.propostaSimulacao.simulacao.termsOfPayment = simulacao.termsOfPayment || [];
    this.propostaSimulacao.simulacao.gracePeriodRange = simulacao.gracePeriodRange;
    this.propostaSimulacao.simulacao.maxPeriod = simulacao.maxPeriod;
    this.propostaSimulacao.simulacao.minPeriod = simulacao.minPeriod;

    const parcelaForm = Number(this.propostaSimulacao.proposta.qtdeParcelas);
    let parcelaSelecionada = this.propostaSimulacao.simulacao.termsOfPayment.filter(e => e.period === parcelaForm)[0];
    if (!parcelaSelecionada) {
      if (this.propostaSimulacao.simulacao.termsOfPayment.length === 1) {
        parcelaSelecionada = this.propostaSimulacao.simulacao.termsOfPayment[0];
      } else if (this.propostaSimulacao.simulacao.termsOfPayment.length > 1) {
        parcelaSelecionada = this.propostaSimulacao.simulacao.termsOfPayment[1];
      }
    }

    parcelaSelecionada = parcelaSelecionada || {};
    this.propostaSimulacao.proposta.qtdeParcelas = parcelaSelecionada.period;

    if (simulacao.firstPaymentDate) {
      this.propostaSimulacao.simulacao.primeiraParcela = simulacao.firstPaymentDate / 1000 + 3 * 3600;
      const diaMes = moment.unix(this.propostaSimulacao.simulacao.primeiraParcela).format('YYYY-MM-DD');
      this.propostaSimulacao.proposta.carencia = this.diasAte(diaMes) - 30;
    } else {
      const units: moment.unitOfTime.DurationConstructor = 'days';
      this.propostaSimulacao.simulacao.primeiraParcela = moment().add(this.propostaSimulacao.proposta.carencia + 30, units).unix();
    }

    this.calculateParcelas();

    this.form.setValue({
      valorFinanciamento: simulacao.net,
      quantidadeParcelas: this.propostaSimulacao.proposta.qtdeParcelas || '',
    });

    const seguroDefault = this.propostaSimulacao.simulacao.paymentProtectionInsurances.filter(seguro => seguro.selected)[0];
    if (seguroDefault) {
      this.propostaSimulacao.proposta.seguro = seguroDefault.id;
    } else {
      this.propostaSimulacao.proposta.seguro = 0;
    }

    const assistenciaDefault = this.propostaSimulacao.simulacao.assistances.filter(assistencia => assistencia.selected)[0];
    if (assistenciaDefault) {
      this.propostaSimulacao.proposta.assistencia = assistenciaDefault.id;
    } else {
      this.propostaSimulacao.proposta.assistencia = 0;
    }

    this.state.setState(this.propostaSimulacao.proposta);
    this.changeDetectorRef.detectChanges();
  }

  onAlterarValor(valorFinanciamento) {
    this.changeProposta(() => this.propostaSimulacao.proposta.valorLiquido = valorFinanciamento);
  }

  onPersonalizarParcela(form) {
    this.changeProposta(() => this.propostaSimulacao.proposta.qtdeParcelas = form.quantidadeParcelas);
  }

  onPersonalizarTabela(tabela) {
    this.changeProposta(() => this.propostaSimulacao.proposta.tabela = tabela);
  }

  private changeProposta(change: Function) {
    this.propostaBackup = { ...this.propostaSimulacao.proposta };

    change();

    this.simula();
  }

  onChangeParcela(quantidade: number) {
    if (!quantidade) {
      return;
    }

    const parcela = this.parcelas.filter(e => e.numeroParcelas === quantidade)[0] || this.parcelas[0];
    this.propostaSimulacao.proposta.qtdeParcelas = parcela.numeroParcelas;
    this.propostaSimulacao.proposta.valorParcela = parcela.valor;

    this.propostaSimulacao.proposta.transients.parcela = parcela;

  }

  onChangePrimeiraParcela(data: number) {
    this.changeProposta(() => {
      this.propostaSimulacao.simulacao.primeiraParcela = data;

      const diaMes = moment.unix(data).format('YYYY-MM-DD');
      const carencia = this.diasAte(diaMes);
      this.propostaSimulacao.proposta.carencia = carencia - 30;
    });
  }

  private diasAte(diaMes): number {
    return Math.round(moment.duration(
      moment(`${diaMes} 03:00:00`)
        .startOf('day')
        .diff(
          moment({ h: 3, m: 0, s: 0, ms: 0 })
        )
    ).asDays());
  }

  onChangeProduto({ seguro, assistencia }) {
    this.changeProposta(() => {
      this.propostaSimulacao.proposta.seguro = seguro;
      this.propostaSimulacao.proposta.assistencia = assistencia;
    });
  }

  abrirModalParcelaPersonalizada() {
    const modal = this.modalService.show(ParcelaPersonalizadaComponent, appSettings.MODAL_PARAMS);
    const maxPeriod = this.propostaSimulacao.simulacao.maxPeriod;
    const minPeriod = this.propostaSimulacao.simulacao.minPeriod;

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe(form => this.onPersonalizarParcela(form));

    modal.content.gerarParcelas(maxPeriod, minPeriod);
  }

  abrirModalAlterarValor() {
    if (this.propostaSimulacao.proposta.parametroRendaProduto) {
      this.parametroRendaProduto = this.propostaSimulacao.proposta.parametroRendaProduto;
    }

    const modal = this.modalService.show(AlterarValorComponent, { ...appSettings.MODAL_PARAMS, initialState: { parametroRendaProduto: this.parametroRendaProduto } });

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe(valor => this.onAlterarValor(valor));
  }

  abrirModalProdutos() {
    const modal = this.modalService.show(ProdutosComponent, appSettings.MODAL_PARAMS);
    modal.content.init(this.propostaSimulacao);

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe(produto => this.onChangeProduto(produto));
  }

  abrirModalTabelas() {
    const modal = this.modalService.show(AlteraTabelaComponent, { ...appSettings.MODAL_PARAMS, initialState: { proposta: this.propostaSimulacao.proposta } });

    modal.content.onSave
      .pipe(takeWhile(() => modal.content.alive))
      .subscribe(tabela => this.onPersonalizarTabela(tabela));
  }

  abrirModalRetencao() {
    const modal = this.modalService.show(RetencaoComponent, appSettings.MODAL_PARAMS);
    modal.content.fillParcela(this.propostaSimulacao.proposta.transients.parcela);
  }

  onClickNext() {
    this.state.setState(this.propostaSimulacao.proposta);
    this.next.emit();
  }

  get isCessao() {
    return this.propostaSimulacao.proposta.cessao || this.propostaSimulacao.proposta.operacao.cessao;
  }

  onClickTryAgain() {
    this.analisarPropostaNovamente.emit();
  }
}
