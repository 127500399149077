import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  @Input() valorMin: number = 0;
  @Input() valorMax: number = 4;
  @Input() passo: number = 1;
  @Input() valorInicial: number = this.valorMax / 2;
  @Input() ticks: number[] = [this.valorMin, this.valorMax];
  @Input() mostrarValor: string = 'hide';
  @Output() outputEmitter: EventEmitter<number> = new EventEmitter();

  ngOnInit() {
    this.initSlider();
    this.setupSlider();
  }

  private initSlider(): void {
    $('#slider-component').slider({
      step: this.passo,
      min: this.valorMin,
      max: this.valorMax,
      value: this.valorInicial,
      ticks: this.ticks,
      ticks_tooltip: false,
      tooltip: this.mostrarValor
    });
  }

  private setupSlider(): void {
    $('#slider-component').on('change', e => this.outputEmitter.emit(e.value.newValue));
  }
}
