
import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmptyObj } from '../helper/utils';
import { ProdutoStorage } from './produto.storage';
import { SelectProdutoService } from './select-produto.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-select-produto',
  templateUrl: './select-produto.component.html',
  styleUrls: ['./select-produto.component.css']
})
export class SelectProdutoComponent implements OnInit {
  produtos: any;
  selecionados: any;
  isAllSelected: boolean = false;

  @Input() disabled = false;
  @Input() isPanel: false;
  @Output() filtrarPorProduto: EventEmitter<any> = new EventEmitter();

  constructor(private service: SelectProdutoService, private produtoStorage: ProdutoStorage) {
    this.selecionados = [];
  }

  ngOnInit() {
    this.service.getProdutos()
      .pipe(map(this.checkProduto))
      .subscribe((produtos) => {
        this.produtos = produtos.filter(item => environment.SHOW_CP || item.descricao !== 'CRÉDITO PESSOAL');
        if (!isEmptyObj(this.produtoStorage.tipoOperacao)) {
          this.produtos = this.produtos.map(produto => {
            produto.checked = this.produtoStorage.tipoOperacao.find(value => value.descricao === produto.descricao);
            return produto;
          });
        }
        this.selectAll();
      });
  }

  checkProduto(res) {
    return res.map(value => {
      return {
        descricao: value.descricao,
        checked: true
      };
    });
  }

  saveProdutosStorage() {
    if (isEmptyObj(this.produtoStorage.tipoOperacao)) {
      this.produtoStorage.save(this.produtos);
    }
    this.selecionados = this.produtoStorage.tipoOperacao;
    this.emitProduto(this.selecionados);
  }

  selectAll() {
    if (!this.isAllSelected) {
      this.produtos = this.produtos.map((item) => {
        return { ...item, checked: false };
      });

      this.selecionados = [];
      this.produtoStorage.save(this.selecionados);
      this.emitProduto(this.selecionados);
    }
    this.isAllSelected = true;
  }

  select(produtoDOM, produtoObj) {
    this.isAllSelected = false;

    const prod = {
      ...produtoObj,
      checked: !produtoObj.checked
    };

    produtoDOM.target.checked
      ? this.selecionados.push(prod)
      : this.setSelecionados(prod);

    this.produtoStorage.save(this.selecionados);
    this.emitProduto(this.selecionados);
  }

  setSelecionados(prod) {
    this.selecionados = this.selecionados
          .filter(ele => ele.descricao !== prod.descricao);
  }

  private emitProduto(produtosSelecionados): void {
    const produtos = produtosSelecionados.map(produto => {
      return {
        ...produto,
        descricaoNormalized: produto.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      };
    });

    this.filtrarPorProduto.emit(produtos);
  }

}
