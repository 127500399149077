import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { UfService } from '../../../services/uf/uf.service';
import { CidadeService } from '../../../services/cidade/cidade.service';
import { CepService } from './../../../services/cep/cep.service';
import { NewModalMessageComponent } from './../../../shared/modais/new-modal-message/new-modal-message.component';
import { EnderecoMultiplo } from '../../../services/cep/endereco-multiplo';
import { ModalMultiplosEnderecosComponent } from '../modal-multiplos-enderecos/modal-multiplos-enderecos.component';
import { appSettings } from '../../../../environments/app.setings';

@Component({
  selector: 'app-modal-encontrar-cep',
  templateUrl: './modal-encontrar-cep.component.html',
  styleUrls: ['./modal-encontrar-cep.component.scss']
})
export class ModalEncontrarCepComponent implements OnInit, OnDestroy {

  @Input() listaEnderecos: any;

  @ViewChild('cepInvalidoModal', { static: true }) cepInvalidoModal: NewModalMessageComponent;

  formEncontrarCep: FormGroup;
  closeModal: Subject<any> = new Subject();
  alive = true;

  ufs: any[];
  cidades: any[];

  isBuscandoCidade = false;
  isLoading = false;

  constructor(
    private modalInstance: BsModalRef,
    private fb: FormBuilder,
    private ufService: UfService,
    private cidadeService: CidadeService,
    private cepService: CepService,
    private modal: BsModalService
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.ufService.ufs().pipe(take(1)).subscribe((res: any[]) => this.ufs = res);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  initForm() {
    this.formEncontrarCep = this.fb.group({
      endereco: ['', Validators.required],
      bairro: [''],
      uf: ['', Validators.required],
      cidade: ['', Validators.required]
    });
  }

  onChangeUf() {
    this.formEncontrarCep.patchValue({ cidade: '' });

    if (this.uf) {
      this.refreshCidades(this.uf);
    }
  }

  refreshCidades(uf: string) {
    this.isBuscandoCidade = true;
    this.cidadeService
      .cidades(uf).pipe(
      take(1),
      finalize(() => this.isBuscandoCidade = false))
      .subscribe(res => this.cidades = res);
  }

  onClickConfirmar() {
    this.isLoading = true;

    this.cepService
      .getCepByEndereco(this.formEncontrarCep.getRawValue())
      .pipe(
        finalize(() => this.isLoading = false),
        take(1)
      )
      .subscribe(
        (res: any) => {
          if (res.enderecos.length === 1) {
            this.closeModal.next(res);
            this.modalInstance.hide();
            return;
          }
          this.handleMultiplosEnderecos(res.enderecos);
        },
        (error) => this.handleCepError(error)
      );

  }

  private handleMultiplosEnderecos(enderecos: EnderecoMultiplo) {
    const initialState = { listaEnderecos: enderecos, isBuscaPorEndereco: true };
    const modal = this.modal.show(ModalMultiplosEnderecosComponent, { ...appSettings.MODAL_PARAMS, initialState });

    const modalMultiplosEnderecos = <ModalMultiplosEnderecosComponent>modal.content;

    modalMultiplosEnderecos.closeModal
      .pipe(
        takeWhile(() => modalMultiplosEnderecos.alive)
      )
      .subscribe((res) => {
        this.closeModal.next({ enderecos: [res.enderecos] });
        setTimeout(() => {
          this.modalInstance.hide();
        });
      });
  }

  private handleCepError(error) {
    if (error.error) {
      this.cepInvalidoModal.showModal({
        titulo: 'Oops...',
        mensagem: 'Endereço não encontrado'
      });
    }
  }

  close() {
    this.modalInstance.hide();
  }

  get endereco() {
    return this.formEncontrarCep.get('endereco').value;
  }

  get bairro() {
    return this.formEncontrarCep.get('bairro').value;
  }

  get uf() {
    return this.formEncontrarCep.get('uf').value;
  }

  get cidade() {
    return this.formEncontrarCep.get('cidade').value;
  }
}
