import { Component, Input } from '@angular/core';
import { FichaListaFiltro } from '../model/ficha-lista-filtro';

@Component({
  selector: 'app-lista-ficha-contrato-cancelado',
  templateUrl: './lista-ficha-contrato-cancelado.component.html',
  styleUrls: ['./lista-ficha-contrato-cancelado.component.css']
})
export class ListaFichaContratoCanceladoComponent {
  @Input() propostas = [];
  @Input() filtro: FichaListaFiltro;
}
