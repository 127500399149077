import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-default',
  templateUrl: './loader-default.component.html',
  styleUrls: ['./loader-default.component.css'],
})
export class LoaderDefaultComponent {
  @Input() text = 'Carregando...';
  @Input() show = true;
  @Input() size = 'fa-2x';
}
