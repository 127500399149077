import { Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ButtonSelectorData } from '../button-selector/button-selector-data';
import { TipoSolicitanteEnum } from './tipo-solicitante.enum';

@Component({
  selector: 'app-quem-solicitou',
  templateUrl: './quem-solicitou.component.html',
  styleUrls: ['./quem-solicitou.component.css']
})
export class QuemSolicitouComponent implements OnInit {
  @Input() mostrarOutraPessoa: boolean = true;

  @Output() solicitanteChange = new BehaviorSubject<string>(undefined);
  solicitante: ButtonSelectorData = new ButtonSelectorData();

  solicitantes: ButtonSelectorData[];

  ngOnInit() {
    this.solicitantes = [
      new ButtonSelectorData(TipoSolicitanteEnum.VENDEDOR, 'VENDEDOR', 'vendedor', 1, 'De uma das lojas da minha rede'),
      new ButtonSelectorData(TipoSolicitanteEnum.CLIENTE, 'CLIENTE NO BALCÃO', 'cliente-balcao', 1, 'Atendimento realizado na própria franquia'),
    ];
    if (this.mostrarOutraPessoa) {
      this.solicitantes.push(new ButtonSelectorData(TipoSolicitanteEnum.OUTRA, 'OUTRA PESSOA', 'outra-pessoa', 1, 'Amigo, parente ou outro contato meu'));
    }
  }

  onSolicitanteChange() {
    this.solicitanteChange.next(this.solicitante.id);
  }
}
