import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.css']
})
export class SimuladorComponent implements OnInit {
  simulationForm: FormGroup;

  yearArray: number[] = [];
  private startYear: number = 1970;
  private currentYear: number;

  deadlineArray: number[] = [];
  private minDeadline: number = 6;
  private maxDeadline: number = 48;

  returnArray: string[] = [];
  private minReturn: number = 0;
  private maxReturn: number = 4;

  isSimulated: boolean = false;
  terms: string = '14';
  termValue: string = '1345.38';
  simulationDate: string = '05/10/2019';

  hasSlider: boolean = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.fillArrays();
    this.initForm();
  }

  private fillArrays(): void {
    this.fillYearArray();
    this.fillDeadlineArray();
    this.fillReturnArray();
  }

  private fillYearArray(): void {
    this.currentYear = new Date().getFullYear();

    for (let i = this.startYear; i <= this.currentYear; i++) {
      this.yearArray.push(i);
    }
  }

  private fillDeadlineArray(): void {
    for (let i = this.minDeadline; i <= this.maxDeadline; i++) {
      this.deadlineArray.push(i);
    }
  }

  private fillReturnArray(): void {
    for (let i = this.minReturn; i <= this.maxReturn; i++) {
      this.returnArray.push(`R${i}`);
    }
  }

  private initForm(): void {
    this.simulationForm = this.fb.group({
      product: ['', Validators.required],
      productYear: ['', Validators.required],
      deadline: ['', Validators.required],
      returnVal: ['2', Validators.required],
      firstParcelDeadline: ['', Validators.required],
      financedValue: ['', Validators.required]
    });
  }

  handleSliderChange($emission: number): void {
    this.returnVal.patchValue(`R${$emission}`);
  }

  onClickSimulate(): void {
    scroll({
      behavior: 'smooth',
      top: 0,
    });
    this.simulate();
    console.log(this.simulationForm.getRawValue());
  }

  private simulate(): void {
    this.isSimulated = true;
  }

  get financedValue(): AbstractControl {
    return this.simulationForm.get('financedValue');
  }

  get deadline(): AbstractControl {
    return this.simulationForm.get('deadline');
  }

  get firstParcelDeadline(): AbstractControl {
    return this.simulationForm.get('firstParcelDeadline');
  }

  get returnVal(): AbstractControl {
    return this.simulationForm.get('returnVal');
  }
}
