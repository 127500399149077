import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginStorage } from '../login/login.storage';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private loginStorage: LoginStorage) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.loginStorage.isContainsToken()) {
      if (route.url[0].path.includes('efetive-mais')) {
        this.loginStorage.registerRedirectRoute('efetive-mais');
      }
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
