import { Component, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OperacoesGeralService } from '../../../../services/geral/operacoes/operacoes.geral.service';
import { ButtonSelectorData } from '../button-selector/button-selector-data';
import { timeout, take } from 'rxjs/operators';
import { NewModalMessageComponent } from '../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TipoVeiculoHandler } from './tipo-veiculo.handler';

@Component({
  selector: 'app-tipo-veiculo',
  templateUrl: './tipo-veiculo.component.html',
  styleUrls: ['./tipo-veiculo.component.scss']
})
export class TipoVeiculoComponent implements OnChanges {

  @Input() dadosOperacao;
  @Output() tipoVeiculoChange = new BehaviorSubject<any>({});
  @ViewChild('modalMessage', { static: true }) modalMessage: NewModalMessageComponent;

  veiculo: ButtonSelectorData = new ButtonSelectorData();
  veiculos: ButtonSelectorData[];
  produtosPorUsuario: any;

  constructor(private operacoesGeralService: OperacoesGeralService) { }

  ngOnChanges() {
    this.initProdutosPorUsuario();
  }

  private initProdutosPorUsuario(): void {
    const { tipoProduto } = this.dadosOperacao;

    this.operacoesGeralService
      .produtosPorUsuario(tipoProduto).pipe(
        timeout(20000),
        take(1)
      ).subscribe(
        (res) => {
          this.veiculos = TipoVeiculoHandler.handle(res);

          this.validateVeiculosLength();
        },
        () => this.modalMessage.showModal({ mensagem: 'Erro obtendo os tipos de produto.' })
      );
  }

  validateVeiculosLength() {
    if (this.veiculos.length === 1) {
      const [veiculo] = this.veiculos;
      this.tipoVeiculoChange.next(veiculo.id);
      this.veiculo = veiculo;
    }
  }

  onTipoVeiculoChange() {
    this.tipoVeiculoChange.next(this.veiculo.id);
  }
}
