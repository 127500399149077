import { Component, DoCheck, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FichaService } from '../../ficha-veiculo.service';
import { setFichaContent } from '../helpers/salvarFicha';
import { FichaCadastro } from './../ficha-cadastro';
import { Data } from '../../../shared/partner/data';

@Component({
  selector: 'app-ficha-cadastro-detalhe-avalista',
  templateUrl: './ficha-cadastro-detalhe-avalista.component.html',
  styleUrls: ['./ficha-cadastro-detalhe-avalista.component.css'],
})
export class FichaCadastroDetalheAvalistaComponent
  implements DoCheck, OnDestroy {
  @Input() fichaCadastro: FichaCadastro;

  private nomeAvalistaOriginal: string;

  nomeAvalista: string;
  emailAvalista: string;
  tipoDocumentoAvalista = 0;
  numeroDocumentoAvalista: string;
  orgaoDocumentoAvalista: string;
  emissaoDocumentoAvalista: string; // Data de emissão
  foneCelularAvalista: string;
  estadoCivilAvalista = 0;
  nomeRealMaeAvalista = '';
  nomeMaeAvalista = '';
  nomePaiAvalista: string;
  nacionalidadeAvalista = 1;
  naturalUfAvalista = '';
  naturalCidadeAvalista = '';
  valorPatrimonioAvalista: number;
  ppeAvalista = false;
  nomeReferenciaAvalista: string;
  foneReferenciaAvalista: string;
  relacReferenciaAvalista = '';
  foneResidencialAvalista: string;
  foneComercialAvalista: string;

  isNomePaiAvalista: boolean;

  subsNomeMaeAvalista: Subscription;
  subsNacionalidadeAvalista: Subscription;
  subsNaturalCidadeAvalista: Subscription;
  subsProfissaoAvalista: Subscription;

  listaNomeMaeAvalista = [];
  listaNacionalidadeAvalista = [];
  listaNaturalCidadeAvalista = [];

  isLoadingNomeMaeAvalista = false;
  isLoadingNacionalidadeAvalista = false;
  isLoadingNaturalCidadeAvalista = false;

  isSelectedTipoDocumentoAvalista = false;
  isSelectedEstadoCivilAvalista = false;
  isSelectedNomeMaeAvalista = false;
  isSelectedBrasilAvalista = true;
  isSelectedNaturalUfAvalista = false;
  isSelectedNaturalCidadeAvalista = false;
  isSelectedProfissaoAvalista = false;
  isSelectedRelacReferenciaAvalista = false;

  minDataEmissao: string;
  maxDataEmissao: string;

  hasData = false;

  isSelectedNaturalCidadeAvalistaAfterBlur = true;

  isNomePaiAvalistaTexto = false;

  dadoProfissional;

  constructor(private fichaService: FichaService) {}

  ngDoCheck() {
    if (!this.hasData && this.fichaCadastro.proposta['avalistas']) {
      this.nomeAvalista = this.nomeAvalistaOriginal;
      this.isNomePaiAvalista = true;
      this.loadNacionalidadeAvalista();
      this.hasData = true;
    }
  }

  ngOnDestroy() {
    if (this.subsNacionalidadeAvalista) {
      this.subsNacionalidadeAvalista.unsubscribe();
    }
    if (this.subsNaturalCidadeAvalista) {
      this.subsNaturalCidadeAvalista.unsubscribe();
    }
    if (this.subsProfissaoAvalista) {
      this.subsProfissaoAvalista.unsubscribe();
    }
  }

  loadNacionalidadeAvalista() {
    this.listaNacionalidadeAvalista = [];
    this.isLoadingNacionalidadeAvalista = true;
    this.subsNacionalidadeAvalista = this.fichaService
      .getAllNacionalidade()
      .subscribe(
        res => {
          this.listaNacionalidadeAvalista = res.nacionalidades;
          this.isLoadingNacionalidadeAvalista = false;
          if (this.fichaCadastro._avalista.naturalDeUf) {
            this.loadNaturalCidadeAvalista();
          }
        },
        err => {
          this.isLoadingNacionalidadeAvalista = false;
        },
      );
  }

  loadNaturalCidadeAvalista() {
    this.listaNaturalCidadeAvalista = [];
    this.isSelectedNaturalCidadeAvalista = false;
    this.isLoadingNaturalCidadeAvalista = true;
    this.subsNaturalCidadeAvalista = this.fichaService
      .getCidadePorUf(this.fichaCadastro._avalista.naturalDeUf)
      .subscribe(
        res => {
          this.listaNaturalCidadeAvalista = res.cidades;
          this.isLoadingNaturalCidadeAvalista = false;
        },
        err => {
          this.isLoadingNaturalCidadeAvalista = false;
        },
      );
  }

  onClickNomePaiAvalista() {
    this.isNomePaiAvalista = !this.isNomePaiAvalista;
    if (this.isNomePaiAvalista) {
      this.fichaCadastro._avalista.nomePai = 'NAO DECLARADO';
    } else {
      this.fichaCadastro._avalista.nomePai = '';
    }
  }

  onChangeTipoDocumentoAvalista(event) {
    this.isSelectedTipoDocumentoAvalista = event.target.selectedIndex !== 0;
  }

  onChangeEstadoCivilAvalista(event) {
    this.isSelectedEstadoCivilAvalista = event.target.selectedIndex !== 0;
  }
  politicamenteExposto(data) {
    this.fichaCadastro._avalista.clientePpe = data;
  }

  onChangeNomeMaeAvalista(event) {
    this.nomeRealMaeAvalista = event.target.value;
    this.nomeMaeAvalista = this.listaNomeMaeAvalista[event.target.selectedIndex - 1].nomeMae;
    this.isSelectedNomeMaeAvalista = event.target.selectedIndex !== 0;
  }

  onChangeNacionalidadeAvalista(event) {
    this.isSelectedBrasilAvalista = event.target.value === '1';
    this.naturalUfAvalista = '';
    this.naturalCidadeAvalista = '';
    this.isSelectedNaturalUfAvalista = false;
    this.isSelectedNaturalCidadeAvalista = false;
  }

  onChangeNaturalUfAvalista(event) {
    this.naturalUfAvalista = event.target.value;
    this.isSelectedNaturalUfAvalista = true;
    this.naturalCidadeAvalista = '';
    this.isSelectedNaturalCidadeAvalista = false;
    this.loadNaturalCidadeAvalista();
  }

  onChangeNaturalCidadeAvalista(event) {
    this.naturalCidadeAvalista = event.target.value;
    this.isSelectedNaturalCidadeAvalista = event.target.selectedIndex !== 0;
    this.onBlurNaturalCidadeAvalista(event);
  }

  onChangeRelacReferenciaAvalista(event) {
    this.isSelectedRelacReferenciaAvalista = event.target.selectedIndex !== 0;
  }

  onBlurNaturalCidadeAvalista(event) {
    this.isSelectedNaturalCidadeAvalistaAfterBlur =
      event.target.selectedIndex !== 0;
  }

  isNextAvailable(form) {
    return form.valid;
  }

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
  }

  formatDateMiliseconds(data) {
    return Data.toMilissegundos(data);
  }

  onClickNext() {
    setFichaContent(this.fichaCadastro);
    this.fichaCadastro.onClickNext();
  }
}
