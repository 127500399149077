import { Directive, ElementRef, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector:
    // tslint:disable-next-line:max-line-length
    '[appCpfCnpjValidator][formControlName],[appCpfCnpjValidator][formControl],[appCpfCnpjValidator][ngModel],',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CpfCnpjValidatorDirective),
    },
  ],
})
export class CpfCnpjValidatorDirective implements Validator {
  constructor(private element: ElementRef) {}

  validate(): { [key: string]: any } {
    const cpfCheck = this.element.nativeElement.value;
    return this.validar(cpfCheck);
  }

  calcDigitosPosicoes(digitos, posicoes = 10, somaDigitos = 0) {
    const auxDig = digitos.toString();
    let auxPos = posicoes;
    let auxSum = somaDigitos;

    for (const value of auxDig) {
      auxSum = auxSum + value * auxPos;
      auxPos = auxPos - 1;
      if (auxPos < 2) {
        auxPos = 9;
      }
    }

    auxSum = auxSum % 11;

    auxSum = auxSum < 2 ? 0 : 11 - auxSum;

    return auxDig + auxSum;
  }

  validarCpf(valor) {
    const vl = valor.replace(/[^0-9]/g, '');
    const digitos = vl.substr(0, 9);
    let novoCpf = this.calcDigitosPosicoes(digitos);
    novoCpf = this.calcDigitosPosicoes(novoCpf, 11);
    return novoCpf === vl;
  }

  validarCnpj(valor) {
    const vl = valor.replace(/[^0-9]/g, '');
    const cnpjOriginal = vl;
    const primeirosNumerosCnpj = vl.substr(0, 12);
    const primeiroCalculo = this.calcDigitosPosicoes(primeirosNumerosCnpj, 5);
    const cnpj = this.calcDigitosPosicoes(primeiroCalculo, 6);
    return cnpj === cnpjOriginal;
  }

  validar(valor) {
    if (valor.length <= 14) {
      if (!this.validarCpf(valor)) {
        return { cpfCheck: true, message: 'CPF inválido' };
      }
    } else {
      if (!this.validarCnpj(valor)) {
        return { cnpjCheck: true, message: 'CNPJ inválido' };
      }
    }
    return null;
  }
}
