import { TiposCombustiveis } from './tipos-combustiveis';
import { Injectable } from '@angular/core';
import { OmniMaisV2RestService } from './../../omni-rest/omni-mais/omni-mais-v2-rest.service';
import { Observable } from 'rxjs';

@Injectable()
export class TiposCombustiveisService {
  constructor(private _restService: OmniMaisV2RestService) {}

  getTipoCombustiveis(): Observable<TiposCombustiveis[]> {
    return this._restService.get('veiculo/tiposCombustivel');
  }
}
