import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import * as toastr from 'toastr';

import { ContextSharedService } from '../../../context-shared/context-shared.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { NewModalMessageComponent } from '../../../shared/modais/new-modal-message/new-modal-message.component';
import { FichaEstapasCadastro } from '../../shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';
import { NovaProposta } from '../../shared/models/nova-proposta';
import { PropostaCadastro } from '../../shared/models/proposta-cadastro';
import { PropostaSimulacao } from '../../shared/models/proposta-simulacao';
import { PropostaStateService } from '../../shared/states/proposta-state.service';
import { CdcLojaService } from '../services/cdc-loja.service';
import { AnaliseAutomaticaStatus, DisparoAnaliseAutomatica } from '../models/analise-automatica-status';
import { CdcLojaEtapas } from '../models/cadastro-etapas';

@Component({
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CdcLojaNovaFichaComponent implements OnInit, OnDestroy {
  @ViewChild('dialog', { static: true })

  modalMessage: NewModalMessageComponent;
  formUpdateEmitter: EventEmitter<FichaEstapasCadastro> = new EventEmitter();
  propostaCadastro = new PropostaCadastro(new NovaProposta());
  propostaSimulacao = new PropostaSimulacao();
  etapaAtual = CdcLojaEtapas.INICIAL;
  etapas: FichaEstapasCadastro[];
  formValid: false;
  etapasCriadas: number[] = [];
  showLoader = false;
  alive = true;

  showTryAgain = false;
  showLoaderPhases = false;
  disparoAnaliseAutomatica = DisparoAnaliseAutomatica;
  timeout: any;
  reenviarParaAnalise = false;
  biometriaAtiva = false;

  constructor(
    private context: ContextSharedService,
    private router: Router,
    private cdcLojaService: CdcLojaService,
    private state: PropostaStateService,
    private activeRoute: ActivatedRoute,
    protected dialogService: DialogService,
  ) { }

  private async initParams() {
    const params = (<any>this.activeRoute.paramMap).source._value;

    if (params.id) {
      const novaProposta = new NovaProposta();
      novaProposta.tipoProduto = `${params.produto}`.replace(' ', '_');
      novaProposta.produto = novaProposta.tipoProduto;
      this.propostaCadastro = new PropostaCadastro(novaProposta);

      const proposta = await this.cdcLojaService.findById(params.id).toPromise();
      if (!proposta.valorLiquido) {
        return;
      }
      this.state.setState(proposta);

      if (proposta.transients) {
        proposta.transients.isConfirmadoTelefone = proposta.transients.celular_confirmado;
      }

      this.propostaCadastro.lojaVendedor.lojaId = proposta.lojista;
      this.propostaCadastro.operacao = proposta.operacao.id;
      if (proposta.favorecido) {
        this.propostaCadastro.banco = proposta.favorecido.banco;
      }

      const etapa = proposta.rastreioStatus.descricao;
      if (etapa.includes('EM PREENCHIMENTO')) {
        this.etapaAtual = CdcLojaEtapas.DETALHES_CLIENTE;
      } else if (etapa.includes('AGUARDANDO BIOMETRIA')) {
        this.etapaAtual = CdcLojaEtapas.DEMAIS_DADOS;
      } else {
        this.etapaAtual = CdcLojaEtapas.RESULTADO_PARCIAL;
      }
      this.etapasCriadas.push(this.etapaAtual.numero);
    }
  }

  async ngOnInit() {
    this.etapas = CdcLojaEtapas.etapas();

    this.showLoader = true;
    await this.initParams();
    this.showLoader = false;

    if (this.etapaAtual === CdcLojaEtapas.INICIAL) {
      this.context
        .get()
        .ifPresentOrElse(
          value => this.initEtapas(value),
          () => this.router.navigateByUrl('nova')
        );
    }

    this.state
      .getState()
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    if (this.state.getLast().lojista) {
      this.biometriaAtiva = this.state.getLast().lojista.liberadoBiometria;
    }
  }

  ngOnDestroy() {
    this.state.clear();
    this.alive = false;
    clearTimeout(this.timeout);
  }

  private initEtapas(value: NovaProposta) {
    this.propostaCadastro = new PropostaCadastro(value);
    this.etapaAtual = CdcLojaEtapas.VENDEDOR;
    this.etapasCriadas.push(this.etapaAtual.numero);
  }

  onClickPrevious() {
    this.isEtapaVendedor
      ? this.router.navigateByUrl('nova')
      : this.voltarParaEtapaAnterior();

    this.formUpdateEmitter.emit(this.etapaAtual);
    this.scrollTop();
  }

  async onClickNext() {
    switch (this.etapaAtual.numero) {
      case CdcLojaEtapas.VENDEDOR.numero: // 1
        const proposta = this.state.getLast();
        proposta.lojista = { id: Number(this.propostaCadastro.lojaVendedor.lojaId) };
        proposta.vendedor = { id: Number(this.propostaCadastro.lojaVendedor.vendedorId) };
        proposta.operacao = { id: Number(this.propostaCadastro.operacao), tipoProduto: this.propostaCadastro.tipoProduto, produto: this.propostaCadastro.produto };
        this.state.setState(proposta);
        return this.nextStep();

      case CdcLojaEtapas.DADOS_ANALISE.numero: // 2
        return this.criarFicha();

      case CdcLojaEtapas.RESULTADO_PARCIAL.numero: // 3
        return this.executarCrivo();

      case CdcLojaEtapas.DETALHES_CLIENTE.numero: // 4
        return this.nextStep();

      case CdcLojaEtapas.DEMAIS_DADOS.numero: // 6 now 5
        return this.finalizarProposta();

      case CdcLojaEtapas.ANALISE.numero: // 7 now 6
        return this.irParaTimeline();

      default:
        return this.nextStep();
    }
  }

  private executarCrivo() {
    this.showLoaderPhases = true;
    if (this.reenviarParaAnalise) {
      this.enviarPropostaParaAnalise();
      return;
    }

    this.cdcLojaService.salvarProposta(this.state.getLast()).subscribe(
      () => {
        this.enviarPropostaParaAnalise();
      },
      (e) => {
        if (e.error && e.error.messages[0] && e.error.messages[0].includes('could not execute statement')) {
          toastr.error('Valores e produtos selecionados não permitido para prosseguimento!', 'Falha ao salvar');
          throw e;
        }
        console.log(e);
        toastr.error('Não foi possível conectar com o servidor.', 'Falha ao simular a ficha');
        this.showLoaderPhases = false;
      });
  }

  private enviarPropostaParaAnalise() {
    this.cdcLojaService.enviarPropostaParaAnalise(this.state.getLast().id).subscribe(
      () => {
        this.scrollTop();
        this.analisarPropostaAssincrona(1, DisparoAnaliseAutomatica.RESULTADO_PARCIAL);
        this.reenviarParaAnalise = false;
      },
      () => {
        this.showLoaderPhases = false;
        this.reenviarParaAnalise = true;
      });
  }

  private erroPoliticaInterna() {
    this.modalMessage.showModal({ mensagem: 'Fora da política interna', titulo: 'Atenção' });
    this.irParaTimeline();
  }

  private async nextStep() {
    await this.save();
    this.etapaAtual = CdcLojaEtapas.next(this.etapaAtual);
    this.etapasCriadas.push(this.etapaAtual.numero);
    this.formUpdateEmitter.emit(this.etapaAtual);
    this.scrollTop();
  }

  private async save() {
    if (this.etapaAtual.numero === CdcLojaEtapas.DETALHES_CLIENTE.numero) {
      try {
        this.showLoader = true;
        await this.cdcLojaService.salvarProposta(this.state.getLast()).toPromise();
      } catch (e) {
        console.error('Erro: Tentativa de salvar ficha falhou', e);
        if (e.error && e.error.messages && e.error.messages[0] && e.error.messages[0].includes('could not execute statement')) {
          toastr.error('Valores e produtos selecionados não permito para prosseguimento!', 'Falha ao salvar');
        } else {
          toastr.error('Não foi possivel prosseguir com a proposta', 'Falha ao acessar o servidor');
        }
        throw e;
      } finally {
        this.showLoader = false;
      }
    }
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  private async criarFicha() {
    try {
      this.showLoader = true;
      const proposta = this.state.getLast();
      const propostaCriada = await this.cdcLojaService.criarProposta(proposta).toPromise();
      proposta.id = propostaCriada.id;
      proposta.simulacao = propostaCriada.simulacao;
      proposta.cessao = propostaCriada.operacao.cessao;
      proposta.lojista.liberadoBiometria = propostaCriada.lojista.liberadoBiometria;
      this.state.setState(proposta);
      this.nextStep();
    } catch (error) {
      if (error.status === 422 && error.error.messages.length > 0) {
        toastr.error(error.error.messages[0], 'Atenção');
        return;
      }
      toastr.error('Não foi possível conectar com o servidor.', 'Falha ao criar ficha');
      throw error;
    } finally {
      this.showLoader = false;
    }
  }

  private async finalizarProposta() {
    try {
      this.showLoaderPhases = true;
      await this.cdcLojaService.finalizaSimulacao(this.state.getLast()).toPromise();
      this.scrollTop();
      this.analisarPropostaAssincrona(1, DisparoAnaliseAutomatica.FINALIZAR_PROPOSTA);
    } catch (error) {
      this.showLoaderPhases = false;
      toastr.error('Não foi possível conectar com o servidor.', 'Falha ao enviar proposta a mesa');
    }
  }

  private irParaTimeline() {
    this.etapaAtual = { numero: 0, descricao: 'finalizada' };
    this.router.navigate(['/produtos/cdcloja/timelines'], { queryParams: { id: this.state.getLast().id } });
  }

  isShowEtapa(numero) {
    return this.etapasCriadas.find(num => num === numero);
  }

  get solicitante() {
    return this.propostaCadastro.solicitante;
  }

  get currentPageBreadcrumb() {
    return 'CDC Loja';
  }

  get numeroEtapaAtual() {
    return this.etapaAtual.numero;
  }

  get isEtapaVendedor() {
    return this.etapaAtual.numero === CdcLojaEtapas.VENDEDOR.numero;
  }

  get isShowButtonPrevious() {
    return ![
      CdcLojaEtapas.RESULTADO_PARCIAL.numero,
      CdcLojaEtapas.ANALISE.numero
    ].includes(this.numeroEtapaAtual);
  }

  etapaNaoPossuiControleIndependente() {
    const etapasIndependente = [2, 3, 4, 5];
    return etapasIndependente.indexOf(this.etapaAtual.numero) < 0;
  }

  private voltarParaEtapaAnterior() {
    this.etapaAtual = CdcLojaEtapas.previous(this.etapaAtual);
  }
  private analisarPropostaAssincrona(tentativa: number, disparoAnalise: DisparoAnaliseAutomatica) {
    const maximoTentativas = 18;

    this.cdcLojaService.analisarProposta(this.state.getLast(), disparoAnalise).pipe(takeWhile(() => this.alive))
      .subscribe(
        ({ status }) => {
          if (tentativa >= maximoTentativas) {
            this.reiniciarTentativas();
          } else if (status === AnaliseAutomaticaStatus.AGUARDANDO_ANALISE_ASSINCRONA && tentativa < maximoTentativas) {
            this.timeout = setTimeout(() => this.analisarPropostaAssincrona(tentativa + 1, disparoAnalise), 5000);
          } else if (status === AnaliseAutomaticaStatus.RECUSADO) {
            this.showLoaderPhases = false;
            this.erroPoliticaInterna();
          } else if (status === AnaliseAutomaticaStatus.APROVADO || status === AnaliseAutomaticaStatus.PENDENTE) {
            this.showLoaderPhases = false;
            this.nextStep();
          }
        },
        () => {
          if (tentativa < maximoTentativas) {
            this.timeout = setTimeout(() => this.analisarPropostaAssincrona(tentativa + 1, disparoAnalise), 5000);
            return;
          }
          this.showLoaderPhases = false;
        });
  }

  private reiniciarTentativas() {
    this.showLoaderPhases = false;
    this.showTryAgain = true;
    toastr.warning('Por favor, clique em "analisar novamente" para recomeçar.', 'Ops, essa operação está demorando mais que o esperado!');
  }

  analisarPropostaAssincronaNovamente(disparo: DisparoAnaliseAutomatica) {
    this.showTryAgain = false;
    this.showLoaderPhases = true;
    this.scrollTop();
    this.analisarPropostaAssincrona(1, disparo);
  }

  private checkBiometriaAtiva() {
    this.cdcLojaService.checkParametro('BIOMETRIA_UNICO_ATIVO').subscribe((response) => {
      if (response.key === 'BIOMETRIA_UNICO_ATIVO') {
        this.biometriaAtiva = response.value === 'S';
      }
    });
  }
}
