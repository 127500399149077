import { GrupoSeguroSelecionado } from './proposta/grupo-seguro-selecionado';
import { GrupoAssistenciaSelecionado } from './proposta/grupo-assistencia-selecionado';

export class DadoSimulacaoSeguro {

  retorno = 0;
  gruposSeguroIds: GrupoSeguroSelecionado[] = [];
  gruposAssistenciaIds: GrupoAssistenciaSelecionado[] = [];

  setRetorno(retorno: number) {
    this.retorno = retorno;
  }

  getRetorno(): number {
    return this.retorno;
  }

  getPercRetorno(): number {
    return (this.retorno || 0) / 10;
  }

  setGrupoSeguroIds(gruposSeguroIds: GrupoSeguroSelecionado[]) {
    this.gruposSeguroIds = gruposSeguroIds;
  }

  getGrupoSeguroIds(): GrupoSeguroSelecionado[] {
    return this.gruposSeguroIds;
  }

  setGrupoAssistenciaIds(gruposAssistenciaIds: GrupoAssistenciaSelecionado[]) {
    this.gruposAssistenciaIds = gruposAssistenciaIds;
  }

  getGrupoAssistenciaIds(): GrupoAssistenciaSelecionado[] {
    return this.gruposAssistenciaIds;
  }
}
