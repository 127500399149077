import { DocumentoVO } from '../documentoVO';
import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-documentation-item-multiavalistas',
  templateUrl: './upload-documentation-item-multiavalistas.component.html'
})
export class UploadDocumentationItemMultiavalistasComponent {
  @Output() fileChange = new EventEmitter;
  @Input() files: any[];
  @Input() filesDocuments: any;
  @Input() document: DocumentoVO;
  @Input() documentPersonIndex: number;
  @Input() documentIndex: number;
  @Input() removeFileCallback: () => void;
  @Input() removeAllFilesCallback: () => void;
  @Input() acceptFiles;

  get hasFile() {
    return this.files && this.files.length;
  }

  get hasUploadDefined() {
    return this.document.url !== undefined;
  }

}
