import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OmniRestModule } from '../../omni-rest/omni-rest.module';
import { OperacoesGeralService } from './operacoes/operacoes.geral.service';

@NgModule({
  imports: [
    CommonModule,
    OmniRestModule,
  ],
  providers: [
    OperacoesGeralService,
  ]
})
export class GeralServiceModule { }
