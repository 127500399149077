export class FichaTimeline {

  private _fichaTimeline: any;

  constructor(obj?: any) {
    this._fichaTimeline = obj || {};
  }

  get isFicha(): boolean {
    return this._fichaTimeline.timelineProposta;
  }

  get timelineProposta(): any {
    return this._fichaTimeline.timelineProposta || {};
  }

  get proposta(): any {
    return this.timelineProposta.proposta || {};
  }

  get cliente(): any {
    return this.proposta.cliente || {};
  }

  get vendedor(): any {
    return this.proposta.vendedor || {};
  }

  get promotor(): any {
    return this.proposta.promotor;
  }

  get timeline(): any[] {
    return this.timelineProposta.timeline || [];
  }

  get status() {
    let status = 0;
    this.timeline.forEach(obj => {
      if (obj.seqTimeline) {
        status = obj.idStatus;
      }
    });
    return status;
  }

}
