import {
  Component, EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { take } from 'rxjs/operators';

import { CdcLojaRestService } from '../../../../../../omni-rest/cdc/cdc-loja-rest.service';
import { ModalMessageComponent } from '../../../../../../shared/modais/modal-message/modal-message.component';

@Component({
  selector: 'app-mercadoria',
  templateUrl: './mercadoria.component.html',
  styleUrls: ['./mercadoria.component.scss']
})
export class MercadoriaComponent implements OnInit, OnDestroy {
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;
  @Input() indentificacaoForm: FormGroup;
  @Input() index: number;
  @Output() selfDestructEmitter: EventEmitter<number> = new EventEmitter();

  mercadoriaForm: FormGroup;
  mercadorias: any[];
  tiposMercadoria: any[];

  isAlive: boolean = true;

  constructor(
    private cdcLojaRestService: CdcLojaRestService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForms();
    this.initObservers();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  private initForms(): void {
    this.mercadoriaForm = this.fb.group(
      {
        tiposMercadoria: ['', Validators.required],
        valorProduto: ['', Validators.required]
      }
    );

    this.indentificacaoForm.addControl(
      `mercadoriaForm_${this.index}`,
      this.mercadoriaForm
    );
  }

  private initObservers(): void {
    this.cdcLojaRestService
      .get('mercadorias/tipos').pipe(
        take(1))
      .subscribe((res) => (this.tiposMercadoria = res));
  }

  selfDestruct(): void {
    this.indentificacaoForm.removeControl(`mercadoriaForm_${this.index}`);
    this.selfDestructEmitter.emit(this.index);
  }

  get tipoMercadoria(): AbstractControl {
    return this.mercadoriaForm.get('tiposMercadoria');
  }

  get valorProduto(): AbstractControl {
    return this.mercadoriaForm.get('valorProduto');
  }
}
