import { ModalComponent } from './../../../../shared/modais/modal/modal.component';
import { IdDescricao } from './../../../shared/models/id-descricao';
import { Proposta } from './../../../shared/models/proposta';

import { take, finalize } from 'rxjs/operators';
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SosInverso } from './sos-inverso';

import * as toastr from 'toastr';
import { CreditoPessoalService } from '../../services/cp.service';

@Component({
  selector: 'app-sos-inverso',
  templateUrl: './sos-inverso.component.html',
  styleUrls: ['./sos-inverso.component.css']
})
export class SosInversoComponent {

  @ViewChild('modalSosInverso', { static: true }) modalSosInverso: ModalComponent;

  @Input() proposta: Proposta;
  @Output() sosEnviado: EventEmitter<boolean> = new EventEmitter();

  motivos: IdDescricao[];
  sosInverso: SosInverso = new SosInverso();
  isLoading = false;

  constructor(private service: CreditoPessoalService) {}

  abrirModalSOS() {
    if (this.proposta.possuiSosInversoEmAberto) {
      toastr.warning('Já possui um SOS inverso aberto para a proposta!');
    } else {
      this.sosInverso = new SosInverso();
      this.modalSosInverso.show(true);
      this.modalSosInverso.ok.pipe(take(1)).subscribe(() => this.enviarSOS());
      this.buscarMotivos();
    }
  }

  isCamposPreenchidos(): boolean {
    return this.sosInverso.idMotivo != null && this.sosInverso.observacao && this.sosInverso.observacao.length >= 3;
  }

  private buscarMotivos() {
    this.isLoading = true;
    this.service
      .getMotivosSosInverso(this.proposta.id)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        result => this.motivos = result,
        error => {
          toastr.error('Erro ao carregar os motivos do SOS.');
          this.modalSosInverso.hide();
        }
      );
  }

  private enviarSOS() {
    this.isLoading = true;
    this.service
      .sendMotivoSosInverso(this.proposta.id, this.sosInverso)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        result => {
          toastr.success('SOS enviado com sucesso!');
          this.sosEnviado.emit(true);
        },
        error => this.handleEnviarSosHandle(error)
      );
  }

  private handleEnviarSosHandle(error) {
    const excecao = error.error.message;
    const msg = excecao.substring(excecao.indexOf(':') + 1).trim() || excecao;

    toastr.error(msg);
  }
}
