import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadersModule } from '../loaders/loaders.module';
import { SharedModule } from '../shared.module';
import { UfCidadeSelectModule } from '../uf-cidade-select/uf-cidade-select.module';
import { DirectivesModule } from './../../directives/directives.module';
import { NacionalidadeComponent } from './nacionalidade.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DirectivesModule,
    FormsModule,
    LoadersModule,
    UfCidadeSelectModule
  ],
  declarations: [
    NacionalidadeComponent
  ],
  exports: [
    NacionalidadeComponent
  ]
})
export class NacionalidadeModule { }
