import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ClienteEndereco } from '../../../shared/models/cliente';
import { Proposta } from '../../../shared/models/proposta';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { EcommerceEtapas } from '../ecommerce-etapas';

@Component({
  selector: 'app-enderecos-cliente',
  templateUrl: './enderecos-cliente.component.html',
  styleUrls: ['./enderecos-cliente.component.css']
})
export class EnderecosClienteComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  proposta: Proposta;
  correspondenciaForm: FormGroup;
  enderecosForm: FormGroup;

  showLoader = false;

  hasEnderecoComercial: boolean;

  constructor(
    private fb: FormBuilder,
    private cdcLojaService: CdcLojaService
  ) {}

  ngOnInit() {
    this.initForms();

    this.proposta = EcommerceEtapas.proposta;
    this.hasEnderecoComercial = !this.isAposentadoOrAutonomo() && !this.isConjugeAposentado();

    setTimeout(() => this.populateForms(), 100);
  }

  private initForms(): void {
    this.correspondenciaForm = this.fb.group({
      enderecoCorrespondencia: ['', Validators.required]
    });

    this.enderecosForm = this.fb.group({});
    this.enderecosForm.addControl('enderecoCorrespondencia', this.correspondenciaForm);
  }

  private populateForms() {
    if (this.proposta.cliente.enderecos) {
      const enderecos = this.proposta.cliente.enderecos;
      const residencialForm = enderecos.filter(e => e.tipoEndereco === 'RESIDENCIAL')[0] || {};
      const comercialForm = enderecos.filter(e => e.tipoEndereco === 'COMERCIAL')[0] || {};
      const enderecoCorrespondencia = enderecos.filter(e => e.correspondencia).map(e => e.tipoEndereco)[0];

      this.enderecosForm.patchValue(
        {
          residencialForm,
          comercialForm,
        },
        { emitEvent: false }
      );

      if (enderecoCorrespondencia) {
        this.correspondenciaForm.patchValue({ enderecoCorrespondencia });
      }
    }
  }

  async onClickNext(): Promise<void> {
    this.showLoader = true;
    this.setEnderecos();
    EcommerceEtapas.proposta = this.proposta;
    await this.cdcLojaService.salvarProposta(this.proposta).toPromise();
    this.showLoader = false;
    this.next.emit();
  }

  async onClickPrevious() {
    this.showLoader = true;
    this.setEnderecos();
    EcommerceEtapas.proposta = this.proposta;
    this.showLoader = false;
    this.previous.emit();
  }

  private setEnderecos(): void {
    const enderecoResidencial: ClienteEndereco = (this.enderecoResidencialForm as FormGroup).getRawValue();
    const enderecoComercial: ClienteEndereco = this.hasEnderecoComercial
      ? (this.enderecoComercialForm as FormGroup).getRawValue()
      : this.copyEnderecoResidencialToComercial(enderecoResidencial);

    this.proposta.cliente.enderecos = [];

    enderecoComercial.cep = (enderecoComercial.cep || '').replace('-', '');
    enderecoComercial.correspondencia = this.isEnderecoComercialCorrespondencia();
    this.proposta.cliente.enderecos.push(enderecoComercial);

    enderecoResidencial.cep = (enderecoResidencial.cep || '').replace('-', '');
    enderecoResidencial.correspondencia = this.isEnderecoResidencialCorrespondencia();
    this.proposta.cliente.enderecos.push(enderecoResidencial);
  }

  private copyEnderecoResidencialToComercial(enderecoResidencial: any): any {
    const enderecoComercial = Object.assign({}, enderecoResidencial);
    enderecoComercial.tipoEndereco = 'COMERCIAL';

    return enderecoComercial;
  }

  private isEnderecoResidencialCorrespondencia(): boolean {
    return this.enderecoCorrespondencia.value === 'RESIDENCIAL';
  }

  private isEnderecoComercialCorrespondencia(): boolean {
    return this.enderecoCorrespondencia.value === 'COMERCIAL';
  }

  private isAposentadoOrAutonomo(): boolean {
    return [5, 7].includes(this.classeProfissional);
  }

  private isConjugeAposentado() {
    return this.proposta.conjuge && this.proposta.conjuge.dadosProfissionais &&
      [5, 7].includes(this.proposta.conjuge.dadosProfissionais.classeProfissional.id);
  }

  get enderecoCorrespondencia(): AbstractControl {
    return this.correspondenciaForm.get('enderecoCorrespondencia');
  }

  get enderecoResidencialForm(): AbstractControl {
    return this.enderecosForm.get('residencialForm');
  }

  get enderecoComercialForm(): AbstractControl {
    return this.enderecosForm.get('comercialForm');
  }

  get classeProfissional(): number {
    return this.proposta.cliente.dadosProfissionais.classeProfissional.id;
  }
}
