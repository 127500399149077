import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as toastr from 'toastr';

import { DocumentoPendenteVO, TimelineStatusVO } from '../../../shared/models/timelineVO';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { appSettings } from './../../../../../environments/app.setings';
import { DialogService } from './../../../../services/dialog/dialog.service';
import { DocumentoVO } from './../../../../shared/upload-arquivos/documentoVO';
import { UploadArquivosComponent } from './../../../../shared/upload-arquivos/upload-arquivos.component';
import { Proposta } from './../../../shared/models/proposta';
import { ModalAssinaturaComponent } from './../modal-assinatura/modal-assinatura.component';

@Component({
  selector: 'app-card-assinatura',
  templateUrl: './card-assinatura.component.html',
  styleUrls: ['./card-assinatura.component.scss']
})
export class CardAssinaturaComponent implements OnInit {

  @Input() proposta: Proposta;
  @Input() timeline: TimelineStatusVO;
  @Input() nextCard: TimelineStatusVO;
  @Output() executar: EventEmitter<any> = new EventEmitter();

  @ViewChild(UploadArquivosComponent, { static: false }) uploadComponent;

  documentosPendentes: DocumentoVO[];
  alfabetizado = true;
  assinaturaDigital = true;
  bloqueiaAssinaturaDigital = false;
  toggleAssinatura = false;
  acceptFiles = appSettings.ACCEPT_IMAGES_AND_PDF;
  propostaId: number;

  constructor(
    private modal: BsModalService,
    private dialogService: DialogService,
    private service: CdcLojaService
  ) { }

  ngOnInit() {
    this.assinaturaManual = this.bloqueiaAssinaturaDigital;
  }

  pushDocumentacao() {
    this.executarAcao('push_assinatura');
  }

  executarAcao(acao: string) {
    this.executar.emit({ action: acao.toLowerCase() });
  }

  get analfabeto() {
    return !this.alfabetizado;
  }

  set analfabeto(value: boolean) {
    this.alfabetizado = !value;
    if (this.analfabeto) {
      this.assinaturaManual = true;
    } else {
      this.popularDocumentosPendentes();
    }

    this.changeFlag(value);
  }

  changeFlag(value: boolean) {
    const analfabeto = value;
    this.propostaId = this.proposta.id;
    this.service
      .changeFlagAnalfabeto({ analfabeto, propostaId: this.propostaId })
      .catch(error => {
        toastr.error(error.message);
      });
  }

  get assinaturaManual() {
    return !this.assinaturaDigital;
  }

  set assinaturaManual(value: boolean) {
    this.assinaturaDigital = !value;
    this.popularDocumentosPendentes();
  }

  verificarMudancaTipo(event: Event) {
    const component = <HTMLInputElement>event.target;
    if (this.uploadComponent.fileList.length > 0) {
      const message = 'Alterar a Alfabetização ou a Opção de Assinatura irá fazer com que os dados preenchidos, até então, sejam perdidos. Deseja continuar?';
      this.dialogService.confirm({
        body: message,
        title: 'Atenção',
        classList: ['modal-md'],
        textClass: 'text-warning',
        iconClass: 'text-warning fa fa-exclamation-triangle',
        callbackSuccess: () => this.uploadComponent.limpaListFile(),
        callbackError: () => this[component.id] = !component.checked
      });
    }
  }

  popularDocumentosPendentes() {
    if (!this.timeline.informacaoComplementar || !this.timeline.informacaoComplementar.documentosPendentes) {
      return;
    }

    this.documentosPendentes = this.timeline
      .informacaoComplementar
      .documentosPendentes
      .map(item => (
        {
          ...item,
          assinado: this.timeline.informacaoComplementar.assinadoDigitalmente,
          somenteDownload: this.assinaturaDigital ? true : item.somenteDownload,
          url: this.filterUrl(item)
        }
      ));
  }

  private filterUrl(item: DocumentoPendenteVO) {
    if (!item.urls) {
      return '';
    }
    const filteredUrl = item
      .urls
      .filter(url => url.alfabetizado === undefined || url.alfabetizado === this.alfabetizado)
      .filter(_ => item.somenteDownload || this.assinaturaManual || this.timeline.informacaoComplementar.assinadoDigitalmente)[0];

    if (filteredUrl) {
      return filteredUrl.url || filteredUrl.urlOmniReport;
    }

    return '';
  }

  abrirModalAssinatura() {
    const initialState = { proposta: this.proposta };
    this.modal.show(ModalAssinaturaComponent, { ...appSettings.MODAL_PARAMS, initialState });
  }

  get assinado() {
    return this.timeline.informacaoComplementar.assinadoDigitalmente;
  }
}
