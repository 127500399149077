import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { StringReplace } from './../../../../shared/partner/string-replace';

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.css']
})
export class RecordListComponent {

  @Input() isShowList;
  @Input() propostas: any[];

  constructor(private router: Router) { }

  goToTimeline(proposta) {
    switch (proposta.produto) {
      case 'CDC LOJA': case 'CDC PREMIUM': {
        return this.router.navigate(['/produtos/cdcloja/timelines'], {
          queryParams: { id: proposta.id }
        });
      }

      case 'CRÉDITO PESSOAL': case 'MICROCREDITO': {
        return this.router.navigate(['/produtos/cp/timelines'], {
          queryParams: { id: proposta.id }
        });
      }

      default: {
        const produto = StringReplace.removeSpaces(
          StringReplace.removeAccentuation(proposta.produto)
        ).toLowerCase();

        const idFicha = proposta.idProposta || proposta.id;
        this.router.navigate(['/timeline'], {
          queryParams: { produto, idFicha }
        });
      }
    }
  }

  isRecusada(proposta): boolean {
    return proposta && proposta.statusProposta.toUpperCase() === 'RECUSADA';
  }

  isAnalise(proposta): boolean {
    return proposta && proposta.statusProposta.toUpperCase() === 'EM ANÁLISE';
  }

  isAnaliseDireta(proposta): boolean {
    return proposta && proposta.statusProposta.toUpperCase() === 'EM ANÁLISE DIRETA';
  }

  isAprovada(proposta): boolean {
    return proposta && proposta.statusProposta.toUpperCase() === 'APROVADA';
  }

  isContrato(proposta): boolean {
    return proposta && proposta.statusProposta.toUpperCase() === 'CONTRATO DISPONÍVEL';
  }
}
