import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuSharedService {

  private onChangeAgenteSubject = new Subject<any>();
  onChangeAgente = this.onChangeAgenteSubject.asObservable();

  notifyAgenteChange() {
    this.onChangeAgenteSubject.next();
  }

}
