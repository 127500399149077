import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Profissao } from '../../shared/dados-profissionais/model/profissao';
import { OmniMaisRestService } from '../../omni-rest/omni-mais/omni-mais-rest.service';

@Injectable()
export class ProfissaoService {

  constructor(private restService: OmniMaisRestService) { }

  profissoes(classeProfissional: number): Observable<{ profissoes: Profissao[] }> {
    return this.restService.get(`profissao/listarProfissoesPorClasse/${classeProfissional}`);
  }

}
