export class RecuperaEndereco {

  private correspondencia: boolean;
  private endereco: string;
  private numero: number;
  private bairro: string;
  private idCidade: number;
  private nomeCidade: string;
  private uf: string;
  private cep: string;
  private tipo: number; // 1 - RESIDENCIAL | 2 - COMERCIAL | 3 - OUTROS

  constructor(obj) {
    this.correspondencia = obj.correspondencia;
    this.endereco = obj.endereco;
    this.numero = parseInt(obj.numero, 10);
    this.bairro = obj.bairro;
    this.idCidade = obj.cidade.id;
    this.nomeCidade = obj.cidade.nome;
    this.uf = obj.uf;
    this.cep = obj.cep;
    this.tipo = parseInt(obj.tipo, 10);
  }

  getCorrespondencia(): boolean {
    return this.correspondencia;
  }

  getEndereco(): string {
    return this.endereco;
  }

  getNumero(): number {
    return this.numero;
  }

  getBairro(): string {
    return this.bairro;
  }

  getIdCidade(): number {
    return this.idCidade;
  }

  getNomeCidade(): string {
    return this.nomeCidade;
  }

  getUf(): string {
    return this.uf;
  }

  getCep(): string {
    return this.cep;
  }

  getTipo(): number {
    return this.tipo;
  }

}
