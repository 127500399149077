import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../../shared/shared.module';
import { AfterSelfieComponent } from './after-selfie/after-selfie.component';
import { BeforeSelfieComponent } from './before-selfie/before-selfie.component';
import { CaptureSelfieVerificacaoComponent } from './before-selfie/capture-selfie-verificacao/capture-selfie-verificacao.component';
import { ModalAvisoAcessoCameraComponent } from './before-selfie/modal-aviso-acesso-camera/modal-aviso-acesso-camera.component';
import { ModalDicasFotoVerificacaoComponent } from './before-selfie/modal-dicas-foto/modal-dicas-foto-verificacao.component';
import { BemVindoComponent } from './bem-vindo/bem-vindo.component';
import { ConcluidoComponent } from './concluido/concluido.component';
import { FichaVerificacaoSegurancaComponent } from './ficha-verificacao-seguranca.component';
import { FichaVerificacaoSegurancaService } from './ficha-verificacao-seguranca.service';
import { FichaVerificacaoSegurancaRoutingModule } from './ficha-verificacao-segurança-routing.module';
import { ModalErroComponent } from './verificar/modal-erro/modal-erro.component';
import { ModalTermosComponent } from './verificar/modal-termos/modal-termos.component';
import { ModalVerificacaoComponent } from './verificar/modal-verificacao/modal-verificacao.component';
import { VerificarComponent } from './verificar/verificar.component';
import { VerificacaoSegurancaIdentificacaoComponent } from './verificacao-seguranca-identificacao/verificacao-seguranca-identificacao.component';
import { MaskModule } from 'soft-angular-mask';
import { ModalAvisoCpfInvalidoComponent } from './verificacao-seguranca-identificacao/modal-aviso-cpf-invalido/modal-aviso-cpf-invalido.component';
import { ModalAvisoIosComponent } from './bem-vindo/modal-aviso-ios/modal-aviso-ios.component';
import { ModalErroAoAcessarCameraComponent } from './before-selfie/modal-erro-ao-acessar-camera/modal-erro-ao-acessar-camera.component';

@NgModule({
  declarations: [
    FichaVerificacaoSegurancaComponent,
    BemVindoComponent,
    VerificarComponent,
    ModalVerificacaoComponent,
    ModalErroComponent,
    ConcluidoComponent,
    ModalTermosComponent,
    BeforeSelfieComponent,
    ModalDicasFotoVerificacaoComponent,
    CaptureSelfieVerificacaoComponent,
    AfterSelfieComponent,
    ModalAvisoAcessoCameraComponent,
    ModalErroAoAcessarCameraComponent,
    VerificacaoSegurancaIdentificacaoComponent,
    ModalAvisoCpfInvalidoComponent,
    ModalAvisoIosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FichaVerificacaoSegurancaRoutingModule,
    ReactiveFormsModule,
    WebcamModule,
    MaskModule
  ],
  entryComponents: [
    ModalVerificacaoComponent,
    ModalErroComponent,
    ModalTermosComponent,
    ModalDicasFotoVerificacaoComponent,
    ModalAvisoAcessoCameraComponent,
    ModalErroAoAcessarCameraComponent,
    ModalAvisoCpfInvalidoComponent,
    ModalAvisoIosComponent
  ],
  providers: [FichaVerificacaoSegurancaService],
})
export class FichaVerificacaoSegurancaModule {}
