import { Directive, ElementRef, forwardRef, Inject, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[lowercase]',
  host: {
    '(input)': 'input($event.target.value, $event.target)',
    '(blur)': 'focusout()'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LowerCaseDirective),
      multi: true
    }
  ]
})

export class LowerCaseDirective {

  constructor(@Inject(Renderer2) private renderer: Renderer2, @Inject(ElementRef) private element: ElementRef) { }

  writeValue(value: any) {
    if (value === undefined || value === null) {
      this.propagateChange(null);
      this.renderer.setProperty(this.element.nativeElement, 'value', '');
    } else {
      this.input(value);
    }
  }

  propagateChange = (_: any) => {
    // This is intentional
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public onTouched: any = () => {
    // This is intentional
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  input(val, event?) {

    const retVal = val.toLowerCase();
    this.propagateChange(retVal);
    this.renderer.setProperty(this.element.nativeElement, 'value', retVal);

    setTimeout(() => {
      if (event !== undefined) {
        event.setSelectionRange(retVal.length, retVal.length);
      }
    },         0);

  }

  focusout() {

    this.onTouched();

  }

}
