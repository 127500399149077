import { StatusModel } from './../../../models/statusModel';
import { FichaStatus } from './ficha-status-interface';
export class StatusEmAnalise implements FichaStatus{
  status:StatusModel[] = [];

  constructor() {
    this.status.push(new StatusModel('Resultado Parcial', '1'));
    this.status.push(new StatusModel('Aguardando Análise', '2'));
    this.status.push(new StatusModel('Em Análise', '3'));
    this.status.push(new StatusModel('Todos', '-1'));
  }

  getStatus() {
    return this.status;
  }
}
