import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-after-selfie',
  templateUrl: './after-selfie.component.html',
  styleUrls: ['./after-selfie.component.scss'],
})
export class AfterSelfieComponent implements OnInit {
  @Input() foto: File;
  fotoUrl: any;

  ngOnInit() {
    this.fotoUrl = 'assets/images/examples/selfie_man 2.png';
  }
}
