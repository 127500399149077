import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { ModalTermosComponent } from './modal-termos/modal-termos.component';

@Component({
  selector: 'app-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.scss']
})
export class TermosComponent {

  @Output() scrEvent = new EventEmitter();
  @Output() termosDeUsoEvent = new EventEmitter();

  scr = false;
  termosDeUso = false;

  constructor(
    private modal: BsModalService,
  ) {}

  onClickTermos(type = 'termos-de-uso' || 'scr') {

    this.modal.show(ModalTermosComponent, {
      class: 'modal-lg',
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        type
      }
    });
  }

  emitChange(type = 'termos-de-uso' || 'scr') {
    if (type === 'termos-de-uso') {
      this.termosDeUsoEvent.emit(this.termosDeUso);
    } else {
      this.scrEvent.emit(this.scr);
    }
  }
}
