import { Injectable } from '@angular/core';

import { LoginAgente } from './model/login-agente';
import { LoginUsuarioTO } from './model/login-usuario-to';
import { StorageService } from '../services/storage/storage.service';

const USER = 'id_session';
const AGENTE = 'id_session_ag';
const REDIRECT_ROUTE = 'id_redirect_route';
const EXPIRATION = 'id_expiration';

@Injectable()
export class LoginStorage {

  constructor(private service: StorageService) {}

  register(usuario: LoginUsuarioTO) {
    this.setStorage(USER, usuario);
  }

  registerAgente(agente: LoginAgente) {
    this.setStorage(AGENTE, agente);
  }

  registerRedirectRoute(route: string) {
    this.setStorage(REDIRECT_ROUTE, route);
  }

  registerDataExpiracao(dataExpiracao: number) {
    sessionStorage.setItem(EXPIRATION, dataExpiracao.toString());
  }

  clear(): void {
    sessionStorage.removeItem(USER);
    sessionStorage.removeItem(AGENTE);
    sessionStorage.removeItem(EXPIRATION);
  }

  clearRedirectRoute() {
    sessionStorage.removeItem(REDIRECT_ROUTE);
  }

  isContainsToken(): boolean {
    return this.usuario.token && this.usuario.token !== 'undefined' && this.usuario.token.trim().length > 0 && !this.isTokenExpired();
  }

  isTokenExpired(): boolean {
    let expirationDate: number = this.dataExpiracao;

    if (expirationDate > 0) {
      return Date.now() > expirationDate;
    } else if (this.login && this.token) {
      this.service.renewUser(this.loginAndTokenAsJson).pipe().take(1).subscribe(
        (result) => {
          this.register(result.usuario);
          this.registerDataExpiracao(result.dataExpiracao);
          expirationDate = result.dataExpiracao;
          return Date.now() > expirationDate;
        },
        (error) => {
          return true;
        });
    } else {
      return false;
    }
  }

  get dataExpiracao(): number {
    return Number(sessionStorage.getItem(EXPIRATION));
  }

  get token(): string {
    return this.usuario.token;
  }

  get login(): string {
    return this.usuario.login;
  }

  get loginAndTokenAsJson(): string {
    const usuario: LoginUsuarioTO = this.usuario;
    return JSON.stringify({ login: usuario.login, token: usuario.token });
  }

  get usuario(): LoginUsuarioTO {
    return new LoginUsuarioTO(this.getStorage(USER));
  }

  get agente(): LoginAgente {
    return this.getStorage(AGENTE);
  }

  get redirectRoute(): string {
    return this.getStorage(REDIRECT_ROUTE);
  }

  private setStorage(key: string, value: any) {
    sessionStorage.setItem(key, btoa(JSON.stringify(value)));
  }

  private getStorage(key: string): any {
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(atob(value));
    }
    return {};
  }
}
