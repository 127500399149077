import { Injectable } from '@angular/core';
import { Optional } from '../optional/optional';

@Injectable()
export class ContextSharedService {
  private context: any;

  public set(context) {
    this.context = context;
  }

  public get(): Optional {
    const value = this.context;
    this.context = undefined;
    return Optional.of(value);
  }
}
