import { Faef } from './../model/faef';
import { PropostaStateService } from './../../../../../shared/states/proposta-state.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

import { FaefStateService } from './../faef-state.service';

@Component({
  selector: 'app-dados-empreendimento',
  templateUrl: './dados-empreendimento.component.html',
  styleUrls: ['./dados-empreendimento.component.css']
})
export class DadosEmpreendimentoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() loading = new EventEmitter(true);

  private alive = true;
  empreendimentoForm: FormGroup;
  flagCreditoAtivo: FormControl;
  instituicoesForm: FormArray;

  constructor(
    private fb: FormBuilder,
    private state: FaefStateService,
    private propostaState: PropostaStateService
  ) { }

  ngOnInit() {
    this.createForms();
    this.initObservers();

    this.loading.emit(true);
    this.init();
  }

  async init() {
    const propostaId = this.propostaState.getLast().id;
    await this.state.init(propostaId);
    const faef = this.state.getLast();
    if (faef) {
      this.empreendimentoForm.patchValue({
        ...faef,
        tipoPontoComercial: String(faef.tipoPontoComercial)
      });
    }
    setTimeout(() => this.loading.emit(false), 300);
  }

  ngAfterViewInit() {
    this.init();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private createForms() {
    this.flagCreditoAtivo = new FormControl();
    this.instituicoesForm = new FormArray([]);

    this.empreendimentoForm = this.fb.group({
      dataInicioAtividade: ['', Validators.required],
      dataInicioPonto: ['', Validators.required],
      concorrencia: ['', Validators.required],
      tipoRenda: ['', Validators.required],
      localFuncionamento: ['', Validators.required],
      tipoPontoComercial: ['', Validators.required],
      setorEconomico: ['', Validators.required],
      flagMudancaAtividade: ['', Validators.required],
      flagExperienciaCrediticia: ['', Validators.required],
      flagCreditoAtivo: this.flagCreditoAtivo,
      faefCredito: this.instituicoesForm
    });
  }

  private initObservers() {
    this.empreendimentoForm
      .get('flagExperienciaCrediticia')
      .valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(value => {
        if (value === 'S') {
          this.flagCreditoAtivo.setValidators([Validators.required]);
        } else {
          this.flagCreditoAtivo.clearValidators();
          this.flagCreditoAtivo.reset(null);
        }
      });

    this.flagCreditoAtivo
      .valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(value => {
        if (value !== 'S') {
          this.instituicoesForm.clear();
          this.instituicoesForm.clearValidators();
        }
      });
  }

  private updateState() {
    const faef:Faef = this.empreendimentoForm.value;

    this.state.setState({
      ...faef,
      tipoPontoComercial: String(faef.tipoPontoComercial),
      faefCredito: faef.faefCredito.map(c => ({
        localCredito: c.localCredito,
        quantidadeParcelasPendentes: Number(c.quantidadeParcelasPendentes),
        valorParcela: Number(c.valorParcela)
      }))
    });
  }

  async onClickNext() {
    this.updateState();
    this.next.emit();
  }

  onClickPrevious() {
    this.updateState();
    this.previous.emit();
  }

}
