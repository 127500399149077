import { Component, Input } from '@angular/core';

@Component({
  selector: 'perfil-cliente',
  templateUrl: './perfil-cliente.component.html',
  styleUrls: ['./perfil-cliente.component.css'],
})
export class PerfilClienteComponent {
  @Input() perfil: string;

  get valor() {
    let score = 0;
    switch (this.perfil) {
      case 'A':
        score = 5;
        break;
      case 'B':
        score = 4;
        break;
      case 'C':
        score = 3;
        break;
      case 'D':
        score = 2;
        break;
      case 'E':
        score = 1;
        break;
    }
    return score;
  }
}
