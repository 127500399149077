import { Ano } from './ano';
import { Mes } from './mes';
import { Periodo } from './periodo';
import { Semana } from './semana';
import { TresDias } from './tres-dias';

export class PeriodoFactory {

  public static readonly PADRAO = true;

  public static tresDias(padrao?: boolean): Periodo {
    return new TresDias(padrao);
  }

  public static semana(padrao?: boolean): Periodo {
    return new Semana(padrao);
  }

  public static mes(padrao?: boolean): Periodo {
    return new Mes(padrao);
  }

  public static ano(padrao?: boolean): Periodo {
    return new Ano(padrao);
  }
}
