import { FichaVeiculoStatus } from './../../../ficha-veiculo/ficha-veiculo.status';
export class DadosGrafico {
  private _status: string[] = [];
  private _cores: string[] = [];
  private _valores: number[] = [];
  private _sosCredito = 0;
  private _sosFormalizacao = 0;
  private _pagamentoAgendado = 0;
  private _total = 0;

  constructor(totaisStatus: any[]) {
    if (totaisStatus && totaisStatus.length > 0) {
      this.montaDadosGrafico(totaisStatus);
    }
  }

  get status(): string[] {
    return this._status;
  }

  get valores(): number[] {
    return this._valores;
  }

  get cores(): string[] {
    return this._cores;
  }

  get sosCredito(): number {
    return this._sosCredito;
  }

  get sosFormalizacao(): number {
    return this._sosFormalizacao;
  }

  get pagamentoAgendado(): number {
    return this._pagamentoAgendado;
  }
  get total(): number {
    return this._total;
  }

  private montaDadosGrafico(totaisStatus: any[]) {
    let outros = 0;
    this._total = 0;
    totaisStatus.forEach(status => {
      this._total += status.totalStatus;
      switch (status.idStatus) {
        case FichaVeiculoStatus.EM_ANALISE_DIRETA:
          this._sosCredito = status.totalSos || 0;
          this.addStatus('Em Análise Direta', '#ffc000', status.totalStatus);
          break;
        case FichaVeiculoStatus.APROVADA:
          this.addStatus('Aprovada', '#00ba4b', status.totalStatus);
          break;
        case FichaVeiculoStatus.RECUSADA:
          this.addStatus('Recusada', '#b7230f', status.totalStatus);
          break;
        case FichaVeiculoStatus.EM_ANALISE:
          this._sosFormalizacao = status.totalSos || 0;
          this.addStatus('Em Análise', '#0e0060', status.totalStatus);
          break;
        case FichaVeiculoStatus.CONTRATO_DISPONÍVEL:
          this.addStatus('Contrato Disponível', '#00a1cc', status.totalStatus);
          break;
        case FichaVeiculoStatus.PAGAMENTO_AGENDADO:
          this._pagamentoAgendado = status.totalSos || 0;
          outros += status.totalStatus;
          break;
        default:
          outros += status.totalStatus;
      }
    });

    if (outros > 0) {
      this.addStatus('Outros', '#a6a6a6', outros);
    }
  }

  private addStatus(descricao: string, cor: string, valor: number) {
    this._status.push(descricao);
    this._cores.push(cor);
    this._valores.push(valor);
  }
}
