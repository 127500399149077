import { SeguroSimulacao } from './seguro-simulacao';
import { AssistenciaSimulacao } from './assistencia-simulacao';
import { PessoaSimulacao } from './pessoa-simulacao';
import { VendedorSimulacao } from './vendedor-simulacao';
import { LojistaSimulacao } from './lojista-simulacao';
import { VeiculoSimulacao } from './veiculo-simulacao';
import { TaxaClienteSimulacao } from './taxa-cliente-simulacao';
import { GrupoSeguro } from './grupo-seguro';
import { GrupoAssistencia } from './grupo-assistencia';
export class PropostaSimulacao {
  id: string;
  grupo1: string;
  cliente: PessoaSimulacao;
  taxaCliente: TaxaClienteSimulacao;
  lojista: LojistaSimulacao;
  veiculos: VeiculoSimulacao[] = [];
  vendedor: VendedorSimulacao;
  avalistas: PessoaSimulacao[];
  idSimulacao: number = null;

  public seguros: SeguroSimulacao[] = [];
  public assistencias: AssistenciaSimulacao[] = [];
  public gruposSeguros: GrupoSeguro[] = [];
  public gruposAssistencias: GrupoAssistencia[] = [];

  private payload: any = {};

  constructor(obj) {
    this.id = (obj.proposta || {}).id;
    this.grupo1 = obj.grupo1 || '';
    this.cliente = new PessoaSimulacao(obj.cliente || {});
    this.taxaCliente = new TaxaClienteSimulacao(obj.taxaCliente || {});
    this.lojista = new LojistaSimulacao(obj.lojista || {});
    this.seguros = (obj.seguros || []).map(
      seguro => new SeguroSimulacao(seguro),
    );
    this.assistencias = (obj.assistencias || []).map(
      assistencia => new AssistenciaSimulacao(assistencia),
    );
    this.veiculos = (obj.veiculos || []).map(
      veiculo => new VeiculoSimulacao(veiculo),
    );
    this.vendedor = new VendedorSimulacao(obj.vendedor || {});
    this.avalistas = (obj.avalistas || []).map(
      avalista => new PessoaSimulacao(avalista),
    );
    if (obj.id) {
      this.idSimulacao = parseInt(obj.id, 10);
    }
  }

  toPayloadWithoutVeiculos(): any {
    this.payload = {};
    this.payload['id'] = this.idSimulacao;
    this.payload['grupo1'] = this.grupo1;
    this.payload['lojaId'] = this.lojista.getId();
    this.payload['vendedorId'] = this.vendedor.getId();

    this.payload['cpf'] = this.cliente.getCpf();
    this.payload['dataNascimento'] = this.cliente.getDataNascimento();
    this.payload['ddd'] = this.cliente.getTelefones()[0].getDdd();
    this.payload['celular'] = this.cliente.getTelefones()[0].getNumero();
    this.payload['renda'] = this.cliente.getRenda();

    if (this.avalistas.length > 0) {
      const avalistas: any[] = [];
      this.avalistas.forEach((avalista: PessoaSimulacao) => {
        avalistas.push({
          cpf: avalista.getCpf(),
          dataNascimento: avalista.getDataNascimento(),
          ddd: avalista.getTelefones()[0].getDdd(),
          celular: avalista.getTelefones()[0].getNumero(),
          renda: avalista.getRenda(),
        });
      });
      this.payload['avalistas'] = avalistas;
    }
    return this.payload;
  }

  getId(): string {
    return this.id;
  }

  getCliente(): PessoaSimulacao {
    return this.cliente;
  }

  getTaxaClinte(): TaxaClienteSimulacao {
    return this.taxaCliente;
  }

  getGrupo1(): string {
    return this.grupo1;
  }

  getLojista(): LojistaSimulacao {
    return this.lojista;
  }

  getVendedor(): VendedorSimulacao {
    return this.vendedor;
  }

  getAvalistas(): PessoaSimulacao[] {
    return this.avalistas;
  }

  getCotacaoVeiculo(): number {
    if (this.veiculos && this.veiculos.length > 0) {
      return this.veiculos[0].getCotacao();
    }
    return 0;
  }

  getSeguros(): SeguroSimulacao[] {
    return this.seguros;
  }

  getAssistencias(): AssistenciaSimulacao[] {
    return this.assistencias;
  }

  getIdSimulacao(): number {
    return this.idSimulacao;
  }

  get placaVeiculo() {
    if (this.veiculos && this.veiculos.length > 0 && this.veiculos[0]) {
      return this.veiculos[0].getPlaca();
    }
    return '';
  }
}
