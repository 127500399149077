import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';

import { Proposta } from '../../../../shared/models/proposta';
import { CpfCnpj } from '../../../../../shared/partner/cpf-cnpj';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';

@Component({
  selector: 'app-analise',
  templateUrl: './analise.component.html',
  styleUrls: ['./analise.component.css']
})
export class AnaliseComponent implements OnInit, OnDestroy {
  @Input() formUpdateEmitter: EventEmitter<any>;
  proposta: Proposta;
  isAlive: boolean = true;

  constructor(
    private state: PropostaStateService
  ) {}

  ngOnInit() {
    this.proposta = this.state.getLast();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  get cpfCliente() {
    return CpfCnpj.formatWithMask(this.proposta.cliente.cpf);
  }

  get nomeCliente() {
    return this.proposta.cliente.nomeCliente.toLowerCase();
  }

}
