import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY as observableEmpty } from 'rxjs';

import { CdcLojaRestService } from '../../../omni-rest/cdc/cdc-loja-rest.service';
import { Lojista } from './models/lojista';
import { RelatorioRequest } from './models/relatorio-request';
import { TipoRelatorio } from './models/tipo-relatorio';

import { LoginStorage } from '../../../login/login.storage';
import { Router } from '@angular/router';

@Injectable()
export class ReportsService {
  constructor(
    private restService: CdcLojaRestService,
    protected http: HttpClient,
    private loginStorage: LoginStorage,
    private router: Router,
  ) { }

  buscarRelatorios(): Observable<{ relatorios: TipoRelatorio[] }> {
    return this.restService.get('reports');
  }

  buscarRelatoriosLojas(): Observable<{ lojistas: Lojista[] }> {
    return this.restService.get('reports/lojistas');
  }

  public gerarRelatorio(page: number, size: number, body: RelatorioRequest): Observable<any> {
    return this.restService.post(`reports/generate?page=${page}&size=${size}`, body);
  }

  public gerarRelatorioAgrupado(body: RelatorioRequest): Observable<any> {
    return this.restService.post('reports/generate-grouped', body);
  }

  public exportarRelatorio(body: any): Observable<any> {
    if (this.loginStorage.isTokenExpired()) {
      this.router.navigate(['/login']);
      return observableEmpty;
    }
    return this.http.post(
      this.restService.getBaseUrl().concat('reports/export-excel'),
      JSON.stringify(body),
      {
        headers: this.restService.getHeaders(),
        responseType: 'blob',
      }
    );
  }

  public exportarRelatorioAgrupado(body: any): Observable<any> {
    if (this.loginStorage.isTokenExpired()) {
      this.router.navigate(['/login']);
      return observableEmpty;
    }
    return this.http.post(
      this.restService.getBaseUrl().concat('reports/export-excel-grouped'),
      JSON.stringify(body),
      {
        headers: this.restService.getHeaders(),
        responseType: 'blob',
      }
    );
  }
}
