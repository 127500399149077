import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { appSettings as globals } from '../../../../../../../environments/app.setings';
import { DialogService } from '../../../../../../services/dialog/dialog.service';
import { ModalErroValidacaoComponent } from '../modal-erro-validacao/modal-erro-validacao.component';
import { ModalValidandoComponent } from '../modal-validando/modal-validando.component';

@Component({
  selector: 'app-webcam',
  templateUrl: './app-webcam.component.html',
  styleUrls: ['./app-webcam.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppWebcamComponent),
      multi: true
    }
  ]
})
export class AppWebcamComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('selfieInput', { static: true }) selfieInput: ElementRef;

  @Input() isLojistaPiloto: boolean;
  @Input() propostaId: number;
  @Input() isAceitaImagem: boolean = false;
  @Input() isEnabledFotoAndEnviar = false;

  @Output() showLoader = new EventEmitter<boolean>();

  readonly ACCEPT_IMAGES = globals.ACCEPT_IMAGES;
  hasWebcam: boolean = false;
  showWebcam: boolean = true;
  tirouFoto: boolean = false;
  webcamTrigger: Subject<void> = new Subject<void>();

  textoErroWebcam: string = 'Não há webcams disponíveis.';

  numeroTentativasBiometria: number = 3;
  hasErrorBiometriaAssessment: boolean = false;
  hasBiometriaCreditRequest: boolean = false;
  selfiePreview;

  isAlive: boolean = true;

  onChange: (imagem: WebcamImage | File) => void;
  onTouch: () => void;

  constructor(private dialogService: DialogService, private modal: BsModalService) { }

  ngOnInit() {
    this.initWebcam();
  }

  writeValue(obj: WebcamImage | File): void {
    this.selfiePreview = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private initWebcam() {
    WebcamUtil.getAvailableVideoInputs()
      .then(
        (mediaDevices: MediaDeviceInfo[]) => {
          this.hasWebcam = mediaDevices && mediaDevices.length > 0;
          this.showLoader.emit(false);
        }
      ).catch(
        error => {
          this.handleErroWebcam(error);
          this.showLoader.emit(false);
        }
      );
  }

  handleErroWebcam($event) {
    this.textoErroWebcam = 'Erro iniciando a webcam. Verifique as permissões de vídeo e a conectividade da webcam.';
    console.error($event);
  }

  handleImageCapture(imagem: WebcamImage | File, uploadArquivo = false): void {
    if (this.isLojistaPiloto && !uploadArquivo) {
      this.tirouFoto = true;
      this.openModalValidando(imagem);
    }

    this.previewSelfie(imagem);
    this.onChange(imagem);
  }

  onClickTirarUmaFoto(): void {
    this.showWebcam = !this.showWebcam;
    this.webcamTrigger.next();
  }

  onClickTirarOutraFoto(): void {
    this.hasErrorBiometriaAssessment = false;
    if (this.numeroTentativasBiometria === 0) {
      this.numeroTentativasBiometria = 3;
    }

    if (this.hasWebcam) {
      this.showWebcam = !this.showWebcam;
      this.onChange(null);
    } else {
      (this.selfieInput.nativeElement as HTMLElement).click();
    }
  }

  selecionarSelfieManual(event) {
    if ((event.target.files[0].size / 1048576) > 10) {
      event.target.value = null;

      return this.dialogService.confirm({
        body: 'Uma ou mais das imagens selecionadas tem tamanho superior à 10mb.',
        callbackSuccess: () => event.target.click()
      });
    }

    if (event && event.target
      && event.target.files && event.target.files[0]
      && globals.ACCEPT_IMAGES_MYMES.includes(event.target.files[0].type)) {
      this.handleImageCapture(event.target.files[0], true);
      this.showWebcam = false;
    }
  }

  async previewSelfie(imagem) {
    const imgFile = imagem;
    if (!imgFile) {
      this.selfiePreview = null;
    } else if (imgFile instanceof WebcamImage) {
      this.selfiePreview = imgFile.imageAsDataUrl;
    } else {
      this.selfiePreview = await this.readFileAsDataURL(imgFile);
    }
  }

  private async readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (_) => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });
  }

  openModalValidando(imagem) {
    const imgFile = imagem;
    let photo: string;

    if (this.tirouFoto && imgFile instanceof WebcamImage) {
      photo = imgFile.imageAsBase64;
    }

    const initialState = {
      foto: photo,
      proposta: this.propostaId
    };

    const modal = this.modal.show(ModalValidandoComponent, { ...globals.MODAL_PARAMS, initialState });
    const validou = <ModalValidandoComponent>modal.content;

    validou.onValidar
      .pipe(
        takeWhile(() => validou.alive)
      ).subscribe(retorno => {
        if (retorno) {
          this.hasBiometriaCreditRequest = true;
          return;
        }
        this.numeroTentativasBiometria--;

        if (this.numeroTentativasBiometria === 0) {
          this.hasErrorBiometriaAssessment = false;
          return;
        }
        this.handleErrorBiometriaUnico();
      });
  }

  private handleErrorBiometriaUnico() {
    this.hasErrorBiometriaAssessment = true;
    this.openModalErroValidacao();
  }

  private openModalErroValidacao() {
    const initialState = {
      retornoAssessment: 'Erro ao realizar biometria. Por favor aproxime-se da câmera, centralize o rosto na área de captura e tente novamente!'
    };
    this.modal.show(ModalErroValidacaoComponent, { ...globals.MODAL_PARAMS, initialState });
  }

  onClickSaibaMais() {
    this.openModalErroValidacao();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
