import {
  Directive,
  ElementRef,
  forwardRef,
  Inject,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[license-plate]',
  host: {
    '(input)': 'input($event.target.value, $event.target)',
    '(blur)': 'focusout()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicensePlateDirective),
      multi: true,
    },
  ],
})
export class LicensePlateDirective {
  constructor(
    @Inject(Renderer2) private renderer: Renderer2,
    @Inject(ElementRef) private element: ElementRef
  ) {}

  writeValue(value: any) {
    if (value === undefined || value === null) {
      this.propagateChange(null);
      this.renderer.setProperty(this.element.nativeElement, 'value', '');

    } else {
      this.input(value);
    }
  }

  propagateChange = (_: any) => {
    // This is intentional
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public onTouched: any = () => {
    // This is intentional
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  input(val, event?) {
    let mascared = '';
    const unmask = val.toString().replace(new RegExp(/[^A-Za-z0-9]/, 'g'), '');
    const mascara = 'AAA9X99';
    let c = 0;

    for (let i = 0; i < mascara.length && c < unmask.length; i++) {
      if (mascara.slice(i, i + 1) === '9') {
        mascared += unmask.slice(c, c + 1).replace(/[^0-9]/g, '');
        c++;
      } else if (mascara.slice(i, i + 1) === 'X') {
        mascared += unmask
          .slice(c, c + 1)
          .toUpperCase()
          .replace(/[^A-Z][0-9]/g, '');
        c++;
      } else if (mascara.slice(i, i + 1) === 'A') {
        mascared += unmask
          .slice(c, c + 1)
          .toUpperCase()
          .replace(/[^A-Z]/g, '');
        c++;
      } else {
        mascared += mascara.slice(i, i + 1);
      }
    }

    this.propagateChange(mascared);
    this.renderer.setProperty(this.element.nativeElement, 'value', mascared);

    setTimeout(() => {
      if (event !== undefined) {
        event.setSelectionRange(mascared.length, mascared.length);
      }
    },         0);
  }

  focusout() {
    this.onTouched();
  }
}
