import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import * as toastr from 'toastr';

import { LoginStorage } from '../../../../../login/login.storage';
import { FichaEstapasCadastro } from '../../../../../produtos/shared/components/ficha-etapas-cadastro/ficha-etapas-cadastro';
import { VendedorService } from '../../../../../services/vendedor/vendedor.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TipoSolicitanteEnum } from '../../../../shared/enums/tipo-solicitante.enum';
import { LojaVendedor } from '../../../../shared/models/loja-vendedor';
import { NumeroEtapaCadastroFichaCDCLoja } from '../../../../shared/services/etapas/etapas.service';
import { CdcLojaService } from './../../../services/cdc-loja.service';

@Component({
  selector: 'app-selecionar-lojista-vendedor',
  templateUrl: './selecionar-lojista-vendedor.component.html',
  styleUrls: ['./selecionar-lojista-vendedor.component.scss']
})
export class SelecionarLojistaVendedorComponent implements OnInit, OnDestroy {
  @ViewChild('modalMessage', { static: true }) modalMessage: NewModalMessageComponent;
  @Input() operacao: number;
  @Input() solicitante;
  @Input() value: LojaVendedor;
  @Input() formUpdateEmitter: EventEmitter<FichaEstapasCadastro>;
  @Output() valueChange = new Subject<LojaVendedor>();
  @Output() valid = new BehaviorSubject(false);

  form: FormGroup;
  lojas: any[];
  vendedores: any[];
  alive = true;

  vendedorKey = item => item.id;
  vendedorLabel = item => item.nome;

  constructor(
    private fb: FormBuilder,
    private cdclService: CdcLojaService,
    private vendedorService: VendedorService,
    private loginStorage: LoginStorage
  ) { }

  ngOnInit() {
    this.initForm();
    this.initLojistas();
    this.populateForm();

    this.formUpdateEmitter
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => this.handleUpdateEmission(res));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  async onAddVendedor(nome) {
    try {
      const response = await this.cdclService.cadastrarVendedor({
        nome,
        codigoLojista: Number(this.lojaIdControl.value)
      });

      this.vendedores.push(response.vendedor);
      this.vendedorIdControl.setValue(response.vendedor.id);
    } catch (e) {
      toastr.error(e.message, 'Vendedor não cadastrado!');
    }
  }

  private populateForm() {
    if (this.value.lojaId) {
      this.lojaIdControl.setValue(this.value.lojaId);
    }

    if (this.value.vendedorId) {
      this.vendedorIdControl.setValue(this.value.vendedorId);
    }
  }

  private initForm() {
    this.form = this.fb.group({
      lojaId: ['', Validators.required],
      vendedorId: ['', Validators.required]
    });

    this.lojaIdControl.valueChanges.pipe(
      takeWhile(() => this.alive))
      .subscribe(() => this.buscarVendedores());

    this.form.valueChanges.pipe(
      takeWhile(() => this.alive))
      .subscribe(() => this.handleValuesChange());
  }

  private handleValuesChange() {
    this.valid.next(this.form.valid);
    this.valueChange.next(this.form.value);
  }

  private initLojistas() {
    if (this.usuarioLogado.isLojista) {
      this.lojaIdControl.setValue(this.usuarioLogado.lojaTO.id);
    } else if (this.isLojaBalcaoSelecionada) {
      this.lojaIdControl.setValue(this.agenteSelecionado.lojaBalcaoId);
    } else {
      this.buscarLojistas();
    }
  }

  private buscarLojistas() {
    this.buscarVendedores();

    this.cdclService
      .listaLojistaOperador(this.operacao)
      .pipe(take(1))
      .subscribe(
        res => (this.lojas = res.lojas ? res.lojas.sort(this.sortByName) : res.sort(this.sortByName)),
        () =>
          this.modalMessage.showModal({
            mensagem: 'A consulta de loja do vendedor não retornou resultados'
          })
      );
  }

  private buscarVendedores() {
    this.vendedorService
      .vendedoresByLojista(this.lojaIdControl.value).pipe(
        take(1))
      .subscribe(
        res => {
          this.vendedores = res.vendedores.sort(this.sortByName);
          this.vendedores.push(this.vendedorNaoCadastrado());

          if (this.vendedores.filter(v => v.id === Number(this.vendedorIdControl.value)).length === 0) {
            this.clearVendedores();
          }
        },
        () => {
          this.clearVendedores();
          this.vendedores = [this.vendedorNaoCadastrado()];
        }
      );
  }

  private vendedorNaoCadastrado() {
    return { id: '0', nome: 'Não cadastrado' };
  }

  private sortByName(a, b): number {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
    return 0;
  }

  private clearVendedores() {
    this.vendedorIdControl.reset();
  }

  private handleUpdateEmission(etapa): void {
    if (etapa === NumeroEtapaCadastroFichaCDCLoja.Vendedor) {
      this.handleValuesChange();
    }
  }

  get isSelecionarLoja() {
    return (
      !this.usuarioLogado.isLojista &&
      this.solicitante === TipoSolicitanteEnum.LOJA
    );
  }

  get lojaIdControl() {
    return this.form.get('lojaId');
  }

  get vendedorIdControl() {
    return this.form.get('vendedorId');
  }

  get usuarioLogado() {
    return this.loginStorage.usuario;
  }

  get agenteSelecionado() {
    return this.loginStorage.agente;
  }

  get titulo() {
    return this.isShowLojas
      ? 'Informações da Loja e do Vendedor'
      : 'Informações do Vendedor';
  }

  get isLojaBalcaoSelecionada() {
    return [TipoSolicitanteEnum.CLIENTE, TipoSolicitanteEnum.OUTRA].includes(
      this.solicitante
    );
  }

  get isShowLojas() {
    return !this.usuarioLogado.isLojista && !this.isLojaBalcaoSelecionada;
  }

}
