import { Component, Input, Output, EventEmitter } from '@angular/core';

import { PropostaSimulacao } from './../model/proposta/proposta-simulacao';
import { FichaCadastroSharedService } from './../../shared/ficha-cadastro-shared.service';
import { FichaCadastro } from './../../ficha-cadastro';
import { VeiculoSimulacao } from './../model/proposta/veiculo-simulacao';

@Component({
  selector: 'veiculo',
  templateUrl: './veiculo.component.html',
  styleUrls: ['./veiculo.component.css']
})
export class VeiculoComponent {

  @Input() veiculo: VeiculoSimulacao;
  @Input() fichaCadastro: FichaCadastro;
  @Input() proposta: PropostaSimulacao;

  @Output() veiculoAlterar: EventEmitter<any> = new EventEmitter();

  constructor(private sharedService: FichaCadastroSharedService) { }

  onClickAlterar() {
    this.fichaCadastro.passoAlterarVeiculo();
    this.fichaCadastro.replacePayload(this.proposta.toPayloadWithoutVeiculos());
    this.fichaCadastro.setIdSimulacao(this.proposta.getIdSimulacao());
    this.sharedService.alterarVeiculo(this.veiculo);
    this.veiculoAlterar.next();
  }
}
