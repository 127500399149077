import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { takeWhile, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { TiposCombustiveisService } from './../../../services/tipos-combustiveis/tipos-combustiveis.service';
import { TiposCombustiveis } from './../../../services/tipos-combustiveis/tipos-combustiveis';

import { FichaService } from '../../ficha-veiculo.service';
import { removeFichaContent } from '../helpers/salvarFicha';
import { ModalMessageComponent } from './../../../shared/modais/modal-message/modal-message.component';
import { FichaCadastro } from './../ficha-cadastro';
import { PropostaSimulacao } from './../ficha-cadastro-resultado-parcial/model/proposta/proposta-simulacao';
import { VeiculoSimulacao } from './../ficha-cadastro-resultado-parcial/model/proposta/veiculo-simulacao';
import { FichaCadastroSharedService } from './../shared/ficha-cadastro-shared.service';

@Component({
  selector: 'app-ficha-cadastro-veiculo',
  templateUrl: './ficha-cadastro-veiculo.component.html',
  styleUrls: ['./ficha-cadastro-veiculo.component.css'],
})
export class FichaCadastroVeiculoComponent
  implements OnInit, OnDestroy, DoCheck {
  @Input() fichaCadastro: FichaCadastro;
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;
  @ViewChild('formVeiculoInicial', { static: false }) formVeiculoInicial: FormGroup;

  cotacao: number;
  isObtendoFicha = false;
  isShowButtonBack = true;
  isShowBtnCancelarAlteracao = false;
  isErroFipeNaoEncontrado = false;
  private isAlive = true;
  hideClose: boolean = true;

  private codigoFornecedorCotacao: string;
  private ufLicenciamento: string;
  private placaVeiculo: string;

  private subsTipoVeiculo: Subscription;
  private subsMarca: Subscription;
  private subsModelo: Subscription;
  private subsVersao: Subscription;
  private subsCotacao: Subscription;

  private readonly ANALISE_PENDENTE: string = 'ANALISE_PENDENTE';
  private readonly REFUSED: string = 'REFUSED';
  private readonly FIPE_NOT_FOUND: string = 'FIPE_NOT_FOUND';
  private readonly PLACA_INVALIDA: string = 'PLACA_INVALIDA';
  private readonly TAXA_CLIENTE_NOT_FOUND: string = 'TAXA_CLIENTE_NOT_FOUND';
  private readonly ERRO_CRIVO: string = 'CRIVO_ERRO';
  private readonly AGUARDANDO_CARREGAMENTO: string = 'AGUARDANDO_CARREGAMENTO';
  private readonly GARANTIA_RECUSADA: string = 'GARANTIA_RECUSADA';

  listaTipoVeiculo = [];
  listaMarca = [];
  listaAnoModelo = [];
  listaModelo = [];
  listaVersao = [];
  listaTiposCombustiveis: TiposCombustiveis[] = [];

  isLoadingTipoVeiculo = false;
  isLoadingMarca = false;
  isLoadingAnoModelo = false;
  isLoadingModelo = false;
  isLoadingVersao = false;
  isLoadingCotacao = false;

  isSelectedTipoVeiculo = false;
  isSelectedMarca = false;
  isSelectedAnoModelo = false;
  isSelectedModelo = false;
  isSelectedVersao = false;
  isSelectedCotacao = false;

  private isSelectedUfLicenciamento = false;

  private isAlteracao = false;

  private isSelectedTipoVeiculoAfterBlur = true;
  private isSelectedMarcaAfterBlur = true;
  private isSelectedAnoModeloAfterBlur = true;
  private isSelectedModeloAfterBlur = true;
  private isSelectedVersaoAfterBlur = true;

  public isPlacaInvalida = false;

  constructor(
    private fichaService: FichaService,
    private sharedService: FichaCadastroSharedService,
    private router: Router,
    private tiposCombustiveisService: TiposCombustiveisService,
  ) {}

  ngOnInit() {
    this.loadListaTipoVeiculo();
    this.initAlterarVeiculoObservable();
    this.initSelecionarVeiculoManualObsrvable();
    this.getTipoCombustiveis();
  }

  ngDoCheck() {
    if (!this.fichaCadastro.isVeiculoAproveitado) {
      this.fichaCadastro.placaVeiculo = this.fichaCadastro
        .getGarantia()
        .getPlaca();
      this.fichaCadastro.isVeiculoAproveitado = true;
    }
  }

  initAlterarVeiculoObservable() {
    this.sharedService.alterarVeiculoObservable.pipe(
      takeWhile(() => this.isAlive))
      .subscribe((veiculo: VeiculoSimulacao) => {
        this.isShowButtonBack = false;
        this.fichaCadastro.placaVeiculo = veiculo.getPlaca();
        this.fichaCadastro.hasZeroKm = veiculo.isZero();
        this.fichaCadastro.hasUtilizacaoAgropecuaria = veiculo.isUtilizacaoAgropecuaria();
        this.ufLicenciamento = veiculo.getUf();
        this.isSelectedUfLicenciamento = true;
        this.isShowBtnCancelarAlteracao = true;

        if (this.fichaCadastro.hasZeroKm) {
          this.isAlteracao = true;
          this.fichaCadastro.categoriaVeiculo = veiculo.getCategoria().getId();
          this.fichaCadastro.marcaVeiculo = veiculo.getMarca().getId();
          this.fichaCadastro.anoVeiculo = veiculo.getAnoModelo();
          this.fichaCadastro.modeloVeiculo = veiculo.getModelo().getId();
          this.fichaCadastro.versaoVeiculo = veiculo.getVersao().getId();
          this.cotacao = veiculo.getCotacao();

          this.isSelectedTipoVeiculo = true;
          this.isSelectedMarca = true;
          this.isSelectedAnoModelo = true;
          this.isSelectedModelo = true;
          this.isSelectedVersao = true;
          this.isSelectedCotacao = true;

          this.isLoadingTipoVeiculo = true;
          this.isLoadingMarca = true;
          this.isLoadingAnoModelo = true;
          this.isLoadingModelo = true;
          this.isLoadingVersao = true;
          this.isLoadingCotacao = true;

          this.loadListaTipoVeiculo();
          this.loadListaMarca();
          this.loadListaAnoModelo();
          this.loadListaModelo();
          this.loadListaVersao();
          this.loadCotacao();
        }
      });
  }

  initSelecionarVeiculoManualObsrvable() {
    this.sharedService.selecionarVeiculoManualObservable.subscribe(() => {
      if (this.fichaCadastro.errorStatus === this.PLACA_INVALIDA) {
        this.isPlacaInvalida = true;
      } else {
        this.isPlacaInvalida = false;
        this.fichaCadastro.placaVeiculo = '';
      }
      this.fichaCadastro.hasZeroKm = false;
      this.isErroFipeNaoEncontrado = true;
      this.fichaCadastro.hasUtilizacaoAgropecuaria = false;
    });
  }

  informarNovaPlaca(idProposta: string) {
    this.buscarProposta(idProposta);
  }

  informarGarantia(idProposta: string) {
    this.isErroFipeNaoEncontrado = true;
    this.buscarProposta(idProposta);
  }

  private buscarProposta(idProposta: string) {
    this.isObtendoFicha = true;
    this.isShowButtonBack = false;
    this.fichaService
      .getDadosProposta(idProposta)
      .pipe(
        take(1)
      )
      .subscribe(
        result => this.handleProposta(result),
        error => {
          this.handleErrorSimulacao(error.error, error.error.id);
        },
        () => (this.isObtendoFicha = false),
      );
  }

  private handleProposta(result) {
    const proposta = new PropostaSimulacao(result);
    this.fichaCadastro.replacePayload(proposta.toPayloadWithoutVeiculos());
    this.fichaCadastro.setIdSimulacao(proposta.getIdSimulacao());
    this.placaVeiculo = proposta.placaVeiculo;
  }

  private handleErrorObterProposta(error) {
    const subscription: Subscription = this.modalMessage.modalClose.subscribe(
      () => {
        this.router.navigate(['/ficha']);
        subscription.unsubscribe();
      },
    );
    this.modalMessage.show(
      'Não foi possível recuperar ficha proposta, tente novamente.',
    );
  }

  resetAlteracao() {
    if (
      !this.isLoadingTipoVeiculo &&
      !this.isLoadingMarca &&
      !this.isLoadingAnoModelo &&
      !this.isLoadingModelo &&
      !this.isLoadingVersao &&
      !this.isLoadingCotacao
    ) {
      this.isAlteracao = false;
    }
  }

  ngOnDestroy() {
    if (this.subsTipoVeiculo) {
      this.subsTipoVeiculo.unsubscribe();
    }
    if (this.subsMarca) {
      this.subsMarca.unsubscribe();
    }
    if (this.subsModelo) {
      this.subsModelo.unsubscribe();
    }
    if (this.subsVersao) {
      this.subsVersao.unsubscribe();
    }
    if (this.subsCotacao) {
      this.subsCotacao.unsubscribe();
    }
    this.isAlive = false;
  }

  clearList(lista: any) {
    if (!this.isAlteracao) {
      for (const value of lista) {
        if (value === 'TipoVeiculo') {
          this.listaTipoVeiculo = [];
          this.isSelectedTipoVeiculo = false;
          this.fichaCadastro.veiculoCategoria = null;
        }
        if (value === 'Marca') {
          this.listaMarca = [];
          this.isSelectedMarca = false;
          this.fichaCadastro.veiculoMarca = null;
        }
        if (value === 'AnoModelo') {
          this.listaAnoModelo = [];
          this.isSelectedAnoModelo = false;
          this.fichaCadastro.veiculoAno = null;
        }
        if (value === 'Modelo') {
          this.listaModelo = [];
          this.isSelectedModelo = false;
          this.fichaCadastro.veiculoModelo = null;
        }
        if (value === 'Versao') {
          this.listaVersao = [];
          this.isSelectedVersao = false;
          this.fichaCadastro.veiculoVersao = null;
        }
        if (value === 'Cotacao') {
          this.cotacao = null;
          this.isSelectedCotacao = false;
          this.cotacao = null;
        }
      }
      this.isSelectedTipoVeiculoAfterBlur = true;
      this.isSelectedMarcaAfterBlur = true;
      this.isSelectedAnoModeloAfterBlur = true;
      this.isSelectedModeloAfterBlur = true;
      this.isSelectedVersaoAfterBlur = true;
    }
  }

  loadListaTipoVeiculo() {
    this.clearList(['TipoVeiculo', 'Marca', 'AnoModelo', 'Modelo', 'Versao']);
    this.isLoadingTipoVeiculo = true;
    this.subsTipoVeiculo = this.fichaService.getTipoVeiculo().pipe(take(1)).subscribe(res => {
      for (const value of res.tipos) {
        if (
          value.id === 1 ||
          value.id === 2 ||
          value.id === 5
        ) {
          this.listaTipoVeiculo.push(value);
        }
      }
      this.isLoadingTipoVeiculo = false;
      this.resetAlteracao();
    });
  }

  loadListaMarca() {
    this.clearList(['Marca', 'AnoModelo', 'Modelo', 'Versao', 'Cotacao']);
    this.isLoadingMarca = true;
    this.subsMarca = this.fichaService
      .getMarcaPorTipo(this.fichaCadastro.veiculoCategoria)
      .pipe(take(1))
      .subscribe(res => {
        this.listaMarca = res.marcas;
        this.isLoadingMarca = false;
        this.resetAlteracao();
      });
  }

  loadListaAnoModelo() {
    this.clearList(['AnoModelo', 'Modelo', 'Versao', 'Cotacao']);

    if (this.fichaCadastro.hasZeroKm) {
      this.handleListaAnoModelo(this.anoModeloVeiculoZero());
    } else {
      this.isLoadingAnoModelo = true;
      this.fichaService
        .getAnoModeloPorMarca(
          this.fichaCadastro.veiculoCategoria,
          this.fichaCadastro.veiculoMarca,
        )
        .pipe(
          take(1),
        )
        .subscribe(
          res => this.handleListaAnoModelo(res.anosModelo),
          error => {
            this.modalMessage.show(
                'Não há ano de veículo para as características selecionadas.'
            );
          },
          () => this.isLoadingAnoModelo = false);
    }
  }

  private handleListaAnoModelo(anos) {
    this.listaAnoModelo = anos;
    this.resetAlteracao();
  }

  loadListaModelo() {
    this.clearList(['Modelo', 'Versao', 'Cotacao']);
    this.isLoadingModelo = true;
    this.subsModelo = this.fichaService
      .getModeloPorMarcaAno(
        this.fichaCadastro.veiculoCategoria,
        this.fichaCadastro.veiculoMarca,
        this.fichaCadastro.veiculoAno,
      )
      .pipe(take(1))
      .subscribe(
        res => {
          this.listaModelo = res.modelos;
          this.resetAlteracao();
        },
        () =>
          this.modalMessage.show(
            ' Não há modelo de veículo para as características selecionadas.',
          ),
        () => (this.isLoadingModelo = false)
      );
  }

  loadListaVersao() {
    this.clearList(['Versao', 'Cotacao']);
    this.isLoadingVersao = true;
    this.subsVersao = this.fichaService
      .getVersaoPorMarcaModeloAno(
        this.fichaCadastro.veiculoCategoria,
        this.fichaCadastro.veiculoMarca,
        this.fichaCadastro.veiculoAno,
        this.fichaCadastro.veiculoModelo,
      )
      .pipe(take(1))
      .subscribe(
        res => {
          this.listaVersao = res.versoes;
          this.resetAlteracao();
        },
        (error) => {
          // This is intentional
        },
        () => this.isLoadingVersao = false
      );
  }

  loadCotacao() {
    this.clearList(['Cotacao']);
    this.isLoadingCotacao = true;
    const ano = this.fichaCadastro.hasZeroKm
      ? '0'
      : this.fichaCadastro.veiculoAno;
    this.subsCotacao = this.fichaService
      .getCotacaoPorVersao(
        this.fichaCadastro.veiculoCategoria,
        this.fichaCadastro.veiculoMarca,
        ano,
        this.fichaCadastro.veiculoModelo,
        this.fichaCadastro.veiculoVersao,
      )
      .pipe(take(1))
      .subscribe(
        res => {
          this.cotacao = res.cotacao[0];
          this.resetAlteracao();
        },
        error => {
          // This is intentional
        },
        () => this.isLoadingCotacao = false
      );
  }

  onChangeTipoVeiculo(event) {
    this.isSelectedTipoVeiculo = true;
    this.loadListaMarca();
  }

  onChangeMarca(event) {
    this.isSelectedMarca = true;
    this.loadListaAnoModelo();
  }

  onChangeAnoModelo(event) {
    this.isSelectedAnoModelo = true;
    this.loadListaModelo();
  }

  onChangeModelo(event) {
    this.isSelectedModelo = true;
    this.loadListaVersao();
  }

  onChangeVersao(event) {
    this.codigoFornecedorCotacao = this.listaVersao[event.target.selectedIndex - 1].codigoMolicar;
    this.isSelectedVersao = true;
    this.loadCotacao();
  }

  onChangeUfLicenciamento(event) {
    this.ufLicenciamento = event.target.value;
    this.isSelectedUfLicenciamento = true;
  }

  onBlurTipoVeiculo(event) {
    this.isSelectedTipoVeiculoAfterBlur = event.target.selectedIndex > 0;
  }

  onBlurMarca(event) {
    this.isSelectedMarcaAfterBlur = event.target.selectedIndex > 0;
  }

  onBlurAnoModelo(event) {
    this.isSelectedAnoModeloAfterBlur = event.target.selectedIndex > 0;
  }

  onBlurModelo(event) {
    this.isSelectedModeloAfterBlur = event.target.selectedIndex > 0;
  }

  onBlurVersao(event) {
    this.isSelectedVersaoAfterBlur = event.target.selectedIndex > 0;
  }

  isNextAvailable(...forms) {
    if (
      (this.isErroFipeNaoEncontrado &&
      !this.fichaCadastro.hasUsado &&
      !this.fichaCadastro.hasZeroKm) ||
      this.isLoading()
    ) {
      return false;
    }

    if (this.fichaCadastro.hasZeroKm || this.fichaCadastro.hasUsado) {
      return forms.every(item => {
        return item === true;
      });
    }
    return forms[0] && forms[1];
  }

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
    this.isErroFipeNaoEncontrado = false;
  }

  onClickNext() {
    if (this.fichaCadastro.getIdSimulacao() !== null) {
      this.fichaCadastro.setPayload('id', this.fichaCadastro.getIdSimulacao());
    }

    this.fichaCadastro.setPayload('veiculos', [this.getPayloadVeiculos()]);
    this.fichaCadastro.setPayload('plataformaInclusao', 'WEB');
    this.isErroFipeNaoEncontrado = false;
    this.fichaCadastro.onClickNext();
    removeFichaContent();
    this.sharedService.simularProposta();
  }

  onClickCancelarEdicao() {
    this.modalMessage
      .show('Deseja cancelar a alteração do veículo?', 'Sim', 'Não')
      .ok.pipe(take(1))
      .subscribe(() => this.fichaCadastro.passoRetornarResultadoParcial());
  }

  onChangeVeiculoZeroUsado() {
    if (this.listaAnoModelo && this.listaAnoModelo.length > 0) {
      this.loadListaAnoModelo();
    }
  }

  private anoModeloVeiculoZero(): any[] {
    const anoAtual: number = new Date().getFullYear() - 2;
    const anos = [];
    for (let index = 0; index < 4; index++) {
      anos[index] = { anoModelo: anoAtual + index };
    }
    return anos;
  }

  private isLoading() {
    return this.isLoadingTipoVeiculo
      || this.isLoadingMarca
      || this.isLoadingAnoModelo
      || this.isLoadingModelo
      || this.isLoadingVersao
      || this.isLoadingCotacao;
  }

  private getPayloadVeiculos(): any {
    if (this.fichaCadastro.hasZeroKm) {
      return this.getPayloadVeiculoZero();
    }

    if (this.fichaCadastro.hasUsado) {
      return this.getPayloadVeiculoUsadoPreenchimentoManual();
    }

    return this.getPayloadVeiculoUsaso();
  }

  private getPayloadVeiculoZero(): any {
    return {
      uf: this.ufLicenciamento,
      utilizacaoAgropecuaria: this.fichaCadastro.hasUtilizacaoAgropecuaria,
      fipe: this.codigoFornecedorCotacao,
      anoModelo: this.fichaCadastro.veiculoAno,
      condicao: 'ZERO',
    };
  }

  private getPayloadVeiculoUsadoPreenchimentoManual(): any {
    const obj = {
      uf: this.ufLicenciamento,
      utilizacaoAgropecuaria: false,
      fipe: this.codigoFornecedorCotacao,
      anoModelo: this.fichaCadastro.veiculoAno,
      condicao: 'USADO',
    };
    if (this.fichaCadastro.errorStatus === this.PLACA_INVALIDA) {
      obj['placa'] = this.fichaCadastro.placaVeiculo;
    }
    return obj;
  }

  private getPayloadVeiculoUsaso(): any {
    return {
      placa: this.fichaCadastro.placaVeiculo
        ? this.fichaCadastro.placaVeiculo.replace(/-/g, '')
        : '',
      uf: this.ufLicenciamento,
      utilizacaoAgropecuaria: false,
      condicao: 'USADO',
    };
  }

  private handleErrorSimulacao(error, idProposta) {
    error.id = idProposta;
    if (error.status === this.FIPE_NOT_FOUND) {
      this.handleProposta(error);
      this.handleErroFipeNaoEncontrada(error);
    } else if (error.status === this.PLACA_INVALIDA) {
      this.handleProposta(error);
      this.perguntaCorrigirDadosPlaca(error);
    } else if (error.status === this.TAXA_CLIENTE_NOT_FOUND) {
      this.handleErroTaxaClienteNaoEncontrada(error);
    } else if (error.status === this.ERRO_CRIVO) {
      this.handleErroCrivo(error);
    } else if (error.status === this.REFUSED) {
      this.handleErrorRefused(error);
    } else if (error.status === this.GARANTIA_RECUSADA) {
      this.handleProposta(error);
      this.perguntaCorrigirGarantia(error);
    }  else {
      this.handleErroInesperado(error);
    }
  }

  private handleErroInesperado(error) {
    if (error && error.message) {
      this.showErrorMessage(
        error.message,
        () => this.home());
    } else {
      this.showErrorMessage(
        'Não foi possível realizar a simulação. Verifique sua conexão com a internet e tente novamente.',
        () => this.home());
    }
  }

  private perguntaCorrigirDadosPlaca(error: any) {
    this.hideClose = false;
    this.modalMessage.show('Não foi possível completar a simulação.\n\n A placa informada é inválida. Deseja preencher as informações do veículo manualmente ?', 'Sim', 'Não');
    this.modalMessage.ok.take(1)
    .subscribe(() => this.handleErroFipeNaoEncontradaWithoutMessage(error));
    this.modalMessage.close.take(1)
    .subscribe(() =>  this.handleErroPlacaInvalida(error));
    this.modalMessage.modalClose.subscribe();
  }

  private perguntaCorrigirGarantia(error: any) {
    this.hideClose = false;
    this.modalMessage.show('Não foi possível completar a simulação.\nA garantia informada foi recusada. Deseja informar outra placa ?', 'Sim', 'Não');
    this.modalMessage.ok.take(1)
    .subscribe(() => this.handleErroPlacaInvalida(error));
    this.modalMessage.close.take(1)
    .subscribe(() =>  this.home());
    this.modalMessage.modalClose.subscribe();
  }

  private handleErroFipeNaoEncontradaWithoutMessage(error: any) {
    this.sharedService.selecionarVeiculoManualmente();
    this.fichaCadastro.passoCorrigirFipeNotFound();
    this.fichaCadastro.hasUsado = true;
    this.fichaCadastro.setIdSimulacao(error.id);
    this.isObtendoFicha = false;
  }

  private handleErroPlacaInvalida(error: any) {
    this.fichaCadastro.passoCorrigirPLacaInvalida();
    this.fichaCadastro.setIdSimulacao(error.id);
    this.handleProposta(error);
    this.isObtendoFicha = false;
  }

  private handleErroFipeNaoEncontrada(error: any) {
    this.showErrorMessage(error.message, () => {
      this.sharedService.selecionarVeiculoManualmente();
      this.fichaCadastro.passoCorrigirFipeNotFound();
      this.fichaCadastro.setIdSimulacao(error.id);
      this.handleProposta(error);
      this.isObtendoFicha = false;
    });
  }

  private handleErroTaxaClienteNaoEncontrada(error: any) {
    this.showErrorMessage(error.message, () => this.home());
  }

  private handleErroCrivo(error: any) {
    this.showErrorMessage(error.message, () => this.home());
  }

  private handleErrorRefused(message) {
    this.showErrorMessage(message, () => this.home());
  }

  private home() {
    this.router.navigate(['/ficha']);
  }

  private showErrorMessage(message: string, next: () => void) {
    this.hideClose = true;
    this.modalMessage
      .show(message)
      .modalClose.take(1)
      .subscribe(next);
  }

  getTipoCombustiveis() {
    this.tiposCombustiveisService
      .getTipoCombustiveis()
      .pipe(take(1))
      .subscribe((tipocombustivel: TiposCombustiveis[]) => {
        this.listaTiposCombustiveis = tipocombustivel;
      });
  }
}
