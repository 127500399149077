import { Component, Input } from '@angular/core';
import { FichaCadastro } from './../ficha-cadastro';
import { setFichaContent } from '../helpers/salvarFicha';
@Component({
  selector: 'app-ficha-cadastro-endereco-avalista',
  templateUrl: './ficha-cadastro-endereco-avalista.component.html',
  styleUrls: ['./ficha-cadastro-endereco-avalista.component.css']
})
export class FichaCadastroEnderecoAvalistaComponent {
  @Input() fichaCadastro: FichaCadastro;

  onClickPrevious() {
    this.fichaCadastro.onClickPrevious();
  }

  nextAvaliable(...data) {
    if (this.fichaCadastro._avalista.tipoEnderecoCorrespondencia === '7') {
      return data.every(item => {
        return item === true;
      });
    }
    return data[0];
  }

  onClickNext() {
    this.fichaCadastro._avalista.enderecos.idTipoEndereco = 1;
    this.fichaCadastro._avalista.enderecoCorrespondencia.idTipoEndereco = this.fichaCadastro._avalista.tipoEnderecoCorrespondencia === '7' ? 7 : undefined;
    this.fichaCadastro.onClickNext();
    setFichaContent(this.fichaCadastro);
  }
}
