import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMoedaValidator],[appMoedaValidator][ngModel]',
  providers: [{
    multi: true,
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MoedaValidatorDirective)
  }]
})
export class MoedaValidatorDirective {

  @Input() valorRendaMinima: number;
  @Input() valorMaximo: number;
  @Input() valorMinimo: number = 1000;
  @Input() valorMinimoMessage = 'O valor mínimo deve ser R$';

  validate(c: AbstractControl): { [key: string]: any } {
    return (this.checkValidate(c.value) ? null : {
      message: `${this.valorMinimoMessage}`
    });
  }

  checkValidate(valor) {
    let valorValido;

    if (this.valorMinimo) {
      valorValido = valor >= Number(this.valorMinimo);
      this.valorMinimoMessage = `O valor mínimo deve ser R$ ${this.valorMinimo}`;
    }

    if (this.valorMinimo && this.valorMaximo) {
      valorValido = (valor >= Number(this.valorMinimo)) && (valor <= Number(this.valorMaximo));
      this.valorMinimoMessage = `O valor solicitado deve ser entre R$ ${this.valorMinimo} e R$ ${this.valorMaximo}.`;
    }

    if (this.valorRendaMinima) {
      valorValido = valor >= Number(this.valorRendaMinima);
      this.valorMinimoMessage = `O valor mínimo da renda deve ser R$ ${this.valorRendaMinima}.`;
    }

    return valorValido;
  }
}
