import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-foto-dica',
  templateUrl: './modal-foto-dica.component.html',
  styleUrls: ['./modal-foto-dica.component.css']
})
export class ModalFotoDicaComponent implements OnInit, OnDestroy {

  titulo: string = 'Dicas para uma boa foto';
  mensagem: string = 'Mensagem';
  labelFechar: string = 'Ok, entendi';
  showFecharBtn: boolean = true;
  showOkBtn: boolean = true;
  isTituloVermelho: boolean = false;

  @Output() confirmValue = new EventEmitter<boolean>();

  alive: boolean = true;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log('Criou modal dica de foto');
  }

  ngOnDestroy() {
    this.alive = false;
    console.log('Destruiu modal dica de foto');
  }

  showModal(
    options?: {
      titulo?: string,
      mensagem?: string,
      labelFechar?: string,
      labelOk?: string,
      showFecharBtn?: boolean,
      showOkBtn?: boolean,
      isTituloVermelho?: boolean
    }): void {

    if (options) {
      this.titulo = options.titulo || this.titulo;
      this.mensagem = options.mensagem || this.mensagem;
      this.labelFechar = options.labelFechar || this.labelFechar;
      this.showFecharBtn = options.showFecharBtn || this.showFecharBtn;
      this.showOkBtn = options.showOkBtn || this.showOkBtn;
      this.isTituloVermelho = options.isTituloVermelho || this.isTituloVermelho;
    }

  }

  onClickClose() {
    this.confirmValue.next(false);
    this.bsModalRef.hide();
  }

}
