import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ClienteEndereco } from '../../../../shared/models/cliente';
import { Proposta } from '../../../../shared/models/proposta';
import { PropostaStateService } from '../../../../shared/states/proposta-state.service';

import * as moment from 'moment';

@Component({
  selector: 'app-enderecos-cliente',
  templateUrl: './enderecos-cliente.component.html',
  styleUrls: ['./enderecos-cliente.component.css']
})
export class EnderecosClienteComponent implements OnInit {
  @Input() avalistaNumero = -1;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  proposta: Proposta;
  correspondenciaForm: FormGroup;
  enderecosForm: FormGroup;

  hasEnderecoComercial: boolean;

  private CLASSE_PROFISSIONAL_VIVE_DE_RENDA:number = 3;
  private CLASSE_PROFISSIONAL_APOSENTADO_PENSONISTA:number = 7;

  constructor(
    private fb: FormBuilder,
    private state: PropostaStateService
    ) {}

  ngOnInit() {
    this.initForms();

    this.proposta = this.state.getLast();
    this.hasEnderecoComercial = !this.isAposentadoOrViveDeRenda() && !this.isConjugeAposentado();

    setTimeout(() => this.populateForms(), 100);
  }

  private initForms(): void {
    this.correspondenciaForm = this.fb.group({
      enderecoCorrespondencia: ['', Validators.required]
    });

    this.enderecosForm = this.fb.group({});
    this.enderecosForm.addControl('enderecoCorrespondencia', this.correspondenciaForm);
  }

  private populateForms() {
    if (this.clienteEntity.enderecos) {
      const enderecos = this.clienteEntity.enderecos;
      const residencialForm = enderecos.filter(e => e.tipoEndereco === 'RESIDENCIAL')[0] || {};
      const comercialForm = enderecos.filter(e => e.tipoEndereco === 'COMERCIAL')[0] || {};
      const enderecoCorrespondencia = enderecos.filter(e => e.correspondencia).map(e => e.tipoEndereco)[0];

      const residencialAtualizado = {
        ...residencialForm,
        tipoMoradia: this.clienteEntity.tipoMoradia,
        tempoResidencia: this.clienteEntity.tempoResidencia ? moment.unix(this.clienteEntity.tempoResidencia / 1000).format(this.tempoMoradiaFormato) : null
      };

      this.enderecosForm.patchValue(
        {
          comercialForm,
          residencialForm: residencialAtualizado,
        },
        { emitEvent: false }
      );
      console.log('enderecos iniciados:', this.enderecosForm.value, enderecoCorrespondencia);

      if (enderecoCorrespondencia) {
        this.correspondenciaForm.patchValue({ enderecoCorrespondencia });
      }
    }
  }

  onClickNext(): void {
    this.setEnderecos();
    this.state.setState(this.proposta);
    this.next.emit();
  }

  onClickPrevious() {
    this.setEnderecos();
    this.state.setState(this.proposta);
    this.previous.emit();
  }

  private setEnderecos(): void {
    const formResidencial = (this.enderecoResidencialForm as FormGroup);
    this.clienteEntity.tipoMoradia = formResidencial.get('tipoMoradia').value;
    this.clienteEntity.tempoResidencia = moment(formResidencial.get('tempoResidencia').value, this.tempoMoradiaFormato).unix() * 1000;

    const enderecoResidencial: ClienteEndereco = formResidencial.getRawValue();
    const enderecoComercial: ClienteEndereco = this.hasEnderecoComercial
      ? (this.enderecoComercialForm as FormGroup).getRawValue()
      : this.copyEnderecoResidencialToComercial(enderecoResidencial);

    this.clienteEntity.enderecos = [];

    enderecoComercial.cep = (enderecoComercial.cep || '').replace('-', '');
    enderecoComercial.correspondencia = this.isEnderecoComercialCorrespondencia();
    this.clienteEntity.enderecos.push(enderecoComercial);

    enderecoResidencial.cep = (enderecoResidencial.cep || '').replace('-', '');
    enderecoResidencial.correspondencia = this.isEnderecoResidencialCorrespondencia();
    this.clienteEntity.enderecos.push(enderecoResidencial);
  }

  public setResidencialInComercial(event: boolean) {
    if (!event) {
      this.hasEnderecoComercial = true;
    }
    if (this.hasEnderecoComercial && event) {
      const formResidencial = (this.enderecoResidencialForm as FormGroup);
      const enderecoResidencial: ClienteEndereco = formResidencial.getRawValue();
      const enderecoComercial: ClienteEndereco = this.copyEnderecoResidencialToComercial(enderecoResidencial);
      this.enderecosForm.patchValue(
        {
          comercialForm: enderecoComercial,
        },
        { emitEvent: false }
      );
      this.hasEnderecoComercial = !event;
    }
  }
  private copyEnderecoResidencialToComercial(enderecoResidencial: any): any {
    const enderecoComercial = Object.assign({}, enderecoResidencial);
    enderecoComercial.tipoEndereco = 'COMERCIAL';

    return enderecoComercial;
  }

  private isEnderecoResidencialCorrespondencia(): boolean {
    return this.enderecoCorrespondencia.value === 'RESIDENCIAL';
  }

  private isEnderecoComercialCorrespondencia(): boolean {
    return this.enderecoCorrespondencia.value === 'COMERCIAL';
  }

  private isAposentadoOrViveDeRenda(): boolean {
    return [this.CLASSE_PROFISSIONAL_VIVE_DE_RENDA,
      this.CLASSE_PROFISSIONAL_APOSENTADO_PENSONISTA]
      .includes(this.classeProfissional);
  }

  private isConjugeAposentado() {
    return this.proposta.conjuge &&
      this.proposta.conjuge.dadosProfissionais &&
      this.proposta.conjuge.dadosProfissionais.classeProfissional &&
      [this.CLASSE_PROFISSIONAL_VIVE_DE_RENDA,
        this.CLASSE_PROFISSIONAL_APOSENTADO_PENSONISTA]
        .includes(this.proposta.conjuge.dadosProfissionais.classeProfissional.id);
  }

  get enderecoCorrespondencia(): AbstractControl {
    return this.correspondenciaForm.get('enderecoCorrespondencia');
  }

  get enderecoResidencialForm(): AbstractControl {
    return this.enderecosForm.get('residencialForm');
  }

  get enderecoComercialForm(): AbstractControl {
    return this.enderecosForm.get('comercialForm');
  }

  get classeProfissional(): number {
    return this.clienteEntity.dadosProfissionais.classeProfissional.id;
  }

  get tempoMoradiaFormato() {
    return 'MMYYYY';
  }

  get clienteEntity() {
    if (this.avalistaNumero > 0) {
      return this.proposta.avalistas[`${this.avalistaNumero}`];
    }

    return this.proposta.cliente;
  }

}
