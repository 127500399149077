import { StatusModel } from './../../../models/statusModel';
import { FichaStatus } from './ficha-status-interface';
export class StatusRecusada implements FichaStatus{
  status:StatusModel[] = [];

  constructor() {
    this.status.push(new StatusModel('Recusada', '12'));
    this.status.push(new StatusModel('Recusada Formalização', '13'));
    this.status.push(new StatusModel('Todos', '-1'));

  }

  getStatus() {
    return this.status;
  }
}
