export class TelefonePartner {

  public static clearMask(value: string) {
    return (value || '').replace(/[^0-9]/g, '');
  }

  public static getDdd(value: string) {
    return this.clearMask(value).substr(0, 2);
  }

  public static getNumero(value: string) {
    return this.clearMask(value).substr(2, 10);
  }

  public static getDddNumero(ddd: string, numero: string) {
    if (ddd && numero) { return `${ddd}${numero}`; }
    if (ddd) { return ddd; }
    return undefined;
  }
}
