import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Periodo } from './periodo/periodo';

@Component({
  selector: 'app-select-periodo',
  templateUrl: './select-periodo.component.html',
  styleUrls: ['./select-periodo.component.css']
})
export class SelectPeriodoComponent implements OnInit {

  @Input() opcoes: Periodo[];
  @Output() selectedChange: EventEmitter<Periodo> = new EventEmitter();

  @Input() disabled = false;

  selected: Periodo;

  ngOnInit() {
    const padrao = this.opcoes.filter(p => p.padrao);
    if (padrao && padrao.length > 0) {
      this.selected = padrao[0];
    }

    if (this.selected) {
      this.emit();
    }
  }

  select(periodo: Periodo) {
    if (!this.selected || this.selected.descricao !== periodo.descricao) {
      this.selected = periodo;
      this.emit();
    }
  }

  isSelected(periodo: Periodo) {
    if (this.selected) {
      return this.selected.descricao === periodo.descricao;
    }
    return false;
  }

  private emit() {
    this.selectedChange.emit(this.selected);
  }

}
