import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import * as toastr from 'toastr';

import { appSettings, appSettings as globals } from '../../../../../environments/app.setings';
import { NewModalMessageComponent } from '../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TimerToken } from '../../../../shared/timer-token/timer-token';
import { UploadArquivosComponent } from '../../../../shared/upload-arquivos/upload-arquivos.component';
import { BiometriaStatus } from '../../../shared/models/biometria-status';
import { Proposta, PropostaMercadoria } from '../../../shared/models/proposta';
import { AnaliseAutomaticaStatus, DisparoAnaliseAutomatica } from '../../models/analise-automatica-status';
import { CdcLojaService } from '../../services/cdc-loja.service';
import { SmsNotReceivedComponent } from '../dados-cliente/sms-not-received/sms-not-received.component';
import { EcommerceEtapas } from '../ecommerce-etapas';

@Component({
  selector: 'app-demais-dados',
  templateUrl: './demais-dados.component.html',
  styleUrls: ['./demais-dados.component.scss']
})
export class DemaisDadosComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('modalAvisos', { static: true }) modalAvisos: NewModalMessageComponent;
  @ViewChild('selfieInput', { static: true }) selfieInput: ElementRef;
  @ViewChild(UploadArquivosComponent, { static: true }) uploadArquivos: UploadArquivosComponent;

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  showLoader: boolean = false;
  demaisDadosForm: FormGroup;

  tituloPainel: string;
  produtos: number[] = [];
  quantidadeProdutos: number = 0;

  file: File;
  nomesArquivosEnviados: string[] = [];

  isSizeLimit = false;
  isAlive: boolean = true;

  hasBiometria: boolean = false; // TODO: obter serviço para saber se tem biometria

  imagemAtualSubject: BehaviorSubject<File> = new BehaviorSubject(null);
  fotosEnviadas: number = 0;
  documentosAdicionais: FileList[] = [];

  acceptImages = globals.ACCEPT_IMAGES;
  valid: boolean = false;

  tirouFoto: boolean = false;
  assessment: any;
  photo: any;
  numeroTentativasBiometria: number = 3;
  hasErrorBiometriaAssessment: boolean = false;
  hasBiometriaCreditRequest: boolean = false;

  proposta: Proposta;
  tiposMercadoria = [];
  resendCodeEnable = true;
  countdownTime = 0;
  clicouBotaoReenviar: boolean = false;

  showLoaderPhases = false;
  showTryAgainButton = false;
  timeout: any;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private cdclojaService: CdcLojaService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.proposta = EcommerceEtapas.proposta;
    this.onSolicitarBiometriaStatus();
    this.verifyConjuge(this.proposta);

    this.initForm();
    this.initObservers();
    this.tituloPainel = 'Descrição dos Produtos';
  }

  ngOnDestroy() {
    this.isAlive = false;
    clearTimeout(this.timeout);
  }

  private initForm() {
    this.demaisDadosForm = this.fb.group({
      documentoOpcional: [''],
      observacoes: [this.proposta.observacoes || '']
    });

    if (this.proposta.transients) {
      this.imagemAtualSubject.next(this.proposta.transients.selfie);
    }

    this.demaisDadosForm.addControl('valorTotalProdutos', this.fb.control(this.proposta.valorLiquido));
    this.demaisDadosForm.addControl('valorSolicitado', this.fb.control(this.proposta.valorLiquido));

    if (this.proposta.mercadorias) {
      this.proposta.mercadorias
        .forEach(this.fillMercadoriaForm.bind(this));
    }
  }

  private initObservers(): void {
    this.demaisDadosForm
      .valueChanges.pipe(
        takeWhile(() => this.isAlive))
      .subscribe(() => this.handledemaisDadosFormChanges());
  }

  ativaBiometria() {
    this.hasBiometria = !this.hasBiometria;
    this.handledemaisDadosFormChanges();
  }

  verificaBiometriaTelefoneConfirmado(realizada) {
    if (realizada) this.hasBiometria = true;
  }

  private handledemaisDadosFormChanges(): void {

    this.valid = this.demaisDadosForm.valid;

    if (this.proposta.transients) {
      this.proposta.transients.selfie = this.imagemAtualSubject.value;
    }

    const identificacao = this.demaisDadosForm.value;
    this.calculateValorTotal();
    this.valid = this.valid && this.valorMercadoriasIgualSolicitado && this.hasBiometria;
    this.proposta.observacoes = identificacao.observacoes;
  }

  private calculateValorTotal(): void {
    const valores: number[] = [];
    this.proposta.mercadorias = [];

    Object.keys(this.demaisDadosForm.getRawValue()).forEach(
      key => {
        if (key.startsWith('mercadoriaForm')) {
          const mercadoria = this.demaisDadosForm.get(key).value;
          this.proposta.mercadorias.push({
            tipoMercadoria: mercadoria.tiposMercadoria,
            mercadoria: mercadoria.mercadoria,
            valor: mercadoria.valorProduto
          });
          valores.push(mercadoria.valorProduto || 0.00);
        }
      }
    );

    let valorTotal: number = 0;

    if (valores.length > 0) {
      valorTotal = valores.reduce(
        (prev, curr) => Number(prev) + Number(curr)
      );
    }

    this.demaisDadosForm.get('valorTotalProdutos').patchValue(
      valorTotal, { emitEvent: false }
    );
  }

  async onClickNext() {
    try {
      this.handledemaisDadosFormChanges();
      EcommerceEtapas.proposta = this.proposta;
      await this.finalizarProposta();
    } catch (error) {
      console.error(error);
    }
  }

  private async finalizarProposta() {
    try {
      this.showLoaderPhases = true;
      this.scrollTop();
      await this.cdclojaService.finalizaSimulacao(this.proposta).toPromise();
      this.analisarPropostaAssincrona(1, this.proposta);
    } catch (error) {
      this.showLoaderPhases = false;
      console.error(error);
      toastr.error('Não foi possível conectar com o servidor.', 'Falha ao enviar proposta a mesa');
    }
  }

  onClickPrevious() {
    this.handledemaisDadosFormChanges();
    EcommerceEtapas.proposta = this.proposta;
    this.previous.emit();
  }

  fillMercadoriaForm(produto: PropostaMercadoria) {
    setTimeout(
      () => {
        const form = <FormGroup>this.demaisDadosForm.controls['mercadoriaForm'];
        form.setValue({
          tiposMercadoria: produto.tipoMercadoria,
          valorProduto: produto.valor
        });
        form.controls['tiposMercadoria'].disable();
        form.controls['valorProduto'].disable();
      },
      500);
  }

  resendSMS() {
    this.clicouBotaoReenviar = true;
    this.onSolicitarBiometriaStatus();
  }

  async onSolicitarBiometriaStatus() {
    const response = await this.getStatusBiometriaAsync();
    const { realizada } = response;
    this.handleStatusBiometria(realizada);
  }

  getStatusBiometriaAsync(): Promise<BiometriaStatus> {

    return this.cdclojaService
      .getStatusBiometria(this.proposta.id)
      .toPromise();
  }

  handleStatusBiometria(realizada) {
    if (realizada) {
      this.hasBiometria = true;
    } else if (!realizada && this.clicouBotaoReenviar) {
      this.onSolicitarToken();
    }
  }

  solicitarToken(): Promise<TimerToken> {
    const telefoneCliente = this.proposta.cliente.telefones.filter(telefone => telefone.categoriaTelefone === 'CELULAR')[0];

    return this.cdclojaService.enviarSms({
      proposta: {
        cliente: {
          telefones: [{
            ddd: telefoneCliente.ddd,
            nrTelefone: telefoneCliente.nrTelefone,
            categoriaTelefone: 'CELULAR',
            tipoTelefone: 'PROPRIO',
          }]
        },
        id: this.proposta.id,
        parceiro: this.proposta.parceiro
      }
    }).toPromise();
  }

  async onSolicitarToken() {
    this.showLoader = true;
    try {
      const response = await this.solicitarToken();
      const { timeLeft } = response;
      this.initTimer(timeLeft);
    } catch (error) {
      if (error.data) {
        this.initTimer(error.data);
      }
    } finally {
      this.showLoader = false;
      this.resendCodeEnable = false;
      this.clicouBotaoReenviar = false;
    }
  }

  initTimer(timer) {
    this.countdownTime = timer;
  }

  handleCountdownEvent(event: { action: string }) {
    if (event.action === 'done') {
      this.resendCodeEnable = true;
    } else {
      this.resendCodeEnable = false;
    }
  }

  openNotReceivedModal() {
    const modal = this.modalService.show(
      SmsNotReceivedComponent,
      appSettings.MODAL_PARAMS
    );
  }

  private verifyConjuge(proposta) {
    const classeProfisional = proposta.cliente.dadosProfissionais.classeProfissional.id;

    if (this.isNotOtherClasseProfissional(classeProfisional)) {
      proposta.conjuge = null;
    }
  }

  private isNotOtherClasseProfissional(classeProfissional): boolean {
    return classeProfissional.value !== '9';
  }

  get randomString(): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substring(2);
  }

  get valorSolicitado(): number {
    return Number(this.demaisDadosForm.get('valorSolicitado').value);
  }

  get valorTotalProdutos(): number {
    return Number(this.demaisDadosForm.get('valorTotalProdutos').value);
  }

  get valorMercadoriasIgualSolicitado(): boolean {
    return this.valorSolicitado.toFixed(2) === this.valorTotalProdutos.toFixed(2);
  }

  private analisarPropostaAssincrona(tentativa: number, propostaCriada) {
    const maximoTentativas = 18;

    this.cdclojaService.analisarProposta(propostaCriada, DisparoAnaliseAutomatica.FINALIZAR_PROPOSTA)
      .subscribe(
        ({ status }) => {
          if (tentativa >= maximoTentativas) {
            this.reiniciarTentativas();
          } else if (status === AnaliseAutomaticaStatus.AGUARDANDO_ANALISE_ASSINCRONA && tentativa < maximoTentativas) {
            this.timeout = setTimeout(() => this.analisarPropostaAssincrona(tentativa + 1, propostaCriada), 5000);
          } else if (status === AnaliseAutomaticaStatus.APROVADO || status === AnaliseAutomaticaStatus.PENDENTE || status === AnaliseAutomaticaStatus.RECUSADO) {
            this.showLoaderPhases = false;
            this.next.emit();
          }
        },
        () => {
          if (tentativa < maximoTentativas) {
            this.timeout = setTimeout(() => this.analisarPropostaAssincrona(tentativa + 1, propostaCriada), 5000);
            return;
          }
          this.showLoaderPhases = false;
        });
  }

  private reiniciarTentativas() {
    this.showLoaderPhases = false;
    this.showTryAgainButton = true;
    toastr.warning('Por favor, clique em "analisar novamente" para recomeçar.', 'Ops, essa operação está demorando mais que o esperado!');
  }

  onClickTryAgain() {
    this.showTryAgainButton = false;
    this.showLoaderPhases = true;
    this.scrollTop();
    this.analisarPropostaAssincrona(1, { id: this.proposta.id });
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }
}
