import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-botao-navegacao',
  templateUrl: './botao-navegacao.component.html',
  styleUrls: ['./botao-navegacao.component.css']
})
export class BotaoNavegacaoComponent {
  @Input() previousShow = true;
  @Input() previousEnabled = true;
  @Input() nextShow = true;
  @Input() nextEnabled = true;
  @Input() nextLabel = 'Continuar';
  @Input() previousLabel = 'Voltar';
  @Input() buttonIcon = 'fa-arrow-left';
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  onClickPrevious() {
    this.previous.emit();
  }

  onClickNext() {
    this.next.emit();
  }
}
