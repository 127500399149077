import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebcamModule } from 'ngx-webcam';
import { MaskModule } from 'soft-angular-mask';

import { SharedModule } from '../../../../shared/shared.module';
import { AfterSelfieComponent } from './after-selfie/after-selfie.component';
import { BeforeSelfieComponent } from './before-selfie/before-selfie.component';
import { ModalDicasFotoVerificacaoComponent } from './before-selfie/modal-dicas-foto/modal-dicas-foto-verificacao.component';
import { BemVindoComponent } from './bem-vindo/bem-vindo.component';
import { ModalAvisoIosComponent } from './bem-vindo/modal-aviso-ios/modal-aviso-ios.component';
import { DoneComponent } from './done/done.component';
import { ExpiredLinkComponent } from './expired-link/expired-link.component';
import { ModalTermosComponent } from './termos/modal-termos/modal-termos.component';
import { TermosComponent } from './termos/termos.component';
import { VerificacaoSegurancaClearsaleComponent } from './verificacao-seguranca-clearsale.component';
import { ModalAvisoCpfInvalidoComponent } from './verificacao-seguranca-identificacao/modal-aviso-cpf-invalido/modal-aviso-cpf-invalido.component';
import { VerificacaoSegurancaIdentificacaoComponent } from './verificacao-seguranca-identificacao/verificacao-seguranca-identificacao.component';

@NgModule({
  declarations: [
    VerificacaoSegurancaClearsaleComponent,
    BemVindoComponent,
    TermosComponent,
    ModalTermosComponent,
    BeforeSelfieComponent,
    ModalDicasFotoVerificacaoComponent,
    AfterSelfieComponent,
    VerificacaoSegurancaIdentificacaoComponent,
    ModalAvisoCpfInvalidoComponent,
    ModalAvisoIosComponent,
    DoneComponent,
    ExpiredLinkComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    WebcamModule,
    MaskModule
  ],
  entryComponents: [
    ModalTermosComponent,
    ModalDicasFotoVerificacaoComponent,
    ModalAvisoCpfInvalidoComponent,
    ModalAvisoIosComponent
  ],
  exports: [VerificacaoSegurancaClearsaleComponent]
})
export class VerificacaoSegurancaClearsaleModule { }
