import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-termos',
  templateUrl: './modal-termos.component.html',
  styleUrls: ['./modal-termos.component.scss']
})
export class ModalTermosComponent {

  @Input() type: string;

  constructor(
    private instance: BsModalRef,
  ) { }

  close() {
    this.instance.hide();
  }

  onClickConfirm() {
    this.close();
  }
}
