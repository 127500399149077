import { UploadArquivosComponent } from './../../../../shared/upload-arquivos/upload-arquivos.component';
import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent {
  @Input() acoes: any;
  @Input() idFicha: number;
  @Input() cliente: {};

  @ViewChild(UploadArquivosComponent, { static: false })
  uploadArquivosComponent: UploadArquivosComponent;

  fileUpload() {
    return this.uploadArquivosComponent.sendDocuments();
  }

  validaEnvio(): boolean {
    return this.uploadArquivosComponent.validaEnvio();
  }

  public hasDocumentos(): boolean {
    return JSON.stringify(this.acoes).includes('GRAVAR_DOCS');
  }
}
