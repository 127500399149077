
import { take, finalize } from 'rxjs/operators';
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SosInverso } from './sos-inverso';
import { ModalLoaderComponent } from './../../../shared/modais/modal-loader/modal-loader.component';
import { ModalComponent } from './../../../shared/modais/modal/modal.component';
import { Motivo } from './select-motivo/motivo';
import { ModalMessageComponent } from '../../../shared/modais/modal-message/modal-message.component';
import { PropostaService } from '../../../services/proposta/proposta.service';

@Component({
  selector: 'app-ficha-timeline-sos-inverso',
  templateUrl: './ficha-timeline-sos-inverso.component.html',
  styleUrls: ['./ficha-timeline-sos-inverso.component.css']
})
export class FichaTimelineSosInversoComponent {

  @ViewChild('modalSosInverso', { static: true }) modalSosInverso: ModalComponent;
  @ViewChild('modalLoader', { static: true }) modalLoader: ModalLoaderComponent;
  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  @Input() proposta: any;
  @Output() sosEnviado: EventEmitter<boolean> = new EventEmitter();

  motivos: Motivo[];
  sosInverso: SosInverso = new SosInverso();

  constructor(private propostaService: PropostaService) { }

  abrirModalSOS() {
    if (this.proposta.possuiSosInversoEmAberto !== 'S') {
      this.sosInverso = new SosInverso();
      this.modalSosInverso.show(true);
      this.modalSosInverso.ok.pipe(take(1)).subscribe(() => this.enviarSOS());
      this.buscarMotivos();
    } else {
      this.modalMessage.show('Já possui um SOS inverso aberto para a proposta!');
    }
  }

  isCamposPreenchidos(): boolean {
    return this.sosInverso.idMotivoSos != null && this.sosInverso.observacaoSos && this.sosInverso.observacaoSos.length >= 3;
  }

  private buscarMotivos() {
    this.modalLoader.show('Caregando os motivos');
    this.propostaService
      .getMotivosSosInverso().pipe(
      take(1),
      finalize(() => this.modalLoader.hide()))
      .subscribe(
      result => this.motivos = result.motivoSosInverso,
      error => {
        this.modalMessage.show('Erro ao carregar os motivos do SOS.');
        this.modalSosInverso.hide();
      });
  }

  private enviarSOS() {
    this.modalLoader.show('Enviando o SOS');
    this.sosInverso.idProposta = this.proposta.id;
    this.propostaService
      .sendMotivoSosInverso(this.sosInverso).pipe(
      take(1),
      finalize(() => this.modalLoader.hide()))
      .subscribe(
      result => {
        this.modalMessage.show('SOS enviado com sucesso!');
        this.sosEnviado.emit(true);
      },
      error => this.handleEnviarSosHandle(error)
      );
  }

  private handleEnviarSosHandle(error) {
    const excecao = error.error.message;
    const msg = excecao.substring(excecao.indexOf(':') + 1).trim() || excecao;

    this.modalMessage.show(msg);
  }
}
