import { DocumentoEnviadoVO } from './../../shared/upload-arquivos/documentoVO';
import { OmniDocFileResponse } from './../../models/file-omni-doc-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY as observableEmpty } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginStorage } from '../../login/login.storage';
import { Router } from '@angular/router';

declare type UploadProposta = {
  id?: number;
  cliente?: { cpf?: string };
  origem: number;
  numeroPreContrato?: string;
};

declare type UploadConfigTokenProposta = {
  login: string;
  token: string;
};

@Injectable({
  providedIn: 'root',
})
export class OmniFacilUploadRestService {
  constructor(private http: HttpClient, private loginStorage: LoginStorage, private router: Router) {}

  post(
    fileOrFileList: File | FileList,
    proposta: UploadProposta,
    typeDocument,
    configTokenProposta?: UploadConfigTokenProposta
  ): Observable<any> {
    if (this.loginStorage.isTokenExpired()) {
      this.router.navigate(['/login']);
      return observableEmpty;
    }
    const configToken: UploadConfigTokenProposta = {
      login: this.loginStorage ? this.loginStorage.login : '',
      token: this.loginStorage ? this.loginStorage.token : '',
    };

    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify(
        this.getBody(proposta, typeDocument, configTokenProposta || configToken)
      )
    );

    if (fileOrFileList instanceof FileList) {
      Array.from(fileOrFileList).forEach((file) =>
        formData.append('file', file, file.name)
      );
    } else {
      formData.append('file', fileOrFileList, fileOrFileList.name);
    }

    return this.http.post(
      `${this.getBaseUrl()}file/ext/upload`,
      formData
    );
  }

  async buscarDocumentosPorProposta(propostaId: number): Promise<DocumentoEnviadoVO[]> {
    if (this.loginStorage.isTokenExpired()) {
      this.router.navigate(['/login']);
      return observableEmpty.toPromise();
    }
    const configToken: UploadConfigTokenProposta = {
      login: this.loginStorage ? this.loginStorage.login : '',
      token: this.loginStorage ? this.loginStorage.token : '',
    };

    const pesquisa = {
      indices: [
        {
          indiceType: 1,
          indiceValue: `${propostaId}`
        }
      ],
      originID: -1,
      token: configToken.token,
      userName: configToken.login,
      ordenaDataDesc: true,
      utilizaUnion: false
    };

    const retorno = await this.http.post<OmniDocFileResponse>(`${this.getBaseUrl()}file/ext/list/generic`, pesquisa).toPromise();
    return retorno.files.map(file => ({
      url: `${retorno.downloadUrl}${file.id}`,
      thumbUrl: `${retorno.downloadThumbUrl}${file.id}`,
      name: file.name,
      idDocto: file.fileType.id
    }));
  }

  getBaseUrl(): string {
    return environment.URL_OMNI_DOC_UPLOAD;
  }

  private getBody(
    proposta: UploadProposta,
    typeDocument,
    configTokenProposta?: UploadConfigTokenProposta
  ) {
    const INDICE_PROPOSTA = 1;
    const INDICE_CONTRATO = 2;
    const INDICE_CPF = 3;

    const indices: any[] = [
      {
        indiceType: INDICE_PROPOSTA,
        indiceValue: proposta.id,
      }
    ];

    if (proposta.numeroPreContrato) {
      indices.push({
        indiceType: INDICE_CONTRATO,
        indiceValue: proposta.numeroPreContrato,
      });
    }

    if (proposta.cliente) {
      indices.push({
        indiceType: INDICE_CPF,
        indiceValue: proposta.cliente.cpf,
      });
    }

    return {
      indices,
      typeDocument,
      userName: configTokenProposta.login,
      userID: configTokenProposta.token,
      departmentID: proposta.origem,
      document: proposta.id,
      typeFile: typeDocument,
    };
  }
}
