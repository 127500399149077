import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaskModule } from 'soft-angular-mask';

import { EtapasCDCLoja } from './services/etapas/etapas.service';
import { DirectivesModule } from '../../directives/directives.module';
import { TextDirectivesModule } from '../../directives/texto/text.directives.module';

import { ModaisModule } from '../../shared/modais/modais.module';
import { BreadcrumbModule } from '../../shared/breadcrumb/breadcrumb.module';
import { LoadersModule } from '../../shared/loaders/loaders.module';
import { SharedModule } from '../../shared/shared.module';
import { PipesModule } from '../../pipes/pipes.module';
import { WebcamModule } from 'ngx-webcam';
import { BtnSmallModule } from '../../shared/btn-small/btn-small.module';
import { PanelCustomModule } from '../../shared/panel-custom/panel-custom.module';
import { PropostaStateService } from './states/proposta-state.service';
import { FichaCadastroComponentesModule } from './components/ficha-cadastro-componentes.module';
import { AlterarValorComponent } from './components/cadastro-fichas-cdc/alterar-valor/alterar-valor.component';
import { ProdutosComponent } from './components/cadastro-fichas-cdc/produtos/produtos.component';

@NgModule({
  declarations: [
    AlterarValorComponent,
    ProdutosComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaskModule,
    DirectivesModule,
    TextDirectivesModule,
    FormsModule,
    ModaisModule,
    BreadcrumbModule,
    LoadersModule,
    SharedModule,
    PipesModule,
    WebcamModule,
    BtnSmallModule,
    PanelCustomModule,
    FichaCadastroComponentesModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MaskModule,
    DirectivesModule,
    TextDirectivesModule,
    FormsModule,
    ModaisModule,
    BreadcrumbModule,
    LoadersModule,
    SharedModule,
    PipesModule,
    WebcamModule,
    BtnSmallModule,
    PanelCustomModule,
    FichaCadastroComponentesModule,
  ],
  providers: [
    EtapasCDCLoja,
    PropostaStateService
  ],
  entryComponents: [
    AlterarValorComponent,
    ProdutosComponent
  ]
})
export class ProdutosSharedModule {}
