import { Component, OnInit, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { appSettings } from '../../../../environments/app.setings';
import { takeWhile, finalize } from 'rxjs/operators';
import { ModalEmissaoPreContratoComponent } from './modal-emissao-pre-contrato/modal-emissao-pre-contrato.component';
import { FichaTimelineSharedService } from '../ficha-timeline.shared.service';
import { FichaService } from '../../ficha-veiculo.service';
import { DadosEnvio } from './model/dados-envio';
import * as toastr from 'toastr';

@Component({
  selector: 'app-ficha-timeline-emissao-pre-contrato',
  templateUrl: './ficha-timeline-emissao-pre-contrato.component.html',
  styleUrls: ['./ficha-timeline-emissao-pre-contrato.component.scss']
})
export class FichaTimelineEmissaoPreContratoComponent implements OnInit {

  @Input() timeline: any;
  @Input() timelineActive: boolean;
  @Input() proposta: any;

  isLoading = false;
  dadosEnvio: DadosEnvio;

  constructor(
    private modal: BsModalService,
    private sharedService: FichaTimelineSharedService,
    private service: FichaService
  ) { }

  ngOnInit() {
    this.dadosEnvio = {
      idCliente: this.proposta.cliente.id,
      idProposta: this.proposta.id,
      tipoComunicacao: '0'
    };
  }

  onClickEmitPreContract() {
    this.proposta.permiteAssinaturaDigital === 'S' ? this.openModalPreContract() : this.generatePreContract(this.dadosEnvio);
  }

  openModalPreContract() {
    const initialState = { proposta: this.proposta };
    const modal = this.modal.show(ModalEmissaoPreContratoComponent, { ...appSettings.MODAL_PARAMS, initialState });

    const modalEmissaoPreContrato = <ModalEmissaoPreContratoComponent>modal.content;

    modalEmissaoPreContrato.closeModal
      .pipe(
        takeWhile(() => modalEmissaoPreContrato.alive)
      )
      .subscribe((dadosEnvio: DadosEnvio) => {
        if (dadosEnvio) {
          this.generatePreContract(dadosEnvio);
        }
      });
  }

  generatePreContract(dadosEnvio) {
    this.isLoading = true;
    this.service.generatePreContract(dadosEnvio)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.sharedService.reloadFicha();
          toastr.success('As informações para emissão do pré-contrato foram geradas com sucesso');
        },
        () => toastr.error('Não conseguimos enviar as informações para a emissão do pré-contrato')
      );
  }

  getPreContractDocuments(): string {
    let documentosPreContrato = '';
    this.timeline.cardDireito.some((card) => {
      if (card.acoes && card.acoes[0].acao === 'LISTA_DOCTOS') {
        documentosPreContrato = card.acoes[0].docs;
      }
    });
    return documentosPreContrato;
  }

}
