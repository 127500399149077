import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Parcela } from './parcela';

@Component({
  selector: 'parcela',
  templateUrl: './parcela.component.html',
  styleUrls: ['./parcela.component.css']
})
export class ParcelaComponent {
  @Input() formParent: FormGroup;
  @Input() parcelas: Parcela[];
  @Input() parcelaMax: number;

  separador: string = 'P';

  getParcelasClass() {
    if (this.parcelas.length > 5) {
      return 'col-ths col-xs-6ths';
    }

    return `col-ths col-xs-${this.parcelas.length}ths`;
  }
}
