export function fichasFilter(ngFilter, data) {
  let status = ngFilter.status;

  if (status === '-1') {
    status = undefined;
  }

  if (data && data.length) {
    return data.filter(item => {

      if (ngFilter.nomeCpf) {
        ngFilter.nomeCpf = ngFilter.nomeCpf.replace(/\D/g, '');
        if (!item.cliente.nome.toLowerCase().includes(ngFilter.nomeCpf.toLowerCase()) &&
          !item.cliente.cpf.toLowerCase().includes(ngFilter.nomeCpf.toLowerCase())) {
          return false;
        }
      }

      if (ngFilter.numero && item.id.toString().indexOf(ngFilter.numero.toString()) === -1) {
        return false;
      }

      if (status && item.statusTimeline.idStatus !== parseInt(status)) {
        return false;
      }

      if (ngFilter.sos && !JSON.stringify(item.statusTimeline).includes('SOS')) {
        return false;
      }

      if (['VEICULOS', 'VEÍCULOS'].includes(item.produto)) {
        if (ngFilter.data) {
          const days = (24 * 60 * 60 * 1000) * +ngFilter.data;
          if (+item.dataHoraEnvio < +(Date.now() - days)) {
            return false;
          }
        }
      } else {
        return item.statusTimeline.idStatus !== 16;
      }

      return true;
    });
  }
  return data;
}

export function calcularQuantidadeSOS(data) {
  const quantidadeSOS = data.filter(item => {
    return item.totalSosPendente !== 0;
  });
  return quantidadeSOS.length;
}
