import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonSelectorData } from './button-selector-data';

@Component({
  selector: 'app-button-selector',
  templateUrl: './button-selector.component.html',
  styleUrls: ['./button-selector.component.css']
})
export class ButtonSelectorComponent {
  @Input() data: ButtonSelectorData;
  @Input() selected: ButtonSelectorData;
  @Output() selectedChange = new EventEmitter<ButtonSelectorData>();

  onClickSelect() {
    this.selected = !this.selected ? this.data : this.getButton();
    this.selectedChange.emit(this.selected);
  }

  private getButton(): ButtonSelectorData {
    return this.data.id === this.selected.id ? new ButtonSelectorData() : this.data;
  }

}
