import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OmniRestModule } from '../omni-rest/omni-rest.module';
import { BreadcrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { SharedModule } from './../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { MeuDesempenhoModule } from './meu-desempenho/meu-desempenho.module';
import { RankingModule } from './ranking/ranking.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MeuDesempenhoModule,
    RankingModule,
    OmniRestModule,
    BreadcrumbModule,
  ],
  declarations: [
    DashboardComponent
  ],
  exports: [
    DashboardComponent
  ],
  providers: [
    DashboardService
  ]
})
export class DashboardModule {}
