import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appDDDtelefone]',
  providers: [
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DDDTelefoneValidator)
    }
  ]
})
export class DDDTelefoneValidator {

  invalidDddList = [23, 25, 26, 29, 36, 39, 52, 56, 57, 58, 59, 72, 76, 78];

  private message: string;

  validate(c: AbstractControl): { [key: string]: any } {
    return this.validatePhone(c.value) ? null : { message: this.message };
  }

  validatePhone(phone: string) {
    this.message = undefined;
    const number = phone.replace(/\D/g, '');
    const DDD = number.substring(0, 2);
    return this.validateDDD(Number(DDD)) &&  this.validateDigits(number.replace(DDD, ''));

  }

  validateDDD(DDD: number) {

    if (this.invalidDddList.includes(DDD) || DDD < 11 || DDD > 99 || DDD % 10 === 0) {
      this.message = 'DDD inválido';
      return false;
    }

    return true;
  }

  validateDigits(phone: string) {
    if (phone.split('')[0] === '0') {
      this.message = 'Número de telefone inválido';
      return false;
    }

    return true;
  }

}
