import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap';

import { CdcLojaRestService } from '../../../../omni-rest/cdc/cdc-loja-rest.service';
import { CdcLojaService } from '../../services/cdc-loja.service';
import {
  ContratoCessao,
  OpcoesPagamento,
  SaveAssignmentRequestBody,
  SignAssignmentRequestBody
} from '../contrato-cessao';

@Component({
  selector: 'app-approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: ['./approval-modal.component.scss'],
})
export class ApprovalModalComponent {
  @ViewChild('buttonRow', { static: false }) buttonRow: ElementRef;
  @Input() onlyContract = false;
  @Input() contratos: ContratoCessao[];
  @Input() title = 'Condições para cessão de crédito';
  @Input() optionIndexes?: number[];
  @Input() pdf?: any;
  @Input() display: 'single' | 'multi' | 'contrato' | 'multi-confirm';
  @Output() confirmValue = new EventEmitter<boolean>();

  alive = true;
  showLoader = false;
  opcaoSelecionada: OpcoesPagamento;

  constructor(
    private bsModalRef: BsModalRef,
    private cdclojaService: CdcLojaService,
    private cdcLojaRestService: CdcLojaRestService,
    private sanitizer: DomSanitizer
  ) { }

  onClose() {
    this.bsModalRef.hide();
  }

  selectIndex(event: [number, number]) {
    const contratoIndex = event[0];
    const opcaoIndex = event[1];
    this.opcaoSelecionada =
      this.contratos[contratoIndex].opcoesPagamento[opcaoIndex];
  }

  saveOneContract() {
    if (!this.opcaoSelecionada) {
      this.opcaoSelecionada = this.contratos[0].opcoesPagamento[0];
    }

    this.showLoader = true;
    const reqBody: SaveAssignmentRequestBody = {
      opcoesPagamentoCessao: [
        {
          contrato: this.contratos[0].contrato,
          dataPagamento: this.opcaoSelecionada.dataPagamento,
          opcao: this.opcaoSelecionada.opcao,
          retencao: this.opcaoSelecionada.retencao,
          valor: this.opcaoSelecionada.valor,
          taxaDias: this.opcaoSelecionada.taxaDias,
          coeficiente: this.opcaoSelecionada.coeficiente
        },
      ],
    };

    this.cdclojaService
      .saveAssingment(reqBody)
      .toPromise()
      .then(() => {
        this.cdcLojaRestService
          .get(this.contratos[0].url, {}, 'arraybuffer')
          .subscribe((response) => {
            const pdfFile = new Blob([response.body], {
              type: 'application/pdf',
            });
            (this.pdf = URL.createObjectURL(pdfFile));
            (this.display = 'contrato');
            this.showLoader = false;
          });
      });
  }

  signContratos() {
    this.showLoader = true;

    const reqBody: SignAssignmentRequestBody = {
      contratosCessao: this.contratos.map((c, idx) => {
        const opcaoSelecionada =
          this.opcaoSelecionada || c.opcoesPagamento[this.optionIndexes[idx]];

        return {
          cnpj: c.cnpj,
          cpfCliente: c.cpfCliente,
          contrato: c.contrato,
          emissao: c.emissao,
          opcoesPagamento: [
            {
              dataPagamento: opcaoSelecionada.dataPagamento,
              opcao: opcaoSelecionada.opcao,
              retencao: opcaoSelecionada.retencao,
              valor: opcaoSelecionada.valor,
              taxaDias: opcaoSelecionada.taxaDias,
              coeficiente: opcaoSelecionada.coeficiente
            },
          ],
          produto: c.produto,
          proposta: c.proposta,
          selecionado: true,
          url: c.url,
        };
      }),
    };

    this.cdclojaService
      .signAssignment(reqBody)
      .toPromise()
      .then(() => {
        this.showLoader = false;
        this.confirmValue.emit(true);
        this.onClose();
      });
  }

  async onAction(): Promise<void> {
    if (this.display === 'single') {
      this.saveOneContract();
    } else if (this.display === 'multi') {
      this.display = 'multi-confirm';
    } else {
      this.signContratos();
    }
  }

  get buttonLabel(): string {
    if (this.stepperStatus === 1) {
      return 'Continuar';
    }
    return this.display === 'contrato'
      ? 'Li e aprovo a cessão de crédito'
      : 'Sim, assinar e aprovar';
  }

  get midMessage(): string {
    switch (this.display) {
      case 'single':
        return 'Leia o termo de cessão de crédito abaixo e assine digitalmente.';
      case 'contrato':
        return 'Nesta etapa avalie as condições e selecione abaixo a opção de carência desejada.';
      case 'multi':
        return 'Selecione abaixo a opção de carência desejada, caso necessário realizar o ajuste de um contrato especifico basta atualizar a opção no campo carência.';
      case 'multi-confirm':
        return '';
    }
  }

  get stepperStatus(): number {
    return this.display === 'multi-confirm' || this.display === 'contrato'
      ? 2
      : 1;
  }

  sanitize(object: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(object);
  }
}
