import { Component, Input } from '@angular/core';
import { FichaService } from '../../ficha-veiculo.service';
import { finalize } from 'rxjs/operators';
import { FichaTimelineSharedService } from '../ficha-timeline.shared.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-ficha-timeline-assinatura-digital',
  templateUrl: './ficha-timeline-assinatura-digital.component.html',
  styleUrls: ['./ficha-timeline-assinatura-digital.component.scss']
})
export class FichaTimelineAssinaturaDigitalComponent {

  @Input() timeline: any;
  @Input() timelineActive: boolean;
  @Input() proposta: any;

  isLoading = false;

  constructor(
    private service: FichaService,
    private sharedService: FichaTimelineSharedService
  ) { }

  onClickPrintDocument(): void {
    window.open(this.proposta.urlDocumentosUnificados, '_blank');
  }

  onClickResend(tipoComunicacao: string): void {
    this.isLoading = true;
    this.service.resendSign({ propostaId: this.proposta.id, tpComunicacao: tipoComunicacao })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.sharedService.reloadFicha();
          toastr.success('As informações do pré-contrato foram reenviadas com sucesso');
        },
        () => toastr.error('Não conseguimos reenviar as informações do pré-contrato')
      );
  }

  get possuiUrlDocumentos(): boolean {
    return this.proposta.urlDocumentosUnificados;
  }

  getStatusMessage(): string {
    let mensagemStatus = '';
    this.timeline.cardDireito.some((card) => {
      if (card.tipo === 'TEXTO') {
        mensagemStatus = card.valor;
      }
    });
    return mensagemStatus;
  }

  hasResendButton(): string {
    let botaoReenviar = '';
    this.timeline.cardDireito.some((card) => {
      if (card.acoes && card.acoes[0].acao === 'REENVIAR') {
        botaoReenviar = card.valor;
      }
    });
    return botaoReenviar;
  }

  getPrintDocumentButton(): string {
    let botaoImprimirDocumento = '';
    this.timeline.cardDireito.some((card) => {
      if (card.acoes && card.acoes[0].acao === 'IMPRIMIR') {
        botaoImprimirDocumento = card.valor;
      }
    });
    return botaoImprimirDocumento;
  }
}
