import { CreditoPessoalService } from './../../../services/cp.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';

import { LoginStorage } from '../../../../../login/login.storage';
import { LojistaService } from '../../../../../services/lojista/lojista.service';
import { NewModalMessageComponent } from '../../../../../shared/modais/new-modal-message/new-modal-message.component';
import { TipoSolicitanteEnum } from '../../../../shared/enums/tipo-solicitante.enum';
import { LojaVendedor } from '../../../../shared/models/loja-vendedor';
import { NumeroEtapaCadastroFichaCDCLoja } from '../../../../shared/services/etapas/etapas.service';

type ILojistaService = { listaLojistaOperador(operacao?: number): Observable<any> };

@Component({
  selector: 'app-selecionar-lojista-vendedor',
  templateUrl: './selecionar-lojista-vendedor.component.html',
  styleUrls: ['./selecionar-lojista-vendedor.component.css']
})
export class SelecionarLojistaVendedorComponent implements OnInit, OnDestroy {
  @Input() service: ILojistaService;
  @Input() operacao: number;
  @Input() solicitante;
  @Input() value: LojaVendedor;
  @Input() formUpdateEmitter: EventEmitter<any>;
  @Output() valueChange = new Subject<LojaVendedor>();
  @Output() valid = new BehaviorSubject(false);
  @ViewChild('modalMessage', { static: true }) modalMessage: NewModalMessageComponent;

  private lojistaService: ILojistaService;
  form: FormGroup;

  lojas: any[];
  vendedores: any[];

  alive = true;

  constructor(
    private fb: FormBuilder,
    private lojistaServiceDefault: LojistaService,
    private cpService: CreditoPessoalService,
    private loginStorage: LoginStorage
  ) {}

  ngOnInit() {
    this.lojistaService = this.service || this.lojistaServiceDefault;

    this.initForm();
    this.initLojistas();
    this.populateForm();

    this.formUpdateEmitter
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => this.handleUpdateEmission(res));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private populateForm() {
    if (this.value.lojaId) {
      this.lojaIdControl.setValue(this.value.lojaId);
    }

    if (this.value.promotorId) {
      this.promotorIdControl.setValue(this.value.promotorId);
    }
  }

  private initForm() {
    this.form = this.fb.group({
      lojaId: ['', Validators.required],
      promotorId: ['', Validators.required]
    });

    this.lojaIdControl.valueChanges.pipe(
      takeWhile(() => this.alive))
      .subscribe(() => this.buscarVendedores());

    this.form.valueChanges.pipe(
      takeWhile(() => this.alive))
      .subscribe(() => this.handleValuesChange());
  }

  private handleValuesChange() {
    this.valid.next(this.form.valid);
    this.valueChange.next(this.form.value);
  }

  private initLojistas() {
    if (this.usuarioLogado.isLojista) {
      this.lojaIdControl.setValue(this.usuarioLogado.lojaTO.id);
    } else if (this.isLojaBalcaoSelecionada) {
      this.lojaIdControl.setValue(this.agenteSelecionado.lojaBalcaoId);
    } else {
      this.buscarLojistas();
    }
  }

  private buscarLojistas() {
    this.buscarVendedores();

    this.lojistaService
      .listaLojistaOperador(this.operacao)
      .pipe(take(1))
      .subscribe(
        res => (this.lojas = res.lojas ? res.lojas.sort(this.sortByName) : res.sort(this.sortByName)),
        () =>
          this.modalMessage.showModal({
            mensagem: 'A consulta de loja do vendedor não retornou resultados'
          })
      );
  }

  private buscarVendedores() {
    this.cpService
      .listarPromotores(this.lojaIdControl.value).pipe(
      take(1))
      .subscribe(
        res => {
          this.vendedores = res.sort(this.sortByName);
          this.vendedores.push(this.vendedorNaoCadastrado());

          if (this.vendedores.filter(v => v.id === Number(this.promotorIdControl.value)).length === 0) {
            this.clearVendedores();
          }
        },
        () => {
          this.clearVendedores();
          this.vendedores = [this.vendedorNaoCadastrado()];
        }
      );
  }

  private vendedorNaoCadastrado() {
    return { id: '0', nome: 'Não cadastrado' };
  }

  private sortByName(a, b): number {
    if (a.nome > b.nome) return 1;
    if (a.nome < b.nome) return -1;
    return 0;
  }

  private clearVendedores() {
    this.promotorIdControl.reset();
  }

  private handleUpdateEmission(etapa): void {
    if (etapa === NumeroEtapaCadastroFichaCDCLoja.Vendedor) {
      this.handleValuesChange();
    }
  }

  get isSelecionarLoja() {
    return (
      !this.usuarioLogado.isLojista &&
      this.solicitante === TipoSolicitanteEnum.LOJA
    );
  }

  get lojaIdControl() {
    return this.form.get('lojaId');
  }

  get promotorIdControl() {
    return this.form.get('promotorId');
  }

  get usuarioLogado() {
    return this.loginStorage.usuario;
  }

  get agenteSelecionado() {
    return this.loginStorage.agente;
  }

  get titulo() {
    return this.isShowLojas
      ? 'Informações da Loja e do Operador'
      : 'Informações do Operador';
  }

  get isLojaBalcaoSelecionada() {
    return [TipoSolicitanteEnum.CLIENTE, TipoSolicitanteEnum.OUTRA].includes(
      this.solicitante
    );
  }

  get isShowLojas() {
    return !this.usuarioLogado.isLojista && !this.isLojaBalcaoSelecionada;
  }
}
