import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

import { AddModalConfig } from '../add-modal-config';

@Component({
  selector: 'app-add-modal',
  templateUrl: './add-modal.component.html',
  styleUrls: ['./add-modal.component.scss']
})
export class AddModalComponent implements OnDestroy {
  @Input() form: FormGroup;
  @Input() config: AddModalConfig;
  @Output() confirmValue = new EventEmitter<boolean>();

  alive = true;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onClose() {
    this.confirmValue.emit(false);
    this.bsModalRef.hide();
  }

  onClickAdd() {
    this.confirmValue.emit(true);
    this.bsModalRef.hide();
  }
}
