import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FichaVeiculoStatus } from './../../../../ficha-veiculo/ficha-veiculo.status';
import { TimelinePropostaVO } from '../../../shared/models/timelineVO';

@Component({
  selector: 'app-banner-timeline',
  templateUrl: './banner-timeline.component.html',
  styleUrls: ['./banner-timeline.component.css']
})
export class BannerTimelineComponent {
  @Input() fichaTimeline: TimelinePropostaVO;
  @Output() afterSosInverso = new EventEmitter<boolean>();

  isShowEnviarSOS() {
    const status = this.fichaTimeline
      .timeline
      .filter(e => e.seqTimeline)
      .pop();

    const statusPermitidos = [
      FichaVeiculoStatus.AGUARDANDO_ANALISE_DIRETA,
      FichaVeiculoStatus.EM_ANALISE_DIRETA
    ];

    return status && !status.rejeitado && statusPermitidos.includes(status.idStatus);
  }

  onSosEnviado() {
    this.afterSosInverso.emit(true);
  }
}
