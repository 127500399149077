import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OmniGeralRestService } from './../../omni-rest/omni-geral/omni-geral-rest.service';

@Injectable()
export class PromotorService {

  liberaCessao: boolean = undefined;
  constructor(private _restService: OmniGeralRestService) { }

  getOperador(idPromotor: number): Observable<any> {
    return this._restService.get(`operadores/id/${idPromotor}`);
  }

  async getOperadorLiberaCessao(idPromotor: number) {
    const response = await this.getOperador(idPromotor).pipe(
      take(1)).toPromise();
    this.liberaCessao = response.operador.liberaCessao;
  }

  get isOperadorLiberaCessao() {
    return this.liberaCessao;
  }

  clear() {
    this.liberaCessao = undefined;
  }

}
