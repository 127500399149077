import { PropostaStateService } from './../../../../../shared/states/proposta-state.service';
import { MicrocreditoService } from './../../../../services/microcredito.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FaefStateService } from './../faef-state.service';
import { Faef } from './../model/faef';

import * as toastr from 'toastr';

@Component({
  selector: 'app-parecer-agente',
  templateUrl: './parecer-agente.component.html',
  styleUrls: ['./parecer-agente.component.css']
})
export class ParecerAgenteComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() loading = new EventEmitter(true);

  parecerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private state: FaefStateService,
    private propostaState: PropostaStateService,
    private service: MicrocreditoService
  ) { }

  ngOnInit() {
    this.createForms();

    const faef = this.state.getLast();
    if (faef.faefResultado) {
      const { parecerAgente, notaConfiancaCliente, valorEmprestaria } = faef.faefResultado;
      this.parecerForm.patchValue(
        {
          parecerAgente,
          notaConfiancaCliente,
          valorEmprestaria: String(valorEmprestaria || '')
        },
        {
          emitEvent: false
        }
      );
    }
  }

  get valoresNotaConfianca() {
    return Array.from({ length: 11 }, (x, i) => i);
  }

  async onClickNext() {
    this.updateState();
    await this.saveFaef();
    this.next.emit();
  }

  onClickPrevious() {
    this.updateState();
    this.previous.emit();
  }

  private updateState() {
    const faef:Faef = this.state.getLast();
    const { parecerAgente, notaConfiancaCliente, valorEmprestaria } = this.parecerForm.value;
    faef.faefResultado = {
      ...faef.faefResultado,
      parecerAgente: parecerAgente && parecerAgente.trim() ? parecerAgente : 'SEM PARECER',
      notaConfiancaCliente: Number(notaConfiancaCliente),
      valorEmprestaria: Number(valorEmprestaria)
    };
    this.state.setState(faef);
  }

  private createForms() {
    this.parecerForm = this.fb.group({
      parecerAgente: [''],
      notaConfiancaCliente: ['', Validators.required],
      valorEmprestaria: ['', Validators.required]
    });
  }

  private async saveFaef() {
    this.loading.emit(true);
    try {
      const proposta = this.propostaState.getLast();
      const faef:Faef = this.state.getLast();
      await this.service.save(faef, proposta.id);
    } catch (error) {
      console.log(error);
      toastr.error('Não foi possível salvar a FAEF no servidor. Seus dados permanecem salvo no dispositivo.', 'Falha ao comunicar com servidor');
      throw error;
    } finally {
      this.loading.emit(false);
    }
  }

}
