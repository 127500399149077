import { DirectivesModule } from './../../directives/directives.module';
import { LoadersModule } from './../loaders/loaders.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DadosProfissionaisComponent } from './dados-profissionais.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoadersModule,
    DirectivesModule
  ],
  declarations: [
    DadosProfissionaisComponent
  ],
  exports: [
    DadosProfissionaisComponent
  ]
})
export class DadosProfissionaisModule { }
