import { EmotionSelectItemComponent } from './../emotion-select-item/emotion-select-item.component';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, forwardRef, ContentChildren, QueryList, AfterContentInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-emotion-select',
  templateUrl: './emotion-select.component.html',
  styleUrls: ['./emotion-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmotionSelectComponent),
    multi: true
  }]
})
export class EmotionSelectComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {

  value: any;
  onChange: (_: any) => void = (_: any) => {
    // This is intentional
  }
  onTouched: () => void = () => {
    // This is intentional
  }

  isAlive = true;

  @ContentChildren(EmotionSelectItemComponent) contentChildren!: QueryList<EmotionSelectItemComponent>;

  ngAfterContentInit() {
    this.contentChildren
      .forEach(c => c.onSelect.pipe(takeWhile(() => this.isAlive)).subscribe(value => this.writeValue(value)));
    this.updateChildren();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (value === this.value) {
      this.value = null;
    } else {
      this.value = value;
    }

    this.updateChildren();

    this.updateChanges();
    this.onTouched();
  }

  private updateChildren() {
    if (this.contentChildren) {
      this.childrenSetValue(this.value);
    }
  }

  private childrenSetValue(value) {
    this.contentChildren.filter(c => c.value !== value)
      .forEach(c => c.isSelected = false);
    this.contentChildren.filter(c => c.value === value)
      .forEach(c => c.isSelected = true);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
