export class TipoVeiculoEnum {
  public static AUTOMOVEIS = 'AUTOMOVEIS';
  public static MOTOCICLETAS = 'MOTOCICLETAS';
  public static PESADOS = 'PESADOS';
}

export class SimuladorVeiculoTipoVeiculoEnum {
  public static AUTOMOVEIS = 'VEÍCULO LEVE';
  public static MOTOCICLETAS = 'MOTO';
  public static PESADOS = 'VEÍCULO PESADO';
}
