
import { take } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { SelectAgenteResult } from '../select-agente/select-agente-result';
import { ModalMessageComponent } from './../../shared/modais/modal-message/modal-message.component';
import { LoginStorage } from './../login.storage';
import { SelectAgenteResultStatus } from './../select-agente/select-agente-result-status';
import { SelectAgenteComponent } from './../select-agente/select-agente.component';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
})
export class AuthenticationComponent implements OnInit {
  @ViewChild('selecionarAgente', { static: true }) selecionarAgente: SelectAgenteComponent;

  @ViewChild('modalMessage', { static: true }) modalMessage: ModalMessageComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginStorage: LoginStorage,
    private loginService: LoginService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.loginStorage.clear();
    this.initObservableParams();
    this.initSelectAgenteSubscribe();
  }

  private initObservableParams() {
    this.activatedRoute.queryParams.pipe(
      take(1))
      .subscribe((params) => this.handleQueryParams(params));
  }

  initSelectAgenteSubscribe() {
    this.selecionarAgente.onSelectAgente.pipe(
      take(1))
      .subscribe((result) => this.onSelectAgente(result));
  }

  private handleQueryParams(params: any) {
    if (!params.login || !params.token) {
      this.goToLogin();
    } else {
      this.storageService
        .renewUser(JSON.stringify({login: params.login, token: params.token}))
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.loginStorage.register(result.usuario);
              this.loginStorage.registerDataExpiracao(result.dataExpiracao);
              this.loginService
                .getAndAuthenticateUser(params)
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    this.selecionarAgente.selecionar();
                  },
                  (err) => this.goToLogin(),
                );
            },
            (err) => this.goToLogin(),
          );
    }
  }

  private onSelectAgente(result: SelectAgenteResult) {
    switch (result.status) {
      case SelectAgenteResultStatus.SUCCESS:
        this.goToFicha();
        break;
      case SelectAgenteResultStatus.ERROR:
        this.modalMessage.show(
          'Falha na Comunicação, verifique sua conexão.',
        );
        this.modalMessage.modalClose.pipe(take(1)).subscribe(() => this.goToLogin());
        break;
      default:
        this.goToLogin();
    }
  }

  private goToLogin() {
    this.router.navigate(['/login']);
  }

  private goToFicha() {
    this.router.navigate(['/ficha']);
  }
}
