import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { DialogService } from '../../services/dialog/dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls:['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: ElementRef;

  constructor(
    public dialogService: DialogService
  ) {}

  ngOnInit() {
    this.dialogService.setNativeElement(this.dialog.nativeElement);
  }

  close(): void {
    this.dialogService.close();
  }

  validateAndCall(fn: () => void) {
    typeof fn === 'function' && fn();
  }
}
