import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-emotion-select-item',
  templateUrl: './emotion-select-item.component.html',
  styleUrls: ['./emotion-select-item.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmotionSelectItemComponent),
    multi: true
  }]
})
export class EmotionSelectItemComponent {

  @Input() value: any;
  @Input() emotion: 'very-happy' | 'happy' | 'so-so' | 'sad' | 'very-sad';

  @Output() onSelect = new EventEmitter<any>();
  isSelected: boolean = false;

  select() {
    this.isSelected = true;
    this.onSelect.emit(this.value);
  }

  get className() {
    return `emoticon ${this.emotion} ${this.isSelected ? '' : 'disabled'}`;
  }

}
