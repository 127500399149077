import { TipoVeiculoComponent } from './tipo-veiculo/tipo-veiculo.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeralServiceModule } from '../../../services/geral/geral.service.module';
import { ServicesModule } from '../../../services/services.module';
import { BreadcrumbModule } from '../../../shared/breadcrumb/breadcrumb.module';
import { ModaisModule } from '../../../shared/modais/modais.module';
import { ButtonSelectorComponent } from './button-selector/button-selector.component';
import { InicioComponent } from './inicio.component';
import { QuemSolicitouComponent } from './quem-solicitou/quem-solicitou.component';
import { TipoOperacaoComponent } from './tipo-operacao/tipo-operacao.component';

@NgModule({
  imports: [
    CommonModule,
    BreadcrumbModule,
    ServicesModule,
    FormsModule,
    ReactiveFormsModule,
    GeralServiceModule,
    ModaisModule,
  ],
  declarations: [
    InicioComponent,
    QuemSolicitouComponent,
    TipoOperacaoComponent,
    ButtonSelectorComponent,
    TipoVeiculoComponent,
  ],
  exports: [
    InicioComponent,
    QuemSolicitouComponent,
    TipoOperacaoComponent,
    ButtonSelectorComponent,
    TipoVeiculoComponent,
  ],
})
export class InicioModule {}
