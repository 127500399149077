export class TelefoneCliente {
  public celular: string;
  public celularAdicional: string;
  public residencial: string;
  public comercial: string;

  constructor(data?: Object) {
    this.trataTelefone(data);
  }

  trataTelefone(value: Object) {
    if (value instanceof Array) {
      value.forEach(item => {
        if (item.categoria && item.categoria.id && item.ddd && item.telefone) {
          if (item.categoria.id === 1) {
            this.residencial = `${item.ddd}${item.telefone}`;
          }
          if (item.categoria.id === 2) {
            this.comercial = `${item.ddd}${item.telefone}`;
          }
          if (item.categoria.id === 3) {
            this.celular = `${item.ddd}${item.telefone}`;
          }
        }
      });
    }
  }
}
