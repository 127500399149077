import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-info-app-omni',
  templateUrl: './modal-info-app-omni.component.html',
  styleUrls: ['./modal-info-app-omni.component.scss']
})
export class ModalInfoAppOmniComponent {

  constructor(private bsModalRef: BsModalRef) { }

  closeOnClick() {
    this.bsModalRef.hide();
  }
}
